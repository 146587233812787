import { SCol, SIcon, SRow } from "@avalara/skylab-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllStatesAsync } from "../../../app/customerSlice";
import { fetchRetailCustomers, setCustomerFiltersState } from "../../../app/retailSlice";
import { urlToPageMap } from "../../../shared/Utils";
import { addressLine1Tag, cityTag, customerCodeTag, zipCodeTag } from "../../../shared/constants";
import SelectState from "../../customers/SelectState";
import SelectExposureZone from "../../sharedComponents/SelectExposureZone";
import "./search.css";
import featureFlag from "../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../shared/sessionUtility";

function SearchComponent(props) {
    const [customerName, setCustomerName] = useState("");
    const [certificateID, setCertificateID] = useState("");
    const [customerCode, setCustomerCode] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [region, setRegion] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [certificateIdHasError, setCertificateIdHasError] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isUserEligibleToCreateCustomer = dispatch(
        isEligibleUser(featureFlag.retail.editCustomer)
    );

    useEffect(() => {
        props?.setHideNav(true);
    }, [props?.setHideNav]);

    useEffect(() => {
        dispatch(getAllStatesAsync());
    }, [dispatch]);

    const validateCertificateId = () => {
        if (/^\d+$/.test(certificateID) || certificateID === "") {
            return true;
        }
        setCertificateIdHasError(true);
        return false;
    };

    const handleChangeInput = setter => {
        return event => setter(event.target.value);
    };

    const handleStateAdd = event => {
        setState(event.value);
    };

    const handleStateRemove = () => {
        setState("");
    };

    const handleRegionAdd = event => {
        setRegion(event.value);
    };

    const handleRegionRemove = () => {
        setRegion("");
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (!validateCertificateId()) {
            return;
        }
        const requestBody = {
            nameOrCode: customerName,
            phoneNumber,
            emailAddress,
            address: addressLine1,
            city,
            zip: zipCode,
            certificateIds: certificateID ? [certificateID] : null,
            customerCodes: customerCode ? [customerCode] : null,
            exposureZones: region ? [region] : null,
            customerRegions: state ? [state] : null,
            isRetailSearch: true,
        };
        dispatch(setCustomerFiltersState(requestBody));
        dispatch(fetchRetailCustomers());
        navigate(`${urlToPageMap.retailSearch}`);
    };

    const handleReset = () => {
        setCustomerName("");
        setCertificateID("");
        setCustomerCode("");
        setAddressLine1("");
        setCity("");
        setState("");
        setZipCode("");
        setPhoneNumber("");
        setEmailAddress("");
        setRegion("");
    };

    const searchClassName = "primary large max-width-buttton icon-trailing ";

    return (
        <form onSubmit={handleSubmit}>
            <SRow>
                <SCol span={12}>
                    <span className="font-semibold text-xl">Find an exemption</span>
                </SCol>
            </SRow>
            <SRow class="margin-top-sm">
                <SCol span={6}>
                    <label htmlFor="customer-name">Customer name</label>
                    <input
                        type="text"
                        id="customer-name"
                        name="customer-name"
                        value={customerName}
                        onChange={handleChangeInput(setCustomerName)}
                    />
                </SCol>
                <SCol span={6}>
                    <label htmlFor={customerCodeTag}>Customer code</label>
                    <input
                        type="text"
                        id={customerCodeTag}
                        name={customerCodeTag}
                        value={customerCode}
                        onChange={handleChangeInput(setCustomerCode)}
                    />
                </SCol>
            </SRow>
            <SRow class="margin-top-sm">
                <SCol span={6}>
                    <label htmlFor="phone-number">Phone number</label>
                    <input
                        type="text"
                        id="phone-number"
                        value={phoneNumber}
                        onChange={handleChangeInput(setPhoneNumber)}
                    />
                </SCol>
                <SCol span={6}>
                    <label htmlFor="email-address">Email address</label>
                    <input
                        type="text"
                        id="email-address"
                        name="email-address"
                        value={emailAddress}
                        onChange={handleChangeInput(setEmailAddress)}
                    />
                </SCol>
            </SRow>
            <SRow class="margin-top-sm">
                <SCol span="lg-8 xl-8 12">
                    <label htmlFor={addressLine1Tag}>Address</label>
                    <input
                        type="text"
                        id={addressLine1Tag}
                        name={addressLine1Tag}
                        value={addressLine1}
                        onChange={handleChangeInput(setAddressLine1)}
                    />
                </SCol>
            </SRow>
            <SRow class="margin-top-sm">
                <SCol span={4}>
                    <label htmlFor={cityTag}>City</label>
                    <input
                        type="text"
                        id={cityTag}
                        name={cityTag}
                        value={city}
                        onChange={handleChangeInput(setCity)}
                    />
                </SCol>
                <SCol span={4}>
                    <SelectState
                        value={state}
                        onChange={handleStateAdd}
                        onRemove={handleStateRemove}
                        label="State, province, or territory"
                        all
                    />
                </SCol>
                <SCol span={4}>
                    <label htmlFor={zipCodeTag}>ZIP code</label>
                    <input
                        type="text"
                        id={zipCodeTag}
                        name={zipCodeTag}
                        value={zipCode}
                        onChange={handleChangeInput(setZipCode)}
                    />
                </SCol>
            </SRow>
            <SRow class="margin-top-sm">
                <SCol span={6}>
                    <label htmlFor="certificate-id">Certificate ID</label>
                    <input
                        type="text"
                        id="certificate-id"
                        value={certificateID}
                        onChange={handleChangeInput(event => {
                            setCertificateIdHasError(false);
                            setCertificateID(event);
                        })}
                        className={certificateIdHasError ? "error" : ""}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        Certificate ID only accepts number
                    </div>
                </SCol>
                <SCol span={6}>
                    <SelectExposureZone
                        onAdd={handleRegionAdd}
                        onRemove={handleRegionRemove}
                        value={region}
                        label="Certificate region"
                        exposureZoneDisabled={false}
                    />
                </SCol>
            </SRow>
            <SRow class="margin-top-sm">
                <SCol span="lg-8 xl-8 12">
                    <button type="submit" className={searchClassName}>
                        Search
                        <SIcon name="chevron-right" aria-label="chevron-right" />
                    </button>
                </SCol>
            </SRow>
            <SRow class="margin-top-sm">
                <SCol span="md-6 lg-5 xl-4 12">
                    <button
                        type="button"
                        className="secondary icon-leading large max-width-buttton pad-all-none"
                        onClick={() => {
                            navigate(
                                `${urlToPageMap.retail}/${urlToPageMap.retailCustomer.replace(
                                    "/:id?",
                                    ""
                                )}`
                            );
                        }}
                        disabled={!isUserEligibleToCreateCustomer}>
                        <SIcon name="plus" aria-label="plus" />
                        New Exemption
                    </button>
                </SCol>
                <SCol span="md-6 lg-3 xl-4 12">
                    <button
                        type="button"
                        className="tertiary large max-width-buttton pad-all-none"
                        onClick={() => handleReset()}>
                        Clear/reset
                    </button>
                </SCol>
            </SRow>
        </form>
    );
}

export default SearchComponent;
