import { SRow, SCol } from "@avalara/skylab-react";
import React from "react";

function SearchNoData() {
    return (
        <SRow>
            <SCol>
                <p className="text-sm font-normal">
                    We can’t find anything related to your search. Adjust your search criteria and
                    try again, or create a new exemption.
                </p>
            </SCol>
        </SRow>
    );
}

export default SearchNoData;
