import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";

import {
    fetchCodesByManyRegions,
    selectIsLoadingMatrixExemptReasons,
    selectMatrixExemptReasonData,
} from "../../../app/exemptionMatrixSlice";

const SelectPackageReasons = React.memo(props => {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingMatrixExemptReasons);
    const selectRef = React.createRef();
    let packageReasons = useSelector(selectMatrixExemptReasonData);

    if (props.exData) {
        packageReasons = packageReasons
            .filter(element => element.templateTag !== null)
            .filter(
                element =>
                    !props.exData.includes(`${element.exemptReason.name} (${element.region.name})`)
            )
            .map(element => {
                const selectOption = {
                    value: `${element.exemptReason.id}-${element.regionId}`,
                    label: `${element.exemptReason.name} (${element.region.name})`,
                    staticFile: element.staticFile !== null,
                    selected: false,
                    templateTag: element.templateTag,
                    taxCodeId: element.taxCodeId,
                    taxCodeName: element.exemptReason.name,
                    regionId: element.regionId,
                    regionName: element.region.name,
                    matrixID: element.id,
                };
                if (props.value?.includes(`${element.exemptReason.id}-${element.regionId}`)) {
                    selectOption.selected = true;
                }
                return selectOption;
            })
            .sort((a, b) => {
                const codeA = a.taxCodeName.toUpperCase();
                const codeB = b.taxCodeName.toUpperCase();

                const nameA = a.regionName.toUpperCase();
                const nameB = b.regionName.toUpperCase();

                if (codeA < codeB || nameA < nameB) {
                    return -1;
                }
                if (codeA > codeB || nameA > nameB) {
                    return 1;
                }
                return 0;
            });
    } else {
        packageReasons = packageReasons
            .filter(element => element.templateTag !== null || element.staticFile !== null)
            .map(element => {
                const selectOption = {
                    value: `${element.exemptReason.id}-${element.regionId}`,
                    label: `${element.exemptReason.name} (${element.region.name})`,
                    staticFile: element.staticFile !== null,
                    selected: false,
                    templateTag: element.templateTag,
                    taxCodeId: element.taxCodeId,
                    taxCodeName: element.exemptReason.name,
                    regionId: element.regionId,
                    regionName: element.region.name,
                    matrixID: element.id,
                };
                if (props.value?.includes(`${element.exemptReason.id}-${element.regionId}`)) {
                    selectOption.selected = true;
                }
                return selectOption;
            })
            .sort((a, b) => {
                const codeA = a.taxCodeName.toUpperCase();
                const codeB = b.taxCodeName.toUpperCase();

                const nameA = a.regionName.toUpperCase();
                const nameB = b.regionName.toUpperCase();

                if (codeA < codeB || nameA < nameB) {
                    return -1;
                }
                if (codeA > codeB || nameA > nameB) {
                    return 1;
                }
                return 0;
            });
    }

    useEffect(() => {
        if (!props.disabled && props.zoneIds.length >= 1) {
            dispatch(fetchCodesByManyRegions(props.zoneIds));
        } else {
            selectRef.current.deselectAll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.disabled, props.zoneIds, props.userAction]);

    useEffect(() => {
        if (props.reasonOverride) {
            selectRef.current.deselectAll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reasonOverride, props.userAction]);

    return (
        <React.Fragment>
            <label htmlFor={getNextId()} className="required" id="lbl-packageReasons">
                Reason for exemption
            </label>
            <SSelect
                name="packageReasons"
                ref={selectRef}
                inputId={getCurrentId()}
                selectionOptional
                optionsList={packageReasons}
                loading={isLoading}
                className={props.reasonError}
                onS-select={e => props.onAdd(e.detail.item)}
                onS-deselect={e => props.onRemove(e.detail.item)}
                disabled={props.disabled ? "disabled" : null}
                noSearch
                multiple
            />
        </React.Fragment>
    );
});

export default SelectPackageReasons;
