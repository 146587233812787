import store from "../app/store";
import { matchSubscriptions, matchRole } from "./Utils";
import { avataxRole, subscription, ecmRole, tier } from "../features/navigation/Subscription";

export const getPageTitle = pageTitle => (dispatch, getState) => {
    const { session } = getState();
    return `AvaTax | ${session.activeCompany.name} | ${pageTitle}`;
};

export const isManagedServiceUser = () => (dispatch, getState) => {
    const { session } = getState();
    if (session?.ecmRole?.includes(ecmRole.MSUser)) {
        return true;
    }
    return false;
};

export const validateSubscriptions = feature => (dispatch, getState) => {
    const { session } = getState();
    if (
        feature?.subscription[0] === "All" ||
        (dispatch(isManagedServiceUser()) && feature?.ecmRole?.includes(ecmRole.MSUser)) ||
        matchSubscriptions(feature?.subscription, session?.subscriptions) ||
        feature?.tier?.includes(session.tier)
    ) {
        return true;
    }
    return false;
};

export const isEligibleUser = (feature, viewOnly) => (dispatch, getState) => {
    const { session } = getState();
    const isValidated = dispatch(validateSubscriptions(feature));

    if (isValidated) {
        if (viewOnly) return true;
        if (
            session.role === avataxRole.ECMRoleUser &&
            feature?.ecmRole?.length &&
            session?.ecmRole?.length
        ) {
            if (matchRole(feature.ecmRole, session.ecmRole)) return true;
            return false;
        }

        if (
            session.role === avataxRole.ECMAccountUser ||
            session.role === avataxRole.ECMCompanyUser
        ) {
            if (feature?.avataxRole?.includes(session.role)) return true;
            return false;
        }

        if (feature.internalUser) {
            if (session.isInternalUser) return true;
            return false;
        }

        if (feature.checkAdmin) {
            if (session?.role?.includes("Admin")) return true;
            return false;
        }

        return true;
    }
    return false;
};

export const essentialsOrAbove = () => (dispatch, getState) => {
    const { session } = getState();
    if (
        session.subscriptions.includes(subscription.ECMEssentials) ||
        session.subscriptions.includes(subscription.ECMStandard) ||
        session.subscriptions.includes(subscription.ECMPro) ||
        session.subscriptions.includes(subscription.ECMPremium) ||
        session.tier === tier.ENT
    ) {
        return true;
    }
    return false;
};

export const vendorExemptions = () => (dispatch, getState) => {
    const { session } = getState();
    if (
        session.subscriptions.includes(subscription.VEPRO) ||
        session.subscriptions.includes(subscription.VEPREMIUM)
    ) {
        return true;
    }
    return false;
};

export const vendorPremium = () => (dispatch, getState) => {
    const { session } = getState();
    if (session.subscriptions.includes(subscription.VEPREMIUM)) {
        return true;
    }
    return false;
};

export const isNoAccessUser = () => (dispatch, getState) => {
    const { session } = getState();
    if (session?.ecmRole?.includes(ecmRole.CEMNoAccess)) {
        return true;
    }
    return false;
};

export const isRetailOnlyUser = () => (dispatch, getState) => {
    const { session } = getState();
    if (session.role === avataxRole.ECMRoleUser && session.isRetailer) {
        return true;
    }
    return false;
};

export const getSessionAccessToken = () => {
    const { session } = store.getState();
    if (!session.accessToken) {
        return Promise.reject(new Error("Session access token not available"));
    }
    return Promise.resolve({ accessToken: session.accessToken });
};
