import React from "react";
import { useDispatch } from "react-redux";
import { SDialog, SAlert } from "@avalara/skylab-react";
import {
    setRequestingCertificateFormsDialogOpen,
    setCertificateFormsDialogOpen,
} from "../../../../app/settingsSlice";

function CancelConfirmationDialog(props) {
    const dispatch = useDispatch();

    return (
        <SDialog
            id="cancelConfirmation-dialog"
            open={props?.isCancelConfirmationDialogOpen}
            onS-dismiss={() => {
                props?.setIsCancelConfirmationDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="cancelConfirmation">
                Leave without saving?
            </div>

            <div slot="body">
                <SAlert id="delete-dialog" status="warning" role="alert" noDismiss>
                    Your changes won&apos;t be saved.
                </SAlert>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => {
                        props?.setIsCancelConfirmationDialogOpen(false);
                        document.getElementById("cancelConfirmation-dialog")?.close();
                    }}>
                    No, cancel
                </button>
                <button
                    type="button"
                    className="secondary small"
                    onClick={() => {
                        dispatch(setRequestingCertificateFormsDialogOpen(false));
                        dispatch(setCertificateFormsDialogOpen(false));
                        props?.setIsCancelConfirmationDialogOpen(false);
                        document.getElementById("cancelConfirmation-dialog")?.close();
                    }}>
                    Yes, Close
                </button>
            </div>
        </SDialog>
    );
}

export default CancelConfirmationDialog;
