import { AuiIcon, SBox, SCol, SIcon, SLoader, SRow, SContainer } from "@avalara/skylab-react";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCertificateFile, selectCertificateStatuses } from "../../../app/certificateSlice";
import {
    fetchCustomerCertificates,
    selectPage,
    selectLoading as loadingCustomerExemptions,
} from "../../../app/customerCertificateSlice";
import {
    fetchCustomerAPI,
    selectCustomer,
    selectLoading,
    setCustomer,
} from "../../../app/customerSlice";
import axiosInstance from "../../../axios";
import toast from "../../../hooks/toast";
import { urlToPageMap, buildApiV3Url } from "../../../shared/Utils";
import RecordCount from "../../search/RecordCount";
import CertificateStatusTag from "../../sharedComponents/CertificateStatusTag";
import "./customer.css";
import PreviewDialog from "../../sharedDialogs/PreviewDialog";
import NoData from "../../search/NoData";
import featureFlag from "../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../shared/sessionUtility";

function CustomerDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const isLoadingCustomer = useSelector(selectLoading);
    const isLoadingExemptions = useSelector(loadingCustomerExemptions);
    const customer = useSelector(selectCustomer);
    const page = useSelector(selectPage);
    const [selectedRow, setSelectedRow] = useState({ certificateId: 0 });
    const pageData = page?.data;
    const actionItemsClassName = classNames("primary icon-leading");
    const [showToast] = toast();
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
    const noCustomerFound = !(Object.keys(customer).length > 0);
    const certificateStatuses = useSelector(selectCertificateStatuses, shallowEqual);
    const isUserEligibleToCreateCustomer = dispatch(
        isEligibleUser(featureFlag.retail.editCustomer)
    );
    useEffect(() => {
        if (Number.isInteger(parseInt(params.id, 10))) {
            dispatch(fetchCustomerAPI(params.id));
            // dispatch(fetchCustomerCertificates(20, 0, null, params.id, true, parseInt(selectedLocation.value, 10)));
            dispatch(fetchCustomerCertificates(20, 0, null, params.id, true));
        }
    }, [params.id, dispatch]);

    useEffect(() => {
        return () => dispatch(setCustomer({}));
    }, [dispatch]);

    const handleRadioChange = (e, row) => {
        if (selectedRow?.certificateId !== row?.certificateId) setSelectedRow(row);
    };

    const columns = [
        gridHeader(
            "",
            ["state"],
            (value, row) => (
                <span>
                    <input
                        type="radio"
                        id={row?.certificateId}
                        value={row?.certificateId}
                        name={row?.certificateId}
                        checked={selectedRow?.certificateId === row?.certificateId}
                        onChange={e => handleRadioChange(e, row)}
                    />
                    <label htmlFor={row.certificateId}>&#8205;</label>
                </span>
            ),
            null,
            "",
            null,
            null,
            null,
            null
        ),
        gridHeader(
            "Certificate",
            ["state"],
            (value, row) => row?.state?.initials,
            null,
            "",
            null,
            null,
            null,
            "Certificate"
        ),
        gridHeader(
            "Status",
            "certificateStatus",
            (value, row, col, index) => (
                <React.Fragment>
                    {CertificateStatusTag(
                        row,
                        col.length - index <= 3,
                        false,
                        certificateStatuses?.filter(x => x.id === row.certificateStatusId)[0]?.name
                    )}
                </React.Fragment>
            ),
            null,
            ""
        ),
        gridHeader(
            "Expiration",
            "expirationDate",
            value => new Date(value).toLocaleDateString(),
            null,
            "",
            null,
            null,
            null,
            "expirationDate"
        ),
        gridHeader(
            "Exempt reason",
            "taxCodeName",
            (value, row) => {
                if (row.taxCodeName !== null) return row.taxCodeName;
                return row.expectedTaxCodeName;
            },
            null,
            ""
        ),
        gridHeader(
            "Certificate ID",
            "certificateId",
            null,
            null,
            null,
            null,
            null,
            null,
            "certificateId"
        ),
    ];

    const getRowKey = row => {
        return row.certificateId;
    };

    const address = (
        <>
            {customer.addressLine1}
            {customer.addressLine2 ? `, ${customer.addressLine2}, ` : ""}{" "}
            {customer.city ? `${customer.city}, ` : ""}{" "}
            {customer.state ? customer.state.initials : ""} {customer.zip}{" "}
            {customer.country ? customer.country.initials : ""}
        </>
    );

    const handleRenew = () => {
        const { exposureZoneId, exposureZoneName, taxCodeName, expectedTaxCodeName } =
            selectedRow ?? {};

        const url = `${urlToPageMap.retail}/${urlToPageMap.retailCustomer.replace(
            ":id?",
            params.id
        )}/${urlToPageMap.retailAddCertificate}`;

        navigate(url, {
            state: {
                exposureZoneObj: {
                    value: exposureZoneId,
                    label: exposureZoneName,
                    isSST: false,
                },
                taxCodeName: taxCodeName ?? expectedTaxCodeName,
            },
        });
    };

    const handlePrint = async () => {
        const response = await axiosInstance.get(
            buildApiV3Url(`certificates/${selectedRow?.certificateId}/certificate-file`),
            { withCredentials: true, responseType: "blob" }
        );
        if (response.headers["content-type"].includes("application/json")) {
            showToast("error", "Failed to print certificate file. Please try again later");
            return;
        }
        const blob = new Blob([response.data], { type: response.data.type });
        const url = window.URL.createObjectURL(blob);
        const myWindow = window.open(url, "");
        myWindow.onload = () => {
            myWindow.print();
        };
    };

    const handlePreview = () => {
        setIsPreviewDialogOpen(true);
    };

    function downloadFile() {
        return () => {
            if (selectedRow?.certificateId !== 0) {
                dispatch(getCertificateFile(selectedRow?.certificateId, showToast));
            }
        };
    }

    let customerContent = null;

    if (isLoadingCustomer) {
        customerContent = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading customer...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    } else if (noCustomerFound) {
        customerContent = <NoData retailCustomer />;
    } else {
        customerContent = (
            <SRow>
                <SCol span="2" className="hide-xs hide-sm">
                    <AuiIcon
                        name="399"
                        aria-hidden="true"
                        alt="customer details"
                        color="gray-darker"
                        size="std"
                        className="aui-icon-img"
                    />
                </SCol>
                <SCol span="10">
                    <span className="inline-display font-semibold text-md">{customer?.name}</span>
                    <button
                        className="link margin-left-lg"
                        onClick={() => {
                            navigate(
                                `${urlToPageMap.retail}/${urlToPageMap.retailCustomer.replace(
                                    ":id",
                                    `${params.id}`
                                )}`
                            );
                        }}
                        disabled={!isUserEligibleToCreateCustomer}>
                        Edit details
                    </button>
                    <SRow className="hide-sm">
                        <SCol span={4}>
                            <h4 id="customer-code" className="margin-all-none">
                                Customer code
                            </h4>
                        </SCol>
                        <SCol span={4}>
                            <h4 id="phone-number" className="margin-all-none">
                                Phone number
                            </h4>
                        </SCol>
                        <SCol span={4}>
                            <h4 id="address" className="margin-all-none">
                                Address
                            </h4>
                        </SCol>
                    </SRow>
                    <SRow className="hide-sm">
                        <SCol span="4" className="font-normal">
                            {customer?.customerNumber}
                        </SCol>
                        <SCol span="4" className="font-normal">
                            {customer?.phoneNumber}
                        </SCol>
                        <SCol span="4" className="font-normal">
                            {address}
                        </SCol>
                    </SRow>
                    <SRow hide="md xl lg">
                        <SCol>
                            <SRow>
                                <SCol span="sm-6">
                                    <h4 id="customer-code" className="margin-all-none">
                                        Customer code
                                    </h4>
                                </SCol>
                                <SCol span="sm-6">{customer?.customerNumber}</SCol>
                            </SRow>
                            <SRow>
                                <SCol span="sm-6">
                                    <h4 id="phone-number" className="margin-all-none">
                                        Phone number
                                    </h4>
                                </SCol>
                                <SCol span="sm-6">{customer?.phoneNumber}</SCol>
                            </SRow>
                            <SRow>
                                <SCol span="sm-6">
                                    <h4 id="address" className="margin-all-none">
                                        Address
                                    </h4>
                                </SCol>
                                <SCol span="sm-6">{address}</SCol>
                            </SRow>
                        </SCol>
                    </SRow>
                </SCol>
            </SRow>
        );
    }

    const footerButtonClasses = "md-6 lg-3 xl-2 12";

    const certificateActions = (
        <SRow>
            <SCol class="flex justify-content-end margin-bottom-md">
                <button
                    type="button"
                    className={actionItemsClassName}
                    disabled={selectedRow?.certificateId === 0 || !isUserEligibleToCreateCustomer}
                    onClick={() => handleRenew()}>
                    <SIcon name="copy" aria-hidden="true" />
                    <span className="hide-sm">Renew</span>
                </button>
                <button
                    type="button"
                    className={actionItemsClassName}
                    disabled={selectedRow?.certificateId === 0}
                    onClick={downloadFile()}>
                    <SIcon name="download" aria-hidden="true" />
                    <span className="hide-sm">Download</span>
                </button>
                <button
                    type="button"
                    className={actionItemsClassName}
                    disabled={selectedRow?.certificateId === 0}
                    onClick={() => handlePreview()}>
                    <SIcon name="zoom-in" aria-hidden="true" />
                    <span className="hide-sm">Preview</span>
                </button>
                <button
                    type="button"
                    className={actionItemsClassName}
                    disabled={selectedRow?.certificateId === 0}
                    onClick={() => handlePrint()}>
                    <SIcon name="print" aria-hidden="true" />
                    <span className="hide-sm">Print</span>
                </button>
            </SCol>
        </SRow>
    );

    const exemptionLoader = (
        <div className="flex dl-flex-center flex-dir-col">
            <h3>Loading customer exemptions...</h3>
            <SLoader id="page-loader" className="medium" aria-live="polite" loading />
        </div>
    );

    const exemptionGrid = (
        <SRow>
            <SCol class="flex justify-content-end">
                <RecordCount
                    selectedCount={0}
                    recordCount={page?.paginate?.totalRecords}
                    recordName="exemption certificates"
                />
            </SCol>
            <SCol span={12} className="margin-bottom-md">
                <s-table-container>
                    <DataGrid columns={columns} rows={pageData} getKey={getRowKey} inContainer />
                </s-table-container>
            </SCol>
        </SRow>
    );

    const exemptionContent = !noCustomerFound ? (
        <div>
            {certificateActions}
            {isLoadingExemptions ? exemptionLoader : exemptionGrid}
            <SRow>
                <SCol span={footerButtonClasses}>
                    <button
                        className="primary large max-width-buttton"
                        onClick={() => navigate(urlToPageMap.retail)}>
                        Done
                    </button>
                </SCol>
                <SCol span={footerButtonClasses}>
                    <button
                        className="secondary icon-leading large max-width-buttton pad-all-none"
                        onClick={() => {
                            dispatch(setCustomer({}));
                            navigate(
                                `${urlToPageMap.retail}/${urlToPageMap.retailCustomer.replace(
                                    ":id?",
                                    params.id
                                )}/${urlToPageMap.retailAddCertificate}`
                            );
                        }}
                        disabled={!isUserEligibleToCreateCustomer}>
                        <s-icon name="plus" />
                        Add a jurisdiction
                    </button>
                </SCol>
            </SRow>
        </div>
    ) : null;

    return (
        <SContainer>
            <SRow>
                <SCol span="12">
                    <h1 className="h1-text">Customer and certificates</h1>
                </SCol>
            </SRow>
            <SRow>
                <SCol span="xs-12 sm-12 md-12 3">
                    <button
                        className="ghost-blue margin-bottom-md margin-top-md icon-leading pad-all-none"
                        onClick={() => navigate("/retail/search")}>
                        <SIcon name="arrow-left" aria-label="arrow-left" aria-hidden="true" />
                        Back to search results
                    </button>
                </SCol>
            </SRow>
            <SBox className="cust-info-box-bg margin-bottom-md">{customerContent}</SBox>
            {exemptionContent}
            {isPreviewDialogOpen ? (
                <PreviewDialog
                    isPreviewDialogOpen
                    setIsPreviewDialogOpen={setIsPreviewDialogOpen}
                    certificateID={selectedRow?.certificateId}
                    certificateName={selectedRow?.certificateFileName}
                    isUploaderDisabled={false}
                />
            ) : null}
        </SContainer>
    );
}

export default CustomerDetails;
