import React from "react";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { toTitleCase } from "../../shared/Utils";

function SplitCertSelectExemptionReasons(props, ref) {
    const [getCurrentId, getNextId] = useId();
    const exemptionReasons =
        props.optionsList && props.optionsList.length > 0
            ? props.optionsList.map(element => {
                  return {
                      label: toTitleCase(element.label),
                      value: element.value.toString(),
                      selected: element.value.toString() === props.value,
                  };
              })
            : [];

    return (
        <React.Fragment>
            <label htmlFor={getNextId()} id="lbl-exemptionReason">
                Reason for exemption
            </label>
            <SSelect
                name="exemptionReason"
                inputId={getCurrentId()}
                ref={ref}
                selectionOptional
                optionsList={exemptionReasons}
                multiple={props.multiple}
                loading={props.isLoading}
                className={props.error ? props.error : ""}
                onS-select={e => props.onAdd(e, props.index)}
                onS-deselect={e => props.onRemove(e, props.index)}
                onS-close={e => (props.onClose ? props.onClose(e) : null)}
                disabled={props.disabled}
            />
        </React.Fragment>
    );
}

export default React.forwardRef(SplitCertSelectExemptionReasons);
