import React, { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import axios from "../../../axios";
import { selectCustomer, setCustomer } from "../../../app/customerSlice";
import { buildApiV3Url } from "../../../shared/Utils";
import { parseSuccessResponse, parsePutObjResponse } from "../../../shared/responseUtils";
import { CRUDOperation } from "../../../shared/constants";

function AddEmailDialog(props) {
    const dispatch = useDispatch();
    const [emailAddress, setEmailAddress] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const saveClassName = classnames({ primary: true, small: true, loading: submitting });
    const vendor = useSelector(selectCustomer, shallowEqual);

    const putCustomerAPI = async () => {
        setSubmitting(true);
        const vendorData = {
            customerNumber: vendor.customerNumber,
            name: vendor.name,

            alternateId: vendor.alternateId,
            addressLine1: vendor.addressLine1,
            addressLine2: vendor.addressLine2,
            city: vendor.city,
            zip: vendor.zip.trim(),
            feinNumber: vendor.feinNumber,
            contactName: vendor.contactName,
            phoneNumber: vendor.phoneNumber,
            emailAddress,
            faxNumber: vendor.faxNumber,
            isBillTo: vendor.isBillTo,
            isShipTo: vendor.isShipTo,
            isVendor: true,
            vendorAdditionalInfos: vendor.vendorAdditionalInfos,
            state: vendor.state,
            country: vendor.country,
        };

        const responseVendor = await axios.put(
            buildApiV3Url(`customers/${vendor.id}`),
            vendorData,
            {
                withCredentials: true,
            }
        );
        if (parseSuccessResponse(CRUDOperation.Update, responseVendor)?.isSuccess) {
            const vendorResponseData = parsePutObjResponse(responseVendor);
            dispatch(setCustomer(vendorResponseData));
        }
        setSubmitting(false);
        props.setIsAddEmailDialogOpen(false);
    };
    return (
        <SDialog
            open={props.isAddEmailDialogOpen}
            id="addEmail-dialog"
            onS-dismiss={() => props.setIsAddEmailDialogOpen(false)}
            aria-modal="true">
            <div slot="header" id="addEmail">
                Add an email address
            </div>
            <div slot="body">
                <span className="block margin-bottom-xs">
                    {vendor.name} needs an email address.
                </span>
                <label htmlFor="emailAddress">Email address</label>
                <input id="emailAddress" onChange={e => setEmailAddress(e.target.value.trim())} />
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => {
                        props.setIsAddEmailDialogOpen(false);
                    }}>
                    Cancel
                </button>
                <button
                    className={saveClassName}
                    disabled={!emailAddress}
                    onClick={() => putCustomerAPI()}>
                    Add
                </button>
            </div>
        </SDialog>
    );
}

export default AddEmailDialog;
