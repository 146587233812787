import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectNoData as selectNoCertificateData } from "../../app/certificateSlice";
import {
    selectNoData as selectNoCustomerData,
    selectLoading as selectCustomerLoading,
} from "../../app/customerSlice";
import { selectNoData as selectNoBulkRequestData } from "../../app/requestSlice";
import { selectNoData as selectNoSavedSearchData } from "../../app/savedSearchesSlice";
import { selectNoData as SelectNoSingleRequestData } from "../../app/certificateRequestSlice";
import "./no-data.css";

const NoData = React.memo(props => {
    const noCertificateData = useSelector(selectNoCertificateData);
    const noCustomerData = useSelector(selectNoCustomerData);
    const isLoadingCustomerData = useSelector(selectCustomerLoading);
    const noBulkRequestData = useSelector(selectNoBulkRequestData);
    const noSingleRequestData = useSelector(SelectNoSingleRequestData);
    const noSavedSearchData = useSelector(selectNoSavedSearchData);
    let noDataMsg = null;

    if (props.certificate && noCertificateData) {
        switch (noCertificateData) {
            case "onLanding":
                noDataMsg = (
                    <React.Fragment>
                        <div id="no-data-landing-header">
                            You haven&apos;t added any certificates yet
                        </div>
                        <div id="helper-message">
                            To add your first certificate,
                            <Link to="/search/customers">search for a customer</Link>
                            then click &quot;Add a certificate&quot;. Or, you can
                            <Link to="/import-customers">
                                import all your customers and exemptions using our import template.
                            </Link>
                        </div>
                    </React.Fragment>
                );
                break;
            case "onSearch":
                noDataMsg = (
                    <div className="margin-top-sm" id="no-data-message">
                        <div
                            id="no-certificates-found"
                            className="font-semibold font-normal text-lg">
                            No matching exemption certificates found
                        </div>
                        <div id="filter-message" className="font-semibold font-normal text-sm">
                            Widen the date range or adjust your filters to find more exemption
                            certificates.
                        </div>
                    </div>
                );
                break;
            default:
                noDataMsg = null;
        }
    } else if (noCustomerData && !isLoadingCustomerData) {
        switch (noCustomerData) {
            case "onLanding":
                noDataMsg = (
                    <React.Fragment>
                        <div id="no-data-landing-header">
                            You haven&apos;t added any customers yet
                        </div>
                        <div id="helper-message">
                            <Link to="/add-customer">Add your first customer</Link>
                            {}, or, you can {}
                            <Link to="/import-customers">
                                import all your customers and exemptions using our import template.
                            </Link>
                        </div>
                    </React.Fragment>
                );
                break;
            case "onSearch":
                noDataMsg = (
                    <div className="margin-top-sm">
                        <div id="no-customers-found" className="margin-top-sm">
                            <div className="font-semibold font-normal text-lg">
                                {props.isVendor
                                    ? "No matching vendors found"
                                    : "No matching customers found"}
                            </div>
                        </div>
                    </div>
                );
                break;
            default:
                noDataMsg = null;
        }
    } else if (props.request === "bulkRequests" && noBulkRequestData) {
        switch (noBulkRequestData) {
            case "onLanding":
                noDataMsg = (
                    <React.Fragment>
                        <div id="no-data-message">You have no requests on file</div>

                        <div id="helper-message">
                            <a href="/requests/create" target="_blank" rel="noreferrer">
                                Get started by creating a campaign
                            </a>
                        </div>
                    </React.Fragment>
                );
                break;
            case "onSearch":
                noDataMsg = (
                    <div className="margin-top-sm">
                        <div id="no-customers-found" className="margin-top-sm">
                            <div className="font-semibold font-normal text-lg">
                                No matching requests found
                            </div>
                        </div>
                    </div>
                );
                break;
            case "onCustomerSearch":
                noDataMsg = (
                    <div className="margin-top-sm">
                        <div id="no-customers-found" className="margin-top-sm">
                            <div className="font-semibold font-normal text-lg">
                                No matching customers found
                            </div>
                        </div>
                    </div>
                );
                break;
            case "noResource":
                noDataMsg = (
                    <div className="margin-top-sm">
                        <div id="no-customers-found" className="margin-top-sm">
                            <div className="font-semibold font-normal text-lg">
                                Resource not found
                            </div>
                        </div>
                    </div>
                );
                break;
            default:
                noDataMsg = null;
        }
    } else if (props.request === "singleRequests" && noSingleRequestData) {
        switch (noSingleRequestData) {
            case "onLanding":
                noDataMsg = (
                    <React.Fragment>
                        <div id="no-data-message">You have no requests on file</div>

                        <div id="helper-message">
                            <a href="/requests/create" target="_blank" rel="noreferrer">
                                Get started by creating a campaign
                            </a>
                        </div>
                    </React.Fragment>
                );
                break;
            case "onSearch":
                noDataMsg = (
                    <div className="margin-top-sm">
                        <div id="no-customers-found" className="margin-top-sm">
                            <div className="font-semibold font-normal text-lg">
                                No matching requests found
                            </div>
                        </div>
                    </div>
                );
                break;
            case "onCustomerSearch":
                noDataMsg = (
                    <div className="margin-top-sm">
                        <div id="no-customers-found" className="margin-top-sm">
                            <div className="font-semibold font-normal text-lg">
                                No matching customers found
                            </div>
                        </div>
                    </div>
                );
                break;
            case "noResource":
                noDataMsg = (
                    <div className="margin-top-sm">
                        <div id="no-customers-found" className="margin-top-sm">
                            <div className="font-semibold font-normal text-lg">
                                Resource not found
                            </div>
                        </div>
                    </div>
                );
                break;
            default:
                noDataMsg = null;
        }
    } else if (props.savedSearch && noSavedSearchData) {
        switch (noSavedSearchData) {
            case "onLanding":
                noDataMsg = <div id="no-data-landing-header">You have no saved searches</div>;
                break;
            case "onSearch":
                noDataMsg = (
                    <div className="margin-top-sm">
                        <div id="no-savedSearch-found" className="margin-top-sm">
                            <div className="font-semibold font-normal text-lg">
                                No matching saved searches found
                            </div>
                        </div>
                    </div>
                );
                break;
            default:
                noDataMsg = null;
        }
    } else if (props.retailCustomer || props.retailCustomerForm) {
        noDataMsg = (
            <div className="margin-all-xl">
                <div
                    id="no-certificate-request-found"
                    className={
                        props.retailCustomerForm ? "color-gray-dark" : "text-center color-gray-dark"
                    }>
                    <div className="font-semibold font-normal text-lg">No customer found</div>
                    <div>Adjust your search or add a new customer.</div>
                </div>
            </div>
        );
    }
    return noDataMsg;
});

export default NoData;
