import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SRow, SCol, SInputExtended, SIcon } from "@avalara/skylab-react";
import { fetchDuplicateCustomers, selectPage } from "../../../app/customerDuplicateSlice";
import DuplicateCustomerDialog from "./DuplicateCustomerDialog";

const DuplicateCustomersFilter = React.memo(props => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    const paginateData = useSelector(selectPage).paginate;

    useEffect(() => {
        dispatch(fetchDuplicateCustomers(props.customerId));
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchValue.length >= 1) {
                dispatch(fetchDuplicateCustomers(props.customerId, searchValue));
            } else {
                dispatch(fetchDuplicateCustomers(props.customerId));
            }
        }, 400);
        return () => clearTimeout(timeoutId);
    }, [dispatch, searchValue]);

    const duplicateCustomerDialogDOM = (
        <DuplicateCustomerDialog
            isDuplicateCustomerDialogOpen={props.isDuplicateCustomerDialogOpen}
            setIsDuplicateCustomerDialogOpen={props.setIsDuplicateCustomerDialogOpen}
            customerId={props.customerId}
        />
    );

    const duplicateCustomerFilterDOM =
        paginateData.totalRecords !== 0 ? (
            <React.Fragment>
                <SRow className="margin-top-md">
                    <SCol span="3" className="pad-bottom-none">
                        <label htmlFor="nameOrCode">Search customer name or code</label>
                        <SInputExtended
                            inputId="nameOrCode"
                            aria-label="search"
                            type="search"
                            onS-clear={() => {
                                setSearchValue("");
                            }}
                            onS-input={e => {
                                setSearchValue(e.detail.value);
                            }}
                            onS-search={e => {
                                setSearchValue(e.detail.value);
                            }}
                            value={searchValue}
                        />
                    </SCol>
                    <SCol span="7" />
                    <SCol span="2">
                        <button
                            id="link-duplicate-customer"
                            className="primary inline right margin-top-md"
                            onClick={() => props.setIsDuplicateCustomerDialogOpen(true)}>
                            <SIcon
                                name="link"
                                className="margin-right-xs"
                                aria-label="Link duplicate customer"
                            />
                            Link duplicate customer
                        </button>
                    </SCol>
                </SRow>

                {duplicateCustomerDialogDOM}
            </React.Fragment>
        ) : (
            <div>{duplicateCustomerDialogDOM}</div>
        );
    return duplicateCustomerFilterDOM;
});

export default DuplicateCustomersFilter;
