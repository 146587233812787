import { SLoader, SPagination } from "@avalara/skylab-react";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import {
    fetchCertificateHistoryAPI,
    selectIsLoading,
    selectPage,
    selectSortColumn,
    setSortColumn,
} from "../../app/certificateHistorySlice";
import { selectSession, updateRowCountPreference } from "../../app/sessionSlice";
import { getRowsPerPage } from "../../shared/Utils";
import { StatementOfTaxability, Taxable } from "../../shared/constants";

const ValidationHistory = React.memo(() => {
    const params = useParams();
    const dispatch = useDispatch();
    const session = useSelector(selectSession, shallowEqual);
    const [submitting, setSubmitting] = useState(false);
    const page = useSelector(selectPage);
    const loading = useSelector(selectIsLoading);
    const pageData = page.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectSortColumn);
    let { certificateID: certificateIdFromURL } = params;
    certificateIdFromURL = !Number.isNaN(Number(certificateIdFromURL))
        ? Number(certificateIdFromURL)
        : 0;

    useEffect(() => {
        if (certificateIdFromURL) dispatch(fetchCertificateHistoryAPI(certificateIdFromURL));
    }, [dispatch, certificateIdFromURL]);

    const parseLogData = (oldValue, newValue, field) => {
        const getModifiedValue = value => {
            if (value === null || value === undefined) {
                return value;
            }
            return value.toLowerCase() === Taxable ? StatementOfTaxability : value;
        };
        let modifiedOldValue;
        let modifiedNewValue;
        if (
            (field !== null || field !== undefined) &&
            (field === "Documented Exempt Reason" || field === "Exempt Reason")
        ) {
            modifiedOldValue = getModifiedValue(oldValue);
            modifiedNewValue = getModifiedValue(newValue);
        } else {
            modifiedOldValue = oldValue;
            modifiedNewValue = newValue;
        }
        let [finalLogRow, text, pText] = "";
        if (modifiedOldValue && modifiedNewValue) {
            if (field === "Tax Id" || field === "Tax Number") {
                text = `changed`;
            } else {
                text = `changed from ${modifiedOldValue} to ${modifiedNewValue}`;
            }
        } else if (modifiedOldValue) {
            if (field === "Tax Id" || field === "Tax Number") {
                text = `removed`;
            } else {
                text = `removed: ${modifiedOldValue}`;
            }
        } else if (modifiedNewValue) {
            if (field === "Tax Id" || field === "Tax Number") {
                text = `added`;
            } else {
                text = `added: ${modifiedNewValue}`;
            }
        } else {
            text = "Missing or invalid history data";
        }
        if (field) {
            pText = `${field} ${parse(text)}`;
        } else {
            pText = `${parse(text)}`;
        }

        finalLogRow = <span>{pText}</span>;
        return finalLogRow;
    };

    async function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn.includes("ASC") ? "DESC" : "ASC";
        dispatch(setSortColumn(`${column} ${order}`));
        await dispatch(
            fetchCertificateHistoryAPI(
                params?.certificateID,
                null,
                e.detail.rowsPerPage,
                e.detail.startIndex,
                false
            )
        );
        setSubmitting(false);
    }

    const columns = [
        gridHeader(
            "Date and time",
            "created",
            value =>
                `${new Date(value).toLocaleDateString()} ${new Date(value).toLocaleTimeString()}`,
            null,
            "",
            null,
            null,
            sortFunction,
            "created"
        ),
        gridHeader("By", "account", (value, row) => row.account, null),
        gridHeader(
            "Activity",
            "event",
            (value, row) => (
                <React.Fragment>
                    {parseLogData(row.oldValue, row.newValue, row.field)}
                </React.Fragment>
            ),
            null
        ),
    ];

    const getRowKey = row => {
        return `${row.field}-${row.id}-${row.logType}`;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            await dispatch(
                fetchCertificateHistoryAPI(
                    params?.certificateID,
                    null,
                    e.detail.rowsPerPage,
                    e.detail.startIndex,
                    false,
                    e.detail
                )
            );
            setSubmitting(false);
        }
    };

    let data = null;
    if (pageData.pageNumber !== 0) {
        data = loading ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <s-table-container class="margin-top-xl">
                <DataGrid
                    columns={columns}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={submitting}
                    inContainer
                    sortColumn={sortColumn}
                />
            </s-table-container>
        );
    }

    return (
        <React.Fragment>
            {data}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    startIndex={paginateData.startIndex}
                    rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                    totalRecords={paginateData.totalRecords}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}
        </React.Fragment>
    );
});
export default ValidationHistory;
