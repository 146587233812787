import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { checkSelectComponentProps } from "../../../shared/Utils";

import {
    fetchExpirationMatrixExposureZones,
    selectIsLoadingMatrixExposureZones,
    selectMatrixExposureZoneData,
} from "../../../app/exemptionMatrixSlice";

const SelectVendorZone = React.memo(props => {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingMatrixExposureZones);
    const selectRef = React.createRef();

    const vendorRegions = useSelector(selectMatrixExposureZoneData).map(element => {
        const selectOption = {
            label: element.region.name,
            value: element.region.id,
            selected: false,
        };
        if (props?.value === element.region.id) {
            selectOption.selected = true;
        }
        return selectOption;
    });

    checkSelectComponentProps("SelectVendorRegion", props);

    useEffect(() => {
        dispatch(fetchExpirationMatrixExposureZones());
    }, [dispatch]);

    return (
        <React.Fragment>
            <label htmlFor={getNextId()} className={props.labelClass} id="lbl-vendorRegion">
                Region covered by this certificate
            </label>
            <SSelect
                name="vendorRegion"
                inputId={getCurrentId()}
                selectionOptional
                ref={selectRef}
                optionsList={vendorRegions}
                loading={isLoading}
                onS-select={e => props.onAdd(e.detail.item)}
                onS-deselect={e => props.onRemove(e.detail.item)}
                className={props.zoneError}
            />
        </React.Fragment>
    );
});
export default SelectVendorZone;
