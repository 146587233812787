import React, { useState } from "react";
import { SDialog } from "@avalara/skylab-react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { selectIsDeleting, deleteInvalidReasonAsync } from "../../../app/invalidReasonSlice";
import { parseSuccessResponse, cannotDeleteRecordResponse } from "../../../shared/responseUtils";
import { CRUDOperation } from "../../../shared/constants";

const DeleteInvalidReasonDialog = React.memo(props => {
    const dialogName = "DeleteInvalidReasonWarningDialog";
    const dispatch = useDispatch();
    const isDeleting = useSelector(selectIsDeleting);
    const [isInUse, setIsInUse] = useState(false);

    const deleteButton = classnames({
        primary: true,
        small: true,
        loading: isDeleting,
    });

    const handleDelete = async event => {
        event.preventDefault();
        const response = await dispatch(
            deleteInvalidReasonAsync(props.invalidReason.id, props.invalidReason.name)
        );
        if (parseSuccessResponse(CRUDOperation.Delete, response)?.isSuccess) {
            setIsInUse(false);
            props.setIsDeleteDialogOpen(false);
        } else if (cannotDeleteRecordResponse(response)) {
            setIsInUse(true);
        } else {
            props.setIsDeleteDialogOpen(false);
        }
    };

    return (
        <>
            {!isInUse ? (
                <SDialog
                    id={dialogName}
                    open={props.isDeleteDialogOpen}
                    onS-dismiss={() => props.setIsDeleteDialogOpen(false)}
                    aria-modal="true">
                    <div slot="header" id="deleteInvalidReason">
                        Are you sure you want to delete this invalid reason?
                    </div>
                    <div slot="body">
                        You can&apos;t undo this action, but you can recreate this invalid reason
                        later.
                    </div>
                    <div slot="footer">
                        <button
                            className="secondary small"
                            onClick={() => props.setIsDeleteDialogOpen(false)}>
                            Cancel
                        </button>
                        <button className={deleteButton} onClick={handleDelete}>
                            Yes, I&apos;m sure
                        </button>
                    </div>
                </SDialog>
            ) : (
                <SDialog
                    id="isInUseDialog"
                    open={props.isDeleteDialogOpen}
                    onS-dismiss={() => props.setIsDeleteDialogOpen(false)}
                    aria-modal="true">
                    <div slot="header" id="inUseReason">
                        Can&apos;t delete this reason?
                    </div>
                    <div slot="body">
                        <div>
                            This invalid reason has been applied to one or more certificates in this
                            company. You must remove
                        </div>
                        <div>
                            this invalid reason from all certificates before it can be deleted.
                        </div>
                    </div>
                    <div slot="footer">
                        <button
                            className="secondary small"
                            onClick={() => props.setIsDeleteDialogOpen(false)}>
                            Back
                        </button>
                    </div>
                </SDialog>
            )}
            <div />
        </>
    );
});

export default DeleteInvalidReasonDialog;
