import React from "react";
import axios from "axios";
import { SToasts } from "@avalara/skylab-react";
import { logger } from "../../shared/Logger";
import axiosObject from "../../axios";
import ErrorPage from "./ErrorPage";
import store from "../../app/store";
import getConfig from "../../config";
import { errorResponse } from "../../shared/responseUtils";
import { getMessage } from "./errorBoundaryUtils";

const { hostnames } = getConfig();
const { ecmApiHost } = hostnames;

window.addEventListener("error", async event => {
    const params = {
        message: event.message,
        filename: event.filename,
        lineno: event.lineno,
        colno: event.colno,
    };
    await logger(2, 4, params);
});

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this.errorHandler = async error => {
            let params = {};
            if (error?.request) {
                params = {
                    method: error?.config?.method,
                    url: error?.config?.url,
                    status: error?.request?.status,
                    statusText: error?.request?.statusText,
                };
            } else if (error?.response) {
                params = {
                    method: error?.config?.method,
                    url: error?.config?.url,
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                };
            }
            await logger(1, 4, params);
        };
    }

    componentDidMount() {
        // Set axios interceptors
        const api = `/api/`;
        this.requestInterceptor = axiosObject.interceptors.request.use(
            req => {
                this.setState({ hasError: false });
                const { session } = store.getState();
                if (req.url.includes("session")) {
                    return req;
                }

                const urlSplit = req.url.split(api);
                const version = urlSplit[1].split("/")[0];
                const methodName = urlSplit[1].replace(version, "");
                req.url = `${urlSplit[0]}${api}${version}/companies/${session.activeCompany.id}${methodName}`;

                return req;
            },
            async error => {
                this.setState({ hasError: true });
                await this.errorHandler(error);
                return Promise.reject(error);
            }
        );

        this.responseInterceptor = axiosObject.interceptors.response.use(
            async res => {
                return res;
            },
            async error => {
                const traceId = error?.response?.headers["x-trace-id"] ?? null;
                // Stops poller if there is an API error
                if (
                    error?.config?.method === "get" &&
                    error?.response?.request?.status === 500 &&
                    error?.response?.request?.responseURL.includes("auto-validation-result")
                ) {
                    window.location.reload();
                }

                const parseError = errorResponse(error);

                if (error.response && error.response.status && error.response.status === 401) {
                    await axios
                        .get(`//${ecmApiHost}/api/v3/session`, {
                            withCredentials: true,
                        })
                        .then(async sessionResponse => {
                            if (!(sessionResponse.data && sessionResponse.data.isAuthenticated)) {
                                window.location = `//${ecmApiHost}/api/v3/auth/login?redirectUrl=${window.location.href}`;
                            } else {
                                this.setState({ hasError: true, status: error.response.status });
                                await this.errorHandler(error);
                            }
                        })
                        .catch(async () => {
                            this.setState({ hasError: true, status: error.response.status });
                        });
                } else if (parseError && parseError?.code) {
                    const responseObj = getMessage(error);
                    if (responseObj?.responseMessage) {
                        return responseObj?.responseMessage;
                    }
                    if (responseObj) {
                        return responseObj;
                    }
                    if (parseError?.status === 500) {
                        this.setState({
                            hasError: true,
                            status: parseError?.status,
                            traceId,
                        });
                        await this.errorHandler(error);
                    }
                    return error;
                } else {
                    this.setState({ hasError: true, status: error?.response?.status, traceId });
                    await this.errorHandler(error);
                }
                return Promise.reject(error);
            }
        );
    }

    componentDidCatch() {
        // It will catch error in any component below.
        this.setState({
            hasError: true,
        });
    }

    componentWillUnmount() {
        // Remove handlers, so Garbage Collector will get rid of if WrappedComponent will be removed
        axiosObject.interceptors.request.eject(this.requestInterceptor);
        axiosObject.interceptors.response.eject(this.responseInterceptor);
    }

    static getDerivedStateFromError() {
        // It will update the state so the next render shows the fallback UI.
        return { hasError: true };
    }

    render() {
        <SToasts role="status" />;
        if (this?.state?.hasError) {
            return <ErrorPage type={this?.state?.status} traceId={this?.state?.traceId} />;
        }
        return this?.props?.children;
    }
}

export default ErrorBoundary;
