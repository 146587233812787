import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { SCol, SRow } from "@avalara/skylab-react";
import { useNavigate } from "react-router-dom";
import RegionGrid from "./RegionGrid";
import RegionFilters from "./RegionFilters";
import { fetchRegionsWithNexusDetailsAPI } from "../../../app/regionSlice";
import AddEditRegionDialog from "./AddEditRegionDialog";

function Region() {
    const [isAddEditRegionDialogOpen, setIsAddEditRegionDialogOpen] = useState(false);
    const [source, setSource] = useState("");
    const [currentRow, setCurrentRow] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchRegionsWithNexusDetailsAPI());
    }, []); // eslint-disable-line
    const navigate = useNavigate();
    const backToAdvancedSettings = () => {
        navigate("/settings/advanced-settings");
    };
    return (
        <>
            <SRow className="pad-bottom-sm">
                <SCol>
                    <h1 id="page-header" className="inline-block">
                        Where you collect certificates
                    </h1>
                </SCol>
                <SCol span="5">
                    View and manage the regions where you collect exemption certificates. These
                    regions are sometimes referred to as exposure zones. ECM doesn’t monitor
                    inactive regions for missing or invalid certificates.
                </SCol>
            </SRow>
            <RegionFilters
                setIsAddEditRegionDialogOpen={setIsAddEditRegionDialogOpen}
                isAddEditRegionDialogOpen={isAddEditRegionDialogOpen}
                setSource={setSource}
            />
            <RegionGrid
                setIsAddEditRegionDialogOpen={setIsAddEditRegionDialogOpen}
                setCurrentRow={setCurrentRow}
                setSource={setSource}
            />
            {isAddEditRegionDialogOpen ? (
                <AddEditRegionDialog
                    isAddEditRegionDialogOpen={isAddEditRegionDialogOpen}
                    setIsAddEditRegionDialogOpen={setIsAddEditRegionDialogOpen}
                    region={currentRow}
                    source={source}
                />
            ) : null}
            <SRow className="pad-top-sm">
                <SCol>
                    <button className="tertiary" type="button" onClick={backToAdvancedSettings}>
                        Back to settings
                    </button>
                </SCol>
            </SRow>
        </>
    );
}

export default Region;
