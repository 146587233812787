import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import { SDialog, SLoader, SAlert, SIcon, SRow, SCol, SInputExtended } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import {
    fetchBulkUploadChildCertificates,
    selectChildLoading,
    selectChildData,
} from "../../app/bulkUploadSlice";
import BulkUploadStatus from "../sharedComponents/BulkUploadStatus";

function BulkUploadCertificateViewDialog(props) {
    const dispatch = useDispatch();
    const loading = useSelector(selectChildLoading);
    const childData = useSelector(selectChildData, shallowEqual);
    const [localData, setLocalData] = useState(childData);
    const [searchedValue, setSearchedValue] = useState("");

    useEffect(() => {
        (async () => {
            const response = await dispatch(fetchBulkUploadChildCertificates(props.parentId, ""));
            setLocalData(response);
        })();
    }, [dispatch, props.parentId]);
    const columns = [
        gridHeader(
            "Certificate Id",
            "certificateId",
            value =>
                value !== 0 ? (
                    <a href={`${window.location.origin}/review/${value}`}>{value}</a>
                ) : null,
            null,
            ""
        ),
        gridHeader("File name", "filename", value => value, null, ""),
        gridHeader("File Type", "contentType", value => value, null, ""),
        gridHeader(
            "Created",
            "created",
            value => (value ? new Date(value).toLocaleDateString() : ""),
            null,
            ""
        ),
        gridHeader(
            "Status",
            "status",
            (value, row) => (
                <div>
                    <BulkUploadStatus data={row} />
                </div>
            ),
            null,
            ""
        ),
    ];

    const getRowKey = useCallback(() => {
        return uuidv4();
    }, []);

    const handleSearch = value => {
        setSearchedValue(value);
        if (value.length === 0) setLocalData(childData);
        setLocalData(
            childData.filter(child =>
                child?.filename?.toLowerCase()?.includes(value.trim().toLocaleLowerCase())
            )
        );
    };

    const refreshDialog = async () => {
        const response = await dispatch(
            fetchBulkUploadChildCertificates(props.parentId, searchedValue)
        );
        setLocalData(response);
    };

    const clearSearch = async () => {
        setSearchedValue("");
        const response = await dispatch(fetchBulkUploadChildCertificates(props.parentId, ""));
        setLocalData(response);
    };

    const dialogClose = () => {
        props.setIsBulkUploadDialogOpen(false);
    };

    let table = null;
    if (loading) {
        table = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="dialog-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    } else if (childData.length === 0 && props.fileStatus?.toLowerCase() === "processing") {
        table = (
            <SAlert status="info" role="alert" noDismiss>
                <div>
                    We are processing your zip file. The certificates will be available post
                    processing.
                </div>
            </SAlert>
        );
    } else if (childData.length !== 0) {
        table =
            localData.length !== 0 ? (
                <s-table-container>
                    <DataGrid columns={columns} rows={localData} getKey={getRowKey} inContainer />
                </s-table-container>
            ) : (
                <h2>No matching certificates found.</h2>
            );
    } else {
        table = (
            <SAlert status="error" role="alert" noDismiss>
                <div>No certificates were found in this zip.</div>
            </SAlert>
        );
    }

    return (
        <SDialog
            open={props.isBulkUploadDialogOpen}
            id="bulkUploadCertView-dialog"
            nodismiss
            aria-modal="true">
            <div slot="header" id="bulkUploadCertView" className="inline">
                <SRow>
                    <SCol span="7" className="text-left">
                        <SInputExtended
                            autofocus
                            inputId="zip-certificate-file-search"
                            aria-label="Search by filename"
                            type="search"
                            value={searchedValue}
                            placeholder="Search by filename"
                            onS-clear={() => {
                                clearSearch();
                            }}
                            onS-input={e => {
                                handleSearch(e.detail.value);
                            }}
                            onS-search={e => {
                                handleSearch(e.detail.value);
                            }}
                        />
                    </SCol>
                    <SCol span="5" className="text-right align-self-center">
                        <button className="tertiary inline" onClick={refreshDialog}>
                            <SIcon name="refresh" aria-hidden="true" /> Refresh
                        </button>
                    </SCol>
                </SRow>
            </div>
            <div slot="body">{table}</div>
            <div slot="footer">
                <button className="tertiary inline" onClick={dialogClose}>
                    Close
                </button>
            </div>
        </SDialog>
    );
}

export default BulkUploadCertificateViewDialog;
