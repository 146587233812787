const defaultEntityUseCodes = [
    { label: "Resale", value: "RESALE" },
    { label: "Direct Pay", value: "DIRECT PAY" },
    { label: "Direct Mail", value: "DIRECT MAIL" },
    { label: "Other/Custom", value: "OTHER/CUSTOM" },
];

const BusinessTypeOptions = [
    {
        label: "Accommodation and food services",
        value: "Accommodation and food services",
        number: 1,
        values: defaultEntityUseCodes,
    },
    {
        label: "Agricultural, forestry, fishing, hunting",
        value: "Agricultural, forestry, fishing, hunting",
        number: 2,
        values: [...defaultEntityUseCodes, { label: "Agriculture", value: "AGRICULTURE" }],
    },
    {
        label: "Construction",
        value: "Construction",
        number: 3,
        values: defaultEntityUseCodes,
    },
    {
        label: "Finance and insurance",
        value: "Finance and insurance",
        number: 4,
        values: [
            ...defaultEntityUseCodes,
            { label: "Industrial Prod/Manufacturers", value: "INDUSTRIAL PROD/MANUFACTURERS" },
        ],
    },
    {
        label: "Information, publishing and communications",
        value: "Information, publishing and communications",
        number: 5,
        values: [
            ...defaultEntityUseCodes,
            { label: "Industrial Prod/Manufacturers", value: "INDUSTRIAL PROD/MANUFACTURERS" },
        ],
    },
    {
        label: "Manufacturing",
        value: "Manufacturing",
        number: 6,
        values: [
            ...defaultEntityUseCodes,
            { label: "Industrial Prod/Manufacturers", value: "INDUSTRIAL PROD/MANUFACTURERS" },
        ],
    },
    {
        label: "Mining",
        value: "Mining",
        number: 7,
        values: [
            ...defaultEntityUseCodes,
            { label: "Industrial Prod/Manufacturers", value: "INDUSTRIAL PROD/MANUFACTURERS" },
        ],
    },
    { label: "Real estate", value: "Real estate", number: 8, values: defaultEntityUseCodes },
    {
        label: "Rental and leasing",
        value: "Rental and leasing",
        number: 9,
        values: defaultEntityUseCodes,
    },
    { label: "Retail trade", value: "Retail trade", number: 10, values: defaultEntityUseCodes },
    {
        label: "Transportation and warehousing",
        value: "Transportation and warehousing",
        number: 11,
        values: defaultEntityUseCodes,
    },
    {
        label: "Utilities",
        value: "Utilities",
        number: 12,
        values: [
            ...defaultEntityUseCodes,
            { label: "State Gov", value: "STATE GOV" },
            { label: "Industrial Prod/Manufacturers", value: "INDUSTRIAL PROD/MANUFACTURERS" },
        ],
    },
    {
        label: "Wholesale trade",
        value: "Wholesale trade",
        number: 13,
        values: defaultEntityUseCodes,
    },
    {
        label: "Business services",
        value: "Business services",
        number: 14,
        values: defaultEntityUseCodes,
    },
    {
        label: "Professional services",
        value: "Professional services",
        number: 15,
        values: defaultEntityUseCodes,
    },
    {
        label: "Education and health-care services",
        value: "Education and health-care services",
        number: 16,
        values: [
            { label: "State Gov", value: "STATE GOV" },
            { label: "Charitable/Exempt Org", value: "CHARITABLE/EXEMPT ORG" },
            { label: "Religious Org", value: "RELIGIOUS ORG" },
            { label: "Resale", value: "RESALE" },
            { label: "Educational Org", value: "EDUCATIONAL ORG" },
            { label: "Other/Custom", value: "OTHER/CUSTOM" },
        ],
    },
    {
        label: "Nonprofit organization",
        value: "Nonprofit organization",
        number: 17,
        values: [
            { label: "Charitable/Exempt Org", value: "CHARITABLE/EXEMPT ORG" },
            { label: "Religious Org", value: "RELIGIOUS ORG" },
            { label: "Other/Custom", value: "OTHER/CUSTOM" },
        ],
    },
    {
        label: "Government",
        value: "Government",
        number: 18,
        values: [
            { label: "Federal Gov", value: "FEDERAL GOV" },
            { label: "State Gov", value: "STATE GOV" },
            { label: "Tribal Government", value: "TRIBAL GOVERNMENT" },
            { label: "Foreign Diplomat", value: "FOREIGN DIPLOMAT" },
            { label: "Agriculture", value: "AGRICULTURE" },
            { label: "Local Government", value: "LOCAL GOVERNMENT" },
            { label: "Other/Custom", value: "OTHER/CUSTOM" },
        ],
    },
    {
        label: "Not A Business",
        value: "Not A Business",
        number: 19,
        values: [
            { label: "Direct Mail", value: "DIRECT MAIL" },
            { label: "Other/Custom", value: "OTHER/CUSTOM" },
        ],
    },
    { label: "Other", value: "Other", number: 20, values: defaultEntityUseCodes },
];

export default BusinessTypeOptions;
