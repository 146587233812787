import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { uniqBy } from "lodash";
import { useId } from "@certcapture/react-components";
import {
    selectExemptionMatrixValidation,
    selectIsLoadingExemptionMatrix,
} from "../../app/certificateSlice";
import { errorTag, noErrorTag, toTitleCase } from "../../shared/Utils";

function SelectExemptionReasonBasedOnRegion(props) {
    const [getCurrentId, getNextId] = useId();
    const isLoadingExemptionReasons = useSelector(selectIsLoadingExemptionMatrix);
    const exemptionReasons = useSelector(selectExemptionMatrixValidation, shallowEqual).map(
        element => ({
            label: toTitleCase(element.exemptReason.name),
            value: element.exemptReason.name.toUpperCase(),
            selected: element.exemptReason.name.toUpperCase() === props.value,
        })
    );
    return (
        <>
            <label htmlFor={getNextId()} id="lbl-exemptReason">
                Reason for the exemption
            </label>
            <SSelect
                name="entityExemptReason"
                inputId={getCurrentId()}
                selectionOptional
                multiple={props.multiple}
                optionsList={uniqBy(exemptionReasons, "label")}
                loading={isLoadingExemptionReasons}
                onS-select={e => props.onAdd(e)}
                onS-deselect={e => props.onRemove(e)}
                disabled={props?.disableExemptReason ? "disabled" : null}
                className={props.error ? errorTag : noErrorTag}
            />
        </>
    );
}

export default React.memo(SelectExemptionReasonBasedOnRegion);
