import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildApiV3UrlWithQuery, buildApiV3Url } from "../shared/Utils";
import {
    parseSuccessResponse,
    parseGetAllObjResponse,
    cannotDeleteRecordResponse,
    parseResponseMessage,
} from "../shared/responseUtils";
import toast from "../hooks/toast";
import { CRUDOperation } from "../shared/constants";

const initialState = {
    isLoading: false,
    isDeleting: false,
    isAddOrEditLoading: false,
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    sortColumn: ["name", true],
    formState: {
        name: "",
    },
    cannotDeleteRecordMessage: "",
};

export const certificateLabelSlice = createSlice({
    name: "certificateLabel",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setIsDeleting: (state, action) => {
            state.isDeleting = action.payload;
        },
        setIsAddOrEditLoading: (state, action) => {
            state.isAddOrEditLoading = action.payload;
        },
        setEnableLoading: (state, action) => {
            state.enableLoading = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        setCannotDeleteRecordMessage: (state, action) => {
            state.cannotDeleteRecordMessage = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
    },
});

const [showToast] = toast();
export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setFormState,
    clearFormState,
    setIsDeleting,
    setIsAddOrEditLoading,
    setEnableLoading,
    setSortColumn,
    setCannotDeleteRecordMessage,
} = certificateLabelSlice.actions;

export const selectIsLoading = state => {
    return state.certificateLabel.isLoading;
};
export const selectPage = state => {
    return state.certificateLabel.page;
};
export const selectFormState = state => {
    return state.certificateLabel.formState;
};
export const selectIsDeleting = state => {
    return state.certificateLabel.isDeleting;
};
export const selectIsAddOrEditLoading = state => {
    return state.certificateLabel.isAddOrEditLoading;
};
export const selectEnableLoading = state => {
    return state.certificateLabel.enableLoading;
};
export const selectSortColumn = state => {
    return state.certificateLabel.sortColumn;
};
export const selectCannotDeleteRecordMessage = state => {
    return state.certificateLabel.cannotDeleteRecordMessage;
};
export const fetchCertificateLabels =
    (top, skip, paginateDetails) => async (dispatch, getState) => {
        const { isLoading, sortColumn, formState } = getState().certificateLabel;
        const { rowsPerPage } = getState().session;
        top = !top ? rowsPerPage : top;
        const { name } = formState;
        if (isLoading) {
            return;
        }
        let filterString = "";
        dispatch(setIsLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
        if (name) {
            filterString = `name contains '${name}' `;
        }

        const apiURI = buildApiV3UrlWithQuery(
            `certificate-labels`,
            filterString,
            null,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });
        if (!parseSuccessResponse(CRUDOperation.ReadAll, response)?.recordFound) {
            dispatch(setPageToNull());
        } else {
            const readAllResponse = parseGetAllObjResponse(response);
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails?.pageNumber,
                      startIndex: paginateDetails?.startIndex,
                      endIndex: paginateDetails?.endIndex,
                      rowsPerPage: paginateDetails?.rowsPerPage,
                      totalRecords: readAllResponse?.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: readAllResponse?.count,
                  };
            dispatch(
                setPage({
                    data: readAllResponse?.value,
                    paginate: paginateData,
                })
            );
        }
        dispatch(setIsLoading(false));
    };

export const deleteCertificateLabel = id => async (dispatch, getState) => {
    const { isDeleting } = getState().certificateLabel;
    let successResponse = true;

    if (isDeleting) {
        return false;
    }
    dispatch(setIsDeleting(true));
    const response = await axios.delete(buildApiV3Url(`certificate-labels/${id}`), {
        withCredentials: true,
    });
    if (cannotDeleteRecordResponse(response)) {
        dispatch(setCannotDeleteRecordMessage(parseResponseMessage(response)));
        successResponse = false;
    } else if (parseSuccessResponse(CRUDOperation.Delete, response)?.isSuccess) {
        showToast("success", "Label deleted.", "deleteCertificateLabelToast", false);
        dispatch(fetchCertificateLabels());
        dispatch(setCannotDeleteRecordMessage(""));
    }
    dispatch(setIsDeleting(false));
    return successResponse;
};

export const addOrUpdateCertificateLabels =
    (addOrEdit, payload, certificateLabelId) => async dispatch => {
        dispatch(setIsAddOrEditLoading(true));
        let success = false;
        if (addOrEdit === "Add") {
            await axios
                .post(buildApiV3Url(`certificate-labels`), payload, {
                    withCredentials: true,
                })
                .then(x => {
                    if (parseSuccessResponse(CRUDOperation.Create, x)?.isSuccess) {
                        showToast("success", `Label added.`, "label-success-toast", false);
                        success = true;
                        dispatch(fetchCertificateLabels());
                    } else {
                        success = false;
                    }
                });
        } else {
            await axios
                .put(buildApiV3Url(`certificate-labels/${certificateLabelId}`), payload, {
                    withCredentials: true,
                })
                .then(x => {
                    if (parseSuccessResponse(CRUDOperation.Update, x)?.isSuccess) {
                        showToast(
                            "success",
                            `Successfully updated certificate label`,
                            "certificate-label-success-toast",
                            false
                        );
                        success = true;
                        dispatch(fetchCertificateLabels());
                    } else {
                        success = false;
                    }
                });
        }
        dispatch(setIsAddOrEditLoading(false));
        return success;
    };

export const putCertificateLabels = (payload, certificateLabelId) => async dispatch => {
    dispatch(setIsAddOrEditLoading(true));
    let success = false;
    await axios
        .put(buildApiV3Url(`certificate-labels/${certificateLabelId}`), payload, {
            withCredentials: true,
        })
        .then(x => {
            if (parseSuccessResponse(CRUDOperation.Update, x)?.isSuccess) {
                showToast(
                    "success",
                    `Successfully updated the label`,
                    "certificate-label-success-toast",
                    false
                );
                success = true;
                dispatch(fetchCertificateLabels());
            } else {
                success = false;
            }
        });
    dispatch(setIsAddOrEditLoading(false));
    return success;
};

export default certificateLabelSlice.reducer;
