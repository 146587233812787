import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { SRow, SCol } from "@avalara/skylab-react";
import { fetchTaxAnswers } from "../../../app/contentSlice";
import TaxAnswersGrid from "./TaxAnswersGrid";
import { getPageTitle } from "../../../shared/sessionUtility";
import SelectState from "../../sharedComponents/SelectState";

export const selectorLabel = "Region";
export const header = "Avalara tax answers";

const TaxAnswers = React.memo(() => {
    const dispatch = useDispatch();
    const [selectedStateInitial, setStateInitial] = useState("AL");

    useEffect(() => {
        dispatch(fetchTaxAnswers(selectedStateInitial));
    }, [dispatch]);

    const handleStateChange = value => {
        if (value && selectedStateInitial !== value.initials) {
            setStateInitial(value.initials);
            dispatch(fetchTaxAnswers(value.initials));
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Tax Content"))}</title>
            </Helmet>
            <div>
                <h1 className="line-height-md pad-bottom-sm">{header}</h1>

                <SRow>
                    <SCol span={3}>
                        <SelectState
                            onAdd={handleStateChange}
                            multiple={false}
                            disabled={false}
                            value={selectedStateInitial}
                            USStates
                            label={selectorLabel}
                            selectionOptional={false}
                        />
                    </SCol>
                </SRow>
                <TaxAnswersGrid />
            </div>
        </React.Fragment>
    );
});

export default TaxAnswers;
