import React from "react";
import { STabs } from "@avalara/skylab-react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

const Labels = React.memo(() => {
    const location = useLocation();
    const locationPath = location.pathname.toString();
    const navigate = useNavigate();

    const tabItems = [
        {
            id: "tabpanel-settings-labels-certificateLabels",
            path: "/settings/labels/certificate-labels",
            label: "Certificates",
            tabPanelId: "tabpanel-settings-labels-certificateLabels",
            selected: locationPath.includes("certificate-labels"),
        },
        {
            id: "tabpanel-settings-labels-CustomerLabels",
            path: "/settings/labels/customer-labels",
            label: "Customers",
            tabPanelId: "tabpanel-settings-labels-CustomerLabels",
            selected: locationPath.includes("customer-labels"),
        },
    ];

    const navigateTo = to => {
        // eslint-disable-next-line
        const currentHistory = locationPath;
        if (
            to.includes("tabpanel-settings-labels-certificateLabels") &&
            !currentHistory.includes("labels/certificate-labels")
        ) {
            navigate(`/settings/labels/certificate-labels`);
        } else if (
            to.includes("tabpanel-settings-labels-CustomerLabels") &&
            !currentHistory.includes("labels/customer-labels")
        ) {
            navigate(`/settings/labels/customer-labels`);
        }
    };

    const DOMelement = (
        <React.Fragment>
            <h1 id="page-header"> Labels</h1>
            <STabs tabItems={tabItems} onS-select={e => navigateTo(e.detail.id)} />
            <Outlet />
        </React.Fragment>
    );
    return (
        <React.Fragment>
            {DOMelement}
            <span className="flex justify-content-start">
                <button
                    className="secondary"
                    type="button"
                    onClick={() => {
                        navigate(`/settings/advanced-settings`);
                    }}>
                    Back to settings
                </button>
            </span>
        </React.Fragment>
    );
});

export default Labels;
