import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import toast from "../../hooks/toast";
import SyncErrorDialog from "./SyncErrorDialog";
import axios from "../../axios";
import { buildApiV3Url } from "../../shared/Utils";
import { errorResponse, parseSuccessResponse } from "../../shared/responseUtils";
import { CRUDOperation } from "../../shared/constants";

function DeleteCustomerDialog(props) {
    const dialogName = "deleteCustomerDialog";
    const [submitting, setSubmitting] = useState(false);
    const [isDeletingCustomer, setDeletingCustomer] = useState(false);
    const deleteButton = classnames({ primary: true, small: true, loading: submitting });
    const navigate = useNavigate();
    const [showToast] = toast();
    const [logEntry, setLogEntry] = useState("");
    const [isSyncErrorDialogOpen, setIsSyncErrorDialogOpen] = useState(false);
    const entityType = props?.isVendor ? "vendor" : "customer";

    const deleteCustomer = async () => {
        setSubmitting(true);
        setDeletingCustomer(true);
        setIsSyncErrorDialogOpen(false);
        await axios
            .delete(buildApiV3Url(`customers/${props.customerId}`), {
                withCredentials: true,
            })
            .then(response => {
                const deleteResponse = parseSuccessResponse(CRUDOperation.Delete, response);
                const error = errorResponse(response);
                if (error?.code === "AvataxIntegrationError") {
                    setLogEntry(error?.detail);
                    setIsSyncErrorDialogOpen(true);
                    setDeletingCustomer(false);
                    props.setIsDeleteCustomerDialogOpen(false);
                    props.setIsCustomerWithCertificate(false);
                    document.getElementById("deleteCustomerDialog").close();
                    setSubmitting(false);
                } else if (deleteResponse?.isSuccess && deleteResponse?.isJobQueued) {
                    showToast(
                        "success",
                        `Your ${entityType} delete request has been queued for processing.`,
                        "deleteCustomerToast",
                        false
                    );
                    setDeletingCustomer(false);
                    props.setIsDeleteCustomerDialogOpen(false);
                    props.setIsCustomerWithCertificate(false);
                    document.getElementById("deleteCustomerDialog").close();
                    setSubmitting(false);
                    navigate(`/search/${entityType}s`);
                } else if (deleteResponse?.isSuccess && !deleteResponse?.isJobQueued) {
                    showToast("success", `${entityType} was deleted`);
                    setDeletingCustomer(false);
                    props.setIsDeleteCustomerDialogOpen(false);
                    props.setIsCustomerWithCertificate(false);
                    document.getElementById("deleteCustomerDialog").close();
                    setSubmitting(false);
                    navigate(`/search/${entityType}s`);
                } else if (!deleteResponse?.isSuccess) {
                    setDeletingCustomer(false);
                    props.setIsDeleteCustomerDialogOpen(false);
                    props.setIsCustomerWithCertificate(false);
                    document.getElementById("deleteCustomerDialog").close();
                    setSubmitting(false);
                }
            });
    };

    return (
        <React.Fragment>
            <SDialog
                id={dialogName}
                open={props.isDeleteCustomerDialogOpen}
                onS-dismiss={() => {
                    props.setIsDeleteCustomerDialogOpen(false);
                }}
                aria-modal="true">
                <div slot="header" id="deleteCustomer">
                    Delete this {entityType}?
                </div>
                <div slot="body">
                    {!props.isCustomerWithCertificate ? (
                        <span>You can&apos;t undo this action.</span>
                    ) : (
                        <span>
                            There are certificates associated with this {entityType}. You can&apos;t
                            undo this action.
                        </span>
                    )}
                </div>
                <div slot="footer">
                    <button
                        className="secondary small"
                        disabled={isDeletingCustomer}
                        onClick={() => {
                            props.setIsDeleteCustomerDialogOpen(false);
                            props.setIsCustomerWithCertificate(false);
                        }}>
                        No, cancel
                    </button>
                    <button className={deleteButton} type="button" onClick={() => deleteCustomer()}>
                        Yes, delete
                    </button>
                </div>
            </SDialog>
            <SyncErrorDialog
                isSyncErrorDialogOpen={isSyncErrorDialogOpen}
                setIsSyncErrorDialogOpen={setIsSyncErrorDialogOpen}
                logEntry={logEntry}
            />
        </React.Fragment>
    );
}

export default DeleteCustomerDialog;
