import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import {
    buildApiV3UrlWithQuery,
    matchSubscriptions,
    buildApiV3Url,
    transformExemptionData,
} from "../shared/Utils";

import { parseSuccessResponse, parseGetAllObjResponse } from "../shared/responseUtils";
import { documentTypeNames, CRUDOperation } from "../shared/constants";
import toast from "../hooks/toast";
import featureFlag from "../featureToggler/featureFlag";
import { getStoreValues } from "./commonSlice";

const initialState = {
    isLoading: false,
    isDeleting: false,
    isAddOrEditLoading: false,
    enableLoading: "",
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    formState: {
        nameOrDescription: "",
    },
    isLoadingEntityUseCodeMappings: false,
    entityUseCodeMappings: [],
};

export const exemptionReasonSlice = createSlice({
    name: "exemptionReason",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setIsDeleting: (state, action) => {
            state.isDeleting = action.payload;
        },
        setIsAddOrEditLoading: (state, action) => {
            state.isAddOrEditLoading = action.payload;
        },
        setEnableLoading: (state, action) => {
            state.enableLoading = action.payload;
        },
        setIsLoadingEntityUseCodeMappings: (state, action) => {
            state.isLoadingEntityUseCodeMappings = action.payload;
        },
        setEntityUseCodeMappings: (state, action) => {
            state.entityUseCodeMappings = action.payload;
        },
    },
});

const [showToast] = toast();
export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setFormState,
    clearFormState,
    setIsDeleting,
    setIsAddOrEditLoading,
    setEnableLoading,
    setIsLoadingEntityUseCodeMappings,
    setEntityUseCodeMappings,
} = exemptionReasonSlice.actions;

export const selectIsLoading = state => {
    return state.exemptionReason.isLoading;
};
export const selectPage = state => {
    return state.exemptionReason.page;
};
export const selectFormState = state => {
    return state.exemptionReason.formState;
};
export const selectIsDeleting = state => {
    return state.exemptionReason.isDeleting;
};
export const selectIsAddOrEditLoading = state => {
    return state.exemptionReason.isAddOrEditLoading;
};
export const selectEnableLoading = state => {
    return state.exemptionReason.enableLoading;
};
export const selectIsLoadingEntityUseCodeMappings = state => {
    return state.exemptionReason.setIsLoadingEntityUseCodeMapping;
};
export const selectEntityUseCodeMappings = state => {
    return state.exemptionReason.entityUseCodeMappings;
};

export const fetchExemptionReasons =
    (top, skip = 0, paginateDetails = null, sort = "name ASC") =>
    async (dispatch, getState) => {
        const { isloading, formState } = getState().exemptionReason;
        const { rowsPerPage } = getState().session;
        top = !top ? rowsPerPage : top;
        const { nameOrDescription } = formState;
        if (isloading) {
            return;
        }
        dispatch(setIsLoading(true));

        let filterString = `documentType.name eq "${documentTypeNames.salesAndUseTax}" AND documentType.outgoing eq false`;
        if (nameOrDescription) {
            filterString += ` and name like '%${formState?.nameOrDescription}%' or description like '%${formState?.nameOrDescription}%' `;
        }
        filterString += ` and denotesExposure eq false and name != NON-DELIVERABLE`;
        const isTelecom = matchSubscriptions(
            featureFlag.afcContent.associateTaxTypeData.subscription,
            dispatch(getStoreValues("session.subscriptions"))
        );
        if (!isTelecom) {
            filterString += ` AND isTelecom eq false`;
        }
        const apiURI = buildApiV3UrlWithQuery(
            `exemption-reasons`,
            filterString,
            null,
            top,
            skip,
            sort
        );
        const apiResponse = await axios.get(`${apiURI}`, { withCredentials: true });
        if (!parseSuccessResponse(CRUDOperation.ReadAll, apiResponse)?.recordFound) {
            dispatch(setPageToNull());
            dispatch(setIsLoading(false));
        } else {
            const response = transformExemptionData(apiResponse, false, false, ["name"]);
            const readAllResponse = parseGetAllObjResponse(response);
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails?.pageNumber,
                      startIndex: paginateDetails?.startIndex,
                      endIndex: paginateDetails?.endIndex,
                      rowsPerPage: paginateDetails?.rowsPerPage,
                      totalRecords: readAllResponse?.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: readAllResponse?.count,
                  };
            dispatch(
                setPage({
                    data: readAllResponse?.value,
                    paginate: paginateData,
                })
            );
        }
        dispatch(setIsLoading(false));
    };
export const deleteExemptReasonAsync = (id, exemptReason) => async (dispatch, getState) => {
    const { isDeleting } = getState().exemptionReason;

    if (isDeleting) {
        return false;
    }

    dispatch(setIsDeleting(true));
    const response = await axios.delete(buildApiV3Url(`exemption-reasons/${id}`), {
        withCredentials: true,
    });
    if (parseSuccessResponse(CRUDOperation.Delete, response)?.isSuccess) {
        showToast(
            "success",
            `Exemption reason "${exemptReason}" deleted`,
            "deleteExemptReasonToast",
            false
        );
        dispatch(fetchExemptionReasons());
    }
    dispatch(setIsDeleting(false));
    return response;
};

const postEntityUseCodeMapping = async (id, entityUseCode) => {
    await axios.post(
        buildApiV3Url(`exempt-reasons/${id}/entity-use-code-mapping`),
        { entityUseCode },
        { withCredentials: true }
    );
};

const putEntityUseCodeMapping = async (id, entityUseCode) => {
    await axios.put(
        buildApiV3Url(`exempt-reasons/${id}/entity-use-code-mapping`),
        { entityUseCode },
        { withCredentials: true }
    );
};

const deleteEntityUseCodeMapping = async id => {
    await axios.delete(buildApiV3Url(`exempt-reasons/${id}/entity-use-code-mapping`), {
        withCredentials: true,
    });
};

export const AddOrUpdateExemptionReason =
    (addOrEdit, payload, taxCodeId, entityUseCode, oldEntityUseCode) =>
    async (dispatch, getState) => {
        const { isAddOrEditLoading } = getState().exemptionReason;
        if (isAddOrEditLoading) return false;
        dispatch(setIsAddOrEditLoading(true));
        let success = false;
        if (addOrEdit === "add") {
            await axios
                .post(buildApiV3Url(`exemption-reasons`), payload, {
                    withCredentials: true,
                })
                .then(async x => {
                    if (x?.data?.id && entityUseCode && entityUseCode !== "no-assign") {
                        await postEntityUseCodeMapping(x?.data?.id, entityUseCode);
                    }
                    if (parseSuccessResponse(CRUDOperation.Create, x)?.isSuccess) {
                        showToast("success", `Exempt reason added`, "", false);
                        success = true;
                        dispatch(fetchExemptionReasons());
                    } else {
                        success = false;
                    }
                });
        } else {
            await axios
                .put(buildApiV3Url(`exemption-reasons/${taxCodeId}`), payload, {
                    withCredentials: true,
                })
                .then(async x => {
                    if (
                        x?.data?.id &&
                        entityUseCode &&
                        entityUseCode !== "no-assign" &&
                        oldEntityUseCode !== ""
                    ) {
                        await putEntityUseCodeMapping(x.data.id, entityUseCode);
                    } else if (x?.data?.id && entityUseCode && oldEntityUseCode === "") {
                        await postEntityUseCodeMapping(x?.data?.id, entityUseCode);
                    } else if (x?.data?.id && entityUseCode && entityUseCode === "no-assign") {
                        await deleteEntityUseCodeMapping(x?.data?.id);
                    }

                    if (parseSuccessResponse(CRUDOperation.Update, x)?.isSuccess) {
                        showToast("success", `Exempt reason updated`, "", false);
                        success = true;
                        dispatch(fetchExemptionReasons());
                    } else {
                        success = false;
                    }
                });
        }
        dispatch(setIsAddOrEditLoading(false));
        return success;
    };

export const EnableDisableExemptionReason = (payload, taxCodeId) => async (dispatch, getState) => {
    const { enableLoading } = getState().exemptionReason;
    if (enableLoading) return;
    dispatch(setEnableLoading(taxCodeId));
    await axios
        .put(buildApiV3Url(`exemption-reasons/${taxCodeId}`), payload, {
            withCredentials: true,
        })
        .then(x => {
            if (x.status === 200) {
                showToast("success", `Successfully updated the exemption reason.`, "", false);
                dispatch(fetchExemptionReasons());
            }
        });

    dispatch(setEnableLoading(""));
};

export const fetchEntityUseCodeMapping = () => async (dispatch, getState) => {
    const { isLoadingEntityUseCodeMappings } = getState().exemptionReason;
    if (isLoadingEntityUseCodeMappings) {
        return;
    }

    dispatch(setIsLoadingEntityUseCodeMappings(true));
    const response = await axios.get(
        buildApiV3Url(`exempt-reasons/entity-use-code-mapping/defaults?&$top=30`),
        { withCredentials: true }
    );

    if (response && response.data) {
        dispatch(setEntityUseCodeMappings(response.data.value));
    }
    dispatch(setIsLoadingEntityUseCodeMappings(false));
};

export default exemptionReasonSlice.reducer;
