import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { SCol, SRow, SLoader, SToggle, SIcon } from "@avalara/skylab-react";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import {
    putGeneralSettings,
    selectIsSavingSettings,
    selectSetting,
} from "../../../app/settingsSlice";

import ProductCategoriesGrid from "./ProductCategoriesGrid";
import AddProductCategoryDialog from "./AddProductCategoryDialog";
import featureFlag from "../../../featureToggler/featureFlag";

const ManageProductCategories = React.memo(() => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsSavingSettings);
    const settings = useSelector(selectSetting, shallowEqual);
    const [dialogOpen, setDialogOpen] = useState(false);
    let clientHeaderDOM = null;
    const toggleProductCategories = async event => {
        const putData = {
            exemptionCategory: event.detail.checked,
        };
        if (settings.exemptionCategory !== event.detail.checked) {
            await dispatch(putGeneralSettings(putData));
        }
    };
    const isUserEligibleAddProductCategory = !dispatch(
        isEligibleUser(featureFlag.settings.addProductCategories)
    );
    const isEligibleUserToggleProductCategory = !dispatch(
        isEligibleUser(featureFlag.settings.useProductCategoriesToggle)
    );
    if (isLoading) {
        clientHeaderDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" aria-live="polite" />
            </div>
        );
    } else {
        clientHeaderDOM = (
            <React.Fragment>
                <Helmet>
                    <title>{dispatch(getPageTitle("Product Categories"))}</title>
                </Helmet>
                <h1 id="page-header" className="line-height-md margin-bottom-sm">
                    Exempt product categories
                </h1>
                <p>
                    Product categories add a field to exemption certificates that limits exemption{" "}
                    <br /> to specific products.
                </p>
                <span>
                    <button
                        className="ghost-blue icon-leading pad-left-none"
                        type="button"
                        onClick={() => {
                            navigate(`/settings/advanced-settings`);
                        }}>
                        <SIcon name="arrow-left" />
                        Back to settings
                    </button>
                </span>
                <SRow className="margin-bottom-none margin-top-sm">
                    <SCol span="3" className="margin-top-xs">
                        <SToggle
                            id="exemptionCategorySettings-toggle"
                            aria-label="Toggle Product Categories"
                            onS-toggle={e => toggleProductCategories(e)}
                            checked={settings.exemptionCategory}
                            disabled={isEligibleUserToggleProductCategory}
                        />
                        <span className="pad-left-sm top-5">
                            {settings.exemptionCategory
                                ? "Use exempt product categories"
                                : "Don't use exempt product categories "}
                        </span>
                    </SCol>
                </SRow>
                <SRow className="margin-top-none margin-bottom-xl">
                    <SCol>
                        <button
                            disabled={isUserEligibleAddProductCategory}
                            className="primary float-right icon-leading"
                            onClick={() => setDialogOpen(true)}>
                            <SIcon name="plus" />
                            Add a category
                        </button>
                    </SCol>
                    <SCol>
                        <ProductCategoriesGrid />
                        <AddProductCategoryDialog
                            isDialogOpen={dialogOpen}
                            openDialog={setDialogOpen}
                        />
                    </SCol>
                </SRow>
            </React.Fragment>
        );
    }
    return clientHeaderDOM;
});

export default ManageProductCategories;
