import React from "react";
import { SAlert, SCol } from "@avalara/skylab-react";
import { useNavigate } from "react-router-dom";

const MissingMatrixAlert = React.memo(() => {
    const navigate = useNavigate();
    const clickRequest = () => {
        navigate(`/settings/default-exemption-forms/outgoing`);
    };
    return (
        <SCol className="pad-bottom-sm">
            <SAlert id="info-alert" status="info" role="status" noDismiss>
                <span>
                    To send certificates to vendors, first
                    <button
                        className="ghost pad-left-xs pad-right-xs"
                        onClick={() => clickRequest()}>
                        <span className="text-underline">
                            define where and why this company is exempt
                        </span>
                    </button>
                    and select the forms you want to send.
                </span>
            </SAlert>
        </SCol>
    );
});

export default MissingMatrixAlert;
