import React from "react";
import { SSelect } from "@avalara/skylab-react";

const SelectRequirementLevel = React.memo(props => {
    const optionList = [
        {
            label: "Preferred",
            value: "Preferred",
            selected: false,
        },
        {
            label: "Required",
            value: "Required",
            selected: false,
        },
        {
            label: "Optional",
            value: "Optional",
            selected: false,
        },
    ];
    const options = optionList.map(element => {
        const selectOption = {
            label: element.label,
            value: element.value,
            selected: false,
        };
        if (props.value && props.value === element.value) {
            selectOption.selected = true;
        }
        return selectOption;
    });

    return (
        <SSelect
            inputId={props?.id}
            aria-labelledby={props?.label}
            value={props?.value}
            selectionOptional={false}
            optionsList={options}
            onS-select={e => props?.onAdd(e)}
            multiple={false}
            noSearch
        />
    );
});

export default SelectRequirementLevel;
