import React, { useState } from "react";
import { SRow, SCol, SDialog } from "@avalara/skylab-react";
import { useDispatch, useSelector } from "react-redux";
import ValidationFileRender from "../review/ValidationFileRender";
import { clearValidationData, selectHasCertificateFile } from "../../app/certificateSlice";
import "./previewDialog.css";

function PreviewDialog(props) {
    const dialogName = "previewDialog";
    const dispatch = useDispatch();
    const hasCertificateFile = useSelector(selectHasCertificateFile);
    const [numberPages, setNumberPages] = useState(null);
    const onSDismiss = () => {
        props.setIsPreviewDialogOpen(false);
        dispatch(clearValidationData());
        if (document.getElementById("img-magnifier-glass"))
            document.getElementById("img-magnifier-glass").remove();
    };

    return (
        <SDialog
            id={dialogName}
            open={props.isPreviewDialogOpen}
            noDismiss
            onS-dismiss={onSDismiss}
            aria-modal="true">
            <div slot="header" id="preview">
                Certificate image
            </div>
            <div slot="body" className="preview-modal-body">
                <SRow>
                    <SCol>
                        <ValidationFileRender
                            certificateID={props.certificateID}
                            enableMagnifier
                            previewEnabled
                            PdfPageCount={setNumberPages}
                            isUploaderDisabled={props.isUploaderDisabled}
                            showRevokedAlert={props?.showRevokedAlert}
                        />
                    </SCol>
                </SRow>
            </div>
            <div slot="footer" className="block">
                <SRow>
                    <SCol span="9" className="align-start">
                        <span className="pad-right-xs">
                            {hasCertificateFile && numberPages ? props.certificateName : ""}
                        </span>
                    </SCol>
                    <SCol span="3" className="flex justify-content-end">
                        <button className="primary inline" onClick={() => onSDismiss()}>
                            Cancel
                        </button>
                    </SCol>
                </SRow>
            </div>
        </SDialog>
    );
}

export default PreviewDialog;
