import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import toast from "../../hooks/toast";
import {
    updateValidationStatusAsync,
    fetchValidationCertificateByIdAPI,
    setIsPauseCertificateDialogOpen,
    selectIsPauseCertificateDialogOpen,
    setIsResumeCertificateDialogOpen,
    selectIsResumeCertificateDialogOpen,
} from "../../app/certificateSlice";

function PauseResumeCertificateDialog(props) {
    const dispatch = useDispatch();
    const [showToast] = toast();
    const [isLoading, setIsLoading] = useState(false);
    const pauseButton = classnames({ primary: true, small: true, loading: isLoading });
    const isPauseCertificateDialogOpen = useSelector(selectIsPauseCertificateDialogOpen);
    const isResumedCertificateDialogOpen = useSelector(selectIsResumeCertificateDialogOpen);

    const pauseCertificate = async () => {
        setIsLoading(true);
        await dispatch(
            updateValidationStatusAsync(props.certificateID, !isPauseCertificateDialogOpen)
        );
        if (isPauseCertificateDialogOpen) {
            showToast("success", "You paused use of this certificate.");
        } else {
            showToast("success", "You resumed use of this certificate.");
        }
        setIsLoading(false);
        dispatch(setIsPauseCertificateDialogOpen(false));
        dispatch(setIsResumeCertificateDialogOpen(false));
        dispatch(fetchValidationCertificateByIdAPI(props.certificateID));
    };

    let pauseResumeDialog = null;

    // Pause certificate dialog
    if (isPauseCertificateDialogOpen) {
        pauseResumeDialog = (
            <SDialog
                id="pause-dialog"
                open={isPauseCertificateDialogOpen}
                onS-dismiss={() => {
                    dispatch(setIsPauseCertificateDialogOpen(false));
                }}
                aria-modal="true">
                <div slot="header" id="pauseCertificate">
                    Pause the certificate?
                </div>
                <div slot="body">Paused certificates aren&apos;t applied to transactions.</div>
                <div slot="footer">
                    <button
                        className="secondary small"
                        onClick={() => dispatch(setIsPauseCertificateDialogOpen(false))}>
                        Cancel
                    </button>
                    <button
                        className={pauseButton}
                        type="button"
                        onClick={() => pauseCertificate()}>
                        Yes, pause it
                    </button>
                </div>
            </SDialog>
        );
    }
    if (isResumedCertificateDialogOpen) {
        pauseResumeDialog = (
            <SDialog
                id="resume-dialog"
                open={isResumedCertificateDialogOpen}
                onS-dismiss={() => {
                    dispatch(setIsResumeCertificateDialogOpen(false));
                }}
                aria-modal="true">
                <div slot="header" id="resumeCertificate?">
                    Resume applying the certificate?
                </div>
                <div slot="body">
                    The certificate will become active again and will apply to any
                    <br /> future transactions where it is applicable.
                </div>
                <div slot="footer">
                    <button
                        className="secondary small"
                        onClick={() => dispatch(setIsResumeCertificateDialogOpen(false))}>
                        Cancel
                    </button>
                    <button
                        className={pauseButton}
                        type="button"
                        onClick={() => pauseCertificate()}>
                        Yes, resume
                    </button>
                </div>
            </SDialog>
        );
    }
    return pauseResumeDialog;
}

export default PauseResumeCertificateDialog;
