import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { fixSearchStringForPostgres, buildApiV3Url } from "../shared/Utils";

export const customerQuickSearchSlice = createSlice({
    name: "customerQuickSearch",
    initialState: {
        isloading: false,
        data: [],
    },

    reducers: {
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setLoading, setData } = customerQuickSearchSlice.actions;

export const selectData = state => {
    return state.customerQuickSearch.data;
};

export const selectIsLoading = state => {
    return state.customerQuickSearch.isloading;
};

export const quickSearch = value => async (dispatch, getState) => {
    const { isloading } = getState().customerQuickSearch;
    if (isloading) {
        return;
    }
    dispatch(setLoading(true));
    value = fixSearchStringForPostgres(value);
    const response = await axios.get(
        buildApiV3Url(
            `customers/typeahead?$top=5&$filter=name contains '${value}' OR customerNumber contains '${value}' OR addressLine1 contains '${value}'`
        ),
        { withCredentials: true }
    );

    if (response && response.data) {
        dispatch(setData(response.data));
    }

    dispatch(setLoading(false));
};

export const clearQuickSearch = () => async dispatch => {
    dispatch(setData([]));
};
export default customerQuickSearchSlice.reducer;
