import React, { useState, useCallback, useEffect, memo } from "react";
import { SDialog, SRow, SCol, SIcon } from "@avalara/skylab-react";
import { useDispatch } from "react-redux";

import { getCertificateStatusesAsync } from "../../app/certificateSlice";
import SelectInvalidReasons from "../sharedComponents/SelectInvalidReasons";
import "./invalidReasonDialog.css";
import { invalidReasonDialogID } from "../../shared/constants";

const InvalidReasonDialog = memo(props => {
    const dispatch = useDispatch();

    const [invalidReasonsError, setInvalidReasonsError] = useState("");

    const {
        invalidReasonsString,
        invalidReasons = JSON.parse(invalidReasonsString),
        setInvalidReasons,
        postExemptions,
        closeInvalidReasonDialog,
    } = props;

    useEffect(() => {
        dispatch(getCertificateStatusesAsync());
    }, [dispatch]);

    const addInvalidReasons = useCallback(item => {
        setInvalidReasonsError("");
        setInvalidReasons(prevInvalidReasons => [...prevInvalidReasons, item.value]);
    }, []);

    const removeInvalidReasons = useCallback(item => {
        setInvalidReasons(prevInvalidReasons =>
            prevInvalidReasons.filter(reason => reason !== item.value)
        );
    }, []);

    return (
        <SDialog id={invalidReasonDialogID} onS-dismiss={() => closeInvalidReasonDialog()}>
            <div slot="header" id="dialog-title">
                Reason the certificate is invalid
            </div>
            <div slot="body" className="invalidReasonDialog">
                <SRow>
                    <SCol span="auto">
                        <SelectInvalidReasons
                            onAdd={addInvalidReasons}
                            onRemove={removeInvalidReasons}
                            multiple
                            error={invalidReasonsError}
                            value={invalidReasonsString}
                        />
                        <div className="input-msg" hidden={!invalidReasonsError}>
                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                            <span className="top-xs">Enter a invalid reason</span>
                        </div>
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => {
                        closeInvalidReasonDialog();
                    }}>
                    Cancel
                </button>
                <button
                    className="primary small"
                    onClick={() => {
                        if (invalidReasons.length === 0) {
                            setInvalidReasonsError("error");
                            return;
                        }
                        closeInvalidReasonDialog();
                        postExemptions();
                    }}>
                    Save
                </button>
            </div>
        </SDialog>
    );
});

export default InvalidReasonDialog;
