import React, { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
    selectPurchaserSettings,
    putPurchaserSettings,
    selectIsLoadingPurchaserSettings,
    selectIsAddingRetailSetting,
    selectRetailSettings,
    putRetailSettings,
} from "../../../../app/retailSettingsSlice";
import DiscardSettingsConfirmationDialog from "./DiscardSettingsConfirmationDialog";
import EditRetailSettingsTable from "./EditRetailSettingsTable";
import EditPurchaserSettingsTable from "./EditPurchaserSettingsTable";
import { allSettings } from "../../../../shared/constants";

const EditRetailSettings = React.memo(props => {
    const dispatch = useDispatch();
    const retailSettings = useSelector(selectRetailSettings, shallowEqual);
    const [isDiscardEditSettingsDialogOpen, setIsDiscardEditSettingsDialogOpen] = useState(false);
    const [retailSettingsPayload, setRetailSettingsPayload] = useState(
        retailSettings.map(x => ({
            retailId: x.id,
            clientRetailId: x?.clientsRetailSettings?.[0]?.id ?? 0,
            isActive: x?.clientsRetailSettings?.[0]?.value || false,
            option: x.option,
            clientsRetailSettings: x.clientsRetailSettings,
        }))
    );
    const [isRetailSettingUpdated, setIsRetailSettingUpdated] = useState(false);
    const [isPurchaserSettingUpdated, setIsPurchaserSettingUpdated] = useState(false);
    const purchaserSettings = useSelector(selectPurchaserSettings, shallowEqual);
    const isAddingPurchaserSettings = useSelector(selectIsLoadingPurchaserSettings);
    const isAddingRetailSettings = useSelector(selectIsAddingRetailSetting);
    const isLoading = isAddingPurchaserSettings || isAddingRetailSettings;
    const onCancel = () => {
        if (isRetailSettingUpdated || isPurchaserSettingUpdated) {
            setIsDiscardEditSettingsDialogOpen(true);
        } else {
            props.setOpenEditSettings(false);
        }
    };
    const onSave = async () => {
        const payload = {};
        payload.retailSettingUpdates = retailSettingsPayload.filter(x => {
            return allSettings[x.option].type !== "client";
        });
        payload.clientShowPending = retailSettingsPayload.find(
            x => x.option === "show_no_pending"
        ).isActive;
        payload.clientShowNoCerts = retailSettingsPayload.find(
            x => x.option === "show_no_cert"
        ).isActive;
        if (isRetailSettingUpdated && isPurchaserSettingUpdated) {
            await Promise.all([
                dispatch(putRetailSettings(payload)),
                dispatch(putPurchaserSettings(purchaserSettings)),
            ]);
        } else if (isRetailSettingUpdated) await dispatch(putRetailSettings(payload));
        else if (isPurchaserSettingUpdated) await dispatch(putPurchaserSettings(purchaserSettings));
        props.setOpenEditSettings(false);
    };

    return (
        <>
            <div className="row">
                <EditRetailSettingsTable
                    setIsRetailSettingUpdated={setIsRetailSettingUpdated}
                    setRetailSettingsPayload={setRetailSettingsPayload}
                    retailSettingsPayload={retailSettingsPayload}
                />
                <EditPurchaserSettingsTable
                    setIsPurchaserSettingUpdated={setIsPurchaserSettingUpdated}
                />
            </div>

            <div>
                <hr className="margin-top-lg margin-bottom-lg" />
                <button
                    className={isLoading ? "primary large loading" : "primary large "}
                    id="save-settings"
                    type="submit"
                    onClick={onSave}>
                    Save
                </button>
                <button className="secondary large" onClick={onCancel} type="button">
                    Cancel
                </button>
            </div>
            {isDiscardEditSettingsDialogOpen ? (
                <DiscardSettingsConfirmationDialog
                    setIsDiscardEditSettingsDialogOpen={setIsDiscardEditSettingsDialogOpen}
                    isDiscardEditSettingsDialogOpen={isDiscardEditSettingsDialogOpen}
                    setOpenEditSettings={props.setOpenEditSettings}
                />
            ) : null}
        </>
    );
});

export default EditRetailSettings;
