import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SRow, SCol, SDialog, SIcon, SSelect } from "@avalara/skylab-react";
import { customFieldTypes, documentTypeNames } from "../../../../shared/constants";
import {
    putCertificateCustomFields,
    postCertificateCustomFields,
    selectIsAddOrEditLoading,
} from "../../../../app/certificateCustomFieldSlice";
import { errorTag, noErrorTag } from "../../../../shared/Utils";

function AddEditCertificateCustomFieldsDialog(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsAddOrEditLoading);
    const [enableSave, setEnableSave] = useState(false);
    const [nameError, setNameError] = useState("");
    const [typeError, setTypeError] = useState("");
    const [showValueBox, setShowValueBox] = useState(false);
    const [customFieldName, setFieldName] = useState(
        props.certificateCustomField.fieldName ? props.certificateCustomField.fieldName : ""
    );
    const [fieldType, setFieldType] = useState(
        props.certificateCustomField.type ? props.certificateCustomField.type : "text"
    );
    const [fieldValues, setFieldValues] = useState(
        props.certificateCustomField.possibleValues
            ? props.certificateCustomField.possibleValues
            : ""
    );
    const [optionList, setOptionList] = useState(customFieldTypes);

    const handleNameChange = value => {
        setFieldName(value);
        if (value === "") setNameError("error");
        else setNameError("");
    };

    const handleTypeChange = item => {
        setFieldType(item.value);
        if (item.value === "select" || item.value === "multi-select") {
            setShowValueBox(true);
        } else if (item.value === "boolean") {
            setShowValueBox(false);
            setFieldValues("Y\nN");
        } else {
            setShowValueBox(false);
            setFieldValues("");
        }
        setTypeError("");
    };

    function getFormattedValue() {
        let formattedStr = "";
        if (fieldValues?.length > 0) {
            if (props.addOrEdit === "Edit") {
                formattedStr = fieldValues?.trim()?.replaceAll("|", "\n");
                setFieldValues(formattedStr);
            }
        }
    }

    const clearFields = () => {
        setFieldName("");
        setNameError("");
        setFieldType("");
        setTypeError("");
        setFieldValues("");
    };

    useEffect(() => {
        if (props.addOrEdit === "Edit") {
            setEnableSave(true);
            if (fieldType) {
                const list = customFieldTypes?.map(x => {
                    if (x.value === fieldType) {
                        return { label: x.label, value: x.value, selected: true };
                    }
                    return { label: x.label, value: x.value, selected: false };
                });
                setOptionList(list);
                if (fieldType === "select" || fieldType === "multi-select") {
                    setShowValueBox(true);
                    getFormattedValue();
                } else if (fieldType === "boolean") {
                    setShowValueBox(false);
                    getFormattedValue();
                } else {
                    setShowValueBox(false);
                    setFieldValues("");
                }
            }
        } else if (props.addOrEdit === "Add") {
            clearFields();
            setFieldType("text");
        }
    }, [props]);

    useEffect(() => {
        let val = false;
        if (showValueBox && fieldValues?.length === 0) {
            val = false;
        } else if (customFieldName && fieldType?.length > 0) {
            val = true;
        }
        setEnableSave(val);
    }, [customFieldName, fieldType, showValueBox, fieldValues]);

    const onSave = async () => {
        const payload = {
            fieldName: customFieldName,
            type: fieldType,
            possibleValues: fieldValues?.trim()?.replaceAll("\n", "|"),
            usesDataEntry: false,
            requiredInDataEntry: false,
            legacyField: "",
            usesGencert: false,
            isEditable: true,
            gencertLock: false,
            documentTypeName: documentTypeNames.salesAndUseTax,
        };
        let res;
        if (props.addOrEdit === "Add") {
            res = await dispatch(postCertificateCustomFields(payload));
        } else {
            res = await dispatch(
                putCertificateCustomFields(payload, props.certificateCustomField.id)
            );
        }
        if (res) props.setOpenAddUpdateDialog(false);
    };

    const onCancel = () => {
        clearFields();
        props.setOpenAddUpdateDialog(false);
    };

    return (
        <SDialog
            id="add-edit-custom-fields-dialog"
            open={props.openAddUpdateDialog}
            onS-dismiss={onCancel}
            className="overflow-visible"
            aria-modal="true">
            <div slot="header" id="addEditCustomFields">
                {props.addOrEdit === "Add"
                    ? "Add a custom field for certificates"
                    : "Edit a custom field for certificates"}
            </div>
            <div slot="body">
                <SRow>
                    <SCol>
                        <label
                            htmlFor="custom-field-name"
                            id="lbl-custom-field-name"
                            className="required">
                            Name
                        </label>
                        <input
                            id="custom-field-name"
                            name="Name"
                            type="text"
                            aria-labelledby="lbl-custom-field-name"
                            value={customFieldName}
                            onChange={e => {
                                handleNameChange(e.target.value);
                            }}
                            className={nameError ? errorTag : noErrorTag}
                        />
                        <div className="input-msg">
                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                            <span className="top-xs">Enter name</span>
                        </div>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="auto">
                        <label htmlFor="type" className="required" id="lbl-type">
                            Type
                        </label>
                        <SSelect
                            inputId="type"
                            name="customFieldType"
                            onS-select={e => handleTypeChange(e.detail.item)}
                            optionsList={optionList}
                            all
                            required
                            typeError={typeError}
                        />

                        {typeError.length > 0 && typeError === "error" ? (
                            <div className="input-msg">
                                <SIcon name="alert-circle-filled" aria-hidden="true" />
                                <span className="top-xs">Select a type.</span>
                            </div>
                        ) : null}
                    </SCol>
                </SRow>
                {showValueBox ? (
                    <SRow>
                        <SCol span="auto">
                            <label htmlFor="values" id="lbl-values">
                                Values
                            </label>
                            <textarea
                                name="values"
                                id="values"
                                rows="3"
                                aria-labelledby="lbl-values"
                                onChange={e => setFieldValues(e.target.value)}
                                value={fieldValues}
                            />
                        </SCol>
                    </SRow>
                ) : null}
            </div>
            <div slot="footer">
                <button className="secondary" onClick={onCancel}>
                    Cancel
                </button>
                <button
                    disabled={!enableSave}
                    className={isLoading ? "primary right loading" : "primary right"}
                    id="save-custom-field"
                    type="button"
                    onClick={onSave}>
                    Save
                </button>
            </div>
        </SDialog>
    );
}

export default AddEditCertificateCustomFieldsDialog;
