import { SRow, SCol, STabs, STag } from "@avalara/skylab-react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import classnames from "classnames";
import { useNavigate, useLocation, useParams, Outlet } from "react-router-dom";
import toast from "../../../hooks/toast";
import {
    setCertificate,
    clearValidationData,
    setHasCertificateFile,
    putCertificateAssignmentAsync,
    selectCustomerCert,
    selectIsLoadingAssignment,
    selectLoading,
} from "../../../app/certificateSlice";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import EscalationDialog from "../../sharedDialogs/EscalationDialog";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import featureFlag from "../../../featureToggler/featureFlag";

function Validation() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const idFromParam = !Number.isNaN(Number(params?.certificateID))
        ? Number(params.certificateID)
        : 0;
    const [certificateID, setCertificateID] = useState("");
    const [showAssignToMe, setShowAssignToMe] = useState(true);
    const [showAssignUserName, setShowAssignUserName] = useState("Unassigned");
    const certificate = useSelector(selectCustomerCert, shallowEqual);
    const isLoadingAssignment = useSelector(selectIsLoadingAssignment);
    const isLoadingCertificate = useSelector(selectLoading);
    const [isEscalationDialogOpen, setIsEscalationDialogOpen] = useState(false);
    const [showEscalatedButton, setShowEscalatedButton] = useState(true);

    const isEligibleUserReviewCertificateAssignRelease = dispatch(
        isEligibleUser(featureFlag.reviewCertificate.assignRelease)
    );

    const isEligibleUserEscalate = dispatch(isEligibleUser(featureFlag.reviewCertificate.escalate));

    const assignButton = classnames({
        tertiary: true,
        loading: isLoadingAssignment || isLoadingCertificate,
    });
    const escalateButton = classnames({
        tertiary: true,
        loading: isLoadingCertificate,
    });
    const [showToast] = toast();

    const certificateTab = "tabpanel-certificate";
    const historyTab = "tabpanel-history";
    const [tabItems, setTabItems] = useState([
        {
            id: "review-certificate",
            label: "Certificate",
            tabPanelId: certificateTab,
            selected: false,
        },
        {
            id: "review-history",
            label: "History",
            tabPanelId: historyTab,
            selected: false,
        },
    ]);

    useEffect(() => {
        const updatedTabs = tabItems.map(tab => ({
            ...tab,
            selected:
                (location.pathname.includes("/history") && tab.tabPanelId === historyTab) ||
                (certificateID && tab.tabPanelId === certificateTab),
        }));
        setTabItems(updatedTabs);
    }, [certificateID, location.pathname]);

    useEffect(() => {
        if (certificateID === "" && idFromParam) {
            setCertificateID(idFromParam);
        }
        if (certificate?.certificateAssignment?.assignUserName) {
            let userName;
            if (certificate?.certificateAssignment?.assignedToMe) {
                userName = "Assigned to me";
                setShowAssignToMe(false);
            } else {
                userName = `Assigned to ${certificate.certificateAssignment.assignUserName}`;
            }
            setShowAssignUserName(userName);
        }
        if (certificate.certificateEscalation?.escalationDate) setShowEscalatedButton(false);
    }, [
        idFromParam,
        certificateID,
        certificate.certificateAssignment,
        certificate.certificateEscalation,
    ]);

    useEffect(() => {
        return () => {
            dispatch(setCertificate([]));
            dispatch(clearValidationData({ fileType: "", data: "", fileName: "" }));
            dispatch(setHasCertificateFile(false));
        };
    }, [dispatch]);

    function navigateTo(e) {
        const path = e.detail.id.includes("history")
            ? `/review/history/${certificateID}`
            : `/review/${certificateID}`;
        navigate(path);
    }

    const validationHandler = async e => {
        const certIds = [parseInt(certificateID, 10)];
        await dispatch(putCertificateAssignmentAsync(certIds, showAssignToMe, showToast));
        if (e.target.id === "release-certificate-btn") {
            setShowAssignToMe(true);
            setShowAssignUserName("Unassigned");
        } else if (e.target.id === "assign-certificate-btn") {
            setShowAssignUserName("Assigned to me");
            setShowAssignToMe(false);
        }
    };

    const ValidationDOM = (
        <>
            <Helmet>
                <title>{dispatch(getPageTitle("Validation"))}</title>
            </Helmet>
            <SRow>
                <SCol span="11">
                    {certificateID ? (
                        <h1 hidden={!certificateID} className="margin-bottom-xl">
                            Exemption certificate {certificateID}
                        </h1>
                    ) : (
                        <h1 hidden={certificateID} className="margin-bottom-xl">
                            Add a certificate
                        </h1>
                    )}
                </SCol>
            </SRow>
            <SRow>
                <SCol span="auto">
                    <div className="flex">
                        {certificateID ? (
                            <>
                                <STabs
                                    tabItems={tabItems}
                                    onS-select={e => navigateTo(e)}
                                    className="tab-width-validation"
                                />

                                <FeatureToggler category="reviewCertificate" id="assignRelease">
                                    <span className="flex validation-button-assign">
                                        <span className="justify-content-start">
                                            <label className="margin-top-sm margin-right-sm max-content-width">
                                                {showAssignUserName}
                                            </label>
                                        </span>
                                        <span className="flex secondary justify-content-end max-content-width">
                                            <FeatureToggler
                                                category="reviewCertificate"
                                                id="assignRelease">
                                                {showAssignToMe ? (
                                                    <button
                                                        className={`secondary ${assignButton}`}
                                                        id="assign-certificate-btn"
                                                        onClick={e => validationHandler(e)}
                                                        disabled={
                                                            !isEligibleUserReviewCertificateAssignRelease
                                                        }
                                                        type="secondary">
                                                        Assign to me
                                                    </button>
                                                ) : (
                                                    <button
                                                        className={`secondary ${assignButton}`}
                                                        id="release-certificate-btn"
                                                        loading={
                                                            isLoadingAssignment ||
                                                            isLoadingCertificate
                                                        }
                                                        onClick={e => validationHandler(e)}
                                                        disabled={
                                                            !isEligibleUserReviewCertificateAssignRelease
                                                        }
                                                        type="secondary">
                                                        Release
                                                    </button>
                                                )}
                                            </FeatureToggler>
                                            <FeatureToggler
                                                category="reviewCertificate"
                                                id="escalate">
                                                {showEscalatedButton ? (
                                                    <button
                                                        className={`secondary ${escalateButton}`}
                                                        id="assign-certificate-btn"
                                                        loading={isLoadingCertificate.toString()}
                                                        disabled={!isEligibleUserEscalate}
                                                        onClick={() =>
                                                            setIsEscalationDialogOpen(true)
                                                        }>
                                                        Escalate
                                                    </button>
                                                ) : (
                                                    <span className="margin-top-xs margin-left-sm">
                                                        <STag color="yellow-medium">
                                                            <span slot="content">Escalated</span>
                                                        </STag>
                                                    </span>
                                                )}
                                            </FeatureToggler>
                                        </span>
                                    </span>
                                </FeatureToggler>
                            </>
                        ) : null}
                    </div>
                    <Outlet />
                </SCol>
            </SRow>
            {isEscalationDialogOpen ? (
                <EscalationDialog
                    isEscalationDialogOpen={isEscalationDialogOpen}
                    setIsEscalationDialogOpen={setIsEscalationDialogOpen}
                    certificateID={certificateID}
                    setShowEscalatedButton={setShowEscalatedButton}
                />
            ) : null}
        </>
    );
    return ValidationDOM;
}
export default Validation;
