import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { SDialog, SAlert } from "@avalara/skylab-react";
import classnames from "classnames";
import toast from "../../hooks/toast";
import {
    deleteCertificatesAsync,
    selectIsDeletingCert,
    selectMultiCertChildList,
    selectIsMulti,
    singleDeleteCertificatesAsync,
} from "../../app/certificateSlice";

function DeleteCertificateDialog(props) {
    const dialogName = "deleteCertificateDialog";
    const deleteMultiple = Array.isArray(props.certificateIds) && props.certificateIds.length > 1;
    const isDeletingCertificate = useSelector(selectIsDeletingCert);
    const deleteButton = classnames({ primary: true, small: true, loading: isDeletingCertificate });
    const dispatch = useDispatch();
    const [showToast] = toast();
    const isCertMulti = useSelector(selectIsMulti);
    const { bulkDelete } = props;
    const multiCertChildList = useSelector(selectMultiCertChildList, shallowEqual);
    const deleteCertificate = async () => {
        const afterDelete = () => {
            if (props.handleParentDelete) props.handleParentDelete();
            props.setIsDeleteCertificateDialogOpen(false);
            if (props.navigate) props.navigate();
        };
        if (bulkDelete) {
            dispatch(
                deleteCertificatesAsync(
                    props.certificateIds,
                    deleteMultiple,
                    showToast,
                    afterDelete
                )
            );
        } else {
            const showMultipleCertDeleteMessage = isCertMulti;
            dispatch(
                singleDeleteCertificatesAsync(
                    props.certificateIds,
                    showToast,
                    showMultipleCertDeleteMessage,
                    afterDelete
                )
            );
        }
    };

    const deleteSingleBody = (
        <p>
            I understand that when a certificate is deleted both the certificate data and <br />
            the certificate image are deleted.{" "}
            <strong>A deleted certificate is gone for good.</strong>
            <br />
            <br />
            Deleting certificates can take a while.
        </p>
    );

    const deleteMultiBody = (
        <SAlert id="delete-dialog" status="warning" role="alert" noDismiss>
            <div>
                This is the primary certificate for a group of certificates. If you delete <br />{" "}
                this certificate, these associated certificates will also be deleted:
                <ul className="pad-left-lg">
                    {multiCertChildList?.map(region => (
                        <li key={uuidv4()}>{`${region.exposureZone} ${region.certificateId}`}</li>
                    ))}
                </ul>
            </div>
        </SAlert>
    );

    return (
        <SDialog
            id={dialogName}
            open={props.isDeleteCertificateDialogOpen}
            onS-dismiss={() => {
                props.setIsDeleteCertificateDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="deleteCertificate">
                {deleteMultiple ? "Delete these certificates?" : "Delete the certificate?"}
            </div>
            <div slot="body">{isCertMulti ? deleteMultiBody : deleteSingleBody}</div>
            <div slot="footer">
                <button
                    className="secondary small"
                    disabled={isDeletingCertificate}
                    onClick={() => props.setIsDeleteCertificateDialogOpen(false)}>
                    Cancel
                </button>
                <button className={deleteButton} type="button" onClick={() => deleteCertificate()}>
                    Yes, delete the certificate
                </button>
            </div>
        </SDialog>
    );
}

export default DeleteCertificateDialog;
