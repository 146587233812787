import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { SRow, SCol, SIcon, SInputExtended } from "@avalara/skylab-react";
import BulkUploadCertificatesGrid from "./BulkUploadCertificatesGrid";
import { fetchBulkUploadCertificates, selectZipCount } from "../../../app/bulkUploadSlice";
import { getPageTitle } from "../../../shared/sessionUtility";
import CustomerQuickSearch from "../../sharedComponents/CustomerQuickSearch";
import BulkUploadCertificateDialog from "../../sharedDialogs/BulkUploadCertificateDialog";

const BulkUploadCertificates = React.memo(() => {
    const dispatch = useDispatch();
    const zipCount = useSelector(selectZipCount, shallowEqual);
    const [isUploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [searchedValue, setSearchedValue] = useState("");

    useEffect(() => {
        dispatch(fetchBulkUploadCertificates());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const refreshGrid = () => {
        if (searchedValue.trim()) dispatch(fetchBulkUploadCertificates(searchedValue, null));
        else dispatch(fetchBulkUploadCertificates());
    };

    const uploadFile = () => {
        setUploadDialogOpen(true);
    };

    const handleSearch = async value => {
        setSearchedValue(value);
        if (value !== null && value.length !== 0) {
            setTimeout(async () => {
                await dispatch(fetchBulkUploadCertificates(value.trim(), null));
            }, 400);
        }
    };

    const clearSearch = async value => {
        setSearchedValue("");
        await dispatch(fetchBulkUploadCertificates(value, null));
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Uploaded ZIP files of certificates"))}</title>
            </Helmet>

            <div className="dl-search">
                <div className="cx-quick-search">
                    <CustomerQuickSearch />
                </div>
                <h1 className="line-height-md margin-bottom-xl">
                    Uploaded ZIP files of certificates
                </h1>
                <SRow>
                    <SCol span="4" className="text-left margin-top-sm">
                        <label htmlFor="zip-file-search"> Search by file name</label>
                        <SInputExtended
                            autofocus
                            inputId="zip-file-search"
                            aria-label="Search"
                            type="search"
                            value={searchedValue}
                            placeholder="Search"
                            onS-clear={() => {
                                clearSearch("");
                            }}
                            onS-input={e => {
                                handleSearch(e.detail.value);
                            }}
                            onS-search={e => {
                                handleSearch(e.detail.value);
                            }}
                        />
                    </SCol>
                    <SCol span={8} className="text-right pad-top-xl">
                        <button className="tertiary secondry inline" onClick={uploadFile}>
                            <SIcon
                                name="upload"
                                className="pad-right-xs"
                                aria-label="Upload a ZIP file"
                                aria-hidden="true"
                            />
                            Upload a ZIP file
                        </button>
                        <button
                            className="tertiary secondry inline margin-bottom-sm"
                            onClick={refreshGrid}>
                            <SIcon name="refresh" aria-label="Refresh" /> Refresh
                        </button>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span={12} className="text-right margin-top-md">
                        <span>{zipCount} ZIPs uploaded</span>
                    </SCol>
                </SRow>
                <BulkUploadCertificatesGrid />
                <BulkUploadCertificateDialog
                    isBulkUploadZipDialogOpen={isUploadDialogOpen}
                    closeUploadDialog={setUploadDialogOpen}
                    refreshGrid={refreshGrid}
                    uploadedFromPending={false}
                />
            </div>
        </React.Fragment>
    );
});

export default BulkUploadCertificates;
