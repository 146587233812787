import { SLoader } from "@avalara/skylab-react";
import React from "react";

function Loader(props) {
    return (
        <div className="flex dl-flex-fill-height dl-flex-center">
            <h3>Loading ...</h3>
            <SLoader
                id="page-loader"
                className="medium"
                aria-live="polite"
                loading={props.loading}
            />
        </div>
    );
}

export default Loader;
