import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { selectRetailSettings } from "../../../../app/retailSettingsSlice";
import { allSettings } from "../../../../shared/constants";

const EditRetailSettingsTable = React.memo(props => {
    const retailSettings = useSelector(selectRetailSettings, shallowEqual);
    const { retailSettingsPayload } = props;

    const getCurrentId = option => {
        return retailSettings.find(x => x.option === option).id;
    };
    const isChecked = option => {
        const setting = retailSettingsPayload.find(x => x.option === option);
        return setting?.isActive;
    };
    const handleRetailCheckBoxChange = e => {
        props.setIsRetailSettingUpdated(true);
        props.setRetailSettingsPayload(
            retailSettingsPayload.map(setting =>
                setting.retailId === parseInt(e.target.id, 10)
                    ? { ...setting, isActive: !setting.isActive }
                    : setting
            )
        );
    };
    const settingsCheckbox = option => {
        return (
            <div className="pad-bottom-sm">
                <input
                    type="checkbox"
                    id={getCurrentId(option)}
                    name={option}
                    checked={isChecked(option)}
                    onChange={e => handleRetailCheckBoxChange(e)}
                />
                <label htmlFor={getCurrentId(option)}>{allSettings[option].label}</label>
            </div>
        );
    };
    return (
        <div className="row">
            <div className="column-1">
                <s-table-container>
                    <table className="retailSettings-table" role="presentation">
                        <thead>
                            <tr>
                                <th>Retail certificate features</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="3">
                                    <div>
                                        <h4 className="margin-top-none font-bold">
                                            Search results
                                        </h4>
                                        {settingsCheckbox("show_no_cert")}
                                        {settingsCheckbox("show_historical")}
                                        {settingsCheckbox("show_no_pending")}
                                    </div>
                                    <div className="pad-top-sm">
                                        <h4 className="margin-top-none font-bold">
                                            Certificate creation
                                        </h4>
                                        {settingsCheckbox("fill_only")}
                                        {settingsCheckbox("upload_form_unavailable")}
                                        {settingsCheckbox("append_barcode")}
                                        {settingsCheckbox("certid_footer")}
                                    </div>
                                    <div className="pad-top-sm">
                                        <h4 className="margin-top-none font-bold">
                                            Certificate management
                                        </h4>
                                        {settingsCheckbox("submit_to_stack")}
                                        {settingsCheckbox("preview")}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </s-table-container>
            </div>
        </div>
    );
});

export default EditRetailSettingsTable;
