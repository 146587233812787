import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import {
    getSavedSearchesAsync,
    selectSavedSearches,
    selectIsLoading,
} from "../../app/savedSearchesSlice";

function SelectSavedSearch(props, ref) {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoading);
    const savedSearches = useSelector(selectSavedSearches).map(element => {
        const selectOption = {
            label: element.title,
            value: element.criteria,
            selected: false,
        };

        if (props.value?.includes(element.title)) {
            selectOption.selected = true;
        }
        return selectOption;
    });

    useEffect(() => {
        if (props.searchType) dispatch(getSavedSearchesAsync(props.searchType));
        else dispatch(getSavedSearchesAsync());
    }, [dispatch]); // eslint-disable-line
    return (
        <React.Fragment>
            <label
                htmlFor={getNextId()}
                className={props.required ? "required" : ""}
                aria-required={props.required}
                id={`lbl-${getCurrentId()}`}>
                {props.label}
            </label>
            <SSelect
                name="savedSearch"
                inputId={getCurrentId()}
                ref={ref}
                loading={isLoading}
                selectionOptional
                optionsList={savedSearches}
                onS-select={e => props?.onAdd(e.detail.item)}
                onS-deselect={e => props?.onRemove(e.detail.item)}
                className={props.error ? props.error : ""}
                disabled={props.disabled ? "disabled" : null}
            />
        </React.Fragment>
    );
}
export default React.forwardRef(SelectSavedSearch);
