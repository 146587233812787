const expirationTimeFrames = [
    { id: "NVR", label: "Never Expire" },
    { id: "IMM", label: "Immediately Expire" },
    { id: "EOM", label: "Month End" },
    { id: "EOM+months", label: "Month End + x Months" },
    { id: "EOM+days", label: "Month End + x Days" },
    { id: "EOY", label: "Year End" },
    { id: "EOY+months", label: "Year End + x Months" },
    { id: "EOY+days", label: "Year End + x Days" },
    { id: "months", label: "+ x Months" },
    { id: "months+EOM", label: "+ x Months, then at End of Month" },
    { id: "months+EOY", label: "+ x Months, then at End of Year" },
    { id: "days", label: "+ x Days" },
    { id: "days+EOM", label: "+ x Days, then at End of Month" },
    { id: "days+EOY", label: "+ x Days, then at End of Year" },
    { id: "OVR", label: "Expiration Manually Provided" },
];

export default expirationTimeFrames;
