import React, { useEffect, useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Buffer } from "buffer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";
import { SRow, SCol, SIcon, SBox, SLoader } from "@avalara/skylab-react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, Modifier, genKey } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DOMPurify from "dompurify";
import {
    postCoverLetter,
    postOutgoingCoverLetter,
    fetchOutgoingCoverLetterById,
    fetchCoverLetterByIdAPI,
    putCoverLetter,
    selectCoverLetterPreview,
    clearCoverLetterPreview,
    setIsCoverLetterValid,
    selectLoading,
    fetchTemplateTags,
    selectTemplateTags,
    selectIsTemplateTagsLoading,
    selectPreviewLoading,
} from "../../app/coverLetterSlice";
import { getPageTitle, isEligibleUser } from "../../shared/sessionUtility";
import "./coverLetter.css";
import featureFlag from "../../featureToggler/featureFlag";
import { noErrorTag } from "../../shared/Utils";

const CoverLetter = React.memo(props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const coverLetterObj = useSelector(selectCoverLetterPreview, shallowEqual);
    const isLoading = useSelector(selectLoading);
    const isEligibleECMUserCoverLetterFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.coverLetter)
    );
    const isCoverLetterLoading = useSelector(selectPreviewLoading);
    const isTemplateTagsLoading = useSelector(selectIsTemplateTagsLoading);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const initialState = {
        title: coverLetterObj.title ? coverLetterObj.title : "",
        description: coverLetterObj.description ? coverLetterObj.description : "",
        subject: coverLetterObj.subject ? coverLetterObj.subject : "",
    };
    const [title, setTitle] = useState(initialState.title);
    const [isDefaultTitle, setIsDefaultTitle] = useState(false);
    const [isTitleDisabled, setIsTitleDisabled] = useState(false);
    const [titleError, setTitleError] = useState("");
    const [description, setDescription] = useState(initialState.description);
    const [descriptionError, setDescriptionError] = useState("");
    const [subject, setSubject] = useState(initialState.subject);
    const [subjectError, setSubjectError] = useState("");
    const [htmlContentError, sethtmlContentError] = useState("");
    const [invalidTagError, setInvalidTagError] = useState("");
    const saveButton = classnames({ primary: true, large: true, loading: isLoading });
    const templateTagsObj = useSelector(selectTemplateTags, shallowEqual);
    const [isCompanyLogo, setIsCompanyLogo] = useState(false);
    const [CompanyLogoError, setCompanyLogoError] = useState("");
    const [isBarcode, setIsBarcode] = useState(false);
    const [barcodeError, setBarcodeError] = useState("");
    const companyLogohtml = `<p style="text-align:right;">{{COMPANY_LOGO}}</p>`;
    const barCodehtml = `<p>{{BARCODE}}</p>`;
    const currentHtmlContent = draftToHtml(convertToRaw(editorState?.getCurrentContent()));
    const generalTags = [];
    const recipientTags = [];
    const senderTags = [];
    const certExpressTags = [];
    const deprecatedTags = [];
    const [buttonLock, setButtonLock] = useState(false);
    const defaultTags = [
        {
            name: "COMPANY_LOGO",
            displayName: "Company logo",
            description: "Company logo uploaded from settings",
            key: "logo",
            type: "system",
        },
        {
            name: "BARCODE",
            displayName: "Barcode",
            description: "Barcode containing customer information",
            key: "barcode",
            type: "system",
        },
    ];
    const pgTitle = !props.isVendor ? "Create a request template" : "Create a vendor template";

    if (templateTagsObj.length > 0) {
        templateTagsObj?.reduce(
            (i, j) => {
                i[j?.type]?.push(j);
                return i;
            },
            {
                general: generalTags,
                recipient: recipientTags,
                sender: senderTags,
                CertExpress: certExpressTags,
                deprecated: deprecatedTags,
            }
        );
    }

    const checkLock = () => {
        if (title.trim() !== "" && description.trim() !== "" && subject.trim() !== "") {
            setButtonLock(false);
        } else {
            setButtonLock(true);
        }
    };

    const getCoverLetter = useCallback(
        id => {
            if (!props.isVendor) {
                dispatch(fetchCoverLetterByIdAPI(id));
            } else {
                dispatch(fetchOutgoingCoverLetterById(id));
            }
        },
        [dispatch]
    );

    async function backToCoverletters() {
        const ending = !props.isVendor ? "requests" : "vendors";
        navigate(`/cover-letters/${ending}`);
    }

    const getTemplateTags = useCallback(() => {
        dispatch(fetchTemplateTags());
    }, [dispatch]);

    useEffect(() => {
        if (params?.id) getCoverLetter(Number.parseInt(params?.id, 10));

        getTemplateTags();
    }, [getCoverLetter, getTemplateTags, params?.id]);

    useEffect(() => {
        return () => {
            dispatch(clearCoverLetterPreview());
            setEditorState(EditorState.createEmpty());
            dispatch(setIsCoverLetterValid(false));
        };
    }, [dispatch]);

    useEffect(() => {
        checkLock();
    }, [description, title, subject]);

    useEffect(() => {
        if (Object.keys(coverLetterObj).length !== 0) {
            setTitle(coverLetterObj.title);
            if (coverLetterObj.isSystem) {
                setIsTitleDisabled(true);
            } else {
                setIsTitleDisabled(false);
            }
            setDescription(coverLetterObj.description);
            setSubject(coverLetterObj.subject);
            if (coverLetterObj?.htmlContent) {
                const htmlstring = Buffer.from(coverLetterObj?.htmlContent, "base64").toString(
                    "utf8"
                );
                // patch for draft js content beginning with image tag start
                let htmlContent = "";
                const startWithImg = htmlstring?.slice(0, 4);
                if (startWithImg === "<img") {
                    htmlContent = htmlstring.replace(/^/, "<p></p>");
                } else {
                    htmlContent = htmlstring;
                }
                // patch for draft js content beginning with image tag end

                setIsBarcode(htmlstring.includes(`{{BARCODE}}`));
                setIsCompanyLogo(htmlstring.includes(`{{COMPANY_LOGO}}`));
                const contentBlocks = htmlToDraft(htmlContent);
                const contentState = ContentState.createFromBlockArray(contentBlocks);
                setEditorState(EditorState.createWithContent(contentState));
            }
        }
    }, [
        coverLetterObj,
        coverLetterObj.description,
        coverLetterObj.htmlContent,
        coverLetterObj.subject,
        coverLetterObj.title,
    ]);

    const getHtmlcontent = es => {
        return draftToHtml(convertToRaw(es.getCurrentContent())).trim();
    };

    const handleChangeInput = setter => {
        return event => {
            if (event.target.value) {
                if (event.target.name === "title") {
                    if (coverLetterObj.isSystem) {
                        setTitleError("error");
                        setIsDefaultTitle(true);
                    } else {
                        setTitleError("");
                        setIsDefaultTitle(false);
                    }
                }
                if (event.target.name === "description") {
                    setDescriptionError("");
                }
                if (event.target.name === "subject") {
                    setSubjectError("");
                }
            }
            setter(event.target.value);
        };
    };

    const tagValidation = () => {
        const currentTemplate = getHtmlcontent(editorState);
        const tags = [...currentTemplate.matchAll(/\{{(.*?)\}}/g)];

        const usedTags = [];
        tags.forEach(ele => {
            usedTags.push({ name: ele[1] });
        });

        const elements = ["name"];
        const ecmTags = [...templateTagsObj, ...defaultTags, ...deprecatedTags];

        const invalidTags = usedTags
            .filter(o1 => {
                return !ecmTags.some(o2 => {
                    return o1.name === o2.name;
                });
            })
            .map(o => {
                return elements.reduce((newo, name) => {
                    newo[name] = o[name]; // eslint-disable-line
                    return newo;
                }, {});
            });

        const seperatedtags = invalidTags
            .map(i => {
                return i.name;
            })
            .join(", ");
        if (seperatedtags) {
            sethtmlContentError("editor-error");
            setInvalidTagError(`Template Tags can not be altered.`);
            return false;
        }
        sethtmlContentError("");
        setInvalidTagError("");
        return true;
    };

    const checkValidation = () => {
        let isValid = true;
        isValid = tagValidation();
        if (!title) {
            isValid = false;
            setTitleError("error");
        } else if (isDefaultTitle) {
            setTitleError("error");
            isValid = false;
        } else {
            setTitleError("");
        }
        if (!description) {
            setDescriptionError("error");
            isValid = false;
        } else {
            setDescriptionError("");
        }
        if (!subject) {
            setSubjectError("error");
            isValid = false;
        } else {
            setSubjectError("");
        }

        if (getHtmlcontent(editorState).length === 7) {
            sethtmlContentError("editor-error");
            isValid = false;
        } else {
            sethtmlContentError("");
        }
        return isValid;
    };

    const handleSubmitForm = async event => {
        event.preventDefault();
        if (checkValidation()) {
            const template = DOMPurify.sanitize(getHtmlcontent(editorState));

            if (Object.keys(coverLetterObj).length === 0) {
                const createCoverLetter = {
                    title,
                    description,
                    subject,
                    htmlContent: Buffer.from(template).toString("base64"),
                };
                if (!props.isVendor) {
                    await dispatch(postCoverLetter(createCoverLetter)).then(response => {
                        if (response.status === 201) {
                            backToCoverletters();
                        }
                    });
                } else {
                    await dispatch(postOutgoingCoverLetter(createCoverLetter)).then(response => {
                        if (response.status === 201) {
                            backToCoverletters();
                        }
                    });
                }
            } else {
                const updateCoverLetter = {
                    id: coverLetterObj.id,
                    title,
                    description,
                    subject,
                    htmlContent: Buffer.from(template).toString("base64"),
                };

                await dispatch(putCoverLetter(updateCoverLetter)).then(response => {
                    if (typeof response.data === "object") {
                        backToCoverletters();
                    }
                });
            }
        }
    };

    const onEditorChange = e => {
        const existingContent = getHtmlcontent(editorState);
        const currentContent = getHtmlcontent(e);
        if (currentContent !== existingContent) {
            setIsBarcode(currentContent.includes(`{{BARCODE}}`));
            setIsCompanyLogo(currentContent.includes(`{{COMPANY_LOGO}}`));
            sethtmlContentError("");
            if (tagValidation()) {
                sethtmlContentError("");
                setInvalidTagError("");
            }
        }
        setEditorState(e);
    };

    const handleCancel = () => {
        backToCoverletters();
    };

    const insertText = text => {
        const currentContent = editorState.getCurrentContent();
        const currentSelection = editorState.getSelection();
        const newContent = Modifier.replaceText(currentContent, currentSelection, text);
        const newEditorState = EditorState.push(editorState, newContent, "insert-characters");
        return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
    };

    const sendTextToEditor = text => {
        const updatedText = `{{${text}}}`;
        setEditorState(insertText(updatedText));
    };

    const replaceText = (tag, htmlstring) => {
        let removedContent = currentHtmlContent.replace(htmlstring, "");
        if (removedContent === currentHtmlContent) {
            removedContent = currentHtmlContent.replace(`${tag}`, "");
        }
        const contentBlocks = htmlToDraft(removedContent);
        const contentState = ContentState.createFromBlockArray(contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
    };

    const insertTextOnLocation = (position, htmlstring) => {
        let newContent = "";
        if (position === "start") {
            newContent = htmlToDraft(`${htmlstring}${currentHtmlContent}`);
        }
        if (position === "end") {
            newContent = htmlToDraft(`${currentHtmlContent} ${htmlstring}`);
        }
        const contentState = ContentState.createFromBlockArray(newContent);
        setEditorState(EditorState.moveFocusToEnd(EditorState.createWithContent(contentState)));
    };

    const handleCheckboxChange = setter => {
        return event => {
            if (event.target.checked) {
                if (event.target.id === "companyLogo") {
                    setCompanyLogoError();
                    if (!currentHtmlContent.includes(`{{COMPANY_LOGO}}`)) {
                        insertTextOnLocation("start", companyLogohtml);
                        setter(event.target.checked);
                    } else {
                        setter(!event.target.checked);
                        setCompanyLogoError("error");
                    }
                }
                if (event.target.id === "barCode") {
                    setBarcodeError();
                    if (!currentHtmlContent.includes(`{{BARCODE}}`)) {
                        insertTextOnLocation("end", barCodehtml);
                        setter(event.target.checked);
                    } else {
                        setter(!event.target.checked);
                        setBarcodeError("error");
                    }
                }
            } else {
                if (
                    event.target.id === "companyLogo" &&
                    currentHtmlContent.includes(`{{COMPANY_LOGO}}`)
                ) {
                    replaceText(`{{COMPANY_LOGO}}`, companyLogohtml);
                }
                if (event.target.id === "barCode" && currentHtmlContent.includes(`{{BARCODE}}`)) {
                    replaceText(`{{BARCODE}}`, barCodehtml);
                }
                setter(event.target.checked);
            }
            return editorState;
        };
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle(pgTitle))}</title>
            </Helmet>
            {isCoverLetterLoading ? (
                <div className="flex flex-dir-col dl-flex-center">
                    <h3>Loading ...</h3>
                    <SLoader id="grid-loader" className="medium" aria-live="polite" loading />
                </div>
            ) : (
                <>
                    <SRow>
                        <SCol>
                            <h1 className="margin-top-md">
                                {Object.keys(coverLetterObj).length === 0
                                    ? pgTitle
                                    : coverLetterObj.title}
                            </h1>
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol>
                            <button
                                className="ghost-blue icon-leading pad-left-none"
                                onClick={backToCoverletters}>
                                <SIcon name="arrow-left" aria-hidden="true" />
                                Back to templates
                            </button>
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol>
                            <label className="text-md" aria-labelledby="title subject">
                                Name and description
                            </label>
                        </SCol>
                        <SCol span="6">
                            <label htmlFor="title" className="required">
                                Give your template a name
                            </label>
                            <input
                                id="title"
                                name="title"
                                type="text"
                                value={title}
                                className={titleError || noErrorTag}
                                onChange={handleChangeInput(setTitle)}
                                disabled={isTitleDisabled}
                            />
                            {titleError && isDefaultTitle ? (
                                <div className="input-msg">
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                    <span>
                                        There is already a template with that name. Try something
                                        different.
                                    </span>
                                </div>
                            ) : (
                                <div className="input-msg">
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                    <span>Enter a template name</span>
                                </div>
                            )}
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span="6">
                            <label htmlFor="subject" className="required">
                                Email subject line
                            </label>
                            <input
                                id="subject"
                                name="subject"
                                type="text"
                                value={subject}
                                className={subjectError || noErrorTag}
                                onChange={handleChangeInput(setSubject)}
                            />
                            {subjectError ? (
                                <div className="input-msg">
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                    <span>Enter a subject line</span>
                                </div>
                            ) : null}
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span="6">
                            <label htmlFor="description" id="lbl-description" className="required">
                                Description
                            </label>
                            <input
                                id="description"
                                name="description"
                                type="text"
                                value={description}
                                className={descriptionError || noErrorTag}
                                onChange={handleChangeInput(setDescription)}
                            />
                            {descriptionError ? (
                                <div className="input-msg">
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                    <span>Enter a description</span>
                                </div>
                            ) : null}
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span="6">
                            <SRow>
                                <SCol span="6">
                                    <label className="text-md margin-top-xs">Company logo</label>
                                </SCol>
                                <SCol span="6">
                                    <label className="text-md margin-top-xs">Bar code</label>
                                </SCol>
                            </SRow>
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span="6">
                            <SRow>
                                <SCol span="6">
                                    <input
                                        type="checkbox"
                                        id="companyLogo"
                                        name="companyLogo"
                                        checked={isCompanyLogo}
                                        onChange={handleCheckboxChange(setIsCompanyLogo)}
                                    />
                                    <label htmlFor="companyLogo" className="margin-top-none">
                                        Include the company logo at right of the message
                                    </label>
                                    {CompanyLogoError ? (
                                        <div className="input-msg">
                                            <SIcon
                                                name="alert-circle-filled"
                                                aria-hidden="true"
                                                className="erroricon"
                                            />
                                            <span className="errorspan">
                                                Remove the existing company logo first, then try
                                                again.
                                            </span>
                                        </div>
                                    ) : null}
                                </SCol>
                                <SCol span="6">
                                    <input
                                        type="checkbox"
                                        id="barCode"
                                        name="barCode"
                                        checked={isBarcode}
                                        onChange={handleCheckboxChange(setIsBarcode)}
                                    />
                                    <label htmlFor="barCode" className="margin-top-none">
                                        Include a bar code at the lower left
                                    </label>
                                    {barcodeError ? (
                                        <div className="input-msg">
                                            <SIcon
                                                name="alert-circle-filled"
                                                aria-hidden="true"
                                                className="erroricon"
                                            />
                                            <span className="errorspan">
                                                Remove the existing bar code first, then try again.
                                            </span>
                                        </div>
                                    ) : null}
                                </SCol>
                            </SRow>
                        </SCol>
                    </SRow>

                    <SRow>
                        <SCol span="6">
                            <h3 className="margin-bottom-none">Message</h3>
                            <label className="required margin-top-none">
                                Provide the message you&apos;d like recipients of requests sent
                                using this template to see
                            </label>
                            <SBox className={htmlContentError}>
                                <Editor
                                    id="Editor"
                                    key={genKey}
                                    spellCheck
                                    handlePastedText={() => false}
                                    editorStyle={{ height: "385px" }}
                                    wrapperClassName={`editor-wrapper `}
                                    editorState={editorState}
                                    defaultEditorState={editorState}
                                    onEditorStateChange={onEditorChange}
                                    toolbar={{
                                        options: [
                                            "inline",
                                            "blockType",
                                            "fontSize",
                                            "list",
                                            "textAlign",
                                            "colorPicker",
                                            "link",
                                            "history",
                                        ],
                                        inline: {
                                            options: [
                                                "bold",
                                                "italic",
                                                "underline",
                                                "strikethrough",
                                            ],
                                            bold: {},
                                            italic: {},
                                            underline: {},
                                            strikethrough: {},
                                            code: {},
                                        },
                                        blockType: {},
                                        fontSize: {},
                                        list: {
                                            inDropdown: true,
                                        },
                                        textAlign: {
                                            inDropdown: true,
                                        },
                                        fontFamily: {
                                            dropdownClassName: "",
                                        },
                                        colorPicker: {
                                            popupClassName: "",
                                        },
                                        link: {
                                            popupClassName: "",
                                        },
                                        image: {
                                            className: "",
                                            popupClassName: "",
                                        },
                                        history: {
                                            undo: {},
                                            redo: {},
                                        },
                                    }}
                                />
                            </SBox>
                            {htmlContentError && invalidTagError.length === 0 ? (
                                <div className="input-msg">
                                    <SIcon
                                        name="alert-circle-filled"
                                        aria-hidden="true"
                                        className="erroricon"
                                    />
                                    <span className="errorspan">Enter a Message</span>
                                </div>
                            ) : null}

                            {invalidTagError ? (
                                <div className="input-msg">
                                    <SIcon
                                        name="alert-circle-filled"
                                        aria-hidden="true"
                                        className="erroricon"
                                    />
                                    <span className="errorspan">{invalidTagError}</span>
                                </div>
                            ) : null}
                        </SCol>
                        <SCol span="6">
                            <h3 className="margin-bottom-none">Merge fields</h3>
                            <label className="required margin-top-none">
                                Choose which fields you want to add to your message.The company logo
                                fits best above the content of the message because of its size. The
                                barcode always appears at the bottom left, regardless of where you
                                the field in the template.
                            </label>

                            {isTemplateTagsLoading ? (
                                <div className="flex flex-dir-col dl-flex-center">
                                    <h3>Loading ...</h3>
                                    <SLoader
                                        id="grid-loader"
                                        className="medium"
                                        aria-live="polite"
                                        loading
                                    />
                                </div>
                            ) : (
                                <SBox id="mergeFieldBox">
                                    <SRow>
                                        <SCol span="5">
                                            <h4 className="margin-top-none  margin-bottom-xs">
                                                General fields
                                            </h4>
                                            {generalTags?.map(generalItems => {
                                                return (
                                                    <React.Fragment key={uuidv4()}>
                                                        <button
                                                            className="secondary button-template-field text-ellipsis margin-bottom-xs"
                                                            id={generalItems.key}
                                                            onClick={() =>
                                                                sendTextToEditor(generalItems.name)
                                                            }>
                                                            {generalItems.displayName}
                                                        </button>
                                                        <br />
                                                    </React.Fragment>
                                                );
                                            })}

                                            <h4 className="margin-bottom-xs">CertExpress links</h4>
                                            {certExpressTags?.map(certexpItems => {
                                                return (
                                                    <React.Fragment key={uuidv4()}>
                                                        <button
                                                            className="secondary button-template-field text-ellipsis margin-bottom-xs"
                                                            id={certexpItems.key}
                                                            onClick={() =>
                                                                sendTextToEditor(certexpItems.name)
                                                            }>
                                                            {certexpItems.displayName}
                                                        </button>
                                                        <br />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </SCol>
                                        <SCol span="3">
                                            <h4 className="margin-top-none  margin-bottom-xs">
                                                Recipient fields
                                            </h4>
                                            {recipientTags?.map(recipientItems => (
                                                <React.Fragment key={uuidv4()}>
                                                    <button
                                                        className="secondary button-template-field text-ellipsis margin-bottom-xs"
                                                        id={recipientItems.key}
                                                        onClick={() =>
                                                            sendTextToEditor(recipientItems.name)
                                                        }>
                                                        {props.isVendor
                                                            ? recipientItems.displayName.replace(
                                                                  "Customer",
                                                                  "Vendor"
                                                              )
                                                            : recipientItems.displayName}
                                                    </button>
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </SCol>
                                        <SCol span="4">
                                            <h4 className="margin-top-none  margin-bottom-xs">
                                                Sender fields
                                            </h4>
                                            {senderTags?.map(senderItems => (
                                                <React.Fragment key={uuidv4()}>
                                                    <button
                                                        className="secondary button-template-field text-ellipsis margin-bottom-xs"
                                                        id={senderItems.key}
                                                        onClick={() =>
                                                            sendTextToEditor(senderItems.name)
                                                        }>
                                                        {senderItems.displayName}
                                                    </button>
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </SCol>
                                    </SRow>
                                </SBox>
                            )}
                        </SCol>
                    </SRow>
                    <SRow className="margin-top-md">
                        <SCol>
                            <button
                                id="btnSave"
                                className={saveButton}
                                disabled={buttonLock || !isEligibleECMUserCoverLetterFlag}
                                onClick={handleSubmitForm}>
                                Save
                            </button>
                            <button
                                disabled={!isEligibleECMUserCoverLetterFlag}
                                className="tertiary large"
                                onClick={handleCancel}>
                                Cancel
                            </button>
                        </SCol>
                    </SRow>
                </>
            )}
        </React.Fragment>
    );
});

export default CoverLetter;
