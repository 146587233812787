import React from "react";
import { Buffer } from "buffer";
import { SDialog } from "@avalara/skylab-react";
import parse from "html-react-parser";
import { formatTemplate } from "../../../shared/Utils";

function PreviewCoverLetterDialog(props) {
    const htmlContent = Buffer.from(props?.coverLetter?.htmlContent, "base64").toString("utf8");

    return (
        <SDialog
            id="previewCoverLetterDialog"
            open={props.isPreviewCoverLetterDialogOpen}
            onS-dismiss={() => {
                props.setIsPreviewCoverLetterDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="previewCoverLetter">
                Template preview
            </div>

            <div slot="body"> {parse(formatTemplate(htmlContent))}</div>
            <div slot="footer">
                <button
                    className="primary small"
                    onClick={() => props.setIsPreviewCoverLetterDialogOpen(false)}>
                    Close
                </button>
            </div>
        </SDialog>
    );
}

export default PreviewCoverLetterDialog;
