import React from "react";
import { SRow, SCol } from "@avalara/skylab-react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { v4 as uuidv4 } from "uuid";
import { selectStaticGridData, setIsEditClicked } from "../../app/roleManagementSlice";
import featureFlag from "../../featureToggler/featureFlag";
import { isEligibleUser } from "../../shared/sessionUtility";

function UserRoleStaticInfo() {
    const dispatch = useDispatch();
    const gridData = useSelector(selectStaticGridData, shallowEqual);
    const isUserCanViewRetailLocation = dispatch(
        isEligibleUser(featureFlag.userRoleManagement.assignRetailLocation)
    );

    const headerColumns = [
        gridHeader(
            "Company name",
            "name",
            value => <span name="companyName">{value}</span>,
            null,
            "",
            null,
            null,
            null,
            "name"
        ),
        gridHeader(
            "Services",
            "service",
            value => <span name="service"> {value} </span>,
            null,
            "",
            null,
            null,
            null,
            "service"
        ),
        gridHeader(
            "Role",
            "role",
            value => <span name="role"> {value} </span>,
            null,
            "",
            null,
            null,
            null,
            "role"
        ),
    ];
    if (isUserCanViewRetailLocation) {
        headerColumns.push(
            gridHeader(
                "Locations",
                "location",
                value => <span name="locations"> {value} </span>,
                null,
                "",
                null,
                null,
                null,
                "location"
            )
        );
    }

    const getRowKey = () => {
        return uuidv4();
    };

    const handleEdit = () => {
        dispatch(setIsEditClicked(true));
    };

    return (
        <>
            <div className="pad-left-sm pad-top-sm">
                <SRow>
                    <label className="text-md">Access and permissions</label>
                </SRow>
                <SRow>
                    Following table is showing the user what role they can acess to in specific
                    company and services.
                </SRow>
                <SRow>
                    <SCol span="10" className="pad-left-none">
                        <div className="pad-bottom-sm pad-top-sm">
                            <s-table-container id="allow-overflow-on-grid">
                                <DataGrid
                                    columns={headerColumns}
                                    rows={gridData}
                                    getKey={getRowKey}
                                    inContainer
                                />
                            </s-table-container>
                        </div>
                    </SCol>
                </SRow>
            </div>
            <div>
                <hr />
                <SRow className="pad-left-sm pad-top-xs">
                    <button className="primary" onClick={handleEdit}>
                        Edit
                    </button>
                </SRow>
            </div>
        </>
    );
}

export default UserRoleStaticInfo;
