import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
    fetchCoverLetterByFiltersAPI,
    fetchDefaultCoverLetterAPI,
    fetchOutgoingLettersByAPI,
    selectCoverLetter,
    selectPreviewLoading,
    setCoverLetterPreview,
} from "../../app/coverLetterSlice";
import { getProduct } from "../../app/sessionSlice";
import featureFlag from "../../featureToggler/featureFlag";
import FeatureToggler from "../../featureToggler/FeatureToggler";
import { DefaultCoverLetters } from "../../shared/constants";
import { matchSubscriptions } from "../../shared/Utils";

const SelectCoverLetter = React.memo(props => {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectPreviewLoading);
    const coverLetters = useSelector(selectCoverLetter, shallowEqual);
    const getSubscription = useSelector(getProduct);
    const [coverLetterOptions, setCoverLetterOptions] = useState([]);

    useEffect(() => {
        if (coverLetters.length > 0) {
            const coverLetterToCheckFor = props.outgoing
                ? DefaultCoverLetters.vemDefault
                : DefaultCoverLetters.ecmDefault;
            const hasSystemDefaultCoverLetter = coverLetters.find(
                coverLetter => coverLetter.title === coverLetterToCheckFor
            );
            let defaultLetter;

            if (!hasSystemDefaultCoverLetter) {
                defaultLetter = coverLetters[0].title;
                dispatch(setCoverLetterPreview(coverLetters[0]));
            } else {
                defaultLetter = coverLetterToCheckFor;
                dispatch(setCoverLetterPreview(hasSystemDefaultCoverLetter));
            }

            setCoverLetterOptions(
                coverLetters?.map(element => {
                    return {
                        label: element.title,
                        value: element.title,
                        key: uuidv4(),
                        selected: element.title === defaultLetter,
                    };
                })
            );
        }
    }, [dispatch, coverLetters.length, props.outgoing]);

    useEffect(() => {
        if (
            matchSubscriptions(
                featureFlag.requestCertificates.coverLetter.subscription,
                getSubscription
            )
        ) {
            if (props.outgoing === true) {
                dispatch(fetchOutgoingLettersByAPI());
            } else {
                dispatch(fetchCoverLetterByFiltersAPI());
            }
        } else {
            dispatch(fetchDefaultCoverLetterAPI());
        }
    }, [dispatch, getSubscription]);

    const handleOnAdd = e => {
        dispatch(setCoverLetterPreview(coverLetters.find(x => x.title === e.label)));
    };

    return (
        <FeatureToggler category="requestCertificates" id="coverLetter">
            <label htmlFor={getNextId()} id={`lbl-${getCurrentId()}`} className={props?.labelClass}>
                {props.label}
            </label>
            <SSelect
                name="coverLetters"
                inputId={getCurrentId()}
                optionsList={coverLetterOptions}
                loading={isLoading}
                className={props.error ? props.error : ""}
                onS-select={e => handleOnAdd(e.detail.item)}
            />
        </FeatureToggler>
    );
});

export default SelectCoverLetter;
