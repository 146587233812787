import React, { useState } from "react";
import { SRow, SCol } from "@avalara/skylab-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import SelectOutgoingExposureZone from "../../../sharedComponents/SelectOutgoingExposureZone";
import SelectOutgoingExemptReasons from "../../../sharedComponents/SelectOutgoingExemptReasons";
import CollectionsDialog from "./CollectionDialog";
import {
    selectSendingCertificateReasons,
    selectSendingCertificateRegions,
    setSendingCertificateReasons,
    setSendingCertificateRegions,
    fetchCertificateRequestFormsAPI,
} from "../../../../app/settingsSlice";
import { isEligibleUser } from "../../../../shared/sessionUtility";
import featureFlag from "../../../../featureToggler/featureFlag";
import FeatureToggler from "../../../../featureToggler/FeatureToggler";

const CertificateOutgoingRequestFormsFilter = React.memo(() => {
    const dispatch = useDispatch();
    const reasons = useSelector(selectSendingCertificateReasons, shallowEqual);
    const regions = useSelector(selectSendingCertificateRegions, shallowEqual);
    const [openDialog, setOpenDialog] = useState(false);

    const isEligibleUserSettingsSendingCertficateExemptionMatrix = dispatch(
        isEligibleUser(featureFlag.settings.sendingCertficateExemptionMatrix)
    );

    const applyFilter = () => {
        dispatch(fetchCertificateRequestFormsAPI(true));
    };

    const handleSearch = async (id, e) => {
        if (id === "requestRegions" && e.label !== "All") {
            const requestRegion = { regionId: e.value, regionName: e.label };
            dispatch(setSendingCertificateRegions(requestRegion));
        }
        if (id === "requestReasons" && e.label !== "All") {
            const reason = { reasonId: e.value, reasonName: e.label };
            dispatch(setSendingCertificateReasons(reason));
        }
        applyFilter();
    };

    const handleRemove = async id => {
        if (id === "requestRegions") {
            dispatch(setSendingCertificateRegions(""));
        }
        if (id === "requestReasons") {
            dispatch(setSendingCertificateReasons("-1"));
        }
        applyFilter();
    };

    return (
        <React.Fragment>
            <SRow className="pad-top-sm">
                <SCol span="3">
                    <SelectOutgoingExposureZone
                        label="Region"
                        id="requestRegions"
                        name="requestRegions"
                        onAdd={e => handleSearch("requestRegions", e)}
                        onRemove={e => handleRemove("requestRegions", e)}
                        value={regions.regionId}
                        multiple={false}
                        exposureZoneDisabled={false}
                        disabled={false}
                        isAll
                    />
                </SCol>

                <SCol span="3">
                    <SelectOutgoingExemptReasons
                        name="requestReasons"
                        id="requestReasons"
                        onAdd={e => handleSearch("requestReasons", e)}
                        onRemove={e => handleRemove("requestReasons", e)}
                        value={reasons.reasonId}
                        multiple={false}
                        disabled={false}
                        isAll
                    />
                </SCol>
                <FeatureToggler category="settings" id="sendingCertficateExemptionMatrix">
                    <SCol span="auto">
                        <button
                            className="primary float-right margin-top-lg"
                            disabled={!isEligibleUserSettingsSendingCertficateExemptionMatrix}
                            onClick={() => {
                                setOpenDialog(true);
                            }}>
                            Add region and reason
                        </button>
                    </SCol>
                </FeatureToggler>
            </SRow>

            <div hidden={!openDialog}>
                <CollectionsDialog
                    diagStatus={openDialog}
                    setOpenDialog={setOpenDialog}
                    reload={applyFilter}
                />
            </div>
        </React.Fragment>
    );
});

export default CertificateOutgoingRequestFormsFilter;
