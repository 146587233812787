import React from "react";
import VendorPackagesForm from "./VendorPackagesForm";
import VendorPackagesGrid from "./VendorPackagesGrid";

const VendorPackages = React.memo(() => {
    const DOMelement = (
        <div className="margin-top-sm">
            Create a certificate package to group certificates that you regularly send to vendors.
            <div>
                <VendorPackagesForm />
                <VendorPackagesGrid />
            </div>
        </div>
    );
    return DOMelement;
});

export default VendorPackages;
