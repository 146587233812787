import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { SDialog, SRow, SCol, SInputExtended, SLoader } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import {
    selectIsCompanyLoading,
    setCompaniesSelectedCount,
    selectCompaniesSelectedCount,
    setCompanyInfo,
    selectCompanyInfo,
} from "../../app/roleManagementSlice";
import "./roleManagement.css";

function SelectCompanyDialog(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsCompanyLoading);
    const companyInfo = useSelector(selectCompanyInfo, shallowEqual);
    const companiesSelectedCount = useSelector(selectCompaniesSelectedCount);
    const [searchValue, setSearchValue] = useState("");
    const totalCompanyCount = companyInfo.length;

    const handleCheckBox = companyId => {
        let count = 0;
        dispatch(
            setCompanyInfo(
                companyInfo?.map(element => {
                    let companyData = element;
                    companyData = {
                        ...companyData,
                        isSelected:
                            element.id === companyId ? !element.isSelected : element.isSelected,
                    };
                    if (companyData.isSelected) count += 1;
                    return companyData;
                })
            )
        );
        dispatch(setCompaniesSelectedCount(count));
    };

    const closeDialog = async () => {
        props.closeDialog(false);
    };

    const headerColumns = [
        gridHeader(
            "Select",
            "isSelected",
            (value, row) => (
                <div>
                    <input
                        name={`${row.id}-select`}
                        id={`${row.id}-select`}
                        type="checkbox"
                        aria-label={`${row.name?.replace(/ /g, "")}-select`}
                        checked={value === true}
                        onChange={() => handleCheckBox(row.id)}
                        className="unlabeled"
                    />
                </div>
            ),
            null,
            "",
            null,
            null,
            null,
            "isSelected"
        ),
        gridHeader(
            "Company id",
            "id",
            value => <span name="id">{value}</span>,
            null,
            "",
            null,
            null,
            null,
            "id"
        ),
        gridHeader(
            "Company name",
            "name",
            value => <span name="name"> {value} </span>,
            null,
            "",
            null,
            null,
            null,
            "name"
        ),
        gridHeader(
            "Parent company",
            "parentCompanyId",
            value => (
                <span name="parent-company">
                    {value !== 0
                        ? companyInfo.filter(x => x.parentCompanyId === value)[0].parentCompanyId
                        : ""}
                </span>
            ),
            null,
            "",
            null,
            null,
            null,
            "parentCompanyId"
        ),
        gridHeader(
            "Status",
            "isActive",
            value => <span name="isActive"> {value ? "Active" : "InActive"} </span>,
            null,
            "",
            null,
            null,
            null,
            "isActive"
        ),
    ];

    const getRowKey = () => {
        return uuidv4();
    };

    const filterData = () => {
        if (searchValue === "") return companyInfo;
        return companyInfo.filter(x =>
            x.name?.toLowerCase()?.includes(searchValue?.trim()?.toLowerCase())
        );
    };

    let table = null;
    if (isLoading)
        table = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    else
        table =
            companyInfo.length > 0 ? (
                <s-table-container id="all-company-grid">
                    <DataGrid
                        columns={headerColumns}
                        rows={filterData()}
                        getKey={getRowKey}
                        inContainer
                    />
                </s-table-container>
            ) : (
                <h2>No companies found </h2>
            );

    return (
        <SDialog
            open={props.isOpen}
            id="select-company-dialog"
            noDismiss
            onS-dismiss={closeDialog}
            aria-modal="true">
            <div slot="header" id="selectCompany" className="inline">
                <SRow>
                    <SCol span="7" className="text-left">
                        <SInputExtended
                            autofocus
                            inputId="company-search"
                            aria-label="Search by company name"
                            type="search"
                            value={searchValue}
                            placeholder="Search by company name"
                            onS-clear={() => {
                                setSearchValue("");
                            }}
                            onS-input={e => {
                                setSearchValue(e.detail.value);
                            }}
                            onS-search={e => {
                                setSearchValue(e.detail.value);
                            }}
                        />
                    </SCol>
                    <SCol className="text-right text-sm-strong pad-top-lg" span="5">
                        <span>
                            {companiesSelectedCount} companies selected out of {totalCompanyCount}
                        </span>
                    </SCol>
                </SRow>
            </div>
            <div slot="body">{table}</div>
            <div slot="footer">
                <button className="secondary small" onClick={closeDialog}>
                    Close
                </button>
            </div>
        </SDialog>
    );
}

export default SelectCompanyDialog;
