import React from "react";
import { SBox } from "@avalara/skylab-react";
import { Link } from "react-router-dom";
import taxAnswersIcon from "./icons/tax_answers.svg";
import taxLawsIcon from "./icons/tax_laws.svg";
import exemptEntitiesIcon from "./icons/exempt_entities.svg";
import certificateLibraryIcon from "./icons/certificate_library.svg";
import avaUniversity from "./icons/avau_video.svg";

const Resources = React.memo(props => {
    return (
        <React.Fragment>
            <h1 className={props?.caller ? " margin-left-sm" : "margin-bottom-none"}> Resources</h1>
            <p className={props?.caller ? "hidden" : ""}>
                State-by-state resources for exemption compliance. Look up exemption requirements
                and tax laws in your state, download official state forms, and more.
            </p>
            <h3 className={props?.caller ? "hidden" : "margin-bottom-none font-bold"}>
                Compliance resources for every state:
            </h3>
            <div className="make-responsive margin-bottom-xs">
                <Link to="/resources/tax-answers" className="no-link">
                    <SBox className="standard-card-first">
                        <img src={taxAnswersIcon} className="resource-link" alt="Tax answers" />
                        <p className="card-label-link">
                            Find answers to resale exemption tax questions
                        </p>
                    </SBox>
                </Link>
                <Link to="/resources/tax-laws" className="no-link">
                    <SBox className="standard-card">
                        <img src={taxLawsIcon} className="resource-link" alt="Tax laws" />
                        <p className="card-label-link">Review exemption laws and legal citations</p>
                    </SBox>
                </Link>
                <Link to="/resources/exempt-entities" className="no-link">
                    <SBox className="standard-card">
                        <img
                            src={exemptEntitiesIcon}
                            className="entity-link"
                            alt="Exempt entities"
                        />
                        <p className="card-label-link">Look up tax-exempt business entities</p>
                    </SBox>
                </Link>
                <Link to="/resources/certificate-forms" className="no-link">
                    <SBox className="standard-card">
                        <img
                            src={certificateLibraryIcon}
                            className="icon-link"
                            alt="Certificate library"
                        />
                        <p className="card-label-link">
                            Download forms from the certificate library
                        </p>
                    </SBox>
                </Link>
                <Link
                    to="https://training.avalara.com/path/exemption-certificate-management"
                    className="no-link">
                    <SBox className="standard-card">
                        <img
                            src={avaUniversity}
                            className="icon-link"
                            alt="Avalara University ECM Video"
                        />
                        <p className="card-label-link">
                            Avalara University for <br /> ECM
                        </p>
                    </SBox>
                </Link>
            </div>
            <div className={props?.caller ? "hidden" : ""}>
                <h3 className="font-bold margin-bottom-xs">
                    Learn more about exemption certificates
                </h3>
                <ul className="margin-left-lg">
                    <li>
                        <a href="/resources/tax-answers" className="font-semibold text-underline">
                            Tax questions and answers:
                        </a>{" "}
                        Answers to state-specific resale exemption questions, provided by legal
                        researchers at Avalara.
                    </li>
                    <li>
                        <a href="/resources/tax-laws" className="font-semibold text-underline">
                            Exemption laws and legal texts:
                        </a>{" "}
                        View source texts for relevant exemption laws and legal citations in every
                        state.
                    </li>
                    <li>
                        <a
                            href="/resources/exempt-entities"
                            className="font-semibold text-underline">
                            Exempt business entities:
                        </a>{" "}
                        Look up tax-exempt businesses and organizations recognized in each state,
                        including state exemption requirements
                    </li>
                    <li>
                        <a
                            href="/resources/certificate-forms"
                            className="font-semibold text-underline">
                            Certificate library:
                        </a>{" "}
                        View and download state exemption certificate forms as blank documents or
                        prefilled with your company information
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
});

export default Resources;
