import React from "react";
import { SCol, SRow } from "@avalara/skylab-react";
import { Outlet, useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import { selectCustomer } from "../../../app/customerSlice";

function Customer() {
    const params = useParams();
    const customer = useSelector(selectCustomer, shallowEqual);

    return (
        <SRow>
            <SCol>
                <SRow>
                    <SCol className="font-semibold text-xl">
                        {params?.id ? customer?.name : "Add a customer tax exemption"}
                    </SCol>
                </SRow>
                <Outlet />
            </SCol>
        </SRow>
    );
}

export default Customer;
