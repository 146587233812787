import React from "react";
import { SDialog } from "@avalara/skylab-react";

const DiscardSettingsConfirmationDialog = React.memo(props => {
    const onCancel = () => {
        props.setIsDiscardEditSettingsDialogOpen(false);
    };
    const onLeave = () => {
        props.setIsDiscardEditSettingsDialogOpen(false);
        props.setOpenEditSettings(false);
    };
    return (
        <SDialog
            id="discardEditedSettingsDialog"
            open={props.isDiscardEditSettingsDialogOpen}
            onS-dismiss={onCancel}
            className="overflow-visible">
            <div slot="header">Leave without saving changes?</div>
            <div slot="body">
                <s-alert status="warning" nodismiss="">
                    <div>It looks like you were in middle of adding or editing something.</div>
                </s-alert>
            </div>
            <div slot="footer">
                <button className="secondary" onClick={onCancel}>
                    No,stay here
                </button>
                <button className="primary right" id="save-label" type="button" onClick={onLeave}>
                    Yes, leave
                </button>
            </div>
        </SDialog>
    );
});

export default DiscardSettingsConfirmationDialog;
