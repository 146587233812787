import React, { useState } from "react";
import { SDialog, SRow, SCol, SIcon } from "@avalara/skylab-react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import toast from "../../hooks/toast";
import {
    selectIsExportSearchDialogOpen,
    setIsExportSearchDialogOpen,
} from "../../app/exportSearchSlice";
import {
    selectCustomerColumns,
    postCustomersExportAPI,
    selectIsLoadingCustomerExport,
} from "../../app/customerSlice";
import {
    getSelectedColumns,
    postCertificateExportApi,
    postPendingCertificateExportApi,
    selectIsLoadingCertificateExport,
    selectPendingCertificateSelectedColumns,
} from "../../app/certificateSlice";
import { noErrorTag } from "../../shared/Utils";

function ExportSearchDialog(props) {
    const dispatch = useDispatch();
    const [showToast, hideToast] = toast();
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");
    const customerLoading = useSelector(selectIsLoadingCustomerExport);
    const certificateLoading = useSelector(selectIsLoadingCertificateExport);
    const isExportSearchDialogOpen = useSelector(selectIsExportSearchDialogOpen);
    const saveClassName = classnames({
        primary: true,
        small: true,
        loading: props.exportType === "Customer" ? customerLoading : certificateLoading,
    });
    const customerColumns = useSelector(selectCustomerColumns, shallowEqual);
    const certificateColumns = useSelector(getSelectedColumns, shallowEqual);
    const pendingCertificateColumns = useSelector(
        selectPendingCertificateSelectedColumns,
        shallowEqual
    );

    const handleSubmitForm = async event => {
        event.preventDefault();
        if (title) {
            if (props.exportType === "Customer") {
                let columnList = customerColumns.filter(column => !column.hidden);
                columnList = columnList.map(column => column.fieldName ?? column.id);
                await dispatch(
                    postCustomersExportAPI(20, 1, columnList, title, showToast, hideToast)
                );
            } else if (props.exportType === "Certificate") {
                let columnList = certificateColumns.filter(column => !column.hidden);
                columnList = columnList.map(column => column.fieldName ?? column.id);
                await dispatch(
                    postCertificateExportApi(columnList, title, showToast, hideToast, 20, 1)
                );
            } else if (props.exportType === "Pending-Certificate") {
                let columnList = pendingCertificateColumns.filter(column => !column.hidden);
                columnList = columnList.map(column => column.fieldName ?? column.id);
                await dispatch(
                    postPendingCertificateExportApi(columnList, title, showToast, hideToast, 20, 1)
                );
            }
            setTitle("");
        } else {
            setTitleError("error");
        }
    };

    const onSDismiss = () => {
        dispatch(setIsExportSearchDialogOpen(false));
    };

    const handleChangeInput = event => {
        setTitle(event.target.value);
        setTitleError("");
    };

    return (
        <SDialog
            open={isExportSearchDialogOpen}
            id="ExportSearch-dialog"
            onS-dismiss={onSDismiss}
            aria-modal="true">
            <div slot="header" id="exportSearch">
                Export as a CSV file
            </div>
            <div slot="body">
                <SRow>
                    <SCol>
                        <label htmlFor="exportTitle" className="required" id="lbl-exportTitle">
                            Give your Export a name
                        </label>
                        <input
                            id="exportTitle"
                            type="text"
                            value={title}
                            className={titleError || noErrorTag}
                            onChange={e => {
                                handleChangeInput(e);
                            }}
                        />
                        {titleError ? (
                            <div className="input-msg">
                                <SIcon name="alert-circle-filled" aria-hidden="true" />
                                <span className="top-xs">Enter a name for Export</span>
                            </div>
                        ) : null}
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button className="secondary small" onClick={onSDismiss}>
                    Cancel
                </button>
                <button className={saveClassName} onClick={handleSubmitForm}>
                    Export
                </button>
            </div>
        </SDialog>
    );
}

export default ExportSearchDialog;
