import React from "react";
import { SRow, SCol } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import { shallowEqual, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { selectUserInfo, selectIsLoading } from "../../app/roleManagementSlice";

function UserDetails() {
    const isLoading = useSelector(selectIsLoading);
    const userInfo = useSelector(selectUserInfo, shallowEqual);

    const headerColumns = [
        gridHeader(
            "First name",
            "firstName",
            value => <span name="firstName"> {value} </span>,
            null,
            "",
            null,
            null,
            null,
            "firstName"
        ),
        gridHeader(
            "Last name",
            "lastName",
            value => <span name="lastName"> {value} </span>,
            null,
            "",
            null,
            null,
            null,
            "lastName"
        ),
        gridHeader(
            "Email",
            "email",
            value => <span name="email"> {value} </span>,
            null,
            "",
            null,
            null,
            null,
            "email"
        ),
        gridHeader(
            "Username",
            "userName",
            value => <span name="username"> {value} </span>,
            null,
            "",
            null,
            null,
            null,
            "userName"
        ),
        gridHeader(
            "Status",
            "isActive",
            value => <span name="status"> {value === true ? "Active" : "InActive"} </span>,
            null,
            "",
            null,
            null,
            null,
            "isActive"
        ),
    ];

    const getRowKey = () => {
        return uuidv4();
    };

    return (
        <>
            <span>
                <h1 className="pad-bottom-lg" data-ref="ecm.components.page-title.user-detail">
                    {userInfo.firstName} {userInfo.lastName}
                </h1>
            </span>
            <label className="text-md pad-bottom-xs">Details</label>
            <SRow>
                <SCol span="10">
                    <s-table-container>
                        <DataGrid
                            columns={headerColumns}
                            rows={userInfo}
                            getKey={getRowKey}
                            loading={isLoading}
                            inContainer
                        />
                    </s-table-container>
                </SCol>
            </SRow>
        </>
    );
}

export default React.memo(UserDetails);
