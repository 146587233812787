import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { SCol, SRow, SLoader, SSelect } from "@avalara/skylab-react";
import classnames from "classnames";
import { getPageTitle, isEligibleUser, vendorPremium } from "../../../shared/sessionUtility";

import {
    fetchCertificateRequestsAPI,
    setCertificateRequest,
    selectCertificateRequest,
    selectIsLoading,
    selectSetting,
    putGeneralSettings,
    putCertificateRequestSettings,
    selectIsSavingSettings,
} from "../../../app/settingsSlice";
import featureFlag from "../../../featureToggler/featureFlag";
import "../settingsStyle.css";
import EmailAddressDialog from "../../sharedDialogs/EmailAddressDialog";
import { setIsEmailAddressDialogOpen } from "../../../app/certificateSlice";

const CertificateRequests = React.memo(() => {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const settings = useSelector(selectSetting, shallowEqual);
    const submitButtonClassNames = classnames({
        primary: true,
        loading: useSelector(selectIsSavingSettings),
    });
    const isLoading = useSelector(selectIsLoading);
    const certificateRequestData = useSelector(selectCertificateRequest, shallowEqual);
    const [accountExpiration, setAccountExpiration] = useState("");
    const [daysToAllowAccessToCertExpress, setDaysToAllowAccessToCertExpress] = useState(false);
    const disableManageTemplates = !dispatch(
        isEligibleUser(featureFlag.requestCertificates.coverLetter)
    )
        ? "disable-anchor-tag"
        : "";
    const isEligibleUserSettingsGeneral = dispatch(isEligibleUser(featureFlag.settings.general));
    const isEligibleUserRequestCerts = dispatch(
        isEligibleUser(featureFlag.settings.requestingCertficateExemptionMatrix, true)
    );
    const [autoRequestReplyEmail, setAutoRequestReplyEmail] = useState("");
    const [manualRequestReplyEmail, setManualRequestReplyEmail] = useState("");
    const [expireWithin, setExpireWithin] = useState(settings?.autoSendExpiring !== "0");
    const [autoSendMissing, setAutoSendMissing] = useState(settings?.autoSendMissing);
    const [setAutoSend, setSetAutoSend] = useState(false);
    const [expireWithinDays, setExpireWithinDays] = useState(settings.autoSendExpiring || 30);
    const [autoRequestFollowUp, setAutoRequestFollowUp] = useState(settings?.autoRequestFollowUp);
    const [autoRequestInterval, setAutoRequestInterval] = useState(
        settings.autoRequestInterval || 15
    );
    const [autoRequestMax, setAutoRequestMax] = useState(settings.autoRequestMax || 3);
    const [requestSettingsChanged, setRequestSettingsChanged] = useState(false);
    const [autoSendInvalid, setAutoSendInvalid] = useState(settings?.autoSendInvalid);
    const [replyAddressesList, setReplyAddressesList] = useState([]);
    const [replyToManualAddressesList, setReplyToManualAddressesList] = useState([]);
    const [replyToHelpText, setReplyToHelpText] = useState("");

    const navigate = useNavigate();

    const replyToHelpTextMap = {
        NOREPLY: "Replies to a no-reply address can't be delivered",
        CORPORATE: "This company's corporate email address",
        FAX: "The email address provided by Avalara for incoming certificate uploads",
        ACCOUNT: "The user requesting the certificate receives email replies",
        NOMATCH: "",
    };

    const getReplyToHelpText = () => {
        let returnValue = "";

        if (settings?.manualRequestReplyEmail) {
            if (settings.manualRequestReplyEmail.startsWith("FAX:")) {
                returnValue = replyToHelpTextMap.FAX;
            } else if (Object.keys(replyToHelpTextMap).includes(settings.manualRequestReplyEmail)) {
                returnValue = replyToHelpTextMap[settings.manualRequestReplyEmail];
            }
        }

        return returnValue;
    };

    useEffect(() => {
        setAutoRequestReplyEmail(settings?.autoRequestReplyEmail);
        setManualRequestReplyEmail(settings?.manualRequestReplyEmail || "NOREPLY");
        setExpireWithin(settings?.autoSendExpiring !== "0");
        setAutoSendMissing(settings?.autoSendMissing);
        setExpireWithinDays(settings.autoSendExpiring || "30");
        setAutoRequestFollowUp(settings?.autoRequestFollowUp);
        setAutoRequestInterval(settings.autoRequestInterval || 15);
        setAutoRequestMax(settings.autoRequestMax || 3);
        setAutoSendInvalid(settings?.autoSendInvalid);
        setReplyToHelpText(getReplyToHelpText());
    }, [
        settings?.autoRequestReplyEmail,
        settings?.manualRequestReplyEmail,
        settings?.autoSendExpiring,
        settings?.autoSendMissing,
        settings?.autoRequestFollowUp,
        settings.autoRequestInterval,
        settings.autoRequestMax,
        settings?.autoSendInvalid,
    ]);

    useEffect(() => {
        const replyToManualAddresses = [];

        replyToManualAddresses.push({
            label: "no-reply@avalara.com",
            value: "NOREPLY",
            selected: settings.manualRequestReplyEmail === "NOREPLY",
        });

        if (settings.corporateEmail) {
            replyToManualAddresses.push({
                label: settings.corporateEmail,
                value: "CORPORATE",
                selected: settings.manualRequestReplyEmail === "CORPORATE",
            });
        }

        if (settings?.clientsFaxEmails) {
            let faxEmailAddress = "";
            if (settings.manualRequestReplyEmail.startsWith("FAX:")) {
                const faxEmailAddressArray = settings.manualRequestReplyEmail.split(":");
                faxEmailAddress = faxEmailAddressArray.length > 1 ? faxEmailAddressArray[1] : "";
            }
            settings.clientsFaxEmails.forEach(element => {
                replyToManualAddresses.push({
                    label: `${element.faxEmailAddress} (Upload via email)`,
                    value: `FAX:${element.faxEmailAddress}`,
                    selected: faxEmailAddress === element.faxEmailAddress,
                });
            });
        }

        replyToManualAddresses.push({
            label: "Requesting user's email address",
            value: "ACCOUNT",
            selected: settings.manualRequestReplyEmail === "ACCOUNT",
        });

        setReplyToManualAddressesList(replyToManualAddresses);
    }, []);

    const followUpDates = [];
    for (let i = 7; i <= 30; i += 1) {
        followUpDates.push({
            label: i.toString(),
            value: i.toString(),
            selected: autoRequestInterval === i.toString(),
        });
    }

    useEffect(() => {
        let replyAddresses = {};

        let replyAddressExists = false;
        replyAddresses = settings.clientsFaxEmails.map(element => {
            const selectOption = {
                label: element.faxEmailAddress,
                value: element.faxEmailAddress,
                selected: false,
            };

            if (element.faxEmailAddress === autoRequestReplyEmail) {
                selectOption.selected = true;
                replyAddressExists = true;
            }
            return selectOption;
        });

        if (settings.corporateEmail) {
            if (settings.corporateEmail === autoRequestReplyEmail) {
                replyAddresses.push({
                    label: settings.corporateEmail,
                    value: settings.corporateEmail,
                    selected: true,
                });

                replyAddressExists = true;
            } else {
                replyAddresses.push({
                    label: settings.corporateEmail,
                    value: settings.corporateEmail,
                    selected: false,
                });
            }
        }

        replyAddresses.unshift({
            label: "no-reply@avalara.com",
            value: "no-reply@avalara.com",
            selected: !replyAddressExists,
        });

        setReplyAddressesList(replyAddresses);
    }, [autoRequestReplyEmail]);

    const handleChangeSelect = setter => {
        return event => {
            setter(event.detail.item.value);

            if (event.detail.selectInputId === "replyToManualAddress") {
                let { value } = event.detail.item;
                if (value.startsWith("FAX")) {
                    value = "FAX";
                }
                const helpTextKey = Object.keys(replyToHelpTextMap).includes(value)
                    ? value
                    : "NOMATCH";
                setReplyToHelpText(replyToHelpTextMap[helpTextKey]);
            }
        };
    };

    const handleChangeInput = setter => {
        return event => {
            // Tell API to update driver database for auto-sending requests
            if (
                [
                    "expireWithin",
                    "autoSendMissing",
                    "autoSendInvalid",
                    "autoRequestFollowUp",
                ].includes(event.target.name)
            ) {
                setSetAutoSend(true);
            }
            if (event.target.name === "account_expiration") {
                setAccountExpiration("");
                setRequestSettingsChanged(true);
            }
            setter(event.target.value);
        };
    };

    const handleCheckboxChange = setter => {
        return event => {
            if (
                [
                    "expireWithin",
                    "autoSendMissing",
                    "autoSendInvalid",
                    "autoRequestFollowUp",
                ].includes(event.target.name)
            ) {
                setSetAutoSend(true);
            }
            setter(event.target.checked);
        };
    };

    useEffect(() => {
        dispatch(fetchCertificateRequestsAPI());

        return () => {
            dispatch(setCertificateRequest([]));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (certificateRequestData?.gencertRegistration) {
            setDaysToAllowAccessToCertExpress(certificateRequestData.gencertRegistration.active);
            setAccountExpiration(certificateRequestData.gencertRegistration.accountExpiration);
        }
    }, [certificateRequestData]);

    const handleSubmitRequest = async event => {
        event.preventDefault();

        const putData = {
            setAutoSend,
            autoSendExpiring: "0",
            expireWithin,
            autoSendMissing,
            autoSendInvalid,
            autoRequestFollowUp,
            autoRequestInterval,
            autoRequestMax,
            autoRequestReplyEmail,
            manualRequestReplyEmail,
        };

        if (expireWithin) {
            putData.autoSendExpiring = parseInt(
                expireWithinDays !== "0" ? expireWithinDays : "30",
                10
            );
        }

        await dispatch(putGeneralSettings(putData));

        if (requestSettingsChanged) {
            setSubmitting(true);
            const putCertRequestData = {
                gencertRegistration: {
                    active: true,
                    accountExpiration:
                        daysToAllowAccessToCertExpress &&
                        !Number.isNaN(parseInt(accountExpiration, 10)) &&
                        parseInt(accountExpiration, 10) > 0
                            ? parseInt(accountExpiration, 10)
                            : 60,
                },
            };
            await dispatch(putCertificateRequestSettings(putCertRequestData));
            setSubmitting(false);
        }
    };

    const OpenEmailDialog = () => {
        dispatch(setIsEmailAddressDialogOpen(true));
    };
    const cancelHandler = () => {
        navigate(0);
    };

    let certificateRequestDOM = null;

    if (isLoading || submitting || certificateRequestData.length === 0) {
        certificateRequestDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" aria-live="polite" />
            </div>
        );
    } else {
        const vendorLink =
            vendorPremium() && !isEligibleUserRequestCerts
                ? `/cover-letters/vendors`
                : `/cover-letters`;
        certificateRequestDOM = (
            <div>
                <Helmet>
                    <title>{dispatch(getPageTitle("Settings : Certificate request"))}</title>
                </Helmet>

                <SRow>
                    <SCol>
                        <h3 className="margin-bottom-none">Certificate request templates </h3>
                        <p className="margin-top-none">
                            <a href={vendorLink} className={disableManageTemplates}>
                                Manage templates
                            </a>{" "}
                            used for emailed and printed certificate requests.
                        </p>

                        {isEligibleUserRequestCerts ? (
                            <div className="margin-top-sm">
                                <h3 className=" margin-bottom-none">Request options </h3>
                                <label
                                    className="margin-bottom-none text-sm"
                                    htmlFor="account_expiration">
                                    Change how long a customer has to respond to a certificate
                                    request. By default, requests expire after 60 days.
                                </label>
                                <div id="showDaysUntill">
                                    <p className="text-xs no-span margin-top-none">
                                        Days until request expires
                                    </p>
                                    <input
                                        id="account_expiration"
                                        name="account_expiration"
                                        type="text"
                                        onChange={handleChangeInput(setAccountExpiration)}
                                        defaultValue={accountExpiration}
                                        className="accountExpirationTextWidth"
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </SCol>
                </SRow>
                {isEligibleUserRequestCerts ? (
                    <>
                        <SRow>
                            <SCol span="9">
                                <div>
                                    <input
                                        id="expireWithin"
                                        name="expireWithin"
                                        type="checkbox"
                                        aria-label="Automatically request certificates that expire within"
                                        className="block"
                                        checked={expireWithin}
                                        onChange={handleCheckboxChange(setExpireWithin)}
                                    />
                                    <label htmlFor="expireWithin" id="lbl-exireDays">
                                        Automatically request certificates that expire within
                                    </label>

                                    <SSelect
                                        inputId="exireDays"
                                        multiple={false}
                                        className="margin-left-xs margin-right-xs small-select"
                                        optionsList={[
                                            {
                                                label: "30",
                                                value: "30",
                                                selected: expireWithinDays === "30",
                                            },
                                            {
                                                label: "45",
                                                value: "45",
                                                selected: expireWithinDays === "45",
                                            },
                                            {
                                                label: "60",
                                                value: "60",
                                                selected: expireWithinDays === "60",
                                            },
                                            {
                                                label: "90",
                                                value: "90",
                                                selected: expireWithinDays === "90",
                                            },
                                            {
                                                label: "120",
                                                value: "120",
                                                selected: expireWithinDays === "120",
                                            },
                                        ]}
                                        onS-select={handleChangeSelect(setExpireWithinDays)}
                                    />
                                    <span htmlFor="exireDays">days</span>
                                </div>
                                <div>
                                    <input
                                        id="autoSendMissing"
                                        name="autoSendMissing"
                                        type="checkbox"
                                        aria-label="Automatically request certificates that are missing for any customer
                                tax region"
                                        checked={autoSendMissing || ""}
                                        onChange={handleCheckboxChange(setAutoSendMissing)}
                                    />
                                    <label htmlFor="autoSendMissing">
                                        Automatically request certificates that are missing for any
                                        customer tax region
                                    </label>
                                </div>
                                <div>
                                    <input
                                        id="autoSendInvalid"
                                        name="autoSendInvalid"
                                        type="checkbox"
                                        aria-label="Automatically request invalid certificates"
                                        checked={autoSendInvalid || ""}
                                        onChange={handleCheckboxChange(setAutoSendInvalid)}
                                    />
                                    <label htmlFor="autoSendInvalid">
                                        Automatically request invalid certificates
                                    </label>
                                </div>
                            </SCol>
                        </SRow>
                        <SRow>
                            <SCol span="9">
                                <div>
                                    <input
                                        id="autoRequestFollowUp"
                                        name="autoRequestFollowUp"
                                        type="checkbox"
                                        aria-label="Automatically send follow-up requests if certificates are not
                                received"
                                        className="block"
                                        checked={autoRequestFollowUp || ""}
                                        onChange={handleCheckboxChange(setAutoRequestFollowUp)}
                                    />
                                    <label htmlFor="autoRequestFollowUp">
                                        Automatically send follow-up requests if certificates are
                                        not received
                                    </label>
                                </div>
                                <div
                                    className={
                                        !autoRequestFollowUp
                                            ? "hidden"
                                            : "margin-top-sm margin-left-md"
                                    }>
                                    <span>Send up to</span>

                                    <SSelect
                                        inputId="autoRequestMax"
                                        multiple={false}
                                        className="margin-left-xs margin-right-xs small-select"
                                        optionsList={[
                                            {
                                                label: "1",
                                                value: "1",
                                                selected: autoRequestMax === "1",
                                            },
                                            {
                                                label: "2",
                                                value: "2",
                                                selected: autoRequestMax === "2",
                                            },
                                            {
                                                label: "3",
                                                value: "3",
                                                selected: autoRequestMax === "3",
                                            },
                                            {
                                                label: "4",
                                                value: "4",
                                                selected: autoRequestMax === "4",
                                            },
                                        ]}
                                        onS-select={handleChangeSelect(setAutoRequestMax)}
                                    />
                                    <span htmlFor="autoRequestInterval">
                                        follow-up requests after the initial request, with{" "}
                                    </span>
                                    <SSelect
                                        inputId="autoRequestInterval"
                                        multiple={false}
                                        className="margin-left-xs margin-right-xs small-select"
                                        optionsList={followUpDates}
                                        onS-select={handleChangeSelect(setAutoRequestInterval)}
                                    />
                                    <span>days between requests.</span>
                                </div>
                            </SCol>
                        </SRow>
                    </>
                ) : (
                    ""
                )}

                <SRow>
                    <SCol span="5">
                        <h3 className="margin-bottom-none">Email replies</h3>
                        <button
                            style={{ display: "none" }}
                            type="button"
                            onClick={OpenEmailDialog}
                            className="ghost-blue pad-left-none link">
                            Manage certificate uploader addresses
                        </button>
                        <EmailAddressDialog
                            setIsEmailAddressDialogOpen={setIsEmailAddressDialogOpen}
                        />
                        <span className="margin-top-sm display-inline-block">
                            When customers reply to certificate request email, send their reply to a
                            specific email address.
                        </span>
                        <label id="lbl-replyToManualAddress" htmlFor="replyToManualAddress">
                            Reply-to for manual requests
                        </label>
                        <SRow>
                            <SCol span="9">
                                <SSelect
                                    inputId="replyToManualAddress"
                                    optionsList={replyToManualAddressesList}
                                    onS-select={handleChangeSelect(setManualRequestReplyEmail)}
                                    aria-describedby="validation-msg-id"
                                />
                                <div id="validation-msg-id" className="reply-to-help-text">
                                    <span className="top-xs">{replyToHelpText}</span>
                                </div>
                            </SCol>
                        </SRow>
                        <label id="lbl-replyAddress" htmlFor="replyAddress">
                            Reply-to for automated requests
                        </label>
                        <SRow>
                            <SCol span="9">
                                <SSelect
                                    inputId="replyAddress"
                                    optionsList={replyAddressesList}
                                    onS-select={handleChangeSelect(setAutoRequestReplyEmail)}
                                />
                            </SCol>
                        </SRow>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="12">
                        <hr className="margin-top-xl margin-bottom-lg" />
                        <button
                            id="save-client"
                            type="button"
                            className={submitButtonClassNames}
                            onClick={handleSubmitRequest}
                            disabled={!isEligibleUserSettingsGeneral}
                            name="stop-apply-btn">
                            Save
                        </button>

                        <button className="tertiary" type="button" onClick={cancelHandler}>
                            Cancel
                        </button>
                    </SCol>
                </SRow>
            </div>
        );
    }
    return <React.Fragment> {certificateRequestDOM} </React.Fragment>;
});

export default CertificateRequests;
