import React from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import ViewRetailSettings from "./ViewRetailSettings";
import { getPageTitle } from "../../../../shared/sessionUtility";

const RetailSettings = React.memo(() => {
    const dispatch = useDispatch();
    return (
        <>
            <Helmet>
                <title>{dispatch(getPageTitle("Retail site settings"))}</title>
            </Helmet>
            <ViewRetailSettings />
        </>
    );
});

export default RetailSettings;
