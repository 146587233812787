import { createSelector, createSlice } from "@reduxjs/toolkit";
import { orderBy, isEqual } from "lodash";
import { Buffer } from "buffer";
import axios from "../axios";
import {
    fixSearchStringForPostgres,
    poll,
    toBase64,
    getFormattedDate,
    removeSpaces,
    matchSubscriptions,
    convertFieldToString,
    fieldValidationTypeCsv,
    fieldsValidation,
    buildApiV3Url,
    buildApiV3UrlWithQuery,
    buildApiV2Point5UrlWithQuery,
    buildApiV2Point5Url,
    transformExemptionData,
} from "../shared/Utils";

import { parseSuccessResponse, parseGetAllObjResponse } from "../shared/responseUtils";
import { setIsExportSearchDialogOpen } from "./exportSearchSlice";
import {
    setIsRequestFromCustomersCertificateGrid,
    fetchCustomerExemptionsDetails,
} from "./customerCertificateSlice";
import { setCertificateIds } from "./bulkUpdateSlice";
import {
    BackgroundJobState,
    documentTypeNames,
    pendingCertificateColumnPreference,
    PendingStatuses,
    CRUDOperation,
} from "../shared/constants";
import featureFlag from "../featureToggler/featureFlag";
import { getStoreValues } from "./commonSlice";
import getConfig from "../config";

const { hostnames } = getConfig();
const { docLockerClient } = hostnames;

let autoValidationTimeout = null;

const initialState = {
    isloading: false,
    isLoadingCountries: false,
    countries: [],
    defaultCountryId: null,
    isLoadingAssignment: false,
    limitedCountries: [],
    isUploadingFile: false,
    isAutoValidatingFile: false,
    downloadableFile: false,
    isLoadingExposureZones: false,
    exposureZones: [],
    sutOutgoingExposureZones: [],
    isLoadingDataEntrySets: false,
    dataEntrySets: [],
    regionIds: [],
    matrixZones: [],
    isLoadingExemptionReasons: false,
    isLoadingOutgoingExemptionReasons: false,
    exemptionReasons: [],
    outgoingExemptionReasons: [],
    isLoadingCoverLetter: false,
    standardCoverLetter: [],
    isLoadingExemptionMatrix: false,
    exemptionMatrix: [],
    customerCert: [],
    exemptionMatrixValidation: [],
    isLoadingCustomerCert: false,
    isPostingCert: false,
    isCertPresent: true,
    isDeletingCert: false,
    isDeletingCertFile: false,
    noDataType: "",
    noSearch: true,
    certificateFormState: {
        exposureZone: "",
        exposureCountry: "",
        taxCode: "",
        expirationDate: "",
        isSingle: false,
        valid: false,
        poNumberValue: "",
        poNumberId: 0,
    },
    formState: {
        nameOrCode: "",
        nameOrCodeWhileLoading: "",
    },
    certificateFiltersState: {
        isCustomerDetails: false,
        isCertificateDetails: true,
        customerRegions: [],
        isBillToRelation: false,
        isShipToRelation: false,
        isDuplicateRelation: false,
        customerCodes: [],
        customerCodeError: "",
        isCustomerDateFilter: false,
        customerCreatedDateAfter: "",
        customerCreatedDateBefore: "",
        customerCreatedDateAfterError: "",
        customerCreatedDateBeforeError: "",
        customerModifiedDateAfter: "",
        customerModifiedDateBefore: "",
        customerModifiedDateAfterError: "",
        customerModifiedDateBeforeError: "",
        certificatesLabels: [],
        customersLabels: [],
        certificateStatus: [],
        certificateRegions: [],
        exemptReasons: [],
        isPurchaseOrder: false,
        invoiceNumber: "",
        certificateIds: "",
        certificateIdError: "",
        isCertificateImage: 0,
        isCertificateActive: false,
        isCertificateDateFilter: false,
        certificateCreatedDateAfter: "",
        certificateCreatedDateBefore: "",
        certificateCreatedDateAfterError: "",
        certificateCreatedDateBeforeError: "",
        certificateModifiedDateAfter: "",
        certificateModifiedDateBefore: "",
        certificateModifiedDateAfterError: "",
        certificateModifiedDateBeforeError: "",
        certificateEffectiveDateAfter: "",
        certificateEffectiveDateBefore: "",
        certificateEffectiveDateAfterError: "",
        certificateEffectiveDateBeforeError: "",
        certificateExpirationDateAfter: "",
        certificateExpirationDateBefore: "",
        savedSearch: "",
        certificateHasCustomers: 0,
        isCertActive: 0,
        certificateCustomFields: {},
        nameOrCode: "",
        reviewedBy: "",
    },
    certificateStatuses: [],
    isLoadingCertificateStatuses: false,
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 20,
            totalRecords: 0,
        },
    },
    pendingFormState: {
        nameOrCodeOrFileOrId: "",
    },
    pendingCertificateFiltersState: {
        exposureZone: [],
        certificateCreatedFrom: "",
        certificateCreatedTo: "",
        assignedTo: null,
        escalated: null,
        isCertificateImage: 0,
        source: 0,
        exemptReasons: [],
    },
    isLoadingValidationData: false,
    validationData: { fileType: "", data: "", fileName: "" },
    fvsValidationData: { Key: "", FileName: "", ValidationId: "" },
    hasFileUnderValidation: false,
    hasCertificateFile: false,
    sortColumn: ["id", true],
    isLoadingInvalidReasons: false,
    invalidReasons: [],
    labels: [],
    isLoadingLabels: false,
    isLoadingCertificateCustomFields: false,
    isLoadingCustomFields: false,
    customFields: [],
    certificateCustomFields: [],
    isAttachingFile: false,
    isAttachingComment: false,
    isLoadingSupplementalFiles: false,
    isLoadingComments: false,
    supplementalFiles: [],
    isLoadingSelectedColumns: false,
    isLoadingPendingCertificateSelectedColumns: false,
    pendingCertificateSelectedColumns: [
        { id: "customer-name", label: "Customer name", hidden: false },
        { id: "customer-code", label: "Customer code", hidden: false },
        { id: "exposure-zone-name", label: "Region", hidden: false },
        { id: "orig-file-name", label: "File name", hidden: false },
        { id: "preview", label: "Preview", hidden: false },
        { id: "source", label: "Source", hidden: false },
        { id: "assigned-to-user-name", label: "Assigned to", hidden: true },
        { id: "escalation-date", label: "Escalation", hidden: true },
        { id: "uploaded-by", label: "Uploaded by", hidden: false },
    ],
    selectedColumns: [
        { id: "name", label: "Customer Name", hidden: false },
        { id: "customerCode", label: "Customer code", hidden: false },
        { id: "exposureZoneName", label: "Region", hidden: false },
        { id: "taxCodeName", label: "Exemption reason", hidden: false },
        { id: "createdDate", label: "Added", hidden: false },
        { id: "signedDate", label: "Effective", hidden: false },
        { id: "expirationDate", label: "Expiration", hidden: false },
        { id: "certificateStatus", label: "Status", hidden: false },
        { id: "isSingle", label: "Single Use", hidden: true },
        { id: "purchaseOrderNumber", label: "PO number", hidden: true },
        { id: "taxNumber", label: "Tax ID", hidden: true },
    ],
    vendorColumns: [
        { id: "name", label: "Vendor name", hidden: false },
        { id: "customerCode", label: "Vendor code", hidden: false },
        { id: "exposureZoneName", label: "Region", hidden: false },
        { id: "taxCodeName", label: "Exemption reason", hidden: false },
        { id: "createdDate", label: "Added", hidden: false },
        { id: "signedDate", label: "Effective", hidden: false },
        { id: "expirationDate", label: "Expiration", hidden: false },
        { id: "certificateStatus", label: "Status", hidden: false },
        { id: "isSingle", label: "Single Use", hidden: true },
        { id: "purchaseOrderNumber", label: "PO number", hidden: true },
    ],
    comments: [],
    isDeletingComment: false,
    autoValidationData: {
        issues: [],
        predictions: {},
        status: "",
        avsId: "",
        isMulti: false,
        isSinglePurchase: false,
    },
    redirectURL: "",
    isMulti: false,
    isRegionValid: true,
    regionMessage: "",
    isTaxcodeMap: false,
    isLoadingCertificateExport: false,
    multiCertChildList: [],
    isUploadDialogOpen: false,
    isEmailAddressDialogOpen: false,
    isUploadDialogEllipsesMenuOpen: false,
    isPauseCertificateDialogOpen: false,
    isResumeCertificateDialogOpen: false,
    isLoadingMulti: false,
    isSplitCertificateDialogOpen: false,
    passedFVS: true,
    isScanningFVS: false,
    isCertificateUpdated: false,
    isSplittingCertificates: false,
    isCertificateGridEllipsesMenu: false,
    isBulkDownloadCertificateDialogOpen: false,
    isInvalidReasonDialogOpen: false,
    escalationLog: null,
    isLoadingEscalationLog: false,
    isLoadingCertificateColumnConfig: false,
    customLabels: [],
    certificateLabels: [],
    isLoadingCertificateLabels: false,
    certificateExistingCustomFieldValues: {},
    certificateAddedCustomFieldValues: {},
    isLoadingTaxContentForms: false,
    taxContentForms: {},
    taxContentForm: "",
    templateTag: "",
    isContentFormDialogOpen: false,
    isTaxTypeDialogOpen: false,
    isLoadingCommunicationTaxTypes: false,
    certificateCommunicationTaxTypes: [],
    mostCommonForm: "",
    isCertificateCountAPICompleted: 0,
    isLoadingCertificateCount: false,
    validationStateCount: 0,
    certificateSources: [],
    isLoadingCertificateSources: false,
    certificateReviewers: [],
    isLoadingCertificateReviewers: false,
};

export const certificateSlice = createSlice({
    name: "certificate",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setLoadingCountries: (state, action) => {
            state.isLoadingCountries = action.payload;
        },
        setIsLoadingCertificateAssignment: (state, action) => {
            state.isLoadingAssignment = action.payload;
        },
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setDefaultCountryId: (state, action) => {
            state.defaultCountryId = action.payload;
        },
        setLimitedCountries: (state, action) => {
            state.limitedCountries = action.payload;
        },
        setUploadingFile: (state, action) => {
            state.isUploadingFile = action.payload;
        },
        setIsAutoValidatingFile: (state, action) => {
            state.isAutoValidatingFile = action.payload;
        },
        setDownloadableFile: (state, action) => {
            state.downloadableFile = action.payload;
        },
        setLoadingExposureZones: (state, action) => {
            state.isLoadingExposureZones = action.payload;
        },
        setExposureZones: (state, action) => {
            state.exposureZones = action.payload;
        },
        setSUTOutgoingExposureZones: (state, action) => {
            state.sutOutgoingExposureZones = action.payload;
        },
        setIsLoadingDataEntrySets: (state, action) => {
            state.isLoadingDataEntrySets = action.payload;
        },
        setDataEntrySets: (state, action) => {
            state.dataEntrySets = action.payload;
        },
        setRegionIds: (state, action) => {
            state.regionIds = action.payload;
        },
        setLoadingExemptionReasons: (state, action) => {
            state.isLoadingExemptionReasons = action.payload;
        },
        setLoadingOutgoingExemptionReasons: (state, action) => {
            state.isLoadingOutgoingExemptionReasons = action.payload;
        },
        setExemptionReasons: (state, action) => {
            state.exemptionReasons = action.payload;
        },
        setOutgoingExemptionReasons: (state, action) => {
            state.outgoingExemptionReasons = action.payload;
        },
        setStandardCoverLetter: (state, action) => {
            state.standardCoverLetter = action.payload;
        },
        setLoadingCoverLetter: (state, action) => {
            state.isLoadingCoverLetter = action.payload;
        },
        setLoadingExemptionMatrix: (state, action) => {
            state.isLoadingExemptionMatrix = action.payload;
        },
        setExemptionMatrix: (state, action) => {
            state.exemptionMatrix = action.payload;
        },
        setExemptionMatrixValidation: (state, action) => {
            state.exemptionMatrixValidation = action.payload;
        },
        setMatrixZones: (state, action) => {
            state.matrixZones = action.payload;
        },
        setCertificate: (state, action) => {
            state.customerCert = action.payload;
        },
        setLoadingCustomerCert: (state, action) => {
            state.isLoadingCustomerCert = action.payload;
        },
        setPostingCert: (state, action) => {
            state.isPostingCert = action.payload;
        },
        setCertPresent: (state, action) => {
            state.isCertPresent = action.payload;
        },
        setDeletingCert: (state, action) => {
            state.isDeletingCert = action.payload;
        },
        setDeletingCertFile: (state, action) => {
            state.isDeletingCertFile = action.payload;
        },
        setTaxCode: (state, action) => {
            state.taxCode = action.payload;
        },
        setCertificateStatuses: (state, action) => {
            state.certificateStatuses = action.payload;
        },
        setExposureCountry: (state, action) => {
            state.exposureCountry = action.payload;
        },
        setCertificateFormState: (state, action) => {
            state.certificateFormState = action.payload;
        },
        clearCertificateFiltersState: state => {
            state.certificateFiltersState = initialState.certificateFiltersState;
        },
        clearPendingFiltersState: state => {
            state.pendingCertificateFiltersState = initialState.pendingCertificateFiltersState;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: (state, action) => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: action?.payload || initialState.page.rowsPerPage,
                    totalRecords: 0,
                },
            };
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setNoSearch: (state, action) => {
            state.noSearch = action.payload;
        },
        setIsLoadingValidationData: (state, action) => {
            state.isLoadingValidationData = action.payload;
        },
        setValidationData: (state, action) => {
            state.validationData = action.payload;
        },
        setFvsValidationData: (state, action) => {
            state.fvsValidationData = action.payload;
        },
        clearValidationData: state => {
            state.validationData = initialState.validationData;
        },
        setHasFileUnderValidation: (state, action) => {
            state.hasFileUnderValidation = action.payload;
        },
        setHasCertificateFile: (state, action) => {
            state.hasCertificateFile = action.payload;
        },
        setPendingState: (state, action) => {
            state.pendingFormState = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setLabels: (state, action) => {
            state.labels = action.payload;
        },
        setLoadingLabels: (state, action) => {
            state.isLoadingLabels = action.payload;
        },
        setLoadingCertificateStatuses: (state, action) => {
            state.isLoadingCertificateStatuses = action.payload;
        },
        setLoadingInvalidReasons: (state, action) => {
            state.isLoadingInvalidReasons = action.payload;
        },
        setInvalidReasons: (state, action) => {
            state.invalidReasons = action.payload;
        },
        setLoadingCustomFields: (state, action) => {
            state.isLoadingCustomFields = action.payload;
        },
        setLoadingCertificateCustomFields: (state, action) => {
            state.isLoadingCertificateCustomFields = action.payload;
        },
        setCustomFields: (state, action) => {
            state.customFields = action.payload;
        },
        setCertificateCustomFields: (state, action) => {
            state.certificateCustomFields = action.payload;
        },
        setIsAttachingFile: (state, action) => {
            state.isAttachingFile = action.payload;
        },
        setLoadingSupplementalFiles: (state, action) => {
            state.isLoadingSupplementalFiles = action.payload;
        },
        setSupplementalFiles: (state, action) => {
            state.supplementalFiles = action.payload;
        },
        setIsLoadingSelectedColumns: (state, action) => {
            state.isLoadingSelectedColumns = action.payload;
        },
        setSelectedColumns: (state, action) => {
            state.selectedColumns = action.payload;
        },
        setIsLoadingPendingCertificateSelectedColumns: (state, action) => {
            state.isLoadingPendingCertificateSelectedColumns = action.payload;
        },
        setPendingCertificateSelectedColumns: (state, action) => {
            state.pendingCertificateSelectedColumns = action.payload;
        },

        setVendorColumns: (state, action) => {
            state.vendorColumns = action.payload;
        },
        setCertificateFiltersState: (state, action) => {
            const key = Object.keys(action.payload)[0];
            const value = Object.values(action.payload)[0];
            state.certificateFiltersState[key] = value;
        },
        setPendingFiltersState: (state, action) => {
            const key = Object.keys(action.payload)[0];
            const value = Object.values(action.payload)[0];
            state.pendingCertificateFiltersState[key] = value;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setIsAttachingComment: (state, action) => {
            state.isAttachingComment = action.payload;
        },

        setLoadingComments: (state, action) => {
            state.isLoadingComments = action.payload;
        },
        setComments: (state, action) => {
            state.comments = action.payload;
        },
        setDeletingComment: (state, action) => {
            state.isDeletingComment = action.payload;
        },
        setAutoValidationData: (state, action) => {
            state.autoValidationData = action.payload;
        },
        setRedirectURL: (state, action) => {
            state.redirectURL = action.payload;
        },
        setSavedSearch: (state, action) => {
            state.certificateFiltersState.savedSearch = action.payload;
        },
        setSavedSearchState: (state, action) => {
            state.certificateFiltersState = {
                ...initialState.certificateFiltersState,
                ...action.payload,
            };
        },
        setIsMulti: (state, action) => {
            state.isMulti = action.payload;
        },
        setIsRegionValid: (state, action) => {
            state.isRegionValid = action.payload;
        },
        setIsRegionMessage: (state, action) => {
            state.regionMessage = action.payload;
        },
        setIsTaxcodeMap: (state, action) => {
            state.isTaxcodeMap = action.payload;
        },
        setIsLoadingCertificateExport: (state, action) => {
            state.isLoadingCertificateExport = action.payload;
        },
        setMultiCertChildList: (state, action) => {
            state.multiCertChildList = action.payload;
        },
        setIsUploadDialogOpen: (state, action) => {
            state.isUploadDialogOpen = action.payload;
        },
        setIsEmailAddressDialogOpen: (state, action) => {
            state.isEmailAddressDialogOpen = action.payload;
        },
        setIsUploadDialogEllipsesMenuOpen: (state, action) => {
            state.isUploadDialogEllipsesMenuOpen = action.payload;
        },
        setIsPauseCertificateDialogOpen: (state, action) => {
            state.isPauseCertificateDialogOpen = action.payload;
        },
        setIsResumeCertificateDialogOpen: (state, action) => {
            state.isResumeCertificateDialogOpen = action.payload;
        },
        setIsLoadingMulti: (state, action) => {
            state.isLoadingMulti = action.payload;
        },
        setIsSplitCertificateDialogOpen: (state, action) => {
            state.isSplitCertificateDialogOpen = action.payload;
        },
        setIsScanningFVS: (state, action) => {
            state.isScanningFVS = action.payload;
        },
        setPassedFVS: (state, action) => {
            state.passedFVS = action.payload;
        },
        setIsCertificateUpdated: (state, action) => {
            state.isCertificateUpdated = action.payload;
        },
        setIsSplittingCertificates: (state, action) => {
            state.isSplittingCertificates = action.payload;
        },
        setIsCertificateGridEllipsesMenu: (state, action) => {
            state.isCertificateGridEllipsesMenu = action.payload;
        },
        setIsBulkDownloadCertificateDialogOpen: (state, action) => {
            state.isBulkDownloadCertificateDialogOpen = action.payload;
        },
        setIsInvalidReasonDialogOpen: (state, action) => {
            state.isInvalidReasonDialogOpen = action.payload;
        },
        setEscalationLog: (state, action) => {
            state.escalationLog = action.payload;
        },
        setIsLoadingEscalationLog: (state, action) => {
            state.isLoadingEscalationLog = action.payload;
        },
        setIsLoadingCertificateColumnConfig: (state, action) => {
            state.isLoadingCertificateColumnConfig = action.payload;
        },
        setCustomLabels: (state, action) => {
            state.customLabels = action.payload;
        },
        setCertificateLabels: (state, action) => {
            state.certificateLabels = action.payload;
        },
        setLoadingCertificateLabels: (state, action) => {
            state.isLoadingCertificateLabels = action.payload;
        },
        setCertificateExistingCustomFieldValues: (state, action) => {
            if (Object.keys(action.payload)[0]) {
                Object.keys(action.payload).forEach(i => {
                    state.certificateExistingCustomFieldValues[i] = action.payload[i];
                });
            }
        },
        setCertificateAddedCustomFieldValues: (state, action) => {
            const key = Object.keys(action.payload)[0];
            if (action.payload[key] === null) {
                delete state.certificateAddedCustomFieldValues[key];
                return;
            }
            state.certificateAddedCustomFieldValues[key] = action.payload[key];
        },
        setIsLoadingTaxContentForms: (state, action) => {
            state.isLoadingTaxContentForms = action.payload;
        },
        setTaxContentForms: (state, action) => {
            state.taxContentForms = action.payload;
        },
        setTaxContentForm: (state, action) => {
            state.taxContentForm = action.payload;
        },
        setTaxTemplateTag: (state, action) => {
            state.templateTag = action.payload;
        },
        setIsContentFormDialogOpen: (state, action) => {
            state.isContentFormDialogOpen = action.payload;
        },
        setIsTaxTypeDialogOpen: (state, action) => {
            state.isTaxTypeDialogOpen = action.payload;
        },
        setIsLoadingCommunicationTaxTypes: (state, action) => {
            state.isLoadingCommunicationTaxTypes = action.payload;
        },
        setCertificateCommunicationTaxTypes: (state, action) => {
            state.certificateCommunicationTaxTypes = action.payload;
        },
        setExistingTaxTypesReset: (state, action) => {
            state.existingTaxTypesReset = action.payload;
        },
        setMostCommonForm: (state, action) => {
            state.mostCommonForm = action.payload;
        },
        setCertificateCountAPICompleted: (state, action) => {
            state.isCertificateCountAPICompleted = action.payload;
        },
        incrementCertificateCountAPICompleted: state => {
            state.isCertificateCountAPICompleted += 1;
        },
        decrementCertificateCountAPICompleted: state => {
            state.isCertificateCountAPICompleted -= 1;
        },
        setIsLoadingCertificateCount: (state, action) => {
            state.isLoadingCertificateCount = action.payload;
        },
        incrementValidationStateCount: state => {
            state.validationStateCount += 1;
        },
        setValidationStateCount: (state, action) => {
            state.validationStateCount = action.payload;
        },
        setLoadingCertificateSources: (state, action) => {
            state.isLoadingCertificateSources = action.payload;
        },
        setCertificateSources: (state, action) => {
            state.certificateSources = action.payload;
        },
        setLoadingCertificateReviewers: (state, action) => {
            state.isLoadingCertificateReviewers = action.payload;
        },
        setCertificateReviewers: (state, action) => {
            state.certificateReviewers = action.payload;
        },
    },
});

export const {
    setLoading,
    setLoadingCountries,
    setIsLoadingCertificateAssignment,
    setCountries,
    setDefaultCountryId,
    setLimitedCountries,
    setUploadingFile,
    setIsAutoValidatingFile,
    setDownloadableFile,
    setLoadingExposureZones,
    setExposureZones,
    setIsLoadingDataEntrySets,
    setDataEntrySets,
    setRegionIds,
    setLoadingExemptionReasons,
    setLoadingOutgoingExemptionReasons,
    setExemptionReasons,
    setOutgoingExemptionReasons,
    setLoadingCoverLetter,
    setStandardCoverLetter,
    setSUTOutgoingExposureZones,
    setLoadingExemptionMatrix,
    setLoadingOutgoingExemptionMatrix,
    setExemptionMatrix,
    setExemptionMatrixValidation,
    setMatrixZones,
    setLoadingCustomerCert,
    setPostingCert,
    setCertPresent,
    setDeletingCert,
    setDeletingCertFile,
    setCertificate,
    setExposureCountry,
    setCertificateFormState,
    setLoadingLabels,
    setLabels,
    setPage,
    setPageToNull,
    setNoDataType,
    setNoSearch,
    setIsLoadingValidationData,
    setValidationData,
    setHasCertificateFile,
    setHasFileUnderValidation,
    clearValidationData,
    setPendingState,
    setSortColumn,
    setCertificateStatuses,
    setLoadingCertificateStatuses,
    setInvalidReasons,
    setLoadingInvalidReasons,
    setCustomFields,
    setCertificateCustomFields,
    setLoadingCustomFields,
    setLoadingCertificateCustomFields,
    setIsAttachingFile,
    setSupplementalFiles,
    setLoadingSupplementalFiles,
    setIsLoadingSelectedColumns,
    setSelectedColumns,
    setIsLoadingPendingCertificateSelectedColumns,
    setPendingCertificateSelectedColumns,
    setVendorColumns,
    setCertificateFiltersState,
    clearCertificateFiltersState,
    setFormState,
    clearFormState,
    setIsAttachingComment,
    setComments,
    setLoadingComments,
    setDeletingComment,
    setPendingFiltersState,
    clearPendingFiltersState,
    setAutoValidationData,
    setRedirectURL,
    setSavedSearch,
    setSavedSearchState,
    setIsMulti,
    setIsRegionValid,
    setIsRegionMessage,
    setIsTaxcodeMap,
    setIsLoadingCertificateExport,
    setMultiCertChildList,
    setIsUploadDialogOpen,
    setIsEmailAddressDialogOpen,
    setIsUploadDialogEllipsesMenuOpen,
    setIsPauseCertificateDialogOpen,
    setIsResumeCertificateDialogOpen,
    setIsLoadingMulti,
    setIsSplitCertificateDialogOpen,
    setIsScanningFVS,
    setPassedFVS,
    setIsCertificateUpdated,
    setIsSplittingCertificates,
    setIsCertificateGridEllipsesMenu,
    setIsBulkDownloadCertificateDialogOpen,
    setIsInvalidReasonDialogOpen,
    setEscalationLog,
    setIsLoadingEscalationLog,
    setFvsValidationData,
    setIsLoadingCertificateColumnConfig,
    setCustomLabels,
    setCertificateLabels,
    setLoadingCertificateLabels,
    setCertificateExistingCustomFieldValues,
    setCertificateAddedCustomFieldValues,
    setIsLoadingTaxContentForms,
    setTaxContentForms,
    setTaxContentForm,
    setTaxTemplateTag,
    setIsContentFormDialogOpen,
    setIsTaxTypeDialogOpen,
    setIsLoadingCommunicationTaxTypes,
    setCertificateCommunicationTaxTypes,
    setExistingTaxTypesReset,
    setMostCommonForm,
    setCertificateCountAPICompleted,
    incrementCertificateCountAPICompleted,
    decrementCertificateCountAPICompleted,
    setIsLoadingCertificateCount,
    incrementValidationStateCount,
    setValidationStateCount,
    setLoadingCertificateSources,
    setCertificateSources,
    setLoadingCertificateReviewers,
    setCertificateReviewers,
} = certificateSlice.actions;

export const selectCertificateExistingCustomFieldValues = state => {
    return state.certificate.certificateExistingCustomFieldValues;
};

export const selectCertificateAddedCustomFieldValues = state => {
    return state.certificate.certificateAddedCustomFieldValues;
};

export const selectIsInvalidReasonDialogOpen = state => {
    return state.certificate.isInvalidReasonDialogOpen;
};

export const selectIsBulkDownloadCertificateDialogOpen = state => {
    return state.certificate.isBulkDownloadCertificateDialogOpen;
};

export const selectMultiCertChildList = state => {
    return state.certificate.multiCertChildList;
};

export const selectIsLoadingCertificateExport = state => {
    return state.certificate.isLoadingCertificateExport;
};

export const selectLoading = state => {
    return state.certificate.isloading;
};

export const selectIsLoadingCountries = state => {
    return state.certificate.isLoadingCountries;
};

export const selectIsLoadingAssignment = state => {
    return state.certificate.isLoadingAssignment;
};

export const selectIsUploadingFile = state => {
    return state.certificate.isUploadingFile;
};

export const selectIsAutoValidatingFile = state => {
    return state.certificate.isAutoValidatingFile;
};

export const selectIsLoadingExposureZones = state => {
    return state.certificate.isLoadingExposureZones;
};

export const selectIsLoadingExemptionReasons = state => {
    return state.certificate.isLoadingExemptionReasons;
};

export const selectIsLoadingExemptionMatrix = state => {
    return state.certificate.isLoadingExemptionMatrix;
};

export const selectIsLoadingCoverLetter = state => {
    return state.certificate.isLoadingCoverLetter;
};

export const selectIsScanningFVS = state => {
    return state.certificate.isScanningFVS;
};

export const selectPassedFVS = state => {
    return state.certificate.passedFVS;
};

export const selectCountries = state => {
    if (state.certificate.countries?.length === 0) {
        if (localStorage.getItem("countries")) {
            return JSON.parse(localStorage.getItem("countries"));
        }
    }
    return state.certificate.countries;
};

export const selectDefaultCountryId = state => {
    if (localStorage.getItem("defaultCountryId")) {
        return JSON.parse(localStorage.getItem("defaultCountryId"));
    }
    return state.certificate.defaultCountryId;
};

export const selectLimitedCountries = state => {
    if (localStorage.getItem("limitedCountries")) {
        return JSON.parse(localStorage.getItem("limitedCountries"));
    }
    return state.certificate.limitedCountries;
};

export const selectExposureZones = createSelector(
    state => state.certificate,
    certificate => {
        if (certificate.exposureZones?.length === 0) {
            if (localStorage.getItem("incomingZones")) {
                return JSON.parse(localStorage.getItem("incomingZones"));
            }
        }

        return certificate.exposureZones;
    }
);

export const selectSUTOutgoingExposureZones = createSelector(
    state => state.certificate,
    certificate => {
        if (certificate.sutOutgoingExposureZones?.length === 0) {
            if (localStorage.getItem("outgoingZones")) {
                return JSON.parse(localStorage.getItem("outgoingZones"));
            }
        }

        return certificate.sutOutgoingExposureZones;
    }
);

export const selectIsLoadingDataEntrySets = state => {
    return state.certificate.isLoadingDataEntrySets;
};

export const selectDataEntrySets = state => {
    if (localStorage.getItem("dataentrysets")) {
        return JSON.parse(localStorage.getItem("dataentrysets"));
    }
    return state.certificate.dataEntrySets;
};

export const selectRegionIds = createSelector(
    state => state.certificate,
    certificate => certificate.regionIds
);

export const selectMatrixZones = state => {
    return state.certificate.matrixZones;
};

export const selectExemptionReasons = state => {
    if (state.certificate.exemptionReasons?.length === 0)
        if (localStorage.getItem("exemptReasons")) {
            return JSON.parse(localStorage.getItem("exemptReasons"));
        }

    return state.certificate.exemptionReasons;
};

export const selectOutgoingExemptionReasons = state => {
    if (state.certificate.outgoingExemptionReasons?.length === 0) {
        if (localStorage.getItem("vemExemptReasons")) {
            return JSON.parse(localStorage.getItem("vemExemptReasons"));
        }
    }
    return state.certificate.outgoingExemptionReasons;
};

export const selectStandardCoverLetter = state => {
    return state.certificate.standardCoverLetter;
};

export const selectCertificateStatuses = createSelector(
    state => state.certificate,
    certificate => {
        if (certificate.certificateStatuses?.length === 0) {
            if (localStorage.getItem("certificateStatuses")) {
                return JSON.parse(localStorage.getItem("certificateStatuses"));
            }
        }

        return certificate.certificateStatuses;
    }
);

export const selectExemptionMatrix = state => {
    return state.certificate.exemptionMatrix;
};

export const selectExemptionMatrixValidation = createSelector(
    state => state.certificate,
    certificate => certificate.exemptionMatrixValidation
);

export const selectIsLoadingCustomerCert = state => {
    return state.certificate.isLoadingCustomerCert;
};

export const selectIsPostingCert = state => {
    return state.certificate.isPostingCert;
};
export const selectIsCertPresent = state => {
    return state.certificate.isCertPresent;
};
export const selectIsDeletingCert = state => {
    return state.certificate.isDeletingCert;
};

export const selectIsDeletingCertFile = state => {
    return state.certificate.isDeletingCertFile;
};

export const selectCustomerCert = createSelector(
    state => state.certificate,
    certificate => certificate.customerCert
);

export const selectDownloadableFile = state => {
    return state.certificate.downloadableFile;
};

export const selectExposureCountry = state => {
    return state.certificate.exposureCountry;
};

export const selectTaxCode = state => {
    return state.certificate.taxCode;
};
export const selectIsLoadingCertificateStatuses = state => {
    return state.certificate.isLoadingCertificateStatuses;
};

export const getCertificateFormState = state => {
    return state.certificate.certificateFormState;
};

export const selectPage = state => {
    return state.certificate.page;
};

export const selectNoData = state => {
    return state.certificate.noDataType;
};

export const selectNoSearch = state => {
    return state.certificate.noSearch;
};
export const selectLabels = state => {
    if (localStorage.getItem("labels")) {
        return JSON.parse(localStorage.getItem("labels"));
    }
    return state.certificate.labels;
};
export const selectIsLoadingLabels = state => {
    return state.certificate.isLoadingLabels;
};

export const selectIsLoadingValidationData = state => {
    return state.certificate.isLoadingValidationData;
};

export const selectValidationData = createSelector(
    state => state.certificate,
    certificate => certificate.validationData
);

export const selectFvsValidationData = state => {
    return state.certificate.fvsValidationData;
};

export const selectHasFileUnderValidation = state => {
    return state.certificate.hasFileUnderValidation;
};

export const selectHasCertificateFile = state => {
    return state.certificate.hasCertificateFile;
};
export const selectSortColumn = state => {
    return state.certificate.sortColumn;
};

export const selectIsLoadingInvalidReasons = state => {
    return state.certificate.isLoadingInvalidReasons;
};

export const selectInvalidReasons = state => {
    if (localStorage.getItem("invalidReasons")) {
        return JSON.parse(localStorage.getItem("invalidReasons"));
    }
    return state.certificate.invalidReasons;
};

export const selectIsLoadingCertificateCustomFields = state => {
    return state.certificate.isLoadingCertificatecustomFields;
};

export const selectCustomFields = state => {
    return state.certificate.customFields;
};

export const selectCertificateCustomFields = state => {
    return state.certificate.certificateCustomFields;
};

export const selectIsAttachingFile = state => {
    return state.certificate.isAttachingFile;
};

export const selectIsLoadingSupplementalFiles = state => {
    return state.certificate.isLoadingSupplementalFiles;
};

export const selectSupplementalFiles = state => {
    return state.certificate.supplementalFiles;
};
export const selectIsAttachingComment = state => {
    return state.certificate.isAttachingComment;
};

export const selectIsLoadingComments = state => {
    return state.certificate.isLoadingSupplementalFiles;
};

export const selectComments = state => {
    return state.certificate.comments;
};

export const selectIsDeletingComment = state => {
    return state.certificate.isDeletingComment;
};

export const selectPendingFormState = state => {
    return state.certificate.pendingFormState;
};

export const selectCertificateFiltersState = state => {
    return state.certificate.certificateFiltersState;
};

export const selectPendingCertificateFiltersState = state => {
    return state.certificate.pendingCertificateFiltersState;
};

export const selectFormState = state => {
    return state.certificate.formState;
};

export const selectAutoValidationData = state => {
    return state.certificate.autoValidationData;
};

export const selectRedirectURL = state => {
    return state.certificate.redirectURL;
};

export const selectSavedSearchState = state => {
    return state.certificate.certificateFiltersState.savedSearch;
};

export const selectIsMulti = state => {
    return state.certificate.isMulti;
};
export const selectIsRegionValid = state => {
    return state.certificate.isRegionValid;
};
export const selectRegionMessage = state => {
    return state.certificate.regionMessage;
};
export const selectTaxcodeMap = state => {
    return state.certificate.isTaxcodeMap;
};
export const selectIsEmailAddressDialogOpen = state => {
    return state.certificate.isEmailAddressDialogOpen;
};
export const selectIsUploadDialogOpen = state => {
    return state.certificate.isUploadDialogOpen;
};
export const selectIsUploadDialogEllipsesMenuOpen = state => {
    return state.certificate.isUploadDialogEllipsesMenuOpen;
};
export const selectIsPauseCertificateDialogOpen = state => {
    return state.certificate.isPauseCertificateDialogOpen;
};
export const selectIsResumeCertificateDialogOpen = state => {
    return state.certificate.isResumeCertificateDialogOpen;
};
export const selectIsSplitCertificateDialogOpen = state => {
    return state.certificate.isSplitCertificateDialogOpen;
};
export const selectIsSplittingCertificates = state => {
    return state.certificate.isSplittingCertificates;
};
export const selectIsLoadingMulti = state => {
    return state.certificate.isLoadingMulti;
};
export const selectIsCertificateUpdated = state => {
    return state.certificate.isCertificateUpdated;
};
export const selectIsCertificateGridEllipsesMenu = state => {
    return state.certificate.isCertificateGridEllipsesMenu;
};
export const selectEscalationLog = state => {
    return state.certificate.escalationLog;
};
export const selectIsLoadingEscalationLog = state => {
    return state.certificate.isLoadingEscalationLog;
};

export const selectIsLoadingSelectedColumns = state => {
    return state.certificate.isLoadingSelectedColumns;
};

export const selectIsLoadingPendingSelectedColumns = state => {
    return state.certificate.isLoadingPendingCertificateSelectedColumns;
};

export const getSelectedColumns = state => {
    return state.certificate.selectedColumns;
};

export const selectPendingCertificateSelectedColumns = state => {
    return state.certificate.pendingCertificateSelectedColumns;
};
export const selectCustomLabels = state => {
    return state.certificate.customLabels;
};
export const selectCertificateLabels = state => {
    return state.certificate.certificateLabels;
};

export const selectIsLoadingCertificateLabels = state => {
    return state.certificate.isLoadingCertificateLabels;
};

export const selectTaxContentForms = state => {
    return state.certificate.taxContentForms;
};

export const selectIsLoadingTaxContentForms = state => {
    return state.certificate.isLoadingTaxContentForms;
};

export const selectTaxContentForm = createSelector(
    state => state.certificate,
    certificate => certificate.taxContentForm
);

export const selectTaxTemplateTag = state => {
    return state.certificate.templateTag;
};

export const selectIsContentFormDialogOpen = state => {
    return state.certificate.isContentFormDialogOpen;
};

export const selectIsTaxTypeDialogOpen = state => {
    return state.certificate.isTaxTypeDialogOpen;
};

export const selectIsLoadingCommunicationTaxTypes = state => {
    return state.certificate.isLoadingCommunicationTaxTypes;
};

export const selectCertificateCommunicationTaxTypes = state => {
    return state.certificate.certificateCommunicationTaxTypes;
};

export const selectMostCommonForm = state => {
    return state.certificate.mostCommonForm;
};

export const selectIsCertificateCountAPICompleted = state => {
    return state.certificate.isCertificateCountAPICompleted;
};

export const selectIsLoadingCertificateCount = state => {
    return state.certificate.isLoadingCertificateCount;
};

export const selectValidationStateCount = state => {
    return state.certificate.validationStateCount;
};

export const selectCertificateSources = state => {
    return state.certificate.certificateSources;
};

export const selectIsLoadingCertificateSources = state => {
    return state.certificate.isLoadingCertificateSources;
};

export const selectCertificateReviewers = state => {
    return state.certificate.certificateReviewers;
};

export const selectIsLoadingCertificateReviewers = state => {
    return state.certificate.isLoadingCertificateReviewers;
};

export const upsertCertificateColumnConfig = preferenceValue => async () => {
    // to handle mismatch with pre-saved ecm preferences.
    const preferenceValueUpdated = JSON.stringify(preferenceValue).replace(
        "certificateLabels",
        "certificatesLabels"
    );
    await axios.post(
        buildApiV3Url(`ecm-preferences`),
        {
            preference: "certificate-columns",
            preferenceValue: preferenceValueUpdated,
        },
        { withCredentials: true }
    );
};

export const upsertDocumentColumnConfig = preferenceValue => async () => {
    // to handle mismatch with pre-saved ecm preferences.
    const preferenceValueUpdated = JSON.stringify(preferenceValue).replace(
        "vendorCertificateLabels",
        "vendorCertificatesLabels"
    );
    await axios.post(
        buildApiV3Url(`ecm-preferences`),
        {
            preference: "vendor-certificate-columns",
            preferenceValue: preferenceValueUpdated,
        },
        { withCredentials: true }
    );
};

export const getCertificateColumnList =
    (
        isEligibleUserCustomFields,
        isUserEligibleToViewCertificateLabels,
        isUserEligibleToViewReviewedBy
    ) =>
    async (dispatch, getState) => {
        const { selectedColumns, isLoadingSelectedColumns } = getState().certificate;

        if (isLoadingSelectedColumns) {
            return false;
        }
        dispatch(setIsLoadingSelectedColumns(true));
        let config = selectedColumns;

        // fetch the user preferences and check if there is a saved column config
        const preferenceResponse = await axios.get(buildApiV3Url(`ecm-preferences`), {
            withCredentials: true,
        });

        if (preferenceResponse && preferenceResponse.data && preferenceResponse.data.value) {
            const certificatePreference = preferenceResponse.data.value.filter(
                pref => pref.preference === "certificate-columns"
            );
            if (certificatePreference.length !== 0 && certificatePreference[0].preferenceValue) {
                config = JSON.parse(certificatePreference[0].preferenceValue);
            }
        }
        if (isUserEligibleToViewCertificateLabels) {
            const certificateLabelColumnItem = {
                id: "certificatesLabels",
                label: "Labels",
                hidden: true,
            };
            // Adds the certificateLabelColumn after the default columns if not present
            config = [
                ...new Map(
                    [...selectedColumns, certificateLabelColumnItem, ...config].map(item => [
                        item.id,
                        item,
                    ])
                ).values(),
            ];
            // If customersLabels is already present then override its label value with current one
            config = [
                ...config.map(column => {
                    if (column.id === certificateLabelColumnItem.id) {
                        return { ...column, label: certificateLabelColumnItem.label };
                    }
                    return column;
                }),
            ];
        }

        if (isUserEligibleToViewReviewedBy) {
            const certificateReviewedByColumnItem = {
                id: "uploadedBy",
                label: "Reviewed by",
                hidden: true,
            };
            // Adds the certificateReviewedBy after the default columns if not present
            config = [
                ...new Map(
                    [...selectedColumns, certificateReviewedByColumnItem, ...config].map(item => [
                        item.id,
                        item,
                    ])
                ).values(),
            ];
        }

        if (isEligibleUserCustomFields) {
            let certificateCustomFields = [];
            // fetch the custom fields

            const response = await axios.get(buildApiV3Url(`certificate-custom-fields?$top=100`), {
                withCredentials: true,
            });
            if (response && response.data && response.data.value) {
                certificateCustomFields = response.data.value;
                dispatch(setCustomFields(response.data.value));
            }

            const customFieldIds = new Set(
                certificateCustomFields.map(field => removeSpaces(field.fieldName))
            );

            // Remove custom fields not present in exiting preferences
            config = config.filter(
                column => !column.fieldName || customFieldIds.has(removeSpaces(column.fieldName))
            );

            // if there are custom fields, add them to the column list
            if (certificateCustomFields.length !== 0) {
                const formatCustomFields = certificateCustomFields.map(field => ({
                    id: removeSpaces(field.fieldName),
                    label: field.fieldName,
                    hidden: true,
                    fieldName: field.fieldName,
                }));

                // add custom fields which are not present in column list after the default columns
                config = [
                    ...new Map(
                        [...formatCustomFields.reverse(), ...config.reverse()].map(item => [
                            item.id,
                            item,
                        ])
                    ).values(),
                ].reverse();
                config = [
                    ...config.map(column => {
                        const matchingData = formatCustomFields.find(data => data.id === column.id);
                        if (matchingData) {
                            return {
                                ...column,
                                fieldName: matchingData.fieldName,
                            };
                        }
                        return column;
                    }),
                ];
                dispatch(upsertCertificateColumnConfig(config));
            }
        }
        dispatch(setSelectedColumns(config));
        dispatch(setIsLoadingSelectedColumns(false));
        return true;
    };

export const getDocumentColumnList =
    (isEligibleUserCustomFields, isUserEligibleToViewCertificateLabels) =>
    async (dispatch, getState) => {
        const { vendorColumns, isLoadingSelectedColumns } = getState().certificate;

        if (isLoadingSelectedColumns) {
            return false;
        }
        dispatch(setIsLoadingSelectedColumns(true));
        let config = vendorColumns;

        // fetch the user preferences and check if there is a saved column config
        const preferenceResponse = await axios.get(buildApiV3Url(`ecm-preferences`), {
            withCredentials: true,
        });

        if (preferenceResponse && preferenceResponse.data && preferenceResponse.data.value) {
            const certificatePreference = preferenceResponse.data.value.filter(
                pref => pref.preference === "vendor-certificate-columns"
            );
            if (certificatePreference.length !== 0 && certificatePreference[0].preferenceValue) {
                config = JSON.parse(certificatePreference[0].preferenceValue);
            }
        }
        if (isUserEligibleToViewCertificateLabels) {
            const certificateLabelColumnItem = {
                id: "vendorCertificatesLabels",
                label: "Labels",
                hidden: true,
            };
            // Adds the certificateLabelColumn after the default columns if not present
            config = [
                ...new Map(
                    [...vendorColumns, certificateLabelColumnItem, ...config].map(item => [
                        item.id,
                        item,
                    ])
                ).values(),
            ];
            // If customersLabels is already present then override its label value with current one
            config = [
                ...config.map(column => {
                    if (column.id === certificateLabelColumnItem.id) {
                        return { ...column, label: certificateLabelColumnItem.label };
                    }
                    return column;
                }),
            ];
        }
        if (isEligibleUserCustomFields) {
            let certificateCustomFields = [];
            // fetch the custom fields

            const response = await axios.get(buildApiV3Url(`certificate-custom-fields?$top=100`), {
                withCredentials: true,
            });
            if (response && response.data && response.data.value) {
                certificateCustomFields = response.data.value;
                dispatch(setCustomFields(response.data.value));
            }
            // if there are custom fields, add them to the column list
            if (certificateCustomFields.length !== 0) {
                const formatCustomFields = certificateCustomFields.map(field => ({
                    id: removeSpaces(field.fieldName),
                    label: field.fieldName,
                    hidden: true,
                    fieldName: field.fieldName,
                }));

                // add custom fields which are not present in column list after the default columns
                config = [
                    ...new Map(
                        [...formatCustomFields.reverse(), ...config.reverse()].map(item => [
                            item.id,
                            item,
                        ])
                    ).values(),
                ].reverse();
                config = [
                    ...config.map(column => {
                        const matchingData = formatCustomFields.find(data => data.id === column.id);
                        if (matchingData) {
                            return {
                                ...column,
                                fieldName: matchingData.fieldName,
                            };
                        }
                        return column;
                    }),
                ];
                dispatch(upsertDocumentColumnConfig(config));
            }
        }
        dispatch(setVendorColumns(config));
        dispatch(setIsLoadingSelectedColumns(false));
        return true;
    };

export const upsertPendingCertificateColumnConfig = preferenceValue => async () => {
    await axios.post(
        buildApiV3Url(`ecm-preferences`),
        {
            preference: pendingCertificateColumnPreference,
            preferenceValue: JSON.stringify(preferenceValue),
        },
        { withCredentials: true }
    );
};

export const getPendingCertificateColumnList = () => async (dispatch, getState) => {
    const { pendingCertificateSelectedColumns, isLoadingPendingCertificateSelectedColumns } =
        getState().certificate;

    if (isLoadingPendingCertificateSelectedColumns) {
        return false;
    }

    dispatch(setIsLoadingPendingCertificateSelectedColumns(true));
    let config = pendingCertificateSelectedColumns;

    const preferenceResponse = await axios.get(buildApiV3Url(`ecm-preferences`), {
        withCredentials: true,
    });

    if (preferenceResponse?.data?.value) {
        const pendingCertificatePreference = preferenceResponse.data.value.filter(
            pref => pref.preference === "pending-certificate-columns"
        );
        if (
            pendingCertificatePreference.length !== 0 &&
            pendingCertificatePreference[0].preferenceValue
        ) {
            config = JSON.parse(pendingCertificatePreference[0].preferenceValue);
        }
    }

    const originalFileColumnItem = {
        id: "orig-file-name",
        label: "File name",
        hidden: false,
    };

    // If Original File Column is already present then override its label value with new one
    config = [
        ...config.map(column => {
            if (column.id === originalFileColumnItem.id) {
                return { ...column, label: originalFileColumnItem.label };
            }
            return column;
        }),
    ];

    const updatedConfig = config.filter(x => x.id !== "certificate-file-name");
    dispatch(setPendingCertificateSelectedColumns(updatedConfig));
    dispatch(setIsLoadingPendingCertificateSelectedColumns(false));
    return true;
};

export const getVendorColumns = state => {
    return state.certificate.vendorColumns;
};

export const getCountriesAsync = () => async (dispatch, getState) => {
    const { certificate } = getState();

    if (certificate.isLoadingCountries || certificate.countries.length > 0) {
        return false;
    }
    if (localStorage.getItem("countries")) {
        return false;
    }

    dispatch(setLoadingCountries(true));
    const response = await axios.get(buildApiV3Url(`countries?$top=300&$orderBy=id`), {
        withCredentials: true,
    });

    const allCountries = convertFieldToString(response.data.value, "id");

    const defaultCountry = allCountries.filter(x => x.name === "United States");
    dispatch(setDefaultCountryId(defaultCountry[0].id));
    localStorage.setItem("defaultCountryId", JSON.stringify(defaultCountry[0].id));

    dispatch(setCountries(allCountries));
    localStorage.setItem("countries", JSON.stringify(allCountries));

    dispatch(setLoadingCountries(false));

    return true;
};

export const getLimitedCountriesAsync = () => async (dispatch, getState) => {
    const { certificate } = getState();
    if (certificate.isLoadingCountries || certificate.limitedCountries.length === 2) {
        return false;
    }
    if (localStorage.getItem("limitedCountries")) {
        return false;
    }

    dispatch(setLoadingCountries(true));
    const response = await axios.get(
        buildApiV3Url(`countries?$filter=Name IN ('United States','Canada')&$orderBy=name desc`),
        { withCredentials: true }
    );

    localStorage.setItem("limitedCountries", JSON.stringify(response.data.value));

    dispatch(setLimitedCountries(response.data.value));
    dispatch(setLoadingCountries(false));
    return true;
};

export const getExposureZonesAsync = filterString => async (dispatch, getState) => {
    const { certificate } = getState();
    if (certificate.isLoadingExposureZones) {
        return false;
    }

    if (filterString?.includes("outgoing eq true") && localStorage.getItem("outgoingZones")) {
        return false;
    }
    if (!filterString?.includes("outgoing eq true") && localStorage.getItem("incomingZones")) {
        return false;
    }

    dispatch(setLoadingExposureZones(true));
    const response = await axios.get(
        buildApiV3Url(`regions/${filterString}&$top=3000`),

        { withCredentials: true }
    );

    const transformedStandardZones = convertFieldToString(response.data.value, "id");
    if (filterString?.includes("outgoing eq true")) {
        localStorage.setItem("outgoingZones", JSON.stringify(transformedStandardZones));
        dispatch(setSUTOutgoingExposureZones(transformedStandardZones));
    } else {
        localStorage.setItem("incomingZones", JSON.stringify(transformedStandardZones));
        dispatch(setExposureZones(transformedStandardZones));
    }
    dispatch(setLoadingExposureZones(false));
    return true;
};

export const getDataEntrySetsAsync = () => async (dispatch, getState) => {
    const { certificate } = getState();
    if (certificate.isLoadingDataEntrySets || localStorage.getItem("dataentrysets")) {
        return false;
    }

    dispatch(setIsLoadingDataEntrySets(true));
    const response = await axios.get(buildApiV3Url(`dataentrysets`), {
        withCredentials: true,
    });
    if (response?.data?.value) {
        localStorage.setItem("dataentrysets", JSON.stringify(response.data.value));
        dispatch(setDataEntrySets(response.data.value));
    } else {
        dispatch(setDataEntrySets([]));
    }
    dispatch(setIsLoadingDataEntrySets(false));
    return true;
};

export const fetchRegionIdsByDataSetIdAPI = dataEntrySetId => async dispatch => {
    dispatch(setLoadingExposureZones(true));
    const response = await axios.get(buildApiV3Url(`regions/dataentryset/${dataEntrySetId}`), {
        withCredentials: true,
    });
    if (response?.data?.value) {
        const transformedRegionIds = convertFieldToString(response.data.value, "regionId");
        dispatch(setRegionIds(transformedRegionIds));
    } else dispatch(setRegionIds([]));

    dispatch(setLoadingExposureZones(false));
};

export const getCertificateStatusesAsync = () => async (dispatch, getState) => {
    const { certificate } = getState();
    if (certificate.isLoadingCertificateStatuses || certificate.certificateStatuses.length > 0) {
        return false;
    }
    if (localStorage.getItem("certificateStatuses")) {
        return false;
    }

    dispatch(setLoadingCertificateStatuses(true));
    const response = await axios.get(buildApiV3Url(`certificate-statuses`), {
        withCredentials: true,
    });

    if (response && response.data && response.data.value) {
        dispatch(setCertificateStatuses(response.data.value));
        localStorage.setItem("certificateStatuses", JSON.stringify(response.data.value));
    }
    dispatch(setLoadingCertificateStatuses(false));
    return true;
};

export const getExemptionReasonsAsync = filterString => async (dispatch, getState) => {
    const { certificate } = getState();

    if (certificate.isLoadingExemptionReasons || certificate.exemptionReasons.length > 0) {
        return false;
    }
    if (localStorage.getItem("exemptReasons")) {
        return false;
    }

    const isTelecom = matchSubscriptions(
        featureFlag.afcContent.associateTaxTypeData.subscription,
        dispatch(getStoreValues("session.subscriptions"))
    );
    if (!isTelecom) {
        filterString += ` AND isTelecom eq false`;
    }

    dispatch(setLoadingExemptionReasons(true));
    const apiResponse = await axios.get(
        buildApiV3Url(`exemption-reasons/${filterString}&$orderBy=name ASC&$top=100`),
        {
            withCredentials: true,
        }
    );

    if (parseSuccessResponse(CRUDOperation.ReadAll, apiResponse)?.recordFound) {
        const parseReadResponse = parseGetAllObjResponse(apiResponse);
        const transformedExemptReason = convertFieldToString(parseReadResponse.value, "id");
        const exemptionReasonsRenamed = transformExemptionData(
            transformedExemptReason,
            false,
            true
        );
        dispatch(setExemptionReasons(exemptionReasonsRenamed));
        localStorage.setItem("exemptReasons", JSON.stringify(exemptionReasonsRenamed));
    }
    dispatch(setLoadingExemptionReasons(false));
    return true;
};

// RJB: I needed to create this due to an issue with using localStorage checks for methods that get used by components across document types. We should re-evaluate why and how we do this.
export const getOutgoingExemptionReasonsAsync = filterString => async (dispatch, getState) => {
    const { certificate } = getState();

    if (
        certificate.isLoadingOutgoingExemptionReasons ||
        certificate.outgoingExemptionReasons.length > 0
    ) {
        return false;
    }
    if (localStorage.getItem("vemExemptReasons")) {
        return false;
    }

    dispatch(setLoadingOutgoingExemptionReasons(true));
    const response = await axios.get(
        buildApiV3Url(`exemption-reasons/${filterString}&$orderBy=name ASC&$top=100`),
        {
            withCredentials: true,
        }
    );
    if (response && response.data && response.data.value) {
        const transformedVemExemptReasons = convertFieldToString(response.data.value, "id");
        const exemptionReasonRenamed = transformExemptionData(
            transformedVemExemptReasons,
            false,
            true
        );

        dispatch(setOutgoingExemptionReasons(exemptionReasonRenamed));
        localStorage.setItem("vemExemptReasons", JSON.stringify(exemptionReasonRenamed));
    }
    dispatch(setLoadingOutgoingExemptionReasons(false));
    return true;
};

export const getSearchedCertificates = () => (dispatch, getState) => {
    const { page } = getState().certificate;
    return page;
};

// TODO: Remove legacy logic when Add Certifiate page is deprecated
export const getExemptionMatrix =
    (filterString, showToast, allowMultipleCalls, legacy = true) =>
    async (dispatch, getState) => {
        const { certificate } = getState();
        let exposureZones = [];
        if (certificate.isLoadingExemptionMatrix && !allowMultipleCalls) {
            return;
        }

        dispatch(setLoadingExemptionMatrix(true));

        const response = await axios.get(buildApiV3Url(`exemption-matrix${filterString}`), {
            withCredentials: true,
        });
        let exemptionMatrix = [];
        if (response && response.data && response.data.value) exemptionMatrix = response.data.value;

        Object.values(exemptionMatrix).forEach(element => {
            if (legacy) {
                exposureZones.push(element.region.name);
            } else {
                exposureZones.push({ value: element.region.id, label: element.region.name });
            }
        });

        if (!exposureZones.length) {
            showToast &&
                showToast(
                    "warning",
                    "Exemption matrix does not collect for selected reason for exemption."
                );
            dispatch(setMatrixZones([]));
        } else if (legacy) {
            exposureZones.sort();
            const selectZones = exposureZones.map(element => ({
                label: element,
                value: element,
            }));
            dispatch(setMatrixZones(selectZones));
        } else {
            exposureZones = orderBy(exposureZones, ["label"], ["asc"]);
            dispatch(setMatrixZones(exposureZones));
        }
        dispatch(setExemptionMatrix(exemptionMatrix));
        dispatch(setLoadingExemptionMatrix(false));
    };

export const fetchTemplateCommunicationTaxTypesAsync = filterString => async dispatch => {
    dispatch(setIsLoadingCommunicationTaxTypes(true));
    const taxTypeSort = [];
    const templateCommunicationTaxTypeIds = [];

    const response = await axios.get(
        buildApiV3Url(`template-communication-tax-types${filterString}`),
        {
            withCredentials: true,
        }
    );

    if (response && response.data) {
        Object.values(response.data.value).forEach(element => {
            taxTypeSort.push({
                id: element.communicationTaxType.id.toString(),
                codeId: element.communicationTaxType.afcCode.code,
                code: element.communicationTaxType.afcCode.name,
                rateType: element.communicationTaxType.communicationTaxTypesCategory.rateType,
                exposureZoneId: element.communicationTaxType.exposureZoneId,
            });
            templateCommunicationTaxTypeIds.push(element.communicationTaxType.id);
        });

        const byRateType = taxTypeSort.slice(0);
        byRateType.sort((a, b) => {
            const x = a.rateType.toLowerCase();
            const y = b.rateType.toLowerCase();
            // eslint-disable-next-line no-nested-ternary
            return x < y ? -1 : x > y ? 1 : 0;
        });

        dispatch(setCertificateCommunicationTaxTypes(byRateType));
    }

    dispatch(setIsLoadingCommunicationTaxTypes(false));
    return true;
};

export const fetchCertificateCommunicationTaxTypesAsync = certificateId => async dispatch => {
    dispatch(setIsLoadingCommunicationTaxTypes(true));
    const communicationTaxTypeIds = [];
    const communicationTaxTypes = [];

    const response = await axios.get(
        buildApiV3Url(`certificates/${certificateId}/communication-tax-types?$top=1000`),

        {
            withCredentials: true,
        }
    );

    if (response && response.data) {
        Object.values(response.data.value).forEach(element => {
            if (!communicationTaxTypeIds.includes(element.communicationTaxType.id)) {
                communicationTaxTypeIds.push(element.communicationTaxType.id);
                communicationTaxTypes.push({
                    id: element.communicationTaxType.id.toString(),
                    codeId: element.communicationTaxType.afcCodeCode,
                    code: element.communicationTaxType.afcCodeName,
                    rateType: element.communicationTaxType.rateType,
                    exposureZoneId: element.communicationTaxType.exposureZoneId,
                });
            }
        });

        const byRateType = communicationTaxTypes.slice(0);

        byRateType.sort((a, b) => {
            const x = a.rateType.toLowerCase();
            const y = b.rateType.toLowerCase();
            // eslint-disable-next-line no-nested-ternary
            return x < y ? -1 : x > y ? 1 : 0;
        });
        dispatch(setCertificateCommunicationTaxTypes(byRateType));
    }
    dispatch(setIsLoadingCommunicationTaxTypes(false));
    return true;
};

export const fetchTaxContentFormsAsync =
    (reason, region, existingTemplateTag, certificateID, certOnly = false) =>
    async dispatch => {
        dispatch(setTaxContentForm(""));
        dispatch(setTaxTemplateTag(""));
        let taxContentFormValues = null;
        let taxContentAndStateForms = null;
        let templateTags = [];

        // Fetch form and taxType data only if account has the following subscriptions
        // 1. ECMProComms
        // 2. ECMPremiumComms
        if (
            !matchSubscriptions(
                featureFlag.afcContent.associateTaxTypeData.subscription,
                dispatch(getStoreValues("session.subscriptions"))
            ) ||
            !region
        ) {
            return false;
        }

        dispatch(setIsLoadingTaxContentForms(true));

        let regionParam = region[0].name;
        let matrixParam = `'${region[0].name}'`;
        let regionInitials = region[0].initials;
        if (region.length > 1) {
            regionParam = region.map(r => r.name).toString();
            matrixParam = `'${regionParam.split(",").join("','")}'`;
            regionInitials = region
                .map(r => r.initials)
                .toString()
                .split(",")
                .join("','");
        }

        const response = await axios.get(
            buildApiV3Url(
                `tax-content/certificate-forms?exposureZoneName=${regionParam}&certOnlyForms=${certOnly}`
            ),
            {
                withCredentials: true,
            }
        );

        const responseStateForms = await axios.get(
            buildApiV3UrlWithQuery(`tax-content/state-forms`, `region in ('${regionInitials}')`),
            {
                withCredentials: true,
            }
        );
        if (response && response.data) {
            taxContentFormValues = response.data;
            taxContentAndStateForms = [response.data];
            if (responseStateForms && responseStateForms.data && responseStateForms.data.value[0]) {
                taxContentFormValues = taxContentFormValues.concat(responseStateForms.data.value);
                taxContentAndStateForms = taxContentAndStateForms.concat([
                    responseStateForms.data.value,
                ]);
            }
            dispatch(setTaxContentForms(taxContentAndStateForms));
        }
        let foundForm = null;

        const pageSize = region.length;
        const pageNumber = 0;
        const filterString = `region.documentType.name eq 'Sales and Use Tax' AND region.name in (${matrixParam}) AND exemptReason.${
            reason.id ? "Id" : "Name"
        } eq '${reason.id || reason.name}'`;
        const collectionURI = buildApiV3UrlWithQuery(
            `exemption-matrix/default-matrix`,
            filterString,
            "",
            pageSize,
            pageNumber,
            ""
        );

        const collectionResponse = await axios.get(`${collectionURI}`, {
            withCredentials: true,
        });
        if (collectionResponse && collectionResponse?.data) {
            templateTags = collectionResponse.data.value.map(c => c.templateTag);
            foundForm = taxContentFormValues.find(o => o.tag === templateTags[0]);

            if (foundForm) {
                dispatch(setMostCommonForm(foundForm.tag));
                dispatch(setTaxContentForm(foundForm.description));
                dispatch(setTaxTemplateTag(foundForm.tag));
            }
            if (existingTemplateTag) {
                foundForm = taxContentFormValues.find(o => o.tag === existingTemplateTag);
                if (foundForm) {
                    dispatch(setTaxContentForm(foundForm.description));
                    dispatch(setTaxTemplateTag(foundForm.tag));
                }
            }
        }
        if (certificateID) {
            dispatch(fetchCertificateCommunicationTaxTypesAsync(certificateID));
        } else if (foundForm) {
            if (templateTags.length > 1) {
                dispatch(
                    fetchTemplateCommunicationTaxTypesAsync(
                        `?$filter=templateTag IN ('${templateTags.join("','")}')&$top=1000`
                    )
                );
            } else {
                dispatch(
                    fetchTemplateCommunicationTaxTypesAsync(
                        `?$filter=templateTag eq '${foundForm.tag}'&$top=1000`
                    )
                );
            }
        }
        dispatch(setIsLoadingTaxContentForms(false));
        return true;
    };

export const fetchExemptionMatrixReasonValidationApi =
    (exposureZoneIds, showToast, documentTypeId) => async (dispatch, getState) => {
        const { certificate } = getState();
        const exposureZones = [];
        if (certificate.isLoadingExemptionMatrix) {
            return;
        }
        dispatch(setLoadingExemptionMatrix(true));
        const response = await axios.post(
            buildApiV3Url(`exemption-matrix/exempt-reasons/validation`),
            { exposureZoneIds, documentTypeId },
            { withCredentials: true }
        );
        if (response?.data?.value) {
            const exemptionMatrix = response.data.value;
            Object.values(exemptionMatrix).forEach(element => {
                exposureZones.push({ value: element.region.id, label: element.region.name });
            });
            const exemptionMatrixUpdated = transformExemptionData(exemptionMatrix, true, false);
            dispatch(setExemptionMatrixValidation(exemptionMatrixUpdated));
        } else {
            if (!exposureZones.length) {
                showToast &&
                    showToast(
                        "warning",
                        "Exemption matrix does not collect for selected reason for exemption."
                    );
            }
            dispatch(setExemptionMatrixValidation([]));
        }

        dispatch(setLoadingExemptionMatrix(false));
    };

export const fetchCertificatesAPI =
    (filterString, include, top, skip, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { isloading, sortColumn } = getState().certificate;
        if (isloading) {
            return;
        }
        dispatch(setLoading(true));
        filterString = fixSearchStringForPostgres(filterString);
        const apiURI = buildApiV2Point5UrlWithQuery(
            `certificates`,
            filterString,
            include,
            top,
            skip,
            sortColumn
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });

        if (response.data && response.data.count === "0") {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull());
        } else {
            dispatch(setNoDataType(""));
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }

        dispatch(setLoading(false));
    };

export const fetchCertificatesSearchAPI =
    (onLanding, paginateDetails, pageSize, pageNumber = 1) =>
    async (dispatch, getState) => {
        const {
            isloading,
            formState,
            certificateFiltersState,
            sortColumn,
            certificateStatuses,
            isCertificateGridEllipsesMenu,
        } = getState().certificate;
        const { rowsPerPage } = getState().session;
        pageSize = !pageSize ? rowsPerPage : pageSize;

        const { nameOrCode, nameOrCodeWhileLoading } = formState;
        if (isloading) {
            dispatch(
                setFormState({
                    ...formState,
                    nameOrCodeWhileLoading: nameOrCode,
                })
            );
            return;
        }

        dispatch(setLoading(true));

        let statuses = certificateStatuses;
        const exemptionStatuses = [];

        if (localStorage.getItem("certificateStatuses")) {
            statuses = JSON.parse(localStorage.getItem("certificateStatuses"));
        }
        if (
            certificateFiltersState?.certificateStatus?.includes("Valid") ||
            certificateFiltersState?.certificateStatus?.includes("Invalid") ||
            certificateFiltersState?.certificateStatus?.includes("Expired") ||
            certificateFiltersState?.certificateStatus?.includes("Paused")
        ) {
            const exemptionStatus = statuses?.filter(
                status => status?.name === "COMPLETE" || status?.name === "PENDING-FUTURE"
            );
            exemptionStatus?.forEach(ele => {
                exemptionStatuses.push(ele?.id);
            });
        }
        if (certificateFiltersState?.certificateStatus?.includes("Pending-future")) {
            exemptionStatuses.push(
                statuses.filter(status => status?.name === "PENDING-FUTURE")[0]?.id
            );
        }

        let searchVal = "";
        if (nameOrCodeWhileLoading !== "") {
            searchVal = nameOrCodeWhileLoading;
        } else if (certificateFiltersState?.nameOrCode !== "") {
            searchVal = certificateFiltersState.nameOrCode;
        } else {
            searchVal = nameOrCode;
        }

        // make a copy of the filters state and remove saved search for comparing
        const filtersWithoutSavedSearch = { ...certificateFiltersState };
        delete filtersWithoutSavedSearch.savedSearch;
        delete filtersWithoutSavedSearch.isCustomerDetails;
        delete filtersWithoutSavedSearch.isCertificateDetails;
        const initialStateWithoutSavedSearch = { ...initialState.certificateFiltersState };
        delete initialStateWithoutSavedSearch.savedSearch;
        delete initialStateWithoutSavedSearch.isCustomerDetails;
        delete initialStateWithoutSavedSearch.isCertificateDetails;

        if (
            isEqual(filtersWithoutSavedSearch, initialStateWithoutSavedSearch) &&
            searchVal === ""
        ) {
            dispatch(setNoSearch(true));
        } else {
            dispatch(setNoSearch(false));
        }

        const requestBody = {
            pageSize,
            pageNumber,
            orderBy: sortColumn[0],
            orderByDirection: sortColumn[1] ? "DESC" : "ASC",
            nameOrCode: fixSearchStringForPostgres(searchVal),
            purchaseOrderNumber: certificateFiltersState.invoiceNumber,
            isPrimary: certificateFiltersState.isCertificateActive,
            isBillTo: certificateFiltersState.isBillToRelation,
            isShipTo: certificateFiltersState.isShipToRelation,
            isDuplicate: certificateFiltersState.isDuplicateRelation,
            isSingle: certificateFiltersState.isPurchaseOrder,
            certificateImageExists: certificateFiltersState.isCertificateImage,
            customerCreatedFrom: certificateFiltersState.customerCreatedDateAfter
                ? certificateFiltersState.customerCreatedDateAfter
                : null,
            customerCreatedTo: certificateFiltersState.customerCreatedDateBefore
                ? certificateFiltersState.customerCreatedDateBefore
                : null,
            customerModifiedFrom: certificateFiltersState.customerModifiedDateAfter
                ? certificateFiltersState.customerModifiedDateAfter
                : null,
            customerModifiedTo: certificateFiltersState.customerModifiedDateBefore
                ? certificateFiltersState.customerModifiedDateBefore
                : null,
            certificateCreatedFrom: certificateFiltersState.certificateCreatedDateAfter
                ? certificateFiltersState.certificateCreatedDateAfter
                : null,
            certificateCreatedTo: certificateFiltersState.certificateCreatedDateBefore
                ? certificateFiltersState.certificateCreatedDateBefore
                : null,
            certificateModifiedFrom: certificateFiltersState.certificateModifiedDateAfter
                ? certificateFiltersState.certificateModifiedDateAfter
                : null,
            certificateModifiedTo: certificateFiltersState.certificateModifiedDateBefore
                ? certificateFiltersState.certificateModifiedDateBefore
                : null,
            certificateEffectiveFrom: certificateFiltersState.certificateEffectiveDateAfter
                ? certificateFiltersState.certificateEffectiveDateAfter
                : null,
            certificateEffectiveTo: certificateFiltersState.certificateEffectiveDateBefore
                ? certificateFiltersState.certificateEffectiveDateBefore
                : null,
            certificateExpirationFrom: certificateFiltersState.certificateExpirationDateAfter
                ? certificateFiltersState.certificateExpirationDateAfter
                : null,
            certificateExpirationTo: certificateFiltersState.certificateExpirationDateBefore
                ? certificateFiltersState.certificateExpirationDateBefore
                : null,
            status: certificateFiltersState.certificateStatus,
            exemptionStatus: exemptionStatuses,
            exposureZones: certificateFiltersState.certificateRegions,
            exemptionReasons: certificateFiltersState.exemptReasons,
            customerRegions: certificateFiltersState.customerRegions,
            certificateIds: certificateFiltersState.certificateIds?.length
                ? certificateFiltersState.certificateIds
                      .split("\n")
                      .map(value => fixSearchStringForPostgres(value.trim()))
                      .map(Number)
                : [],
            customerCodes: certificateFiltersState.customerCodes?.length
                ? certificateFiltersState.customerCodes
                      .split("\n")
                      .map(value => fixSearchStringForPostgres(value.trim()))
                : [],

            activeCertificate: certificateFiltersState.isCertActive,
            certificateHasCustomers: certificateFiltersState.certificateHasCustomers,
            certificateCustomFields: certificateFiltersState.certificateCustomFields,
            certificatesLabels: certificateFiltersState.certificatesLabels,
            customersLabels: certificateFiltersState.customersLabels,
            reviewedBy: certificateFiltersState.reviewedBy,
        };

        axios
            .post(buildApiV3Url(`certificates/search`), requestBody, {
                withCredentials: true,
            })
            .then(async apiResponse => {
                let response;
                if (apiResponse === null || !apiResponse.data) {
                    onLanding
                        ? dispatch(setNoDataType("onLanding"))
                        : dispatch(setNoDataType("onSearch"));
                    dispatch(setPageToNull(pageSize));
                    dispatch(setLoading(false));
                } else {
                    response = transformExemptionData(apiResponse, false, false, [
                        "expectedTaxCodeName",
                        "taxCodeName",
                    ]);
                    dispatch(setNoDataType(""));
                    const { page, isCertificateCountAPICompleted } = getState().certificate;
                    const pageData = { ...page.paginate };
                    const paginateData = paginateDetails
                        ? {
                              pageNumber,
                              startIndex: paginateDetails.startIndex,
                              endIndex: paginateDetails.endIndex,
                              rowsPerPage: paginateDetails.rowsPerPage,
                              totalRecords: pageData.totalRecords,
                              indeterminate: false,
                          }
                        : {
                              pageNumber,
                              startIndex: 0,
                              endIndex: 19,
                              rowsPerPage: pageSize,
                              totalRecords:
                                  isCertificateCountAPICompleted === 0 ? pageData.totalRecords : 20,
                              indeterminate: !(isCertificateCountAPICompleted === 0),
                          };
                    dispatch(
                        setPage({
                            data: response.data.value,
                            paginate: paginateData,
                        })
                    );
                    dispatch(setLoading(false));
                }
                const certificateIds = [];
                if (response?.data) {
                    response.data?.value.forEach(element => {
                        certificateIds.push(element.certificateId);
                    });
                    dispatch(setCertificateIds(certificateIds));
                }
                if (nameOrCodeWhileLoading !== "") {
                    await dispatch(
                        setFormState({
                            ...formState,
                            nameOrCodeWhileLoading: "",
                        })
                    );
                }
            });

        if (!paginateDetails || isCertificateGridEllipsesMenu) {
            dispatch(setIsLoadingCertificateCount(true));
            dispatch(incrementCertificateCountAPICompleted());
            axios
                .post(buildApiV3Url(`certificates/search-count`), requestBody, {
                    withCredentials: true,
                })
                .then(res => {
                    dispatch(decrementCertificateCountAPICompleted());
                    const { page } = getState().certificate;
                    const pageData = { ...page.paginate };
                    pageData.totalRecords = res?.data;
                    pageData.indeterminate = false;
                    dispatch(
                        setPage({
                            data: page.data,
                            paginate: pageData,
                        })
                    );
                    dispatch(setIsLoadingCertificateCount(false));
                });
        }
    };

export const fetchVendorCertificatesSearchAPI =
    (onLanding, paginateDetails, pageSize, pageNumber = 1) =>
    async (dispatch, getState) => {
        const { isloading, formState, certificateFiltersState, sortColumn, certificateStatuses } =
            getState().certificate;
        const { rowsPerPage } = getState().session;
        pageSize = !pageSize ? rowsPerPage : pageSize;

        const { nameOrCode, nameOrCodeWhileLoading } = formState;
        if (isloading) {
            dispatch(
                setFormState({
                    ...formState,
                    nameOrCodeWhileLoading: nameOrCode,
                })
            );
            return;
        }

        dispatch(setLoading(true));
        let statuses = certificateStatuses;
        const exemptionStatuses = [];

        if (localStorage.getItem("certificateStatuses")) {
            statuses = JSON.parse(localStorage.getItem("certificateStatuses"));
        }
        if (
            certificateFiltersState?.certificateStatus?.includes("Valid") ||
            certificateFiltersState?.certificateStatus?.includes("Invalid") ||
            certificateFiltersState?.certificateStatus?.includes("Expired") ||
            certificateFiltersState?.certificateStatus?.includes("Paused")
        ) {
            exemptionStatuses.push(statuses?.filter(status => status?.name === "COMPLETE")[0]?.id);
        }
        if (certificateFiltersState?.certificateStatus?.includes("Pending-future")) {
            exemptionStatuses.push(
                statuses.filter(status => status?.name === "PENDING-FUTURE")[0]?.id
            );
        }
        const searchVal = nameOrCodeWhileLoading !== "" ? nameOrCodeWhileLoading : nameOrCode;
        const apiResponse = await axios.post(
            buildApiV3Url(`certificates/vendor-search`),
            {
                pageSize,
                pageNumber,
                orderBy: sortColumn[0],
                orderByDirection: sortColumn[1] ? "DESC" : "ASC",
                nameOrCode: fixSearchStringForPostgres(searchVal),
                purchaseOrderNumber: certificateFiltersState.invoiceNumber,
                isPrimary: certificateFiltersState.isCertificateActive,
                isBillTo: certificateFiltersState.isBillToRelation,
                isShipTo: certificateFiltersState.isShipToRelation,
                certificateImageExists: certificateFiltersState.isCertificateImage,
                customerCreatedFrom: certificateFiltersState.customerCreatedDateAfter
                    ? certificateFiltersState.customerCreatedDateAfter
                    : null,
                customerCreatedTo: certificateFiltersState.customerCreatedDateBefore
                    ? certificateFiltersState.customerCreatedDateBefore
                    : null,
                customerModifiedFrom: certificateFiltersState.customerModifiedDateAfter
                    ? certificateFiltersState.customerModifiedDateAfter
                    : null,
                customerModifiedTo: certificateFiltersState.customerModifiedDateBefore
                    ? certificateFiltersState.customerModifiedDateBefore
                    : null,
                certificateCreatedFrom: certificateFiltersState.certificateCreatedDateAfter
                    ? certificateFiltersState.certificateCreatedDateAfter
                    : null,
                certificateCreatedTo: certificateFiltersState.certificateCreatedDateBefore
                    ? certificateFiltersState.certificateCreatedDateBefore
                    : null,
                certificateModifiedFrom: certificateFiltersState.certificateModifiedDateAfter
                    ? certificateFiltersState.certificateModifiedDateAfter
                    : null,
                certificateModifiedTo: certificateFiltersState.certificateModifiedDateBefore
                    ? certificateFiltersState.certificateModifiedDateBefore
                    : null,
                certificateEffectiveFrom: certificateFiltersState.certificateEffectiveDateAfter
                    ? certificateFiltersState.certificateEffectiveDateAfter
                    : null,
                certificateEffectiveTo: certificateFiltersState.certificateEffectiveDateBefore
                    ? certificateFiltersState.certificateEffectiveDateBefore
                    : null,
                certificateExpirationFrom: certificateFiltersState.certificateExpirationDateAfter
                    ? certificateFiltersState.certificateExpirationDateAfter
                    : null,
                certificateExpirationTo: certificateFiltersState.certificateExpirationDateBefore
                    ? certificateFiltersState.certificateExpirationDateBefore
                    : null,
                status: certificateFiltersState.certificateStatus,
                exemptionStatus: exemptionStatuses,
                exposureZones: certificateFiltersState.certificateRegions,
                exemptionReasons: certificateFiltersState.exemptReasons,
                customerRegions: certificateFiltersState.customerRegions,
                certificateIds: certificateFiltersState.certificateIds?.length
                    ? certificateFiltersState.certificateIds
                          .split("\n")
                          .map(value => fixSearchStringForPostgres(value.trim()))
                          .map(Number)
                    : [],
                customerCodes: certificateFiltersState.customerCodes?.length
                    ? certificateFiltersState.customerCodes
                          .split("\n")
                          .map(value => fixSearchStringForPostgres(value.trim()))
                    : [],
                certificateCustomFields: certificateFiltersState.certificateCustomFields,
            },
            { withCredentials: true }
        );
        let response;
        if (!apiResponse.data) {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull(pageSize));
        } else {
            dispatch(setNoDataType(""));
            response = transformExemptionData(apiResponse, false, false, [
                "expectedTaxCodeName",
                "taxCodeName",
            ]);
            const paginateData = paginateDetails
                ? {
                      pageNumber,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: pageSize,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }
        if (nameOrCodeWhileLoading !== "") {
            await dispatch(
                setFormState({
                    ...formState,
                    nameOrCodeWhileLoading: "",
                })
            );
        }
        dispatch(setLoading(false));
    };

export const fetchPendingCertificatesSearchAPI =
    (pageSize, pageNumber = 0, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const {
            isloading,
            pendingFormState,
            pendingCertificateFiltersState,
            sortColumn,
            certificateStatuses,
        } = getState().certificate;
        const { rowsPerPage } = getState().session;
        pageSize = !pageSize ? rowsPerPage : pageSize;
        if (isloading) {
            return;
        }
        dispatch(setLoading(true));
        let statuses = certificateStatuses;
        let updatedCertificateCreatedTo = "";
        if (pendingCertificateFiltersState?.certificateCreatedTo) {
            const cloneDate = new Date(pendingCertificateFiltersState.certificateCreatedTo);
            cloneDate.setDate(cloneDate.getDate() + 1);
            updatedCertificateCreatedTo = getFormattedDate(cloneDate, true);
        }
        const exemptionStatuses = [];
        if (localStorage.getItem("certificateStatuses")) {
            statuses = JSON.parse(localStorage.getItem("certificateStatuses"));
        }
        if (statuses && statuses.length > 0) {
            const exemptionStatus = statuses?.filter(status =>
                PendingStatuses.includes(status?.name)
            );
            exemptionStatus?.forEach(ele => {
                exemptionStatuses.push(ele?.id);
            });
        }
        const { nameOrCodeOrFileOrId } = pendingFormState;
        const requestBody = {
            pageSize,
            pageNumber,
            orderBy: sortColumn[0],
            orderByDirection: sortColumn[1] ? "ASC" : "DESC",
            exemptionStatus: exemptionStatuses,
            nameOrCode: nameOrCodeOrFileOrId
                ? fixSearchStringForPostgres(nameOrCodeOrFileOrId)
                : null,
            exposureZones: pendingCertificateFiltersState.exposureZone,
            certificateCreatedFrom: pendingCertificateFiltersState.certificateCreatedFrom
                ? pendingCertificateFiltersState.certificateCreatedFrom
                : null,
            certificateCreatedTo: updatedCertificateCreatedTo || null,
            assignedTo: pendingCertificateFiltersState.assignedTo || 0,
            escalated: pendingCertificateFiltersState.escalated || 0,
            avsStateFilter: BackgroundJobState.Completed,
            certificateImageExists: pendingCertificateFiltersState.isCertificateImage || 0,
            source: pendingCertificateFiltersState.source,
            exemptionReasons: pendingCertificateFiltersState.exemptReasons,
        };

        axios
            .post(buildApiV3Url(`certificates/search`), requestBody, {
                withCredentials: true,
            })
            .then(async apiResponse => {
                let response;
                if (!apiResponse.data) {
                    onLanding
                        ? dispatch(setNoDataType("onLanding"))
                        : dispatch(setNoDataType("onSearch"));
                    dispatch(setPageToNull());
                } else {
                    response = transformExemptionData(apiResponse, false, false, [
                        "expectedTaxCodeName",
                        "taxCodeName",
                    ]);
                    dispatch(setNoDataType(""));
                    const { page, isCertificateCountAPICompleted } = getState().certificate;
                    const pageData = { ...page.paginate };
                    const paginateData = paginateDetails
                        ? {
                              pageNumber,
                              startIndex: paginateDetails.startIndex,
                              endIndex: paginateDetails.endIndex,
                              rowsPerPage: paginateDetails.rowsPerPage,
                              totalRecords: pageData.totalRecords,
                              indeterminate: false,
                          }
                        : {
                              pageNumber,
                              startIndex: 0,
                              endIndex: 19,
                              rowsPerPage: pageSize,
                              totalRecords:
                                  isCertificateCountAPICompleted === 0 ? pageData.totalRecords : 20,
                              indeterminate: !(isCertificateCountAPICompleted === 0),
                          };
                    dispatch(
                        setPage({
                            data: response.data.value,
                            paginate: paginateData,
                        })
                    );
                }
                dispatch(setLoading(false));
            });

        if (!paginateDetails) {
            dispatch(setIsLoadingCertificateCount(true));
            dispatch(incrementCertificateCountAPICompleted());
            axios
                .post(buildApiV3Url(`certificates/search-count`), requestBody, {
                    withCredentials: true,
                })
                .then(async res => {
                    dispatch(decrementCertificateCountAPICompleted());
                    const { page } = getState().certificate;
                    const pageData = { ...page.paginate };
                    pageData.totalRecords = res?.data;
                    pageData.indeterminate = false;
                    dispatch(
                        setPage({
                            data: page.data,
                            paginate: pageData,
                        })
                    );
                    dispatch(setIsLoadingCertificateCount(false));
                });
        }
    };
export const fetchValidationCertificateByIdAPI = certificateID => async (dispatch, getState) => {
    const { isloading } = getState().certificate;
    if (isloading) {
        return;
    }
    dispatch(setLoading(true));
    const response = await axios.get(buildApiV3Url(`certificates/${certificateID}/validation`), {
        withCredentials: true,
    });
    if (!parseSuccessResponse(CRUDOperation.Read, response)?.recordFound) {
        dispatch(setCertificate(null));
        dispatch(setCertPresent(false));
    } else {
        const exemptionData = response.data;
        const transformedData = transformExemptionData(exemptionData, true, false);
        if (transformedData.customers && transformedData.customers.length > 0) {
            transformedData.customers = convertFieldToString(transformedData.customers, null, [
                "country",
                "state",
            ]);
        }
        dispatch(setCertificate(transformedData));
        dispatch(setCertPresent(true));
        const list = response?.data?.multiCertificates?.map(region => ({
            certificateId: region.certificateId,
            exposureZone: region.exposureZoneName,
        }));
        list?.length > 0 && dispatch(setIsMulti(true));
        dispatch(setMultiCertChildList(list));
    }

    dispatch(setLoading(false));
};

const badFileNames = ["document_file_does_not_exist", "N/A", "n/a"];

export const getCertificateAsync = certificateID => async (dispatch, getState) => {
    const { certificate } = getState();
    let taxCode = "";
    let exposureZone = "";
    if (certificate.isLoadingCustomerCert) {
        return;
    }

    dispatch(setLoadingCustomerCert(true));
    const queryParam = `${certificateID}?$include=customers, poNumbers, invalidReasons`;
    const response = await axios.get(buildApiV2Point5Url(`certificates/${queryParam}`), {
        withCredentials: true,
    });

    if (badFileNames.includes(response.data.fileName)) {
        dispatch(setDownloadableFile(false));
    } else {
        dispatch(setDownloadableFile(true));
    }

    if (!badFileNames.includes(response.data.fileName) && !response.data.documentExists) {
        dispatch(setHasFileUnderValidation(true));
    }

    if (response.data.actualTaxCode) {
        taxCode = response.data.actualTaxCode.name;
    } else if (response.data.status) {
        taxCode = response.data.status.name;
    }

    if (response.data.exposureZone) {
        exposureZone = response.data.exposureZone;
    }

    // TODO: move out
    dispatch(
        setCertificateFormState({
            exposureZone: exposureZone.name,
            exposureCountry: exposureZone && exposureZone.country && exposureZone.country.name,
            taxCode,
            valid: response.data.valid,
            expirationDate: response.data.expirationDate,
            isSingle: response.data.isSingle,
            poNumberValue: response.data.poNumbers[0] && response.data.poNumbers[0].poNumber,
            poNumberId: response.data.poNumbers[0] && response.data.poNumbers[0].id,
        })
    );
    dispatch(setCertificate(response.data));
    dispatch(setLoadingCustomerCert(false));
};

export const postCertificatePONumber = (certificateID, PONumber) => async () => {
    const postData = [{ poNumber: PONumber }];
    await axios.post(buildApiV2Point5Url(`certificates/${certificateID}/po-numbers`), postData, {
        withCredentials: true,
    });
};

export const addFaxEmailAddress = (postData, showToast) => async () => {
    const response = await axios.post(buildApiV3Url(`fax-emails`), postData, {
        withCredentials: true,
    });
    if (response.status === 200) {
        if (response.data.success === true) {
            showToast("success", "Client Fax Email has been added");
        } else {
            showToast("error", response?.data?.message);
        }
    } else {
        showToast("error", response?.response?.data?.[0]?.message);
    }
};

export const putCertificatePONumber = (certificate, poNumberId, poNumberValue) => async () => {
    const putData = [{ id: poNumberId, poNumber: poNumberValue }];

    await axios.put(buildApiV2Point5Url(`certificates/${certificate.id}/po-numbers`), putData, {
        withCredentials: true,
    });
};

export const putCertificateAsync = (certificateID, certificateData) => async () => {
    await axios.put(buildApiV2Point5Url(`certificates/${certificateID}`), certificateData, {
        withCredentials: true,
    });
};

async function refreshGrid(getState, dispatch) {
    // Refresh the certificates grid (Upon upload and delete success) this will help to view the updated ellipses menu
    const { isCertificateGridEllipsesMenu, page } = getState().certificate;

    // When the request is from certificate search page
    if (isCertificateGridEllipsesMenu) {
        await dispatch(
            fetchCertificatesSearchAPI(
                false,
                page.paginate,
                page.paginate.rowsPerPage,
                page.paginate.pageNumber
            )
        );
    }

    // When the request is from the customer details - Certificate grid (Customer's certificate grid)
    if (getState().customerCertificate?.isRequestFromCustomersCertificateGrid) {
        const { paginate } = getState().customerCertificate.page;
        dispatch(
            fetchCustomerExemptionsDetails(paginate.rowsPerPage, paginate.pageNumber, paginate)
        );
    }

    dispatch(setIsRequestFromCustomersCertificateGrid(false));
    dispatch(setIsCertificateGridEllipsesMenu(false));
}

export const singleDeleteCertificatesAsync =
    (certificateID, showToast, showMultipleCertDeleteMessage, afterDeleteCallback) =>
    async (dispatch, getState) => {
        dispatch(setDeletingCert(true));
        if (certificateID) {
            const response = await axios.delete(buildApiV3Url(`certificates/${certificateID}`), {
                withCredentials: true,
            });
            const toastString = `Your ${
                showMultipleCertDeleteMessage ? "certificates" : "certificate"
            } has been deleted.`;
            if (response.status === 200) {
                showToast("success", toastString);
                refreshGrid(getState, dispatch);
            }
        }
        dispatch(setDeletingCert(false));
        if (afterDeleteCallback) afterDeleteCallback();
    };

export const putCertificateAssignmentAsync =
    (certIds, assignToMeFlag, showToast) => async (dispatch, getState) => {
        if (getState().certificate.isLoadingAssignment) return;
        dispatch(setIsLoadingCertificateAssignment(true));
        const assignmentJson = {
            assignUserId: assignToMeFlag ? getState().session.userId : null,
            assignUserName: assignToMeFlag ? getState().session.username : null,
            certificatesId: certIds,
        };
        const response = await axios.put(
            buildApiV3Url(`certificates/certificates-assignment`),
            assignmentJson,
            { withCredentials: true }
        );
        if (response?.status === 200) {
            if (certIds.length > 1) {
                if (assignToMeFlag)
                    showToast("success", `${certIds.length} certificates are now assigned to you`);
                else showToast("success", `You released ${certIds.length} certificates`);
            } else if (assignToMeFlag)
                showToast("success", "The certificate is now assigned to you.");
            else showToast("success", "You released the certificate.");
        }
        dispatch(setIsLoadingCertificateAssignment(false));
    };
export const putCertificateEscalationAsync =
    (certificateId, escalationNote) => async (dispatch, getState) => {
        if (getState().certificate.isLoading) return;
        dispatch(setIsLoadingEscalationLog(true));
        const currentDate = new Date();
        const escalationJson = {
            escalationDate: currentDate,
            escalationLog: escalationNote,
            certificateId,
        };
        const response = await axios.put(
            buildApiV3Url(`certificates/certificates-escalation`),
            escalationJson,
            { withCredentials: true }
        );
        if (response?.status === 200) {
            dispatch(setEscalationLog(response.data));
        }
        dispatch(setIsLoadingEscalationLog(false));
    };

export const deleteCertificateFile = certificateID => async (dispatch, getState) => {
    dispatch(setDeletingCertFile(true));
    const response = await axios.delete(
        buildApiV3Url(`certificates/${certificateID}/certificate-file`),
        { withCredentials: true }
    );
    dispatch(setDeletingCertFile(false));
    if (response?.data?.success) {
        dispatch(setDownloadableFile(false));
        refreshGrid(getState, dispatch);
        return true;
    }

    dispatch(setDownloadableFile(true));
    return false;
};

export const getCertificateFile = (certificateID, showToast) => async () => {
    await axios
        .get(buildApiV3Url(`certificates/${certificateID}/certificate-file`), {
            withCredentials: true,
            responseType: "blob",
        })
        .then(response => {
            if (response.headers["content-type"].includes("application/json")) {
                showToast("error", "Failed to download certificate file. Please try again later");
                return;
            }
            const blob = new Blob([response.data], { type: response.data.type });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", certificateID);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
};

export const postCertificateAsync = certificateData => async dispatch => {
    dispatch(setPostingCert(true));
    const response = await axios.post(buildApiV2Point5Url(`certificates`), certificateData, {
        withCredentials: true,
    });

    dispatch(setCertificate(response.data));
    dispatch(setPostingCert(false));
};

export const getValidationFile = certificateID => async (dispatch, getState) => {
    if (getState().certificate.isLoadingValidationData) return;
    dispatch(setIsLoadingValidationData(true));
    const response = await axios.get(
        buildApiV3Url(`certificates/${certificateID}/certificate-file`),
        {
            responseType: "arraybuffer",
            withCredentials: true,
        }
    );
    if (response.headers["content-type"].includes("application/json")) {
        const buffer = JSON.parse(Buffer.from(response.data));
        dispatch(setHasCertificateFile(false));
        dispatch(
            setValidationData({
                fileType: `${response.headers["content-type"]}`,
                data: buffer,
                fileName: "",
            })
        );
        if (buffer.success) dispatch(setHasFileUnderValidation(true));
        else dispatch(setHasFileUnderValidation(false));
    } else {
        dispatch(setHasCertificateFile(true));
        dispatch(setHasFileUnderValidation(false));
        let fileName = "";
        if (response.headers["content-disposition"]?.split("=").length > 1)
            fileName = response.headers["content-disposition"]?.split("=")[1].replace(/"/g, "");
        if (response && response.data) {
            const buffer = Buffer.from(response.data, "binary").toString("base64");
            dispatch(
                setValidationData({
                    fileType: `data:${response.headers["content-type"]};base64`,
                    data: `data:${response.headers["content-type"]};base64,${buffer}`,
                    fileName,
                })
            );
        }
    }

    dispatch(setIsLoadingValidationData(false));
};

export const uploadFileAsync =
    (certificateID, fileData, callGetAPI, showToast) => async (dispatch, getState) => {
        dispatch(setUploadingFile(true));
        dispatch(clearValidationData());
        // url when rotation is integrated: `//${ecmApiHost}/api/${apiVersion3}/certificates/${certificateID}/certificate-file?rotation=${rotation}`
        await axios
            .post(buildApiV3Url(`certificates/${certificateID}/certificate-file`), fileData, {
                withCredentials: true,
            })
            .then(async res => {
                dispatch(setDownloadableFile(true));
                if (showToast && res.status === 200)
                    showToast("success", "Certificate image added.");
                if (callGetAPI) {
                    const fileObj = fileData.get("file");
                    const base64Result = await toBase64(fileObj);
                    const [fileType] = base64Result.split(","); // eslint-disable-line no-unused-vars
                    const fileName = fileObj.name ? fileObj.name : "";
                    dispatch(setHasCertificateFile(true));
                    dispatch(setHasFileUnderValidation(false));
                    dispatch(setValidationData({ fileType, data: base64Result, fileName }));
                }

                await refreshGrid(getState, dispatch);
            })
            .catch(() => {
                dispatch(setDownloadableFile(false));
            })
            .then(() => {
                dispatch(setUploadingFile(false));
            });
    };

export const replaceFileAsync =
    (certificateID, fileData, callGetAPI, showToast) => async (dispatch, getState) => {
        dispatch(setUploadingFile(true));
        dispatch(clearValidationData());
        await axios
            .put(buildApiV3Url(`certificates/${certificateID}/certificate-file`), fileData, {
                withCredentials: true,
            })
            .then(async res => {
                dispatch(setDownloadableFile(true));
                if (showToast && res.status === 200)
                    showToast("success", "Certificate image added.");
                await refreshGrid(getState, dispatch);
                callGetAPI && dispatch(getValidationFile(certificateID));
            })
            .catch(() => {
                dispatch(setDownloadableFile(false));
            })
            .then(() => {
                dispatch(setUploadingFile(false));
            });
    };

const setAutoValidationResults = (autoValidationResults, avsId, dispatch, certificateUpdated) => {
    let issues = [];
    const predictions = {};
    const { isMulti, isSinglePurchase } = autoValidationResults;
    Object.keys(autoValidationResults).forEach(key => {
        if (autoValidationResults[key] === null) {
            delete autoValidationResults[key];
        } else if (key === "taxIds") {
            autoValidationResults?.taxIds.forEach(taxId => {
                if (taxId.isError) {
                    issues = [...issues, taxId.reasonPhrase];
                } else if (!certificateUpdated) {
                    if (!("taxIds" in predictions)) predictions.taxIds = [];
                    predictions.taxIds.push(taxId);
                }
            });
        } else if (autoValidationResults[key].isError) {
            issues = [
                ...issues,
                autoValidationResults[key].reasonPhrase
                    ? autoValidationResults[key].reasonPhrase
                    : `${key} is missing`,
            ];
        } else if (!certificateUpdated) {
            predictions[key] = autoValidationResults[key].value;
        }
    });

    dispatch(setIsAutoValidatingFile(false));
    dispatch(
        setAutoValidationData({
            avsId,
            status: "success",
            issues,
            predictions,
            isMulti,
            isSinglePurchase,
        })
    );
};

export const clearAutoValidation = () => async dispatch => {
    dispatch(setIsAutoValidatingFile(false));
    clearTimeout(autoValidationTimeout);
    dispatch(setAutoValidationData({ status: "", issues: [], predictions: {} }));
};

export const autoValidateFileAsync = fileData => async (dispatch, getState) => {
    if (getState().certificate.isAutoValidatingFile) return;
    dispatch(setIsAutoValidatingFile(true));
    let retryCount = 7;

    await axios.post(buildApiV3Url(`certificates/auto-validate-file`), fileData, {
        withCredentials: true,
    });

    autoValidationTimeout = setTimeout(() => {
        poll(
            () =>
                axios
                    .get(
                        buildApiV3Url(
                            `certificates/auto-validation-result/${fileData.get("avsId")}`
                        ),
                        { withCredentials: true }
                    )
                    .then(response => {
                        if (response.data) {
                            if (response.data.processStatus !== "COMPLETED") {
                                if (response.data.processStatus === "ERROR" || retryCount === 0) {
                                    dispatch(setIsAutoValidatingFile(false));
                                    dispatch(
                                        setAutoValidationData({
                                            status: "error",
                                            issues: [],
                                            predictions: {},
                                        })
                                    );
                                } else {
                                    retryCount -= 1;
                                    throw new Error("Auto-validation not complete. Retrying...");
                                }
                            } else if (response.data.processStatus === "COMPLETED") {
                                dispatch(setIsAutoValidatingFile(false));
                                setAutoValidationResults(
                                    response.data.validationResults.ruleProcessingResult
                                        .requiredCheck,
                                    fileData.get("avsId"),
                                    dispatch,
                                    getState().certificate.isCertificateUpdated
                                );
                            }
                        }
                    }),
            7,
            3500
        );
    }, 5000);
};

export const getInvalidReasons = () => async (dispatch, getState) => {
    const { certificate, isLoadingInvalidReasons } = getState();
    if (isLoadingInvalidReasons || certificate.invalidReasons.length > 0) {
        return false;
    }
    if (localStorage.getItem("invalidReasons")) {
        return false;
    }
    dispatch(setLoadingInvalidReasons(true));

    const apiResponse = await axios.get(
        buildApiV3Url(`invalid-reasons?$orderBy=name ASC&$top=100`),
        {
            withCredentials: true,
        }
    );
    if (parseSuccessResponse(CRUDOperation.ReadAll, apiResponse)?.recordFound) {
        const responseResult = parseGetAllObjResponse(apiResponse);
        const transformedResponse = responseResult.value.map(reason => {
            const ele = reason;
            ele.id = reason.id.toString();
            return ele;
        });
        dispatch(setInvalidReasons(transformedResponse));
        localStorage.setItem("invalidReasons", JSON.stringify(transformedResponse));
    }

    dispatch(setLoadingInvalidReasons(false));
    return true;
};
export const getLabels = () => async (dispatch, getState) => {
    const { certificate, isLoadingLabels } = getState();
    if (isLoadingLabels || certificate.labels.length > 0) {
        return false;
    }
    if (localStorage.getItem("labels")) {
        return false;
    }
    dispatch(setLoadingLabels(true));
    const response = await axios.get(
        buildApiV3Url(`certificate-labels/bulkupdate-certificatelabels?$orderBy=name ASC&$top=100`),
        { withCredentials: true }
    );
    if (response?.data?.value) {
        const transformedLabels = convertFieldToString(response.data.value, "id");
        dispatch(setLabels(transformedLabels));
        localStorage.setItem("labels", JSON.stringify(transformedLabels));
    }
    dispatch(setLoadingLabels(false));
    return true;
};

export const getSupplementalFiles = certificateId => async (dispatch, getState) => {
    const { certificate } = getState();
    if (certificate.isLoadingSupplementalFiles) {
        return false;
    }
    if (Number.isNaN(certificateId) || certificateId === 0) {
        dispatch(setSupplementalFiles(certificate.supplementalFiles));
    } else {
        dispatch(setLoadingSupplementalFiles(true));
        const response = await axios.get(
            buildApiV3UrlWithQuery(
                `certificates/${certificateId}/certificate-comments?commentType=1&$top=50&$orderBy=id desc`
            ),
            { withCredentials: true }
        );
        if (response && response.data && response.data.value) {
            dispatch(setSupplementalFiles(response.data.value));
        }
        dispatch(setLoadingSupplementalFiles(false));
    }
    return true;
};

export const attachSupplementalFile = (certificateID, fileData) => async (dispatch, getState) => {
    const { certificate, session } = getState();
    if (Number.isNaN(certificateID) || certificateID === 0) {
        dispatch(
            setSupplementalFiles([
                ...certificate.supplementalFiles,
                {
                    ...fileData,
                    id: certificate.supplementalFiles.length + 1,
                    entry: fileData.FileName,
                    accountName: session.username,
                    created: new Date(),
                },
            ])
        );
    } else {
        dispatch(setIsAttachingFile(true));
        await axios.post(
            buildApiV3Url(`certificates/${certificateID}/certificate-comments/file`),
            fileData,
            { withCredentials: true }
        );
        dispatch(setIsAttachingFile(false));
        dispatch(getSupplementalFiles(certificateID));
    }
};

export const deleteSupplementalFile =
    (certificateID, fileId, showToast) => async (dispatch, getState) => {
        const { certificate } = getState();
        if (Number.isNaN(certificateID) || certificateID === 0) {
            dispatch(
                setSupplementalFiles(
                    [...certificate.supplementalFiles].filter(item => item.id !== fileId)
                )
            );
        } else {
            const response = await axios.delete(
                buildApiV3Url(`certificates/${certificateID}/certificate-comments/${fileId}/file`),
                { withCredentials: true }
            );
            if (response && response.status === 200) {
                showToast("success", "Attachment deleted successfully.");
            }
        }
        dispatch(getSupplementalFiles(certificateID));
    };

export const getCertificateCommentsAsync = certificateId => async (dispatch, getState) => {
    const { certificate } = getState();
    if (certificate.isLoadingComments) {
        return false;
    }
    if (Number.isNaN(certificateId) || certificateId === 0) {
        dispatch(setComments(certificate.comments));
    } else {
        dispatch(setLoadingComments(true));
        const response = await axios.get(
            buildApiV3Url(
                `certificates/${certificateId}/certificate-comments?commentType=0&$top=50`
            ),
            { withCredentials: true }
        );
        if (response && response.data && response.data.value)
            dispatch(setComments(response.data.value));
        else dispatch(setComments([]));
        dispatch(setLoadingComments(false));
    }
    return true;
};

export const attachCommentsAsync = (certificateID, commentText) => async (dispatch, getState) => {
    dispatch(setIsAttachingComment(true));
    if (Number.isNaN(certificateID) || certificateID === 0) {
        const { certificate, session } = getState();
        dispatch(
            setComments([
                ...certificate.comments,
                {
                    id: certificate.comments.length + 1,
                    aiUserName: session.username,
                    accountName: session.accountName,
                    entry: commentText,
                    created: new Date(),
                },
            ])
        );
    } else {
        await axios.post(
            buildApiV3Url(`certificates/${certificateID}/certificate-comments`),
            { entry: commentText },
            { withCredentials: true }
        );
        dispatch(getCertificateCommentsAsync(certificateID));
    }
    dispatch(setIsAttachingComment(false));
};

export const deleteCertificateCommentAsync =
    (certificateID, commentId) => async (dispatch, getState) => {
        const { certificate } = getState();
        if (Number.isNaN(certificateID) || certificateID === 0) {
            dispatch(setComments([...certificate.comments].filter(item => item.id !== commentId)));
        } else {
            dispatch(setDeletingComment(true));
            await axios.delete(
                buildApiV3Url(`certificates/${certificateID}/certificate-comments/${commentId}`),
                { withCredentials: true }
            );
            dispatch(setDeletingComment(false));
        }
        dispatch(getCertificateCommentsAsync(certificateID));
    };

export const postCertificateInvalidReasons = async (id, inValidReasonsData) => {
    inValidReasonsData = inValidReasonsData.map(reason => ({
        invalidReasonId: reason,
    }));

    await axios.post(buildApiV3Url(`certificates/${id}/invalid-reasons`), inValidReasonsData, {
        withCredentials: true,
    });
};

export const getAutoValidationResults = avsId => async (dispatch, getState) => {
    if (getState().certificate.isAutoValidatingFile) return;
    dispatch(setIsAutoValidatingFile(true));

    axios
        .get(buildApiV3Url(`certificates/auto-validation-result/${avsId}`), {
            withCredentials: true,
        })
        .then(response => {
            if (response.data) {
                if (response.data.processStatus === "COMPLETED") {
                    setAutoValidationResults(
                        response.data.validationResults.ruleProcessingResult.requiredCheck,
                        avsId,
                        dispatch,
                        getState().certificate.isCertificateUpdated
                    );
                } else {
                    dispatch(
                        setAutoValidationData({
                            status: "error",
                            issues: [],
                            predictions: {},
                        })
                    );
                }
            }
            dispatch(setIsAutoValidatingFile(false));
        });
};

export const fetchMultiCertificateSameAs = certificateID => async (dispatch, getState) => {
    const { isLoadingMulti } = getState().certificate;
    if (isLoadingMulti) {
        return "0";
    }
    dispatch(setIsLoadingMulti(true));
    let parentId = "0";
    const response = await axios.get(
        buildApiV3Url(`certificates/${certificateID}/multi-certificate-same-as`),
        { withCredentials: true }
    );
    if (response?.data?.childCertificateIds?.length) {
        dispatch(setIsMulti(true));
        parentId = `${response?.data?.parentCertificateId}`;
    } else {
        parentId = certificateID;
        dispatch(setIsMulti(false));
    }
    dispatch(setIsLoadingMulti(false));
    return parentId;
};

export const getTaxCodeValidation = id => async dispatch => {
    const response = await axios.get(buildApiV3Url(`certificates/taxcode-map?taxCodeId=${id}`), {
        withCredentials: true,
    });
    dispatch(setIsTaxcodeMap(true));
    if (response?.data) {
        dispatch(setIsRegionMessage(response?.data?.message));
        dispatch(setIsRegionValid(response?.data?.success));
    }
    return response?.data;
};

function certificateFiltersData(selectedColumns, getState, pageSize = 20, pageNumber = 0) {
    const { userId, accountId, role } = getState().session;
    const { formState, certificateFiltersState, sortColumn, certificateStatuses } =
        getState().certificate;
    const { nameOrCode, nameOrCodeWhileLoading } = formState;
    const searchVal = nameOrCodeWhileLoading !== "" ? nameOrCodeWhileLoading : nameOrCode;
    let statuses = certificateStatuses;
    const exemptionStatuses = [];

    if (localStorage.getItem("certificateStatuses")) {
        statuses = JSON.parse(localStorage.getItem("certificateStatuses"));
    }
    if (
        certificateFiltersState?.certificateStatus?.includes("Valid") ||
        certificateFiltersState?.certificateStatus?.includes("Invalid") ||
        certificateFiltersState?.certificateStatus?.includes("Expired") ||
        certificateFiltersState?.certificateStatus?.includes("Paused")
    ) {
        exemptionStatuses.push(statuses.filter(status => status.name === "COMPLETE")[0]?.id);
        exemptionStatuses.push(statuses.filter(status => status.name === "PENDING-FUTURE")[0]?.id);
    } else if (certificateFiltersState?.certificateStatus?.includes("Pending-future")) {
        exemptionStatuses.push(statuses.filter(status => status.name === "PENDING-FUTURE")[0]?.id);
    }
    const filterJsonObject = {
        pageSize,
        pageNumber,
        selectedColumns,
        orderBy: sortColumn[0],
        orderByDirection: sortColumn[1] ? "ASC" : "DESC",
        nameOrCode: fixSearchStringForPostgres(searchVal),
        purchaseOrderNumber: certificateFiltersState.invoiceNumber,
        isPrimary: certificateFiltersState.isCertificateActive,
        isBillTo: certificateFiltersState.isBillToRelation,
        isShipTo: certificateFiltersState.isShipToRelation,
        isDuplicate: certificateFiltersState.isDuplicateRelation,
        isSingle: certificateFiltersState.isPurchaseOrder,
        certificateImageExists: certificateFiltersState.isCertificateImage,
        customerCreatedFrom: certificateFiltersState.customerCreatedDateAfter
            ? certificateFiltersState.customerCreatedDateAfter
            : null,
        customerCreatedTo: certificateFiltersState.customerCreatedDateBefore
            ? certificateFiltersState.customerCreatedDateBefore
            : null,
        customerModifiedFrom: certificateFiltersState.customerModifiedDateAfter
            ? certificateFiltersState.customerModifiedDateAfter
            : null,
        customerModifiedTo: certificateFiltersState.customerModifiedDateBefore
            ? certificateFiltersState.customerModifiedDateBefore
            : null,
        certificateCreatedFrom: certificateFiltersState.certificateCreatedDateAfter
            ? certificateFiltersState.certificateCreatedDateAfter
            : null,
        certificateCreatedTo: certificateFiltersState.certificateCreatedDateBefore
            ? certificateFiltersState.certificateCreatedDateBefore
            : null,
        certificateModifiedFrom: certificateFiltersState.certificateModifiedDateAfter
            ? certificateFiltersState.certificateModifiedDateAfter
            : null,
        certificateModifiedTo: certificateFiltersState.certificateModifiedDateBefore
            ? certificateFiltersState.certificateModifiedDateBefore
            : null,
        certificateEffectiveFrom: certificateFiltersState.certificateEffectiveDateAfter
            ? certificateFiltersState.certificateEffectiveDateAfter
            : null,
        certificateEffectiveTo: certificateFiltersState.certificateEffectiveDateBefore
            ? certificateFiltersState.certificateEffectiveDateBefore
            : null,
        certificateExpirationFrom: certificateFiltersState.certificateExpirationDateAfter
            ? certificateFiltersState.certificateExpirationDateAfter
            : null,
        certificateExpirationTo: certificateFiltersState.certificateExpirationDateBefore
            ? certificateFiltersState.certificateExpirationDateBefore
            : null,
        status: certificateFiltersState.certificateStatus,
        exemptionStatus: exemptionStatuses,
        exposureZones: certificateFiltersState.certificateRegions,
        exemptionReasons: certificateFiltersState.exemptReasons,
        customerRegions: certificateFiltersState.customerRegions,
        certificateHasCustomers: certificateFiltersState.certificateHasCustomers,
        activeCertificate: certificateFiltersState.isCertActive,
        certificateIds: certificateFiltersState.certificateIds?.length
            ? certificateFiltersState.certificateIds
                  .split("\n")
                  .map(value => fixSearchStringForPostgres(value.trim()))
                  .map(Number)
            : [],
        customerCodes: certificateFiltersState.customerCodes?.length
            ? certificateFiltersState.customerCodes
                  .split("\n")
                  .map(value => fixSearchStringForPostgres(value.trim()))
            : [],
        avalaraUserId: userId,
        avalaraUserRole: role,
        avaTaxAccountId: accountId,
        certificateCustomFields: certificateFiltersState.certificateCustomFields,
        certificatesLabels: certificateFiltersState.certificatesLabels,
        customersLabels: certificateFiltersState.customersLabels,
        reviewedBy: certificateFiltersState.reviewedBy,
    };
    return filterJsonObject;
}

export const postCertificateExportApi =
    (selectedColumns, title, showToast, hideToast, pageSize = 20, pageNumber = 0) =>
    async (dispatch, getState) => {
        const { isLoadingCertificateExport } = getState().certificate;
        if (isLoadingCertificateExport) {
            return;
        }
        dispatch(setIsLoadingCertificateExport(true));
        const certificateFilterJson = certificateFiltersData(
            selectedColumns,
            getState,
            pageSize,
            pageNumber
        );

        const response = await axios.post(
            buildApiV3Url(`export-certificate?title=${title}`),
            certificateFilterJson,
            { withCredentials: true }
        );
        dispatch(setIsExportSearchDialogOpen(false));
        dispatch(setIsLoadingCertificateExport(false));
        if (response.status === 200) {
            const baseURL = `https://${docLockerClient}`;
            window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
            showToast(
                "success",
                "We are working on your export. When it's ready, find it in",
                "export-success",
                true,
                "your downloads",
                () => {
                    hideToast("export-success");
                    window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
                },
                "The time it takes to export data depends on the size of the file and network traffic."
            );
            return;
        }
        showToast("error", `Something went wrong`);
    };

export const postPendingCertificateExportApi =
    (selectedColumns, title, showToast, hideToast, pageSize = 20, pageNumber = 0) =>
    async (dispatch, getState) => {
        const { isLoadingCertificateExport } = getState().certificate;
        if (isLoadingCertificateExport) {
            return;
        }
        dispatch(setIsLoadingCertificateExport(true));
        const certificateFilterJson = certificatePendingFiltersData(
            selectedColumns,
            getState,
            title,
            pageSize,
            pageNumber
        );
        const response = await axios.post(
            buildApiV3Url(`export-pending-certificates`),
            certificateFilterJson,
            { withCredentials: true }
        );
        dispatch(setIsExportSearchDialogOpen(false));
        dispatch(setIsLoadingCertificateExport(false));
        if (response.status === 200) {
            const baseURL = `https://${docLockerClient}`;
            window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
            showToast(
                "success",
                "We are working on your export. When it's ready, find it in",
                "export-success",
                true,
                "your downloads",
                () => {
                    hideToast("export-success");
                    window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
                },
                "The time it takes to export data depends on the size of the file and network traffic."
            );
            return;
        }
        showToast("error", `Something went wrong`);
    };

function certificatePendingFiltersData(
    selectedColumns,
    getState,
    title,
    pageSize = 20,
    pageNumber = 0
) {
    const { userId, accountId, role } = getState().session;
    const { pendingFormState, pendingCertificateFiltersState, sortColumn, certificateStatuses } =
        getState().certificate;
    let statuses = certificateStatuses;
    const exemptionStatuses = [];
    let updatedCertificateCreatedTo = "";

    if (pendingCertificateFiltersState?.certificateCreatedTo) {
        const cloneDate = new Date(pendingCertificateFiltersState.certificateCreatedTo);
        cloneDate.setDate(cloneDate.getDate() + 1);
        updatedCertificateCreatedTo = getFormattedDate(cloneDate, true);
    }

    if (localStorage.getItem("certificateStatuses")) {
        statuses = JSON.parse(localStorage.getItem("certificateStatuses"));
    }
    if (statuses && statuses.length > 0) {
        const exemptionStatus = statuses?.filter(status => PendingStatuses.includes(status?.name));
        exemptionStatus?.forEach(ele => {
            exemptionStatuses.push(ele?.id);
        });
    }
    const { nameOrCodeOrFileOrId } = pendingFormState;

    const filterJsonObject = {
        pageSize,
        pageNumber,
        selectedColumns,
        title,
        orderBy: sortColumn[0],
        orderByDirection: sortColumn[1] ? "ASC" : "DESC",
        exemptionStatus: exemptionStatuses,
        nameOrCode: nameOrCodeOrFileOrId ? fixSearchStringForPostgres(nameOrCodeOrFileOrId) : null,
        exposureZones: pendingCertificateFiltersState.exposureZone,
        certificateCreatedFrom: pendingCertificateFiltersState.certificateCreatedFrom
            ? pendingCertificateFiltersState.certificateCreatedFrom
            : null,
        certificateCreatedTo: updatedCertificateCreatedTo || null,
        assignedTo: pendingCertificateFiltersState.assignedTo || 0,
        escalated: pendingCertificateFiltersState.escalated || 0,
        avsStateFilter: BackgroundJobState.Completed,
        certificateImageExists: pendingCertificateFiltersState.isCertificateImage || 0,
        source: pendingCertificateFiltersState.source,
        exemptionReasons: pendingCertificateFiltersState.exemptReasons,
        avalaraUserId: userId,
        avalaraUserRole: role,
        avaTaxAccountId: accountId,
    };

    return filterJsonObject;
}
// This API call is used to Pause and to restore paused certificate
// To Pause --> valid : false
// To Resume --> valid : true
export const updateValidationStatusAsync = (certificateID, isValid) => async () => {
    await axios.put(
        buildApiV3Url(`certificates/${certificateID}/update_validation`),
        { paused: isValid },
        { withCredentials: true }
    );
};

export const splitCertificateAsync = (certificateID, postData) => async dispatch => {
    const response = await axios.post(
        buildApiV3Url(`certificates/${certificateID}/split-certificate`),
        postData,
        { withCredentials: true }
    );
    if (response.status === 200) {
        dispatch(setIsSplittingCertificates(false));
        dispatch(setIsSplitCertificateDialogOpen(false));
        return true;
    }
    return false;
};

export const postBulkPrintCertificateApi =
    (selectedColumns, title, showToast, hideToast, pageSize = 20, pageNumber = 0) =>
    async (dispatch, getState) => {
        const { isLoadingCertificateExport } = getState().certificate;
        if (isLoadingCertificateExport) {
            return;
        }
        dispatch(setIsLoadingCertificateExport(true));
        const certificateFilterJson = certificateFiltersData(
            selectedColumns,
            getState,
            pageSize,
            pageNumber
        );
        const response = await axios.post(
            buildApiV3Url(`certificates/bulk-certificate-print?title=${title}`),
            certificateFilterJson,
            { withCredentials: true }
        );
        dispatch(setIsBulkDownloadCertificateDialogOpen(false));
        dispatch(setIsLoadingCertificateExport(false));
        if (response.status === 200) {
            const baseURL = `https://${docLockerClient}`;
            window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
            showToast(
                "success",
                "We are working on your export certificate request. When it's ready, find it in",
                "export-success",
                true,
                "your downloads",
                () => {
                    hideToast("export-success");
                    window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
                },
                "The time it takes to export data depends on the size of the file and network traffic."
            );
            return;
        }
        showToast("error", `Something went wrong`);
    };

export const getCustomFieldsValidationResults = () => (dispatch, getState) => {
    const { certificateAddedCustomFieldValues, certificateExistingCustomFieldValues } =
        getState().certificate;
    const payloadWithCustomFields = {};
    Object.keys(certificateAddedCustomFieldValues).forEach(customFieldId => {
        if (!Array.isArray(certificateAddedCustomFieldValues[customFieldId])) {
            payloadWithCustomFields[customFieldId] = {
                value: certificateAddedCustomFieldValues[customFieldId],
                validations: [fieldValidationTypeCsv],
            };
        }
    });
    Object.keys(certificateExistingCustomFieldValues).forEach(customFieldId => {
        if (!Array.isArray(certificateExistingCustomFieldValues[customFieldId])) {
            payloadWithCustomFields[customFieldId] = {
                value: certificateExistingCustomFieldValues[customFieldId],
                validations: [fieldValidationTypeCsv],
            };
        }
    });
    return fieldsValidation(payloadWithCustomFields);
};

export const getCustomFields = () => async (dispatch, getState) => {
    const { isLoadingCustomFields } = getState().certificate;
    if (isLoadingCustomFields) {
        return false;
    }
    dispatch(setLoadingCustomFields(true));

    const response = await axios.get(buildApiV3Url(`certificate-custom-fields?$top=100`), {
        withCredentials: true,
    });

    if (response && response.data && response.data.value) {
        dispatch(setCustomFields(response.data.value));
    }

    dispatch(setLoadingCustomFields(false));
    return true;
};

export const getCustomFieldFromSlice = () => (dispatch, getState) => {
    const { certificateAddedCustomFieldValues, certificateExistingCustomFieldValues } =
        getState().certificate;
    return { certificateAddedCustomFieldValues, certificateExistingCustomFieldValues };
};

export const getCertificateCustomFields =
    (certificateID, isOutgoing = false) =>
    async (dispatch, getState) => {
        const { isLoadingCertificateCustomFields } = getState();
        const filterString = `documentType.Name eq '${documentTypeNames.salesAndUseTax}' and documentType.Outgoing eq ${isOutgoing}`;

        if (isLoadingCertificateCustomFields) {
            return false;
        }
        let customFields = null;

        dispatch(setLoadingCertificateCustomFields(true));
        const allFieldsResponse = await axios.get(
            buildApiV3Url(`certificate-custom-fields?$filter=${filterString}&$top=100`),
            { withCredentials: true }
        );
        if (allFieldsResponse && allFieldsResponse.data.value) {
            customFields = allFieldsResponse.data.value;
        }

        const response = await axios.get(
            buildApiV3Url(`certificates/${certificateID}/custom-fields?$top=100`),
            { withCredentials: true }
        );

        const manipulatedCustomFields = {};
        if (response?.data?.value) {
            Object.entries(customFields).forEach(([, value], index) => {
                Object.values(response.data.value).forEach(v => {
                    if (v.fieldId === value.id) {
                        customFields[index].value = v.value;
                        customFields[index].id = v.id;
                        customFields[index].fieldId = v.fieldId;
                        customFields[index].exists = true;
                        if (value.type === "multi-select") {
                            manipulatedCustomFields[v.fieldId] = v.value.split("|");
                        } else {
                            manipulatedCustomFields[v.fieldId] = v.value;
                        }
                    }
                });
            });
        }

        if (Object.keys(manipulatedCustomFields).length > 0) {
            dispatch(setCertificateExistingCustomFieldValues(manipulatedCustomFields));
        }
        dispatch(setCertificateCustomFields(customFields));
        dispatch(setLoadingCertificateCustomFields(false));
        return true;
    };

export const fetchAllCertificateLabels = () => async dispatch => {
    const allLabelsResponse = await axios.get(
        buildApiV3Url(`certificate-labels?$orderBy=name ASC&documentType=1&$top=100`),
        { withCredentials: true }
    );
    if (allLabelsResponse && allLabelsResponse.data.value) {
        const transformedCertificateLabels = convertFieldToString(
            allLabelsResponse.data.value,
            "id"
        );
        dispatch(setCertificateLabels(transformedCertificateLabels));
    }
};

export const getCertificateLabels = certificateId => async (dispatch, getState) => {
    const { isLoadingCertificateLabels } = getState().certificate;
    if (isLoadingCertificateLabels) {
        return false;
    }

    dispatch(setLoadingCertificateLabels(true));
    dispatch(fetchAllCertificateLabels());
    if (certificateId) {
        const response = await axios.get(
            buildApiV3Url(`certificates/${certificateId}/certificate-labels`),
            { withCredentials: true }
        );
        if (response?.data?.value) {
            const transformedCertificateLabels = convertFieldToString(response?.data?.value, "id");
            dispatch(setCustomLabels(transformedCertificateLabels));
        }
    }
    dispatch(setLoadingCertificateLabels(false));
    return true;
};

export const getCertificateReviewers = () => async (dispatch, getState) => {
    const { isLoadingCertificateReviewers } = getState().certificate;

    if (isLoadingCertificateReviewers) {
        return false;
    }
    dispatch(setLoadingCertificateReviewers(true));
    const response = await axios.get(buildApiV3Url(`certificates/reviewers`), {
        withCredentials: true,
    });

    if (response?.data) {
        dispatch(setCertificateReviewers(response.data));
    }

    dispatch(setLoadingCertificateReviewers(false));
    return true;
};

export const getCertificateSources = () => async (dispatch, getState) => {
    const { isLoadingCertificateSources } = getState().certificate;

    if (isLoadingCertificateSources) {
        return false;
    }
    dispatch(setLoadingCertificateSources(true));

    const response = await axios.get(buildApiV3Url(`certificate-labels/sources`), {
        withCredentials: true,
    });

    if (response?.data) {
        const transformedCertificateSources = convertFieldToString(response?.data, "id");
        dispatch(setCertificateSources(transformedCertificateSources));
    }

    dispatch(setLoadingCertificateSources(false));
    return true;
};

export const deleteCertificatesAsync =
    (certificateIDs, deleteMultiple, showToast, afterDeleteCallback, useFilterCriteriaForDelete) =>
    async (dispatch, getState) => {
        dispatch(setDeletingCert(true));
        if (!Array.isArray(certificateIDs)) {
            certificateIDs = [certificateIDs];
        }
        certificateIDs = [...new Set(certificateIDs)];

        const certificateFilterJson = useFilterCriteriaForDelete
            ? certificateFiltersData(null, getState, 20, 0)
            : {};
        const { page } = getState().certificate;
        if (certificateIDs?.length > 0 || useFilterCriteriaForDelete) {
            const response = await axios.post(
                buildApiV3Url(`certificates/deletes`),
                {
                    certificateIds: certificateIDs,
                    certificateFilterModel: {
                        ...certificateFilterJson,
                        searchedTotalRecords: page.paginate.totalRecords,
                    },
                },
                { withCredentials: true }
            );
            const toastString = deleteMultiple
                ? "Your bulk certificates delete request has been queued for processing."
                : "Your certificate delete request has been queued for processing.";
            if (response.status === 200) {
                if (Object.keys(response.data).includes("-1")) {
                    showToast("error", response.data["-1"]);
                } else {
                    showToast("success", toastString);
                }
                refreshGrid(getState, dispatch);
            }
        }
        dispatch(setDeletingCert(false));
        if (afterDeleteCallback) afterDeleteCallback();
    };
export default certificateSlice.reducer;
