import React, { useCallback } from "react";
import { SInputExtended } from "@avalara/skylab-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    fetchExemptionReasons,
    setFormState,
    selectFormState,
} from "../../../app/exemptionReasonSlice";

function ExemptionReasonsFilter() {
    const formState = useSelector(selectFormState, shallowEqual);
    const dispatch = useDispatch();
    const { nameOrDescription } = formState;

    const handleSearchBoxSubmit = useCallback(() => {
        dispatch(fetchExemptionReasons(null, 0, null, null));
    }, [dispatch]);

    const handleInput = e => {
        dispatch(
            setFormState({
                ...formState,
                [e.detail.inputId]: e.detail.value.trim(),
            })
        );
    };

    const clearSearchBox = async () => {
        dispatch(
            setFormState({
                ...formState,
                nameOrDescription: "",
            })
        );
        handleSearchBoxSubmit();
    };

    return (
        <React.Fragment>
            <label htmlFor="nameOrDescription">Search by name or description</label>
            <SInputExtended
                inputId="nameOrDescription"
                aria-label="Search by name or description"
                type="search"
                onS-input={e => handleInput(e)}
                onS-search={e => handleSearchBoxSubmit(e)}
                onS-clear={() => clearSearchBox()}
                value={nameOrDescription}
            />
        </React.Fragment>
    );
}
export default ExemptionReasonsFilter;
