import React from "react";
import { SRow, SCol, SDialog, SLoader } from "@avalara/skylab-react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { selectSession } from "../../app/sessionSlice";
import {
    selectCoverLetterPreview,
    selectPreviewLoading,
    clearCoverLetterPreview,
} from "../../app/coverLetterSlice";
import toast from "../../hooks/toast";
import "./requests.css";

function CoverLetterPreviewDialog(props) {
    const dialogName = "coverLetterPreviewDialog";
    const dispatch = useDispatch();
    const session = useSelector(selectSession);
    const coverLetter = useSelector(selectCoverLetterPreview);
    const isLoading = useSelector(selectPreviewLoading);
    const regMsg = /\{\{CUSTOM_MESSAGE\}\}/;
    const regName = /\{\{FROM_BUSINESS_NAME\}\}/gi;
    const [showToast] = toast();

    const onSDismiss = () => {
        props.setIsPreviewDialogOpen(false);
        dispatch(clearCoverLetterPreview());
    };

    const formatCustomEmail = () => {
        let formattedContent = `
        Looks like we can&apos;t pull up a preivew of the image right now. You can still view template details and use the template in requests.`;

        if (coverLetter.htmlContent) {
            try {
                const baseContent = atob(coverLetter.htmlContent);
                formattedContent = baseContent.replaceAll(regName, session.activeCompany.name);
                formattedContent = formattedContent.replaceAll("â", "&apos;");
                formattedContent = props.customMessage
                    ? formattedContent.replace(regMsg, props.customMessage)
                    : formattedContent.replace(regMsg, "");
            } catch {
                showToast("error", "Could not display the cover letter, please try again later.");
            }
        }

        return formattedContent;
    };

    return (
        <SDialog
            id={dialogName}
            open={props.isPreviewDialogOpen}
            noDismiss
            onS-dismiss={onSDismiss}
            aria-modal="true"
            className="coverLetterPreviewDialog">
            <div slot="header" id="coverLetterPreview">
                Template preview
            </div>
            <div slot="body" className="preview-modal-body">
                {isLoading ? (
                    <div className="flex dl-flex-fill-height dl-flex-center">
                        <h3>Loading ...</h3>
                        <SLoader id="page-loader" className="medium" aria-live="polite" loading />
                    </div>
                ) : (
                    <div className="text-gray">{coverLetter ? parse(formatCustomEmail()) : ""}</div>
                )}
            </div>
            <div slot="footer" className="block">
                <SRow>
                    <SCol span="12" className="flex justify-content-end">
                        <button className="primary inline" onClick={() => onSDismiss()}>
                            Close
                        </button>
                    </SCol>
                </SRow>
            </div>
        </SDialog>
    );
}

export default CoverLetterPreviewDialog;
