import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { checkSelectComponentProps } from "../../shared/Utils";
import {
    selectCustomerShipStates,
    getCustomerShipStatesAsync,
    selectIsLoadingCustomerShipStates,
    selectShipStateSelectOptions,
    selectShipStateOptions,
    setShipStateOptions,
} from "../../app/customerSlice";

const SelectCustomerRegion = React.memo(props => {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingCustomerShipStates);
    const [showAllRegions, setShowAllRegions] = useState(false);
    const [userSubmitted, setUserSubmitted] = useState(false);

    const selectRef = React.createRef();

    const customerRegions = useSelector(selectCustomerShipStates, shallowEqual).map(element => {
        const selectOption = {
            label: element.name,
            value: element.id,
            selected: false,
        };

        if (props.value && props.value[0]?.name && typeof props.value === "object") {
            const name = props.value.map(zone => zone.name);
            selectOption.selected = name.includes(element.name);
        } else if (
            props.multiple &&
            (props.value?.includes(element.name) || props.value?.includes(element.id))
        ) {
            selectOption.selected = true;
        }
        if (props.value === element.name) {
            selectOption.selected = true;
        }
        return selectOption;
    });

    const allRegions = useSelector(selectShipStateSelectOptions, shallowEqual).map(element => {
        const selectOption = {
            label: element.name,
            value: element.id,
            selected: false,
        };

        if (props.value && props.value[0]?.name && typeof props.value === "object") {
            const name = props.value.map(zone => zone.name);
            selectOption.selected = name.includes(element.name);
        } else if (
            props.multiple &&
            (props.value?.includes(element.name) || props.value?.includes(element.id))
        ) {
            selectOption.selected = true;
        }
        if (props.value === element.name) {
            selectOption.selected = true;
        }
        return selectOption;
    });

    checkSelectComponentProps("SelectCustomerRegion", props);

    const regionListHandler = itemSel => {
        if (itemSel) {
            setShowAllRegions(false);
        } else {
            setShowAllRegions(true);
        }
        selectRef.current.deselectAll();
    };

    const selectHandler = val => {
        props.onAdd(val);
        setUserSubmitted(true);
    };

    useEffect(() => {
        dispatch(getCustomerShipStatesAsync(props.customerId));
    }, [props.customerId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(selectShipStateOptions());
        return () => {
            dispatch(setShipStateOptions([]));
        };
    }, [dispatch]);

    return (
        <React.Fragment>
            <label htmlFor={getNextId()} id="lbl-customerRegion">
                Regions covered by this certificate
            </label>

            <SSelect
                name="customerRegion"
                inputId={getCurrentId()}
                ref={selectRef}
                async
                selectionOptional
                optionsList={
                    showAllRegions ||
                    (!userSubmitted && props?.value) ||
                    customerRegions.length === 0
                        ? allRegions
                        : customerRegions
                }
                loading={isLoading}
                onS-select={e => selectHandler(e.detail.item)}
                onS-deselect={e => props.onRemove(e.detail.item)}
                onS-close={e => (props.onClose ? props.onClose(e) : null)}
                disabled={props.customerRegionDisabled ? "disabled" : null}
                multiple={props.multiple}>
                <div
                    slot="sticky-footer"
                    className="pad-all-none"
                    hidden={customerRegions.length === 0 || (!userSubmitted && props.value)}>
                    <button
                        className="ghost region-select"
                        onClick={() => regionListHandler(showAllRegions)}>
                        {showAllRegions
                            ? "Show only linked tax regions"
                            : "Show all available tax regions"}
                    </button>
                </div>
            </SSelect>
        </React.Fragment>
    );
});
export default SelectCustomerRegion;
