import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import {
    getCountriesAsync,
    selectCountries,
    selectIsLoadingCountries,
} from "../../app/certificateSlice";
import { errorTag, noErrorTag, updateSelectedAttribute } from "../../shared/Utils";

const SelectCountry = React.memo(props => {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingCountries);
    const countries = useSelector(selectCountries, shallowEqual).map(element => {
        if ((props.value && element.id === props.value) || element.name === props.value) {
            return { label: element.name, value: element.id, selected: true };
        }
        return { label: element.name, value: element.id };
    });

    useEffect(() => {
        dispatch(getCountriesAsync());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function handleChange(event) {
        if (props.onChange) {
            props.onChange(event.detail.item.value, event.detail);
            updateSelectedAttribute(countries, event.detail.item.value);
        }
    }

    function handleRemove(event) {
        if (props.onRemove) {
            props.onRemove(event.detail.item.value);
            updateSelectedAttribute(countries, event.detail.item.value);
        }
    }

    return (
        <React.Fragment>
            {props.required ? (
                <label htmlFor={getNextId()} className="required" id="lbl-country">
                    Country
                </label>
            ) : (
                <label htmlFor={getNextId()} id="lbl-country">
                    Country
                </label>
            )}
            <SSelect
                inputId={getCurrentId()}
                name="country"
                selectionOptional
                className={props.countryError === errorTag ? errorTag : noErrorTag}
                loading={isLoading}
                optionsList={countries}
                onS-select={e => handleChange(e)}
                onS-deselect={e => handleRemove(e)}
            />
        </React.Fragment>
    );
});

export default SelectCountry;
