export default function magnify(imgID, zoom) {
    const img = document.getElementById(imgID);

    /* create magnifier glass: */
    const glass = document.createElement("DIV");
    glass.setAttribute("id", "img-magnifier-glass");

    /* insert magnifier glass: */
    img.parentElement.insertBefore(glass, img);

    /* set background properties for the magnifier glass: */
    glass.style.backgroundImage = `url('${img.src}')`;
    glass.style.backgroundRepeat = "no-repeat";
    glass.style.backgroundSize = `${img.width * zoom}px ${img.height * zoom}px`;
    glass.style.zIndex = 100;
    const bw = 3;
    const glassOffsetWidth = glass.offsetWidth / 2;
    const glassOffsetHeight = glass.offsetHeight / 2;

    /* eslint-disable no-param-reassign */
    function moveMagnifier(event) {
        let cursorXPosition;
        let cursorYPosition;
        /* prevent any other actions that may occur when moving over the image */
        event.preventDefault();
        /* get the cursor's x and y positions: */
        function getCursorPosition(e) {
            let cursorXPos = 0;
            let cursorYPos = 0;
            /* get the x and y positions of the image: */
            const imgPos = img.getBoundingClientRect();
            /* calculate the cursor's x and y coordinates, relative to the image: */
            cursorXPos = e.pageX - imgPos.left;
            cursorYPos = e.pageY - imgPos.top;
            /* consider any page scrolling: */
            cursorXPos -= window.pageXOffset;
            cursorYPos -= window.pageYOffset;
            return { x: cursorXPos, y: cursorYPos };
        }
        const cursorPosition = getCursorPosition(event);
        cursorXPosition = cursorPosition.x;
        cursorYPosition = cursorPosition.y;
        /* prevent the magnifier glass from being positioned outside the image: */
        if (cursorXPosition > img.width - glassOffsetWidth / zoom) {
            cursorXPosition = img.width - glassOffsetWidth / zoom;
        }
        if (cursorXPosition < glassOffsetWidth / zoom) {
            cursorXPosition = glassOffsetWidth / zoom;
        }
        if (cursorYPosition > img.height - glassOffsetHeight / zoom) {
            cursorYPosition = img.height - glassOffsetHeight / zoom;
        }
        if (cursorYPosition < glassOffsetHeight / zoom) {
            cursorYPosition = glassOffsetHeight / zoom;
        }
        /* set the position of the magnifier glass: */
        glass.style.left = `${cursorXPosition - glassOffsetWidth}px`;
        glass.style.top = `${cursorYPosition - glassOffsetHeight}px`;
        /* display what the magnifier glass "sees": */
        glass.style.backgroundPosition = `-${cursorXPosition * zoom - glassOffsetWidth + bw}px -${
            cursorYPosition * zoom - glassOffsetHeight + bw
        }px`;
    }
    /* hided the magnifier initially and will only react to mouse event */
    glass.hidden = true;
    /* execute a function when someone moves the magnifier glass over the image: */
    glass.addEventListener("mousemove", moveMagnifier);
    img.addEventListener("mousemove", moveMagnifier);
    /* and also for touch screens: */
    glass.addEventListener("touchmove", moveMagnifier);
    img.addEventListener("touchmove", moveMagnifier);
}
