import { createSlice } from "@reduxjs/toolkit";

export const exportSearchSlice = createSlice({
    name: "exportSearch",
    initialState: {
        isExportSearchDialogOpen: false,
        isLoading: false,
    },
    reducers: {
        setIsExportSearchDialogOpen: (state, action) => {
            state.isExportSearchDialogOpen = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const { setIsExportSearchDialogOpen, setIsLoading } = exportSearchSlice.actions;

export const selectIsExportSearchDialogOpen = state => {
    return state.exportSearch.isExportSearchDialogOpen;
};

export const selectIsLoading = state => {
    return state.isLoading;
};

export default exportSearchSlice.reducer;
