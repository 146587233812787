/* eslint-disable */
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { SRow, SCol, SIcon, SDatepicker } from "@avalara/skylab-react";
import SelectState from "../../sharedComponents/SelectState";
import SelectOutgoingExposureZone from "../../sharedComponents/SelectOutgoingExposureZone";
import { errorTag, noErrorTag } from "../../../shared/Utils";
import CustomerCustomField from "./CustomerCustomFieldFilter";
import FeatureToggler from "../../../featureToggler/FeatureToggler";

const FilterByVendor = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const {
        isLoading,
        updateFilters,
        handleMultilineInputChange,
        filterState,
        setFilterState,
        applyFilters,
        usedBy,
    } = props;

    const {
        isCustomerDetails,
        customerRegions,
        isMissingcertificates,
        missingExposureZones,
        customerCodes,
        customerCodeError,
        customerCreatedDateAfter,
        customerCreatedDateBefore,
        customerCreatedDateAfterError,
        customerCreatedDateBeforeError,
        customerModifiedDateAfter,
        customerModifiedDateBefore,
        customerModifiedDateAfterError,
        customerModifiedDateBeforeError,
        customerCustomFields,
        alternateId,
    } = filterState;

    const [vendorCodePrevVal, setVendorCodePrevVal] = useState("");
    const [vendorRegionPrevVal, setVendorRegionPrevVal] = useState([]);
    const [missingExposureZonePrevVal, setMissingExposureZonePrevValPrevVal] = useState([]);

    useEffect(() => {
        updateFilters("isVendorFilter", true);
    }, [dispatch]);

    const handleSearch = async (id, value) => {
        dispatch(
            setFilterState({
                [id]: [...filterState[`${id}`], value.value],
            })
        );
    };

    const updateCustomFieldPostData = async (customFieldId, customFieldValue) => {
        let value = "";
        if (Array.isArray(customFieldValue)) {
            value = customFieldValue.join("|");
        } else {
            value = customFieldValue;
        }
        let currentCustomFieldData = { ...customerCustomFields };
        if (customFieldValue === "" || customFieldValue === null) {
            delete currentCustomFieldData[customFieldId];
        } else {
            currentCustomFieldData = {
                ...currentCustomFieldData,
                ...{ [customFieldId]: value },
            };
        }
        await dispatch(
            setFilterState({
                customerCustomFields: currentCustomFieldData,
            })
        );
    };

    const handleRemove = async (selectKey, optionObj) => {
        switch (selectKey) {
            case "customerRegions": {
                setVendorRegionPrevVal(vendorRegionPrevVal?.filter(val => val !== optionObj.value));
                break;
            }
            case "missingExposureZones": {
                setMissingExposureZonePrevValPrevVal(
                    missingExposureZonePrevVal?.filter(val => val !== optionObj.value)
                );
                break;
            }
            default:
                break;
        }

        dispatch(
            setFilterState({
                [`${selectKey}`]: filterState[`${selectKey}`]?.filter(
                    option => option !== optionObj.value
                ),
            })
        );
    };

    const handleSelectClose = async (fieldId, e) => {
        switch (fieldId) {
            case "customerRegions": {
                setVendorRegionPrevVal(e.target.value);
                break;
            }
            case "missingExposureZones": {
                setMissingExposureZonePrevValPrevVal(e.target.value);
                break;
            }
            default:
                break;
        }
    };

    const handleMissingcertificatesChange = useCallback(
        async e => {
            await dispatch(
                setFilterState({
                    [e.target.id]: e.target.checked,
                })
            );
            if (!e.target.checked) {
                await dispatch(
                    setFilterState({
                        missingExposureZones: [],
                    })
                );
            }
        },
        [dispatch, setFilterState]
    );

    const handleTextBoxChange = useCallback(
        async e => {
            await dispatch(
                setFilterState({
                    [e.target.id]: e.target.value,
                })
            );
        },
        [dispatch, setFilterState]
    );

    return (
        <div className={isCustomerDetails ? "block" : "hidden"}>
            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <SelectState
                        ref={ref}
                        id="vendorRegions"
                        name="vendorRegions"
                        onAdd={e => handleSearch("customerRegions", e)}
                        onRemove={e => handleRemove("customerRegions", e)}
                        onClose={e => handleSelectClose("customerRegions", e)}
                        loading={isLoading}
                        value={customerRegions}
                        multiple
                        label="Region of vendor address"
                        disabled={isLoading}
                    />
                </SCol>
            </SRow>
            <SRow className={usedBy === "VendorSearch" ? "block" : "hidden"}>
                <SCol span="12" className="pad-bottom-sm">
                    <input
                        id="isMissingcertificates"
                        name="isMissingcertificates"
                        type="checkbox"
                        aria-label="Vendors missing certificates"
                        onChange={e => handleMissingcertificatesChange(e)}
                        checked={isMissingcertificates}
                        disabled={isLoading}
                    />
                    <label htmlFor="isMissingcertificates">Vendors missing certificates</label>
                </SCol>
                {isMissingcertificates ? (
                    <SCol span="12" className="pad-bottom-sm">
                        <SelectOutgoingExposureZone
                            id="missingExposureZones"
                            name="missingExposureZones"
                            onAdd={e => handleSearch("missingExposureZones", e)}
                            onRemove={e => handleRemove("missingExposureZones", e)}
                            onClose={e => handleSelectClose("missingExposureZones", e)}
                            loading={isLoading}
                            value={missingExposureZones}
                            multiple
                            exposureZoneDisabled={false}
                            disabled={isLoading}
                        />
                    </SCol>
                ) : null}
            </SRow>
            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <label htmlFor="customerCodes">Vendor code</label>
                    <textarea
                        name="customerCodes"
                        id="customerCodes"
                        rows="5"
                        onChange={handleMultilineInputChange("customerCodes")}
                        value={customerCodes}
                        className={customerCodeError ? errorTag : noErrorTag}
                        disabled={isLoading}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {customerCodeError}
                    </div>
                </SCol>
            </SRow>
            <SRow>
                <SCol span="12" className="pad-bottom-sm">
                    <label htmlFor="alternateId">Alternate ID</label>
                    <input
                        name="alternateId"
                        id="alternateId"
                        type="text"
                        onChange={e => handleTextBoxChange(e)}
                        value={alternateId}
                        disabled={isLoading}
                    />
                </SCol>
            </SRow>
            <SRow>
                <SCol span="6" className="pad-right-xs pad-bottom-sm">
                    <label htmlFor="customerCreatedDateAfter">Created starting on</label>
                    <SDatepicker
                        inputid="customerCreatedDateAfter"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("customerCreatedDateAfter", e.detail.value, false);
                            e.target.className = "";
                        }}
                        value={customerCreatedDateAfter}
                        onS-deselect={() => {
                            updateFilters("customerCreatedDateAfter", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {customerCreatedDateAfterError}
                    </div>
                </SCol>
                <SCol span="6" className="pad-left-xs pad-bottom-sm">
                    <label htmlFor="customerCreatedDateBefore">...and through</label>
                    <SDatepicker
                        inputid="customerCreatedDateBefore"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("customerCreatedDateBefore", e.detail.value, false);
                        }}
                        value={customerCreatedDateBefore}
                        onS-deselect={() => {
                            updateFilters("customerCreatedDateBefore", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {customerCreatedDateBeforeError}
                    </div>
                </SCol>
            </SRow>
            <SRow>
                <SCol span="6" className="pad-right-xs pad-bottom-sm">
                    <label htmlFor="customerModifiedDateAfter">Modified starting on</label>
                    <SDatepicker
                        inputid="customerModifiedDateAfter"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("customerModifiedDateAfter", e.detail.value, false);
                        }}
                        value={customerModifiedDateAfter}
                        onS-deselect={() => {
                            updateFilters("customerModifiedDateAfter", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {customerModifiedDateAfterError}
                    </div>
                </SCol>
                <SCol span="6" className="pad-left-xs pad-bottom-sm">
                    <label htmlFor="customerModifiedDateBefore">...and through</label>
                    <SDatepicker
                        inputid="customerModifiedDateBefore"
                        className="width-auto"
                        disabled={isLoading}
                        onS-select={e => {
                            updateFilters("customerModifiedDateBefore", e.detail.value, false);
                        }}
                        value={customerModifiedDateBefore}
                        onS-deselect={() => {
                            updateFilters("customerModifiedDateBefore", "", false);
                        }}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {customerModifiedDateBeforeError}
                    </div>
                </SCol>
            </SRow>
            <SRow className={usedBy === "VendorSearch" ? "block" : "hidden"}>
                <SCol span="12" className="pad-bottom-sm">
                    <FeatureToggler category="customers" id="customFields">
                        <CustomerCustomField
                            updateCustomFieldPostData={updateCustomFieldPostData}
                            loading={isLoading}
                            searchValues={customerCustomFields}
                        />
                    </FeatureToggler>
                </SCol>
            </SRow>
        </div>
    );
});

export default FilterByVendor;
