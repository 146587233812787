import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { SDialog } from "@avalara/skylab-react";
import {
    selectIsRemoveRegionReasonLoading,
    removeRegionAndReason,
    setMatrixSetting,
} from "../../../../app/settingsSlice";

function RemoveConfirmationDialog(props) {
    const isRemoveRegionReason = useSelector(selectIsRemoveRegionReasonLoading);
    const removeButton = classnames({
        primary: true,
        small: true,
        loading: isRemoveRegionReason,
    });
    const dispatch = useDispatch();
    const removeRegionReason = async () => {
        const matrixSetting = {
            id: props?.certificateForm?.id,
            regionName: props?.certificateForm?.regionName,
            reasonName: props?.certificateForm?.exemptReasonName,
        };
        await dispatch(setMatrixSetting(matrixSetting));

        await dispatch(removeRegionAndReason());
        props.setIsRemoveConfirmationDialogOpen(false);
    };

    return (
        <SDialog
            id="removeConfirmation-dialog"
            open={props.isRemoveRegionReason}
            onS-dismiss={() => {
                props.setIsRemoveConfirmationDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="removeRegionReason">
                Remove this region and reason?
            </div>

            <div slot="body">
                <span>
                    You can&apos;t undo this action, but you can recreate it later if necessary.
                </span>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => props.setIsRemoveConfirmationDialogOpen(false)}>
                    Cancel
                </button>
                <button type="button" className={removeButton} onClick={removeRegionReason}>
                    Yes, remove
                </button>
            </div>
        </SDialog>
    );
}

export default RemoveConfirmationDialog;
