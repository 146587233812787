import React from "react";
import { SDialog, SAlert, SRow, SCol } from "@avalara/skylab-react";

function SyncErrorDialog(props) {
    const dialogName = "syncErrorDialog";

    return (
        <SDialog
            id={dialogName}
            open={props.isSyncErrorDialogOpen}
            onS-dismiss={() => {
                props.setIsSyncErrorDialogOpen(false);
            }}
            role="alertdialog"
            aria-modal="true">
            <div slot="header" id="syncError">
                There’s a problem saving this
            </div>
            <div slot="body">
                <SAlert status="error" role="alert" noDismiss className="margin-bottom-md">
                    <div>Is all the required information complete?</div>
                </SAlert>

                <SRow>
                    <SCol className="margin-left-xs">
                        <h4 className="inline custom-line-height" hidden={!props.logEntry}>
                            Reason for error
                        </h4>
                        <p className="width-600 margin-top-none" hidden={!props.logEntry}>
                            {props.logEntry}
                        </p>
                        <h4 className="inline">What caused this message?</h4>
                        <ul className="margin-left-xl margin-bottom-md">
                            <li>The associated customer record is missing information</li>
                            <li>
                                The reason for exemption isn&apos;t supported in the region covered
                                by the certificate
                            </li>
                            <li>A technical glitch</li>
                        </ul>
                        <h4 className="inline margin-top-md">
                            How do I save my changes successfully
                        </h4>
                        <ul className="margin-left-xl margin-bottom-md">
                            <li>
                                Check and make sure the required customer information is complete
                            </li>
                            <li>
                                Check the exemption reason and make sure it&apos;s valid in the
                                region covered by the certificate
                            </li>
                            <li>Try to save this again</li>
                        </ul>
                        <span>
                            If you get this message again after checking and trying to save again,
                            wait a few minutes
                            <br /> and try again. If the problem persists,
                            <a
                                className="pad-left-xs gray-link text-underline"
                                href="https://www.avalara.com/us/en/about/support.html">
                                contact support
                            </a>
                        </span>
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button
                    className="primary"
                    type="button"
                    onClick={() => props.setIsSyncErrorDialogOpen(false)}>
                    Ok, got it
                </button>
            </div>
        </SDialog>
    );
}

export default SyncErrorDialog;
