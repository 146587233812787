import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildApiV3UrlWithQuery, buildApiV3Url } from "../shared/Utils";
import { parseGetAllObjResponse, parseSuccessResponse } from "../shared/responseUtils";
import { documentTypeNames, CRUDOperation } from "../shared/constants";
import toast from "../hooks/toast";

const initialState = {
    isLoading: false,
    isDeleting: false,
    isAddOrEditLoading: false,
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    formState: {
        nameOrDescription: "",
    },
    sortColumn: ["name", true],
};

export const invalidReasonSlice = createSlice({
    name: "invalidReason",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setIsDeleting: (state, action) => {
            state.isDeleting = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setIsAddOrEditLoading: (state, action) => {
            state.isAddOrEditLoading = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setFormState,
    clearFormState,
    setIsDeleting,
    setSortColumn,
    setIsAddOrEditLoading,
} = invalidReasonSlice.actions;

export const selectIsLoading = state => {
    return state.invalidReason.isLoading;
};
export const selectPage = state => {
    return state.invalidReason.page;
};
export const selectFormState = state => {
    return state.invalidReason.formState;
};
export const selectIsDeleting = state => {
    return state.invalidReason.isDeleting;
};
export const selectSortColumn = state => {
    return state.invalidReason.sortColumn;
};
export const selectIsAddOrEditLoading = state => {
    return state.invalidReason.isAddOrEditLoading;
};

const [showToast] = toast();

export const fetchInvalidReasons =
    (filterString, top, skip, paginateDetails) => async (dispatch, getState) => {
        const { isLoading, formState, sortColumn } = getState().invalidReason;
        const { rowsPerPage } = getState().session;
        top = !top ? rowsPerPage : top;
        const { nameOrDescription } = formState;
        if (isLoading) {
            return;
        }
        dispatch(setIsLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;

        if (nameOrDescription) {
            filterString = `name contains '${formState?.nameOrDescription}' or description contains '${formState?.nameOrDescription}' `;
        }
        const apiURI = buildApiV3UrlWithQuery(
            `invalid-reasons`,
            filterString,
            null,
            top,
            skip,
            sort
        );
        const apiResponse = await axios.get(`${apiURI}`, {
            params: {
                documentType: documentTypeNames.salesAndUseTax,
                outgoing: false,
            },
            withCredentials: true,
        });

        if (!parseSuccessResponse(CRUDOperation.ReadAll, apiResponse)?.recordFound) {
            dispatch(setPageToNull());
        } else {
            const readAllResponse = parseGetAllObjResponse(apiResponse);
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails?.pageNumber,
                      startIndex: paginateDetails?.startIndex,
                      endIndex: paginateDetails?.endIndex,
                      rowsPerPage: paginateDetails?.rowsPerPage,
                      totalRecords: readAllResponse?.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: readAllResponse?.count,
                  };
            dispatch(
                setPage({
                    data: readAllResponse?.value,
                    paginate: paginateData,
                })
            );
        }

        dispatch(setIsLoading(false));
    };

export const deleteInvalidReasonAsync = (id, invalidReason) => async (dispatch, getState) => {
    const { isDeleting } = getState().invalidReason;

    if (isDeleting) {
        return false;
    }

    dispatch(setIsDeleting(true));
    const response = await axios.delete(buildApiV3Url(`invalid-reasons/${id}`), {
        withCredentials: true,
    });
    if (parseSuccessResponse(CRUDOperation.Delete, response)?.isSuccess) {
        showToast(
            "success",
            `Invalid reason "${invalidReason}" has been deleted successfully`,
            "deleteInvalidReasonToast",
            false
        );
        dispatch(fetchInvalidReasons());
    }
    dispatch(setIsDeleting(false));
    return response;
};
export const AddOrUpdateInvalidReason =
    (addOrEdit, payload, invalidReasonId) => async (dispatch, getState) => {
        const { isAddOrEditLoading } = getState().invalidReason;
        if (isAddOrEditLoading) return false;
        dispatch(setIsAddOrEditLoading(true));
        let success = false;
        if (addOrEdit === "add") {
            await axios
                .post(buildApiV3Url(`invalid-reasons`), payload, {
                    withCredentials: true,
                })
                .then(x => {
                    if (parseSuccessResponse(CRUDOperation.Create, x)?.isSuccess) {
                        showToast(
                            "success",
                            `Successfully added the invalid reason.`,
                            "invalid-reason-success-toast",
                            false
                        );
                        success = true;
                        dispatch(fetchInvalidReasons());
                    }
                });
        } else {
            await axios
                .put(buildApiV3Url(`invalid-reasons/${invalidReasonId}`), payload, {
                    withCredentials: true,
                })
                .then(x => {
                    if (parseSuccessResponse(CRUDOperation.Update, x)?.isSuccess) {
                        showToast(
                            "success",
                            `Successfully updated the invalid reason.`,
                            "invalid-reason-success-toast",
                            false
                        );

                        success = true;
                        dispatch(fetchInvalidReasons());
                    }
                });
        }
        dispatch(setIsAddOrEditLoading(false));
        return success;
    };

export default invalidReasonSlice.reducer;
