import { Identifier } from "./responseConstants";
import { HttpStatusCode } from "./constants";

export const errorResponse = response => {
    let result = null;
    if (response?.response?.data?.title) {
        result = response?.response?.data;
    } else if (response?.data?.title) {
        result = response?.data;
    }
    return result
        ? {
              code: result?.title,
              message: result?.detail,
              instance: result?.instance,
              status: result?.status,
          }
        : null;
};

export const parsePostObjResponse = response => {
    return response?.data;
};

export const parsePutObjResponse = response => {
    return response?.data;
};

export const parseDeleteObjResponse = response => {
    return response?.data;
};

export const parseGetObjResponse = response => {
    const obj = { value: null };
    if (response?.data) {
        obj.value = response?.data;
    } else if (response) {
        obj.value = response;
    }
    return obj;
};

export const parseGetAllObjResponse = response => {
    const obj = {
        value: [],
        count: 0,
    };
    if (response?.data) {
        obj.value = response?.data?.value;
        obj.count = response?.data?.count;
    }
    return obj;
};

export const parseSuccessResponse = (CRUDOperation, response) => {
    switch (CRUDOperation) {
        case "ReadAll":
            return getAllSuccessResponse(response);
        case "Read":
            return getSuccessResponse(response);
        case "Create":
            return postSuccessResponse(response);
        case "Update":
            return putSuccessResponse(response);
        case "Delete":
            return deleteSuccessResponse(response);
        default:
            return false;
    }
};

export const postSuccessResponse = response => {
    const postSuccessResponseObj = { isSuccess: false, isJobQueued: false };
    if (
        response?.status === HttpStatusCode.Created ||
        (response?.status === HttpStatusCode.Success && response?.data?.message?.jobQueued)
    ) {
        postSuccessResponseObj.isJobQueued = true;
        postSuccessResponseObj.isSuccess = true;
    } else if (
        response?.status === HttpStatusCode.Created ||
        response?.status === HttpStatusCode.Success
    ) {
        postSuccessResponseObj.isJobQueued = false;
        postSuccessResponseObj.isSuccess = true;
    }
    return postSuccessResponseObj;
};

export const deleteSuccessResponse = response => {
    const deleteSuccessResponseObj = { isSuccess: false, isJobQueued: false };
    if (errorResponse(response)?.code.toLowerCase() === Identifier.ObjectDeleted.toLowerCase()) {
        deleteSuccessResponseObj.isJobQueued = false;
        deleteSuccessResponseObj.isSuccess = true;
    } else if (
        errorResponse(response)?.code.toLowerCase() === Identifier.ObjectDeletedQueued.toLowerCase()
    ) {
        deleteSuccessResponseObj.isJobQueued = true;
        deleteSuccessResponseObj.isSuccess = true;
    }
    return deleteSuccessResponseObj;
};

export const putSuccessResponse = response => {
    const putSuccessResponseObj = { isSuccess: false, isJobQueued: false };
    if (response?.status === HttpStatusCode.Success && response?.data?.message?.jobQueued) {
        putSuccessResponseObj.isJobQueued = true;
        putSuccessResponseObj.isSuccess = true;
    } else if (response?.status === HttpStatusCode.Success) {
        putSuccessResponseObj.isJobQueued = false;
        putSuccessResponseObj.isSuccess = true;
    }
    return putSuccessResponseObj;
};

export const getAllSuccessResponse = response => {
    const obj = { recordFound: false };
    if (response?.data && response?.data?.count !== 0) {
        obj.recordFound = true;
    } else if (response?.data?.value && response?.data?.value?.length !== 0) {
        obj.recordFound = true;
    }
    return obj;
};

export const getSuccessResponse = response => {
    const obj = { recordFound: false };
    if (response?.data) {
        obj.recordFound = true;
    }
    return obj;
};

export const cannotDeleteRecordResponse = response => {
    return response?.identifier === Identifier.CannotDeleteRecord;
};

export const parseResponseMessage = response => {
    return response?.message;
};
