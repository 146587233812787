import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildApiV3UrlWithQuery, buildApiV3Url } from "../shared/Utils";

import {
    parseResponseMessage,
    parseSuccessResponse,
    cannotDeleteRecordResponse,
    parseGetAllObjResponse,
} from "../shared/responseUtils";
import toast from "../hooks/toast";
import { CRUDOperation } from "../shared/constants";

const initialState = {
    isLoading: false,
    isDeleting: false,
    isAddOrEditLoading: false,
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    sortColumn: ["fieldName", true],
    formState: {
        fieldName: "",
    },
    cannotDeleteRecordMessage: "",
};

export const certificateCustomFieldSlice = createSlice({
    name: "certificateCustomField",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setIsDeleting: (state, action) => {
            state.isDeleting = action.payload;
        },
        setIsAddOrEditLoading: (state, action) => {
            state.isAddOrEditLoading = action.payload;
        },
        setEnableLoading: (state, action) => {
            state.enableLoading = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setIsLimitReached: (state, action) => {
            state.setIsLimitReached = action.payload;
        },
        setCannotDeleteRecordMessage: (state, action) => {
            state.cannotDeleteRecordMessage = action.payload;
        },
    },
});

const [showToast] = toast();
export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setFormState,
    clearFormState,
    setIsDeleting,
    setIsAddOrEditLoading,
    setEnableLoading,
    setSortColumn,
    setIsLimitReached,
    setCannotDeleteRecordMessage,
} = certificateCustomFieldSlice.actions;

export const selectIsLoading = state => {
    return state.certificateCustomField.isLoading;
};
export const selectPage = state => {
    return state.certificateCustomField.page;
};
export const selectFormState = state => {
    return state.certificateCustomField.formState;
};
export const selectIsDeleting = state => {
    return state.certificateCustomField.isDeleting;
};
export const selectIsAddOrEditLoading = state => {
    return state.certificateCustomField.isAddOrEditLoading;
};
export const selectEnableLoading = state => {
    return state.certificateCustomField.enableLoading;
};
export const selectSortColumn = state => {
    return state.certificateCustomField.sortColumn;
};
export const selectIsLimitReached = state => {
    return state.certificateCustomField.setIsLimitReached;
};
export const selectCannotDeleteRecordMessage = state => {
    return state.certificateCustomField.cannotDeleteRecordMessage;
};

export const fetchCertificateCustomFields =
    (top, skip, paginateDetails) => async (dispatch, getState) => {
        const { isLoading, sortColumn, formState } = getState().certificateCustomField;
        const { rowsPerPage } = getState().session;
        top = !top ? rowsPerPage : top;
        const { fieldName } = formState;
        if (isLoading) {
            return;
        }
        let filterString = "";
        dispatch(setIsLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
        if (fieldName) {
            filterString = `fieldName contains '${fieldName}' `;
        }

        const apiURI = buildApiV3UrlWithQuery(
            `certificate-custom-fields`,
            filterString,
            null,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });
        if (!parseSuccessResponse(CRUDOperation.ReadAll, response)?.recordFound) {
            dispatch(setPageToNull());
            dispatch(setIsLimitReached(false));
        } else {
            const readAllResponse = parseGetAllObjResponse(response);
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.pageNumber,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: readAllResponse.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: readAllResponse.count,
                  };
            dispatch(
                setPage({
                    data: readAllResponse.value,
                    paginate: paginateData,
                })
            );
            if (paginateData?.totalRecords >= 50) dispatch(setIsLimitReached(true));
            else dispatch(setIsLimitReached(false));
        }
        dispatch(setIsLoading(false));
    };

export const deleteCertificateCustomField = id => async (dispatch, getState) => {
    const { isDeleting } = getState().certificateCustomField;

    if (isDeleting) {
        return false;
    }
    let successResponse = true;
    dispatch(setIsDeleting(true));
    const response = await axios.delete(buildApiV3Url(`certificate-custom-fields/${id}`), {
        withCredentials: true,
    });
    if (cannotDeleteRecordResponse(response)) {
        dispatch(setCannotDeleteRecordMessage(parseResponseMessage(response)));
        successResponse = false;
    } else if (parseSuccessResponse(CRUDOperation.Delete, response)?.isSuccess) {
        showToast("success", "Custom field deleted.", "deleteCertificateCustomFieldToast", false);
        dispatch(fetchCertificateCustomFields());
        dispatch(setCannotDeleteRecordMessage(""));
    }
    dispatch(setIsDeleting(false));
    return successResponse;
};

export const postCertificateCustomFields = payload => async dispatch => {
    dispatch(setIsAddOrEditLoading(true));
    let success = false;
    await axios
        .post(buildApiV3Url(`certificate-custom-fields`), payload, {
            withCredentials: true,
        })
        .then(x => {
            if (parseSuccessResponse(CRUDOperation.Create, x)?.isSuccess) {
                showToast(
                    "success",
                    `Certificate custom field added successfully.`,
                    "certificate-custom-field-success-toast",
                    false
                );
                success = true;
                dispatch(fetchCertificateCustomFields());
            } else {
                success = false;
            }
        });
    dispatch(setIsAddOrEditLoading(false));
    return success;
};

export const putCertificateCustomFields = (payload, certificateCustomFieldId) => async dispatch => {
    dispatch(setIsAddOrEditLoading(true));
    let success = false;
    await axios
        .put(buildApiV3Url(`certificate-custom-fields/${certificateCustomFieldId}`), payload, {
            withCredentials: true,
        })
        .then(x => {
            if (parseSuccessResponse(CRUDOperation.Update, x)?.isSuccess) {
                showToast(
                    "success",
                    `Successfully updated certificate custom field`,
                    "certificate-custom-field-success-toast",
                    false
                );

                success = true;
                dispatch(fetchCertificateCustomFields());
            } else {
                success = false;
            }
        });
    dispatch(setIsAddOrEditLoading(false));
    return success;
};

export default certificateCustomFieldSlice.reducer;
