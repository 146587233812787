import React from "react";
import { SInputExtended } from "@avalara/skylab-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    fetchCustomerCustomFields,
    setFormState,
    selectFormState,
} from "../../../../app/customerCustomFieldSlice";

function CustomersCustomFieldsFilter() {
    const formState = useSelector(selectFormState, shallowEqual);
    const dispatch = useDispatch();
    const { fieldName } = formState;

    const handleSearchBoxSubmit = () => {
        dispatch(fetchCustomerCustomFields());
    };

    const handleInput = e => {
        dispatch(
            setFormState({
                ...formState,
                [e.detail.inputId]: e.detail.value.trim(),
            })
        );
    };

    const clearSearchBox = async () => {
        dispatch(
            setFormState({
                ...formState,
                fieldName: "",
            })
        );
        handleSearchBoxSubmit();
    };

    return (
        <React.Fragment>
            <label htmlFor="fieldName">Search by name</label>
            <SInputExtended
                inputId="fieldName"
                type="search"
                onS-input={e => handleInput(e)}
                onS-search={e => handleSearchBoxSubmit(e)}
                onS-clear={() => clearSearchBox()}
                value={fieldName}
            />
        </React.Fragment>
    );
}
export default CustomersCustomFieldsFilter;
