import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { SRow, SCol, SBox } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import { selectData, setActiveEntities, selectActiveEntities } from "../../../app/contentSlice";
import "../taxContent.css";

const ExemptEntitiesList = React.memo(() => {
    const dispatch = useDispatch();
    const pageData = useSelector(selectData);
    const activeEntitiesId = useSelector(selectActiveEntities);
    const setActiveEntitiesId = e => {
        if (e.pageY >= window.innerHeight) window.scrollTo(0, 0);
        dispatch(setActiveEntities(e.target.id));
    };
    return (
        <React.Fragment>
            <SRow className="margin-top-md">
                <SCol span="7">
                    <span className="laws-font">Entities</span>
                </SCol>
                <SCol span="5">
                    <span className="right">
                        {pageData?.data?.length ? pageData?.data?.length : 0} exempt entities
                    </span>
                </SCol>
            </SRow>
            <hr />
            <SRow>
                <SCol>
                    {pageData.data && pageData.data.length > 0 ? (
                        pageData.data.map(entities => (
                            <div
                                key={uuidv4()}
                                className="margin-top-xs font-bold"
                                id={entities.id}
                                role="button"
                                tabIndex={0}
                                onClick={e => setActiveEntitiesId(e)}>
                                <SBox
                                    id={entities.id}
                                    className={
                                        entities.id == activeEntitiesId
                                            ? "laws-blue-box"
                                            : "laws-box"
                                    } /* eslint eqeqeq: 0 */
                                >
                                    {entities.itemname}
                                </SBox>
                            </div>
                        ))
                    ) : (
                        <span className="margin-top-lg font-semibold line-height-sm">
                            No results found.
                        </span>
                    )}
                </SCol>
            </SRow>
        </React.Fragment>
    );
});

export default ExemptEntitiesList;
