import React from "react";
import { SAlert, SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { deleteProductCategoryAsync } from "../../../app/productCategoriesSlice";
import "./productCategories.css";

const DeleteProductCategories = React.memo(props => {
    const dialogName = "DeleteProductCategories";
    const dispatch = useDispatch();
    const deleteButton = classnames({
        primary: true,
        small: true,
    });

    const handleDelete = async event => {
        event.preventDefault();
        await dispatch(deleteProductCategoryAsync(props.categoryName));
        props.setIsDeleteDialogOpen(false);
    };

    return (
        <SDialog
            id={dialogName}
            className="width-500"
            open={props.isDeleteDialogOpen}
            onS-dismiss={() => props.setIsDeleteDialogOpen(false)}
            aria-modal="true">
            <div slot="header" id="deleteExemptReason">
                Delete the category?
            </div>
            {props.certCount > 0 ? (
                <div slot="body" className="warning">
                    <SAlert status="warning" role="alert" nodismiss>
                        Because {props.categoryName} is in use, deleteing the category will remove
                        it from {props.certCount} certificates.
                    </SAlert>
                </div>
            ) : (
                <div slot="body">Once a category is deleted, it&apos;t gone for good.</div>
            )}

            <div slot="footer">
                <button className={deleteButton} onClick={handleDelete}>
                    Yes, delete it
                </button>
                <button
                    className="secondary small"
                    onClick={() => props.setIsDeleteDialogOpen(false)}>
                    Cancel
                </button>
            </div>
        </SDialog>
    );
});

export default DeleteProductCategories;
