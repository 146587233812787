import { SCol, SLoader, SRow, STabs } from "@avalara/skylab-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { isEligibleUser } from "../../shared/sessionUtility";
import {
    fetchPublicCertExpressToken,
    fetchSettingsAPI,
    selectPublicCertExpressToken,
    selectSetting,
    setSetting,
    selectIsSavingSettings,
} from "../../app/settingsSlice";
import featureFlag from "../../featureToggler/featureFlag";

const SettingsHeader = React.memo(() => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsSavingSettings);
    const location = useLocation();
    const locationPath = location.pathname.toString();
    const navigate = useNavigate();
    const setting = useSelector(selectSetting, shallowEqual);
    const publicCertExpressToken = useSelector(selectPublicCertExpressToken);
    const isEligibleUserSendingCerts = dispatch(
        isEligibleUser(featureFlag.settings.sendingCertficateExemptionMatrix, true)
    );
    const isEligibleUserRequestCerts = dispatch(
        isEligibleUser(featureFlag.settings.requestingCertficateExemptionMatrix, true)
    );

    const isEligibleUserSettingsAutomations = dispatch(
        isEligibleUser(featureFlag.settings.automations, true)
    );

    const isEligibleInternalUserSettings = dispatch(
        isEligibleUser(featureFlag.settings.internalSettings)
    );

    let tabItems = [
        {
            id: "tabpanel-settings-general",
            path: "/settings/general",
            label: "General",
            tabPanelId: "tabpanel-settings-general",
            selected: locationPath.includes("general"),
            displayIndex: 1,
        },
        {
            id: "tabpanel-settings-default-exemption-forms",
            path: `/settings/default-exemption-forms/${
                isEligibleUserSendingCerts && !isEligibleUserRequestCerts ? "outgoing" : "incoming"
            }`,
            label: "Default exemption forms",
            tabPanelId: "tabpanel-settings-default-exemption-forms",
            selected: locationPath.includes("default-exemption-forms"),
            displayIndex: 4,
        },
    ];

    if (isEligibleUserSettingsAutomations) {
        tabItems = [...tabItems];
    }
    if (isEligibleUserRequestCerts || isEligibleUserSendingCerts) {
        tabItems = [
            ...tabItems,
            {
                id: "tabpanel-settings-certificateRequests",
                path: "/settings/certificateRequests",
                label: "Certificate requests",
                tabPanelId: "tabpanel-settings-certificateRequests",
                selected: locationPath.includes("certificateRequests"),
                displayIndex: 2,
            },
        ];
    }

    if (isEligibleUserRequestCerts) {
        tabItems = [
            ...tabItems,
            {
                id: "tabpanel-settings-certexpress-settings",
                path: "/settings/certexpress-settings",
                label: "CertExpress settings",
                tabPanelId: "tabpanel-settings-certexpress-settings",
                selected: locationPath.includes("certexpress-settings"),
                displayIndex: 3,
            },
        ];
    }

    tabItems = [
        ...tabItems,
        {
            id: "tabpanel-settings-advanced-settings",
            path: "/settings/advanced-settings",
            label: "Advanced settings",
            tabPanelId: "tabpanel-settings-advanced-settings",
            selected: locationPath.includes("advanced-settings"),
            displayIndex: 5,
        },
    ];

    if (isEligibleInternalUserSettings) {
        tabItems = [
            ...tabItems,
            {
                id: "tabpanel-settings-internal-user-settings",
                path: "/settings/internal-user-settings",
                label: "Internal user settings",
                tabPanelId: "tabpanel-settings-internal-user-settings",
                selected: locationPath.includes("internal-user-settings"),
                displayIndex: 6,
            },
        ];
    }

    let clientHeaderDOM = null;

    const navigateTo = to => {
        const currentHistory = locationPath;
        if (to.includes("general") && !currentHistory.includes("general")) {
            navigate(`/settings/general`);
        } else if (
            to.includes("certificateRequests") &&
            !currentHistory.includes("certificateRequests")
        ) {
            navigate(`/settings/certificateRequests`);
        } else if (
            to.includes("default-exemption-forms") &&
            !currentHistory.includes("default-exemption-forms")
        ) {
            navigate(
                `/settings/default-exemption-forms/${
                    isEligibleUserSendingCerts && !isEligibleUserRequestCerts
                        ? "outgoing"
                        : "incoming"
                }`
            );
        } else if (
            to.includes("advanced-settings") &&
            !currentHistory.includes("advanced-settings")
        ) {
            navigate(`/settings/advanced-settings`);
        } else if (
            to.includes("certexpress-settings") &&
            !currentHistory.includes("certexpress-settings")
        ) {
            navigate(`/settings/certexpress-settings`);
        } else if (
            to.includes("internal-user-settings") &&
            !currentHistory.includes("internal-user-settings")
        ) {
            navigate(`/settings/internal-user-settings`);
        }
    };

    useEffect(() => {
        dispatch(fetchSettingsAPI(true));
        return () => {
            dispatch(setSetting([]));
        };
    }, [dispatch]);

    useEffect(() => {
        if (setting?.id) dispatch(fetchPublicCertExpressToken(setting.id));
    }, [setting?.id, dispatch]);

    if (isLoading) {
        clientHeaderDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" aria-live="polite" />
            </div>
        );
    } else {
        clientHeaderDOM = (
            <>
                <SRow>
                    <SCol>
                        <h1 className="inline-block" id="pageHeader">
                            Exemption certificate options
                        </h1>
                    </SCol>
                    <SCol span="10">
                        {setting?.id ? (
                            <table className="condensed-table borderless" role="presentation">
                                <tbody>
                                    <tr>
                                        <th span="col">API company ID</th>
                                        <th span="col">Inbound fax number </th>
                                        <th span="col">
                                            Share link to Exemption Certificate Express
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>{setting.id}</td>
                                        <td>{setting.faxNumber}</td>
                                        <td>
                                            <a
                                                href={publicCertExpressToken}
                                                target="_blank"
                                                rel="noreferrer">
                                                {publicCertExpressToken}
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : null}
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        {setting.id ? (
                            <React.Fragment>
                                <STabs
                                    tabItems={tabItems.sort((a, b) => {
                                        return a.displayIndex - b.displayIndex;
                                    })}
                                    onS-select={e => navigateTo(e.detail.id)}
                                />
                                <Outlet />
                            </React.Fragment>
                        ) : null}
                    </SCol>
                </SRow>
            </>
        );
    }
    return clientHeaderDOM;
});

export default SettingsHeader;
