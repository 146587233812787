import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SIcon, SDialog, SBox } from "@avalara/skylab-react";
import classnames from "classnames";
import {
    selectIsDeletingCertFile,
    deleteCertificateFile,
    clearValidationData,
} from "../../app/certificateSlice";
import { isEligibleUser } from "../../shared/sessionUtility";
import "../review/validation.css";
import featureFlag from "../../featureToggler/featureFlag";

function DeleteCertificateFileDialog(props) {
    const isDeletingCertificateFile = useSelector(selectIsDeletingCertFile);
    const deleteButton = classnames({
        primary: true,
        small: true,
        loading: isDeletingCertificateFile,
    });
    const dispatch = useDispatch();
    const deleteFile = async () => {
        await dispatch(deleteCertificateFile(props.certificateID));
        await dispatch(clearValidationData());
        props.setIsDeleteCertificateFileDialogOpen(false);
    };
    const isEligibleUserReviewCertificateUpload = dispatch(
        isEligibleUser(featureFlag.reviewCertificate.upload)
    );

    return (
        <SDialog
            id="deleteCertificateFile-dialog"
            open={props.isDeleteCertificateFileDialogOpen}
            onS-dismiss={() => {
                props.setIsDeleteCertificateFileDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="deleteCertificateFile">
                Delete the certificate image?
            </div>
            <div slot="body">
                <SBox className="yellow-box">
                    <table className="borderless" role="presentation">
                        <tbody>
                            <tr>
                                <td>
                                    <SIcon name="flag" className="medium" aria-hidden="true" />
                                </td>
                                <td>
                                    <span>When you delete the image, it&apos;s gone for good.</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </SBox>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    disabled={isDeletingCertificateFile}
                    onClick={() => props.setIsDeleteCertificateFileDialogOpen(false)}>
                    Cancel
                </button>
                <button
                    disabled={!isEligibleUserReviewCertificateUpload}
                    className={deleteButton}
                    type="button"
                    onClick={() => deleteFile()}>
                    Yes, delete
                </button>
            </div>
        </SDialog>
    );
}

export default DeleteCertificateFileDialog;
