import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./sessionSlice";
import certificateReducer from "./certificateSlice";
import customerReducer from "./customerSlice";
import customerCertificateReducer from "./customerCertificateSlice";
import certificateHistoryReducer from "./certificateHistorySlice";
import customerHistoryReducer from "./customerHistorySlice";
import customerDuplicateReducer from "./customerDuplicateSlice";
import requestReducer from "./requestSlice";
import coverLetterReducer from "./coverLetterSlice";
import exemptionMatrixReducer from "./exemptionMatrixSlice";
import overviewReducer from "./overviewSlice";
import commonReducer from "./commonSlice";
import customerQuickSearchReducer from "./CustomerQuickSearchSlice";
import contentReducer from "./contentSlice";
import settingReducer from "./settingsSlice";
import savedSearchesReducer from "./savedSearchesSlice";
import exportSearchReducer from "./exportSearchSlice";
import certificateRequestReducer from "./certificateRequestSlice";
import bulkUploadReducer from "./bulkUploadSlice";
import bulkUpdateReducer from "./bulkUpdateSlice";
import roleManagementReducer from "./roleManagementSlice";
import vendorPackagesReducer from "./vendorPackagesSlice";
import regionReducer from "./regionSlice";
import exemptionReasonReducer from "./exemptionReasonSlice";
import invalidReasonReducer from "./invalidReasonSlice";
import customerCustomFieldReducer from "./customerCustomFieldSlice";
import certificateCustomFieldReducer from "./certificateCustomFieldSlice";
import certificateLabelReducer from "./certificateLabelSlice";
import customerLabelReducer from "./customerLabelSlice";
import retailLocationReducer from "./retailLocationSlice";
import retailSettingsReducer from "./retailSettingsSlice";
import healthCheckReducer from "./healthCheckSlice";
import retailReducer from "./retailSlice";
import productCategoriesReducer from "./productCategoriesSlice";

export default configureStore({
    reducer: {
        session: sessionReducer,
        certificate: certificateReducer,
        customer: customerReducer,
        customerCertificate: customerCertificateReducer,
        customerHistory: customerHistoryReducer,
        customerDuplicate: customerDuplicateReducer,
        certificateHistory: certificateHistoryReducer,
        request: requestReducer,
        coverLetter: coverLetterReducer,
        exemptionMatrix: exemptionMatrixReducer,
        overview: overviewReducer,
        common: commonReducer,
        customerQuickSearch: customerQuickSearchReducer,
        content: contentReducer,
        setting: settingReducer,
        savedSearches: savedSearchesReducer,
        exportSearch: exportSearchReducer,
        certificateRequest: certificateRequestReducer,
        bulkUpload: bulkUploadReducer,
        bulkUpdate: bulkUpdateReducer,
        roleManagement: roleManagementReducer,
        vendorPackages: vendorPackagesReducer,
        exemptionReason: exemptionReasonReducer,
        region: regionReducer,
        invalidReason: invalidReasonReducer,
        customerCustomField: customerCustomFieldReducer,
        certificateCustomField: certificateCustomFieldReducer,
        retailLocation: retailLocationReducer,
        retailSettings: retailSettingsReducer,
        certificateLabel: certificateLabelReducer,
        customerLabel: customerLabelReducer,
        healthCheck: healthCheckReducer,
        retail: retailReducer,
        productCategories: productCategoriesReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }),
});
