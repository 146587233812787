import React from "react";
import { SDialog, SAlert } from "@avalara/skylab-react";

function BulkUpdateErrorDialog(props) {
    const { bulkEditCertificateCount } = props;
    const dismissDialog = () => {
        props.setIsBulkUpdateErrorDialogOpen(false);
    };
    return (
        <SDialog
            id="bulkUpdateErrorDialog"
            open={props.isBulkUpdateErrorDialogOpen}
            onS-dismiss={() => {
                dismissDialog();
            }}
            aria-modal="true"
            role="alertdialog">
            <div slot="header" id="bulkUpdateError">
                Select fewer certificates to update
            </div>
            <div slot="body">
                <SAlert status="info" nodismiss="" role="status">
                    <div>
                        Updates can be made to up to 100 certificates at a time. You asked to update{" "}
                        {bulkEditCertificateCount}.
                    </div>
                </SAlert>
                <br />
                <span>Refine your search in order to update certificates.</span>
            </div>
            <div slot="footer">
                <button className="primary small" onClick={dismissDialog}>
                    OK, got it
                </button>
            </div>
        </SDialog>
    );
}
export default BulkUpdateErrorDialog;
