import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import {
    getCertificateLabels,
    selectCertificateLabels,
    selectIsLoadingCertificateLabels,
    setCertificateLabels,
    selectCustomLabels,
} from "../../app/certificateSlice";

function CertificateLabel(props) {
    const dispatch = useDispatch();
    const selectRef = React.createRef();
    const isLoading = useSelector(selectIsLoadingCertificateLabels);
    const selectedLabels = useSelector(selectCustomLabels, shallowEqual);
    const optionsList = useSelector(selectCertificateLabels, shallowEqual).map(element => ({
        label: element.name,
        value: element.id,
        selected: props.certificateID ? selectedLabels.some(x => x.id === element.id) : false,
    }));

    useEffect(() => {
        dispatch(getCertificateLabels(props.certificateID));
        return () => {
            dispatch(setCertificateLabels([]));
        };
    }, [dispatch, props.certificateID]);
    return (
        <React.Fragment>
            <label htmlFor="Certificatelabel" className="margin-top-sm" id="lbl-certificateLabel">
                Certificate labels
            </label>
            <SSelect
                inputId="Certificatelabel"
                ref={selectRef}
                multiple
                selectionOptional
                loading={isLoading}
                optionsList={optionsList}
                disabled={props?.disabledLabel ? "disabled" : null}
                onS-select={e => {
                    props.updateCertificateLabel(e, true, selectRef);
                }}
                onS-deselect={e => {
                    props.updateCertificateLabel(e, false, selectRef);
                }}
            />
        </React.Fragment>
    );
}

export default React.memo(CertificateLabel);
