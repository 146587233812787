import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { SRow, SCol, SIcon } from "@avalara/skylab-react";
import { useDispatch, useSelector } from "react-redux";
import { getPageTitle, isEligibleUser } from "../../../../shared/sessionUtility";
import { selectIsLimitReached, selectIsLoading } from "../../../../app/customerCustomFieldSlice";
import FeatureToggler from "../../../../featureToggler/FeatureToggler";
import CustomersCustomFieldsGrid from "./CustomersCustomFieldsGrid";
import CustomersCustomFieldsFilter from "./CustomersCustomFieldsFilter";
import CannotAddCustomFieldDialog from "../CannotAddCustomFieldDialog";
import featureFlag from "../../../../featureToggler/featureFlag";

const CustomersCustomFields = React.memo(() => {
    const dispatch = useDispatch();

    const [openAddUpdateDialog, setOpenAddUpdateDialog] = useState(false);
    const [addOrEdit, setAddOrEdit] = useState("");
    const [isLimitReachedDialogOpen, setIsLimitReachedDialogOpen] = useState(false);
    const isLimitReached = useSelector(selectIsLimitReached);
    const isGridLoading = useSelector(selectIsLoading);

    const handleAddCustomField = () => {
        if (isLimitReached) {
            setIsLimitReachedDialogOpen(true);
        } else {
            setOpenAddUpdateDialog(true);
            setIsLimitReachedDialogOpen(false);
            setAddOrEdit("Add");
        }
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Customers custom fields"))}</title>
            </Helmet>

            <div>
                <p>View and manage additional fields for customer information.</p>
                <SRow className="align-items-end pad-bottom-sm">
                    <SCol span="8">
                        <SRow>
                            <SCol span="md-6 xl-6 5" className="pad-bottom-none">
                                <CustomersCustomFieldsFilter />
                            </SCol>
                        </SRow>
                    </SCol>
                    <FeatureToggler category="settings" id="addCustomersCustomField">
                        <SCol span="4">
                            <span className="flex justify-content-end">
                                <button
                                    id="add-customer-customField-button"
                                    className="primary inline margin-left-sm"
                                    disabled={
                                        isGridLoading ||
                                        !dispatch(
                                            isEligibleUser(
                                                featureFlag.settings.addCustomersCustomField
                                            )
                                        )
                                    }
                                    onClick={() => handleAddCustomField()}>
                                    <SIcon
                                        name="plus"
                                        className="margin-right-xs"
                                        aria-label=" Add custom field"
                                    />
                                    Add custom field
                                </button>
                            </span>
                        </SCol>
                    </FeatureToggler>
                </SRow>
            </div>
            <FeatureToggler category="settings" id="viewCustomersCustomField">
                <CustomersCustomFieldsGrid
                    openAddUpdateDialog={openAddUpdateDialog}
                    setOpenAddUpdateDialog={setOpenAddUpdateDialog}
                    addOrEdit={addOrEdit}
                    setAddOrEdit={setAddOrEdit}
                />
            </FeatureToggler>
            <CannotAddCustomFieldDialog
                isLimitReachedDialogOpen={isLimitReachedDialogOpen}
                setIsLimitReachedDialogOpen={setIsLimitReachedDialogOpen}
            />
        </React.Fragment>
    );
});

export default CustomersCustomFields;
