import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { SIcon, SDialog, SBox } from "@avalara/skylab-react";
import classnames from "classnames";
import toast from "../../../hooks/toast";
import {
    unlinkDuplicateCustomerAPI,
    selectIsUnlinkingDuplicateCustomer,
} from "../../../app/customerDuplicateSlice";

function UnlinkDuplicateCustomerDialog(props) {
    const dispatch = useDispatch();
    const [showToast] = toast();
    const isUnlinkingDuplicateCustomer = useSelector(selectIsUnlinkingDuplicateCustomer);
    const deleteButton = classnames({
        primary: true,
        small: true,
        loading: isUnlinkingDuplicateCustomer,
    });

    const unlinkDuplicateCustomer = async () => {
        await dispatch(unlinkDuplicateCustomerAPI(props.customerId, props.sameAsId));
        props.setIsUnlinkDuplicateCustomerDialogOpen(false);
        showToast("success", "The duplicate customer has been unlinked successfully.");
    };

    return (
        <SDialog
            open={props.isUnlinkDuplicateCustomerDialogOpen}
            onS-dismiss={() => {
                props.setIsUnlinkDuplicateCustomerDialogOpen(false);
            }}
            className="unlink-dialog-width"
            aria-modal="true">
            <div slot="header">Unlink the duplicate customer</div>
            <div slot="body">
                <SBox className="yellow">
                    <div className="unlink-dialog-text">
                        <SIcon name="flag" className="medium pad-right-sm" aria-hidden="true" />
                        <span>
                            After unlinking, {props.customerName} retains the certificates that were
                            shared with them when the link was first created.
                        </span>
                    </div>
                </SBox>
            </div>
            <div slot="footer" className="flex right">
                <button
                    className="secondary small"
                    onClick={() => props.setIsUnlinkDuplicateCustomerDialogOpen(false)}>
                    Cancel
                </button>
                <button className={deleteButton} type="button" onClick={unlinkDuplicateCustomer}>
                    Unlink
                </button>
            </div>
        </SDialog>
    );
}

export default UnlinkDuplicateCustomerDialog;
