import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { createSelector } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    getDataEntrySetsAsync,
    getExposureZonesAsync,
    selectDataEntrySets,
    selectExposureZones,
    selectIsLoadingDataEntrySets,
    selectIsLoadingExposureZones,
    selectSUTOutgoingExposureZones,
} from "../../app/certificateSlice";
import { checkSelectComponentProps, errorTag, noErrorTag } from "../../shared/Utils";

const modifiedDataEntry = createSelector(selectDataEntrySets, data => {
    const option = data.map(element => ({
        label: element.tag,
        value: `${element.id}-sets`,
        selected: false,
        isDataEntrySet: true,
        isSST: element.isSst,
    }));
    return option;
});

const SelectExposureZone = React.forwardRef((props, ref) => {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingExposureZones);
    const isLoadingDataEntrySets = useSelector(selectIsLoadingDataEntrySets);
    const { value, multiple, isDataEntrySet } = props;
    let exposureZones = useSelector(
        !props.isVendor ? selectExposureZones : selectSUTOutgoingExposureZones,
        shallowEqual
    );

    exposureZones = exposureZones
        .map(element => {
            const optionvalue = element?.id ? element?.id.toString() : null;
            if (optionvalue)
                return {
                    label: element.name,
                    value: optionvalue,
                    selected: false,
                    isDataEntrySet: false,
                    isSST: element.isSst,
                    initials: element.state?.initials,
                };
            return null;
        })
        .filter(element => element != null);

    const dataEntry = useSelector(modifiedDataEntry, shallowEqual);
    const [optionsList, setOptionsList] = useState(exposureZones);
    const [deselectedClicked, setDeselectedClicked] = useState(false);

    // function to set selected true according to prop.value
    const setExposureList = useCallback(() => {
        setOptionsList(exposureZoneList => {
            return exposureZoneList.map(exposureZone => {
                const expZone = exposureZone;
                let selectedChanged = false;
                if (value && value[0]?.name && typeof value === "object") {
                    const name = value.map(zone => zone.name);
                    expZone.selected = name.includes(exposureZone.label);
                    selectedChanged = true;
                } else if (
                    multiple &&
                    (value?.includes(exposureZone.label) || value?.includes(exposureZone.value))
                ) {
                    expZone.selected = true;
                    selectedChanged = true;
                } else if (
                    value === exposureZone.label ||
                    value?.toString() === exposureZone.value?.toString()
                ) {
                    expZone.selected = true;
                    selectedChanged = true;
                } else if (!selectedChanged && value) {
                    expZone.selected = false;
                } else if (!value && props?.isAll && expZone.label === "All") {
                    expZone.selected = true;
                } else if (!value) {
                    expZone.selected = false;
                }

                return expZone;
            });
        });
    }, [value]);

    // call fetch APIs of zones and sst
    useEffect(() => {
        let filter = `?$filter=documentType.name eq 'Sales and Use Tax'`;
        filter += !props.isVendor
            ? ` AND documentType.outgoing eq false`
            : ` AND documentType.outgoing eq true`;

        dispatch(getExposureZonesAsync(filter));
        if (props.isDataEntrySet) {
            dispatch(getDataEntrySetsAsync());
        }
    }, [isDataEntrySet, dispatch]);

    // add exp zones to option list
    useEffect(() => {
        setOptionsList(prevOptionList => {
            const prevOptionListLabels = prevOptionList.map(x => x.label);
            const zonesNotIncluded = exposureZones.filter(
                x => !prevOptionListLabels.includes(x.label)
            );
            if (zonesNotIncluded) return [...prevOptionList, ...zonesNotIncluded];
            return prevOptionList;
        });
        if (value?.length) {
            setExposureList();
        }
    }, [JSON.stringify(exposureZones)]);

    // add dataentry to optionlist
    useEffect(() => {
        if (isDataEntrySet)
            setOptionsList(prevOptionList => {
                const prevOptionListLabels = prevOptionList.map(x => x.label);
                const dataEntryNotIncluded = dataEntry.filter(
                    x => !prevOptionListLabels.includes(x.label)
                );
                if (dataEntryNotIncluded) return [...prevOptionList, ...dataEntryNotIncluded];
                return prevOptionList;
            });
    }, [isDataEntrySet, JSON.stringify(dataEntry)]);

    // set selected true as per value
    useEffect(() => {
        setExposureList();
    }, [multiple, setExposureList]);

    useEffect(() => {
        if (props?.isAll)
            setOptionsList(prevState => {
                if (!prevState.find(x => x.label === "All")) {
                    return [{ label: "All", value: "-1", selected: !props?.value }, ...prevState];
                }
                return prevState;
            });
    }, [props?.isAll, props?.value]);

    checkSelectComponentProps("SelectExposureZone", props);

    return (
        <React.Fragment>
            {props.label ? (
                <label htmlFor={getNextId()} id={`lbl-${getCurrentId()}`}>
                    {props.label}
                </label>
            ) : (
                <label htmlFor={getNextId()} id={`lbl-${getCurrentId()}`}>
                    Region{props.multiple ? "s" : ""} covered by this certificate
                </label>
            )}
            <SSelect
                name="exposureZone"
                inputId={getCurrentId()}
                selectionOptional
                optionsList={optionsList}
                loading={isLoading || (props.isDataEntrySet && isLoadingDataEntrySets)}
                className={props.error ? errorTag : noErrorTag}
                onS-select={e => {
                    props.onAdd(e.detail.item);
                    if (deselectedClicked !== false) setDeselectedClicked(false);
                }}
                onS-deselect={e => {
                    // this is to run deselect only once if deselect all is clicked
                    if (
                        e?.detail?.isDeselectAll &&
                        (deselectedClicked === false || !props?.externalDeselectedClicked)
                    ) {
                        setDeselectedClicked(true);
                        props.onRemove(e.detail.item, e?.detail?.isDeselectAll);
                    } else if (!e?.detail?.isDeselectAll) {
                        props.onRemove(e.detail.item, e?.detail?.isDeselectAll);
                    }
                }}
                onS-close={e => (props.onClose ? props?.onClose(e) : null)}
                disabled={props?.disabled || props?.exposureZoneDisabled ? "disabled" : null}
                multiple={props?.multiple}
                ref={ref}
                value={props?.value}
            />
        </React.Fragment>
    );
});

export default React.memo(SelectExposureZone);
