import React, { useEffect } from "react";
import { SSelect } from "@avalara/skylab-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useId } from "@certcapture/react-components";
import {
    GetAllRetailLocations,
    selectIsLoading,
    selectRetailLocations,
} from "../../app/retailLocationSlice";

const SelectRetailLocation = React.memo(props => {
    const [getCurrentId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoading);
    const { value, locations = value?.length ? JSON.parse(value) : [] } = props;

    useEffect(() => {
        dispatch(GetAllRetailLocations());
    }, [dispatch]);

    const locationOptionList = useSelector(selectRetailLocations, shallowEqual).map(element => {
        const selectOption = {
            label: element.name,
            value: element.id,
            selected: false,
        };
        if (locations?.includes(element.id)) {
            selectOption.selected = true;
        }
        return selectOption;
    });

    return (
        <SSelect
            name="retail-location"
            inputId={getCurrentId()}
            aria-label="Select retail location"
            optionsList={locationOptionList}
            multiple={props.multiple}
            loading={isLoading}
            onS-select={e => props.onAdd(e.detail.item)}
            onS-deselect={e => props.onRemove(e.detail.item)}
            value={props.value}
            disabled={props?.disabled ? true : false} // eslint-disable-line
        />
    );
});

export default SelectRetailLocation;
