import React from "react";

import { SDialog } from "@avalara/skylab-react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import {
    selectLoading,
    deleteCoverLetterAPI,
    fetchCoverLettersByPagingAPI,
    deleteOutgoingCoverLetterAPI,
} from "../../../app/coverLetterSlice";
import featureFlag from "../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../shared/sessionUtility";

function DeleteCoverLetterDialog(props) {
    const loading = useSelector(selectLoading);
    const deleteClassName = classnames({ primary: true, small: true, loading });
    const dispatch = useDispatch();

    const deleteCoverLetter = async () => {
        if (props.isVendor) {
            dispatch(deleteOutgoingCoverLetterAPI(props?.coverLetter?.id)).then(() => {
                dispatch(fetchCoverLettersByPagingAPI());
                props.setIsDeleteCoverLetterDialogOpen(false);
            });
        } else {
            dispatch(deleteCoverLetterAPI(props?.coverLetter?.id)).then(() => {
                dispatch(fetchCoverLettersByPagingAPI());
                props.setIsDeleteCoverLetterDialogOpen(false);
            });
        }
    };

    const isEligibleECMUserCoverLetterFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.coverLetter)
    );

    return (
        <SDialog
            id="deleteCertificate-dialog"
            open={props.isDeleteCoverLetterDialogOpen}
            onS-dismiss={() => {
                props.setIsDeleteCoverLetterDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="deleteCoverLetter">
                Delete the template?
            </div>

            <div slot="body">When you delete a template, it&apos;s gone for good.</div>
            <div slot="footer">
                <button
                    className="secondary small"
                    disabled={!isEligibleECMUserCoverLetterFlag}
                    onClick={() => props.setIsDeleteCoverLetterDialogOpen(false)}>
                    Cancel
                </button>
                <button
                    className={deleteClassName}
                    type="button"
                    disabled={loading || !isEligibleECMUserCoverLetterFlag}
                    onClick={() => deleteCoverLetter()}>
                    Yes, Delete
                </button>
            </div>
        </SDialog>
    );
}

export default DeleteCoverLetterDialog;
