import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { checkSelectComponentProps } from "../../shared/Utils";
import { selectSession } from "../../app/sessionSlice";

import {
    getOutgoingExemptionReasonsAsync,
    selectOutgoingExemptionReasons,
    selectIsLoadingExemptionReasons,
} from "../../app/certificateSlice";

function SelectOutgoingExemptionReasons(props, ref) {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingExemptionReasons);
    const exemptionReasons = useSelector(selectOutgoingExemptionReasons).map(element => {
        const selectOption = {
            label: element.name,
            value: element.id,
            selected: false,
        };
        if (
            props.multiple &&
            (props?.value?.includes(element.name) || props?.value?.includes(element.id))
        ) {
            selectOption.selected = true;
        } else if (element.name === props?.value || element.id === props?.value) {
            selectOption.selected = true;
        }
        return selectOption;
    });
    if (props?.isAll && !props.value) {
        exemptionReasons.unshift({ label: "All", value: "-1", selected: true });
    }

    const session = useSelector(selectSession);
    checkSelectComponentProps("SelectExemptionReasons", props);

    useEffect(() => {
        if (session.activeCompany.id !== null) {
            dispatch(
                getOutgoingExemptionReasonsAsync(
                    '?$filter=DenotesExemption eq true OR DenotesExemption eq false AND DenotesExposure eq false AND DocumentType.Description eq "Sales and Use Tax (Outgoing)" AND Enabled eq true'
                )
            );
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <label htmlFor={getNextId()} id="lbl-exemptionReason">
                Reason for exemption
            </label>
            <SSelect
                name="exemptionReason"
                inputId={getCurrentId()}
                ref={ref}
                selectionOptional
                optionsList={exemptionReasons}
                multiple={props.multiple}
                loading={isLoading}
                className={props.error ? props.error : ""}
                onS-select={e => props.onAdd(e.detail.item)}
                onS-deselect={e => props.onRemove(e.detail.item)}
                onS-close={e => (props.onClose ? props.onClose(e) : null)}
                disabled={props.disabled ? "disabled" : null}
                value={props?.value}
            />
        </React.Fragment>
    );
}

export default React.forwardRef(SelectOutgoingExemptionReasons);
