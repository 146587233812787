import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SDatepicker, SDialog, SCol, SRow, SIcon } from "@avalara/skylab-react";
import { selectPage } from "../../app/certificateSlice";
import "./bulkeditdialog.css";
import {
    setExpirationDate,
    setSelectedField,
    selectCertificateIds,
} from "../../app/bulkUpdateSlice";
import { errorTag, noErrorTag } from "../../shared/Utils";

function BulkUpdateExpirationDateDialog(props) {
    const dispatch = useDispatch();
    const certificateIds = useSelector(selectCertificateIds, shallowEqual);
    const [selectedRadio, setSelectedRadio] = useState("");
    const [assignedDate, setAssignedDate] = useState(null);
    const [assignedDateError, setAssignedDateError] = useState(null);
    const dateErrorMsg = "Enter a date";
    const invalidDateErrorMsg = "Enter the date in MM/DD/YYYY format";
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page.data;
    const handleRadioChange = e => {
        setSelectedRadio(e.target.value);
    };
    const onSave = () => {
        if (selectedRadio === "assignDate" && assignedDateError) {
            return;
        }
        if (selectedRadio === "assignDate" && !assignedDate) {
            setAssignedDateError(dateErrorMsg);
            return;
        }
        if (selectedRadio === "doesNotExpire") {
            dispatch(setExpirationDate(new Date("9999-12-31")));
        } else {
            dispatch(setExpirationDate(assignedDate));
        }
        props.setIsBulkUpdateConfirmDialogOpen(true);
        props.setIsBulkUpdateExpirationDateDialogOpen(false);
        setSelectedRadio("");
    };
    const onCancel = () => {
        props.setIsBulkUpdateExpirationDateDialogOpen(false);
        setAssignedDate(null);
        setSelectedRadio("");
        dispatch(setSelectedField(""));
    };
    // event when assigned date is changed
    const handleDateChange = e => {
        if (e.detail.value) {
            setAssignedDate(e.detail.value);
            if (e.detail.value === undefined) {
                setAssignedDateError(dateErrorMsg);
            } else if (assignedDateError !== "") {
                setAssignedDateError("");
            }
        } else if (e?.detail?.invalidInput) {
            setAssignedDateError(invalidDateErrorMsg);
        } else {
            setAssignedDate(null);
            setAssignedDateError(dateErrorMsg);
        }
    };
    const selectedDistinctExpiationDate = () => {
        const filteredDates = pageData
            .filter(cert => {
                return certificateIds.includes(cert.certificateId.toString());
            })
            .map(cert => new Date(cert.expirationDate).toLocaleDateString());
        const uniqueExpirationDates = [...new Set(filteredDates)];
        return uniqueExpirationDates.length;
    };
    return (
        <SDialog
            id="bulk-updated-expiration-dialog"
            open={props.isBulkUpdateExpirationDateDialogOpen}
            onS-dismiss={onCancel}
            aria-modal="true">
            <div slot="header" id="bulk-updated-expiration">
                Update {certificateIds.length}{" "}
                {certificateIds.length > 1 ? "certificates" : "certificate"}
            </div>
            <div slot="body">
                <div>
                    These certificates currently have {selectedDistinctExpiationDate()} different
                    expiration dates that will be replaced.
                </div>

                <div className="text-sm-strong pad-top-md">Updated expiration</div>
                <div>
                    <fieldset role="radiogroup">
                        <SRow>
                            <SCol span="1">
                                <input
                                    type="radio"
                                    id="assignDate"
                                    value="assignDate"
                                    name="expiration"
                                    onChange={e => handleRadioChange(e)}
                                    checked={selectedRadio === "assignDate"}
                                />
                                <label htmlFor="assignDate" id="lbl-assignDate" />
                            </SCol>
                            <SCol span="4" className="pad-left-none pad-top-xs">
                                <SDatepicker
                                    onS-select={e => {
                                        handleDateChange(e);
                                    }}
                                    onS-error={e => {
                                        handleDateChange(e);
                                    }}
                                    onS-deselect={e => {
                                        handleDateChange(e);
                                    }}
                                    value={assignedDate}
                                    className={
                                        assignedDateError && selectedRadio === "assignDate"
                                            ? errorTag
                                            : noErrorTag
                                    }
                                    disabled={selectedRadio !== "assignDate"}
                                    pickerTop
                                />
                                <div className="input-msg" hidden={!assignedDateError}>
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                    <span className="auto top-xs">{assignedDateError}</span>
                                </div>
                            </SCol>
                        </SRow>

                        <input
                            type="radio"
                            id="doesNotExpire"
                            value="doesNotExpire"
                            name="expiration"
                            onChange={e => handleRadioChange(e)}
                            checked={selectedRadio === "doesNotExpire"}
                        />
                        <label
                            className="pad-left-xl"
                            htmlFor="doesNotExpire"
                            id="lbl-doesNotExpire">
                            Doesn&apos;t expire
                        </label>
                    </fieldset>
                </div>
            </div>
            <div slot="footer">
                <button className="secondary small" onClick={onCancel}>
                    Cancel
                </button>
                <button
                    className="primary small"
                    type="button"
                    onClick={onSave}
                    disabled={!selectedRadio || (selectedRadio === "assignDate" && !assignedDate)}>
                    Save
                </button>
            </div>
        </SDialog>
    );
}

export default BulkUpdateExpirationDateDialog;
