import React from "react";
import { useSelector } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import {
    selectStates,
    selectIsLoadingStates,
    selectIsLoadingAllStates,
    selectAllStates,
} from "../../app/customerSlice";
import { noErrorTag, updateSelectedAttribute } from "../../shared/Utils";

const SelectState = React.memo(props => {
    const [getCurrentId, getNextId] = useId();
    const isLoading = useSelector(selectIsLoadingStates);
    const loadingAllStates = useSelector(selectIsLoadingAllStates);
    const countryStates = useSelector(selectStates).map(element => {
        if (props.value && element.id === props.value) {
            return { label: element.name, value: element.id, selected: true };
        }
        return { label: element.name, value: element.id };
    });

    const allStates = useSelector(selectAllStates).map(element => {
        if (props.value && element.id === props.value) {
            return { label: element.name, value: element.id, selected: true };
        }
        return { label: element.name, value: element.id };
    });

    const states = props.all ? allStates : countryStates;

    function handleChange(event) {
        if (props.onChange) {
            props.onChange(event.detail.item);
            updateSelectedAttribute(states, event.detail.item);
        }
    }

    function handleRemove(event) {
        if (props.onRemove) {
            props.onRemove(event.target.value);
            updateSelectedAttribute(states, event.target.value);
        }
    }

    const getLabel = () => {
        if (props?.label) {
            if (states.length > 0)
                return (
                    <label
                        htmlFor={getNextId()}
                        id={`lbl-${getCurrentId()}`}
                        className={props?.required ? "required" : null}>
                        {props.label}
                    </label>
                );
            return null;
        }
        if (props.required)
            return (
                <label
                    htmlFor={getNextId()}
                    id="lbl-region"
                    hidden={(!isLoading && props.hidden) || states?.length === 0}
                    className="required">
                    Region
                </label>
            );
        return (
            <label htmlFor={getNextId()} id="lbl-state">
                State
            </label>
        );
    };

    return (
        <React.Fragment>
            {getLabel()}
            <SSelect
                name="state"
                inputId={getCurrentId()}
                hidden={isLoading || loadingAllStates || states?.length === 0}
                loading={isLoading || loadingAllStates}
                selectionOptional
                optionsList={states}
                onS-select={e => handleChange(e)}
                onS-deselect={e => handleRemove(e)}
                className={props.stateError || noErrorTag}
            />
        </React.Fragment>
    );
});

export default SelectState;
