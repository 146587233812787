import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setDeliveryType, selectDeliveryType } from "../../../app/coverLetterSlice";
import { selectCustomer } from "../../../app/customerSlice";
import AddEmailDialog from "./AddEmailDialog";
import ZoomTip from "../../sharedComponents/ZoomTip";

const OutgoingDeliveryType = React.memo(() => {
    const dispatch = useDispatch();
    const deliveryType = useSelector(selectDeliveryType);
    const vendor = useSelector(selectCustomer, shallowEqual);
    const [isAddEmailDialogOpen, setIsAddEmailDialogOpen] = useState(false);

    const handleDeliveryChange = e => {
        dispatch(setDeliveryType(e.target.value));
    };

    const addEmailDialog = () => {
        setIsAddEmailDialogOpen(true);
    };

    return (
        <React.Fragment>
            <input
                onChange={e => handleDeliveryChange(e)}
                type="radio"
                id="send"
                value="E"
                checked={deliveryType === "E"}
                disabled={!vendor.emailAddress}
            />
            <label htmlFor="send" className="radio-button-fix">
                Email to the vendor
            </label>
            {!vendor.emailAddress ? (
                <button onClick={() => addEmailDialog()} className="link">
                    Add an email address
                </button>
            ) : null}
            <br />
            <input
                onChange={e => handleDeliveryChange(e)}
                type="radio"
                id="download"
                value="D"
                checked={deliveryType === "D"}
            />
            <label htmlFor="download" className="radio-button-fix">
                Make available for download (to print and mail)
            </label>
            <ZoomTip
                tipTitle="download-option"
                url="bundle/pah1663778507797/page/wnp1716499538252.html"
            />

            <AddEmailDialog
                isAddEmailDialogOpen={isAddEmailDialogOpen}
                setIsAddEmailDialogOpen={setIsAddEmailDialogOpen}
            />
        </React.Fragment>
    );
});

export default OutgoingDeliveryType;
