import React, { useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuiUploader, SLoader, SIcon } from "@avalara/skylab-react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ImportErrorDialog from "./ImportErrorDialog";
import axios from "../../axios";
import toast from "../../hooks/toast";
import { selectSession } from "../../app/sessionSlice";
import { getPageTitle, getSessionAccessToken, isEligibleUser } from "../../shared/sessionUtility";
import { documentTypeNames } from "../../shared/constants";
import { buildApiV3Url } from "../../shared/Utils";
import featureFlag from "../../featureToggler/featureFlag";
import getConfig from "../../config";

const { fvsConfigurationId } = getConfig();

const ImportVendors = React.memo(() => {
    const [showToast, hideToast] = toast();
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState("");
    const [errorFile, setErrorFile] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isImportErrorDialogOpen, setIsImportErrorDialogOpen] = useState(false);
    const session = useSelector(selectSession, shallowEqual);
    const [uploadCertificateEvent, setUploadCertificateEvent] = useState("");
    const auiFileUploaderRef = React.createRef();
    const isEligibleUserVendorAdditionalInfo = dispatch(
        isEligibleUser(featureFlag.vendors.vendorAdditionalInfo)
    );
    const postImportAPI = async file => {
        const uploadData = {
            Key: file.key,
            FileName: file.name,
            ValidationId: file.validationId,
            DocumentTypeName: documentTypeNames.salesAndUseTax,
            Outgoing: false,
        };
        return axios.post(buildApiV3Url(`bulk-import`), uploadData, {
            withCredentials: true,
        });
    };

    const getImportAPI = async jobId => {
        return axios.get(buildApiV3Url(`bulk-import/${jobId}`), {
            withCredentials: true,
        });
    };

    const sleep = () => new Promise(resolve => setInterval(resolve, 2000));

    const resetAuiRef = () => {
        if (auiFileUploaderRef.current) {
            auiFileUploaderRef.current.reset();
        }
        const uploader = document.getElementById("aui-uploader");
        if (uploader) {
            uploader.reset();
        }
    };

    const checkStatusUntilCompleted = async jobId => {
        const response = await getImportAPI(jobId);
        const pollerResponse = response.data.status;
        if (!["Completed", "Errors found", "Error"].includes(pollerResponse)) {
            await sleep();
            checkStatusUntilCompleted(jobId);
        } else {
            if (pollerResponse === "Completed") {
                showToast(
                    "success",
                    "Exempt vendors uploaded successfully.",
                    "import-success",
                    true,
                    "Go to vendor search",
                    () => {
                        hideToast("import-success");
                        navigateTo("/search/vendors");
                    }
                );
            } else if (response?.data?.downloadLink) {
                setErrorFile(response.data.downloadLink);
                setIsImportErrorDialogOpen(true);
            } else if (response?.data?.resultJson) {
                const parseError = JSON.parse(response.data.resultJson);
                if (parseError?.error) {
                    setErrorMessage(parseError.error);
                    setIsImportErrorDialogOpen(true);
                }
            } else if (response?.data.status === "Error") {
                setIsImportErrorDialogOpen(true);
            }
            resetAuiRef();
        }
    };

    const uploadFile = async event => {
        setErrorFile("");
        setErrorMessage("");
        const response = await postImportAPI(event.detail.files[0]);
        if (response?.response?.status === 400) {
            showToast("error", response.response.data.message);
            resetAuiRef();
        } else if (!response?.data?.success) {
            showToast("error", "Failed to queue bulk import.");
            resetAuiRef();
        } else {
            const { jobId } = response.data;
            checkStatusUntilCompleted(jobId);
        }
    };

    const handleAttachFunc = event => {
        setFileName(event.detail.files[0].name);
        setUploadCertificateEvent(event);
    };

    const handleValidateFunc = event => {
        const { filesPassedValidation, filesFailedValidation } = event.detail;
        if (filesPassedValidation.length > 0) {
            uploadFile(uploadCertificateEvent);
        }
        if (filesFailedValidation.length > 0) {
            showToast("error", `${filesFailedValidation[0].message}`);
            resetAuiRef();
        }
    };

    const handleErrorEvent = useCallback(() => {
        resetAuiRef();
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Import vendors"))}</title>
            </Helmet>
            <div>
                <h1>Import vendor {session.baseSubscription ? "and certificate" : ""} data</h1>
                <p className="margin-bottom-xl">
                    Download a template and place your
                    {!session.baseSubscription ? " vendor" : ""} data in the correct columns.
                </p>
                {session.baseSubscription ? (
                    <Link to="/assets/ImportExemptionsToolkit.zip" target="_blank" download>
                        <SIcon name="download" aria-hidden="true" />
                        <span className="margin-left-xs font-semibold">
                            Download a template and place your data in the correct columns.
                        </span>
                    </Link>
                ) : (
                    <React.Fragment>
                        <span role="separator" aria-orientation="vertical" />
                        <a
                            href="https://knowledge.avalara.com/bundle/xyq1665650841341_xyq1665650841341/page/Import_vendors.html"
                            target="_blank"
                            className="text-underline divider-right"
                            rel="noreferrer">
                            <span className="margin-left-xs margin-right-sm">
                                View the import guidelines
                            </span>
                        </a>
                        {isEligibleUserVendorAdditionalInfo ? (
                            <Link
                                to="/assets/ap_vendor_upload_template.xlsx"
                                target="_blank"
                                download
                                className="margin-left-sm">
                                <SIcon name="download" aria-hidden="true" />
                                <span
                                    className="font-semibold margin-left-xs margin-right-sm"
                                    id="vendorTemplate">
                                    Vendors template
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/assets/vendor_upload_template.xlsx"
                                target="_blank"
                                download
                                className="margin-left-sm">
                                <SIcon name="download" aria-hidden="true" />
                                <span className="font-semibold margin-left-xs margin-right-sm">
                                    Vendors template
                                </span>
                            </Link>
                        )}
                    </React.Fragment>
                )}
                <div className="margin-top-xl">
                    <AuiUploader
                        id="aui-uploader"
                        ref={auiFileUploaderRef}
                        callbackUrl="/"
                        onS-attach={e => {
                            handleAttachFunc(e);
                        }}
                        onS-validate={handleValidateFunc}
                        onS-error={handleErrorEvent}
                        stagingExternalCta={false}
                        stagingInlineCta={false}
                        multiple={false}
                        accept=".xlsx"
                        retries={3}
                        configurationId={fvsConfigurationId}
                        requestCurrentAccessToken={getSessionAccessToken}>
                        <div slot="guidelines">
                            <div>
                                Files can be up to 20MB and include up to 60,000 rows of{" "}
                                {session.baseSubscription ? "certificate" : ""} data.
                            </div>
                        </div>
                        <div
                            slot="loading"
                            className="flex flex-dir-col align-items-center pad-all-lg">
                            <SLoader
                                loading="loading"
                                className="margin-bottom-sm"
                                aria-live="polite"
                            />
                            <h3 className="margin-all-none">Currently importing {fileName}</h3>
                            <p className="margin-all-none">This import may take a few minutes.</p>
                        </div>
                    </AuiUploader>
                </div>
            </div>
            {isImportErrorDialogOpen && (
                <ImportErrorDialog
                    isImportErrorDialogOpen={isImportErrorDialogOpen}
                    setIsImportErrorDialogOpen={setIsImportErrorDialogOpen}
                    errorFile={errorFile}
                    errorMessage={errorMessage}
                />
            )}
        </React.Fragment>
    );
});

export default ImportVendors;
