import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { SLoader } from "@avalara/skylab-react";
import UserDetails from "./UserDetails";
import UserCompanyAccess from "./UserCompanyAccess";
import UserRoleStaticInfo from "./UserRoleStaticInfo";
import {
    selectIsLoading,
    selectUserInfoRole,
    selectIsEditClicked,
    getUserInfo,
} from "../../app/roleManagementSlice";

function RoleManagementPage() {
    const dispatch = useDispatch();
    const { userId } = useParams();
    const isLoading = useSelector(selectIsLoading);
    const userRoles = useSelector(selectUserInfoRole, shallowEqual);
    const isEditClicked = useSelector(selectIsEditClicked);

    useEffect(() => {
        dispatch(getUserInfo(userId));
    }, [userId, isEditClicked, dispatch]);

    return (
        <div>
            {isLoading ? (
                <div className="flex dl-flex-fill-height dl-flex-center">
                    <h3>Loading ...</h3>
                    <SLoader id="page-loader" className="medium" aria-live="polite" loading />
                </div>
            ) : (
                <>
                    <UserDetails />
                    {userRoles === null || isEditClicked ? (
                        <UserCompanyAccess />
                    ) : (
                        <UserRoleStaticInfo />
                    )}
                </>
            )}
        </div>
    );
}

export default React.memo(RoleManagementPage);
