import React, { useState, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import { SLoader, SPagination, SIcon } from "@avalara/skylab-react";
import {
    selectPage,
    selectIsLoadingRegions,
    fetchRegionsWithNexusDetailsAPI,
} from "../../../app/regionSlice";
import { getStateByCountry } from "../../../app/customerSlice";
import "./region.css";
import DeleteRegionDialog from "./DeleteRegionDialog";
import RecordCount from "../../search/RecordCount";
import { isEligibleUser } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import { selectSession, updateRowCountPreference } from "../../../app/sessionSlice";
import { getRowsPerPage } from "../../../shared/Utils";

function RegionGrid(props) {
    const dispatch = useDispatch();
    const session = useSelector(selectSession, shallowEqual);
    const [submitting, setSubmitting] = useState(false);
    const [isDeleteRegionDialogOpen, setIsDeleteRegionDialogOpen] = useState(false);
    const [currentRegion, setCurrentRegion] = useState(false);
    const loading = useSelector(selectIsLoadingRegions);
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page.data;
    const paginateData = page.paginate;

    const isEligibleUserSettingsEditExemptionRegion = dispatch(
        isEligibleUser(featureFlag.settings.editExemptionRegion)
    );
    const isEligibleUserSettingsDeleteExemptionRegion = dispatch(
        isEligibleUser(featureFlag.settings.deleteExemptionRegion)
    );
    const getRowKey = useCallback(row => {
        return row.id;
    }, []);

    const onEditClick = row => {
        props.setCurrentRow(row);
        props.setSource("EditARegion");
        if (row.country.id) {
            dispatch(getStateByCountry(row.country.id));
        }
        props.setIsAddEditRegionDialogOpen(true);
    };

    const onDeleteClick = row => {
        setCurrentRegion(row);
        setIsDeleteRegionDialogOpen(true);
    };

    const headerColumns = [
        gridHeader("Name", "name", value => <div id="name">{value}</div>),
        gridHeader("State or province", "state", value => <div id="state">{value?.name} </div>),
        gridHeader("Country", "country", value => <div id="country">{value?.initials} </div>),
        gridHeader(
            "Include with state or province",
            "autoShipTo",
            value => value && <SIcon name="check" aria-hidden="true" />
        ),
        gridHeader(
            "Exclude from requests",
            "isExcludeFromRequest",
            value => value && <SIcon name="check" aria-hidden="true" />
        ),
        gridHeader("", "id", (value, row) => (
            <FeatureToggler category="settings" id="editExemptionRegion">
                <button
                    className="link font-bold"
                    id="edit-region"
                    onClick={() => onEditClick(row)}
                    disabled={!isEligibleUserSettingsEditExemptionRegion}>
                    Edit
                </button>
            </FeatureToggler>
        )),
        gridHeader(
            "",
            "isCustom",
            (value, row) => (
                <FeatureToggler category="settings" id="deleteExemptionRegion">
                    <button
                        className="link font-bold"
                        disabled={!value || !isEligibleUserSettingsDeleteExemptionRegion}
                        onClick={() => onDeleteClick(row)}>
                        Delete
                    </button>
                </FeatureToggler>
            ),
            "text-center",
            "text-center"
        ),
    ];

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            await dispatch(
                fetchRegionsWithNexusDetailsAPI(
                    null,
                    null,
                    e.detail.rowsPerPage,
                    e.detail.startIndex,
                    null,
                    e.detail
                )
            );
            setSubmitting(false);
        }
    };
    let table = null;
    if (submitting || loading)
        table = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    else if (paginateData.totalRecords !== 0)
        table = (
            <s-table-container class="th-with-filter">
                <DataGrid
                    columns={headerColumns}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={submitting}
                    inContainer
                />
            </s-table-container>
        );
    else
        table = (
            <span className="no-data flex dl-flex-fill-height dl-flex-center">
                <h1 className="no-data" id="no-region-found">
                    No region found.
                </h1>
                <p>Adjust your search and try again</p>
            </span>
        );

    return (
        <React.Fragment>
            <RecordCount
                selectedCount={0}
                recordCount={paginateData?.totalRecords}
                recordName="regions"
            />
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={getRowsPerPage(pageData, session.rowsPerPage)}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            {isDeleteRegionDialogOpen ? (
                <DeleteRegionDialog
                    isDeleteRegionDialogOpen={isDeleteRegionDialogOpen}
                    setIsDeleteRegionDialogOpen={setIsDeleteRegionDialogOpen}
                    region={currentRegion}
                />
            ) : null}
        </React.Fragment>
    );
}

export default RegionGrid;
