import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import {
    buildApiV3UrlWithQuery,
    convertFieldToString,
    fixSearchStringForPostgres,
    buildApiV3Url,
} from "../shared/Utils";
import { parseSuccessResponse } from "../shared/responseUtils";
import toast from "../hooks/toast";
import { CRUDOperation } from "../shared/constants";

const [showToast] = toast();
const initialState = {
    isLoading: false,
    isDeleting: false,
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    sortColumn: ["name", true],
    formState: {
        name: "",
    },
    isAddOrEditLoading: false,
    selectedTabItem: 0,
    retailLocations: [],
};

export const retailLocationSlice = createSlice({
    name: "retailLocation",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setIsAddOrEditLoading: (state, action) => {
            state.isAddOrEditLoading = action.payload;
        },
        setIsDeleting: (state, action) => {
            state.isDeleting = action.payload;
        },
        setSelectedTabItem: (state, action) => {
            state.selectedTabItem = action.payload;
        },
        setRetailLocations: (state, action) => {
            state.retailLocations = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setSortColumn,
    setFormState,
    clearFormState,
    setIsAddOrEditLoading,
    setIsDeleting,
    setSelectedTabItem,
    setRetailLocations,
} = retailLocationSlice.actions;

export const selectIsLoading = state => {
    return state.retailLocation.isLoading;
};
export const selectPage = state => {
    return state.retailLocation.page;
};
export const selectSortColumn = state => {
    return state.retailLocation.sortColumn;
};
export const selectFormState = state => {
    return state.retailLocation.formState;
};
export const selectIsAddOrEditLoading = state => {
    return state.retailLocation.isAddOrEditLoading;
};
export const selectIsDeleting = state => {
    return state.retailLocation.isDeleting;
};
export const selectSelectedTabItem = state => {
    return state.retailLocation.selectedTabItem;
};
export const selectRetailLocations = state => {
    return state.retailLocation.retailLocations;
};

export const fetchRetailLocations = (top, skip, paginateDetails) => async (dispatch, getState) => {
    const { isLoading, sortColumn, formState } = getState().retailLocation;
    const { rowsPerPage } = getState().session;
    top = !top ? rowsPerPage : top;

    const { name } = formState;

    if (isLoading) {
        return;
    }
    const value = fixSearchStringForPostgres(name.trim());
    const filterString = value ? `name contains '${value}' or code contains '${value}'` : "";

    dispatch(setIsLoading(true));
    const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    const apiURI = buildApiV3UrlWithQuery(`retail-locations`, filterString, null, top, skip, sort);
    const response = await axios.get(`${apiURI}`, { withCredentials: true });
    if (!parseSuccessResponse(CRUDOperation.ReadAll, response)?.recordFound) {
        dispatch(setPageToNull());
        dispatch(setIsLoading(false));
        return;
    }
    if (response.data && response.data.count === "0") {
        dispatch(setPageToNull());
    } else {
        const paginateData = paginateDetails
            ? {
                  pageNumber: paginateDetails.pageNumber,
                  startIndex: paginateDetails.startIndex,
                  endIndex: paginateDetails.endIndex,
                  rowsPerPage: paginateDetails.rowsPerPage,
                  totalRecords: response.data.count,
              }
            : {
                  pageNumber: 1,
                  startIndex: 0,
                  endIndex: 19,
                  rowsPerPage: 20,
                  totalRecords: response.data.count,
              };
        dispatch(
            setPage({
                data: convertFieldToString(response.data.value, "stateId"),
                paginate: paginateData,
            })
        );
    }
    dispatch(setIsLoading(false));
};

export const addOrUpdateRetailLocations = (addOrEdit, payload, locationId) => async dispatch => {
    dispatch(setIsAddOrEditLoading(true));
    let success = false;
    if (addOrEdit === "Add") {
        await axios
            .post(buildApiV3Url(`locations`), payload, {
                withCredentials: true,
            })
            .then(response => {
                if (parseSuccessResponse(CRUDOperation.Create, response)?.isSuccess) {
                    showToast("success", `Location added.`, "Location-success-toast", false);
                    success = true;
                    dispatch(fetchRetailLocations());
                } else {
                    success = false;
                }
            });
    } else {
        await axios
            .put(buildApiV3Url(`locations/${locationId}`), payload, {
                withCredentials: true,
            })
            .then(response => {
                if (parseSuccessResponse(CRUDOperation.Update, response)?.isSuccess) {
                    success = true;
                    showToast("success", `Location updated.`, "location-success-toast", false);
                    dispatch(fetchRetailLocations());
                } else {
                    success = false;
                }
            });
    }
    dispatch(setIsAddOrEditLoading(false));
    return success;
};

export const deleteRetailLocation = id => async (dispatch, getState) => {
    const { isDeleting } = getState().certificateLabel;

    if (isDeleting) {
        return false;
    }
    dispatch(setIsDeleting(true));
    const response = await axios.delete(buildApiV3Url(`locations/${id}`), {
        withCredentials: true,
    });
    if (response?.data?.success) {
        showToast("success", "Retail location deleted.", "deleteLocationToast", false);
        dispatch(fetchRetailLocations());
    }
    dispatch(setIsDeleting(false));
    return response?.data?.success;
};

export const GetAllRetailLocations = () => async (dispatch, getState) => {
    const { isLoading, retailLocations } = getState().retailLocation;
    if (isLoading || retailLocations.length) {
        return;
    }
    dispatch(setIsLoading(true));
    const response = await axios.get(buildApiV3Url(`retail-locations?$orderBy=name ASC&$top=500`), {
        withCredentials: true,
    });
    if (response && response.data && response.data.value) {
        const transformedLocations = convertFieldToString(response.data.value, "id");
        dispatch(setRetailLocations(transformedLocations));
    }
    dispatch(setIsLoading(false));
};

export default retailLocationSlice.reducer;
