import React, { useState, useRef } from "react";
import { SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import Customer from "../customers/Customer";

function AddCustomerDialog(props) {
    const customerSaveRef = useRef(null);
    const [fromUpdated, setFormUpdated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const saveClassName = classnames({ primary: true, small: true, loading: submitting });
    return (
        <SDialog
            open={props.customerDialogOpen}
            id="add-customer-dialog"
            onS-dismiss={() => props.setCustomerDialogOpen(false)}
            aria-modal="true">
            <div slot="header" id="addCustomer">
                Add customer information
            </div>
            <div slot="body">
                <Customer
                    ref={customerSaveRef}
                    setFormUpdated={setFormUpdated}
                    setSubmitting={setSubmitting}
                    handleAddCustomer={props?.handleAddCustomer}
                    setCustomerDialogOpen={props?.setCustomerDialogOpen}
                />
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => {
                        props.setCustomerDialogOpen(false);
                    }}>
                    Cancel
                </button>
                <button
                    className={saveClassName}
                    disabled={!fromUpdated}
                    onClick={() => {
                        customerSaveRef?.current?.click();
                    }}>
                    Save
                </button>
            </div>
        </SDialog>
    );
}

export default AddCustomerDialog;
