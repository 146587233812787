import React from "react";
import { useNavigate } from "react-router-dom";
import { SDialog, SRow, SCol } from "@avalara/skylab-react";

function MissingSignatureDialog(props) {
    const navigate = useNavigate();
    const errorMessage =
        "To send certificate packages, you need to have a company signature on file. If you’re not an authorized signer, ask an authorized signer to add a company signature to the settings.";

    const onSDismiss = () => {
        navigate("/settings");
    };

    const clickCancel = () => {
        navigate("/overview");
    };

    return (
        <SDialog
            id="signatureError-dialog"
            open={props.sigError}
            noDismiss
            aria-modal="true"
            className="signatureError">
            <div slot="header" id="signatureError">
                Sending certificate package
            </div>
            <div slot="body" className="preview-modal-body">
                <div className="text-gray">{errorMessage}</div>
            </div>
            <div slot="footer" className="block">
                <SRow>
                    <SCol span="12" className="flex justify-content-end">
                        <button className="secondary inline" onClick={() => clickCancel()}>
                            Cancel
                        </button>
                        <button className="primary inline" onClick={() => onSDismiss()}>
                            Create a signature
                        </button>
                    </SCol>
                </SRow>
            </div>
        </SDialog>
    );
}

export default MissingSignatureDialog;
