import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Helmet } from "react-helmet-async";
import { SCol, SRow, SLoader } from "@avalara/skylab-react";
import classnames from "classnames";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import {
    fetchCertificateRequestsAPI,
    setCertificateRequest,
    selectCertificateRequest,
    selectIsLoading,
    selectSetting,
    putGeneralSettings,
    selectIsSavingSettings,
    postBulkPdfRebuild,
    updateMSUserRole,
    deleteMSUserRole,
    getUserInfo,
    selectUserInfo,
    selectIsLoadingUserInfo,
} from "../../../app/settingsSlice";
import { selectSession } from "../../../app/sessionSlice";
import featureFlag from "../../../featureToggler/featureFlag";
import "../settingsStyle.css";
import { ecmRole } from "../../navigation/Subscription";

const InternalUserSettings = React.memo(() => {
    const dispatch = useDispatch();
    const settings = useSelector(selectSetting, shallowEqual);
    const session = useSelector(selectSession, shallowEqual);
    const userInfo = useSelector(selectUserInfo, shallowEqual);
    const isUserInfoLoading = useSelector(selectIsLoadingUserInfo);
    const [collectSst, setCollectSst] = useState(settings?.collectSst);
    const [usesManagedService, setUsesManagedServices] = useState(settings?.usesManagedServices);
    const [requireSst, setRequireSst] = useState(settings?.requireSst);
    const [sstOptionsChanged, setSstOptionsChanged] = useState(false);
    const submitButtonClassNames = classnames({
        primary: true,
        loading: useSelector(selectIsSavingSettings),
    });
    const isLoading = useSelector(selectIsLoading);
    const certificateRequestData = useSelector(selectCertificateRequest, shallowEqual);
    const isEligibleUserSettingsGeneral = dispatch(isEligibleUser(featureFlag.settings.general));
    const [isManagedServiceUser, setIsManagedServiceUser] = useState(false);

    const handleCheckboxChange = setter => {
        return event => {
            if (event.target.name === "collectSst" || event.target.name === "requireSst") {
                setSstOptionsChanged(true);
                if (event.target.name === "requireSst" && event.target.checked) {
                    setRequireSst(true);
                    setCollectSst(true);
                } else if (event.target.name === "collectSst" && !event.target.checked) {
                    setRequireSst(false);
                    setCollectSst(false);
                } else {
                    setter(event.target.checked);
                }
            } else {
                setter(event.target.checked);
            }
        };
    };

    const handleManagedServiceUser = setter => {
        return event => {
            setter(event.target.checked);
        };
    };

    useEffect(() => {
        dispatch(getUserInfo(session?.userId));
    }, [session?.userId]);

    useEffect(() => {
        dispatch(fetchCertificateRequestsAPI());
        return () => {
            dispatch(setCertificateRequest([]));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const cancelHandler = () => {
        window.location.reload();
    };

    useEffect(() => {
        setIsManagedServiceUser(userInfo.userRoles?.some(x => x.role === ecmRole.MSUser));
    }, [JSON.stringify(userInfo.userRoles)]);

    const handleSubmitRequest = async event => {
        event.preventDefault();
        if (isManagedServiceUser) {
            dispatch(updateMSUserRole(session?.userId));
        } else {
            dispatch(deleteMSUserRole(session?.userId));
        }

        const putData = {
            ...(sstOptionsChanged
                ? {
                      collectSst,
                      requireSst,
                      requireTaxId: (collectSst && requireSst) || collectSst,
                  }
                : {}),
            usesManagedServices: usesManagedService,
        };
        dispatch(putGeneralSettings(putData));
        dispatch(getUserInfo(session?.userId));
    };

    const handleBulkPdfRebuild = async event => {
        event.preventDefault();
        await dispatch(postBulkPdfRebuild());
    };

    let internalUserSettingDOM = null;

    if (isLoading || certificateRequestData.length === 0) {
        internalUserSettingDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" aria-live="polite" />
            </div>
        );
    } else {
        internalUserSettingDOM = (
            <div>
                <Helmet>
                    <title>{dispatch(getPageTitle("Settings : CertExpress settings"))}</title>
                </Helmet>
                <SRow>
                    <SCol>
                        <h3 className="margin-bottom-none">Managed Services Flag</h3>

                        <input
                            id="usesManagedServices"
                            name="usesManagedServices"
                            type="checkbox"
                            aria-label="Track certificate validation and requests for case management and usage
                            purposes"
                            checked={usesManagedService}
                            onChange={handleCheckboxChange(setUsesManagedServices)}
                        />
                        <label htmlFor="usesManagedServices">
                            Track certificate validation and requests for case management and usage
                            purposes
                        </label>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        <h3 className="margin-bottom-none">Streamlined sales tax (SST) options</h3>
                        <input
                            id="collectSst"
                            name="collectSst"
                            type="checkbox"
                            aria-label="Make SST forms available to SST customers in CertExpress"
                            checked={collectSst}
                            onChange={handleCheckboxChange(setCollectSst)}
                        />
                        <label htmlFor="collectSst">
                            Make SST forms available to SST customers in CertExpress
                        </label>
                        <br />
                        <input
                            id="requireSst"
                            name="requireSst"
                            type="checkbox"
                            aria-label="Require an SST form for states that support SST"
                            checked={requireSst}
                            onChange={handleCheckboxChange(setRequireSst)}
                        />
                        <label htmlFor="requireSst">
                            Require an SST form for states that support SST
                        </label>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        <h3 className="margin-bottom-none">Managed services user </h3>
                        {isUserInfoLoading ? (
                            <SLoader
                                id="page-loader"
                                className="medium"
                                aria-live="polite"
                                loading
                            />
                        ) : (
                            <>
                                <input
                                    id="managed-service-user"
                                    name="managed-service-user"
                                    type="checkbox"
                                    checked={isManagedServiceUser}
                                    onChange={handleManagedServiceUser(setIsManagedServiceUser)}
                                />
                                <label htmlFor="managed-service-user">
                                    Current user is a managed services user
                                </label>
                            </>
                        )}
                    </SCol>
                </SRow>

                <SRow>
                    <SCol>
                        <h3 className="margin-bottom-none">Rebuild certificate image previews</h3>
                    </SCol>
                    <SCol>
                        <label htmlFor="description">
                            Submit a rebuild request when certificate image previews are visible in
                            CertCapture but not ECM. Only certificates in this company without a
                            preview will be rebuilt.
                        </label>
                    </SCol>
                    <SCol>
                        <button
                            id="rebuild-preview"
                            type="button"
                            className="tertiary"
                            name="rebuild-previews-btn"
                            onClick={handleBulkPdfRebuild}>
                            Rebuild
                        </button>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="12">
                        <hr className="margin-top-xl margin-bottom-lg" />
                        <button
                            id="save-client"
                            type="button"
                            className={submitButtonClassNames}
                            onClick={handleSubmitRequest}
                            disabled={!isEligibleUserSettingsGeneral}
                            name="stop-apply-btn">
                            Save
                        </button>

                        <button className="tertiary" type="button" onClick={cancelHandler}>
                            Cancel
                        </button>
                    </SCol>
                </SRow>
            </div>
        );
    }
    return <React.Fragment> {internalUserSettingDOM} </React.Fragment>;
});

export default InternalUserSettings;
