import React from "react";
import classnames from "classnames";
import "./tooltip.css";

function Tooltip(props) {
    const toolTipclassName = classnames({
        tooltiptext: true,
        "tooltiptext-right": props?.columnPositionRight,
        "tooltiptext-left": !props?.columnPositionRight,
    });
    return <span className={toolTipclassName}>{props.tooltipData}</span>;
}

export default Tooltip;
