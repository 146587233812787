import React, { useState, useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SSelect, SRow, SCol } from "@avalara/skylab-react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "../../hooks/toast";
import {
    selectSubjectId,
    postUserRoleInfo,
    putUserRoleInfo,
    selectIsPutPostLoading,
    setIsEditClicked,
    selectUserInfoRole,
    selectUserLocationsInfo,
} from "../../app/roleManagementSlice";
import { roleOptionList } from "../../shared/constants";
import { ecmRole } from "../navigation/Subscription";
import "./roleManagement.css";
import SelectRetailLocation from "./SelectRetailLocation";
import FeatureToggler from "../../featureToggler/FeatureToggler";
import featureFlag from "../../featureToggler/featureFlag";
import { isEligibleUser } from "../../shared/sessionUtility";

function RolePermissionAll() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRoles = useSelector(selectUserInfoRole, shallowEqual);
    const userLocationsInfo = useSelector(selectUserLocationsInfo, shallowEqual);
    const subjectId = useSelector(selectSubjectId);
    const isLoading = useSelector(selectIsPutPostLoading);
    const [locations, setLocations] = useState([]);
    const { userId } = useParams();
    const [currentRole, setCurrentRole] = useState(
        userRoles !== null ? userRoles[0].role : ecmRole.CEMAdmin
    );
    const [showToast] = toast();
    const isUserCanAssignRetailLocation = dispatch(
        isEligibleUser(featureFlag.userRoleManagement.assignRetailLocation)
    );

    useEffect(() => {
        if (userLocationsInfo?.[0]?.locationIds?.length && userLocationsInfo[0]?.isAllCompany) {
            setLocations(userLocationsInfo[0]?.locationIds);
        }
    }, [JSON.stringify(userLocationsInfo?.[0]?.locationIds)]);

    const saveRole = async () => {
        const roleInfo = [
            {
                role: currentRole,
                companyIds: [],
                isAllCompany: true,
            },
        ];

        const locationInfo =
            locations.length > 0
                ? [
                      {
                          companyId: null,
                          locationIds: locations,
                          isAllCompany: true,
                      },
                  ]
                : null;
        if (userRoles === null)
            await dispatch(postUserRoleInfo(subjectId, userId, roleInfo, locationInfo, showToast));
        else await dispatch(putUserRoleInfo(subjectId, userId, roleInfo, locationInfo, showToast));
    };

    const handleCancel = () => {
        if (userRoles !== null) dispatch(setIsEditClicked(false));
        else navigate("/overview");
    };

    const addRetailLocation = useCallback(item => {
        setLocations(prevVal => [...prevVal, item.value]);
    }, []);

    const removeRetailLocation = useCallback(item => {
        setLocations(prevVal => {
            const filteredLocations = prevVal.filter(value => value !== item.value);
            return filteredLocations;
        });
    }, []);

    return (
        <>
            <SRow className="pad-left-sm pad-top-sm">
                <label className="text-md">Role</label>
            </SRow>
            <SRow className="pad-left-sm">
                <span className="text-xs-strong pad-top-none">Default permission</span>
            </SRow>
            <SRow className="pad-left-sm">
                <span>This user role applies to all the companies the user has access to.</span>
            </SRow>
            <SRow className="pad-bottom-xs">
                <SCol span="3">
                    <SSelect
                        inputId="all-company-role-select"
                        aria-label="This user role applies to all the companies the user has access to"
                        selectionOptional={false}
                        optionsList={roleOptionList.map(element => {
                            return {
                                label: element.label,
                                value: element.value,
                                selected: currentRole !== null && currentRole === element.value,
                            };
                        })}
                        onS-select={e => setCurrentRole(e.detail.item.value)}
                    />
                </SCol>
            </SRow>

            <FeatureToggler category="userRoleManagement" id="assignRetailLocation">
                <SRow className="pad-left-sm pad-top-sm">
                    <label id="lbl-location" className="text-md" htmlFor="default-location-select">
                        Retail Location
                    </label>
                </SRow>
                <SRow className="pad-left-sm">
                    <span>Assign location separately by company.</span>
                </SRow>
                <SRow>
                    <SCol span="3">
                        <SelectRetailLocation
                            value={JSON.stringify(locations)}
                            name="Default-retail-locations"
                            onAdd={e => addRetailLocation(e)}
                            onRemove={e => removeRetailLocation(e)}
                            multiple
                            disabled={!isUserCanAssignRetailLocation}
                        />
                    </SCol>
                </SRow>
            </FeatureToggler>

            <hr />
            <SRow className="pad-left-sm pad-top-xs">
                <button
                    id="save-role"
                    className={isLoading ? "primary loading" : "primary"}
                    onClick={saveRole}
                    disabled={currentRole === ""}>
                    Save
                </button>
                <button className="tertiary" onClick={handleCancel}>
                    Cancel
                </button>
            </SRow>
        </>
    );
}

export default RolePermissionAll;
