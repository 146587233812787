import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SDialog } from "@avalara/skylab-react";
import {
    addOrUpdateRetailLocations,
    selectIsAddOrEditLoading,
} from "../../../../app/retailLocationSlice";
import EditRetailSiteSetting from "./EditRetailSiteSetting";
import "./retailLocation.css";

function EditRetailSettingDialog(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsAddOrEditLoading);
    const { location } = props;
    const [acceptedRegions, setAcceptedRegions] = useState(
        location?.acceptedRegions.map(item => item.stateId)
    );
    const [useStateAsJurisdiction, setUseStateAsJurisdiction] = useState(
        location.useStateAsJurisdiction
    );
    const [useLocationAsJurisdiction, setUseLocationAsJurisdiction] = useState(
        location.useLocationAsJurisdiction
    );
    const [enableCustomerRefId, setEnableCustomerRefId] = useState(location.enableCustomerRefId);
    const [acceptedRegionsError, setAcceptedRegionsError] = useState("");

    const onCancel = () => {
        props.setLocation({});
        props.setOpenEditSettingsDialog(false);
    };

    const checkValidation = () => {
        let valid = true;
        if (useStateAsJurisdiction && (!acceptedRegions || acceptedRegions?.length === 0)) {
            setAcceptedRegionsError("Enter a state");
            valid = false;
        }
        return valid;
    };

    const onSave = async () => {
        if (checkValidation()) {
            const payload = {
                useStateAsJurisdiction,
                useLocationAsJurisdiction,
                acceptedRegions,
                enableCustomerRefId,
            };
            await dispatch(addOrUpdateRetailLocations("Edit", payload, location.id));
            props.setLocation({});
            props.setOpenEditSettingsDialog(false);
        }
    };
    return (
        <SDialog
            id="editRetailSettings-dialog"
            open={props.openEditSettingsDialog}
            onS-dismiss={onCancel}
            className="retail-dialog-width"
            aria-modal="true">
            <div slot="header" id="editRetailSettings">
                {location?.name}
            </div>
            <div slot="body">
                <EditRetailSiteSetting
                    useLocationAsJurisdiction={useLocationAsJurisdiction}
                    useStateAsJurisdiction={useStateAsJurisdiction}
                    acceptedRegions={acceptedRegions}
                    enableCustomerRefId={enableCustomerRefId}
                    setUseLocationAsJurisdiction={setUseLocationAsJurisdiction}
                    setUseStateAsJurisdiction={setUseStateAsJurisdiction}
                    setEnableCustomerRefId={setEnableCustomerRefId}
                    setAcceptedRegions={setAcceptedRegions}
                    acceptedRegionsError={acceptedRegionsError}
                    setAcceptedRegionsError={setAcceptedRegionsError}
                />
            </div>
            <div slot="footer">
                <button
                    className={isLoading ? "primary right loading" : "primary right"}
                    id="save-label"
                    type="button"
                    onClick={onSave}>
                    Save
                </button>
            </div>
        </SDialog>
    );
}

export default EditRetailSettingDialog;
