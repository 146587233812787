import React, { useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuiUploader, SLoader, SIcon } from "@avalara/skylab-react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ImportErrorDialog from "./ImportErrorDialog";
import axios from "../../axios";
import toast from "../../hooks/toast";
import { selectSession } from "../../app/sessionSlice";
import { getPageTitle, getSessionAccessToken } from "../../shared/sessionUtility";
import { advanceSubscription } from "../navigation/Subscription";
import { matchSubscriptions, buildApiV2Point5Url } from "../../shared/Utils";
import getConfig from "../../config";

const { fvsConfigurationId } = getConfig();

const ImportCustomers = React.memo(() => {
    // eslint-disable-next-line no-unused-vars
    const [submitting, setSubmitting] = useState(false);
    const [showToast, hideToast] = toast();
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState("");
    const [errorFile, setErrorFile] = useState("");
    const [isImportErrorDialogOpen, setIsImportErrorDialogOpen] = useState(false);
    const session = useSelector(selectSession, shallowEqual);
    const [uploadCertificateEvent, setUploadCertificateEvent] = useState("");
    const auiFileUploaderRef = React.createRef();
    const postImportAPI = async file => {
        const formData = new FormData();
        formData.append("file", file);

        return axios.post(buildApiV2Point5Url(`customers/bulk-import`), formData, {
            withCredentials: true,
        });
    };

    const getImportAPI = async jobId => {
        return axios.get(buildApiV2Point5Url(`customers/bulk-import/${jobId}`), {
            withCredentials: true,
        });
    };

    const sleep = () => new Promise(resolve => setInterval(resolve, 2000));

    const resetAuiRef = () => {
        if (auiFileUploaderRef.current) {
            auiFileUploaderRef.current.reset();
        }
        const uploader = document.getElementById("aui-uploader");
        if (uploader) {
            uploader.reset();
        }
    };

    const checkStatusUntilCompleted = async jobId => {
        const response = await getImportAPI(jobId);
        const pollerResponse = response.data.status;
        if (!["Success", "Errors found"].includes(pollerResponse)) {
            await sleep();
            checkStatusUntilCompleted(jobId);
        } else {
            if (pollerResponse === "Success") {
                showToast(
                    "success",
                    "Exempt customers uploaded successfully.",
                    "import-success",
                    true,
                    "Go to customer search",
                    () => {
                        hideToast("import-success");
                        navigateTo("/search/customers");
                    }
                );
            } else {
                setErrorFile(response.data.downloadLink);
                setIsImportErrorDialogOpen(true);
            }
            setSubmitting(false);
            resetAuiRef();
        }
    };

    const uploadFile = async event => {
        setSubmitting(true);
        const response = await postImportAPI(event.detail.files[0]);

        if (response?.response?.status === 400) {
            showToast("error", response.response.data.message);
            resetAuiRef();
        } else if (!response.data.success) {
            showToast("error", "Failed to queue bulk import.");
            resetAuiRef();
        } else {
            const { jobId } = response.data;
            checkStatusUntilCompleted(jobId);
        }
    };

    const handleAttachFunc = event => {
        setFileName(event.detail.files[0].name);
        setUploadCertificateEvent(event);
    };

    const handleValidateFunc = event => {
        const { filesPassedValidation, filesFailedValidation } = event.detail;
        if (filesPassedValidation.length > 0) {
            uploadFile(uploadCertificateEvent);
        }
        if (filesFailedValidation.length > 0) {
            showToast("error", `${filesFailedValidation[0].message}`);
            resetAuiRef();
        }
    };

    const handleErrorEvent = useCallback(() => {
        resetAuiRef();
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Import customers"))}</title>
            </Helmet>
            <div>
                <h1>Import customer {session.baseSubscription ? "and certificate" : ""} data</h1>
                <p className="margin-bottom-xl">
                    Download a template and place your
                    {!session.baseSubscription ? " customer" : ""} data in the correct columns.
                </p>
                {session.baseSubscription ? (
                    <Link to="/assets/ImportExemptionsToolkit.zip" target="_blank" download>
                        <SIcon name="download" aria-hidden="true" />
                        <span className="margin-left-xs font-semibold">
                            Download a template and place your data in the correct columns.
                        </span>
                    </Link>
                ) : (
                    <React.Fragment>
                        <span role="separator" aria-orientation="vertical" />
                        <a
                            href="https://help.avalara.com/Avalara_Exemption_Certificate_Management_Pro/Import_customers"
                            target="_blank"
                            className="text-underline divider-right"
                            rel="noreferrer">
                            <span className="margin-left-xs margin-right-sm">
                                View the import guidelines
                            </span>
                        </a>
                        <span role="separator" aria-orientation="vertical" />
                        <Link
                            to="/assets/customer_upload_template_basic.xlsx"
                            target="_blank"
                            download
                            className="margin-left-sm">
                            <SIcon name="download" aria-hidden="true" />
                            <span className="font-semibold margin-left-xs margin-right-sm">
                                Customers template
                            </span>
                        </Link>
                        <Link
                            to="/assets/customer_upload_template.xlsx"
                            target="_blank"
                            hidden={
                                !matchSubscriptions(advanceSubscription, session?.subscriptions)
                            }
                            download
                            className="divider-left margin-sm">
                            <SIcon name="download" className="margin-left-sm" aria-hidden="true" />
                            <span className="margin-left-xs font-semibold margin-left-xs">
                                Customers with bill-to and ship-to relationships template
                            </span>
                        </Link>
                    </React.Fragment>
                )}
                <div className="margin-top-xl">
                    <AuiUploader
                        id="aui-uploader"
                        ref={auiFileUploaderRef}
                        callbackUrl="/"
                        onS-attach={e => {
                            handleAttachFunc(e);
                        }}
                        onS-validate={handleValidateFunc}
                        onS-error={handleErrorEvent}
                        stagingExternalCta={false}
                        stagingInlineCta={false}
                        multiple={false}
                        accept=".xlsx"
                        configurationId={fvsConfigurationId}
                        requestCurrentAccessToken={getSessionAccessToken}>
                        <div slot="guidelines">
                            <div>
                                Files can be up to 20MB and include up to 60,000 rows of{" "}
                                {session.baseSubscription ? "certificate" : ""} data.
                            </div>
                        </div>
                        <div
                            slot="loading"
                            className="flex flex-dir-col align-items-center pad-all-lg">
                            <SLoader
                                loading="loading"
                                className="margin-bottom-sm"
                                aria-live="polite"
                            />
                            <h3 className="margin-all-none">Currently importing {fileName}</h3>
                            <p className="margin-all-none">This import may take a few minutes.</p>
                        </div>
                    </AuiUploader>
                </div>
            </div>

            <ImportErrorDialog
                isImportErrorDialogOpen={isImportErrorDialogOpen}
                setIsImportErrorDialogOpen={setIsImportErrorDialogOpen}
                errorFile={errorFile}
            />
        </React.Fragment>
    );
});

export default ImportCustomers;
