import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import toast from "../../../hooks/toast";
import { selectSession } from "../../../app/sessionSlice";
import { buildApiV3Url } from "../../../shared/Utils";

function VendorPackagesDeleteDialog(props) {
    const [isLoading, setIsLoading] = useState(false);
    const deleteButton = classnames({ primary: true, small: true, loading: isLoading });
    const [showToast] = toast();
    const session = useSelector(selectSession);
    const companyId = session.activeCompany.id;
    const deletePackage = async () => {
        setIsLoading(true);
        try {
            await axios
                .delete(
                    buildApiV3Url(`companies/${companyId}/vendor-packages/${props.packageId}`),
                    { withCredentials: true }
                )
                .then(() => {
                    showToast("success", "Successfully deleted certificate package");
                    props.reloadPage();
                });
        } catch {
            showToast("error", "Failed to delete the certificate package");
        }
        setIsLoading(false);
        props.closePaDelDiag(false);
    };

    return (
        <SDialog
            id="packageFullDelete-dialog"
            open={props.openPaDelDiag}
            onS-dismiss={() => {
                props.closePaDelDiag(false);
            }}
            aria-modal="true">
            <div slot="header" id="packageFullDelete">
                Delete the certificate package?
            </div>
            <div slot="body">
                <span>You can&apos;t undo this action</span>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    disabled={isLoading}
                    onClick={() => props.closePaDelDiag(false)}>
                    No, cancel
                </button>
                <button className={deleteButton} type="button" onClick={() => deletePackage()}>
                    Yes, delete
                </button>
            </div>
        </SDialog>
    );
}

export default VendorPackagesDeleteDialog;
