import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SIcon, SDialog, SBox } from "@avalara/skylab-react";
import classnames from "classnames";
import { deleteCertificateCommentAsync, selectIsDeletingComment } from "../../app/certificateSlice";

function DeleteCommentDialog(props) {
    const isDeletingComment = useSelector(selectIsDeletingComment);
    const deleteButton = classnames({ primary: true, small: true, loading: isDeletingComment });
    const dispatch = useDispatch();
    const { certificateID, commentId } = props;
    const deleteComment = async () => {
        await dispatch(deleteCertificateCommentAsync(certificateID, commentId));
        props.setIsDeleteCommentDialogOpen(false);
    };
    return (
        <SDialog
            id="deleteComment-dialog"
            open={props.isDeleteCommentDialogOpen}
            onS-dismiss={() => {
                props.setIsDeleteCommentDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="deleteComment">
                Delete the comment?
            </div>
            <div slot="body">
                <SBox className="yellow">
                    <table className="borderless" role="presentation">
                        <tbody>
                            <tr>
                                <td>
                                    <SIcon name="flag" className="medium" aria-hidden="true" />
                                </td>
                                <td>
                                    <span>You can&apos;t undo this action</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </SBox>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    disabled={isDeletingComment}
                    onClick={() => props.setIsDeleteCommentDialogOpen(false)}>
                    No, cancel
                </button>
                <button className={deleteButton} type="button" onClick={() => deleteComment()}>
                    Yes, delete
                </button>
            </div>
        </SDialog>
    );
}

export default DeleteCommentDialog;
