import React, { useEffect, useState } from "react";
import { SRow, SCol, SLoader } from "@avalara/skylab-react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaxLaws, selectIsLoading } from "../../../app/contentSlice";
import { getPageTitle } from "../../../shared/sessionUtility";
import SelectState from "../../sharedComponents/SelectState";
import TaxLawsList from "./TaxLawsList";
import TaxLawsContent from "./TaxLawContent";

export const header = "Avalara tax laws";

const TaxLaws = React.memo(() => {
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);
    const [selectedStateInitial, setStateInitial] = useState("AL");

    useEffect(() => {
        dispatch(fetchTaxLaws(selectedStateInitial));
    }, [dispatch]);

    const handleStateChange = value => {
        if (value && selectedStateInitial !== value.initials) {
            setStateInitial(value.initials);
            dispatch(fetchTaxLaws(value.initials));
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Tax Laws"))}</title>
            </Helmet>

            <h1 className="line-height-md pad-bottom-sm">{header}</h1>
            <SRow>
                <SCol span={3}>
                    <SelectState
                        onAdd={handleStateChange}
                        multiple={false}
                        disabled={false}
                        value={selectedStateInitial}
                        USStates
                        label="Region"
                        selectionOptional={false}
                    />
                </SCol>
            </SRow>
            {loading ? (
                <SRow>
                    <div className="flex dl-flex-fill-height dl-flex-center">
                        <h3>Loading ...</h3>
                        <SLoader id="page-loader" className="medium" aria-live="polite" loading />
                    </div>
                </SRow>
            ) : (
                <SRow>
                    <SCol span="3">
                        <TaxLawsList />
                    </SCol>
                    <SCol span="9">
                        <TaxLawsContent />
                    </SCol>
                </SRow>
            )}
        </React.Fragment>
    );
});
export default TaxLaws;
