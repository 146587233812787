import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import {
    getCustomerLabels,
    selectCustomerLabels,
    selectIsLoadingCustomerLabels,
    setCustomerLabels,
    selectLabels,
} from "../../app/customerSlice";

function CustomerLabel(props) {
    const dispatch = useDispatch();
    const selectRef = React.createRef();
    const isLoading = useSelector(selectIsLoadingCustomerLabels);
    const selectedLabels = useSelector(selectLabels, shallowEqual);
    const optionsList = useSelector(selectCustomerLabels, shallowEqual).map(element => {
        const selectOption = {
            label: element.name,
            value: element.id,
            selected: selectedLabels.some(x => {
                return x.id === element.id;
            }),
        };
        return selectOption;
    });

    useEffect(() => {
        dispatch(getCustomerLabels(props.customerID));

        return () => {
            dispatch(setCustomerLabels([]));
        };
    }, [dispatch, props.customerID]);

    return (
        <React.Fragment>
            <label htmlFor="customerLabel" className="margin-top-sm" id="lbl-customerLabel">
                Customer labels
            </label>
            <SSelect
                name="customerLabel"
                inputId="customerLabel"
                ref={selectRef}
                multiple
                selectionOptional
                loading={isLoading}
                optionsList={optionsList}
                onS-select={e => {
                    props.updateCustomLabelPostData(e, true, selectRef);
                }}
                onS-deselect={e => {
                    props.updateCustomLabelPostData(e, false, selectRef);
                }}
            />
        </React.Fragment>
    );
}

export default React.memo(CustomerLabel);
