import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { checkSelectComponentProps, noErrorTag } from "../../../shared/Utils";

import {
    fetchExpirationMatrixExposureZones,
    selectIsLoadingMatrixExposureZones,
    selectMatrixExposureZoneData,
} from "../../../app/exemptionMatrixSlice";

const SelectPackageZone = React.memo(props => {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingMatrixExposureZones);
    const selectPackageRef = React.createRef();

    const packageZones = useSelector(selectMatrixExposureZoneData).map(element => {
        const selectOption = {
            label: element.region.name,
            value: element.region.id,
            selected: false,
        };
        if (
            props.value?.includes(element.region.name) ||
            props.value?.includes(element.region.id)
        ) {
            selectOption.selected = true;
        }
        return selectOption;
    });

    checkSelectComponentProps("SelectPackageRegion", props);

    useEffect(() => {
        dispatch(fetchExpirationMatrixExposureZones());
    }, [dispatch]);

    return (
        <React.Fragment>
            <label className="required" htmlFor={getNextId()} id="lbl-packageRegion">
                Region covered by this certificate
            </label>
            <SSelect
                name="packageRegion"
                inputId={getCurrentId()}
                ref={selectPackageRef}
                optionsList={packageZones}
                loading={isLoading}
                onS-open={props.forceDisable}
                onS-select={e => props.onAdd(e.detail.item)}
                onS-close={props.onToggle}
                onS-deselect={e => props.onRemove(e.detail.item)}
                className={props.zoneError || noErrorTag}
                noSearch
                multiple
            />
        </React.Fragment>
    );
});
export default SelectPackageZone;
