import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SRow, SCol, SSelect, SLoader } from "@avalara/skylab-react";
import {
    getCustomFields,
    selectCustomFields,
    selectIsLoadingCertificateCustomFields,
    selectCertificateExistingCustomFieldValues,
    setCertificateExistingCustomFieldValues,
    selectCertificateAddedCustomFieldValues,
    setCertificateAddedCustomFieldValues,
} from "../../../app/certificateSlice";
import { getLabelForCustomField } from "../../../shared/Utils";

function CertificateCustomField(props) {
    const dispatch = useDispatch();
    const certificateCustomFields = useSelector(selectCustomFields, shallowEqual);
    const isLoading = useSelector(selectIsLoadingCertificateCustomFields);
    const certificateExistingCustomFieldValues = useSelector(
        selectCertificateExistingCustomFieldValues,
        shallowEqual
    );
    const certificateAddedCustomFieldValues = useSelector(
        selectCertificateAddedCustomFieldValues,
        shallowEqual
    );

    useEffect(() => {
        dispatch(getCustomFields());
    }, [dispatch]);

    useEffect(() => {
        if (certificateCustomFields) {
            certificateCustomFields.forEach(certificateCustomField => {
                if (certificateCustomField.possibleValues) {
                    const splitPossibleValues = certificateCustomField.possibleValues.split("|");

                    const customFieldOptions = splitPossibleValues.map(element => {
                        return {
                            label: getLabelForCustomField(certificateCustomField.type, element),
                            value: element,
                            selected:
                                props.searchValues &&
                                props.searchValues[certificateCustomField.id] &&
                                props.searchValues[certificateCustomField.id].includes(element),
                        };
                    });

                    const customFieldSelect = document.querySelector(
                        `s-select[inputid='${certificateCustomField.id}']`
                    );

                    if (customFieldSelect) {
                        customFieldSelect.optionsList = customFieldOptions;
                    }
                }
            });
        }
    }, [certificateCustomFields]);

    const updateCertificateCustomFields = (e, type, add = true, isDeselectAll, valueExists) => {
        let value = null;
        if (isDeselectAll) {
            value = null;
        } else if (type === "multi-select") {
            if (add) {
                if (valueExists) {
                    value = certificateExistingCustomFieldValues[e.target.id]
                        ? [
                              ...certificateExistingCustomFieldValues[e.target.id],
                              e.detail.item.value,
                          ]
                        : [e.detail.item.value];
                } else
                    value = certificateAddedCustomFieldValues[e.target.id]
                        ? [...certificateAddedCustomFieldValues[e.target.id], e.detail.item.value]
                        : [e.detail.item.value];
            } else if (valueExists) {
                const filteredValue = certificateExistingCustomFieldValues[e.target.id].filter(
                    item => {
                        return item !== e.detail.item.value;
                    }
                );
                value = filteredValue;
            } else {
                const filteredValue = certificateAddedCustomFieldValues[e.target.id].filter(
                    item => {
                        return item !== e.detail.item.value;
                    }
                );
                value = filteredValue;
            }
        } else if (type === "select" || type === "boolean") {
            value = e.detail.item.value;
        } else {
            value = e.target.value;
        }

        if (valueExists) {
            dispatch(setCertificateExistingCustomFieldValues({ [e.target.id]: value }));
        } else {
            dispatch(setCertificateAddedCustomFieldValues({ [e.target.id]: value }));
        }
        props.updateCustomFieldPostData(e.target.id, value);
    };

    return (
        <React.Fragment>
            {isLoading && (
                <SRow className="margin-top-sm">
                    <SCol>
                        <SLoader loading="true" aria-live="polite" />
                    </SCol>
                </SRow>
            )}

            {certificateCustomFields.map(certificateCustomField => (
                <SRow key={certificateCustomField.id}>
                    <SCol span="12">
                        {certificateCustomField.type === "text" ? (
                            <React.Fragment>
                                <label
                                    className="margin-top-sm"
                                    id={`lbl-certificate-custom-field-${certificateCustomField.id}`}>
                                    {certificateCustomField.fieldName}
                                </label>
                                <input
                                    id={certificateCustomField.id}
                                    aria-labelledby={`lbl-certificate-custom-field-${certificateCustomField.id}`}
                                    type="text"
                                    onBlur={e => updateCertificateCustomFields(e, "input")}
                                    key={
                                        props.searchValues &&
                                        props.searchValues[certificateCustomField.id]
                                            ? props.searchValues[certificateCustomField.id]
                                            : ""
                                    }
                                    defaultValue={
                                        props.searchValues &&
                                        props.searchValues[certificateCustomField.id]
                                            ? props.searchValues[certificateCustomField.id]
                                            : ""
                                    }
                                    disabled={props.loading === true}
                                />
                            </React.Fragment>
                        ) : null}
                        {certificateCustomField.type === "select" ||
                        certificateCustomField.type === "boolean" ? (
                            <React.Fragment>
                                <label
                                    className="margin-top-sm"
                                    id={`lbl-certificate-custom-field-${certificateCustomField.id}`}
                                    htmlFor={certificateCustomField.id}>
                                    {certificateCustomField.fieldName}
                                </label>
                                <SSelect
                                    id={certificateCustomField.id}
                                    inputid={certificateCustomField.id}
                                    aria-labelledby={`lbl-certificate-custom-field-${certificateCustomField.id}`}
                                    selectionOptional
                                    disabled={props.loading ? "disabled" : null}
                                    onS-select={e => {
                                        updateCertificateCustomFields(
                                            e,
                                            certificateCustomField.type,
                                            true,
                                            false,
                                            certificateCustomField.exists
                                        );
                                    }}
                                    onS-deselect={e => {
                                        if (e.detail.isDeselectAll) {
                                            updateCertificateCustomFields(
                                                e,
                                                certificateCustomField.type,
                                                false,
                                                e.detail.isDeselectAll,
                                                certificateCustomField.exists
                                            );
                                        } else if (!e.detail.isDeselectAll) {
                                            updateCertificateCustomFields(
                                                e,
                                                certificateCustomField.type,
                                                false,
                                                e.detail.isDeselectAll,
                                                certificateCustomField.exists
                                            );
                                        }
                                    }}
                                />
                            </React.Fragment>
                        ) : null}

                        {certificateCustomField.type === "multi-select" ? (
                            <React.Fragment>
                                <label
                                    className="margin-top-sm"
                                    id={`lbl-certificate-custom-field-${certificateCustomField.id}`}
                                    htmlFor={certificateCustomField.id}>
                                    {certificateCustomField.fieldName}
                                </label>
                                <SSelect
                                    id={certificateCustomField.id}
                                    inputid={certificateCustomField.id}
                                    aria-labelledby={`lbl-certificate-custom-field-${certificateCustomField.id}`}
                                    multiple
                                    selectionOptional
                                    disabled={props.loading ? "disabled" : null}
                                    onS-select={e => {
                                        updateCertificateCustomFields(
                                            e,
                                            "multi-select",
                                            true,
                                            false,
                                            certificateCustomField.exists
                                        );
                                    }}
                                    onS-deselect={e => {
                                        if (e.detail.isDeselectAll) {
                                            updateCertificateCustomFields(
                                                e,
                                                "multi-select",
                                                false,
                                                e.detail.isDeselectAll,
                                                certificateCustomField.exists
                                            );
                                        } else if (!e.detail.isDeselectAll) {
                                            updateCertificateCustomFields(
                                                e,
                                                "multi-select",
                                                false,
                                                e.detail.isDeselectAll,
                                                certificateCustomField.exists
                                            );
                                        }
                                    }}
                                />
                            </React.Fragment>
                        ) : null}
                    </SCol>
                </SRow>
            ))}
        </React.Fragment>
    );
}

export default React.memo(CertificateCustomField);
