import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SRow, SCol, SSelect, SLoader } from "@avalara/skylab-react";
import {
    getCustomFields,
    selectCustomFields,
    selectIsLoadingCustomerCustomFields,
    selectCustomerExistingCustomFieldValues,
    setCustomerExistingCustomFieldValues,
    selectCustomerAddedCustomFieldValues,
    setCustomerAddedCustomFieldValues,
} from "../../../app/customerSlice";
import { getLabelForCustomField } from "../../../shared/Utils";

function CustomerCustomField(props) {
    const dispatch = useDispatch();
    const customerCustomFields = useSelector(selectCustomFields, shallowEqual);
    const isLoading = useSelector(selectIsLoadingCustomerCustomFields);
    const customerExistingCustomFieldValues = useSelector(
        selectCustomerExistingCustomFieldValues,
        shallowEqual
    );
    const customerAddedCustomFieldValues = useSelector(
        selectCustomerAddedCustomFieldValues,
        shallowEqual
    );

    useEffect(() => {
        dispatch(getCustomFields());
    }, [dispatch]);

    useEffect(() => {
        if (customerCustomFields) {
            customerCustomFields.forEach(customerCustomField => {
                if (customerCustomField.possibleValues) {
                    const splitPossibleValues = customerCustomField.possibleValues.split("|");

                    const customFieldOptions = splitPossibleValues.map(element => {
                        return {
                            label: getLabelForCustomField(customerCustomField.type, element),
                            value: element,
                            selected:
                                props.searchValues &&
                                props.searchValues[customerCustomField.id] &&
                                props.searchValues[customerCustomField.id].includes(element),
                        };
                    });

                    const customFieldSelect = document.querySelector(
                        `s-select[inputid='${customerCustomField.id}']`
                    );

                    if (customFieldSelect) {
                        customFieldSelect.optionsList = customFieldOptions;
                    }
                }
            });
        }
    }, [customerCustomFields]);

    const updateCustomerCustomFields = (e, type, isDeselectAll, valueExists, add = true) => {
        let value = null;
        if (isDeselectAll) {
            value = null;
        } else if (type === "multi-select") {
            if (add) {
                if (valueExists) {
                    value = customerExistingCustomFieldValues[e.target.id]
                        ? [...customerExistingCustomFieldValues[e.target.id], e.detail.item.value]
                        : [e.detail.item.value];
                } else
                    value = customerAddedCustomFieldValues[e.target.id]
                        ? [...customerAddedCustomFieldValues[e.target.id], e.detail.item.value]
                        : [e.detail.item.value];
            } else if (valueExists) {
                const filteredValue = customerExistingCustomFieldValues[e.target.id].filter(
                    item => {
                        return item !== e.detail.item.value;
                    }
                );
                value = filteredValue;
            } else {
                const filteredValue = customerAddedCustomFieldValues[e.target.id].filter(item => {
                    return item !== e.detail.item.value;
                });
                value = filteredValue;
            }
        } else if (type === "select" || type === "boolean") {
            value = e.detail.item.value;
        } else {
            value = e.target.value;
        }

        if (valueExists) {
            dispatch(setCustomerExistingCustomFieldValues({ [e.target.id]: value }));
        } else {
            dispatch(setCustomerAddedCustomFieldValues({ [e.target.id]: value }));
        }
        props.updateCustomFieldPostData(e.target.id, value);
    };

    return (
        <React.Fragment>
            {isLoading && (
                <SRow className="margin-top-sm">
                    <SCol>
                        <SLoader loading="true" aria-live="polite" />
                    </SCol>
                </SRow>
            )}

            {customerCustomFields &&
                customerCustomFields.map(customerCustomField => (
                    <SRow key={customerCustomField.id}>
                        <SCol span="12">
                            {customerCustomField.type === "text" ? (
                                <React.Fragment>
                                    <label
                                        id={`lbl-customer-custom-field-${customerCustomField.id}`}
                                        className="margin-top-sm">
                                        {customerCustomField.fieldName}
                                    </label>
                                    <input
                                        id={customerCustomField.id}
                                        aria-labelledby={`lbl-customer-custom-field-${customerCustomField.id}`}
                                        type="text"
                                        onBlur={e => updateCustomerCustomFields(e, "input")}
                                        key={
                                            props.searchValues &&
                                            props.searchValues[customerCustomField.id]
                                                ? props.searchValues[customerCustomField.id]
                                                : ""
                                        }
                                        defaultValue={
                                            props.searchValues &&
                                            props.searchValues[customerCustomField.id]
                                                ? props.searchValues[customerCustomField.id]
                                                : ""
                                        }
                                        disabled={props.loading === true}
                                    />
                                </React.Fragment>
                            ) : null}
                            {customerCustomField.type === "select" ||
                            customerCustomField.type === "boolean" ? (
                                <React.Fragment>
                                    <label
                                        id={`lbl-customer-custom-field-${customerCustomField.id}`}
                                        htmlFor={customerCustomField.id}
                                        className="margin-top-sm">
                                        {customerCustomField.fieldName}
                                    </label>
                                    <SSelect
                                        id={customerCustomField.id}
                                        inputid={customerCustomField.id}
                                        aria-labelledby={`lbl-customer-custom-field-${customerCustomField.id}`}
                                        selectionOptional
                                        disabled={props.loading ? "disabled" : null}
                                        onS-select={e => {
                                            updateCustomerCustomFields(
                                                e,
                                                customerCustomField.type,
                                                false,
                                                customerCustomField.exists
                                            );
                                        }}
                                        onS-deselect={e => {
                                            if (e.detail.isDeselectAll) {
                                                updateCustomerCustomFields(
                                                    e,
                                                    customerCustomField.type,
                                                    e.detail.isDeselectAll,
                                                    customerCustomField.exists,
                                                    false
                                                );
                                            } else if (!e.detail.isDeselectAll) {
                                                updateCustomerCustomFields(
                                                    e,
                                                    customerCustomField.type,
                                                    e.detail.isDeselectAll,
                                                    customerCustomField.exists,
                                                    false
                                                );
                                            }
                                        }}
                                    />
                                </React.Fragment>
                            ) : null}

                            {customerCustomField.type === "multi-select" ? (
                                <React.Fragment>
                                    <label
                                        id={`lbl-customer-custom-field-${customerCustomField.id}`}
                                        htmlFor={customerCustomField.id}
                                        className="margin-top-sm">
                                        {customerCustomField.fieldName}
                                    </label>
                                    <SSelect
                                        id={customerCustomField.id}
                                        inputid={customerCustomField.id}
                                        aria-labelledby={`lbl-customer-custom-field-${customerCustomField.id}`}
                                        multiple
                                        selectionOptional
                                        disabled={props.loading ? "disabled" : null}
                                        onS-select={e => {
                                            updateCustomerCustomFields(
                                                e,
                                                "multi-select",
                                                false,
                                                customerCustomField.exists
                                            );
                                        }}
                                        onS-deselect={e => {
                                            if (e.detail.isDeselectAll) {
                                                updateCustomerCustomFields(
                                                    e,
                                                    "multi-select",
                                                    e.detail.isDeselectAll,
                                                    customerCustomField.exists,
                                                    false
                                                );
                                            } else if (!e.detail.isDeselectAll) {
                                                updateCustomerCustomFields(
                                                    e,
                                                    "multi-select",
                                                    e.detail.isDeselectAll,
                                                    customerCustomField.exists,
                                                    false
                                                );
                                            }
                                        }}
                                    />
                                </React.Fragment>
                            ) : null}
                        </SCol>
                    </SRow>
                ))}
        </React.Fragment>
    );
}

export default React.memo(CustomerCustomField);
