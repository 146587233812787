import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SRow, SCol, SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import {
    selectIsRelatedCustomerDialog,
    setIsRelatedCustomerDialog,
    selectCustomer,
    selectRelatedCustomer,
    setRelatedCustomer,
    linkChildCustomerAPI,
    linkParentCustomerAPI,
    selectIsLoadingShipTos,
    selectIsLoadingBillTos,
} from "../../../app/customerSlice";
import "../customer.css";
import toast from "../../../hooks/toast";
import CustomerTabTypeAhead from "../CustomerTabTypeAhead";

const [showToast] = toast();

function LinkCustomerDialog(props) {
    const dispatch = useDispatch();
    const dialogName = "linkCustomerDialog";
    const isDialogOpen = useSelector(selectIsRelatedCustomerDialog);
    const primaryCustomer = useSelector(selectCustomer, shallowEqual);
    const relatedCustomers = useSelector(selectRelatedCustomer, shallowEqual);
    const isLoadingShipTos = useSelector(selectIsLoadingShipTos);
    const isLoadingBillTos = useSelector(selectIsLoadingBillTos);
    const saveButton = classnames({
        primary: true,
        loading: props.linkDialogType === 1 ? isLoadingBillTos : isLoadingShipTos,
    });

    const [dialogTitle, setDialogTitle] = useState("No Title");
    const [typeAheadlabel, setTypeAheadlabel] = useState("");

    useEffect(() => {
        if (props?.linkDialogType) {
            if (props.linkDialogType === 1) {
                setDialogTitle("Link a bill-to customer");
                setTypeAheadlabel("Bill-to customer");
            } else if (props.linkDialogType === 2) {
                setDialogTitle("Link a ship-to customer");
                setTypeAheadlabel("Ship-to customer");
            }
        }
    }, [props.linkDialogType]);

    const addCustomer = value => {
        dispatch(setRelatedCustomer([...relatedCustomers, value[0].id]));
    };

    const removeCustomer = value => {
        const items = relatedCustomers.filter(item => item !== parseInt(value.value, 10));
        dispatch(setRelatedCustomer(items));
    };

    const closeDialog = () => {
        dispatch(setRelatedCustomer([]));
        dispatch(setIsRelatedCustomerDialog(false));
    };

    const linkCustomer = async () => {
        if (props.linkDialogType === 2) {
            dispatch(linkChildCustomerAPI(primaryCustomer.id, relatedCustomers, showToast));
        } else {
            dispatch(
                linkParentCustomerAPI(
                    primaryCustomer.id,
                    parseInt(relatedCustomers[0], 10),
                    showToast
                )
            );
        }
    };

    return (
        <SDialog
            id={dialogName}
            open={isDialogOpen}
            onS-dismiss={closeDialog}
            className="dialog-width"
            aria-modal="true">
            <div slot="header" id="linkCustomer">
                {dialogTitle}
            </div>
            <div slot="body" className="dialog-body">
                <SRow>
                    <SCol span="12">
                        <CustomerTabTypeAhead
                            multiple={false}
                            handleAdd={addCustomer}
                            handleRemove={removeCustomer}
                            value={relatedCustomers}
                            typeAheadlabel={typeAheadlabel}
                        />
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button className="secondary" onClick={closeDialog}>
                    Cancel
                </button>
                <button
                    className={saveButton}
                    type="button"
                    onClick={linkCustomer}
                    disabled={!relatedCustomers.length}>
                    Save
                </button>
            </div>
        </SDialog>
    );
}

export default LinkCustomerDialog;
