import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { SCol, SRow, SIcon } from "@avalara/skylab-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import "./SignaturePad.css";
import { isEmpty } from "lodash";
import SignatureValidationError from "./SignaturePadValidationError";
import SignatureDeleteDialog from "./SignaturePadDeleteDialog";

import {
    fetchSignatureData,
    selectSignatureData,
    saveSignatureData,
    updateSignatureData,
    setIsSignChange,
    setAddNewSignature,
    selectAddNewSignature,
} from "../../../app/settingsSlice";
import toast from "../../../hooks/toast";
import { isEligibleUser } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";
import { noErrorTag } from "../../../shared/Utils";

const SignaturePad = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const sigCanvas = useRef({});
    const defaultSigData = useSelector(selectSignatureData, shallowEqual);
    const [sigName, setSigName] = useState(defaultSigData?.name);
    const [sigTitle, setSigTitle] = useState(defaultSigData?.title);
    const [startingSig, setStartingSig] = useState(defaultSigData?.signature);
    const [isUpdate, setIsUpdate] = useState(false);
    const [showToast] = toast();
    const [titleError, setTitleError] = useState("");
    const [nameError, setNameError] = useState("");
    const [sigError, setSigError] = useState("");
    const [changedData, setChangedData] = useState(null);
    const [deleteId, setDeleteId] = useState("");
    const [activeDelete, setActiveDelete] = useState(false);
    const [isDeleteSignatureDialogOpen, setIsDeleteSignatureDialogOpen] = useState(false);
    const [customSignature, setCustomSignature] = useState(false);
    const addNewSignature = useSelector(selectAddNewSignature, shallowEqual);
    const isEligibleUserFlag = !dispatch(isEligibleUser(featureFlag.settings.vendorOptions));
    const navigate = useNavigate();

    const clearCanvas = () => {
        if (defaultSigData) {
            setIsUpdate(true);
        }
        dispatch(setIsSignChange(true));
        sigCanvas.current.clear();
    };

    const checkValidation = () => {
        let valid = true;
        if (sigName === "" || !sigName) {
            setNameError("error");
            valid = false;
        }
        if (sigTitle === "" || !sigTitle) {
            setTitleError("error");
            valid = false;
        }
        if (sigCanvas.current.isEmpty()) {
            setSigError("error");
            valid = false;
        }

        if (!valid) {
            showToast(
                "warning",
                "Please correct the information highlighted in red above.",
                "validation-warning"
            );
        }
        return valid;
    };

    const reRoute = () => {
        navigate(0);
    };

    const cancelHandler = () => {
        dispatch(setAddNewSignature(false));
        navigate(0);
    };

    const inputToImage = text => {
        sigCanvas.current.clear();
        const canvas = sigCanvas.current.getCanvas().getContext("2d");
        canvas.font = "28pt 'Kalam', cursive";
        canvas.fillText(text, 120, 170);
        sigCanvas.current.fromDataURL();
    };

    const inputHandler = setter => {
        return event => {
            if (event.target.value && event.target.className === "error") {
                if (event.target.name === "name") {
                    setNameError("");
                }
                if (event.target.name === "title") {
                    setTitleError("");
                }
                if (!sigCanvas.current.isEmpty()) {
                    setSigError("");
                }
            }
            if (!customSignature && event.target.name === "name") {
                inputToImage(event.target.value);
            }

            setter(event.target.value);
            dispatch(setIsSignChange(true));
        };
    };

    const deleteMessage = status => {
        if (status === "success") {
            showToast("success", "Succesfully deleted account signature!");
        } else {
            showToast("error", "Failed to delete account signature. Please contact support");
        }
    };

    const handleSubmitRequest = async () => {
        const sigData = isUpdate
            ? {
                  Id: defaultSigData?.id,
                  Title: sigTitle,
                  Name: sigName,
                  Signature: sigCanvas.current.toDataURL(),
                  ClientEntityId: defaultSigData?.ClientEntityId,
                  Modified: null,
              }
            : {
                  Title: sigTitle,
                  Name: sigName,
                  Signature: sigCanvas.current.toDataURL(),
                  ClientEntityId: props.companyId,
                  Created: defaultSigData?.created,
                  Modified: null,
              };
        if (checkValidation()) {
            if (isUpdate) {
                try {
                    dispatch(updateSignatureData(sigData));
                    showToast("success", "Succesfully updated account signature!");
                } catch {
                    showToast("error", "Failed to update account signature, please try again!");
                }
            } else {
                try {
                    dispatch(saveSignatureData(sigData));
                    showToast("success", "Succesfully created account signature!");
                    setActiveDelete(true);
                } catch {
                    showToast("error", "Failed to create account signature, please try again!");
                }
            }

            if (!isUpdate) {
                setIsUpdate(true);
            }

            setChangedData(sigData);
            dispatch(setIsSignChange(false));
            dispatch(fetchSignatureData(props.companyId));
        }
    };

    const onPageRender = () => {
        if (defaultSigData && !isEmpty(defaultSigData)) {
            sigCanvas.current.clear();
            sigCanvas.current.on();
            setIsUpdate(true);
            setSigName(defaultSigData?.name);
            setSigTitle(defaultSigData?.title);
            setStartingSig(defaultSigData?.signature);
            sigCanvas.current.fromDataURL(defaultSigData?.signature);
            setActiveDelete(true);
        }
    };

    const customSignatureHandler = () => {
        setCustomSignature(true);
        sigCanvas.current.on();
        sigCanvas.current.clear();
    };

    const resetHandler = () => {
        sigCanvas.current.off();
        sigCanvas.current.clear();
        setCustomSignature(false);
        if (sigName !== undefined) {
            inputToImage(sigName);
        }
    };

    useEffect(() => {
        dispatch(fetchSignatureData(props.companyId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        onPageRender();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultSigData]);

    const drawHandler = () => {
        dispatch(setIsSignChange(true));

        if (!sigCanvas.current.isEmpty() && sigError) {
            setSigError("");
        }

        if (startingSig && !isUpdate) {
            setIsUpdate(true);
        }
    };

    const deleteHandler = () => {
        setDeleteId(changedData?.id || defaultSigData?.id);
        setIsDeleteSignatureDialogOpen(true);
    };

    const addSignatureHandler = () => {
        dispatch(setAddNewSignature(true));
    };

    const checkPadState = () => {
        if (!customSignature) {
            sigCanvas.current.off();
        }
    };

    useImperativeHandle(ref, () => ({
        signatureValidation() {
            return checkValidation();
        },
        signatureSubmit() {
            handleSubmitRequest();
        },
    }));

    const domData =
        !addNewSignature && !defaultSigData ? (
            <div>
                <SRow className="margin-top-md">
                    <SCol>
                        <button className="ghost-blue add-new" onClick={addSignatureHandler}>
                            <SIcon className="margin-right-xs" name="plus" aria-hidden="true" /> Add
                            new signature
                        </button>
                    </SCol>
                </SRow>
            </div>
        ) : (
            <div>
                <SRow>
                    <SCol span="2">
                        <label htmlFor="name"> Name </label>
                        <input
                            name="name"
                            id="name"
                            type="text"
                            onChange={inputHandler(setSigName)}
                            className={nameError || noErrorTag}
                            value={sigName || ""}
                        />
                        {nameError ? <SignatureValidationError fieldName="name" /> : null}
                    </SCol>
                    <SCol span="2">
                        <label htmlFor="title"> Title </label>
                        <input
                            type="text"
                            name="title"
                            id="title"
                            className={titleError || noErrorTag}
                            onChange={inputHandler(setSigTitle)}
                            value={sigTitle || ""}
                        />
                        {titleError ? <SignatureValidationError fieldName="title" /> : null}
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="5">
                        <div className="signature-wrapper">
                            <div className="float-left">
                                <span> Signature </span>
                            </div>
                            <div className="menu-buttons pad-right-lg">
                                <button
                                    className="ghost-blue final-pad"
                                    disabled={isEligibleUserFlag}
                                    onClick={() => customSignatureHandler("custom")}
                                    hidden={customSignature}>
                                    Create your own signature
                                </button>

                                <button
                                    className="ghost-blue  pad-all-none no-link"
                                    onClick={clearCanvas}
                                    hidden={!customSignature}>
                                    Clear
                                </button>
                                <span hidden={!customSignature}>
                                    <s-pipe />
                                </span>
                                <button
                                    className="ghost-blue  pad-all-none no-link"
                                    onClick={resetHandler}
                                    hidden={!customSignature}>
                                    Reset
                                </button>
                                <span hidden={!activeDelete}>
                                    <s-pipe />
                                </span>
                                <button
                                    className="ghost-blue pad-all-none"
                                    type="button"
                                    disabled={isEligibleUserFlag}
                                    onClick={() => {
                                        deleteHandler();
                                    }}
                                    hidden={!activeDelete}>
                                    Delete signature
                                </button>
                            </div>
                        </div>
                        <SignatureCanvas
                            ref={sigCanvas}
                            canvasProps={
                                sigError
                                    ? { className: "signature-pad signature-pad-error" }
                                    : { className: "signature-pad" }
                            }
                            onBegin={() => checkPadState()}
                            clearOnResize={false}
                            onEnd={drawHandler}
                        />
                        {sigError ? <SignatureValidationError fieldName="signature" type /> : null}
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="12">
                        <button
                            id="save-client"
                            type="button"
                            className="primary hidden"
                            onClick={handleSubmitRequest}
                            ref={ref}
                            name="stop-apply-btn">
                            Save
                        </button>

                        <button className="tertiary hidden" type="button" onClick={cancelHandler}>
                            Cancel
                        </button>
                    </SCol>
                </SRow>
                <SignatureDeleteDialog
                    isDeleteSignatureDialogOpen={isDeleteSignatureDialogOpen}
                    setIsDeleteSignatureDialogOpen={setIsDeleteSignatureDialogOpen}
                    signatureId={deleteId}
                    deleteMessage={deleteMessage}
                    reRoute={reRoute}
                />
            </div>
        );

    return domData;
});

export default SignaturePad;
