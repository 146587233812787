import React, { useState } from "react";
import { SDialog, SRow, SCol, SIcon } from "@avalara/skylab-react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import {
    selectIsExportCampaignDialogOpen,
    postCampaignExportAPI,
    setIsExportCampaignDialogOpen,
    selectIsExportCampaignLoading,
} from "../../app/requestSlice";
import { noErrorTag } from "../../shared/Utils";

function CampaignExportDialog() {
    const dispatch = useDispatch();
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");
    const exportCampaignLoading = useSelector(selectIsExportCampaignLoading);
    const isExportCampaignDialogOpen = useSelector(selectIsExportCampaignDialogOpen);
    const saveClassName = classnames({
        primary: true,
        small: true,
        loading: exportCampaignLoading,
    });

    const handleSubmitForm = async event => {
        event.preventDefault();
        if (title) {
            await dispatch(postCampaignExportAPI(20, 1, title));
        } else {
            setTitleError("error");
        }
    };

    const onSDismiss = () => {
        dispatch(setIsExportCampaignDialogOpen(false));
    };

    const handleChangeInput = event => {
        setTitle(event.target.value);
        setTitleError("");
    };

    return (
        <SDialog
            open={isExportCampaignDialogOpen}
            id="ExportSearch-dialog"
            onS-dismiss={onSDismiss}
            aria-modal="true">
            <div slot="header" id="exportSearch">
                Export as a CSV file
            </div>
            <div slot="body">
                <SRow>
                    <SCol>
                        <label htmlFor="exportTitle" className="required" id="lbl-exportTitle">
                            Give your Export a name
                        </label>
                        <input
                            id="exportTitle"
                            type="text"
                            value={title}
                            className={titleError || noErrorTag}
                            onChange={e => {
                                handleChangeInput(e);
                            }}
                        />
                        {titleError ? (
                            <div className="input-msg">
                                <SIcon name="alert-circle-filled" aria-hidden="true" />
                                <span className="top-xs">Enter a name for Export</span>
                            </div>
                        ) : null}
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button className="secondary small" onClick={onSDismiss}>
                    Cancel
                </button>
                <button className={saveClassName} onClick={handleSubmitForm}>
                    Export
                </button>
            </div>
        </SDialog>
    );
}

export default CampaignExportDialog;
