import React, { useState } from "react";
import { SRow, SCol, SIcon, SAlert, SLoader, SBox, SDatepicker } from "@avalara/skylab-react";
import { useDispatch, useSelector } from "react-redux";
import {
    setRequestFiltersState,
    selectRequestFiltersState,
    selectCustomerSearchPage,
    selectMissingCustomerEmailCount,
    selectCustomersWithoutEmailLoading,
} from "../../app/requestSlice";
import SelectCoverLetter from "../sharedComponents/SelectCoverLetter";
import { noErrorTag, errorTag } from "../../shared/Utils";
import SelectExemptionReasons from "../sharedComponents/SelectExemptionReasons";
import "./requests.css";

const CustomizeRequest = React.memo(props => {
    const dispatch = useDispatch();
    const requestFiltersState = useSelector(selectRequestFiltersState);
    const { title, customMessage } = requestFiltersState;
    const { paginate } = useSelector(selectCustomerSearchPage);
    const missingEmailCount = useSelector(selectMissingCustomerEmailCount);
    const isLoading = useSelector(selectCustomersWithoutEmailLoading);
    const [visual, setVisual] = useState(null);

    const emailClass =
        props.deliveryType === "email"
            ? "method-card-selected pad-top-none"
            : "method-card pad-top-none";
    const pdfClass =
        props.deliveryType === "pdf"
            ? "method-card-selected pad-top-none"
            : "method-card pad-top-none";
    const zipClass =
        props.deliveryType === "zip"
            ? "method-card-selected pad-top-none"
            : "method-card pad-top-none";

    const handleInputChange = e => {
        const formValue = e.target.id === "attachCertificates" ? e.target.checked : e.target.value;

        dispatch(
            setRequestFiltersState({
                [e.target.id]: formValue,
            })
        );
    };

    const handleSendDateChange = e => {
        props.setSendDate(e.detail.value);
        if (props.returnDate && props.returnDate <= e.detail.value) {
            props.setReturnDateError("Select a date that's after the send date");
        } else {
            props.setReturnDateError(false);
        }
    };

    const handleReturnDateChange = e => {
        props.setReturnDate(e.detail.value);
        if (props.sendDate && e.detail.value <= props.sendDate) {
            props.setReturnDateError("Select a date that's after the send date");
        } else {
            props.setReturnDateError(false);
        }
    };

    const handleSelectChange = event => {
        props.setReason([Number(event.value)]);
        setVisual(event.label);
    };

    const handleSelectRemove = () => {
        props.setReason(null);
        setVisual(null);
    };

    const requestDOM = (
        <React.Fragment>
            <h1 className="line-height-md"> Certificate request settings</h1>
            <SRow className="margin-top-xl">
                <SCol
                    hidden={
                        props.searchType === "certificate" || props.searchType === "customerSearch"
                    }>
                    <span className="text-default">
                        Requesting from {new Intl.NumberFormat().format(paginate.totalRecords)}{" "}
                        customers.
                    </span>
                </SCol>
            </SRow>
            <SRow className="margin-top-sm">
                <SCol span="3">
                    <fieldset>
                        <label className="required" htmlFor="title">
                            Give this request a name
                        </label>
                        <input
                            name="title"
                            id="title"
                            type="text"
                            required
                            defaultValue={title}
                            className={props.requestNameError || noErrorTag}
                            onChange={handleInputChange}
                        />
                        {props.requestNameError ? (
                            <div className="input-msg">
                                <SIcon name="alert-circle-filled" aria-hidden="true" />
                                <span className="top-xs">Enter a request name</span>
                            </div>
                        ) : null}
                    </fieldset>
                </SCol>
                <div className="custom-datepicker-width margin-right-md">
                    <label htmlFor="sendDate">Send date</label>
                    <SDatepicker
                        inputId="sendDate"
                        onS-select={e => {
                            handleSendDateChange(e);
                        }}
                        onS-deselect={e => {
                            handleSendDateChange(e);
                        }}
                        value={props.sendDate}
                        mindate={new Date().toISOString().slice(0, 10)}
                    />
                </div>
                <div className="custom-datepicker-width">
                    <label htmlFor="returnDate">Return date</label>
                    <SDatepicker
                        inputId="returnDate"
                        onS-select={e => {
                            handleReturnDateChange(e);
                        }}
                        onS-deselect={e => {
                            handleReturnDateChange(e);
                        }}
                        value={props.returnDate}
                        className={props.returnDateError ? errorTag : "returnDate"}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        {props.returnDateError}
                    </div>
                </div>

                <SCol span="3">
                    <SelectCoverLetter labelClass="required" label="Certificate request template" />
                </SCol>
            </SRow>
            <SRow className="margin-top-sm margin-bottom-md">
                <SCol hidden={props.deliveryType !== "email"}>
                    <input
                        id="attachCertificates"
                        name="attachCertificates"
                        type="checkbox"
                        aria-label="Attach a copy of the certificate form prefilled with any information
                        available"
                        onChange={handleInputChange}
                    />
                    <label htmlFor="attachCertificates">
                        Attach a copy of the certificate form prefilled with any information
                        available
                    </label>
                </SCol>
            </SRow>
            <SRow>
                <SCol span="auto">
                    <label className="margin-bottom-xs required text-default font-bold">
                        Delivery method
                    </label>
                    <div className="make-responsive">
                        <div
                            role="presentation"
                            onClick={() => {
                                props.setDelivery("email");
                            }}
                            className="margin-right-sm">
                            <SBox className={emailClass}>
                                <input
                                    id="email-method"
                                    name="email-method"
                                    type="radio"
                                    value="email"
                                    checked={props.deliveryType === "email"}
                                />
                                <label htmlFor="email-type" className="radio-button-fix font-bold">
                                    Email
                                </label>
                                <div className="margin-top-xs">
                                    Send a certificate request via email.
                                </div>
                            </SBox>
                        </div>
                        <div
                            role="presentation"
                            onClick={() => {
                                props.setDelivery("pdf");
                            }}
                            className="margin-right-sm">
                            <SBox className={pdfClass}>
                                <input
                                    id="pdf-method"
                                    name="pdf-method"
                                    type="radio"
                                    value="pdf"
                                    checked={props.deliveryType === "pdf"}
                                />
                                <label htmlFor="pdf-type" className="radio-button-fix font-bold">
                                    Download a single PDF
                                </label>
                                <div className="margin-top-xs">
                                    Receive all requests in a single PDF file.
                                </div>
                            </SBox>
                        </div>
                        <div
                            role="presentation"
                            onClick={() => {
                                props.setDelivery("zip");
                            }}
                            className="margin-right-sm">
                            <SBox className={zipClass}>
                                <input
                                    id="zip-method"
                                    name="zip-method"
                                    type="radio"
                                    value="zip"
                                    checked={props.deliveryType === "zip"}
                                />
                                <label htmlFor="pdf-type" className="radio-button-fix font-bold">
                                    Download multiple PDFs
                                </label>
                                <div className="margin-top-xs">
                                    Receive individual requests in a ZIP file.
                                </div>
                            </SBox>
                        </div>
                    </div>
                </SCol>
            </SRow>
            <SRow className="alert-row-info">
                {isLoading ? (
                    <div className="margin-left-xl">
                        <h3>Loading ...</h3>
                        <SLoader id="page-loader" className="medium" aria-live="polite" loading />
                    </div>
                ) : (
                    <SCol
                        className="margin-top-sm margin-bottom-xs"
                        hidden={missingEmailCount === 0 || props.deliveryType !== "email"}>
                        <SAlert status="info" noDismiss role="status">
                            <div>
                                {new Intl.NumberFormat().format(missingEmailCount)}/
                                {new Intl.NumberFormat().format(paginate.totalRecords)} customers
                                are missing an email address and won&apos;t receive the request.
                            </div>
                        </SAlert>
                    </SCol>
                )}
            </SRow>
            <SRow hidden={props.deliveryType === "email"}>
                <SCol span="auto">
                    <div className="sub-text">
                        <label className="margin-bottom-xs text-default font-bold margin-top-md">
                            Downloading to print and mail
                        </label>
                        <span className="sub-text">
                            When you choose this option, Avalara can use the reason for exemption to
                            attach the correct certificate to the request.
                        </span>
                        <SelectExemptionReasons
                            name="exemptReasons"
                            id="exemptReasons"
                            onAdd={e => handleSelectChange(e)}
                            onRemove={() => handleSelectRemove()}
                            disabled={isLoading}
                            value={visual}
                        />
                        <span className="input-desc">
                            Select a reason to include a certificate form with your request.
                        </span>
                    </div>
                </SCol>
            </SRow>
            <SRow className="margin-top-lg">
                <SCol span="5">
                    <label className="margin-bottom-xs text-default font-bold">Add a note</label>
                    Notes appear above the request template content.
                    <textarea
                        id="customMessage"
                        className="margin-top-xs"
                        placeholder=""
                        onChange={handleInputChange}
                        defaultValue={customMessage}
                    />
                </SCol>
            </SRow>
        </React.Fragment>
    );

    return <React.Fragment> {requestDOM} </React.Fragment>;
});

export default CustomizeRequest;
