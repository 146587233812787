import React from "react";
import { SIcon } from "@avalara/skylab-react";

const SignatureValidationError = React.memo(props => {
    return (
        <div className="flex align-items-center input-msg">
            <SIcon
                name="alert-circle-filled"
                aria-hidden="true"
                className={props.type ? "pad-right-xs text-red-medium" : ""}
            />
            <span className={props.type ? "text-red-medium" : "top-xs"}>
                {props.fieldName === "FileUpload" ? `Upload a file` : `Enter a ${props.fieldName}`}
            </span>
        </div>
    );
});

export default SignatureValidationError;
