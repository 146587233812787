import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { SRow, SCol, SIcon } from "@avalara/skylab-react";
import {
    errorTag,
    noErrorTag,
    fieldsValidation,
    fieldValidationTypeCommaSeparatedValue,
    fieldValidationTypeCommaSeparatedUniqueValue,
} from "../../shared/Utils";

const Coverage = forwardRef((props, ref) => {
    const { single, PoNumber, setIsSingle, setPoNumber, submitted, certStatus } = props;
    const [validationResults, setValidationResults] = useState({
        poNumber: {
            issues: [],
            status: "passed",
        },
    });
    const handleCheckBoxChange = () => {
        if (single) {
            setIsSingle(false);
            setPoNumber("");
        } else {
            setIsSingle(true);
        }
    };

    useImperativeHandle(ref, () => ({
        validatePoNumber() {
            // Check whether PONumber is present
            if (submitted && single && PoNumber === "" && certStatus !== "Invalid") {
                const emptyResult = {
                    poNumber: {
                        status: "failed",
                        issues: [
                            "Enter unique, comma-separated purchase orders or invoice numbers",
                        ],
                    },
                };
                setValidationResults(emptyResult);
                return false;
            }
            // Check whether PONumber has unique comma separated values and conforms to non formula injection payload.
            const result = fieldsValidation({
                poNumber: {
                    value: PoNumber,
                    validations: [
                        fieldValidationTypeCommaSeparatedValue,
                        fieldValidationTypeCommaSeparatedUniqueValue,
                    ],
                },
            });
            setValidationResults(result);
            const customFieldsWithFailedValidation = Object.keys(result).filter(
                customField => result[customField].status === "failed"
            );
            return customFieldsWithFailedValidation.length > 0;
        },
    }));

    const handleInputChange = e => {
        setValidationResults({
            poNumber: {
                issues: [],
                status: "passed",
            },
        });
        setPoNumber(e.target.value);
    };

    useEffect(() => {
        if (PoNumber) {
            setIsSingle(true);
        }
    }, [PoNumber]);

    return (
        <React.Fragment>
            <SRow>
                <SCol>
                    <fieldset role="group" aria-labelledby="coverage">
                        <input
                            id="coverage"
                            name="coverage"
                            type="checkbox"
                            aria-label="Limit this exemption to one document or purchase order"
                            onChange={handleCheckBoxChange}
                            checked={single}
                            disabled={props?.disabled}
                        />
                        <label htmlFor="coverage">
                            Limit this exemption to one document or purchase order
                        </label>
                    </fieldset>
                </SCol>
            </SRow>
            {single ? (
                <SRow>
                    <SCol className="margin-left-md no-wrap">
                        <label htmlFor="poNumber" className="inline">
                            Purchase order or invoice number
                        </label>
                        <input
                            id="poNumber"
                            name="poNumber"
                            type="text"
                            defaultValue={PoNumber}
                            className={
                                validationResults.poNumber.status === "failed"
                                    ? errorTag
                                    : noErrorTag
                            }
                            onChange={e => handleInputChange(e)}
                            disabled={props?.disabled}
                        />
                        <div className="input-msg">
                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                            <span className="top-xs">
                                {validationResults.poNumber.status === "failed"
                                    ? validationResults.poNumber.issues[0]
                                    : ""}
                            </span>
                        </div>
                    </SCol>
                </SRow>
            ) : null}
        </React.Fragment>
    );
});

export default React.memo(Coverage);
