import { SCol, SRow } from "@avalara/skylab-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useRoutes } from "react-router-dom";
import { selectColSpan, setColSpan } from "../../app/retailSlice";
import { selectSession, selectSkylabHeader } from "../../app/sessionSlice";
import adobeAnalytics from "../../shared/adobeAnalytics";
import { isRetailOnlyUser } from "../../shared/sessionUtility";
import { urlToPageMap } from "../../shared/Utils";
import "./navigation.css";
import retailRouteConfig from "./RetailRouteConfig";
import routes from "./RouteConfig";

const fullScreenWhenThisURL = [
    `${urlToPageMap.retail}/${urlToPageMap.retailCustomerAndCertificates.replace("/:id", "")}`,
    `${urlToPageMap.retail}/${urlToPageMap.retailSearch}`,
];
const fullScreenURLRegex = new RegExp(`^(${fullScreenWhenThisURL.join("|")})(?:/\\d+)?/?$`);
const matchFullScreenURL = url => fullScreenURLRegex.test(url);

function Navigation(props) {
    const dispatch = useDispatch();
    const { activeCompany, subscriptions, userId, accountId, isAuthenticated } = useSelector(
        selectSession,
        shallowEqual
    );
    const skylabHeader = useSelector(selectSkylabHeader, shallowEqual);
    const location = useLocation();
    const [hideHeader, setHideHeader] = useState(false);
    const route = useRoutes(routes(setHideHeader, props?.setHideNav));
    const colSpan = useSelector(selectColSpan);
    const retailRoutes = useRoutes(retailRouteConfig(setHideHeader, props?.setHideNav));
    const [topSub, setTopSub] = useState("Exemption Certificates");
    const ishealthCheckGoingOn = location.pathname.includes("health-check");
    const isRetailView = location.pathname.startsWith(`${urlToPageMap.retail}`);
    const isRetailOnly = useCallback(() => dispatch(isRetailOnlyUser()), []);
    const subscriptionsHash = useMemo(() => subscriptions.join(","), [subscriptions]);
    const showSub = useCallback(() => {
        if (subscriptions.includes("ECMPremium") || subscriptions.includes("VEMPremium")) {
            setTopSub("ECM Premium");
        } else if (subscriptions.includes("ECMPro") || subscriptions.includes("VEMPro")) {
            setTopSub("ECM Pro");
        } else if (subscriptions.includes("ECMEssentials")) {
            setTopSub("ECM Essentials");
        }
    }, [subscriptionsHash]);

    useEffect(() => {
        adobeAnalytics(location, subscriptions, userId, accountId, isAuthenticated);
        if (subscriptions.length >= 1) {
            showSub();
        }
    }, [location, subscriptionsHash, showSub, userId, accountId, isAuthenticated]);

    useEffect(() => {
        if (isRetailView) {
            const modifiedLocation = location.pathname.replace(/\/$/, "");
            const shouldSetFullScreen = matchFullScreenURL(modifiedLocation, fullScreenWhenThisURL);

            if (shouldSetFullScreen) {
                dispatch(setColSpan("md-10 12"));
            }
        }
        return () => {
            if (isRetailView) {
                // reset before exiting page
                dispatch(setColSpan("md-10 lg-7 xl-7 12"));
            }
        };
    }, [location.pathname, isRetailView, matchFullScreenURL, dispatch]);

    return (
        <React.Fragment>
            {!ishealthCheckGoingOn && (
                <SRow>
                    <SCol span={isRetailView ? colSpan : ""} center={isRetailView ? "md" : ""}>
                        <h4 className="margin-top-none margin-bottom-md" hidden={hideHeader}>
                            <span className="gray-darker workspace-letter-spacing margin-right-sm">
                                {topSub}
                            </span>
                            <span className="divider-right margin-right-sm" />
                            <span className="gray-dark margin-right-lg workspace-letter-spacing text-uppercase">
                                {activeCompany.name}
                            </span>
                            <a href={skylabHeader.switchCompanyUrl}>Switch company</a>
                        </h4>
                    </SCol>
                </SRow>
            )}
            <div className="flex dl-flex-fill-height">{isRetailOnly() ? retailRoutes : route}</div>
        </React.Fragment>
    );
}

export default React.memo(Navigation);
