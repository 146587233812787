import React from "react";
import { SIcon } from "@avalara/skylab-react";

const ValidationError = React.memo(props => {
    return (
        <div className="input-msg" id={`${props?.fieldName?.replace(/\s+/g, "") || "input"}-msg`}>
            <SIcon name="alert-circle-filled" aria-hidden="true" />
            {props.errorMsg ? (
                <span className="top-xs">{props.errorMsg}</span>
            ) : (
                <span className="top-xs">Enter a {props.fieldName}</span>
            )}
        </div>
    );
});

export default ValidationError;
