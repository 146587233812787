/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { SAlert, SRow, SCol, STag, SContainer } from "@avalara/skylab-react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { selectIsAutoValidatingFile, selectAutoValidationData } from "../../app/certificateSlice";
import scanningIcon from "./icons/AutoValidation.svg";

function AutoValidation() {
    const isAutoValidatingFile = useSelector(selectIsAutoValidatingFile);
    const autoValidationData = useSelector(selectAutoValidationData, shallowEqual);

    return (
        <React.Fragment>
            {isAutoValidatingFile && (
                <SAlert
                    status="info"
                    role="status"
                    className="auto-validation-alert pad-all-md margin-bottom-xs gray-box max-height-130"
                    nodismiss>
                    <SContainer className="auto-validation-alert-Container">
                        <SRow>
                            <SCol span="8">
                                <div>
                                    <h4 className="float-left  inline margin-top-none">
                                        Scanning the image...
                                    </h4>
                                </div>

                                <span className="custom-line-height margin-bottom-md">
                                    We&apos;re scanning the image for information to prefill the
                                    certificate.
                                </span>
                                <br />
                                <label className="avs-alert-text-color margin-top-none">
                                    Avalara form reader
                                </label>
                            </SCol>
                            <SCol span="4">
                                <img
                                    src={scanningIcon}
                                    className="float-right auto-validation-image"
                                    alt="Scanning form"
                                />
                            </SCol>
                        </SRow>
                    </SContainer>
                </SAlert>
            )}
            {!(isAutoValidatingFile || autoValidationData.status !== "success") && (
                <SAlert
                    status="info"
                    role="status"
                    className="auto-validation-alert pad-all-md margin-bottom-xs"
                    nodismiss>
                    <SContainer className="auto-validation-alert-Container">
                        <SRow>
                            <SCol span="3">
                                <strong className="float-left">Image scan results</strong>
                            </SCol>
                            <SCol span="4">
                                <strong>Details</strong>
                            </SCol>
                            <SCol span="5">
                                <label className="avs-alert-text-color margin-top-none float-right">
                                    Avalara AI-assisted certificate validation
                                </label>
                            </SCol>
                        </SRow>
                        {autoValidationData.issues.length === 0 ? (
                            <SRow>
                                <SCol span="3">
                                    <STag color="blue-light">
                                        <span slot="content">No issues found</span>
                                    </STag>
                                </SCol>
                                <SCol span="6" className="top-fix">
                                    <span>
                                        We got information from the form image, but we recommend you
                                        look it over to make sure it’s complete.
                                    </span>
                                </SCol>
                            </SRow>
                        ) : null}
                        {autoValidationData.issues.length > 0 ? (
                            <SRow>
                                <SCol span="3">
                                    <STag color="yellow-medium">
                                        <span slot="content">Possible issues</span>
                                    </STag>
                                </SCol>
                                <SCol span="6" className="top-fix">
                                    <span>Check these possible problems with the certificate:</span>
                                    <ul>
                                        {autoValidationData.issues.map(issue => {
                                            return (
                                                <span
                                                    className="block margin-left-lg"
                                                    key={uuidv4()}>
                                                    <li>{issue}</li>
                                                </span>
                                            );
                                        })}
                                    </ul>
                                </SCol>
                            </SRow>
                        ) : null}
                    </SContainer>
                </SAlert>
            )}
            {!(isAutoValidatingFile || ["success", ""].includes(autoValidationData.status)) && (
                <SAlert
                    status="info"
                    role="status"
                    className="auto-validation-alert pad-all-md margin-bottom-xs"
                    nodismiss>
                    <SContainer className="auto-validation-alert-Container">
                        <SRow>
                            <SCol span="3">
                                <strong className="float-left">Image scan results</strong>
                            </SCol>
                            <SCol span="4">
                                <strong>Details</strong>
                            </SCol>
                            <SCol span="5">
                                <label className="avs-alert-text-color margin-top-none float-right">
                                    Avalara AI-assisted certificate validation
                                </label>
                            </SCol>
                        </SRow>

                        <SRow>
                            <SCol span="3">
                                <STag color="gray-darker">
                                    <span slot="content">Unreviewed</span>
                                </STag>
                            </SCol>
                            <SCol span="9" className="top-fix">
                                <span>
                                    The form reader can read{" "}
                                    <a
                                        href="https://knowledge.avalara.com/bundle/xyq1665650841341_xyq1665650841341/page/Supported_certificates_for_automatic_validation.html"
                                        target="_blank"
                                        className="auto-validation-link text-underline"
                                        rel="noreferrer">
                                        <span>these certificate forms</span>
                                    </a>{" "}
                                    but not this one. If you want to verify that it&apos;s a valid
                                    form,{" "}
                                    <Link
                                        to="/resources/certificate-forms"
                                        target="_blank"
                                        className="auto-validation-link text-underline"
                                        rel="noreferrer">
                                        look it up in our certificate library.
                                    </Link>
                                </span>
                            </SCol>
                        </SRow>
                    </SContainer>
                </SAlert>
            )}
        </React.Fragment>
    );
}

export default AutoValidation;
