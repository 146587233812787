import { SCol, SRow } from "@avalara/skylab-react";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { fetchExemptionMatrixReasonValidationApi } from "../../../app/certificateSlice";
import toast from "../../../hooks/toast";
import ValidationError from "../../sharedComponents/ValidationError";
import SelectExemptionReasonBasedOnRegion from "../../sharedComponents/SelectExemptionReasonBasedOnRegion";
import SelectExposureZone from "../../sharedComponents/SelectExposureZone";
import GenCert from "../../sharedComponents/GenCert";
import { fetchCustomerAPI, selectCustomer } from "../../../app/customerSlice";
import {
    fetchDocumentTypeInfoByName,
    selectDocumentTypeInfo,
    selectSignatureData,
} from "../../../app/settingsSlice";
import { selectSession } from "../../../app/sessionSlice";
import { SalesAndUseTax } from "../../../shared/constants";

const Certificate = () => {
    let dom = null;
    const [exposureZone, setExposureZone] = useState({});
    const [exemptReason, setExemptReason] = useState("");
    const [exposureZoneError, setExposureZoneError] = useState(false);
    const [exemptReasonError, setExemptReasonError] = useState(false);
    const [enableGenCert, setEnableGenCert] = useState(false);

    const customer = useSelector(selectCustomer, shallowEqual);
    const session = useSelector(selectSession, shallowEqual);
    const documentType = useSelector(selectDocumentTypeInfo, shallowEqual);
    const defaultSigData = useSelector(selectSignatureData, shallowEqual);

    const [showToast] = toast();
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const saveButton = classNames({
        primary: true,
        large: true,
        "max-width-buttton": true,
        "pad-all-none": true,
    });

    const cancelButton = classNames({
        tertiary: true,
        large: true,
        "max-width-buttton": true,
    });

    const exemptionMatrixAPI = useCallback(
        ids => {
            dispatch(
                fetchExemptionMatrixReasonValidationApi(
                    ids?.length ? ids : [],
                    ids?.length ? showToast : false
                )
            );
        },
        [dispatch]
    );

    useEffect(() => {
        if (Number.isInteger(parseInt(params.id, 10))) {
            dispatch(fetchCustomerAPI(params.id));
        }
    }, [params.id]);

    useEffect(() => {
        dispatch(fetchDocumentTypeInfoByName(SalesAndUseTax, false));
    }, [dispatch]);

    const handleRegionRemove = useCallback(() => {
        exemptionMatrixAPI([]);
        setExposureZone([]);
    }, [exemptionMatrixAPI, dispatch]);

    const handleRegionAdd = useCallback(
        event => {
            setExposureZone(() => {
                const exposureZoneId = event.value;
                const selectedExposureZoneObj = {
                    id: event.value,
                    name: event.label,
                    isSST: event.isSST,
                };
                exemptionMatrixAPI([exposureZoneId]);
                return selectedExposureZoneObj;
            });
            setExposureZoneError(false);
            setExemptReasonError(false);
        },
        [exemptionMatrixAPI, dispatch]
    );

    const handleReasonChange = useCallback(el => {
        setExemptReason(() => {
            const reason = typeof el === "object" ? el.detail.item.value : el;
            setExemptReasonError(false);
            return reason;
        });
    }, []);

    useEffect(() => {
        const exposureZoneObj = location?.state?.exposureZoneObj;
        if (exposureZoneObj && Object.keys(exposureZoneObj).length) {
            handleRegionAdd(exposureZoneObj);
            exemptionMatrixAPI([exposureZoneObj.value]);
        } else {
            exemptionMatrixAPI([]);
        }
    }, [JSON.stringify(location?.state?.exposureZoneObj), exemptionMatrixAPI, handleRegionAdd]);

    useEffect(() => {
        if (location.state?.taxCodeName) {
            setExemptReason(location.state?.taxCodeName);
        }
    }, [location.state?.taxCodeName]);

    const handleReasonRemove = useCallback(() => {
        setExemptReason("");
    }, [dispatch]);

    const validate = () => {
        let isValid = true;
        if (Object.keys(exposureZone).length === 0) {
            setExposureZoneError(true);
            isValid = false;
        }
        if (exemptReason === "") {
            setExemptReasonError(true);
            isValid = false;
        }
        return isValid;
    };

    const handleSubmit = () => {
        if (validate()) {
            setEnableGenCert(true);
        }
    };

    dom = (
        <>
            {!enableGenCert ? (
                <>
                    <SRow>
                        <SCol span="auto" className="margin-top-sm">
                            <SelectExposureZone
                                onAdd={handleRegionAdd}
                                onRemove={handleRegionRemove}
                                value={[exposureZone]}
                                exposureZoneDisabled={false}
                                error={exposureZoneError}
                            />
                            <ValidationError fieldName="region" />
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span="auto" className="margin-top-sm">
                            <SelectExemptionReasonBasedOnRegion
                                onAdd={handleReasonChange}
                                onRemove={handleReasonRemove}
                                value={exemptReason}
                                error={exemptReasonError}
                            />
                            <ValidationError fieldName="reason" />
                        </SCol>
                    </SRow>
                    <SRow class="margin-top-xl">
                        <SCol span="xs-6 sm-6 4">
                            <button
                                className={saveButton}
                                type="submit"
                                onClick={() => handleSubmit()}>
                                Save and continue
                            </button>
                        </SCol>
                        <SCol span="xs-6 sm-6 4">
                            <button
                                type="button"
                                className={cancelButton}
                                onClick={() => {
                                    navigate(-1);
                                }}>
                                Cancel
                            </button>
                        </SCol>
                    </SRow>
                </>
            ) : null}
            <SRow>
                <SCol>
                    {enableGenCert && (
                        <div id="gframe">
                            <GenCert
                                customer={JSON.stringify(customer)}
                                exposureZone={exposureZone.name}
                                enableGencert={enableGenCert}
                                taxCode={exemptReason}
                                emailAddress={customer.emailAddress}
                                docType={documentType[0]?.id}
                                ecmUser={session.username}
                                defaultSigData={defaultSigData}
                                isRetail
                            />
                        </div>
                    )}
                </SCol>
            </SRow>
        </>
    );
    return dom;
};

export default Certificate;
