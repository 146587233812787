import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SRow, SCol, SIcon, SLoader } from "@avalara/skylab-react";
import classnames from "classnames";
import { isEligibleUser } from "../../shared/sessionUtility";
import { getFormattedDate } from "../../shared/Utils";
import {
    selectIsAttachingComment,
    selectIsLoadingComments,
    attachCommentsAsync,
    getCertificateCommentsAsync,
    selectComments,
} from "../../app/certificateSlice";
import { selectSession } from "../../app/sessionSlice";
import DeleteCommentDialog from "../sharedDialogs/DeleteCommentDialog";
import featureFlag from "../../featureToggler/featureFlag";

function CertificateComments(props) {
    const dispatch = useDispatch();

    const session = useSelector(selectSession, shallowEqual);
    const attachingComment = useSelector(selectIsAttachingComment);
    const isLoading = useSelector(selectIsLoadingComments);
    const isEligibleUserCommentsFlag = dispatch(
        isEligibleUser(
            props.onReview
                ? featureFlag.reviewCertificate.comments
                : featureFlag.validatedCertificate.comments
        )
    );
    const { certificateID } = props;
    const [showCommentInput, setShowCommentInput] = useState(false);
    const [commentValue, setCommentValue] = useState("");
    const [isDeleteCommentDialogOpen, setIsDeleteCommentDialogOpen] = useState(false);
    const [commentIdToDelete, setCommentIdToDelete] = useState("");
    const saveCommentButton = classnames({ primary: true, loading: attachingComment });
    const comments = useSelector(selectComments, shallowEqual);

    const handleChangeInput = setter => {
        return event => {
            setter(event.target.value);
        };
    };

    const onCancel = () => {
        setCommentValue("");
        setShowCommentInput(false);
    };

    const onSave = async () => {
        await dispatch(attachCommentsAsync(certificateID, commentValue));
        setShowCommentInput(false);
    };

    const deleteComment = commentId => {
        setIsDeleteCommentDialogOpen(true);
        setCommentIdToDelete(commentId);
    };

    useEffect(() => {
        if (session.activeCompany.id !== null && certificateID) {
            dispatch(getCertificateCommentsAsync(certificateID));
        }
    }, [dispatch, certificateID, session.activeCompany.id]);
    return (
        <>
            <SRow>
                <SCol>
                    <SRow className="margin-top-sm">
                        <SCol>
                            {isLoading && <SLoader loading="true" aria-live="polite" />}
                            {!isLoading && comments.length > 0 && (
                                <>
                                    {comments.length}{" "}
                                    {comments.length === 1 ? "comment" : "comments"}
                                </>
                            )}
                        </SCol>
                    </SRow>
                    {comments.length > 0 ? (
                        <SRow className="margin-top-sm">
                            <span>
                                {comments &&
                                    comments.map(comment => (
                                        <div key={comment.id}>
                                            <SCol>
                                                {getFormattedDate(comment.created)} by{" "}
                                                {comment.aiUserName || comment.accountName}
                                            </SCol>
                                            <SCol>{comment.entry}</SCol>
                                            <SCol>
                                                <button
                                                    className="link inline font-semibold"
                                                    onClick={() => deleteComment(comment.id)}
                                                    disabled={!isEligibleUserCommentsFlag}>
                                                    <SIcon
                                                        className="top-sm ghost link"
                                                        name="trash"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="pad-left-xs">Delete</span>
                                                </button>
                                            </SCol>
                                        </div>
                                    ))}
                            </span>
                        </SRow>
                    ) : null}
                    <SRow>
                        {showCommentInput ? (
                            <SCol className="margin-top-sm">
                                <textarea
                                    rows="4"
                                    onChange={handleChangeInput(setCommentValue)}
                                    aria-label="add your comments"
                                />
                                <SRow>
                                    <SCol className="margin-top-md">
                                        <button
                                            className={saveCommentButton}
                                            onClick={onSave}
                                            disabled={
                                                commentValue.length === 0 ||
                                                !isEligibleUserCommentsFlag
                                            }>
                                            Save
                                        </button>
                                        <button className="tertiary" onClick={onCancel}>
                                            Cancel
                                        </button>
                                    </SCol>
                                </SRow>
                            </SCol>
                        ) : null}
                        <SCol className="margin-top-xs">
                            <button
                                className="link inline font-bold"
                                disabled={
                                    showCommentInput ||
                                    !isEligibleUserCommentsFlag ||
                                    props?.disabledComments
                                        ? "disabled"
                                        : null
                                }
                                onClick={() => setShowCommentInput(true)}>
                                <SIcon
                                    className="top-sm ghost link"
                                    name="plus"
                                    aria-hidden="true"
                                />
                                <span className="pad-left-xs">Add a comment</span>
                            </button>
                        </SCol>
                    </SRow>
                </SCol>
            </SRow>
            {isDeleteCommentDialogOpen ? (
                <DeleteCommentDialog
                    certificateID={certificateID}
                    commentId={commentIdToDelete}
                    isDeleteCommentDialogOpen={isDeleteCommentDialogOpen}
                    setIsDeleteCommentDialogOpen={setIsDeleteCommentDialogOpen}
                />
            ) : null}
        </>
    );
}

export default React.memo(CertificateComments);
