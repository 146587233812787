import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SCol, SRow, SIcon, SInputExtended } from "@avalara/skylab-react";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import featureFlag from "../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../shared/sessionUtility";
import {
    fetchRegionsWithNexusDetailsAPI,
    setFormState,
    selectFormState,
} from "../../../app/regionSlice";
import { getStateByCountry } from "../../../app/customerSlice";
import SelectCountry from "../../sharedComponents/SelectCountry";
import SelectState from "../../customers/SelectState";
import { countryIds } from "../../../shared/constants";

function RegionFilters(props) {
    const formState = useSelector(selectFormState, shallowEqual);
    const dispatch = useDispatch();
    const { name, country, state } = formState;

    const isEligibleUserSettingsCustomRegionAddition = dispatch(
        isEligibleUser(featureFlag.settings.addExemptionRegion)
    );
    const handleSubmit = useCallback(() => {
        dispatch(fetchRegionsWithNexusDetailsAPI(null, null, null, 0));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getStateByCountry(countryIds.US));
    }, []); // eslint-disable-line

    useEffect(() => {
        let timeout = "";
        timeout = setTimeout(() => {
            handleSubmit();
        }, 400);
        return () => clearTimeout(timeout);
    }, [formState.name]);

    const handleInput = e => {
        dispatch(
            setFormState({
                ...formState,
                [e.detail.inputId]: e.detail.value.trim(),
            })
        );
    };

    const clearSearchBox = async () => {
        dispatch(
            setFormState({
                ...formState,
                name: "",
            })
        );
        handleSubmit();
    };

    const handleCountryChange = value => {
        dispatch(
            setFormState({
                ...formState,
                country: value,
                state: null,
            })
        );
        dispatch(getStateByCountry(value));
        handleSubmit();
    };
    const handleCountryRemove = () => {
        dispatch(
            setFormState({
                ...formState,
                country: null,
            })
        );
        dispatch(getStateByCountry(countryIds.US));
        handleSubmit();
    };
    const handleStateChange = value => {
        dispatch(
            setFormState({
                ...formState,
                state: value.value,
            })
        );
        handleSubmit();
    };
    const handleStateRemove = () => {
        dispatch(
            setFormState({
                ...formState,
                state: null,
            })
        );
        handleSubmit();
    };
    const OpenAddRegionDialog = () => {
        props.setIsAddEditRegionDialogOpen(true);
        props.setSource("AddARegion");
    };
    return (
        <SRow>
            <SCol span="3">
                <label htmlFor="name">Search by name</label>
                <SInputExtended
                    inputId="name"
                    aria-label="Search by name"
                    type="search"
                    onS-input={e => handleInput(e)}
                    onS-search={e => handleSubmit(e)}
                    onS-clear={() => clearSearchBox()}
                    value={name}
                />
            </SCol>
            <SCol span="3">
                <SelectCountry
                    onChange={handleCountryChange}
                    onRemove={handleCountryRemove}
                    value={country}
                    all
                    countryError="valid"
                />
            </SCol>
            <SCol span="3">
                <SelectState
                    label="State or province"
                    onChange={handleStateChange}
                    onRemove={handleStateRemove}
                    value={state}
                    multiple={false}
                    stateError="valid"
                />
            </SCol>
            <FeatureToggler category="settings" id="addExemptionRegion">
                <SCol span="3" className="flex justify-content-end">
                    <button
                        type="button"
                        id="add-region-button"
                        className="primary inline margin-top-md"
                        onClick={OpenAddRegionDialog}
                        disabled={!isEligibleUserSettingsCustomRegionAddition}>
                        <SIcon name="plus" className="margin-right-xs" aria-label="Add a region" />
                        Add a region
                    </button>
                </SCol>
            </FeatureToggler>
        </SRow>
    );
}

export default RegionFilters;
