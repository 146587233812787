import { SBadge, SBox, SLoader } from "@avalara/skylab-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
    clearCertificateFiltersState,
    setCertificateFiltersState,
} from "../../../app/certificateSlice";
import { clearCustomerFiltersState, setCustomerFiltersState } from "../../../app/customerSlice";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import { urlToPageMap } from "../../../shared/Utils";
import certReview from "./icons/cert_review.svg";
import missingCerts from "./icons/cx_missing_cert.svg";
import expiringCert from "./icons/expiring_soon.svg";
import sigIcon from "./icons/sig_task_icon.svg";
import vemMatrix from "./icons/vem_matrix.svg";

const Tasks = React.memo(props => {
    const dispatch = useDispatch();
    const [expiringCount, setExpiringCount] = useState(0);
    const [reviewCount, setReviewCount] = useState(0);
    const [missingCertCount, setMissingCertCount] = useState(0);

    const today = new Date();
    const thirtyOut = new Date(new Date().setDate(today.getDate() + 30))
        .toISOString()
        .split("T")[0];

    const startDay = new Date(new Date().setDate(today.getDate())).toISOString().split("T")[0];

    const searchExpired = () => {
        dispatch(clearCertificateFiltersState());
        dispatch(setCertificateFiltersState({ isCertificateActive: true }));
        dispatch(setCertificateFiltersState({ certificateExpirationDateBefore: thirtyOut }));
        dispatch(setCertificateFiltersState({ certificateExpirationDateAfter: startDay }));
        dispatch(
            setCertificateFiltersState({ savedSearch: "Certificates expiring within 30 days" })
        );
    };

    const searchMissingCerts = () => {
        dispatch(clearCertificateFiltersState());
        dispatch(clearCustomerFiltersState());
        dispatch(setCustomerFiltersState({ isMissingcertificates: true }));
    };
    useEffect(() => {
        dispatch(clearCertificateFiltersState());
        dispatch(clearCustomerFiltersState());
    }, [dispatch]);

    useEffect(() => {
        setExpiringCount(props.overviewData?.expiringCertificateCount);
        setReviewCount(props.overviewPendingCertificatesData?.pendingCertificateCount);
        setMissingCertCount(props.overviewMissingCertificatesData?.customerMissingCertificateCount);
    }, [
        props.overviewData.expiringCertificateCount,
        props.overviewPendingCertificatesData.pendingCertificateCount,
        props.overviewMissingCertificatesData.customerMissingCertificateCount,
    ]);

    const pageLoading = <SLoader loading className="large" aria-live="polite" />;
    const pageDetail =
        expiringCount >= 1 ||
        reviewCount >= 1 ||
        missingCertCount >= 1 ||
        !props.comSig ||
        !props.matrixSet ? (
            <div className="make-responsive">
                <FeatureToggler category="validatedSearchPage" id="viewValidatedCertificates">
                    {expiringCount >= 1 ? (
                        <Link onClick={searchExpired} to="/search/certificates" className="no-link">
                            <SBox className="standard-card-first">
                                <SBadge
                                    count={expiringCount}
                                    className="right"
                                    aria-label="Certificates expiring within 30 days"
                                />
                                <img
                                    src={expiringCert}
                                    alt="Expiring Certificates within 30 days"
                                    className="icon-link"
                                />
                                <p className="card-label-link">
                                    Certificates expiring within 30 days
                                </p>
                            </SBox>
                        </Link>
                    ) : null}
                </FeatureToggler>
                <FeatureToggler category="reviewSearchPage" id="viewReviewSearchPage">
                    {reviewCount >= 1 ? (
                        <Link to={urlToPageMap["search-pending"]} className="no-link">
                            <SBox className="standard-card">
                                <SBadge
                                    count={reviewCount}
                                    className="right"
                                    aria-label="Incoming certificates to review"
                                />
                                <img
                                    src={certReview}
                                    alt="review Incoming certificates"
                                    className="icon-link"
                                />
                                <p className="card-label-link">Incoming certificates to review</p>
                            </SBox>
                        </Link>
                    ) : null}
                </FeatureToggler>
                <FeatureToggler category="customers" id="manageCustomer">
                    {missingCertCount >= 1 ? (
                        <Link
                            onClick={searchMissingCerts}
                            to="/search/customers"
                            className="no-link">
                            <SBox className="standard-card">
                                <SBadge
                                    count={missingCertCount}
                                    className="right"
                                    aria-label="Customers missing certificates"
                                />
                                <img
                                    src={missingCerts}
                                    alt="customer missing certificates"
                                    className="icon-link"
                                />
                                <p className="card-label-link">Customers missing certificates</p>
                            </SBox>
                        </Link>
                    ) : null}
                </FeatureToggler>
                <FeatureToggler category="settings" id="vendorOptions">
                    {!props.comSig ? (
                        <Link to="/settings" className="no-link">
                            <SBox className="standard-card">
                                <img src={sigIcon} alt="add a signature" className="icon-link" />
                                <p className="card-label-link">
                                    Add a signature for outgoing certificates
                                </p>
                            </SBox>
                        </Link>
                    ) : null}
                </FeatureToggler>
                <FeatureToggler category="settings" id="sendingCertficateExemptionMatrix">
                    {!props.matrixSet ? (
                        <Link to="/settings/default-exemption-forms/outgoing" className="no-link">
                            <SBox className="standard-card">
                                <img src={vemMatrix} alt="set matrix" className="icon-link" />
                                <p className="card-label-link">
                                    Define where and why your company is exempt
                                </p>
                            </SBox>
                        </Link>
                    ) : null}
                </FeatureToggler>
            </div>
        ) : (
            <div className="box-empty margin-left-sm pad-top-md pad-bottom-md">
                <label> Your certificates are up to date. </label>
                <label> Give yourself a present! </label>
            </div>
        );

    return (
        <SBox className="info-box margin-bottom-lg">
            <h1 className="margin-left-sm"> Tasks </h1>
            {props.isLoading ||
            props.isMissingCertificatesLoading ||
            props.isPendingCertificatesLoading ||
            props.matrixLoad
                ? pageLoading
                : pageDetail}
        </SBox>
    );
});

export default Tasks;
