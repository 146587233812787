import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SLoader, SPagination } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import { v4 as uuidv4 } from "uuid";
import DeleteSavedSearchDialog from "./DeleteSavedSearchDialog";
import { isEligibleUser } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";

import {
    selectPage,
    selectIsLoading,
    selectSortColumn,
    setSortColumn,
    fetchSavedSearchesAPI,
    setIsVendor,
} from "../../../app/savedSearchesSlice";
import {
    clearCertificateFiltersState,
    clearFormState as clearCertificateFormState,
    setSavedSearchState as setCertificateSavedSearchState,
} from "../../../app/certificateSlice";
import {
    clearCustomerFiltersState,
    clearFormState as clearCustomerFormState,
    setSavedSearchState as setCustomerSavedSearchState,
} from "../../../app/customerSlice";
import { selectSession, updateRowCountPreference } from "../../../app/sessionSlice";
import RecordCount from "../RecordCount";
import { nextDays, previousDays, getRowsPerPage } from "../../../shared/Utils";

function SavedSearchGrid() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector(selectIsLoading);
    const session = useSelector(selectSession, shallowEqual);
    const [submitting, setSubmitting] = useState(false);
    const [savedSearchIdToDelete, setSavedSearchIdToDelete] = useState("");
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectSortColumn);
    const currentdate = new Date().toISOString().slice(0, 10);
    function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setSortColumn([column, order]));
        dispatch(fetchSavedSearchesAPI(session.rowsPerPage, e.detail.startIndex, false));
        setSubmitting(false);
    }
    const isEligibleUserReportsViewVendorReports = dispatch(
        isEligibleUser(featureFlag.reports.viewVendorReports)
    );

    useEffect(() => {
        dispatch(setIsVendor(isEligibleUserReportsViewVendorReports));
    }, []); // eslint-disable-line

    const clearSetAndNavigate = {
        Certificate: requiredSearchCriteria => {
            dispatch(clearCertificateFormState());
            dispatch(clearCertificateFiltersState());
            dispatch(setCertificateSavedSearchState(JSON.parse(requiredSearchCriteria)));
            navigate(`/saved/search/certificates`);
        },
        Vendor: requiredSearchCriteria => {
            dispatch(clearCustomerFormState());
            dispatch(clearCustomerFiltersState());
            dispatch(setCustomerSavedSearchState(JSON.parse(requiredSearchCriteria)));
            navigate(`/saved/search/vendors`);
        },
        Customer: requiredSearchCriteria => {
            dispatch(clearCustomerFormState());
            dispatch(clearCustomerFiltersState());
            dispatch(setCustomerSavedSearchState(JSON.parse(requiredSearchCriteria)));
            navigate(`/saved/search/customers`);
        },
        "Vendor-Certificate": requiredSearchCriteria => {
            dispatch(clearCertificateFormState());
            dispatch(clearCertificateFiltersState());
            dispatch(setCertificateSavedSearchState(JSON.parse(requiredSearchCriteria)));
            navigate(`/saved/search/vendor-certificates`);
        },
    };

    const setFilterStateAndNavigate = ({ criteria, searchType }) => {
        const requiredSearchCriteria = criteria
            .replace("Last30days", previousDays(30))
            .replace("Next30days", nextDays(30))
            .replace("today", currentdate);
        clearSetAndNavigate[searchType](requiredSearchCriteria);
    };

    const columns = [
        gridHeader("Name", "title", value => value, null, "", null, null, sortFunction, "title"),
        gridHeader("Created by", "cannedSearch", value => value, null, ""),
        gridHeader(
            "Report type",
            "searchType",
            value => value,
            null,
            "",
            null,
            null,
            sortFunction,
            "searchType"
        ),
        gridHeader(
            "",
            "",
            (value, row) => (
                <>
                    <button
                        type="button"
                        className="secondary small"
                        onClick={() => setFilterStateAndNavigate(row)}>
                        Run report
                    </button>

                    <button
                        className="ghost-blue small pad-left-lg"
                        onClick={() => setSavedSearchIdToDelete(row.id)}
                        disabled={row.cannedSearch !== "User"}>
                        Delete
                    </button>
                </>
            ),
            "text-right",
            "text-right",
            null
        ),
    ];

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            dispatch(
                fetchSavedSearchesAPI(e.detail.rowsPerPage, e.detail.startIndex, false, e.detail)
            );
            setSubmitting(false);
        }
    };
    const getRowKey = useCallback(() => {
        return uuidv4();
    }, []);

    const table =
        submitting || loading ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="grid-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <>
                <RecordCount
                    selectedCount={0}
                    recordCount={paginateData.totalRecords}
                    recordName="reports"
                />
                <s-table-container key={uuidv4()}>
                    <DataGrid
                        columns={columns}
                        rows={pageData}
                        getKey={getRowKey}
                        inContainer
                        sortColumn={sortColumn}
                    />
                </s-table-container>
            </>
        );

    return (
        <React.Fragment>
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            <DeleteSavedSearchDialog
                savedSearchIdToDelete={savedSearchIdToDelete}
                setSavedSearchIdToDelete={setSavedSearchIdToDelete}
            />
        </React.Fragment>
    );
}
export default SavedSearchGrid;
