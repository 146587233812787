import React from "react";
import { STag } from "@avalara/skylab-react";
import Tooltip from "./Tooltip";
import "./tooltip.css";

export default function BulkUploadStatus(row) {
    const tagValue = row.data.status;
    const tagErrorMsg = row.data.errorMessage;
    let tagColor = "blue-light";
    if (tagValue?.toLowerCase() === "complete") tagColor = "green-dark";
    if (tagValue?.toLowerCase() === "processing") tagColor = "green-light";
    if (tagValue?.toLowerCase() === "succeeded") tagColor = "green-dark";
    if (tagValue?.toLowerCase() === "partially succeeded") tagColor = "green-light";
    if (tagValue?.toLowerCase() === "failed") tagColor = "red-dark";
    if (tagValue?.toLowerCase() === "failed" && tagErrorMsg !== null) {
        return (
            <div className="tooltip">
                <STag color={tagColor} className="statusTag file-status-info-align text-ellipsis">
                    <span slot="content">{`${tagValue} ...`}</span>
                </STag>
                <Tooltip tooltipData={tagErrorMsg} columnPositionRight />
            </div>
        );
    }
    return (
        <STag color={tagColor} role="status">
            <span slot="content">{tagValue}</span>
        </STag>
    );
}
