import React, { useState } from "react";
import { SRow, SCol } from "@avalara/skylab-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import SelectExposureZone from "../../../sharedComponents/SelectExposureZone";
import SelectExemptionReasons from "../../../sharedComponents/SelectExemptionReasons";
import RequestingCollectionDialog from "./RequestingCollectionDialog";

import {
    selectRequestCertificateReasons,
    selectRequestCertificateRegions,
    setRequestCertificateReasons,
    setRequestCertificateRegions,
    fetchCertificateRequestFormsAPI,
} from "../../../../app/settingsSlice";

import FeatureToggler from "../../../../featureToggler/FeatureToggler";
import { isEligibleUser } from "../../../../shared/sessionUtility";
import featureFlag from "../../../../featureToggler/featureFlag";

const CertificateRequestFormsFilter = React.memo(() => {
    const dispatch = useDispatch();
    const reasons = useSelector(selectRequestCertificateReasons, shallowEqual);
    const regions = useSelector(selectRequestCertificateRegions, shallowEqual);
    const [openDialog, setOpenDialog] = useState(false);
    const applyFilter = () => {
        dispatch(fetchCertificateRequestFormsAPI(false));
    };

    const isEligibleUserSettingsRequestingCertficateExemptionMatrix = dispatch(
        isEligibleUser(featureFlag.settings.requestingCertficateExemptionMatrix)
    );

    const handleSearch = async (id, e) => {
        if (id === "requestRegions") {
            if (e.label === "All") {
                dispatch(setRequestCertificateRegions(""));
            } else {
                const requestRegion = { regionId: e.value, regionName: e.label };
                dispatch(setRequestCertificateRegions(requestRegion));
            }
        }
        if (id === "requestReasons") {
            if (e.label === "All") {
                dispatch(setRequestCertificateReasons(""));
            } else {
                const reason = { reasonId: e.value, reasonName: e.label };
                dispatch(setRequestCertificateReasons(reason));
            }
        }
        applyFilter();
    };

    const handleRemove = async id => {
        if (id === "requestRegions") {
            dispatch(setRequestCertificateRegions(""));
        }
        if (id === "requestReasons") {
            dispatch(setRequestCertificateReasons("-1"));
        }
        applyFilter();
    };

    return (
        <React.Fragment>
            <SRow className="pad-top-sm">
                <SCol span="3">
                    <SelectExposureZone
                        label="Region"
                        id="requestRegions"
                        name="requestRegions"
                        onAdd={e => handleSearch("requestRegions", e)}
                        onRemove={e => handleRemove("requestRegions", e)}
                        value={regions.regionName}
                        multiple={false}
                        exposureZoneDisabled={false}
                        disabled={false}
                        isAll
                    />
                </SCol>

                <SCol span="3">
                    <SelectExemptionReasons
                        name="requestReasons"
                        id="requestReasons"
                        onAdd={e => handleSearch("requestReasons", e)}
                        onRemove={e => handleRemove("requestReasons", e)}
                        value={reasons.reasonId}
                        multiple={false}
                        disabled={false}
                        isAll
                    />
                </SCol>
                <FeatureToggler category="settings" id="requestingCertficateExemptionMatrix">
                    <SCol span="auto">
                        <button
                            disabled={!isEligibleUserSettingsRequestingCertficateExemptionMatrix}
                            className="primary float-right margin-top-lg"
                            onClick={() => {
                                setOpenDialog(true);
                            }}>
                            Add region and reason
                        </button>
                    </SCol>
                </FeatureToggler>
            </SRow>

            <div hidden={!openDialog}>
                <RequestingCollectionDialog
                    diagStatus={openDialog}
                    setOpenDialog={setOpenDialog}
                    reload={applyFilter}
                />
            </div>
        </React.Fragment>
    );
});

export default CertificateRequestFormsFilter;
