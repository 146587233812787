import React from "react";
import { STabs } from "@avalara/skylab-react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

const Retail = React.memo(() => {
    const location = useLocation();
    const locationPath = location.pathname.toString();
    const navigate = useNavigate();

    const tabItems = [
        {
            id: "retail-locations",
            path: "/settings/retail/locations",
            label: "Retail site locations",
            tabPanelId: "retail-locations",
            selected: locationPath.includes("locations"),
        },
        {
            id: "retail-settings",
            path: "/settings/retail/retail-settings",
            label: "Retail settings",
            tabPanelId: "retail-settings",
            selected: locationPath.includes("retail-settings"),
        },
    ];
    const navigateTo = to => {
        // eslint-disable-next-line
        const currentHistory = locationPath;
        if (to.includes("retail-locations") && !currentHistory.includes("retail/locations")) {
            navigate(`/settings/retail/locations`);
        } else if (
            to.includes("retail-settings") &&
            !currentHistory.includes("retail/retail-settings")
        ) {
            navigate(`/settings/retail/retail-settings`);
        }
    };

    const DOMelement = (
        <React.Fragment>
            <h1> Retail site locations and settings</h1>
            <div>
                <button
                    className="ghost-blue icon-leading pad-left-none large font-bold"
                    onClick={() => {
                        navigate(`/settings/advanced-settings`);
                    }}>
                    <s-icon name="arrow-left" aria-hidden="true" />
                    Back to advanced settings
                </button>
            </div>

            <STabs tabItems={tabItems} onS-select={e => navigateTo(e.detail.id)} />
            <Outlet />
        </React.Fragment>
    );
    return DOMelement;
});

export default Retail;
