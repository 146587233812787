import React, { useState } from "react";
import { SDialog } from "@avalara/skylab-react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import {
    selectIsDeleting,
    deleteCertificateLabel,
    selectCannotDeleteRecordMessage,
} from "../../../../app/certificateLabelSlice";

const DeleteCertificateLabelDialog = React.memo(props => {
    const dispatch = useDispatch();
    const isDeleting = useSelector(selectIsDeleting);
    const cannotDeleteRecordMessage = useSelector(selectCannotDeleteRecordMessage);
    const [isInUse, setIsInUse] = useState(false);
    const deleteButton = classnames({
        primary: true,
        small: true,
        loading: isDeleting,
    });

    const handleDelete = async event => {
        event.preventDefault();
        const response = await dispatch(deleteCertificateLabel(props.certificateLabel.id));
        setIsInUse(!response);
        props.setIsDeleteDialogOpen(!response);
    };

    return (
        <SDialog
            id="deleteCertificateLabel-dialog"
            open={props.isDeleteDialogOpen}
            onS-dismiss={() => props.setIsDeleteDialogOpen(false)}
            aria-modal="true">
            <div slot="header" id="deleteCertificateLabel">
                {isInUse
                    ? "Can't delete this label"
                    : "Are you sure you want to delete this label?"}
            </div>
            <div slot="body">
                {isInUse ? (
                    <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{ __html: cannotDeleteRecordMessage }}
                    />
                ) : (
                    "You can't undo this action, but you can recreate this label later."
                )}
            </div>
            <div slot="footer">
                {isInUse ? (
                    <button
                        className="secondary small"
                        onClick={() => props.setIsDeleteDialogOpen(false)}>
                        Back
                    </button>
                ) : (
                    <>
                        <button
                            className="secondary small"
                            onClick={() => props.setIsDeleteDialogOpen(false)}>
                            Cancel
                        </button>
                        <button className={deleteButton} onClick={handleDelete}>
                            Yes, I&apos;m sure
                        </button>
                    </>
                )}
            </div>
        </SDialog>
    );
});

export default DeleteCertificateLabelDialog;
