function removeHyphenAlphabet(id) {
    const tempId = id.replace(/[a-z]/gi, "");
    return tempId.replace(/-/g, "");
}

function appendZero(id, count) {
    let zero = "";
    for (let i = 0; i < count; i += 1) {
        zero += "0";
    }
    return zero.concat(id);
}

const GetValidTaxIdForState = (stateCode, taxId) => {
    let acceptedId;
    switch (stateCode) {
        case "AZ":
            // Arizona accept 8 digit id
            acceptedId = removeHyphenAlphabet(taxId);
            if (acceptedId.length < 8) acceptedId = appendZero(acceptedId, 8 - acceptedId.length);
            break;
        case "AR":
            // Arkansaa accept 11 digit id
            acceptedId = removeHyphenAlphabet(taxId);
            if (acceptedId.length < 11) acceptedId = appendZero(acceptedId, 11 - acceptedId.length);
            break;
        case "CA":
            // California accept 9 digit id
            acceptedId = removeHyphenAlphabet(taxId);
            if (acceptedId.length < 9) acceptedId = appendZero(acceptedId, 9 - acceptedId.length);
            break;
        case "CO":
            // Colorado accept 8 and above
            acceptedId = removeHyphenAlphabet(taxId);
            if (acceptedId.length < 8) acceptedId = appendZero(acceptedId, 8 - acceptedId.length);
            break;
        case "FL":
            // Florida accept 13 digit id
            acceptedId = removeHyphenAlphabet(taxId);
            if (acceptedId.length < 13) acceptedId = appendZero(acceptedId, 13 - acceptedId.length);
            break;
        case "GA":
            // Georgia accept 9 digit id
            acceptedId = removeHyphenAlphabet(taxId);
            if (acceptedId.length < 9) acceptedId = appendZero(acceptedId, 9 - acceptedId.length);
            break;
        case "HI":
            // only removing hyphen and alphabet
            acceptedId = removeHyphenAlphabet(taxId);
            break;
        case "ID":
            // Idaho accept 9 digit id for sales tax
            acceptedId = removeHyphenAlphabet(taxId);
            if (acceptedId.length < 9) acceptedId = appendZero(acceptedId, 9 - acceptedId.length);
            break;
        case "IL":
            // Illinios donot accept any - or char
            acceptedId = taxId.replace(/-/g, "");
            break;
        case "KS":
            // Kansas accept 9 of 15 char long id (alphanumeric)
            acceptedId = taxId.replace(/-/g, "");
            break;
        case "LA":
            // Louisiana accept 10 to 13 digit id
            acceptedId = removeHyphenAlphabet(taxId);
            if (acceptedId.length < 10) acceptedId = appendZero(acceptedId, 10 - acceptedId.length);
            break;
        case "ME":
            // Maine 7 character alaphanumeric id
            acceptedId = taxId.replace(/-/g, "");
            break;
        case "MD":
            // Maryland accept 5 to 9 digit id
            acceptedId = removeHyphenAlphabet(taxId);
            break;
        case "MS":
            // Mississippi accept 9 digit id
            acceptedId = removeHyphenAlphabet(taxId);
            if (acceptedId.length < 9) acceptedId = appendZero(acceptedId, 9 - acceptedId.length);
            break;
        case "NC":
            // North Carolina accept 9
            acceptedId = removeHyphenAlphabet(taxId);
            if (acceptedId.length < 9) acceptedId = appendZero(acceptedId, 9 - acceptedId.length);
            break;
        case "ND":
            // North Dakota accept 5 to 9 digit id or S followed by 8 digit
            acceptedId = taxId.replace(/-/g, "");
            break;
        case "OK":
            // Oklahoma accept 6 digit id or 10 digit with STS prefixed
            acceptedId = taxId.replace(/-/g, "");
            break;
        case "TN":
            // Tennessee accept 9 to 10 digit id
            acceptedId = removeHyphenAlphabet(taxId);
            if (acceptedId.length < 9) acceptedId = appendZero(acceptedId, 9 - acceptedId.length);
            break;
        case "TX":
            // Texas accept 11 digit it
            acceptedId = removeHyphenAlphabet(taxId);
            if (acceptedId.length < 11) acceptedId = appendZero(acceptedId, 11 - acceptedId.length);
            break;
        default:
            acceptedId = taxId;
    }
    return acceptedId;
};

export default GetValidTaxIdForState;
