import React from "react";
import { Helmet } from "react-helmet-async";
import { SCol, SRow } from "@avalara/skylab-react";
import { useDispatch } from "react-redux";
import { getPageTitle } from "../../shared/sessionUtility";
import SettingsHeader from "./SettingsHeader";

const Settings = React.memo(() => {
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Settings"))}</title>
            </Helmet>
            <SRow>
                <SCol span="auto">
                    <SettingsHeader />
                </SCol>
            </SRow>
        </React.Fragment>
    );
});

export default Settings;
