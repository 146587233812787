import React from "react";
import { SDialog } from "@avalara/skylab-react";
import { useSelector, useDispatch } from "react-redux";
import { deleteBulkUploadCertificate, selectIsDeleting } from "../../../app/bulkUploadSlice";

const DeleteCertificatesZipDialog = React.memo(props => {
    const dispatch = useDispatch();
    const isDeleting = useSelector(selectIsDeleting);

    const handleDelete = event => {
        event.preventDefault();
        const response = dispatch(deleteBulkUploadCertificate(props.parentId));
        props.setIsDeleteDialogOpen(!response);
    };

    return (
        <SDialog
            id="deleteCertificatesZip-dialog"
            open={props.isDeleteDialogOpen}
            onS-dismiss={() => props.setIsDeleteDialogOpen(false)}
            aria-modal="true">
            <div slot="header" id="deleteCertificatesZip">
                Are you sure you want to delete this zip?
            </div>
            <div slot="body">You can&apos;t undo this action</div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => props.setIsDeleteDialogOpen(false)}>
                    Cancel
                </button>
                <button
                    className={`primary small ${isDeleting ? "loading" : ""}`}
                    onClick={handleDelete}>
                    Yes, I&apos;m sure
                </button>
            </div>
        </SDialog>
    );
});

export default DeleteCertificatesZipDialog;
