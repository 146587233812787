import React from "react";
import { SDialog } from "@avalara/skylab-react";
import { useSelector, useDispatch } from "react-redux";
import {
    selectIsUploadDialogOpen,
    setIsUploadDialogOpen,
    selectIsUploadDialogEllipsesMenuOpen,
    setIsUploadDialogEllipsesMenuOpen,
} from "../../app/certificateSlice";
import FVSUploader from "../sharedComponents/FVSUploader";

function FileUploaderDialog(props) {
    const dispatch = useDispatch();
    const isUploadDialogOpen = useSelector(selectIsUploadDialogOpen);
    const isUploadDialogEllipsesMenuOpen = useSelector(selectIsUploadDialogEllipsesMenuOpen);

    const closeDialog = () => {
        if (isUploadDialogEllipsesMenuOpen) {
            dispatch(setIsUploadDialogEllipsesMenuOpen(false));
        } else {
            dispatch(setIsUploadDialogOpen(false));
        }
    };

    return !props.showOnlyComponent ? (
        <SDialog
            open={isUploadDialogOpen || isUploadDialogEllipsesMenuOpen}
            id="Upload-dialog"
            onS-dismiss={() => closeDialog()}
            aria-modal="true">
            <div slot="header" id="UploadFile">
                {props.title}
            </div>
            <div slot="body">
                <FVSUploader
                    isSupplementalAttachment={props?.isSupplementalAttachment}
                    certificateID={props?.certificateID}
                    isLogo={props?.isLogo}
                    isReplace={props?.isReplace}
                    isUploaderDisabled={props?.isUploaderDisabled}
                    showRevokedAlert={props?.showRevokedAlert}
                />
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </button>
            </div>
        </SDialog>
    ) : (
        <FVSUploader
            isSupplementalAttachment={props?.isSupplementalAttachment}
            certificateID={props?.certificateID}
            isLogo={props?.isLogo}
            isReplace={props?.isReplace}
            isUploaderDisabled={props?.isUploaderDisabled}
            showRevokedAlert={props?.showRevokedAlert}
        />
    );
}
export default FileUploaderDialog;
