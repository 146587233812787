import React from "react";
import { useSelector } from "react-redux";
import { SDialog } from "@avalara/skylab-react";
import promoImage from "./assets/images/upgrade-illustration.svg";
import { selectSession } from "../../app/sessionSlice";

function PromoDialog(props) {
    const dialogName = "promo-dialog";
    const session = useSelector(selectSession);

    return (
        <SDialog
            id={dialogName}
            open={props.isPromoDialogOpen}
            onS-dismiss={() => {
                props.setIsPromoDialogOpen(false);
            }}
            aria-modal="true"
            className="dialog-style">
            <div slot="header" id="ecmPromo">
                How to get ECM Pro
            </div>
            <div slot="body" id="promo-body">
                <img id="promo-image" className="pad-right-sm" src={promoImage} alt="Get ECM Pro" />
                <div id="promo-text">
                    ECM Pro subscriptions are customized to the needs of your business. To purchase
                    an ECM Pro plan,&nbsp;
                    <a
                        id="support-link"
                        href={`https://account.avalara.com/#/a/${session.accountId}/support-cases/support-request`}>
                        open a support case
                    </a>
                    &nbsp;and an Avalara representative will be in touch promptly.
                </div>
            </div>
            <div slot="footer">
                <button
                    className="primary"
                    type="button"
                    onClick={() => props.setIsPromoDialogOpen(false)}>
                    Close
                </button>
            </div>
        </SDialog>
    );
}

export default PromoDialog;
