import React, { useEffect, useState } from "react";
import { SIcon } from "@avalara/skylab-react";
import { poll, buildApiV3Url } from "../../shared/Utils";
import magnify from "../../shared/magnifier";
import axios from "../../axios";
import "./certificate-image.css";

const CertificateImage = React.memo(props => {
    const [src, setSrc] = useState("");
    const [loaded, setLoaded] = useState(false);
    let retryCount = 5;
    const imgContainerId = "img-magnifier-container";
    const imgMagnifierId = "img-magnifier-glass";
    const imgId = "img-preview";
    const [errorDom, setErrorDom] = useState(false);
    const loadImageNotFound = () => {
        setLoaded(true);
        if (props.previewEnabled) setErrorDom(true);
        // setSrc(`/assets/document_notfound.jpg`);
        if (props.enableMagnifier && document.getElementById("img-magnifier-glass")) {
            document.getElementById("img-magnifier-glass").remove();
        }
    };

    useEffect(() => {
        if (props.src) setSrc(props.src);
    }, [props.src]);

    const validateImage = (response, error) => {
        if (!response.data) {
            if (retryCount === 0) {
                if (error.type === "error") {
                    loadImageNotFound();
                }

                if (props.onError) {
                    props.onError(error);
                }
            } else {
                retryCount -= 1;
                throw new Error("Image conversion not complete");
            }
        } else {
            setLoaded(true);
            setSrc(`${props.src.split("&rand")[0]}&rand=${Math.random()}`);
        }
    };

    const onLoad = () => {
        if (props.onLoad) {
            props.onLoad();
        }
        setLoaded(true);
        if (props.enableMagnifier) {
            if (!document.getElementById(imgMagnifierId)) {
                magnify(imgId, 2);
            }
            document.getElementById(imgContainerId).addEventListener("mouseenter", () => {
                if (document.getElementById(imgMagnifierId)) {
                    document.getElementById(imgMagnifierId).hidden = false;
                }
            });
            document.getElementById(imgContainerId).addEventListener("mouseleave", () => {
                if (document.getElementById(imgMagnifierId))
                    document.getElementById(imgMagnifierId).hidden = true;
            });
        }
    };

    const onError = error => {
        // Poll for 5 seconds
        if (props.poll) {
            setLoaded(false);
            poll(
                () =>
                    axios
                        .get(
                            buildApiV3Url(`certificates/${props.certificateID}/certificate-file`),
                            { withCredentials: true }
                        )
                        .then(response => {
                            validateImage(response, error);
                        })
                        .catch(err => {
                            if (err.response.status === 400) {
                                validateImage(null, error);
                            }
                        }),
                5,
                1000
            );
        } else {
            loadImageNotFound();
        }
    };

    return !errorDom ? (
        <div id="img-magnifier-container">
            <img
                alt="certificate-preview"
                src={src}
                id={imgId}
                className={props.className}
                hidden={!loaded}
                onLoad={() => onLoad()}
                onError={e => {
                    onError(e);
                }}
                width={props.width ? props.width : "-webkit-fill-available !important"}
            />
        </div>
    ) : (
        <div className="">
            <SIcon name="alert-triangle-filled" aria-hidden="true" className="pad-right-xs" />
            Document doesn&apos;t exist
        </div>
    );
});

export default CertificateImage;
