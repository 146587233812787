import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { SLoader, SPagination, SIcon, STag } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import RecordCount from "../RecordCount";
import PendingFilter from "./PendingFilter";
import PreviewDialog from "../../sharedDialogs/PreviewDialog";
import DeleteCertificateDialog from "../../sharedDialogs/DeleteCertificateDialog";
import { selectSession, getProduct, updateRowCountPreference } from "../../../app/sessionSlice";
import featureFlag from "../../../featureToggler/featureFlag";
import {
    selectPage,
    selectLoading,
    selectIsPostingCert,
    setSortColumn,
    selectSortColumn,
    fetchPendingCertificatesSearchAPI,
    putCertificateAssignmentAsync,
    selectIsLoadingCertificateStatuses,
    getPendingCertificateColumnList,
    selectPendingCertificateSelectedColumns,
    selectIsLoadingPendingSelectedColumns,
} from "../../../app/certificateSlice";
import "./pending.css";
import { getFormattedDate, matchSubscriptions, getRowsPerPage } from "../../../shared/Utils";
import toast from "../../../hooks/toast";
import { isEligibleUser } from "../../../shared/sessionUtility";
import ExportSearchDialog from "../../sharedDialogs/ExportSearchDialog";

function PendingSearchGrid() {
    const session = useSelector(selectSession, shallowEqual);
    const dispatch = useDispatch();
    const [showToast] = toast();
    const [submitting, setSubmitting] = useState(false);
    const loading = useSelector(selectLoading);
    const isLoadingCertificateStatuses = useSelector(selectIsLoadingCertificateStatuses);
    const isPostingCert = useSelector(selectIsPostingCert);
    const page = useSelector(selectPage);
    const pageData = page.data;
    const paginateData = page.paginate;
    const [selectedRows, setSelectedRows] = useState([]);
    const selectedColumns = useSelector(selectPendingCertificateSelectedColumns, shallowEqual);
    const isLoadingPendingSelectedColumns = useSelector(selectIsLoadingPendingSelectedColumns);
    const [rowCount, setRowCount] = useState(0);
    const [disableAccept, setDisableAccept] = useState(true);
    const [disableDelete, setDisableDelete] = useState(true);
    const [disableAssignment, setDisableAssignment] = useState(true);
    const [currentRow, setCurrentRow] = useState({});
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
    const [isDeleteCertificateDialogOpen, setIsDeleteCertificateDialogOpen] = useState(false);
    const [customerRows, setCustomerRows] = useState({});
    const sortColumn = useSelector(selectSortColumn);
    let customerTableMap = [];
    const openPreviewModal = row => {
        setCurrentRow(row);
        setIsPreviewDialogOpen(true);
    };
    const signedInUser = session?.username;
    const getProductInfo = useSelector(getProduct);
    const toggleCustomerRow = customerRow => {
        const rowId = customerRow.certificateId;
        let collapsed = true;
        if (!customerRows[rowId] || customerRows[rowId].collapsed) {
            collapsed = false;
        }
        setCustomerRows(prevState => ({
            ...prevState,
            [rowId]: { ...prevState[rowId], collapsed },
        }));
    };

    useEffect(() => {
        dispatch(getPendingCertificateColumnList());
    }, [dispatch]);

    const deleteCertificates = () => {
        setSubmitting(true);
        dispatch(fetchPendingCertificatesSearchAPI());
        setSelectedRows([]);
        setDisableDelete(true);
        setDisableAccept(true);
        setRowCount(0);
        setSubmitting(false);
    };

    const assignCertificate = async e => {
        setSubmitting(true);
        setDisableAssignment(true);
        setDisableDelete(true);
        if (e.target.id === "release-certificate-btn") {
            await dispatch(putCertificateAssignmentAsync(selectedRows, false, showToast));
        } else if (e.target.id === "assign-certificate-btn") {
            await dispatch(putCertificateAssignmentAsync(selectedRows, true, showToast));
        }
        await dispatch(fetchPendingCertificatesSearchAPI());
        setSelectedRows([]);
        setRowCount(0);
        setSubmitting(false);
    };
    function calculateDays(escalationDate) {
        if (!escalationDate) return null;
        const currentDate = new Date();
        const escDate = new Date(escalationDate);
        if (getFormattedDate(escalationDate) === getFormattedDate(currentDate))
            return (
                <STag color="blue-light" className="font-bold" id="escalated">
                    <span slot="content">Today</span>
                </STag>
            );
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const diffDays = Math.round(Math.abs((currentDate - escDate) / oneDay));
        return (
            <STag color="blue-light" className="font-bold text-ellipsis" id="escalated">
                <span slot="content">
                    {diffDays} {diffDays === 1 ? "day" : "days"} ago
                </span>
            </STag>
        );
    }

    const handleChangeSelection = value => {
        if (value.length === 0) {
            setDisableDelete(true);
            setDisableAccept(true);
            setDisableAssignment(true);
            setSelectedRows([]);
        } else {
            setDisableDelete(false);
            setDisableAccept(false);
            setDisableAssignment(false);
        }
        setRowCount(value.length);

        setSelectedRows(value);
    };

    function assignedToValueFunction(userName) {
        let assignedToValue = null;
        let tagColor = "";
        if (userName === null) {
            assignedToValue = "Unassigned";
            tagColor = "blue-light";
        } else if (userName.toLowerCase() === signedInUser?.toLowerCase()) {
            assignedToValue = "Me";
            tagColor = "green-light";
        } else {
            assignedToValue = userName;
            return <span>{assignedToValue}</span>;
        }
        return (
            <STag color={tagColor} id="assigned-to" className="font-bold">
                <span slot="content">{assignedToValue}</span>
            </STag>
        );
    }

    async function sortFunction(e) {
        setSubmitting(true);
        const columnId = e.detail.id;
        const order = sortColumn[0] === columnId ? !sortColumn[1] : true;
        dispatch(setSortColumn([columnId, order]));
        await dispatch(fetchPendingCertificatesSearchAPI(paginateData.rowsPerPage, 1, false, null));
        setSubmitting(false);
    }

    const columns = [
        gridHeader(
            "Date",
            "createdDate",
            value => new Date(value).toLocaleDateString(),
            null,
            null,
            null,
            null,
            sortFunction,
            "created-date"
        ),
        gridHeader(
            "Customer name",
            "[customers]",
            (value, row) =>
                row.customers.length > 1 ? (
                    <div>
                        {customerRows[row.certificateId] &&
                        !customerRows[row.certificateId].collapsed ? (
                            <s-table-container class="no-border name-table">
                                <table className="row-height-sm borderless" role="presentation">
                                    <tbody>
                                        {Object.keys(row.customers).map((v, idx) => {
                                            customerTableMap.push(
                                                <tr key={row.customers[idx]}>
                                                    <td className="pad-left-none">
                                                        {row.customers[idx].name}
                                                    </td>
                                                </tr>
                                            );

                                            const returnMap = customerTableMap;
                                            customerTableMap = [];
                                            return returnMap;
                                        })}
                                    </tbody>
                                </table>
                            </s-table-container>
                        ) : null}
                    </div>
                ) : (
                    row.customers[0] && <span>{row.customers[0].name}</span>
                ),
            null,
            null,
            null,
            null,
            null,
            "customer-name"
        ),
        gridHeader(
            "Customer code",
            "[customers]",
            (value, row) =>
                row.customers.length > 1 ? (
                    <div>
                        <button className="link" onClick={() => toggleCustomerRow(row)}>
                            {`${row.customers.length} customers`}
                            {customerRows[row.id] && !customerRows[row.id].collapsed ? (
                                <SIcon
                                    name="chevron-up"
                                    className="pad-left-xs top-xs"
                                    aria-hidden="true"
                                />
                            ) : (
                                <SIcon
                                    name="chevron-down"
                                    className="pad-left-xs top-xs"
                                    aria-hidden="true"
                                />
                            )}
                        </button>

                        {customerRows[row.certificateId] &&
                        !customerRows[row.certificateId].collapsed ? (
                            <s-table-container class="no-border margin-top-sm">
                                <table className="row-height-sm borderless" role="presentation">
                                    <tbody>
                                        {Object.keys(row.customers).map((v, idx) => {
                                            customerTableMap.push(
                                                <tr key={row.customers[idx]}>
                                                    <td className="pad-left-none">
                                                        <Link
                                                            to={`/customer/${row.customers[idx].id}`}>
                                                            <button className="link">
                                                                {row.customers[idx].customerNumber}
                                                            </button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );

                                            const returnMap = customerTableMap;
                                            customerTableMap = [];
                                            return returnMap;
                                        })}
                                    </tbody>
                                </table>
                            </s-table-container>
                        ) : null}
                    </div>
                ) : (
                    row.customers[0] && (
                        <Link to={`/customer/${row.customers[0].id}`}>
                            <button className="link">{row.customers[0].customerNumber}</button>
                        </Link>
                    )
                ),
            null,
            null,
            null,
            null,
            null,
            "customer-code"
        ),
        gridHeader(
            "Region",
            "exposureZoneName",
            value => <span id="region-field">{value}</span>,
            null,
            "",
            null,
            null,
            sortFunction,
            "exposure-zone-name"
        ),

        gridHeader(
            "File name",
            "origFileName",
            value => value,
            null,
            "",
            null,
            null,
            sortFunction,
            "orig-file-name"
        ),

        gridHeader(
            "Preview",
            "id",
            (value, row) => (
                <button className="link font-bold" onClick={() => openPreviewModal(row)}>
                    <SIcon
                        name="zoom-in"
                        aria-label="zoom-in"
                        className={row.certificateFilename ? "blue-icon" : "disabled-icon"}
                    />
                </button>
            ),
            "text-center",
            "text-center",
            null,
            null,
            null,
            "preview"
        ),
        gridHeader(
            "Source",
            "source",
            value => value,
            null,
            "",
            null,
            null,
            sortFunction,
            "source"
        ),

        // TODO: "Uploaded by" should only be visible the service user
        gridHeader(
            "Uploaded by",
            "uploadedBy",
            value => <span id="uploadedBy-field">{value}</span>,
            null,
            "",
            null,
            null,
            sortFunction,
            "uploaded-by"
        ),
    ];

    if (
        matchSubscriptions(
            featureFlag.reviewCertificate.assignRelease.subscription,
            getProductInfo
        ) ||
        isEligibleUser(featureFlag.reviewCertificate.assignRelease)
    ) {
        columns.push(
            gridHeader(
                "Assigned to",
                "assignedToUserName",
                value => <React.Fragment>{assignedToValueFunction(value)}</React.Fragment>,
                null,
                "",
                null,
                null,
                sortFunction,
                "assigned-to-user-name"
            )
        );
        columns.push(
            gridHeader(
                "Escalation",
                "escalationDate",
                value => <React.Fragment>{calculateDays(value)}</React.Fragment>,
                null,
                "",
                null,
                null,
                sortFunction,
                "escalation-date"
            )
        );
    }
    // To insert the last column - Review Button
    columns.push(
        gridHeader(
            "",
            "",
            (value, row) => (
                <Link to={`/review/${row.certificateId}`}>
                    <button type="button" className="primary small">
                        Review
                    </button>
                </Link>
            ),
            "text-right",
            "text-right",
            null,
            null,
            null,
            null
        )
    );

    let modifiedColumns = [];

    const arrangeColumns = columnlist => {
        const visibleColumns = columnlist?.filter(a => a.hidden === false);
        const tempCol = [modifiedColumns[0]];
        for (let j = 0; j < visibleColumns.length; ) {
            const item = modifiedColumns.find(obj => {
                return obj.id === visibleColumns[j].id;
            });
            tempCol.push(item);
            j += 1;
        }
        tempCol.push(modifiedColumns[modifiedColumns.length - 1]);
        modifiedColumns = tempCol;
    };

    const manageColumns = () => {
        modifiedColumns = columns;
        selectedColumns?.forEach(col => {
            if (col.hidden) {
                modifiedColumns = modifiedColumns.filter(obj => {
                    return obj.id !== col.id;
                });
            }
        });
        arrangeColumns(selectedColumns);

        return modifiedColumns;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            await dispatch(
                fetchPendingCertificatesSearchAPI(
                    e.detail.rowsPerPage,
                    e.detail.currentPage,
                    false,
                    e.detail
                )
            );
            setSubmitting(false);
            setSelectedRows([]);
            setRowCount(0);
        }
    };

    const getRowKey = useCallback(row => {
        return row.certificateId;
    }, []);

    const tableHeader = (
        <PendingFilter
            disableAccept={disableAccept}
            disableDelete={disableDelete}
            disableAssignment={disableAssignment}
            setSelectedRows={setSelectedRows}
            setIsDeleteCertificateDialogOpen={setIsDeleteCertificateDialogOpen}
            setRowCount={setRowCount}
            setDisableDelete={setDisableDelete}
            setDisableAssignment={setDisableAssignment}
            certificateIds={selectedRows}
            assignCertificate={assignCertificate}
        />
    );

    const table =
        submitting ||
        loading ||
        isPostingCert ||
        isLoadingCertificateStatuses ||
        isLoadingPendingSelectedColumns ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <>
                <RecordCount
                    selectedCount={rowCount}
                    recordCount={new Intl.NumberFormat().format(paginateData.totalRecords)}
                    recordName="certificates"
                />
                <s-table-container>
                    <DataGrid
                        columns={manageColumns(selectedColumns)}
                        rows={pageData}
                        getKey={getRowKey}
                        loading={submitting}
                        inContainer
                        options={{ id: "certificateId", showRowSelect: true }}
                        onChangeSelection={handleChangeSelection}
                        sortColumn={sortColumn}
                    />
                </s-table-container>
            </>
        );

    let pagination = null;
    if (paginateData.totalRecords > 0 && paginateData.indeterminate) {
        pagination = (
            <SPagination
                className="margin-top-md"
                rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                totalRecords={paginateData.totalRecords}
                indeterminate
                disabled
                startIndex={paginateData.startIndex}
                onS-paginate={e => {
                    handlePaginate(e);
                }}
            />
        );
    } else if (paginateData.totalRecords > 0) {
        pagination = (
            <SPagination
                className="margin-top-md"
                rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                totalRecords={paginateData.totalRecords}
                startIndex={paginateData.startIndex}
                onS-paginate={e => {
                    handlePaginate(e);
                }}
            />
        );
    }
    return (
        <React.Fragment>
            {tableHeader}
            {table}
            {pagination}
            {currentRow.certificateId && isPreviewDialogOpen ? (
                <PreviewDialog
                    setIsPreviewDialogOpen={setIsPreviewDialogOpen}
                    isPreviewDialogOpen={isPreviewDialogOpen}
                    certificateID={currentRow.certificateId}
                    certificateName={
                        currentRow.s3DocumentExists && currentRow.certificateFilename
                            ? currentRow.certificateFilename
                            : ""
                    }
                />
            ) : null}

            <DeleteCertificateDialog
                isDeleteCertificateDialogOpen={isDeleteCertificateDialogOpen}
                setIsDeleteCertificateDialogOpen={setIsDeleteCertificateDialogOpen}
                certificateIds={selectedRows}
                handleParentDelete={deleteCertificates}
                bulkDelete
            />

            <ExportSearchDialog exportType="Pending-Certificate" />
        </React.Fragment>
    );
}
export default PendingSearchGrid;
