import React from "react";
import { SRow, SCol, SDialog } from "@avalara/skylab-react";
import Parser from "html-react-parser";
import "../taxContent.css";

export const dialogHeader = "Source Text";
export const dialogName = "citationsDialog";

function CitationsDialog(props) {
    const onSDismiss = () => {
        props.setIsCitationsDialogOpen(false);
    };

    return props.isCitationsDialogOpen ? (
        <SDialog
            id={dialogName}
            open={props.isCitationsDialogOpen}
            onS-dismiss={onSDismiss}
            aria-modal="true">
            <div slot="header" id="citations">
                {dialogHeader}
            </div>
            <div slot="body" className="citations-modal-body">
                <span className="font-bold line-height-lg citation-font">{props.text.name}</span>
                <SRow>
                    <SCol>
                        <div>{Parser(props.text.content)}</div>
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button className="primary small" onClick={onSDismiss}>
                    Close
                </button>
            </div>
        </SDialog>
    ) : null;
}

export default CitationsDialog;
