import { SAlert, SCol, SRow, STabs, SLoader } from "@avalara/skylab-react";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Outlet, useNavigate, useLocation, useParams } from "react-router-dom";
import {
    fetchMultiCertificateSameAs,
    setCertificate,
    clearValidationData,
    selectIsMulti,
    selectCustomerCert,
    setIsResumeCertificateDialogOpen,
    setIsPauseCertificateDialogOpen,
    selectLoading,
    selectIsLoadingMulti,
    setHasCertificateFile,
    setIsInvalidReasonDialogOpen,
} from "../../../app/certificateSlice";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import "../../search/certificates/certificate.css";
import { invalidReasonDialogID } from "../../../shared/constants";

function Certificate() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const idFromParam = !Number.isNaN(Number(params?.certificateID))
        ? Number(params.certificateID)
        : 0;
    const dispatch = useDispatch();
    const [certificateID, setCertificateID] = useState(-1);
    const certificate = useSelector(selectCustomerCert, shallowEqual);
    const isLoading = useSelector(selectLoading);
    const isLoadingMulti = useSelector(selectIsLoadingMulti);
    const isMulti = useSelector(selectIsMulti);
    const [parentId, setParentId] = useState(0);
    const [showPauseAlert, setShowPauseAlert] = useState(false);
    const [showRevokedAlert, setShowRevokedAlert] = useState(false);
    const [showInvalidReasonsAlert, setshowInvalidReasonsAlert] = useState(false);
    const isEligibleUserValidatedCertificateValidate = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.validate)
    );
    const isEligibleUserValidatedCertificatePauseResume = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.pauseResume)
    );

    const certificateTab = "tabpanel-certificate";
    const historyTab = "tabpanel-history";

    useEffect(() => {
        setCertificateID(prevCertificateID => {
            if (prevCertificateID === -1 && idFromParam) {
                return idFromParam;
            }
            return prevCertificateID;
        });
    }, [idFromParam]);

    const [tabItems, setTabItems] = useState([
        {
            id: certificateTab,
            label: "Certificate",
            tabPanelId: certificateTab,
            selected: false,
        },
        {
            id: historyTab,
            label: "History",
            tabPanelId: historyTab,
            selected: false,
        },
    ]);

    useEffect(() => {
        const updatedTabs = tabItems.map(tab => ({
            ...tab,
            selected:
                (location.pathname.includes("/history") && tab.tabPanelId === historyTab) ||
                (certificateID !== -1 && tab.tabPanelId === certificateTab),
        }));
        setTabItems(updatedTabs);
    }, [certificateID, location.pathname]);

    const pausedReasonObj = certificate?.invalidReasons?.findIndex(obj => {
        return obj.name === "PAUSED";
    });

    useEffect(() => {
        if (certificate?.certificateStatus === "REVOKED") {
            setShowRevokedAlert(true);
            setShowPauseAlert(false);
            setshowInvalidReasonsAlert(false);
        } else if (certificate?.invalidReasons?.length > 0) {
            if (pausedReasonObj >= 0) {
                setShowPauseAlert(true);
                setshowInvalidReasonsAlert(false);
                setShowRevokedAlert(false);
            } else {
                setShowPauseAlert(false);
                setshowInvalidReasonsAlert(true);
                setShowRevokedAlert(false);
            }
        } else {
            setShowPauseAlert(false);
            setshowInvalidReasonsAlert(false);
            setShowRevokedAlert(false);
        }
    }, [certificate?.invalidReasons?.length, pausedReasonObj]);

    useEffect(() => {
        async function fetchMulti() {
            if (certificateID !== -1) {
                const certParentId = Number(
                    await dispatch(fetchMultiCertificateSameAs(certificateID))
                );
                // eslint-disable-next-line
                if (certParentId !== 0 && certParentId !== certificateID)
                    window.open(`/certificate/${certParentId}`, "_self");
                else if (certParentId === certificateID) {
                    setParentId(certParentId);
                }
            }
        }
        fetchMulti();
    }, [dispatch, certificateID]);

    useEffect(() => {
        return () => {
            dispatch(setCertificate([]));
            dispatch(clearValidationData({ fileType: "", data: "", fileName: "" }));
            dispatch(setHasCertificateFile(false));
        };
    }, [dispatch]);

    function navigateTo(e) {
        const path = e.detail.id.includes("history")
            ? `/certificate/history/${certificateID}`
            : `/certificate/${certificateID}`;
        navigate(path);
    }

    const closeInvalidReasonDialog = useCallback(() => {
        document.getElementById(invalidReasonDialogID).close();
        dispatch(setIsInvalidReasonDialogOpen(false));
    }, []);

    const openInvalidReasonDialog = useCallback(() => {
        document.getElementById(invalidReasonDialogID).showModal();
        dispatch(setIsInvalidReasonDialogOpen(true));
    }, []);

    const openResumeCertificateDialog = () => {
        dispatch(setIsPauseCertificateDialogOpen(false));
        dispatch(setIsResumeCertificateDialogOpen(true));
    };

    let ValidationDOM = null;
    if (isLoadingMulti || parentId !== certificateID) {
        ValidationDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" aria-live="polite" />
            </div>
        );
    } else {
        ValidationDOM = (
            <React.Fragment>
                <Helmet>
                    <title>{dispatch(getPageTitle("Validation"))}</title>
                </Helmet>
                <SRow>
                    <SCol span="11">
                        {certificateID ? (
                            <h1 hidden={!certificateID} className="margin-bottom-xl">
                                Exemption certificate {certificateID}
                            </h1>
                        ) : (
                            <h1 hidden={certificateID} className="margin-bottom-xl">
                                Review the certificate
                            </h1>
                        )}
                    </SCol>
                </SRow>
                {isMulti ? (
                    <SRow>
                        <SCol span="auto">
                            <SAlert status="info" role="status" nodismiss="">
                                <div className="alert-maxWidth">
                                    This is a part of group of certificates. Changes made to this
                                    certificate are applied to the other associated certificates.
                                    Learn about{" "}
                                    <a
                                        href="https://help.avalara.com/Avalara_Exemption_Certificate_Management_Pro/Multijurisdictional_certificates"
                                        target="_blank"
                                        rel="noreferrer">
                                        multijurisdictional certificates
                                    </a>
                                </div>
                            </SAlert>
                        </SCol>
                    </SRow>
                ) : null}
                {showPauseAlert && !isLoading ? (
                    <SRow>
                        <SCol span="auto">
                            <SAlert status="warning" role="alert" nodismiss="">
                                <div className="alert-maxWidth">
                                    This certificate is paused. It is not being applied to
                                    transactions
                                    <FeatureToggler
                                        category="validatedCertificate"
                                        id="pauseResume">
                                        <button
                                            disabled={
                                                !isEligibleUserValidatedCertificatePauseResume
                                            }
                                            className="link inline"
                                            onClick={openResumeCertificateDialog}>
                                            <span className="margin-left-xs">
                                                Resume use of this certificate
                                            </span>
                                        </button>
                                    </FeatureToggler>
                                </div>
                            </SAlert>
                        </SCol>
                    </SRow>
                ) : null}

                {showRevokedAlert && !isLoading ? (
                    <SRow>
                        <SCol span="auto">
                            <SAlert status="warning" role="alert" nodismiss="">
                                <div>
                                    This certificate has been revoked, which is legacy status that
                                    cannot be changed. It is not being applied to transactions.
                                </div>
                            </SAlert>
                        </SCol>
                    </SRow>
                ) : null}

                {!isLoading && showInvalidReasonsAlert ? (
                    <SRow>
                        <SCol span="auto">
                            <SAlert status="warning" role="alert" nodismiss="">
                                <div className="">
                                    This certificate is invalid because:{" "}
                                    {certificate.invalidReasons
                                        ?.map(invalidReason => invalidReason.name)
                                        .join(", ")}
                                    .
                                    <FeatureToggler category="validatedCertificate" id="validate">
                                        <button
                                            className="link inline"
                                            disabled={!isEligibleUserValidatedCertificateValidate}
                                            onClick={() => openInvalidReasonDialog()}>
                                            <span className="margin-left-xs">
                                                Change the reason
                                            </span>
                                        </button>
                                    </FeatureToggler>
                                </div>
                            </SAlert>
                        </SCol>
                    </SRow>
                ) : null}

                <SRow>
                    <SCol span="auto">
                        <STabs tabItems={tabItems} onS-select={e => navigateTo(e)} />
                        <Outlet
                            context={{
                                isMulti,
                                pausedReasonObj,
                                showPauseAlert,
                                showRevokedAlert,
                                closeInvalidReasonDialog,
                                openInvalidReasonDialog,
                            }}
                        />
                    </SCol>
                </SRow>
            </React.Fragment>
        );
    }
    return ValidationDOM;
}
export default Certificate;
