import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { SDialog, SAlert } from "@avalara/skylab-react";
import classnames from "classnames";
import {
    selectIsLoadingSaveMatrixSetting,
    putDefaultMatrixSetting,
    setRequestingCertificateFormsDialogOpen,
    putResetDefaultMatrixSetting,
    setCertificateFormsDialogOpen,
} from "../../../../app/settingsSlice";

function SaveResetConfirmationDialog(props) {
    const isSaveMatrixSetting = useSelector(selectIsLoadingSaveMatrixSetting);
    const saveButton = classnames({
        primary: true,
        small: true,
        loading: isSaveMatrixSetting,
    });
    const dispatch = useDispatch();
    const saveResetMatrixSetting = async () => {
        props.setIsSaveConfirmationDialogOpen(false);
        document.getElementById("saveResetConfirmationDialog")?.close();
        dispatch(setRequestingCertificateFormsDialogOpen(false));
        document.getElementById("requestingCertificateForms-dialog")?.close();
        dispatch(setCertificateFormsDialogOpen(false));
        document.getElementById("certificateForms-dialog")?.close();

        if (props?.title === "Update") {
            await dispatch(putDefaultMatrixSetting(props.certificateID));
        } else {
            await dispatch(putResetDefaultMatrixSetting(props.certificateID));
        }
    };

    return (
        <SDialog
            id="saveResetConfirmationDialog"
            open={props.isDeleteCertificateDialogOpen}
            onS-dismiss={() => {
                props.setIsSaveConfirmationDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="resetMatrixSettings">
                {props.title} the default matrix settings?
            </div>

            <div slot="body">
                <SAlert id="delete-dialog" status="warning" role="alert" noDismiss>
                    {props.message}
                </SAlert>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    disabled={isSaveMatrixSetting}
                    onClick={() => props.setIsSaveConfirmationDialogOpen(false)}>
                    No, cancel
                </button>
                <button
                    id="save-confirm"
                    className={saveButton}
                    type="button"
                    onClick={saveResetMatrixSetting}>
                    Yes, save
                </button>
            </div>
        </SDialog>
    );
}

export default SaveResetConfirmationDialog;
