import { createSelector, createSlice } from "@reduxjs/toolkit";
import { forIn } from "lodash";
import { Buffer } from "buffer";
import axios from "../axios";
import axiosInstanceWithoutInterceptor from "../axiosWithoutInterceptor";
import toast from "../hooks/toast";
import {
    buildApiV3UrlWithQuery,
    buildApiV2Point5UrlWithQuery,
    buildApiV3Url,
    transformExemptionData,
} from "../shared/Utils";
import { errorResponse } from "../shared/responseUtils";
import { ecmRole } from "../features/navigation/Subscription";
import getConfig from "../config";

const { hostnames } = getConfig();
const { docLockerClient, certExpress } = hostnames;

export const settingsSlice = createSlice({
    name: "setting",
    initialState: {
        isLoading: false,
        settingData: [],
        isLoadingCertificateRequest: false,
        certificateRequest: [],
        isSavingSettings: false,
        isFetchingLogo: false,
        isSavingLogo: false,
        isDeletingLogo: false,
        companyLogo: { fileType: "", data: "", fileName: "" },
        isCompanyLogoPresent: false,
        isLoadingCertificateRequestForm: false,
        isLoadingSaveMatrixSetting: false,
        certificateFormsDialogOpen: false,
        requestingCertificateFormsDialogOpen: false,
        matrixSetting: {},
        resetMatrixSetting: {},
        isSignatureLoading: false,
        signatureData: {},
        collectionRegions: {},
        collectionInfo: {},
        isLoadingDocumentType: false,
        documentTypeInfo: {},
        isLoadingCollectionRegions: false,
        isOutgoingMatrixSet: true,
        matrixCheckLoading: false,
        requestCertificateRegions: { regionId: "", regionName: "" },
        requestCertificateReasons: { reasonId: "", reasonName: "" },
        sendingCertificateRegions: { regionId: "", regionName: "" },
        sendingCertificateReasons: { reasonId: "", reasonName: "" },
        defaultExist: false,
        isRequestingAvalaraDefault: false,
        isSendingAvalaraDefault: false,
        noDataType: "",
        corporateEmail: "",
        equipmentDescription: "",
        isLoadingMatrixFile: false,
        isSavingMatrixFile: false,
        matrixFile: "",
        isSendingStaticDocument: false,
        page: {
            data: [],
            paginate: {
                pageNumber: 0,
                startIndex: -1,
                endIndex: 0,
                rowsPerPage: 10,
                totalRecords: 0,
            },
        },
        publicCertExpressToken: "",
        sortColumn: ["region.name", true],
        isRemoveRegionReasonLoading: false,
        isSignChange: false,
        addNewSignature: false,
        userInfo: {
            id: null,
            subjectId: null,
            userName: null,
            firstName: null,
            lastName: null,
            email: null,
            isActive: null,
            userRoles: null,
        },
        isLoadingUserInfo: false,
        isLoadingupdateRole: false,
    },

    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setSetting: (state, action) => {
            state.settingData = action.payload;
        },
        setIsLoadingCertificateRequest: (state, action) => {
            state.isLoadingCertificateRequest = action.payload;
        },
        setCertificateRequest: (state, action) => {
            state.certificateRequest = action.payload;
        },
        setCompanyLogo: (state, action) => {
            state.companyLogo = action.payload;
        },
        setIsSavingSettings: (state, action) => {
            state.isSavingSettings = action.payload;
        },
        setIsFetchingLogo: (state, action) => {
            state.isFetchingLogo = action.payload;
        },
        setIsSavingLogo: (state, action) => {
            state.isSavingLogo = action.payload;
        },
        setIsDeletingLogo: (state, action) => {
            state.isDeletingLogo = action.payload;
        },
        setIsCompanyLogoPresent: (state, action) => {
            state.isCompanyLogoPresent = action.payload;
        },
        setIsLoadingCertificateRequestForm: (state, action) => {
            state.isLoadingCertificateRequestForm = action.payload;
        },

        setIsLoadingSaveMatrixSetting: (state, action) => {
            state.isLoadingSaveMatrixSetting = action.payload;
        },

        setMatrixSetting: (state, action) => {
            state.matrixSetting = action.payload;
        },
        setResetMatrixSetting: (state, action) => {
            state.resetMatrixSetting = action.payload;
        },
        setCertificateFormsDialogOpen: (state, action) => {
            state.certificateFormsDialogOpen = action.payload;
        },
        setRequestingCertificateFormsDialogOpen: (state, action) => {
            state.requestingCertificateFormsDialogOpen = action.payload;
        },
        setCollectionRegions: (state, action) => {
            state.collectionRegions = action.payload;
        },
        setIsLoadingCollectionRegion: (state, action) => {
            state.isLoadingCollectionRegions = action.payload;
        },
        setMatrixCheckLoading: (state, action) => {
            state.matrixCheckLoading = action.payload;
        },
        setIsOutgoingMatrixSet: (state, action) => {
            state.isOutgoingMatrixSet = action.payload;
        },
        setCollectionInfo: (state, action) => {
            state.collectionInfo = action.payload;
        },
        setLoadingDocumentType: (state, action) => {
            state.isLoadingDocumentType = action.payload;
        },
        setDocumentTypeInfo: (state, action) => {
            state.documentTypeInfo = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: (state, action) => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: action?.payload,
                    totalRecords: 0,
                },
            };
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setRequestCertificateRegions: (state, action) => {
            state.requestCertificateRegions = action.payload;
        },
        setRequestCertificateReasons: (state, action) => {
            state.requestCertificateReasons = action.payload;
        },

        setSendingCertificateRegions: (state, action) => {
            state.sendingCertificateRegions = action.payload;
        },
        setSendingCertificateReasons: (state, action) => {
            state.sendingCertificateReasons = action.payload;
        },
        setIsRequestingAvalaraDefault: (state, action) => {
            state.isRequestingAvalaraDefault = action.payload;
        },
        setIsSendingAvalaraDefault: (state, action) => {
            state.isSendingAvalaraDefault = action.payload;
        },
        setSignatureLoading: (state, action) => {
            state.isSignatureLoading = action.payload;
        },
        setSignatureData: (state, action) => {
            state.signatureData = action.payload;
        },
        setCorporateEmail: (state, action) => {
            state.corporateEmail = action.payload;
        },
        setEquipmentDescription: (state, action) => {
            state.equipmentDescription = action.payload;
        },
        setLoadingMatrixFile: (state, action) => {
            state.isLoadingMatrixFile = action.payload;
        },
        setSavingMatrixFile: (state, action) => {
            state.isSavingMatrixFile = action.payload;
        },
        setMatrixFile: (state, action) => {
            state.matrixFile = action.payload;
        },
        setIsSendingStaticDocument: (state, action) => {
            state.isSendingStaticDocument = action.payload;
        },
        setDefaultExist: (state, action) => {
            state.defaultExist = action.payload;
        },
        setPublicCertExpressToken: (state, action) => {
            state.publicCertExpressToken = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setIsRemoveRegionReasonLoading: (state, action) => {
            state.isRemoveRegionReasonLoading = action.payload;
        },
        setIsSignChange: (state, action) => {
            state.isSignChange = action.payload;
        },
        setAddNewSignature: (state, action) => {
            state.addNewSignature = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        setIsLoadingUserInfo: (state, action) => {
            state.isLoadingUserInfo = action.payload;
        },
        setIsLoadingupdateRole: (state, action) => {
            state.isLoadingupdateRole = action.payload;
        },
    },
});

export const {
    setLoading,
    setSetting,
    setIsLoadingCertificateRequest,
    setCertificateRequest,
    setIsSavingSettings,
    setIsFetchingLogo,
    setIsSavingLogo,
    setIsDeletingLogo,
    setCompanyLogo,
    setIsCompanyLogoPresent,
    setIsLoadingCertificateRequestForm,
    setIsLoadingSaveMatrixSetting,
    setCertificateFormsDialogOpen,
    setRequestingCertificateFormsDialogOpen,
    setPage,
    setPageToNull,
    setNoDataType,
    setRequestCertificateRegions,
    setRequestCertificateReasons,
    setSendingCertificateRegions,
    setSendingCertificateReasons,
    setIsRequestingAvalaraDefault,
    setIsSendingAvalaraDefault,
    setMatrixSetting,
    setResetMatrixSetting,
    setIsLoadingCollectionRegion,
    setMatrixCheckLoading,
    setIsOutgoingMatrixSet,
    setCollectionRegions,
    setCollectionInfo,
    setLoadingDocumentType,
    setDocumentTypeInfo,
    setSignatureLoading,
    setSignatureData,
    setCorporateEmail,
    setEquipmentDescription,
    setLoadingMatrixFile,
    setSavingMatrixFile,
    setMatrixFile,
    setIsSendingStaticDocument,
    setDefaultExist,
    setPublicCertExpressToken,
    setSortColumn,
    setIsRemoveRegionReasonLoading,
    setIsSignChange,
    setAddNewSignature,
    setUserInfo,
    setIsLoadingUserInfo,
    setIsLoadingupdateRole,
} = settingsSlice.actions;

export const selectAddNewSignature = state => {
    return state.setting.addNewSignature;
};

export const selectIsSignChange = state => {
    return state.setting.isSignChange;
};

export const selectIsCompanyLogoPresent = state => {
    return state.setting.isCompanyLogoPresent;
};

export const selectIsSavingSettings = state => {
    return state.setting.isSavingSettings;
};

export const selectIsFetchingLogo = state => {
    return state.setting.isFetchingLogo;
};

export const selectIsSavingLogo = state => {
    return state.setting.isSavingLogo;
};

export const selectIsDeletingLogo = state => {
    return state.setting.isDeletingLogo;
};

export const selectCompanyLogo = state => {
    return state.setting.companyLogo;
};

export const selectIsLoading = state => {
    return state.setting.isLoading;
};

export const selectSetting = createSelector(
    state => state.setting,
    setting => {
        if (setting.settingData?.length === 0) {
            if (localStorage.getItem("generalSettings")) {
                return JSON.parse(localStorage.getItem("generalSettings"));
            }
        }
        return setting.settingData;
    }
);

export const selectCertificateRequest = state => {
    return state.setting.certificateRequest;
};

export const getCertificateRequest = () => (dispatch, getState) => {
    const { setting } = getState();
    return setting.certificateRequest;
};

export const selectIsLoadingCertificateRequestForm = state => {
    return state.setting.isLoadingCertificateRequestForm;
};

export const selectIsLoadingSaveMatrixSetting = state => {
    return state.setting.isLoadingSaveMatrixSetting;
};

export const selectCertificateFormsDialogOpen = state => {
    return state.setting.certificateFormsDialogOpen;
};

export const selectRequestingCertificateFormsDialogOpen = state => {
    return state.setting.requestingCertificateFormsDialogOpen;
};

export const selectPage = state => {
    return state.setting.page;
};

export const selectRequestCertificateRegions = state => {
    return state.setting.requestCertificateRegions;
};

export const selectRequestCertificateReasons = state => {
    return state.setting.requestCertificateReasons;
};

export const selectSendingCertificateRegions = state => {
    return state.setting.sendingCertificateRegions;
};

export const selectSendingCertificateReasons = state => {
    return state.setting.sendingCertificateReasons;
};
export const selectIsRequestingAvalaraDefault = state => {
    return state.setting.isRequestingAvalaraDefault;
};
export const selectIsSendingAvalaraDefault = state => {
    return state.setting.isSendingAvalaraDefault;
};

export const selectNoDataType = state => {
    return state.setting.noDataType;
};

export const selectLoadingSignature = state => {
    return state.setting.isSignatureLoading;
};

export const selectSignatureData = state => {
    return state.setting.signatureData;
};

export const selectLoadingCollectionRegions = state => {
    return state.setting.isLoadingCollectionRegions;
};

export const selectIsOutgoingMatrixSet = state => {
    return state.setting.isOutgoingMatrixSet;
};

export const selectMatrixCheckLoading = state => {
    return state.setting.matrixCheckLoading;
};

export const selectCollectionRegions = state => {
    return state.setting.collectionRegions;
};

export const selectLoadingDocumentType = state => {
    return state.setting.isLoadingDocumentType;
};

export const selectDocumentTypeInfo = state => {
    return state.setting.documentTypeInfo;
};

export const selectCollectionInfo = state => {
    return state.setting.collectionInfo;
};

export const selectIsLoadingMatrixFile = state => {
    return state.setting.isLoadingMatrixFile;
};

export const selectMatrixFile = state => {
    return state.setting.matrixFile;
};

export const selectIsSendingStaticDocument = state => {
    return state.setting.isSendingStaticDocument;
};

export const selectIsSavingMatrixFile = state => {
    return state.setting.isSavingMatrixFile;
};

export const selectDefaultExist = state => {
    return state.setting.defaultExist;
};

export const selectPublicCertExpressToken = state => {
    return state.setting.publicCertExpressToken;
};
export const selectSortColumn = state => {
    return state.setting.sortColumn;
};

export const selectIsRemoveRegionReasonLoading = state => {
    return state.setting.isRemoveRegionReasonLoading;
};

export const selectUserInfo = state => {
    return state.setting.userInfo;
};

export const selectIsLoadingUserInfo = state => {
    return state.setting.isLoadingUserInfo;
};

export const selectIsLoadingUpdateRole = state => {
    return state.setting.isLoadingupdateRole;
};

export const validateSettings = settingId => (dispatch, getState) => {
    const { setting } = getState();
    return setting.settingData[settingId];
};

export const fetchCertificateRequestsAPI = () => async (dispatch, getState) => {
    const { setting } = getState();
    if (setting.isLoadingCertificateRequest) {
        return;
    }
    dispatch(setIsLoadingCertificateRequest(true));
    const response = await axios.get(buildApiV3Url(`certificateRequestSettings`), {
        withCredentials: true,
    });
    if (response && response.data) {
        dispatch(setCertificateRequest(response.data));
    } else {
        dispatch(setCertificateRequest([]));
    }
    dispatch(setIsLoadingCertificateRequest(false));
};

export const clearCollectionData = () => async dispatch => {
    dispatch(setCollectionRegions({}));
};

export const fetchCertificateRequestFormsAPI =
    (outgoing = false, forceAll = false, pageSize, pageNumber = 0, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { setting } = getState();
        const { rowsPerPage } = getState().session;
        pageSize = !pageSize ? rowsPerPage : pageSize;
        if (setting.isLoadingCertificateRequestForm) {
            return;
        }
        const reasonName = outgoing
            ? setting.sendingCertificateReasons.reasonName
            : setting.requestCertificateReasons.reasonName;
        const regionName = outgoing
            ? setting.sendingCertificateRegions.regionName
            : setting.requestCertificateRegions.regionName;
        const isAvalaraDefault = outgoing
            ? setting.isSendingAvalaraDefault
            : setting.isRequestingAvalaraDefault;
        const reasonId = outgoing
            ? setting.sendingCertificateReasons.reasonId
            : setting.requestCertificateReasons.reasonId;
        let filterstring = `region.documentType.name eq 'Sales and Use Tax' and region.documentType.outgoing eq ${outgoing}`;
        if (reasonId) filterstring += ` and exemptReason.Id eq "${reasonId}"`;
        else if (reasonName) filterstring += ` and exemptReason.Name eq "${reasonName}"`;
        if (regionName) filterstring += ` and region.Name eq "${regionName}"`;
        if (isAvalaraDefault && !forceAll) {
            filterstring += ` and setToDefault eq "${!isAvalaraDefault}"`;
        }
        const sort = `${setting.sortColumn[0]} ${setting.sortColumn[1] ? "ASC" : "DESC"}`;

        dispatch(setIsLoadingCertificateRequestForm(true));
        const apiURI = buildApiV3UrlWithQuery(
            `exemption-matrix/default-matrix`,
            filterstring,
            "",
            pageSize,
            pageNumber,
            sort
        );

        const apiResponse = await axios.get(`${apiURI}`, {
            withCredentials: true,
        });

        if (errorResponse(apiResponse)?.code) {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull(pageSize));
        } else if (apiResponse?.data) {
            dispatch(setNoDataType(""));
            const response = transformExemptionData(apiResponse, false, false, [
                "exemptReasonName",
            ]);

            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response?.data?.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 100,
                      rowsPerPage: 100,
                      totalRecords: response?.data?.count,
                  };
            dispatch(
                setPage({
                    data: response?.data?.value,
                    paginate: paginateData,
                })
            );
        } else {
            dispatch(setPageToNull([]));
        }
        dispatch(setIsLoadingCertificateRequestForm(false));
    };

export const fetchOutgoingMatrixSet = () => async (dispatch, getState) => {
    const { setting } = getState();
    if (setting.setMatrixCheckLoading) {
        return;
    }
    const pageSize = 1;
    const pageNumber = 0;
    const filterstring = `region.documentType.name eq 'Sales and Use Tax' and region.documentType.outgoing eq true`;

    dispatch(setMatrixCheckLoading(true));
    const collectionURI = buildApiV3UrlWithQuery(
        `exemption-matrix/default-matrix`,
        filterstring,
        "",
        pageSize,
        pageNumber,
        ""
    );

    const collectionResponse = await axios.get(`${collectionURI}`, {
        withCredentials: true,
    });

    if (
        collectionResponse &&
        collectionResponse?.data &&
        !errorResponse(collectionResponse)?.code
    ) {
        dispatch(setIsOutgoingMatrixSet(true));
    } else {
        dispatch(setIsOutgoingMatrixSet(false));
    }
    dispatch(setMatrixCheckLoading(false));
};

export const fetchCollectionRegionsAPI =
    (outgoing = false, taxCode) =>
    async (dispatch, getState) => {
        const { setting } = getState();
        if (setting.isLoadingCollectionRegions) {
            return;
        }
        dispatch(clearCollectionData());

        const pageSize = 1000;
        const pageNumber = 0;
        const filterstring = `region.documentType.name eq 'Sales and Use Tax' and region.documentType.outgoing eq ${outgoing} and exemptReason.Id eq '${taxCode}'`;

        dispatch(setIsLoadingCollectionRegion(true));
        const collectionURI = buildApiV3UrlWithQuery(
            `exemption-matrix/default-matrix`,
            filterstring,
            "",
            pageSize,
            pageNumber,
            ""
        );

        const collectionResponse = await axios.get(`${collectionURI}`, {
            withCredentials: true,
        });

        if (collectionResponse && collectionResponse?.data) {
            const regions = collectionResponse.data.value;
            const optionsList = [];
            const infoList = [];
            forIn(regions, (value, key) => {
                infoList.push({
                    collectionId: regions[key].id,
                    regionName: regions[key].regionName,
                    reasonName: regions[key].exemptReasonName,
                });
                optionsList.push(regions[key].regionName);
            });
            dispatch(setCollectionRegions(optionsList));
            dispatch(setCollectionInfo(infoList));
        } else {
            dispatch(setCollectionRegions({}));
        }
        dispatch(setIsLoadingCollectionRegion(false));
    };

export const fetchSettingsAPI = forceApi => async (dispatch, getState) => {
    const { setting } = getState();
    if (setting.isSavingSettings) {
        return false;
    }

    if (!forceApi && localStorage.getItem("generalSettings")) {
        return false;
    }

    dispatch(setIsSavingSettings(true));
    const response = await axios.get(buildApiV3Url(`generalSettings`), {
        withCredentials: true,
    });
    if (response && response.data) {
        if (!Number.isNaN(response.data.autoSendExpiring))
            response.data.autoSendExpiring = response.data.autoSendExpiring.toString();
        if (!Number.isNaN(response.data.autoRequestInterval))
            response.data.autoRequestInterval = response.data.autoRequestInterval.toString();
        if (!Number.isNaN(response.data.autoRequestMax))
            response.data.autoRequestMax = response.data.autoRequestMax.toString();
        dispatch(setSetting(response.data));
        localStorage.setItem("generalSettings", JSON.stringify(response.data));
    }
    dispatch(setIsSavingSettings(false));
    return true;
};

const [showToast, hideToast] = toast();
export const putGeneralSettings = putData => async (dispatch, getState) => {
    const { setting } = getState();
    dispatch(setIsSavingSettings(true));

    await axios
        .put(
            buildApiV3Url(`generalSettings`),
            { ...setting.settingData, ...putData },
            { withCredentials: true }
        )
        .then(async () => {
            dispatch(setIsSavingSettings(false));
            showToast("success", `Settings saved successfully`);
            await dispatch(fetchSettingsAPI(true));
        });
    dispatch(setIsSavingSettings(false));
};

export const putCertificateRequestSettings = data => async dispatch => {
    const putData = {
        gencertRegistration: data.gencertRegistration,
        webPortalOptions: data.webPortalOptions,
    };
    dispatch(setLoading(true));
    await axios
        .put(buildApiV3Url(`certificateRequestSettings`), putData, {
            withCredentials: true,
        })
        .then(() => {
            showToast("success", `Certificate request settings saved successfully`);
            dispatch(fetchCertificateRequestsAPI());
        });
    dispatch(setLoading(false));
};

export const fetchCompanyLogo = () => async (dispatch, getState) => {
    const { setting } = getState();
    if (setting.isFetchingLogo) {
        return;
    }
    dispatch(setIsFetchingLogo(true));
    const response = await axios.get(buildApiV3Url(`logo`), {
        responseType: "arraybuffer",
        withCredentials: true,
    });
    if (response.status === 204) {
        dispatch(setIsCompanyLogoPresent(false));
    }
    if (response && response.data && response.status === 200) {
        dispatch(setIsCompanyLogoPresent(true));
        const buffer = Buffer.from(response.data, "binary").toString("base64");
        dispatch(
            setCompanyLogo({
                fileType: `${response.headers["content-type"]}`,
                data: buffer,
                fileName: "",
            })
        );
    }
    dispatch(setIsFetchingLogo(false));
};

export const saveCompanyLogo = fileData => async (dispatch, getState) => {
    const { setting } = getState();
    if (setting.isSavingLogo) {
        return;
    }
    dispatch(setIsSavingLogo(true));
    await axios.post(buildApiV3Url(`logo`), fileData, { withCredentials: true }).then(() => {
        showToast("success", `Company logo saved successfully`);
        dispatch(fetchCompanyLogo());
    });
    dispatch(setIsSavingLogo(false));
};

export const deleteCompanyLogo = () => async dispatch => {
    dispatch(setIsDeletingLogo(true));
    await axios
        .delete(buildApiV3Url(`logo`), {
            withCredentials: true,
        })
        .then(() => {
            showToast("success", `Company Logo deleted successfully`);
            dispatch(setIsCompanyLogoPresent(false));
        });
    dispatch(setIsDeletingLogo(false));
};

export const putDefaultMatrixSetting = () => async (dispatch, getState) => {
    const { setting, isLoadingSaveMatrixSetting } = getState();
    const matrixData = setting?.matrixSetting;
    if (isLoadingSaveMatrixSetting) return;
    dispatch(setIsLoadingSaveMatrixSetting(true));
    await axios
        .put(buildApiV3Url(`exemption-matrix/default-matrix/${matrixData.id}`), matrixData, {
            withCredentials: true,
        })
        .then(() => {
            showToast(
                "success",
                `Default matrix settings updated successfully`,
                "save-form-success-toast"
            );
            dispatch(fetchCertificateRequestFormsAPI(matrixData.outgoing));
        });
    dispatch(setIsLoadingSaveMatrixSetting(false));
};

export const putResetDefaultMatrixSetting = () => async (dispatch, getState) => {
    const { setting, isLoadingSaveMatrixSetting } = getState();
    const resetMatrixData = setting?.resetMatrixSetting;
    if (isLoadingSaveMatrixSetting) return;
    dispatch(setIsLoadingSaveMatrixSetting(true));
    await axios
        .put(
            buildApiV3Url(`exemption-matrix/reset-default-matrix/${resetMatrixData.id}`),
            resetMatrixData,
            { withCredentials: true }
        )
        .then(() => {
            showToast("success", `Default matrix settings reset successfully`);
            dispatch(fetchCertificateRequestFormsAPI());
        });
    dispatch(setIsLoadingSaveMatrixSetting(false));
};

export const fetchSignatureData = companyEntityId => async (dispatch, getState) => {
    const { isSignatureLoading } = getState();
    if (isSignatureLoading) {
        return;
    }

    const response = await axios.get(buildApiV3Url(`account-signatures/${companyEntityId}`), {
        withCredentials: true,
    });
    if (response && response.data && response.status === 200) {
        if (errorResponse(response)?.code.toLowerCase() === "objectnotfound") {
            dispatch(setSignatureData(null));
        } else {
            dispatch(setSignatureData(response.data));
        }
    }
    dispatch(setSignatureLoading(false));
};

export const saveSignatureData = sigPostData => async dispatch => {
    dispatch(setSignatureLoading(true));

    await axios.post(buildApiV3Url(`account-signatures`), sigPostData, {
        withCredentials: true,
    });

    dispatch(setSignatureLoading(false));
};

export const updateSignatureData = sigPutData => async dispatch => {
    dispatch(setSignatureLoading(true));
    await axios.put(buildApiV3Url(`account-signatures/${sigPutData.Id}`), sigPutData, {
        withCredentials: true,
    });

    dispatch(setSignatureLoading(false));
};

export const deleteSignatureDataById = signatureId => async dispatch => {
    dispatch(setSignatureLoading(true));
    await axios.delete(buildApiV3Url(`account-signatures/${signatureId}`), {
        withCredentials: true,
    });

    dispatch(setSignatureLoading(false));
};

export const getMatrixFile = matrixId => async (dispatch, getState) => {
    const { isLoadingMatrixFile } = getState();
    if (isLoadingMatrixFile) {
        return;
    }
    dispatch(setLoadingMatrixFile(true));
    const response = await axios.get(buildApiV3Url(`exemption-matrix/matrix-file/${matrixId}`), {
        withCredentials: true,
        responseType: "arraybuffer",
    });
    if (response.status === 204) {
        dispatch(setLoadingMatrixFile(false));
        dispatch(setMatrixFile(""));
    } else if (response && response.data) {
        const buffer = Buffer.from(response.data, "binary").toString("base64");
        dispatch(setMatrixFile(`data:${response.headers["content-type"]};base64,${buffer}`));
        dispatch(setLoadingMatrixFile(false));
    }
};

export const postMatrixFileAsync = (matrixId, fileData) => async dispatch => {
    await axios
        .post(buildApiV3Url(`exemption-matrix/${matrixId}/matrix-file`), fileData, {
            withCredentials: true,
        })
        .then(() => {
            dispatch(getMatrixFile(matrixId));
            dispatch(setSavingMatrixFile(false));
        });
};

export const sendOutgoingStaticDocument = requestData => async (dispatch, getState) => {
    const { setting } = getState();
    if (setting.isSendingStaticDocument) {
        return;
    }
    dispatch(setIsSendingStaticDocument(true));
    await axios
        .post(buildApiV3Url(`outgoing-requests`), requestData, {
            withCredentials: true,
        })
        .then(() => {
            if (requestData.DeliveryMethod === "E") {
                showToast(
                    "success",
                    `Document request sent. We emailed ${requestData.EmailTo}`,
                    "outgoing-request-success",
                    true,
                    "Go back to my vendors",
                    () => {
                        hideToast("outgoing-request-success");
                        window.location = `/search/vendors`;
                    }
                );
            } else {
                showToast(
                    "success",
                    "We're creating your request. It may take a few minutes.",
                    "download-success",
                    true,
                    "View the request file in My Downloads",
                    () => {
                        hideToast("download-success");
                        window.open(
                            `https://${docLockerClient}/mylockers/downloads`,
                            "_blank",
                            "noopener,noreferrer"
                        );
                    }
                );
            }

            dispatch(setIsSendingStaticDocument(false));
        });
};

export const checkIfSystemCollection = (reasonTag, regionTag) => async dispatch => {
    dispatch(setDefaultExist(false));
    const pageSize = 10;
    const pageNumber = 0;
    const filterString = `exposureZoneTag eq '${regionTag}' AND exemptReasonTag eq '${reasonTag}'`;
    const systemMatrixURI = buildApiV3UrlWithQuery(
        `exemption-matrix/system-default-matrix-expire`,
        filterString,
        "",
        pageSize,
        pageNumber,
        ""
    );

    const matrixResponse = await axios.get(`${systemMatrixURI}`, {
        withCredentials: true,
    });

    if (errorResponse(matrixResponse)?.code) {
        dispatch(setDefaultExist(false));
    } else {
        dispatch(setDefaultExist(true));
    }
};

export const fetchPublicCertExpressToken = clientId => async dispatch => {
    const tokenURI = buildApiV2Point5UrlWithQuery(
        `get-public-certexpress-token`,
        `ClientID eq ${clientId}`,
        null,
        null,
        null,
        null
    );

    const tokenResponse = await axios.get(tokenURI, {
        withCredentials: true,
    });

    dispatch(
        setPublicCertExpressToken(
            `${certExpress}/?c=${tokenResponse.data.token}:${tokenResponse.data.iv}`
        )
    );
};

export const fetchDocumentTypeInfoByName = (docName, outgoing) => async (dispatch, getState) => {
    const { setting } = getState();
    if (setting.isLoadingDocumentType) {
        return false;
    }
    dispatch(setLoadingDocumentType(true));
    const docURI = buildApiV3Url(
        `document-types?$filter=name eq '${docName}' and outgoing eq '${outgoing}'`
    );
    const response = await axios.get(docURI, {
        withCredentials: true,
    });
    dispatch(setLoadingDocumentType(false));
    dispatch(setDocumentTypeInfo(response.data.value));
    return true;
};

export const removeRegionAndReason = () => async (dispatch, getState) => {
    const { setting, isRemoveRegionReasonLoading } = getState();
    const matrixData = setting?.matrixSetting;
    const regionName = matrixData?.regionName;
    const reasonName = matrixData?.reasonName;
    const body = { regionName, reasonName };

    if (isRemoveRegionReasonLoading) return;
    dispatch(setIsRemoveRegionReasonLoading(true));
    await axios
        .delete(buildApiV3Url(`exemption-matrix/${matrixData?.id}`), {
            data: body,
            withCredentials: true,
        })
        .then(() => {
            showToast("success", `Region and reason deleted successfully`);
        });
    dispatch(setIsRemoveRegionReasonLoading(false));
    dispatch(fetchCertificateRequestFormsAPI(false));
};

export const fetchDocumentTypeInfoType = isOutgoing => async (dispatch, getState) => {
    const { setting } = getState();
    if (setting.isLoadingDocumentType) {
        return false;
    }

    dispatch(setLoadingDocumentType(true));
    const docURI = buildApiV3Url(
        `document-types?$filter=name eq  'Sales and Use Tax' and outgoing eq ${isOutgoing}`
    );
    const response = await axios.get(docURI, {
        withCredentials: true,
    });

    dispatch(setDocumentTypeInfo(response.data.value));
    dispatch(setLoadingDocumentType(false));
    return true;
};

export const postBulkPdfRebuild = () => async () => {
    const response = await axios.post(
        buildApiV3Url(`certificates/bulk-pdf-rebuild`),
        {},
        { withCredentials: true }
    );

    if (response && response.data) {
        if (response.data.success) {
            showToast("success", `Rebuild request submitted.`);
        } else {
            showToast("error", `The rebuild service is unavailable. Try again later.`);
        }
    }
};

export const getUserInfo = userId => async (dispatch, getState) => {
    const { isLoadingUserInfo } = getState().setting;
    if (isLoadingUserInfo) {
        return false;
    }
    dispatch(setIsLoadingUserInfo(true));
    try {
        const response = await axiosInstanceWithoutInterceptor.get(
            buildApiV3Url(`user-role/${userId}`),
            { withCredentials: true }
        );

        if (response?.status === 200 && response?.data) {
            dispatch(setUserInfo(response?.data));
        }
    } catch (error) {
        dispatch(setIsLoadingUserInfo(false));
        return false;
    }
    dispatch(setIsLoadingUserInfo(false));
    return true;
};

export const updateMSUserRole = userId => async (dispatch, getState) => {
    const localState = getState();
    const { activeCompany } = localState.session;
    const { userInfo, isLoadingupdateRole } = localState.setting;
    if (isLoadingupdateRole) return;
    dispatch(setIsLoadingupdateRole(true));

    if (userInfo?.subjectId !== null) {
        if (userInfo.userRoles !== null && Array.isArray(userInfo.userRoles)) {
            axiosInstanceWithoutInterceptor
                .put(
                    buildApiV3Url(`user-role?subjectId=${userInfo?.subjectId}&userId=${userId}`),
                    {
                        userRoles: [
                            {
                                role: ecmRole.MSUser,
                                companyIds: [activeCompany.id],
                                isAllCompany: true,
                            },
                        ],
                    },
                    { withCredentials: true }
                )
                .then(res => {
                    if (res.status === 200) {
                        showToast(
                            "success",
                            `Managed services user role has been assigned successfully`
                        );
                    } else {
                        showToast("error", `Unable to update managed services user role`);
                    }
                });
        } else {
            axiosInstanceWithoutInterceptor
                .post(
                    buildApiV3Url(`user-role?subjectId=${userInfo?.subjectId}&userId=${userId}`),
                    {
                        userRoles: [
                            {
                                role: ecmRole.MSUser,
                                companyIds: [activeCompany.id],
                                isAllCompany: true,
                            },
                        ],
                    },
                    { withCredentials: true }
                )
                .then(() => {
                    dispatch(getUserInfo(userId));
                    showToast(
                        "success",
                        `Managed services user role has been assigned successfully`
                    );
                });
        }
    } else {
        showToast("error", `Unable to update managed services user role`);
    }
    dispatch(setIsLoadingupdateRole(false));
};

export const deleteMSUserRole = userId => async (dispatch, getState) => {
    const localState = getState();
    const { userInfo, isLoadingupdateRole } = localState.setting;
    if (isLoadingupdateRole) return;
    dispatch(setIsLoadingupdateRole(true));

    axiosInstanceWithoutInterceptor
        .delete(buildApiV3Url(`user-role?subjectId=${userInfo?.subjectId}`), {
            withCredentials: true,
        })
        .then(() => {
            dispatch(getUserInfo(userId));
            showToast("success", `Managed services user role has been unassigned successfully`);
        });
    dispatch(setIsLoadingupdateRole(false));
};

export default settingsSlice.reducer;
