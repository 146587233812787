import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SDialog, SAlert, SIcon, SRow, SCol } from "@avalara/skylab-react";
import classnames from "classnames";
import {
    putBulkUpdateCertificatesExpirationDate,
    putBulkUpdateCertificatesLabels,
    putBulkUpdateCertificatesInvalidReasons,
    selectIsLoading,
    selectExpirationDate,
    selectAddLabelsList,
    selectRemoveLabelsList,
    selectAddInvalidReasonsList,
    selectRemoveInvalidReasonsList,
    setExpirationDate,
    setAddLabelsList,
    setRemoveLabelsList,
    setAddInvalidReasonsList,
    setRemoveInvalidReasonsList,
    setSelectedField,
    selectCertificateIds,
    setCertificateIds,
} from "../../app/bulkUpdateSlice";
import { fetchCertificatesSearchAPI } from "../../app/certificateSlice";

function BulkUpdateConfirmDialog(props) {
    const dispatch = useDispatch();
    const expirationDate = useSelector(selectExpirationDate);
    const addLabelsList = useSelector(selectAddLabelsList, shallowEqual);
    const removeLabelsList = useSelector(selectRemoveLabelsList, shallowEqual);
    const addInvalidReasonsList = useSelector(selectAddInvalidReasonsList, shallowEqual);
    const removeInvalidReasonsList = useSelector(selectRemoveInvalidReasonsList, shallowEqual);
    const isLoading = useSelector(selectIsLoading);
    const updateButton = classnames({ primary: true, small: true, loading: isLoading });
    const certificateIds = useSelector(selectCertificateIds, shallowEqual);
    const [confirmText, setConfirmText] = useState("");
    const [confirmTextError, setConfirmTextError] = useState("");
    const handleSubmit = async () => {
        if (confirmText !== "UPDATE" || confirmText.length === 0) {
            setConfirmTextError("error");
        } else {
            setConfirmTextError("");
            if (addLabelsList.length || removeLabelsList.length) {
                await dispatch(
                    putBulkUpdateCertificatesLabels(certificateIds, addLabelsList, removeLabelsList)
                );
            } else if (expirationDate) {
                await dispatch(
                    putBulkUpdateCertificatesExpirationDate(certificateIds, expirationDate)
                );
            } else if (addInvalidReasonsList.length || removeInvalidReasonsList.length) {
                await dispatch(
                    putBulkUpdateCertificatesInvalidReasons(
                        certificateIds,
                        addInvalidReasonsList,
                        removeInvalidReasonsList
                    )
                );
            }
            setConfirmText("");
            props.setIsBulkUpdateConfirmDialogOpen(false);
            dispatch(setExpirationDate(""));
            dispatch(setSelectedField(""));
            dispatch(setAddLabelsList([]));
            dispatch(setRemoveLabelsList([]));
            dispatch(setAddInvalidReasonsList([]));
            dispatch(setRemoveInvalidReasonsList([]));
            dispatch(fetchCertificatesSearchAPI());
            dispatch(setCertificateIds([]));
        }
    };
    const handleChangeInput = e => {
        setConfirmText(e.target.value.toUpperCase());
        if (e.target.value === "UPDATE") setConfirmTextError("");
    };

    const onCancel = () => {
        props.setIsBulkUpdateConfirmDialogOpen(false);
        setConfirmTextError("");
        dispatch(setSelectedField(""));
        dispatch(setAddInvalidReasonsList([]));
        dispatch(setRemoveInvalidReasonsList([]));
        dispatch(setAddLabelsList([]));
        dispatch(setRemoveLabelsList([]));
        dispatch(setExpirationDate(""));
    };

    return (
        <SDialog
            id="bulkUpdateConfirmDialog"
            open={props.isBulkUpdateConfirmDialogOpen}
            onS-dismiss={() => {
                onCancel();
            }}
            aria-modal="true">
            <div slot="header" id="bulkUpdateConfirm">
                Update {certificateIds.length}{" "}
                {certificateIds.length > 1 ? "certificates" : "certificate"}
            </div>
            <div slot="body">
                <SAlert status="warning" role="alert" nodismiss>
                    <SRow>
                        <SCol span={6}>
                            <strong>This action can&apos;t be undone.</strong>Type{" "}
                            <strong>UPDATE</strong> to confirm you want to do this.
                        </SCol>
                        <SCol span={6}>
                            <div>
                                <input
                                    type="text"
                                    value={confirmText}
                                    className={
                                        confirmTextError.length === 0
                                            ? "margin-top-md margin-bottom-md"
                                            : "error"
                                    }
                                    onChange={handleChangeInput}
                                />
                                <div className="input-msg" hidden={confirmTextError !== "error"}>
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                    <span className="top-xs">
                                        Type UPDATE to update the certificates.
                                    </span>
                                </div>
                            </div>
                        </SCol>
                    </SRow>
                </SAlert>
                <br />
                <span>Updating multiple certificates can take a while.</span>
            </div>
            <div slot="footer">
                <button className="secondary small" onClick={onCancel}>
                    Cancel
                </button>
                <button className={updateButton} onClick={handleSubmit}>
                    Yes, update
                </button>
            </div>
        </SDialog>
    );
}
export default BulkUpdateConfirmDialog;
