import React, { useEffect } from "react";
import { SLoader } from "@avalara/skylab-react";
import { useDispatch, useSelector } from "react-redux";
import { getHealthStatus, selectData, selectIsLoading } from "../../app/healthCheckSlice";

function HealthCheck() {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoading);
    const data = useSelector(selectData);

    useEffect(() => {
        dispatch(getHealthStatus());
    }, [dispatch]);

    let appDOM = null;
    if (isLoading) {
        appDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader id="page-loader" className="large" aria-live="polite" loading />
            </div>
        );
    } else {
        appDOM = <h3>Health status: {data}</h3>;
    }
    return appDOM;
}

export default HealthCheck;
