import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SRow, SCol, SDialog, SIcon } from "@avalara/skylab-react";
import {
    addOrUpdateCustomerLabels,
    selectIsAddOrEditLoading,
} from "../../../../app/customerLabelSlice";
import { errorTag, noErrorTag } from "../../../../shared/Utils";

function AddEditCustomerLabelsDialog(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsAddOrEditLoading);
    const [enableSave, setEnableSave] = useState(false);
    const [nameError, setNameError] = useState("");
    const [isExcludedFromRequest, setIsExcludedFromRequest] = useState(
        props.customerLabel.nonDeliver
    );
    const [descriptionError, setDescriptionError] = useState("");
    const [labelName, setLabelName] = useState(
        props.customerLabel.name ? props.customerLabel.name : ""
    );
    const [labelDescription, setLabelDescription] = useState(
        props.customerLabel.description ? props.customerLabel.description : ""
    );
    const handleNameChange = value => {
        setLabelName(value);
        if (value === "") setNameError("error");
        else setNameError("");
    };

    const handleDescriptionChange = value => {
        setLabelDescription(value);
        if (value === "") setDescriptionError("error");
        else setDescriptionError("");
    };

    const clearFields = () => {
        setLabelName("");
        setNameError("");
        setLabelDescription("");
        setDescriptionError("");
        setIsExcludedFromRequest(false);
    };

    useEffect(() => {
        if (props.addOrEdit === "Edit") {
            setEnableSave(true);
        } else if (props.addOrEdit === "Add") {
            clearFields();
        }
    }, [props]);

    useEffect(() => {
        const isInputNotEmpty = labelName?.trim() !== "" && labelDescription?.trim() !== "";
        setEnableSave(isInputNotEmpty);
    }, [labelName, labelDescription]);

    const onSave = async () => {
        const payload = {
            name: labelName,
            description: labelDescription,
            systemCode: false,
            nonDeliver: isExcludedFromRequest,
        };
        let result;
        if (props.addOrEdit === "Add") {
            result = await dispatch(addOrUpdateCustomerLabels("Add", payload));
        } else {
            result = await dispatch(
                addOrUpdateCustomerLabels("Edit", payload, props.customerLabel.id)
            );
        }
        if (result) props.setOpenAddUpdateDialog(false);
    };

    const onCancel = () => {
        clearFields();
        props.setOpenAddUpdateDialog(false);
    };
    const handleExcludeFromRequestCheckboxChange = () => {
        setIsExcludedFromRequest(!isExcludedFromRequest);
    };
    return (
        <SDialog
            id="add-edit-labels-dialog"
            open={props.openAddUpdateDialog}
            onS-dismiss={onCancel}
            className="overflow-visible"
            aria-modal="true">
            <div slot="header" id="addEditLabels">
                {props.addOrEdit === "Add" ? "Add customer label" : "Edit customer label"}
            </div>
            <div slot="body">
                <SRow>
                    <SCol>
                        <label htmlFor="label-name" className="required">
                            Name
                        </label>
                        <input
                            id="label-name"
                            name="Name"
                            type="text"
                            value={labelName}
                            onChange={e => {
                                handleNameChange(e.target.value);
                            }}
                            className={nameError ? errorTag : noErrorTag}
                        />
                        <div className="input-msg">
                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                            <span className="top-xs">Enter name</span>
                        </div>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        <label htmlFor="label-description" id="lbl-description">
                            Description
                        </label>
                        <input
                            id="label-description"
                            name="Description"
                            type="text"
                            value={labelDescription}
                            onChange={e => {
                                handleDescriptionChange(e.target.value);
                            }}
                            className={descriptionError ? errorTag : noErrorTag}
                        />
                        <div className="input-msg">
                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                            <span className="top-xs">Enter description</span>
                        </div>
                        <input
                            id="checkExcludedFromRequest"
                            name="checkExcludedFromRequest"
                            type="checkbox"
                            aria-label="Exclude from certificate requests"
                            checked={isExcludedFromRequest}
                            onChange={() => handleExcludeFromRequestCheckboxChange()}
                        />
                        <label
                            htmlFor="checkExcludedFromRequest"
                            className="margin-top-xs margin-bottom-md">
                            Exclude from certificate requests
                        </label>
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button className="secondary" onClick={onCancel}>
                    Cancel
                </button>
                <button
                    disabled={!enableSave}
                    className={isLoading ? "primary right loading" : "primary right"}
                    id="save-label"
                    type="button"
                    onClick={onSave}>
                    Save
                </button>
            </div>
        </SDialog>
    );
}

export default AddEditCustomerLabelsDialog;
