import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { STag, SPagination, SLoader, SRowActions } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import RequestDialog from "../../sharedDialogs/RequestDialog";
import SaveSearchDialog from "../../sharedDialogs/SaveSearchDialog";
import {
    selectPage,
    fetchCustomersAPI,
    selectLoading,
    selectSortColumn,
    setSortColumn,
    selectCustomerColumns,
    selectCustomerFiltersState,
    selectIsLoadingCustomerColumns,
    getCustomerColumnList,
    selectCustomFields,
    selectFormState,
} from "../../../app/customerSlice";
import { setRedirectURL } from "../../../app/certificateSlice";
import { selectSession, updateRowCountPreference } from "../../../app/sessionSlice";
import Upgrade from "../../upgradeTier/OnlineBuyDialog";
import CustomerFilter from "./CustomerFilter";
import "./customer.css";
import toast from "../../../hooks/toast";
import { selectResponseType, setResponseType } from "../../../app/savedSearchesSlice";
import { Messages } from "../../../shared/constants";
import ExportSearchDialog from "../../sharedDialogs/ExportSearchDialog";
import { urlToPageMap, getRowsPerPage } from "../../../shared/Utils";
import { isEligibleUser } from "../../../shared/sessionUtility";
import BulkDeleteCustomerDialog from "./BulkDeleteCustomerDialog";
import featureFlag from "../../../featureToggler/featureFlag";
import LabelElement from "../../sharedComponents/LabelElementInGrid";
import RecordCount from "../RecordCount";

function CustomerGrid() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showToast] = toast();
    const [submitting, setSubmitting] = useState(false);
    const loading = useSelector(selectLoading);
    const loadingCustomerColumns = useSelector(selectIsLoadingCustomerColumns);
    const [currentRow, setCurrentRow] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectSortColumn);
    const session = useSelector(selectSession, shallowEqual);
    const selectedColumns = useSelector(selectCustomerColumns, shallowEqual);
    const isEligibleECMUserRequestsFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.singleRequest)
    );
    let valueOfCustomerRelationship = null;
    const customerFiltersState = useSelector(selectCustomerFiltersState, shallowEqual);
    const formState = useSelector(selectFormState, shallowEqual);
    const isEligibleUserManageCustomersColumn = dispatch(
        isEligibleUser(featureFlag.customers.manageCustomersColumns, true)
    );
    const isEligibleUserCustomFields = dispatch(
        isEligibleUser(featureFlag.customers.customFields, true)
    );
    const isUserEligibleCustomerLabels = dispatch(
        isEligibleUser(featureFlag.customers.customerLabels)
    );
    const customFields = useSelector(selectCustomFields);
    const saveSearchStatus = useSelector(selectResponseType);
    const savedSearchToast = useCallback(() => {
        switch (saveSearchStatus) {
            case "success": {
                dispatch(setResponseType(""));
                return showToast("success", Messages.saved_Success);
            }

            case "failure": {
                dispatch(setResponseType(""));
                return showToast("error", Messages.saved_failure);
            }

            default:
                return null;
        }
    }, [dispatch, saveSearchStatus, showToast]);

    useEffect(() => {
        if (saveSearchStatus) savedSearchToast();
    }, [saveSearchStatus, savedSearchToast]);

    useEffect(() => {
        dispatch(getCustomerColumnList(isEligibleUserCustomFields, isUserEligibleCustomerLabels));
    }, [dispatch, isEligibleUserCustomFields, isUserEligibleCustomerLabels]);

    async function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setSortColumn([column, order]));
        await dispatch(fetchCustomersAPI(paginateData.rowsPerPage, 1, false));
        setSubmitting(false);
    }

    const customerRelationshipValueFunction = relationshipValue => {
        if (relationshipValue === "Bill-to customer") {
            valueOfCustomerRelationship = "Bill-to";
        } else if (relationshipValue === "Ship-to customer") {
            valueOfCustomerRelationship = "Ship-to";
        } else if (relationshipValue === "Bill-to and ship-to customer") {
            valueOfCustomerRelationship = "Bill-to and ship-to customer";
        } else if (relationshipValue === "None") {
            valueOfCustomerRelationship = "None";
        } else {
            valueOfCustomerRelationship = null;
        }
    };

    const getCustomValue = (customField, fieldName) => {
        const noDupes = [...new Set(customField)];
        if (noDupes[0] !== null && noDupes[0] !== "null") {
            const match = noDupes?.filter(x => x?.includes(fieldName));
            if (match.length !== 0) {
                const customValue = match[0].split(":");
                return customValue[1].trim();
            }
            return match;
        }
        return noDupes;
    };

    const getCustomerLabels = customerLabels => {
        if (customerLabels) {
            return [...customerLabels.filter(label => label)];
        }
        return [];
    };

    const headerColumns = [
        gridHeader("Customer ID", "id", null, null, null, null, null, sortFunction, "id"),
        gridHeader(
            "Customer code",
            "customerNumber",
            (value, row) => (
                <Link className="font-semibold customer-link" to={`/customer/${row.id}`}>
                    {row.customerNumber}
                </Link>
            ),
            null,
            null,
            null,
            null,
            sortFunction,
            "customerNumber"
        ),
        gridHeader("Customer name", "name", null, null, null, null, null, sortFunction, "name"),
        gridHeader(
            "Address",
            ["city", "state"],
            (value, row) => (
                <span>
                    {row.addressLine1} {row.city ? `${row.city}, ` : ""}{" "}
                    {row.state ? row.state.initials : ""} {row.zip}{" "}
                    {row.country ? row.country.initials : ""}
                </span>
            ),
            null,
            null,
            null,
            null,
            null,
            "address"
        ),
        gridHeader(
            "Active certificates",
            "activeCertificateCount",
            (value, row) => (
                <div>
                    <div className="text-left active-count">{row.activeCertificateCount}</div>
                    <STag
                        color="blue-light"
                        className="margin-left-xs tagText"
                        hidden={!row.expiringSoonCertificateCount}>
                        <span slot="content">
                            {`${row.expiringSoonCertificateCount} expiring soon`}
                        </span>
                    </STag>
                </div>
            ),
            null,
            "activeCert-td",
            null,
            null,
            null,
            "activeCertificateCount"
        ),
        gridHeader(
            "Alternate ID",
            "alternateId",
            null,
            null,
            null,
            null,
            null,
            sortFunction,
            "alternateId"
        ),
        gridHeader(
            "Phone",
            "phoneNumber",
            null,
            null,
            "fixed-column",
            null,
            null,
            sortFunction,
            "phoneNumber"
        ),
        gridHeader("Fax", "faxNumber", null, null, null, null, null, sortFunction, "faxNumber"),
        gridHeader(
            "Email",
            "emailAddress",
            null,
            null,
            null,
            null,
            null,
            sortFunction,
            "emailAddress"
        ),
        gridHeader(
            "Contact name",
            "contactName",
            null,
            null,
            null,
            null,
            null,
            sortFunction,
            "contactName"
        ),
        gridHeader(
            "Created",
            "created",
            value => new Date(value).toLocaleDateString(),
            null,
            "",
            null,
            null,
            sortFunction,
            "created"
        ),
        gridHeader(
            "Last modified",
            "modified",
            value => new Date(value).toLocaleDateString(),
            null,
            "",
            null,
            null,
            sortFunction,
            "modified"
        ),
        gridHeader(
            "Bill-to and ship-to relationships",
            "customerRelationship",
            value => (
                <React.Fragment>
                    {customerRelationshipValueFunction(value)}
                    <span>{valueOfCustomerRelationship}</span>
                </React.Fragment>
            ),
            null,
            null,
            null,
            null,
            sortFunction,
            "customerRelationship"
        ),
        gridHeader(
            "Bill-to customer number",
            ["parentCustomer"],
            (value, row) => <span>{row?.parentCustomer?.parentCustomerNumber}</span>,
            null,
            null,
            null,
            null,
            null,
            "parentCustomerNumber"
        ),
        gridHeader(
            "Bill-to customer name",
            ["parentCustomer"],
            (value, row) => <span>{row?.parentCustomer?.parentCustomerName}</span>,
            null,
            null,
            null,
            null,
            null,
            "parentCustomerName"
        ),
        gridHeader(
            "Bill-to customer address ",
            ["parentCustomer", "parentCustomerAddressLine1"],
            (value, row) => (
                <span>
                    {row?.parentCustomer?.parentCustomerAddressLine1}{" "}
                    {row?.parentCustomer?.parentCustomerCity
                        ? `${row.parentCustomer.parentCustomerCity}, `
                        : ""}{" "}
                    {row?.parentCustomer?.parentCustomerState
                        ? row?.parentCustomer?.parentCustomerState?.initials
                        : ""}{" "}
                    {row?.parentCustomer?.parentCustomerZip}{" "}
                    {row?.parentCustomer?.parentCustomerCountry
                        ? row?.parentCustomer?.parentCustomerCountry?.initials
                        : ""}
                </span>
            ),
            null,
            null,
            null,
            null,
            null,
            "parentCustomerAddress"
        ),
        gridHeader(
            "Labels",
            "[customersLabels]",
            (value, row) => <LabelElement labels={[...getCustomerLabels(row.customersLabels)]} />,
            null,
            null,
            null,
            null,
            null,
            "customersLabels"
        ),
        gridHeader(
            "",
            "",
            (value, row) => (
                <SRowActions
                    key={uuidv4()}
                    menuId={`menu-${uuidv4()}`}
                    className="row-actions"
                    actionItems={[
                        {
                            label: "Request a certificate",
                            iconName: "send",
                            classList: ["primary", "icon-leading"],
                            id: `menu-${uuidv4()}-send`,
                            disabled: !isEligibleECMUserRequestsFlag,
                        },
                        {
                            label: "Create a certificate",
                            iconName: "plus",
                            classList: ["primary", "icon-leading"],
                            id: `menu-${uuidv4()}-create`,
                        },
                    ]}
                    collapsed
                    onS-select={e => {
                        if (e.detail.id.includes("send")) {
                            if (session.baseSubscription) {
                                setIsUpgradeDialogOpen(true);
                            } else {
                                setCurrentRow(row);
                                setIsDialogOpen(true);
                            }
                        } else if (e.detail.id.includes("create")) {
                            navigate(urlToPageMap.review, {
                                state: { customer: row },
                            });
                            dispatch(setRedirectURL(urlToPageMap["search-customer"]));
                        }
                    }}
                />
            ),
            "text-right",
            "text-right"
        ),
    ];

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            setSubmitting(true);
            await dispatch(
                fetchCustomersAPI(e.detail.rowsPerPage, e.detail.currentPage, false, e.detail)
            );
            setSubmitting(false);
        }
    };

    const getRowKey = row => {
        return row.id;
    };
    let modifiedColumns = [];

    const arrangeColumns = columnlist => {
        const visibleColumns = columnlist?.filter(a => a.hidden === false);
        const tempCol = [modifiedColumns[0], modifiedColumns[1], modifiedColumns[2]];
        for (let j = 0; j < visibleColumns.length; ) {
            const item = modifiedColumns.find(obj => {
                return obj.id === visibleColumns[j].id;
            });
            tempCol.push(item);
            j += 1;
        }
        tempCol.push(modifiedColumns[modifiedColumns.length - 1]);
        modifiedColumns = tempCol;
    };

    const manageColumns = () => {
        if (isEligibleUserManageCustomersColumn && customFields.length !== 0) {
            customFields.forEach(field => {
                if (!headerColumns.find(obj => obj.id === field.fieldName)) {
                    headerColumns.splice(
                        -1,
                        0,
                        gridHeader(
                            field.fieldName,
                            field.fieldName,
                            (value, row) => (
                                <span name={field.fieldName}>
                                    {getCustomValue(row.customFields, field.fieldName)}
                                </span>
                            ),
                            null,
                            null,
                            null,
                            null,
                            null,
                            field.fieldName?.replace(/ /g, "")
                        )
                    );
                }
            });
        }
        modifiedColumns = headerColumns;
        selectedColumns?.forEach(col => {
            if (col.hidden) {
                modifiedColumns = modifiedColumns?.filter(obj => {
                    return obj.id !== col.id;
                });
            }
        });
        arrangeColumns(selectedColumns);
        return modifiedColumns;
    };

    let table = null;
    if (submitting || loading || loadingCustomerColumns) {
        table = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    } else if (paginateData?.totalRecords !== 0) {
        table = (
            <s-table-container class="th-with-filter">
                <DataGrid
                    columns={manageColumns(selectedColumns)}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={submitting}
                    inContainer
                    sortColumn={sortColumn}
                />
            </s-table-container>
        );
    }

    let pagination = null;
    if (paginateData.totalRecords > 0 && paginateData.indeterminate) {
        pagination = (
            <SPagination
                className="margin-top-md"
                rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                totalRecords={paginateData.totalRecords}
                indeterminate
                disabled
                startIndex={paginateData.startIndex}
                onS-paginate={e => {
                    handlePaginate(e);
                }}
            />
        );
    } else if (paginateData.totalRecords > 0) {
        pagination = (
            <SPagination
                className="margin-top-md"
                rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                totalRecords={paginateData.totalRecords}
                startIndex={paginateData.startIndex}
                onS-paginate={e => {
                    handlePaginate(e);
                }}
            />
        );
    }
    return (
        <React.Fragment>
            <CustomerFilter />
            {paginateData.totalRecords > 0 ? (
                <div className="margin-top-sm">
                    <RecordCount
                        selectedCount={0}
                        recordCount={new Intl.NumberFormat().format(paginateData.totalRecords)}
                        recordName="customers"
                    />
                </div>
            ) : null}
            {table}
            {pagination}
            {currentRow.id ? (
                <RequestDialog
                    isRequestDialogOpen={isDialogOpen}
                    setIsRequestDialogOpen={setIsDialogOpen}
                    customer={currentRow}
                    setCurrentRow={setCurrentRow}
                    customerCount={1}
                />
            ) : null}
            <Upgrade
                isUpgradeDialogOpen={isUpgradeDialogOpen}
                setIsUpgradeDialogOpen={setIsUpgradeDialogOpen}
                type="request"
            />
            <SaveSearchDialog
                searchType="Customer"
                criteria={{ ...customerFiltersState, ...formState }}
            />
            <ExportSearchDialog exportType="Customer" />
            <BulkDeleteCustomerDialog />
        </React.Fragment>
    );
}
export default CustomerGrid;
