import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SCol, SInputExtended } from "@avalara/skylab-react";
import {
    selectPendingFormState,
    setPendingState,
    fetchPendingCertificatesSearchAPI,
    selectLoading,
} from "../../../app/certificateSlice";
import "./pending.css";
import { typeAheadDelay } from "../../../shared/constants";

const PendingSearchForm = React.memo(props => {
    const dispatch = useDispatch();
    const pendingFormState = useSelector(selectPendingFormState);
    const isLoading = useSelector(selectLoading);
    const { nameOrCodeOrFileOrId } = pendingFormState;
    const { setSelectedRows, setDisableDelete, setRowCount } = props;

    const handleSubmitForm = useCallback(() => {
        dispatch(fetchPendingCertificatesSearchAPI());
        setSelectedRows([]);
        setDisableDelete(true);
        setRowCount(0);
    }, [dispatch, setSelectedRows, setDisableDelete, setRowCount]);

    const clearSearch = async () => {
        await dispatch(
            setPendingState({
                ...pendingFormState,
                nameOrCodeOrFileOrId: "",
            })
        );
        handleSubmitForm();
    };

    const handleInput = el => {
        dispatch(
            setPendingState({
                ...pendingFormState,
                [el.detail.inputId]: el.detail.value.trim(),
            })
        );
    };

    useEffect(() => {
        let timeout = "";
        timeout = setTimeout(() => {
            handleSubmitForm();
        }, typeAheadDelay.PendingCertificateSearchGrid);
        return () => clearTimeout(timeout);
    }, [nameOrCodeOrFileOrId, handleSubmitForm]);

    const handleSearch = async el => {
        await dispatch(
            setPendingState({
                ...pendingFormState,
                [el.detail.inputId]: el.detail.value.trim(),
            })
        );
        handleSubmitForm();
    };

    const certificateDOM = (
        <SCol span="md-6 xl-6 5" className="pad-bottom-none">
            <label htmlFor="nameOrCodeOrFileOrId">
                Search by Certificate ID, customer name, customer code, or file name
            </label>
            <SInputExtended
                inputId="nameOrCodeOrFileOrId"
                aria-label="search"
                placeholder=" "
                type="search"
                onS-input={e => handleInput(e)}
                onS-search={e => handleSearch(e)}
                onS-clear={() => clearSearch()}
                value={nameOrCodeOrFileOrId}
                className={isLoading ? "disable-sinput-extended" : "none"}
            />
        </SCol>
    );

    return certificateDOM;
});
export default PendingSearchForm;
