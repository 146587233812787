import React, { useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { SLoader } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import { selectData, selectIsLoading } from "../../../app/contentSlice";
import RecordCount from "../../search/RecordCount";
import CitationsDialog from "./CitationsDialog";
import "../taxContent.css";

export const countLabel = "Avalara tax answers";
export const loaderText = "Loading ...";
export const noData = "No results found.";
export const citationLink = "citation-link";

function TaxAnswersGrid() {
    const pageData = useSelector(selectData);
    let citationTableMap = [];
    const loading = useSelector(selectIsLoading);

    const [citationText, setcitationText] = useState("");

    const [isCitationDialogOpen, setIsCitationDialogOpen] = useState(false);

    const openCitation = row => {
        setcitationText(row);
        setIsCitationDialogOpen(true);
    };

    const columns = [
        gridHeader(
            "Question",
            "questiontext",
            value => (
                <div style={{ minWidth: "200px" }}>
                    <span className="citation-font">{value}</span>
                </div>
            ),
            null,
            ""
        ),
        gridHeader(
            "Answer",
            "answer",
            value => (
                <div style={{ minWidth: "100px" }}>
                    <span className="citation-font">{value}</span>
                </div>
            ),
            null,
            ""
        ),
        gridHeader(
            "Explanation",
            "explanation",
            value => (
                <div
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{ __html: value }}
                />
            ),
            null,
            ""
        ),
        gridHeader(
            "Citations",
            "[citations]",
            (value, row) => (
                <div>
                    {row?.citations ? (
                        <s-table-container class="no-border" key={uuidv4()}>
                            {row?.citations.length > 0 && (
                                <table className="borderless" role="presentation">
                                    <tbody>
                                        {Object.keys(row?.citations).map((v, idx) => {
                                            citationTableMap.push(
                                                <tr key={row?.citations[idx]}>
                                                    <th
                                                        span="col"
                                                        className="pad-bottom-xs"
                                                        style={{ minWidth: "200px" }}>
                                                        <div
                                                            className={`pad-left-md  ${citationLink}`}
                                                            role="link"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                openCitation(row.citations[idx])
                                                            }>
                                                            {row.citations[idx].name}
                                                        </div>
                                                    </th>
                                                </tr>
                                            );

                                            const returnMap = citationTableMap;
                                            citationTableMap = [];
                                            return returnMap;
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </s-table-container>
                    ) : null}
                </div>
            ),
            null,

            ""
        ),
    ];

    const getRowKey = row => {
        return `${row.id}`;
    };

    let data = null;
    if (loading)
        data = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>{loaderText}</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    else if (pageData.data)
        data = (
            <React.Fragment>
                <RecordCount
                    selectedCount={0}
                    recordCount={pageData?.data?.length}
                    recordName={countLabel}
                />
                <s-table-container class="margin-top-sm">
                    <DataGrid
                        columns={columns}
                        rows={pageData.data}
                        getKey={getRowKey}
                        loading={loading}
                        inContainer
                    />
                </s-table-container>
            </React.Fragment>
        );
    else
        data = (
            <span className="margin-top-lg font-semibold line-height-sm citation-font">
                {noData}
            </span>
        );

    return (
        <React.Fragment>
            {data}
            <CitationsDialog
                isCitationsDialogOpen={isCitationDialogOpen}
                text={citationText}
                setIsCitationsDialogOpen={setIsCitationDialogOpen}
            />
        </React.Fragment>
    );
}
export default TaxAnswersGrid;
