import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import DuplicateCustomerGrid from "./DuplicateCustomerGrid";
import DuplicateCustomersFilter from "./DuplicateCustomersFilter";
import UnlinkDuplicateCustomerDialog from "./UnlinkDuplicateCustomerDialog";
import { fetchCrossCompanySameAsFeature } from "../../../app/customerDuplicateSlice";

const DuplicateCustomers = React.memo(props => {
    const dispatch = useDispatch();
    const [isUnlinkDuplicateCustomerDialogOpen, setIsUnlinkDuplicateCustomerDialogOpen] =
        useState(false);
    const [isDuplicateCustomerDialogOpen, setIsDuplicateCustomerDialogOpen] = useState(false);
    const [sameAsId, setSameAsId] = useState(0);

    useEffect(() => {
        dispatch(fetchCrossCompanySameAsFeature());
    }, [dispatch]);

    return (
        <React.Fragment>
            <DuplicateCustomersFilter
                customerId={props.customerId}
                isDuplicateCustomerDialogOpen={isDuplicateCustomerDialogOpen}
                setIsDuplicateCustomerDialogOpen={setIsDuplicateCustomerDialogOpen}
            />

            <DuplicateCustomerGrid
                customerId={props.customerId}
                setIsDuplicateCustomerDialogOpen={setIsDuplicateCustomerDialogOpen}
                setIsUnlinkDuplicateCustomerDialogOpen={setIsUnlinkDuplicateCustomerDialogOpen}
                setSameAsId={setSameAsId}
            />
            <UnlinkDuplicateCustomerDialog
                isUnlinkDuplicateCustomerDialogOpen={isUnlinkDuplicateCustomerDialogOpen}
                setIsUnlinkDuplicateCustomerDialogOpen={setIsUnlinkDuplicateCustomerDialogOpen}
                customerId={props.customerId}
                customerName={props.customerName}
                sameAsId={sameAsId}
            />
        </React.Fragment>
    );
});

export default DuplicateCustomers;
