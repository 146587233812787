import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildApiV3Url } from "../shared/Utils";

export const overviewSlice = createSlice({
    name: "overview",
    initialState: {
        isloading: false,
        isPendingCertificatesloading: false,
        isMissingCertificatesloading: false,
        overview: [],
        overviewMissingCertificates: [],
        overviewPendingCertificates: [],
    },
    reducers: {
        setOverview: (state, action) => {
            state.overview = action.payload;
        },
        setOverviewMissingCertificates: (state, action) => {
            state.overviewMissingCertificates = action.payload;
        },
        setOverviewPendingCertificates: (state, action) => {
            state.overviewPendingCertificates = action.payload;
        },
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setPendingCertificatesLoading: (state, action) => {
            state.isPendingCertificatesloading = action.payload;
        },
        setMissingCertificatesLoading: (state, action) => {
            state.isMissingCertificatesloading = action.payload;
        },
    },
});

export const {
    setLoading,
    setOverview,
    setOverviewMissingCertificates,
    setOverviewPendingCertificates,
    setPendingCertificatesLoading,
    setMissingCertificatesLoading,
} = overviewSlice.actions;

export const selectOverview = state => {
    return state.overview.overview;
};

export const selectOverviewMissingCertificates = state => {
    return state.overview.overviewMissingCertificates;
};

export const selectOverviewPendingCertificates = state => {
    return state.overview.overviewPendingCertificates;
};

export const selectLoading = state => {
    return state.overview.isloading;
};

export const selectPendingCertificatesLoading = state => {
    return state.overview.isPendingCertificatesloading;
};

export const selectMissingCertificatesLoading = state => {
    return state.overview.isMissingCertificatesloading;
};

export const fetchOverviewAPI = documentTypeId => async (dispatch, getState) => {
    const { overview } = getState();
    if (overview.isloading) {
        return;
    }
    dispatch(setLoading(true));
    const response = await axios.get(buildApiV3Url(`overview?documentTypeId=${documentTypeId}`), {
        withCredentials: true,
    });
    if (response && response.data) {
        dispatch(setOverview(response.data));
    }
    dispatch(setLoading(false));
};

export const fetchOverviewMissingCertificatesAPI = () => async (dispatch, getState) => {
    const { overview } = getState();
    if (overview.isMissingCertificatesloading) {
        return;
    }

    dispatch(setMissingCertificatesLoading(true));
    const response = await axios.get(buildApiV3Url(`overview-missing-certificates`), {
        withCredentials: true,
    });

    if (response && response.data) {
        dispatch(setOverviewMissingCertificates(response.data));
    }
    dispatch(setMissingCertificatesLoading(false));
};

export const fetchOverviewPendingCertificatesAPI = documentTypeId => async (dispatch, getState) => {
    const { overview } = getState();
    if (overview.isPendingCertificatesloading) {
        return;
    }

    dispatch(setPendingCertificatesLoading(true));
    const response = await axios.get(
        buildApiV3Url(`overview-pending-certificates?documentTypeId=${documentTypeId}`),
        { withCredentials: true }
    );

    if (response && response.data) {
        dispatch(setOverviewPendingCertificates(response.data));
    }
    dispatch(setPendingCertificatesLoading(false));
};
export default overviewSlice.reducer;
