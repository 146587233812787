import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SLoader, STabs, SRow, SCol, STag, SAlert } from "@avalara/skylab-react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { upperFirst } from "lodash";
import {
    fetchCustomerAPI,
    selectCustomer,
    selectLoading,
    selectHasCustomer,
    getCustomerMissingCertRegion,
    setIsCustomerMissingCertificates,
    setCustomerMissingCertRegions,
    selectIsCustomerMissingCertificates,
    selectCustomerMissingCertRegions,
    getVendorMissingCertRegion,
    setCustomer,
} from "../../app/customerSlice";
import Customer from "./Customer";
import CustomerExemptions from "./customerExemptions/CustomerExemptions";
import RelatedCustomers from "./relatedCustomers/RelatedCustomers";
import CustomerHistory from "./customerHistory/CustomerHistoryGrid";
import DuplicateCustomers from "./duplicateCustomers/DuplicateCustomers";
import { selectSession } from "../../app/sessionSlice";
import CustomerQuickSearch from "../sharedComponents/CustomerQuickSearch";
import {
    selectSetting,
    selectIsOutgoingMatrixSet,
    fetchOutgoingMatrixSet,
} from "../../app/settingsSlice";
import { isEligibleUser, vendorExemptions, essentialsOrAbove } from "../../shared/sessionUtility";
import RequestDialog from "../sharedDialogs/RequestDialog";
import Upgrade from "../upgradeTier/OnlineBuyDialog";
import featureFlag from "../../featureToggler/featureFlag";
import FeatureToggler from "../../featureToggler/FeatureToggler";
import MissingMatrixAlert from "../sharedComponents/MissingMatrixAlert";
import "./customer.css";

const CustomerHeader = React.memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const session = useSelector(selectSession, shallowEqual);
    const isLoading = useSelector(selectLoading);
    const isHasCustomer = useSelector(selectHasCustomer, shallowEqual);
    const customer = useSelector(selectCustomer, shallowEqual);
    const settings = useSelector(selectSetting, shallowEqual);
    const [parentTag, setParentTag] = useState("");
    const [childTag, setChildTag] = useState("");
    const [showHistory, setShowHistory] = useState(false);
    const [showRelated, setShowRelated] = useState(false);
    const [showDuplicates, setShowDuplicates] = useState(false);
    const location = useLocation();
    const cxVendor = location.pathname.includes("vendor");
    const entityType = customer.isVendor ? "vendor" : "customer";
    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const isCustomerMissingCertificates = useSelector(selectIsCustomerMissingCertificates);
    const customerMissingCertRegions = useSelector(selectCustomerMissingCertRegions, shallowEqual);
    const [isSpecificRequestDialogOpen, setIsSpecificRequestDialogOpen] = useState(false);
    const matrixSet = useSelector(selectIsOutgoingMatrixSet);
    const isEligibleUserCustRequestCertificateFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.singleRequest)
    );
    const isEligibleUserVendorCertificateFlag = dispatch(
        isEligibleUser(featureFlag.vendors.createAndSendCertificates)
    );

    const isEligibleUserRelatedCustomerFlag = dispatch(
        isEligibleUser(featureFlag.customers.relatedCustomer, true)
    );

    const isEligibleUserDuplicateCustomerFlag = dispatch(
        isEligibleUser(featureFlag.customers.duplicateCustomer, true)
    );

    const isEligibleUserVendorAdditionalInfoFlag = dispatch(
        isEligibleUser(featureFlag.vendors.vendorAdditionalInfo)
    );
    const isEssentialsOrAbove = dispatch(essentialsOrAbove());
    const isVendorExemptions = dispatch(vendorExemptions());

    const isAvaTaxAp =
        isEligibleUserVendorAdditionalInfoFlag && !isEssentialsOrAbove && !isVendorExemptions;
    const [showExemptions, setShowExemptions] = useState(isEssentialsOrAbove || isVendorExemptions);
    const [showDetails, setShowDetails] = useState(isAvaTaxAp);

    let tabItems = [
        {
            id: "tabpanel-customer-details",
            label: `${upperFirst(entityType)} details`,
            tabPanelId: "tabpanel-customer-details",
            selected: showDetails,
        },
    ];

    if (!isAvaTaxAp) {
        tabItems.splice(0, 0, {
            id: "tabpanel-customer-exemptions",
            label: "Certificates",
            tabPanelId: "tabpanel-customer-exemptions",
            selected: showExemptions,
        });
    }

    if (
        isEligibleUserRelatedCustomerFlag &&
        !customer.isVendor &&
        settings.showCustomerRelationships === true
    ) {
        tabItems = [
            ...tabItems,
            {
                id: "tabpanel-customer-related",
                label: "Bill-to and ship-to relationships",
                tabPanelId: "tabpanel-customer-related",
                selected: showRelated,
            },
        ];
    }

    if (
        isEligibleUserDuplicateCustomerFlag &&
        !customer.isVendor &&
        settings.showDuplicateCustomers === true
    ) {
        tabItems = [
            ...tabItems,
            {
                id: "tabpanel-customer-duplicates",
                label: "Duplicate customers",
                tabPanelId: "tabpanel-customer-duplicates",
                selected: showDuplicates,
            },
        ];
    }

    if (!isAvaTaxAp) {
        tabItems = [
            ...tabItems,
            {
                id: "tabpanel-customer-history",
                label: "History",
                tabPanelId: "tabpanel-customer-history",
                selected: showHistory,
            },
        ];
    }

    let customerHeaderDOM = null;

    const navigateTo = to => {
        if (to.includes("history") && !showHistory) {
            setShowDetails(false);
            setShowExemptions(false);
            setShowRelated(false);
            setShowDuplicates(false);
            setShowHistory(true);
        } else if (to.includes("exemptions") && !showExemptions) {
            setShowHistory(false);
            setShowDetails(false);
            setShowRelated(false);
            setShowDuplicates(false);
            setShowExemptions(true);
        } else if (to.includes("related") && !showRelated) {
            setShowHistory(false);
            setShowDetails(false);
            setShowExemptions(false);
            setShowDuplicates(false);
            setShowRelated(true);
        } else if (to.includes("details") && !showDetails) {
            setShowHistory(false);
            setShowExemptions(false);
            setShowRelated(false);
            setShowDuplicates(false);
            setShowDetails(true);
        } else if (to.includes("duplicates") && !showDuplicates) {
            setShowHistory(false);
            setShowExemptions(false);
            setShowRelated(false);
            setShowDetails(false);
            setShowDuplicates(true);
        }
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isHasCustomer) {
            if (location.pathname.includes("vendor")) {
                dispatch(getVendorMissingCertRegion(params.id));
            } else {
                dispatch(getCustomerMissingCertRegion(params.id));
            }

            return () => {
                dispatch(setIsCustomerMissingCertificates(false));
                dispatch(setCustomerMissingCertRegions([]));
            };
        }
    }, [params.id, isHasCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(fetchCustomerAPI(params.id));
        const isEligibleUserSendingCertficateExemptionMatrix = dispatch(
            isEligibleUser(featureFlag.settings.sendingCertficateExemptionMatrix, true)
        );
        if (isEligibleUserSendingCertficateExemptionMatrix) {
            dispatch(fetchOutgoingMatrixSet());
        }
    }, [params.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // clean up when component dismounts
    useEffect(() => {
        return () => {
            dispatch(setCustomer({}));
        };
    }, [dispatch]);

    useEffect(() => {
        if (customer?.isBillTo && customer?.isShipTo) {
            setParentTag("Bill-to customer");
            setChildTag("Ship-to customer");
        } else if (customer?.isBillTo) {
            setParentTag("Bill-to customer");
            setChildTag("");
        } else if (customer?.isShipTo) {
            setChildTag("Ship-to customer");
            setParentTag("");
        } else {
            setParentTag("");
            setChildTag("");
        }
    }, [customer]);

    const clickRequest = () => {
        if (session.baseSubscription) {
            setIsUpgradeDialogOpen(true);
        } else if (customer.isVendor) {
            navigate(`/vendor-requests/${customer.id}`);
        } else if (!isDialogOpen) {
            setIsDialogOpen(true);
        }
    };

    if (isLoading) {
        customerHeaderDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" aria-live="polite" />
            </div>
        );
    } else if (
        (customer && cxVendor && customer.isVendor) ||
        (customer && !cxVendor && !customer.isVendor)
    ) {
        customerHeaderDOM = (
            <div>
                <SRow className="margin-bottom-xl">
                    <SCol className="pad-bottom-sm">
                        <CustomerQuickSearch />
                        <h1 className="inline-block">{customer.name}</h1>
                    </SCol>
                    {!matrixSet ? <MissingMatrixAlert /> : ""}
                    {isCustomerMissingCertificates && !isAvaTaxAp ? (
                        <SCol className="pad-bottom-sm">
                            <SAlert id="error-alert" status="warning" noDismiss role="alert">
                                <span>
                                    {customer.name} is missing certificates for{" "}
                                    {customerMissingCertRegions}
                                    {". "}
                                    <button
                                        className="ghost small pad-left-xs"
                                        onClick={() => clickRequest()}
                                        disabled={
                                            customer.isVendor
                                                ? !isEligibleUserVendorCertificateFlag
                                                : !isEligibleUserCustRequestCertificateFlag
                                        }>
                                        {customer.isVendor ? (
                                            <span className="text-underline">
                                                Create &amp; send
                                            </span>
                                        ) : (
                                            <span className="text-underline">
                                                Request missing certificates
                                            </span>
                                        )}
                                    </button>
                                </span>
                            </SAlert>
                        </SCol>
                    ) : null}
                    <SCol>
                        <label
                            htmlFor="customer-number"
                            className="inline-block font-bold text-sm margin-right-sm">
                            {upperFirst(entityType)} code
                        </label>
                        <span id="customer-number">{customer.customerNumber}</span>
                        <FeatureToggler category="customers" id="parentTag">
                            <>
                                {parentTag ? (
                                    <STag
                                        className="margin-left-sm blue-lighter"
                                        hidden={
                                            customer.isVendor || !settings.showCustomerRelationships
                                        }>
                                        <span slot="content">{parentTag}</span>
                                    </STag>
                                ) : null}
                                {childTag ? (
                                    <STag
                                        className="margin-left-sm blue-lighter"
                                        hidden={
                                            customer.isVendor || !settings.showCustomerRelationships
                                        }>
                                        <span slot="content">{childTag}</span>
                                    </STag>
                                ) : null}
                            </>
                        </FeatureToggler>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        {customer && customer.id ? (
                            <React.Fragment>
                                <STabs
                                    tabItems={tabItems}
                                    onS-select={e => navigateTo(e.detail.id)}
                                />
                                {showExemptions &&
                                    params.id.toString() === customer.id.toString() && (
                                        <CustomerExemptions
                                            customer={customer}
                                            isDialogOpen={isDialogOpen}
                                            setIsDialogOpen={setIsDialogOpen}
                                            setIsUpgradeDialogOpen={setIsUpgradeDialogOpen}
                                            matrixSet={matrixSet}
                                        />
                                    )}
                                {showRelated && (
                                    <RelatedCustomers customer={customer} navigateTo={navigateTo} />
                                )}
                                {showDuplicates && (
                                    <DuplicateCustomers
                                        customerId={customer.id}
                                        customerName={customer.name}
                                        navigateTo={navigateTo}
                                    />
                                )}
                                {showHistory && <CustomerHistory customer={customer} />}
                                {showDetails && (
                                    <Customer navigateTo={navigateTo} setVendor={cxVendor} />
                                )}
                            </React.Fragment>
                        ) : null}
                    </SCol>
                </SRow>
                <RequestDialog
                    isRequestDialogOpen={isDialogOpen || isSpecificRequestDialogOpen}
                    setIsGeneralRequestDialogOpen={setIsDialogOpen}
                    setIsRequestDialogOpen={setIsSpecificRequestDialogOpen}
                    customer={customer}
                    customerCount={1}
                />
                <Upgrade
                    isUpgradeDialogOpen={isUpgradeDialogOpen}
                    setIsUpgradeDialogOpen={setIsUpgradeDialogOpen}
                    type="request"
                />
            </div>
        );
    } else {
        customerHeaderDOM = (
            <div>
                <SRow className="margin-bottom-xl">
                    <SCol className="pad-bottom-sm">
                        <CustomerQuickSearch />
                        <h1 className="inline-block">
                            No {cxVendor ? "vendor " : "customer"} can be found.
                        </h1>
                    </SCol>
                </SRow>
            </div>
        );
    }
    return customerHeaderDOM;
});

export default CustomerHeader;
