import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SColumnManager } from "@avalara/skylab-react";
import {
    selectPendingCertificateSelectedColumns,
    setPendingCertificateSelectedColumns,
    upsertPendingCertificateColumnConfig,
} from "../../../app/certificateSlice";

const PendingCertificateColumnsManager = React.memo(() => {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const defaultColumns = useSelector(selectPendingCertificateSelectedColumns, shallowEqual);

    const dispatchUpsertPendingCertificateColumnConfig = columnItem => {
        return new Promise((resolve, reject) => {
            dispatch(upsertPendingCertificateColumnConfig(columnItem))
                .then(() => resolve())
                .catch(error => reject(error));
        });
    };

    const applyChanges = async columnItem => {
        setSubmitting(true);
        dispatch(setPendingCertificateSelectedColumns(columnItem));
        await dispatchUpsertPendingCertificateColumnConfig(columnItem);
        setSubmitting(false);
    };

    return (
        <SColumnManager
            id="pending-certificate-column-manager-panel"
            columnItems={defaultColumns}
            loading={submitting}
            onS-save={e => applyChanges(e.detail.columnItems)}
        />
    );
});

export default PendingCertificateColumnsManager;
