import React, { useCallback, useState } from "react";
import { SRow, SCol, SBox, SLoader } from "@avalara/skylab-react";
import { useSelector } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import { selectIsFormLoading } from "../../../../app/contentSlice";

import "../../../content/taxContent.css";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const CertificateRequestFormsContent = React.memo(() => {
    const isFormLoading = useSelector(selectIsFormLoading);
    const [numberPages, setNumberPages] = useState(null);
    let formData = null;
    const onDocumentLoadSuccess = useCallback(({ numPages }) => {
        setNumberPages(numPages);
    }, []);

    if (localStorage.getItem("certificateForm")) {
        formData = localStorage.getItem("certificateForm");
    }

    const fileLoader = (
        <SBox className="no-border">
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        </SBox>
    );
    return (
        <SRow>
            <SCol span="12" className="content-border">
                {!isFormLoading && formData?.length !== 0 ? (
                    <Document
                        file={formData}
                        loading={fileLoader}
                        noData={
                            isFormLoading && formData?.length === 0 ? fileLoader : "no file found"
                        }
                        onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numberPages), (el, index) => (
                            <Page
                                className="pdf-border"
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={1.75}
                            />
                        ))}
                    </Document>
                ) : (
                    fileLoader
                )}
            </SCol>
        </SRow>
    );
});

export default CertificateRequestFormsContent;
