import { SBox, SLoader } from "@avalara/skylab-react";
import React, { useCallback, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

function FileRenderer(props) {
    const [numberPages, setNumberPages] = useState(null);
    const [fileType, setFileType] = useState(0);
    const isUploadingFile = false;

    useEffect(() => {
        const pdfTypeString = "data:application/pdf;base64";

        if (props.fileData) {
            if (props.fileData.includes(pdfTypeString)) {
                setFileType(1);
            } else {
                setFileType(2);
            }
        }
    }, [props.fileData]);

    const onDocumentLoadSuccess = useCallback(({ numPages }) => {
        setNumberPages(numPages);
    }, []);

    const onDocumentClick = useCallback(() => {
        const downloadLink = document.createElement("a");
        downloadLink.href = props.fileData;
        downloadLink.download = "template.pdf";
        downloadLink.click();
    }, []);

    const fileLoader = (
        <SBox className="no-border">
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        </SBox>
    );

    let viewerDOM = null;
    if (isUploadingFile) {
        viewerDOM = fileLoader;
    } else if (fileType === 1) {
        viewerDOM = (
            <Document
                onClick={onDocumentClick}
                file={props.fileData}
                loading={fileLoader}
                noData={fileLoader}
                className={props.documentClass}
                onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numberPages), (el, index) => (
                    <Page
                        className="pdf-border"
                        key={`page_${index + 1}`}
                        scale={props.scale ? props.scale : 1.6}
                        pageNumber={index + 1}
                    />
                ))}
            </Document>
        );
    } else if (fileType === 2) {
        viewerDOM = (
            <a download="preview.jpg" href={props.fileData}>
                <img alt="preview-file" className={props.imageClass} src={props.fileData} />
            </a>
        );
    }

    return viewerDOM;
}

export default FileRenderer;
