import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import { selectLoadingSignature, deleteSignatureDataById } from "../../../app/settingsSlice";

function SignaturePadDeleteDialog(props) {
    const isDeletingSignature = useSelector(selectLoadingSignature);
    const deleteButton = classnames({ primary: true, small: true, loading: isDeletingSignature });
    const dispatch = useDispatch();
    const deleteSig = async () => {
        try {
            await dispatch(deleteSignatureDataById(props.signatureId));
            props.deleteMessage("success");
            setTimeout(() => {
                props.reRoute();
            }, 800);
        } catch {
            props.deleteMessage("error");
        }

        props.setIsDeleteSignatureDialogOpen(false);
    };
    return (
        <SDialog
            id="signatureDelete-dialog"
            open={props.isDeleteSignatureDialogOpen}
            onS-dismiss={() => {
                props.setIsDeleteSignatureDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="deleteSignature">
                Delete the signature?
            </div>
            <div slot="body">
                <span>You can&apos;t undo this action</span>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    disabled={isDeletingSignature}
                    onClick={() => props.setIsDeleteSignatureDialogOpen(false)}>
                    No, cancel
                </button>
                <button className={deleteButton} type="button" onClick={() => deleteSig()}>
                    Yes, delete
                </button>
            </div>
        </SDialog>
    );
}

export default SignaturePadDeleteDialog;
