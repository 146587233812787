import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { SIcon, SLoader } from "@avalara/skylab-react";
import { useNavigate } from "react-router-dom";
import { selectIsLoadingShipTos } from "../../../app/customerSlice";
import { isEligibleUser } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";

const ShipToCustomers = React.memo(props => {
    const loading = useSelector(selectIsLoadingShipTos);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isEligibleUserRelatedCustomerFlag = dispatch(
        isEligibleUser(featureFlag.customers.relatedCustomer)
    );

    const unlinkCustomer = row => {
        props?.setIsUnlinkCustomerDialogOpen(true);
        props?.setChildCustomerId(row.id);
        props?.setLinkDialogType(2);
    };

    const navigateToDefaultTab = customerId => {
        props?.navigateToDefaultTab();
        navigate(`/customer/${customerId}`);
    };

    const columns = [
        gridHeader(
            "Customer code",
            "customerNumber",
            (value, row) => (
                <button
                    className="link"
                    onClick={() => {
                        navigateToDefaultTab(row.id);
                    }}>
                    {row.customerNumber}
                </button>
            ),
            null,
            null
        ),
        gridHeader("Customer name", "name", null, null, null),
        gridHeader("Region", "stateName", null, null, null),
        gridHeader("Email", "emailAddress", null, null, null),
        gridHeader("Phone", "phoneNumber", null, null, null),
        gridHeader(
            "",
            "",
            (value, row) => (
                <button
                    type="button"
                    className="secondary small"
                    disabled={!isEligibleUserRelatedCustomerFlag}
                    onClick={() => unlinkCustomer(row)}>
                    <SIcon name="close" small className="pad-right-xs" aria-hidden="true" />
                    Unlink
                </button>
            ),
            "text-right",
            "text-right",
            null
        ),
    ];

    const getRowKey = row => {
        return row.id;
    };
    let table = null;
    if (props.shipTos) {
        table = loading ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <s-table-container>
                <DataGrid
                    columns={columns}
                    rows={props.shipTos}
                    getKey={getRowKey}
                    loading={loading}
                    inContainer
                />
            </s-table-container>
        );
    }
    return table;
});

export default ShipToCustomers;
