import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SDialog } from "@avalara/skylab-react";
import axios from "axios";
import { fetchSavedSearchesAPI } from "../../../app/savedSearchesSlice";
import toast from "../../../hooks/toast";
import { buildApiV3Url } from "../../../shared/Utils";

export default function DeleteSavedSearchDialog(props) {
    const dispatch = useDispatch();
    const [showToast] = toast();
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = async () => {
        setIsLoading(true);
        const response = await axios.delete(
            buildApiV3Url(`savedSearches/${props.savedSearchIdToDelete}`),
            { withCredentials: true }
        );
        if (response.status === 200) {
            props.setSavedSearchIdToDelete("");
            dispatch(fetchSavedSearchesAPI());
            showToast("success", "Deleted the saved ecm report");
        }
        setIsLoading(false);
    };

    return (
        <SDialog
            open={props.savedSearchIdToDelete !== ""}
            id="deleteSavedSearch-dialog"
            onS-dismiss={() => props.setSavedSearchIdToDelete("")}
            aria-modal="true">
            <div slot="header" id="deleteSavedSearch">
                Delete this report?
            </div>
            <div slot="body">You can&apos;t undo this action</div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => props.setSavedSearchIdToDelete("")}>
                    Cancel
                </button>
                <button
                    className={isLoading ? "primary small loading" : "primary small"}
                    onClick={() => handleDelete()}>
                    Yes,delete
                </button>
            </div>
        </SDialog>
    );
}
