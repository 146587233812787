import React from "react";
import { SRow, SCol } from "@avalara/skylab-react";
import { useSelector } from "react-redux";
import Parser from "html-react-parser";
import classnames from "classnames";
import { selectData, selectActiveLaw } from "../../../app/contentSlice";

export const downloadButtonLabel = "Download the document";

const TaxLawsContent = React.memo(() => {
    const pageData = useSelector(selectData);
    const downloadDocumentButton = classnames({ primary: true, large: true });
    const activeLaw = useSelector(selectActiveLaw);
    const openDocument = contenturl => {
        window.open(contenturl);
    };

    const law = pageData?.data?.find(element => {
        return element.id == activeLaw; /* eslint eqeqeq: 0 */
    });

    return (
        <SRow>
            <SCol>{law?.name ? <h2>{law.name}</h2> : null}</SCol>

            {law?.contenturl ? (
                <SCol>
                    <button
                        className={downloadDocumentButton}
                        type="primary"
                        onClick={() => openDocument(law.contenturl)}>
                        {downloadButtonLabel}
                    </button>
                </SCol>
            ) : (
                <SCol span="9">{law && Parser(law.content)} </SCol>
            )}
        </SRow>
    );
});

export default TaxLawsContent;
