import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SRow, SCol, SDialog, SIcon, SSelect } from "@avalara/skylab-react";
import { customFieldTypes } from "../../../../shared/constants";
import {
    AddOrUpdateCustomerCustomFields,
    selectIsAddOrEditLoading,
    setIsAddOrEditLoading,
} from "../../../../app/customerCustomFieldSlice";
import { errorTag, noErrorTag } from "../../../../shared/Utils";

function AddEditCustomerCustomFieldsDialog(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsAddOrEditLoading);
    const [enableSave, setEnableSave] = useState(false);
    const [nameError, setNameError] = useState("");
    const [typeError, setTypeError] = useState("");
    const [showValueBox, setShowValueBox] = useState(false);
    const [customFieldName, setFieldName] = useState(
        props.customerCustomField.fieldName ? props.customerCustomField.fieldName : ""
    );
    const [fieldType, setFieldType] = useState(
        props.customerCustomField.type ? props.customerCustomField.type : "text"
    );
    const [fieldValues, setFieldValues] = useState(
        props.customerCustomField.possibleValues ? props.customerCustomField.possibleValues : ""
    );
    const [optionList, setOptionList] = useState(customFieldTypes);

    const handleNameChange = value => {
        setFieldName(value);
        if (value === "") setNameError("error");
        else setNameError("");
    };

    const handleTypeChange = item => {
        setFieldType(item.value);
        if (item.value === "select" || item.value === "multi-select") {
            setShowValueBox(true);
        } else if (item.value === "boolean") {
            setShowValueBox(false);
            setFieldValues("Y\nN");
        } else {
            setShowValueBox(false);
            setFieldValues("");
        }
        setTypeError("");
    };

    function getFormattedValue() {
        let formattedStr = "";
        if (fieldValues.length > 0) {
            if (props.addOrEdit === "Edit") {
                formattedStr = fieldValues.trim().replaceAll("|", "\n");
                setFieldValues(formattedStr);
            }
        }
    }
    const clearFields = () => {
        setFieldName("");
        setNameError("");
        setFieldType("");
        setTypeError("");
        setFieldValues("");
        dispatch(setIsAddOrEditLoading(false));
    };

    useEffect(() => {
        if (props.addOrEdit === "Edit") {
            setEnableSave(true);
            if (fieldType) {
                const listItems = customFieldTypes.map(x => {
                    if (x.value === fieldType) {
                        return { label: x.label, value: x.value, selected: true };
                    }
                    return { label: x.label, value: x.value, selected: false };
                });

                setOptionList(listItems);
                if (fieldType === "select" || fieldType === "multi-select") {
                    setShowValueBox(true);
                    getFormattedValue();
                } else if (fieldType === "boolean") {
                    setShowValueBox(false);
                    getFormattedValue();
                } else {
                    setShowValueBox(false);
                    setFieldValues("");
                }
            }
        } else if (props.addOrEdit === "Add") {
            clearFields();
            setFieldType("text");
        }
    }, [props]);

    useEffect(() => {
        let val = false;
        if (showValueBox && fieldValues?.length === 0) {
            val = false;
        } else if (customFieldName && fieldType?.length > 0) {
            val = true;
        }
        setEnableSave(val);
    }, [customFieldName, fieldType, showValueBox, fieldValues]);

    const onSave = async () => {
        const payload = {
            fieldName: customFieldName,
            type: fieldType,
            possibleValues: fieldValues?.trim()?.replaceAll("\n", "|"),
            usesDataEntry: false,
            requiredInDataEntry: false,
            legacyField: "",
            usesGencert: false,
            isEditable: true,
            gencertLock: false,
        };
        let result;
        if (props.addOrEdit === "Add") {
            result = await dispatch(AddOrUpdateCustomerCustomFields("Add", payload));
        } else {
            result = await dispatch(
                AddOrUpdateCustomerCustomFields("Edit", payload, props.customerCustomField.id)
            );
        }
        if (result) props.setOpenAddUpdateDialog(false);
    };

    const onCancel = () => {
        clearFields();
        props.setOpenAddUpdateDialog(false);
    };

    return (
        <SDialog
            id="add-edit-custom-fields-dialog"
            open={props.openAddUpdateDialog}
            onS-dismiss={onCancel}
            className="overflow-visible"
            aria-modal="true">
            <div slot="header" id="addEditCustomFields">
                {props.addOrEdit === "Add"
                    ? "Add a custom field for customers"
                    : "Edit a custom field for customers"}
            </div>
            <div slot="body">
                <SRow className="pad-bottom-sm pad-top-xs">
                    <SCol>
                        <label htmlFor="custom-field-name" className="required">
                            Name
                        </label>
                        <input
                            id="custom-field-name"
                            name="Name"
                            type="text"
                            value={customFieldName}
                            onChange={e => {
                                handleNameChange(e.target.value);
                            }}
                            className={nameError ? errorTag : noErrorTag}
                        />
                        <div className="input-msg">
                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                            <span className="top-xs">Enter name</span>
                        </div>
                    </SCol>
                </SRow>
                <SRow className="pad-bottom-md">
                    <SCol span="auto">
                        <label htmlFor="type" className="required" id="lbl-type">
                            Type
                        </label>
                        <SSelect
                            inputId="type"
                            onS-select={e => handleTypeChange(e.detail.item)}
                            optionsList={optionList}
                            all
                            required
                            typeError={typeError}
                        />

                        {typeError.length > 0 && typeError === "error" ? (
                            <div className="input-msg">
                                <SIcon name="alert-circle-filled" aria-hidden="true" />
                                <span className="top-xs">Select a type.</span>
                            </div>
                        ) : null}
                    </SCol>
                </SRow>
                {showValueBox ? (
                    <SRow className="pad-bottom-md">
                        <SCol span="auto">
                            <label htmlFor="values">Values</label>
                            <textarea
                                name="values"
                                id="values"
                                rows="3"
                                onChange={e => setFieldValues(e.target.value)}
                                value={fieldValues}
                            />
                        </SCol>
                    </SRow>
                ) : null}
            </div>
            <div slot="footer">
                <button className="secondary" onClick={onCancel}>
                    Cancel
                </button>
                <button
                    disabled={!enableSave}
                    className={isLoading ? "primary right loading" : "primary right"}
                    id="save-custom-field"
                    type="button"
                    onClick={onSave}>
                    Save
                </button>
            </div>
        </SDialog>
    );
}

export default AddEditCustomerCustomFieldsDialog;
