import { SCol, SDialog, SIcon, SRow } from "@avalara/skylab-react";
import React, { useState, useRef, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateProductCategoryAsync } from "../../../app/productCategoriesSlice";
import { errorTag, noErrorTag, matchSubscriptions } from "../../../shared/Utils";
import { avaTaxSubscription } from "../../navigation/Subscription";
import { selectSession } from "../../../app/sessionSlice";
import SelectAvataxCategories from "./SelectAvataxCategories";

const EditProductCategoryDialog = React.memo(props => {
    const dialogName = "EditProductCategoryDialog";
    const [categoryName, setCategoryName] = useState(null);
    const [categoryCodes, setCategoryCodes] = useState([]);
    const dispatch = useDispatch();
    const [categoryCodeError, setCategoryCodeError] = useState("");
    const [categoryNameError, setCategoryNameError] = useState("");
    const codeRef = useRef();
    const session = useSelector(selectSession, shallowEqual);
    const isAvaTax = matchSubscriptions(session.subscriptions, avaTaxSubscription);

    useEffect(() => {
        setCategoryName(props.oldCategoryName);
    }, [props.oldCategoryName]);

    useEffect(() => {
        if (categoryCodes.length === 0) {
            props.oldTaxcodes.forEach(taxcode => {
                categoryCodes.push(taxcode.taxCode);
            });
        }
    }, []);

    const handleCodesInput = txCode => {
        setCategoryCodeError("valid");
        const result = txCode.split(",");
        setCategoryCodes(result);
    };

    const handleCodesSelect = codeSel => {
        setCategoryCodes([...categoryCodes, codeSel.value]);
        setCategoryCodeError("valid");
    };

    const handleCodeRemove = codeSel => {
        setCategoryCodes(prevState => prevState.filter(prevItem => prevItem !== codeSel.value));
    };

    const buildCategoryData = () => {
        return {
            ExistingCategoryName: props.oldCategoryName,
            NewCategoryName: categoryName,
            TaxCode: categoryCodes,
        };
    };

    const handleNameChange = value => {
        setCategoryName(value);
        if (value === "") setCategoryNameError("error");
        else setCategoryNameError("");
    };

    const handleValidation = () => {
        let isValid = true;
        if (categoryName === "" || !categoryName) {
            setCategoryNameError("error");
            isValid = false;
        }
        if (!categoryCodes || categoryCodes.length < 1) {
            setCategoryCodeError("error");
            isValid = false;
        }
        return isValid;
    };

    const cancelHandler = () => {
        setCategoryNameError(null);
        setCategoryCodeError(null);
        setCategoryName(null);
        setCategoryCodes([]);
        props.setIsEditDialogOpen(false);
    };

    const handleSubmission = async () => {
        if (handleValidation()) {
            const theData = buildCategoryData();
            await dispatch(updateProductCategoryAsync(theData));
            setCategoryName(null);
            setCategoryCodes(null);
            setCategoryCodeError(null);
            setCategoryNameError(null);
            props.setIsEditDialogOpen(false);
        }
    };

    return (
        <SDialog
            id={dialogName}
            open={props.isEditDialogOpen}
            aria-modal="true"
            className="overflow-visible width-500"
            onS-dismiss={() => {
                props.setIsEditDialogOpen(false);
            }}>
            <div slot="header" id="editCategory">
                Edit a category
            </div>
            <div slot="body" className="category-modal-body">
                <SRow>
                    <SCol>
                        <label htmlFor="category-name" className="required">
                            {" "}
                            Name{" "}
                        </label>
                        <input
                            id="category-name"
                            name="category-name"
                            type="text"
                            onChange={e => handleNameChange(e.target.value)}
                            defaultValue={categoryName}
                            className={categoryNameError === "error" ? errorTag : noErrorTag}
                        />
                        {categoryNameError === "error" ? (
                            <div className="input-msg">
                                <SIcon name="alert-circle-filled" aria-hidden="true" />
                                <span className="top-xs">Enter a name </span>
                            </div>
                        ) : null}
                    </SCol>
                    <SCol className="margin-top-sm">
                        <SelectAvataxCategories
                            onAdd={handleCodesSelect}
                            onRemove={handleCodeRemove}
                            codeError={categoryCodeError}
                            value={categoryCodes}
                            onInput={handleCodesInput}
                            avaCheck={isAvaTax}
                            codeRef={codeRef}
                        />
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button
                    className="primary large dialog-footer-button"
                    onClick={() => {
                        handleSubmission();
                    }}>
                    Save
                </button>
                <button
                    className="secondary large dialog-footer-button"
                    onClick={() => {
                        cancelHandler();
                    }}>
                    Cancel
                </button>
            </div>
        </SDialog>
    );
});

export default EditProductCategoryDialog;
