import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { checkSelectComponentProps } from "../../shared/Utils";
import {
    selectSUTOutgoingExposureZones,
    selectIsLoadingExposureZones,
    getExposureZonesAsync,
} from "../../app/certificateSlice";

const SelectOutgoingExposureZone = React.forwardRef((props, ref) => {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingExposureZones);
    const isRunned = useRef(false);
    const { value, multiple, isDataEntrySet } = props;
    const sutOutgoingZones = useSelector(selectSUTOutgoingExposureZones, shallowEqual).map(
        element => ({
            label: element.name,
            value: element.id,
            selected: false,
            isDataEntrySet: false,
        })
    );

    const [optionsList, setOptionsList] = useState(sutOutgoingZones);
    useEffect(() => {
        dispatch(
            getExposureZonesAsync(
                `?$filter=documentType.name eq 'Sales and Use Tax' AND documentType.outgoing eq true`
            )
        );
    }, [isDataEntrySet, dispatch]); // eslint-disable-line

    useEffect(() => {
        setOptionsList(prevOptionList => {
            const optionsListHasZones = prevOptionList.find(
                x => x.label === sutOutgoingZones[0]?.label
            );
            if (!optionsListHasZones) return [...prevOptionList, ...sutOutgoingZones];
            return prevOptionList;
        });
    }, [JSON.stringify(sutOutgoingZones)]); // eslint-disable-line

    useEffect(() => {
        setOptionsList(exposureZoneList => {
            return exposureZoneList.map(exposureZone => {
                const expZone = exposureZone;
                let selectedChanged = false;
                if (value && value[0]?.name && typeof value === "object") {
                    const name = value.map(zone => zone.name);
                    expZone.selected = name.includes(exposureZone.label);
                    selectedChanged = true;
                } else if (
                    multiple &&
                    (value?.includes(exposureZone.label) || value?.includes(exposureZone.value))
                ) {
                    expZone.selected = true;
                    selectedChanged = true;
                }
                if (
                    value === exposureZone.label ||
                    value?.toString() === exposureZone.value?.toString()
                ) {
                    expZone.selected = true;
                    selectedChanged = true;
                }
                if (!selectedChanged) {
                    expZone.selected = false;
                }

                return expZone;
            });
        });
    }, [JSON.stringify(value), multiple]); // eslint-disable-line

    useEffect(() => {
        if (props?.isAll && !isRunned.current) {
            setOptionsList(prevState => {
                return [{ label: "All", value: "", selected: "true" }, ...prevState];
            });
            isRunned.current = true;
        }
    }, [props.isAll]); // eslint-disable-line

    checkSelectComponentProps("SelectExposureZone", props);

    return (
        <React.Fragment>
            {props.label ? (
                <label htmlFor={getNextId()} id={`lbl-${getCurrentId()}`}>
                    {props.label}
                </label>
            ) : (
                <label htmlFor={getNextId()} id={`lbl-${getCurrentId()}`}>
                    Region{props.multiple ? "s" : ""} covered by this certificate
                </label>
            )}
            <SSelect
                name="exposureZone"
                inputId={getCurrentId()}
                selectionOptional
                optionsList={optionsList}
                loading={isLoading}
                className={props.error ? props.error : ""}
                onS-select={e => {
                    props.onAdd(e.detail.item);
                }}
                onS-deselect={e => {
                    props.onRemove(e.detail.item, e?.detail?.isDeselectAll);
                }}
                onS-close={e => (props.onClose ? props.onClose(e) : null)}
                disabled={props?.disabled || props?.exposureZoneDisabled ? "disabled" : null}
                multiple={props.multiple}
                ref={ref}
                value={props?.value}
            />
        </React.Fragment>
    );
});

export default React.memo(SelectOutgoingExposureZone);
