import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SIcon, SDialog, SBox } from "@avalara/skylab-react";
import classnames from "classnames";
import toast from "../../../hooks/toast";
import {
    unlinkCustomerAPI,
    unlinkParentAPI,
    selectIsUnlinkingCustomer,
    selectIsUnlinkParent,
} from "../../../app/customerSlice";

function UnlinkCustomerDialog(props) {
    const dispatch = useDispatch();
    const [showToast] = toast();
    const dialogName = "UnlinkCustomerDialog";
    const isUnlinkingCustomer = useSelector(selectIsUnlinkingCustomer);
    const isUnlinkParent = useSelector(selectIsUnlinkParent);
    const deleteButton = classnames({
        primary: true,
        small: true,
        loading: props.linkDialogType === 1 ? isUnlinkParent : isUnlinkingCustomer,
    });
    const [dialogTitle, setDialogTitle] = useState("No Title");

    useEffect(() => {
        if (props.linkDialogType === 1) {
            setDialogTitle("Unlink a bill-to customer");
        } else if (props.linkDialogType === 2) {
            setDialogTitle("Unlink a ship-to customer");
        }
    }, [props.linkDialogType, props.unlinkType]);

    const unlinkCustomer = async () => {
        if (props.linkDialogType === 2) {
            await dispatch(unlinkCustomerAPI(props.parentCustomerId, props.childCustomerId));
            props.setIsUnlinkCustomerDialogOpen(false);
            showToast("success", "The ship-to customer has been unlinked successfully.");
        } else {
            await dispatch(unlinkParentAPI(props.parentCustomerId, props.childCustomerId));
            props.setIsUnlinkCustomerDialogOpen(false);
            showToast("success", "The bill-to customer has been unlinked successfully.");
        }
    };

    return (
        <SDialog
            id={dialogName}
            open={props.isUnlinkCustomerDialogOpen}
            onS-dismiss={() => {
                props.setIsUnlinkCustomerDialogOpen(false);
            }}
            className="unlink-dialog-width"
            aria-modal="true">
            <div slot="header" id="unlinkCustomer">
                {dialogTitle}
            </div>
            <div slot="body">
                <SBox className="yellow">
                    <div className="unlink-dialog-text">
                        <SIcon name="flag" className="medium pad-right-sm" aria-hidden="true" />
                        <span>You can&apos;t undo this action.</span>
                    </div>
                </SBox>
            </div>
            <div slot="footer" className="flex right">
                <button
                    className="secondary small"
                    onClick={() => props.setIsUnlinkCustomerDialogOpen(false)}>
                    No, cancel
                </button>
                <button className={deleteButton} type="button" onClick={unlinkCustomer}>
                    Yes, delete
                </button>
            </div>
        </SDialog>
    );
}

export default UnlinkCustomerDialog;
