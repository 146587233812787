import React, { useState } from "react";
import { SCol, SBox } from "@avalara/skylab-react";
import Upgrade from "../upgradeTier/OnlineBuyDialog";
import FVSUploader from "../sharedComponents/FVSUploader";
import FeatureToggler from "../../featureToggler/FeatureToggler";

function CertificateFileNotFound(props) {
    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);

    let uploadElm = (
        <FVSUploader
            certificateID={props?.certificateID}
            isSupplementalAttachment={false}
            showOnlyComponent
            displayOn="certificateDetails"
            isUploaderDisabled={props?.isUploaderDisabled}
            showRevokedAlert={props?.showRevokedAlert}
        />
    );

    if (props.isOutgoing) {
        uploadElm = <h1 className="margin-left-lg"> Image not found</h1>;
    }

    return (
        <>
            <SCol span="auto">
                <FeatureToggler
                    category="validatedCertificate"
                    id="upload"
                    firstChild={uploadElm}
                    secondChild={
                        <SBox className="margin-top-md text-center pad-bottom-xl">
                            <h3 className="custom-line-height">Subscribe to ECM</h3>
                            <p>
                                You need to subscribe to ECM in order to attach certificate images
                                and be ready for an audit.
                            </p>
                            <button
                                className="secondary margin-bottom-md"
                                onClick={() => setIsUpgradeDialogOpen(true)}>
                                Learn more and buy
                            </button>
                        </SBox>
                    }
                />
            </SCol>
            <Upgrade
                isUpgradeDialogOpen={isUpgradeDialogOpen}
                setIsUpgradeDialogOpen={setIsUpgradeDialogOpen}
                type="request"
            />
        </>
    );
}

export default CertificateFileNotFound;
