import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SRow, SCol, SBox } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import {
    selecCertificateForms,
    selectActiveForm,
    setActiveForm,
    getCertificateFormFile,
    fetchCertificateForms,
} from "../../../app/contentSlice";
import "../taxContent.css";

const CertificateFormsList = React.memo(props => {
    const dispatch = useDispatch();
    const certificateForms = useSelector(selecCertificateForms, shallowEqual);
    const activeFormId = useSelector(selectActiveForm);
    useEffect(() => {
        if (props?.selectedState) {
            if (certificateForms && certificateForms?.data?.length) {
                if (certificateForms.data[0]?.s3_UUID === undefined) {
                    dispatch(fetchCertificateForms(props.selectedState));
                }
                dispatch(
                    getCertificateFormFile(
                        certificateForms?.data[0]?.s3_UUID,
                        certificateForms?.data[0]?.fileName
                    )
                );
            }
        }
    }, [certificateForms, dispatch, props.selectedState]);

    const setActiveFormId = (form, e) => {
        dispatch(setActiveForm(form.id));
        dispatch(getCertificateFormFile(form.s3_UUID, form.fileName));
        if (e.pageY >= window.innerHeight) window.scrollTo(0, 0);
    };
    return (
        <React.Fragment>
            <SRow className="margin-top-md">
                <SCol span="6">
                    <span className="laws-font">Forms</span>
                </SCol>
                <SCol span="6">
                    <span className="right">
                        {certificateForms?.data?.length ? certificateForms.data.length : "0"}{" "}
                        Certificate Forms
                    </span>
                </SCol>
            </SRow>
            <hr />
            <SRow>
                <SCol>
                    {certificateForms.data && certificateForms.data.length > 0 ? (
                        certificateForms.data.map(form => (
                            <div
                                key={uuidv4()}
                                className="margin-top-xs font-bold"
                                id={form.id}
                                role="button"
                                tabIndex={0}
                                onClick={e => setActiveFormId(form, e)}>
                                <SBox
                                    id={form.id}
                                    className={
                                        form.id == activeFormId ? "laws-blue-box" : "laws-box"
                                    } /* eslint eqeqeq: 0 */
                                >
                                    {form.name}
                                </SBox>
                            </div>
                        ))
                    ) : (
                        <span className="margin-top-lg font-semibold line-height-sm">
                            No results found.
                        </span>
                    )}
                </SCol>
            </SRow>
        </React.Fragment>
    );
});

export default CertificateFormsList;
