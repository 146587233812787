import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SDialog, SUploader, SLoader } from "@avalara/skylab-react";
import { postBulkUploadCertificates, selectIsUploading } from "../../app/bulkUploadSlice";
import toast from "../../hooks/toast";

function BulkUploadCertificateDialog(props) {
    const dispatch = useDispatch();
    const dialogClose = () => {
        props.closeUploadDialog(false);
    };
    const [showToast] = toast();
    const isUploadingZip = useSelector(selectIsUploading);
    const handleAttachEvent = async event => {
        const uploadData = new FormData();
        const fileType = event.detail?.files[0]?.name.split(".")[1].toLowerCase() === "zip";
        if (event.detail?.files[0]?.size <= 209715200 && fileType) {
            uploadData.append("file", event.detail?.files[0], event.detail?.files[0]?.name);
            await dispatch(postBulkUploadCertificates(uploadData, props.uploadedFromPending));
            dialogClose();
            if (!props.uploadedFromPending) props.refreshGrid();
        } else if (!fileType) {
            showToast("error", "Please select .zip file only.");
        } else {
            showToast("error", "Selected File Exceeds the Max Limit of 200MB.");
        }
    };
    return (
        <SDialog
            open={props.isBulkUploadZipDialogOpen}
            id="bulkUploadZip-dialog"
            onS-dismiss={dialogClose}
            aria-modal="true">
            <div slot="header" id="bulkUploadZip">
                Upload a zip file
            </div>
            <div slot="body">
                {!isUploadingZip ? (
                    <SUploader
                        id="zipUploader"
                        multiple={false}
                        ctaText="Upload a file"
                        accept="application/zip"
                        onS-attach={e => {
                            handleAttachEvent(e);
                        }}>
                        <div slot="guidelines">
                            <div>Supported file formats include .zip only</div>
                            <div>Maximum file size: 200MB</div>
                        </div>
                    </SUploader>
                ) : (
                    <div className="flex dl-flex-fill-height dl-flex-center">
                        <h3>Uploading ...</h3>
                        <SLoader id="dialog-loader" className="medium" aria-live="polite" loading />
                    </div>
                )}
            </div>
            <div slot="footer">
                <button className="tertiary inline" onClick={dialogClose}>
                    Close
                </button>
            </div>
        </SDialog>
    );
}

export default BulkUploadCertificateDialog;
