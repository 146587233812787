const retainCriticalData = () => {
    let customerColumnConfigData = null;
    let vendorColumnConfigData = null;

    if (localStorage.getItem("customerColumnConfig")) {
        customerColumnConfigData = JSON.parse(localStorage.getItem("customerColumnConfig"));
    }

    if (localStorage.getItem("vendorColumnConfig")) {
        vendorColumnConfigData = JSON.parse(localStorage.getItem("vendorColumnConfig"));
    }
    localStorage.clear();

    if (customerColumnConfigData)
        localStorage.setItem("customerColumnConfig", JSON.stringify(customerColumnConfigData));
    if (vendorColumnConfigData)
        localStorage.setItem("vendorColumnConfig", JSON.stringify(vendorColumnConfigData));
};

export default retainCriticalData;
