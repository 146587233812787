import deepmerge from "deepmerge";
import isOneWebDomainRuntime from "./shared/avaAppRuntime";

const ecmConfigForAvaApp = {
    local: {
        cupHost: "qa.admin.web.avalara.io",
        ecmApiHost: "api.qa.web.exemptions.avalara.io",
        docLockerClient: "qa.documentlocker.avalara.io",
        certCapture: "https://staging.certcapture.com",
        genCert: "https://api.qa.exemptions.avalara.io",
        certExpress: "https://staging.certexpress.com",
        fvsConfigurationId: "ab64032e-ebaf-4cb4-aa51-ad15464953c0",
        apiVersion: "v2.5",
        apiVersion3: "v3",
    },
    dev: {
        cupHost: "qa.admin.web.avalara.io",
        ecmApiHost: "api-web-exemptions.gamma.dev.us-west-2.aws.avalara.io",
        docLockerClient: "dev.documentlocker.avalara.io",
        certCapture: "https://staging.certcapture.com",
        genCert: "https://api.dev.exemptions.avalara.io",
        certExpress: "https://test-cc7-ce.dev.certcapture.com",
        fvsConfigurationId: "16e6001b-95d2-42de-8b3a-185a15b444af",
        apiVersion: "v2.5",
        apiVersion3: "v3",
    },
    qa: {
        cupHost: "qa.admin.web.avalara.io",
        ecmApiHost: "api.qa.web.exemptions.avalara.io",
        docLockerClient: "qa.documentlocker.avalara.io",
        certCapture: "https://staging.certcapture.com",
        genCert: "https://api.qa.exemptions.avalara.io",
        certExpress: "https://staging.certexpress.com",
        fvsConfigurationId: "ab64032e-ebaf-4cb4-aa51-ad15464953c0",
        apiVersion: "v2.5",
        apiVersion3: "v3",
    },
    sbx: {
        cupHost: "sandbox.admin.avalara.com",
        ecmApiHost: "api.sbx.web.exemptions.avalara.com",
        docLockerClient: "sbx.documentlocker.avalara.com",
        certCapture: "https://sbx.certcapture.com",
        genCert: "https://api.sbx.exemptions.avalara.com",
        certExpress: "https://sbx.certexpress.com",
        fvsConfigurationId: "fc747193-a22a-405e-bc06-0c582fde6a98",
        apiVersion: "v2.5",
        apiVersion3: "v3",
    },
    prd: {
        cupHost: "admin.avalara.com",
        ecmApiHost: "api.web.exemptions.avalara.com",
        docLockerClient: "documentlocker.avalara.com",
        certCapture: "https://app.certcapture.com",
        genCert: "https://api.exemptions.avalara.com",
        certExpress: "https://app.certexpress.com",
        fvsConfigurationId: "13dc2391-5825-46a3-a31e-afb7d50f11d2",
        apiVersion: "v2.5",
        apiVersion3: "v3",
    },
};

export default function getConfig() {
    // when running in AvaApp do not use configs from environment variables (.env files)
    if (isOneWebDomainRuntime()) {
        const avaAppConfig = window.AvaAppConfig;
        const appEnv = avaAppConfig?.env;
        const ecmConfig = {
            appEnv,
            hostnames: {
                identity: avaAppConfig?.hostnames?.identity,
                avatax: avaAppConfig?.hostnames?.avatax,
                cupHost: ecmConfigForAvaApp[appEnv]?.cupHost,
                ecmApiHost: ecmConfigForAvaApp[appEnv]?.ecmApiHost,
                docLockerClient: ecmConfigForAvaApp[appEnv]?.docLockerClient,
                certCapture: ecmConfigForAvaApp[appEnv]?.certCapture,
                genCert: ecmConfigForAvaApp[appEnv]?.genCert,
                certExpress: ecmConfigForAvaApp[appEnv]?.certExpress,
            },
            fvsConfigurationId: ecmConfigForAvaApp[appEnv]?.fvsConfigurationId,
            apiVersion: ecmConfigForAvaApp[appEnv]?.apiVersion,
            apiVersion3: ecmConfigForAvaApp[appEnv]?.apiVersion3,
        };

        return deepmerge(ecmConfig, avaAppConfig);
    }

    return {
        appEnv: process.env.REACT_APP_ENV,
        hostnames: {
            identity: process.env.REACT_APP_IDENTITY_HOST,
            avatax: process.env.REACT_APP_AVATAX_API_HOST,
            cupHost: process.env.REACT_APP_CUP_HOST,
            ecmApiHost: process.env.REACT_APP_API_HOST_BASE,
            docLockerClient: process.env.REACT_APP_DOCLOCKER_HOST,
            certCapture: process.env.REACT_APP_CERTCAPTURE,
            genCert: process.env.REACT_APP_GENCERT_URL,
            certExpress: process.env.REACT_APP_CERTEXPRESS_URL,
        },
        fvsConfigurationId: process.env.REACT_APP_FVS_CONFIGURATIONID,
        apiVersion: process.env.REACT_APP_API_VERSION,
        apiVersion3: process.env.REACT_APP_API_VERSION3,
    };
}
