import React, { useEffect, useState } from "react";
import { SDialog, SRow, SCol, SIcon } from "@avalara/skylab-react";
import { useDispatch, useSelector } from "react-redux";
import { documentTypeNames } from "../../../shared/constants";
import {
    AddOrUpdateInvalidReason,
    selectIsAddOrEditLoading,
} from "../../../app/invalidReasonSlice";
import { isEligibleUser } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";
import { errorTag, noErrorTag } from "../../../shared/Utils";

function InvalidReasonDialog(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsAddOrEditLoading);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [invalidReasonId, setInvalidReasonId] = useState("");

    const isEligibleUserSettingsAddOrEditInvalidReason =
        dispatch(isEligibleUser(featureFlag.settings.addInvalidReason)) ||
        dispatch(isEligibleUser(featureFlag.settings.editInvalidReason));

    useEffect(() => {
        setName(props.addOrEdit === "edit" ? props.invalidReason.name : "");
        setDescription(props.addOrEdit === "edit" ? props.invalidReason.description : "");
        setInvalidReasonId(props.invalidReason.id);
    }, [
        props.addOrEdit,
        props.invalidReason.name,
        props.invalidReason.description,
        props.invalidReason.id,
    ]);

    const handleNameChange = value => {
        setName(value);
        if (value === "") setNameError("error");
        else setNameError("");
    };

    const handleDescriptionChange = value => {
        setDescription(value);
        if (value === "") setDescriptionError("error");
        else setDescriptionError("");
    };

    const handleCloseDialog = () => {
        setName("");
        setNameError("");
        setDescription("");
        setDescriptionError("");
        setInvalidReasonId(0);
        props.closeDialog(false);
    };

    const handleSave = async () => {
        if (!name || !description) {
            if (name === "") setNameError("error");
            if (description === "") setDescriptionError("error");
        } else {
            const payload = {
                Name: name.trim(),
                Description: description.trim(),
                SystemCode: false,
                DocumentTypeName: documentTypeNames.salesAndUseTax,
                Outgoing: false,
            };
            let res;
            if (props.addOrEdit === "add") {
                res = await dispatch(AddOrUpdateInvalidReason("add", payload));
            } else {
                res = await dispatch(AddOrUpdateInvalidReason("edit", payload, invalidReasonId));
            }
            if (res) handleCloseDialog();
        }
    };

    return (
        <SDialog
            open={props.openDialog}
            id="add-edit-invalid-reason-dialog"
            onS-dismiss={handleCloseDialog}
            className="overflow-visible"
            aria-modal="true">
            <div slot="header" id="addEditInvalidReason" className="inline">
                {props.addOrEdit === "add" ? (
                    <span>Add invalid reason</span>
                ) : (
                    <span>Update invalid reason</span>
                )}
            </div>
            <div slot="body">
                <SRow>
                    <SCol>
                        <label htmlFor="invalid-reason-name" className="required">
                            Name
                        </label>
                        <input
                            id="invalid-reason-name"
                            name="invalid-reason-name"
                            type="text"
                            onChange={e => handleNameChange(e.target.value)}
                            value={name}
                            className={nameError ? errorTag : noErrorTag}
                        />
                        <div className="input-msg">
                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                            <span className="top-xs">Enter name</span>
                        </div>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        <label htmlFor="invalid-reason-desc" className="required">
                            Description
                        </label>
                        <input
                            id="invalid-reason-desc"
                            name="invalid-reason-desc"
                            type="text"
                            onChange={e => handleDescriptionChange(e.target.value)}
                            value={description}
                            className={descriptionError ? errorTag : noErrorTag}
                        />
                        <div className="input-msg">
                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                            <span className="top-xs">Enter description</span>
                        </div>
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button className="tertiary" onClick={handleCloseDialog}>
                    Cancel
                </button>
                <button
                    id="save-InvalidReason-button"
                    className={isLoading ? "primary right loading" : "primary right"}
                    onClick={handleSave}
                    disabled={!isEligibleUserSettingsAddOrEditInvalidReason}>
                    Save
                </button>
            </div>
        </SDialog>
    );
}

export default InvalidReasonDialog;
