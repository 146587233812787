import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { SAlert, SCol, SRow, SLoader } from "@avalara/skylab-react";
import classnames from "classnames";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import {
    fetchCertificateRequestsAPI,
    setCertificateRequest,
    selectCertificateRequest,
    selectIsLoading,
    putCertificateRequestSettings,
} from "../../../app/settingsSlice";
import featureFlag from "../../../featureToggler/featureFlag";
import "../settingsStyle.css";

const CertExpressSettings = React.memo(() => {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const [requestSettingsChanged, setRequestSettingsChanged] = useState(false);
    const submitButtonClassNames = classnames({
        primary: true,
        loading: submitting,
    });
    const isLoading = useSelector(selectIsLoading);
    const certificateRequestData = useSelector(selectCertificateRequest, shallowEqual);
    const [fillOnly, setFillOnly] = useState(true);
    const [uploadFormUnavailable, setUploadFormUnavailable] = useState(false);
    const [uploadOnly, setUploadOnly] = useState(false);
    const [editPurchaser, setEditPurchaser] = useState(false);
    const [uploadToDataEntry, setUploadToDataEntry] = useState(false);
    const [disableFillAny, setDisableFillAny] = useState(false);
    const [showCertIds, setShowCertIds] = useState(false);
    const [certificateCompletionNotification, setCertificateCompletionNotification] =
        useState(false);
    const [certificateValidFromCertExpress, setCertificateValidFromCertExpress] = useState(true);
    const [certIdFooter, setCertIdFooter] = useState(false);
    const [appendBarcode, setAppendBarcode] = useState(false);
    const [customerList, setCustomerList] = useState(false);
    const [purgeData, setPurgeData] = useState(false);
    const navigate = useNavigate();
    const isEligibleUserSettingsGeneral = dispatch(isEligibleUser(featureFlag.settings.general));

    const handleCheckboxChange = setter => {
        return event => {
            if (event.target.name === "certificate_valid_from_certexpress") {
                setCertificateValidFromCertExpress(!event.target.checked);
                setRequestSettingsChanged(true);
            }
            setRequestSettingsChanged(true);
            setter(event.target.checked);
        };
    };

    useEffect(() => {
        dispatch(fetchCertificateRequestsAPI());

        return () => {
            dispatch(setCertificateRequest([]));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (certificateRequestData?.webPortalOptions) {
            if (certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 17)) {
                setFillOnly(
                    !certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 17)
                        .value
                );
            }
            setUploadFormUnavailable(
                certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 15)
                    ?.value === true
            );

            setUploadOnly(
                certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 8)
                    ?.value === true
            );

            setEditPurchaser(
                certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 9)
                    ?.value === true
            );

            setUploadToDataEntry(
                certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 18)
                    ?.value === true
            );

            setDisableFillAny(
                certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 11)
                    ?.value === true
            );

            setShowCertIds(
                certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 13)
                    ?.value === true
            );

            setCertificateCompletionNotification(
                certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 10)
                    ?.value === true
            );

            if (certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 1)) {
                setCertificateValidFromCertExpress(
                    !certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 1)
                        .value
                );
            }

            setCertIdFooter(
                certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 14)
                    ?.value === true
            );

            setAppendBarcode(
                certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 12)
                    ?.value === true
            );

            setCustomerList(
                certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 4)
                    ?.value === true
            );

            setPurgeData(
                certificateRequestData.webPortalOptions.find(x => x.webportalOptionId === 16)
                    ?.value === true
            );
        }
    }, [certificateRequestData]);

    const cancelHandler = () => {
        navigate(0);
    };

    const handleSubmitRequest = async event => {
        setSubmitting(true);
        event.preventDefault();

        const currentWebPortalOptions = [
            { webportalOptionId: 15, value: uploadFormUnavailable },
            { webportalOptionId: 8, value: uploadOnly },
            { webportalOptionId: 9, value: editPurchaser },
            { webportalOptionId: 18, value: uploadToDataEntry },
            { webportalOptionId: 11, value: disableFillAny },
            { webportalOptionId: 13, value: showCertIds },
            { webportalOptionId: 10, value: certificateCompletionNotification },
            { webportalOptionId: 14, value: certIdFooter },
            { webportalOptionId: 12, value: appendBarcode },
            { webportalOptionId: 4, value: customerList },
            { webportalOptionId: 16, value: purgeData },
        ];

        const optionsFromGetAPI = currentWebPortalOptions.filter(x =>
            certificateRequestData.webPortalOptions.some(
                c => x.webportalOptionId === c.webportalOptionId
            )
        );

        const optionsUpdatedByUser = currentWebPortalOptions.filter(
            x =>
                !certificateRequestData.webPortalOptions.some(
                    c => x.webportalOptionId === c.webportalOptionId
                ) && x.value === true
        );

        optionsFromGetAPI.push(
            {
                webportalOptionId: 1,
                value: !certificateValidFromCertExpress,
            },
            {
                webportalOptionId: 17,
                value: !fillOnly,
            }
        );
        if (requestSettingsChanged) {
            const putData = {
                webPortalOptions: optionsFromGetAPI.concat(optionsUpdatedByUser),
            };

            await dispatch(putCertificateRequestSettings(putData));
        }
        setSubmitting(false);
    };

    let certificateRequestDOM = null;

    if (isLoading || certificateRequestData.length === 0) {
        certificateRequestDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" aria-live="polite" />
            </div>
        );
    } else {
        certificateRequestDOM = (
            <div>
                <Helmet>
                    <title>{dispatch(getPageTitle("Settings : CertExpress settings"))}</title>
                </Helmet>
                <SRow className="margin-top-sm">
                    <SCol>
                        <SAlert status="info" role="status" nodismiss="">
                            <div className="alert-maxWidth">
                                Your customers use CertExpress to complete certificates and return
                                them to you. Modify these settings to control what customers can and
                                can&apos;t do when completing a request.
                            </div>
                        </SAlert>
                    </SCol>
                </SRow>

                <SRow>
                    <SCol>
                        <h3>Certificates created via CertExpress</h3>
                        <div>
                            <input
                                id="10"
                                name="certificate_completion_notification"
                                type="checkbox"
                                aria-label="Send a confirmation email to customers after they successfully
                                submit a certificate"
                                checked={certificateCompletionNotification}
                                onChange={handleCheckboxChange(
                                    setCertificateCompletionNotification
                                )}
                            />
                            <label htmlFor="10">
                                Send a confirmation email to customers after they successfully
                                submit a certificate
                            </label>
                        </div>
                        <div>
                            <input
                                id="14"
                                name="certid_footer"
                                type="checkbox"
                                aria-label="Add the certificate ID number to the bottom of new certificates"
                                checked={certIdFooter}
                                onChange={handleCheckboxChange(setCertIdFooter)}
                            />
                            <label htmlFor="14">
                                Add the certificate ID number to the bottom of new certificates
                            </label>
                        </div>
                        <div>
                            <input
                                id="12"
                                name="append_barcode"
                                type="checkbox"
                                aria-label="Add a barcode to the bottom of new certificates"
                                checked={appendBarcode}
                                onChange={handleCheckboxChange(setAppendBarcode)}
                            />
                            <label htmlFor="12">
                                Add a barcode to the bottom of new certificates
                            </label>
                        </div>
                        <div>
                            <input
                                id="4"
                                name="customer_list"
                                type="checkbox"
                                aria-label="Add a page containing the customer's address information to new certificates created"
                                checked={customerList}
                                onChange={handleCheckboxChange(setCustomerList)}
                            />
                            <label htmlFor="4">
                                {`Add a page containing the customer's address information to new certificates created`}
                            </label>
                        </div>

                        <div>
                            <input
                                id="1"
                                name="certificate_valid_from_certexpress"
                                type="checkbox"
                                aria-label="Automatically save all certificates submitted through Avalara
                                CertExpress as Valid"
                                checked={certificateValidFromCertExpress}
                                onChange={handleCheckboxChange(setCertificateValidFromCertExpress)}
                            />
                            <label htmlFor="1">
                                Automatically save all certificates submitted through Avalara
                                CertExpress as Valid
                            </label>
                        </div>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        <h3 className="margin-bottom-none">
                            Change what customers can do in CertExpress
                        </h3>
                        <input
                            id="17"
                            name="fill_only"
                            type="checkbox"
                            aria-label="Customers can upload certificate images"
                            checked={fillOnly}
                            onChange={handleCheckboxChange(setFillOnly)}
                        />
                        <label htmlFor="17">Customers can upload certificate images</label>
                    </SCol>
                    <SCol className="margin-left-md">
                        <div>
                            <input
                                id="15"
                                name="upload_form_unavailable"
                                type="checkbox"
                                aria-label=" Limit uploads to documents that are not fillable, such as an
                                affidavit"
                                checked={uploadFormUnavailable}
                                onChange={handleCheckboxChange(setUploadFormUnavailable)}
                            />
                            <label htmlFor="15">
                                Limit uploads to documents that are not fillable, such as an
                                affidavit
                                <br />
                                <span className="text-xs">
                                    Customers can fill out any fillable document and upload images
                                    of any non-fillable document
                                </span>
                            </label>
                        </div>
                        <div>
                            <input
                                id="8"
                                name="upload_only"
                                type="checkbox"
                                aria-label="Only accept uploads: Don't show fillable certificate fields"
                                checked={uploadOnly}
                                onChange={handleCheckboxChange(setUploadOnly)}
                            />
                            <label htmlFor="8">
                                {`  Only accept uploads: Don't show fillable certificate fields `}
                            </label>
                        </div>
                    </SCol>
                    <SCol>
                        <div>
                            <input
                                id="9"
                                name="edit_purchaser"
                                type="checkbox"
                                aria-label="Customers can edit their prefilled company name and address when
                                completing a certificate request"
                                checked={editPurchaser}
                                onChange={handleCheckboxChange(setEditPurchaser)}
                            />
                            <label htmlFor="9">
                                Customers can edit their prefilled company name and address when
                                completing a certificate request
                            </label>
                        </div>
                        <div>
                            <input
                                id="11"
                                name="disable_fill_any"
                                type="checkbox"
                                aria-label="Customers can submit only the specific certificates requested in
                                CertExpress"
                                checked={disableFillAny}
                                onChange={handleCheckboxChange(setDisableFillAny)}
                            />
                            <label htmlFor="11">
                                Customers can submit only the specific certificates requested in
                                CertExpress
                            </label>
                        </div>

                        <div>
                            <input
                                id="13"
                                name="show_cert_ids"
                                type="checkbox"
                                aria-label="Customers can see the certificate ID number after they submit a
                                certificate in CertExpress"
                                checked={showCertIds}
                                onChange={handleCheckboxChange(setShowCertIds)}
                            />
                            <label htmlFor="13">
                                Customers can see the certificate ID number after they submit a
                                certificate in CertExpress
                            </label>
                        </div>

                        <div>
                            <input
                                id="18"
                                name="upload_to_data_entry"
                                type="checkbox"
                                aria-label="Customers can import multiple certificates when adding certificates
                                on your CertExpress Public site"
                                checked={uploadToDataEntry}
                                onChange={handleCheckboxChange(setUploadToDataEntry)}
                            />
                            <label htmlFor="18">
                                Customers can import multiple certificates when adding certificates
                                on your CertExpress Public site
                            </label>
                        </div>

                        <div>
                            <input
                                id="16"
                                name="purge_data"
                                type="checkbox"
                                aria-label="Discard customer data after they submit a certificate: Customers
                                have to start over if they abandon a request"
                                checked={purgeData}
                                onChange={handleCheckboxChange(setPurgeData)}
                            />
                            <label htmlFor="16">
                                Discard customer data after they submit a certificate: Customers
                                have to start over if they abandon a request
                                <br />
                                <span className="text-xs">
                                    Unless you enable this setting, Avalara temporarily stores
                                    customer data so they can make changes later without starting
                                    over.
                                </span>
                            </label>
                        </div>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="12">
                        <hr className="margin-top-xl margin-bottom-lg" />
                        <button
                            id="save-client"
                            type="button"
                            className={submitButtonClassNames}
                            onClick={handleSubmitRequest}
                            disabled={!isEligibleUserSettingsGeneral || !requestSettingsChanged}
                            name="stop-apply-btn">
                            Save
                        </button>

                        <button className="tertiary" type="button" onClick={cancelHandler}>
                            Cancel
                        </button>
                    </SCol>
                </SRow>
            </div>
        );
    }
    return <React.Fragment> {certificateRequestDOM} </React.Fragment>;
});

export default CertExpressSettings;
