import React, { useState, useEffect } from "react";
import { STabs } from "@avalara/skylab-react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import VendorCertificate from "./VendorCertificate";
import VendorPackages from "../VendorPackages/VendorPackages";
import { isEligibleUser, vendorPremium, essentialsOrAbove } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";

const VendorCertificateHeader = React.memo(() => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [showPackages, setShowPackages] = useState(false);
    const [showCertificates, setShowCertificates] = useState(false);
    const canCreatePackage = dispatch(
        isEligibleUser(featureFlag.vendorCertificates.manageCertificatePackages, true)
    );
    const isEssentialsOrAbove = dispatch(essentialsOrAbove());
    const isVendorPremium = dispatch(vendorPremium());

    const tabItems = canCreatePackage
        ? [
              {
                  id: "tabpanel-vendor-certificates",
                  label: "Certificates for vendors",
                  tabPanelId: "tabpanel-vendor-certificates",
                  selected: showCertificates,
              },
              {
                  id: "tabpanel-vendor-packages",
                  label: `Certificate packages`,
                  tabPanelId: "tabpanel-vendor-packages",
                  selected: showPackages,
              },
          ]
        : [
              {
                  id: "tabpanel-vendor-certificates",
                  label: "Certificates for vendors",
                  tabPanelId: "tabpanel-vendor-certificates",
                  selected: showCertificates,
              },
          ];

    useEffect(() => {
        if (location.pathname.includes("vendor-packages")) {
            setShowPackages(true);
            setShowCertificates(false);
        } else {
            setShowPackages(false);
            setShowCertificates(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const navigateTo = to => {
        if (to.includes("tabpanel-vendor-certificates") && !showCertificates) {
            setShowCertificates(true);
            setShowPackages(false);
        } else if (to.includes("tabpanel-vendor-packages") && !showPackages) {
            setShowPackages(true);
            setShowCertificates(false);
        }
    };

    const headerDOM = (
        <React.Fragment>
            <h1> Vendor Certificates</h1>
            {isEssentialsOrAbove && isVendorPremium ? (
                <STabs
                    tabItems={tabItems}
                    onS-select={e => navigateTo(e.detail.id)}
                    className="margin-top-sm"
                />
            ) : (
                ""
            )}
            {showCertificates && <VendorCertificate />}
            {showPackages && <VendorPackages />}
        </React.Fragment>
    );

    return headerDOM;
});

export default VendorCertificateHeader;
