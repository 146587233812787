import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { v4 as uuidv4 } from "uuid";
import { checkSelectComponentProps, noErrorTag } from "../../shared/Utils";

import {
    getInvalidReasons,
    selectInvalidReasons,
    selectIsLoadingInvalidReasons,
} from "../../app/certificateSlice";

const selectModifiedInvalidReasons = createSelector(
    selectInvalidReasons,
    (_, props) => props,
    (reasons, props) => {
        let value = null;
        try {
            value = JSON.parse(props?.value);
        } catch {
            value = [];
        }
        return reasons
            .filter(element => {
                if (element.name === "PAUSED") {
                    return false;
                }
                return true;
            })
            .map(element => {
                return {
                    label: element.name,
                    value: element.id,
                    selected: value?.includes(element.id),
                    key: uuidv4(),
                };
            });
    }
);

const SelectInvalidReasons = React.memo(props => {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingInvalidReasons);
    const invalidReasonOptions = useSelector(
        state => selectModifiedInvalidReasons(state, props),
        shallowEqual
    );

    checkSelectComponentProps("SelectInvalidReasons", props);

    useEffect(() => {
        if (!props.hasInvalidReasons) {
            dispatch(getInvalidReasons());
        }
    }, [props.hasInvalidReasons, dispatch]);

    return (
        <React.Fragment>
            {props.name ? (
                <label htmlFor={getNextId()} id={`lbl-${getCurrentId()}`}>
                    {props.name}
                </label>
            ) : (
                <label htmlFor={getNextId()} id={`lbl-${getCurrentId()}`}>
                    Why isn&apos;t this certificate valid?
                </label>
            )}
            <SSelect
                name="invalidReasons"
                inputId={getCurrentId()}
                optionsList={invalidReasonOptions}
                multiple={props.multiple}
                loading={isLoading}
                className={props.error || noErrorTag}
                onS-select={e => props.onAdd(e.detail.item)}
                onS-deselect={e => props.onRemove(e.detail.item)}
                disabled={props?.disabled ? "disabled" : null}
            />
        </React.Fragment>
    );
});

export default SelectInvalidReasons;
