import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SDialog, SRow, SCol } from "@avalara/skylab-react";
import classnames from "classnames";
import {
    putCertificateEscalationAsync,
    selectIsLoadingEscalationLog,
} from "../../app/certificateSlice";

function EscalationDialog(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingEscalationLog);
    const { certificateID } = props;
    const escalateButton = classnames({ primary: true, small: true, loading: isLoading });
    const [escalationNote, setEscalationNote] = useState("");
    const handleChangeInput = setter => {
        return event => {
            setter(event.target.value);
        };
    };
    const onSave = async () => {
        await dispatch(putCertificateEscalationAsync(certificateID, escalationNote));
        props.setIsEscalationDialogOpen(false);
        props.setShowEscalatedButton(false);
    };

    return (
        <SDialog
            id="esacalation-dialog"
            open={props.isEscalationDialogOpen}
            onS-dismiss={() => {
                props.setIsEscalationDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="esacalation">
                Escalate this incoming certificate
            </div>
            <div slot="body">
                <SRow>
                    <SCol>
                        Adding a comment about why you&apos;re escalating this certificate can be
                        helpful.
                    </SCol>
                    <SCol>
                        <strong>Comment</strong>
                        <textarea rows="4" onChange={handleChangeInput(setEscalationNote)} />
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => props.setIsEscalationDialogOpen(false)}>
                    Cancel
                </button>
                <button
                    disabled={escalationNote.length === 0}
                    className={escalateButton}
                    onClick={onSave}
                    type="button">
                    Escalate
                </button>
            </div>
        </SDialog>
    );
}

export default EscalationDialog;
