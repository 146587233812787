import { useEffect, useRef } from "react";
import { isEqual } from "lodash";

function useDeepCompareEffect(callback, dependencies) {
    const currentDependencies = useRef(dependencies);

    if (!isEqual(currentDependencies.current, dependencies)) {
        currentDependencies.current = dependencies;
    }

    useEffect(callback, [currentDependencies.current]);
}

export default useDeepCompareEffect;
