import React from "react";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "./Tooltip";
import "./tooltip.css";

const getTooltipContent = labels => {
    const labelsListElement = [];
    labels.forEach(item => labelsListElement.push(<li key={uuidv4()}>{item}</li>));
    return labelsListElement;
};

function LabelElementInGrid({ labels }) {
    return labels.length > 0 ? (
        <div className="tooltip">
            <span>{`${labels[0]}...`}</span>
            <Tooltip tooltipData={getTooltipContent(labels)} columnPositionRight={false} />
        </div>
    ) : (
        <span />
    );
}

export default LabelElementInGrid;
