import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SColumnManager } from "@avalara/skylab-react";
import {
    selectCustomerColumns,
    setDefaultCustomerColumns,
    upsertCustomerColumnConfig,
} from "../../../app/customerSlice";

const CustomerColumnsManager = React.memo(() => {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const defaultColumns = useSelector(selectCustomerColumns);

    const applyChanges = async columnItems => {
        setSubmitting(true);
        const updatedColumns = columnItems.map(columnItem => {
            const match = defaultColumns.find(item => item.id === columnItem.id);
            return match ? { ...columnItem, fieldName: match.fieldName } : columnItem;
        });
        dispatch(setDefaultCustomerColumns(updatedColumns));
        dispatch(upsertCustomerColumnConfig(updatedColumns));
        setSubmitting(false);
    };

    return (
        <SColumnManager
            id="customer-column-manager-panel"
            columnItems={defaultColumns}
            loading={submitting}
            onS-save={e => applyChanges(e.detail.columnItems)}
        />
    );
});

export default CustomerColumnsManager;
