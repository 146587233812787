import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { SRow, SCol, SIcon } from "@avalara/skylab-react";
import { getPageTitle, isEligibleUser } from "../../../../shared/sessionUtility";
import RetailLocationsGrid from "./RetailLocationsGrid";
import RetailLocationsFilter from "./RetailLocationsFilter";
import FeatureToggler from "../../../../featureToggler/FeatureToggler";
import featureFlag from "../../../../featureToggler/featureFlag";

const RetailLocations = React.memo(() => {
    const dispatch = useDispatch();
    const [openAddUpdateDialog, setOpenAddUpdateDialog] = useState(false);
    const [addOrEdit, setAddOrEdit] = useState("");
    const handleAddRetailLocation = () => {
        setOpenAddUpdateDialog(true);
        setAddOrEdit("Add");
    };
    const isUserEligibleAddRetailLocations = !dispatch(
        isEligibleUser(featureFlag.settings.addRetailLocations)
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Retail site locations"))}</title>
            </Helmet>
            <div>
                <SRow className="align-items-end pad-bottom-sm pad-top-sm">
                    <SCol span="8">
                        <SRow>
                            <SCol span="md-6 xl-4 4">
                                <RetailLocationsFilter />
                            </SCol>
                        </SRow>
                    </SCol>

                    <FeatureToggler category="settings" id="addRetailLocations">
                        <SCol span="4">
                            <span className="flex justify-content-end">
                                <button
                                    className="primary inline"
                                    onClick={() => handleAddRetailLocation()}
                                    disabled={isUserEligibleAddRetailLocations}>
                                    <SIcon
                                        aria-hidden="true"
                                        name="plus"
                                        className="margin-right-xs"
                                    />
                                    Add a retail site location
                                </button>
                            </span>
                        </SCol>
                    </FeatureToggler>
                </SRow>
            </div>

            <FeatureToggler category="settings" id="viewRetailLocations">
                <RetailLocationsGrid
                    openAddUpdateDialog={openAddUpdateDialog}
                    setOpenAddUpdateDialog={setOpenAddUpdateDialog}
                    addOrEdit={addOrEdit}
                    setAddOrEdit={setAddOrEdit}
                />
            </FeatureToggler>
        </React.Fragment>
    );
});

export default RetailLocations;
