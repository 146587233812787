import React, { useState, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import classnames from "classnames";
import { SDialog, SLoader, SCol, SRow, SIcon, SToggle } from "@avalara/skylab-react";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import {
    putGeneralSettings,
    fetchCompanyLogo,
    selectIsCompanyLogoPresent,
    selectCompanyLogo,
    selectIsFetchingLogo,
    selectIsSavingLogo,
    selectIsDeletingLogo,
    deleteCompanyLogo,
    selectIsSavingSettings,
    selectSetting,
    selectIsSignChange,
    selectAddNewSignature,
} from "../../../app/settingsSlice";
import "../settingsStyle.css";
import { errorTag, noErrorTag, validateEmail } from "../../../shared/Utils";
import FileUploaderDialog from "../../sharedDialogs/FileUploaderDialog";
import { setIsUploadDialogOpen, selectIsUploadDialogOpen } from "../../../app/certificateSlice";
import featureFlag from "../../../featureToggler/featureFlag";
import SignaturePad from "../../sharedComponents/SignaturePad/SignaturePad";
import FeatureToggler from "../../../featureToggler/FeatureToggler";

const General = React.memo(props => {
    const dispatch = useDispatch();
    const settings = useSelector(selectSetting, shallowEqual);
    const isUploadDialogOpen = useSelector(selectIsUploadDialogOpen);
    const { requireTaxId, id: companyId } = settings;
    const [requireCertificateImage, setRequireCertificateImage] = useState(
        settings.requireCertificateImage !== null
    );
    const [requireTaxPayerId, setRequireTaxPayerId] = useState(requireTaxId);
    const [showCustomerRelationships, setShowCustomerRelationships] = useState(
        settings.showCustomerRelationships
    );
    const [showDuplicateCustomers, setShowDuplicateCustomers] = useState(
        settings.showDuplicateCustomers
    );
    const [enableRetail, setEnableRetail] = useState(settings.enableRetail);
    const [autoCustomerTaxRegion, setAutoCustomerTaxRegion] = useState(
        settings.autoCustomerTaxRegion
    );
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [limitTimeFrame, setLimitTimeFrame] = useState(false);
    const [timeFrame, setTimeFrame] = useState(0);
    const isCompanyLogoPresent = useSelector(selectIsCompanyLogoPresent);
    const isFetchingLogo = useSelector(selectIsFetchingLogo);
    const isSavingLogo = useSelector(selectIsSavingLogo);
    const isDeletingLogo = useSelector(selectIsDeletingLogo);
    const companyLogo = useSelector(selectCompanyLogo, shallowEqual);
    const [emailError, setEmailError] = useState("");
    const submitButtonClassNames = classnames({
        primary: true,
        loading: useSelector(selectIsSavingSettings),
    });
    const [corporateEmail, setCorporateEmail] = useState(settings.corporateEmail);
    const [equipmentDescription, setEquipmentDescription] = useState(settings.equipmentDescription);
    const isEligibleUserFlag = !dispatch(isEligibleUser(featureFlag.settings.general));
    const [autoScan, setAutoScan] = useState(settings?.useAvs !== null);
    const [markAsValid, setMarkAsValid] = useState(settings?.useAvs?.markAsValid);
    const [markAsInvalid, setMarkAsInvalid] = useState(settings?.useAvs?.markAsInvalid);
    const signatureSubmitRef = useRef(null);
    const isSignChange = useSelector(selectIsSignChange);
    const navigate = useNavigate();
    const addNewSignature = useSelector(selectAddNewSignature, shallowEqual);

    const applyCustomerExposure = "applyCustomerExposure";
    const removeNonNexusExposure = "removeNonNexusExposure";
    const ignoreAllRegions = "ignoreAllRegions";

    const [removeExtraRegions, setRemoveExtraRegions] = useState(false);
    const [radioValue, setRadioValue] = useState(ignoreAllRegions);
    const isVemOnly =
        dispatch(isEligibleUser(featureFlag.settings.vendorOptions)) &&
        !dispatch(isEligibleUser(featureFlag.settings.requestingCertExemptionMatrixUpdate));

    const handleRadioChange = e => {
        setRadioValue(e.target.value);
    };
    useEffect(() => {
        setRemoveExtraRegions(settings.applyCustomerExposure || settings.removeNonNexusExposure);

        if (settings.applyCustomerExposure) {
            setRadioValue(applyCustomerExposure);
        }
        if (settings.removeNonNexusExposure) {
            setRadioValue(removeNonNexusExposure);
        }
        if (settings.removeNonNexusExposure && settings.applyCustomerExposure) {
            setRadioValue(ignoreAllRegions);
        }
    }, [settings.applyCustomerExposure, settings.removeNonNexusExposure]);

    useEffect(() => {
        dispatch(fetchCompanyLogo());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleAutoScan = e => {
        if (!e.detail.checked) {
            setMarkAsValid(false);
            setMarkAsInvalid(false);
        }
        setAutoScan(prev => !prev);
    };

    const handleCheckBoxChange = setter => {
        return event => {
            setter(event.target.checked);
        };
    };

    const handleChangeInput = setter => {
        return event => {
            if (event.target.value && event.target.className === "error") {
                if (event.target.name === "corporateEmail") {
                    setEmailError("");
                }
            }
            setter(event.target.value);
        };
    };

    const checkValidation = () => {
        let valid = true;
        if (corporateEmail && !validateEmail(corporateEmail)) {
            setEmailError("Please enter a valid email address");
            valid = false;
        }
        return valid;
    };

    const cancelHandler = () => {
        navigate(0);
    };

    const handleSubmitRequest = async event => {
        event.preventDefault();
        if (addNewSignature || isSignChange) {
            if (signatureSubmitRef.current.signatureValidation()) {
                signatureSubmitRef.current.signatureSubmit();
            } else {
                return;
            }
        }

        if (checkValidation()) {
            setLimitTimeFrame(false);
            setTimeFrame(0);
            const putData = {
                requireTaxId: requireTaxPayerId,
                requireCertificateImage: requireCertificateImage
                    ? { LimitTimeFrame: limitTimeFrame, TimeFrame: timeFrame }
                    : null,
                showCustomerRelationships,
                showDuplicateCustomers,
                autoCustomerTaxRegion,
                corporateEmail,
                equipmentDescription,
                useAvs: autoScan ? {} : null,
                enableRetail,
            };
            if (settings.corporateEmail === settings.autoRequestReplyEmail) {
                putData.autoRequestReplyEmail = corporateEmail;
            }
            if (markAsValid || markAsInvalid) {
                putData.useAvs.markAsValid = markAsValid;
                putData.useAvs.markAsInvalid = markAsInvalid;
            }

            if (removeExtraRegions) {
                if (radioValue === ignoreAllRegions) {
                    putData.applyCustomerExposure = true;
                    putData.removeNonNexusExposure = true;
                }
                if (radioValue === applyCustomerExposure) {
                    putData.applyCustomerExposure = true;
                    putData.removeNonNexusExposure = false;
                }
                if (radioValue === removeNonNexusExposure) {
                    putData.applyCustomerExposure = false;
                    putData.removeNonNexusExposure = true;
                }
            } else {
                putData.applyCustomerExposure = false;
                putData.removeNonNexusExposure = false;
            }
            dispatch(putGeneralSettings(putData));
        }
    };

    const toggleRequireCertificateImage = event => {
        if (event.detail.checked) setRequireCertificateImage(true);
        else setRequireCertificateImage(false);
    };

    const deleteLogo = async () => {
        setIsDeleteDialogOpen(false);
        await dispatch(deleteCompanyLogo());
    };

    const cancelDelete = () => {
        setIsDeleteDialogOpen(false);
    };

    const openPreviewDialog = () => {
        setIsPreviewDialogOpen(true);
    };

    const openDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
    };

    const onPreviewDismiss = () => {
        setIsPreviewDialogOpen(false);
    };

    const onDeleteDismiss = () => {
        setIsDeleteDialogOpen(false);
    };

    const openFVSDialog = () => {
        dispatch(setIsUploadDialogOpen(true));
    };

    const companyLogoDom = isCompanyLogoPresent ? (
        <SRow>
            <SCol span="5">
                <img
                    src={`data:image/*;base64,${companyLogo.data}`}
                    alt="logo-preview"
                    className="company-logo-box"
                />
            </SCol>
            <SCol>
                <button onClick={openDeleteDialog} className="link inline font-semibold">
                    <SIcon name="trash" aria-hidden="true" />
                    <span className="margin-left-xs margin-right-xs">Remove</span>
                </button>
                <s-pipe />
                <button onClick={openPreviewDialog} className="link inline font-semibold">
                    <SIcon name="zoom-in" aria-hidden="true" />
                    <span className="margin-left-xs">Preview</span>
                </button>
            </SCol>
        </SRow>
    ) : (
        <SRow>
            <SCol>
                <button
                    onClick={openFVSDialog}
                    className="link inline font-semibold"
                    disabled={isEligibleUserFlag}>
                    <SIcon name="upload" aria-hidden="true" />
                    <span className="margin-left-xs">Upload a file</span>
                </button>
            </SCol>
            <SCol className="company-logo-label">Supported formats: JPG, PNG, GIF</SCol>
        </SRow>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Settings"))}</title>
            </Helmet>
            <SRow>
                <SCol span="3">
                    <label htmlFor="corporate-email" className="margin-top-md">
                        Corporate email address
                    </label>
                    <input
                        id="corporate-email"
                        name="corporateEmail"
                        type="email"
                        className={emailError !== "" ? errorTag : noErrorTag}
                        onChange={handleChangeInput(setCorporateEmail)}
                        value={corporateEmail || ""}
                    />
                    {emailError ? (
                        <div className="input-msg">
                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                            <span className="top-xs">{emailError}</span>
                        </div>
                    ) : null}
                </SCol>
            </SRow>
            <SRow>
                <SCol span="4">
                    <span className="margin-top-md text-md">
                        Description of your products or services
                    </span>
                </SCol>
                <SCol>
                    <label htmlFor="description">
                        Avalara adds this to certificate forms that require a purchase description
                    </label>
                </SCol>
                <SCol span="3">
                    <textarea
                        id="description"
                        className="margin-top-xs"
                        name="description"
                        onChange={handleChangeInput(setEquipmentDescription)}
                        value={equipmentDescription || ""}
                    />
                </SCol>
            </SRow>

            <SRow>
                <SCol span="3">
                    <span className="margin-top-md text-md">Your company logo</span>
                </SCol>

                <SCol>
                    <span className="text-sm no-span">
                        Your logo appears at the top of the cover letter when a certificate request
                        is sent by mail.
                    </span>
                </SCol>
            </SRow>
            {isFetchingLogo || isSavingLogo || isDeletingLogo ? (
                <div>
                    {(() => {
                        if (isFetchingLogo)
                            return <h3 className="margin-bottom-none">Fetching logo...</h3>;
                        if (isSavingLogo)
                            return <h3 className="margin-bottom-none">Saving logo...</h3>;
                        return <h3 className="margin-bottom-none">Deleting logo...</h3>;
                    })()}
                    <SLoader loading="true" className="margin-left-lg" aria-live="polite" />
                </div>
            ) : (
                companyLogoDom
            )}

            <FeatureToggler category="vendors" id="signaturePad">
                <SRow className="margin-top-md">
                    <SCol>
                        <span className="margin-top-md text-md"> Authorized Signer </span>
                    </SCol>
                    <SCol>
                        <SignaturePad companyId={settings.id} ref={signatureSubmitRef} />
                    </SCol>
                </SRow>
            </FeatureToggler>

            {!isVemOnly ? (
                <>
                    <SRow>
                        <SCol span="3">
                            <span className="margin-top-md text-md">Certificate images</span>
                        </SCol>
                        <SCol>
                            <span className="text-sm no-span">
                                Tax laws typically require companies to provide copies of
                                certificates during an audit. Companies that accept <br />{" "}
                                affidavits or store physical or digital copies of exemption
                                certificates elsewhere should use these settings to <br /> fine-tune
                                when images are required.
                            </span>
                        </SCol>
                        <SCol span="1" className="margin-top-sm shrink-column">
                            <SToggle
                                id="requireCert-toggle"
                                aria-label="Toggle Require Certificate"
                                onS-toggle={e => toggleRequireCertificateImage(e)}
                                checked={requireCertificateImage}
                            />
                        </SCol>
                        <SCol span="9">
                            <h4>
                                {" "}
                                Allow users to add certificates without uploading a certificate
                                image{" "}
                            </h4>
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span="1" className="margin-top-sm shrink-column">
                            <SToggle
                                id="autoscan-toggle"
                                aria-label="Toggle Auto Scan"
                                onS-toggle={e => toggleAutoScan(e)}
                                checked={autoScan}
                            />
                        </SCol>
                        <SCol span="9">
                            <h4>
                                Automatically scan incoming certificate images and prefill any
                                available information
                            </h4>

                            <span className="top-negative-10">
                                Avalara&apos;s Form reader determines the validity of certificates
                                when possible.
                            </span>

                            <div className="top-negative-15 hidden">
                                <h4>Automatically review certificates</h4>
                                <div className="top-negative-15">
                                    <span>These automations cause certificates to bypass the </span>
                                    <strong>incoming certificates to review</strong>
                                    <span> list.</span>
                                </div>
                                <div className="block top-negative-20">
                                    <input
                                        id="markAsValid"
                                        name="setMarkAsValid"
                                        type="checkbox"
                                        aria-label={`Automatically save as Valid if Avalara's Form Reader image scan
                                    result is "No issues found"`}
                                        checked={markAsValid || ""}
                                        onChange={handleCheckBoxChange(setMarkAsValid)}
                                    />
                                    <label htmlFor="markAsValid">
                                        Automatically save as Valid if Avalara&apos;s Form Reader
                                        image scan result is &quot;No issues found&quot;
                                    </label>
                                </div>
                                <div className="block top-negative-20">
                                    <input
                                        id="markAsInvalid"
                                        name="setMarkAsInvalid"
                                        type="checkbox"
                                        aria-label={`Automatically save as Invalid if Avalara's Form Reader image
                                scan result is "Issues found"`}
                                        checked={markAsInvalid || ""}
                                        onChange={handleCheckBoxChange(setMarkAsInvalid)}
                                    />
                                    <label htmlFor="markAsInvalid">
                                        Automatically save as Invalid if Avalara&apos;s Form Reader
                                        image scan result is &quot;Issues found&quot;
                                    </label>
                                </div>
                            </div>
                        </SCol>
                    </SRow>

                    <SRow>
                        <SCol span="3">
                            <span className="margin-top-md text-md">Linked customers</span>
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol>
                            <h4 className="no-line-height">Duplicate customers</h4>

                            <input
                                id="showDuplicateCustomers"
                                name="showDuplicateCustomers"
                                type="checkbox"
                                aria-label="Allow users to view and manage duplicate customers"
                                checked={showDuplicateCustomers}
                                onChange={handleCheckBoxChange(setShowDuplicateCustomers)}
                            />
                            <label htmlFor="showDuplicateCustomers" className="margin-top-xs">
                                Allow users to view and manage duplicate customers
                            </label>
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol>
                            <h4 className="no-line-height">Bill-to and ship-to relationships</h4>

                            <input
                                id="showCustomerRelationships"
                                name="showCustomerRelationships"
                                type="checkbox"
                                aria-label="Link together customers that are part of the same organization"
                                checked={showCustomerRelationships}
                                onChange={handleCheckBoxChange(setShowCustomerRelationships)}
                            />
                            <label htmlFor="showCustomerRelationships" className="margin-top-xs">
                                Link together bill-to and ship-to customers that are part of the
                                same organization
                            </label>
                            <SRow hidden>
                                <SCol className="margin-left-md">
                                    <label className="text-xs">
                                        What do you want to call these customer relationships?
                                    </label>
                                    <input
                                        type="radio"
                                        id="rd1"
                                        name="rd1"
                                        // onChange={handleInputChange}
                                    />
                                    <label htmlFor="rd1">
                                        {`  Refer to them as "parent" and "child" customers `}
                                    </label>
                                    <br />
                                    <input
                                        type="radio"
                                        id="rd2"
                                        name="rd2"
                                        // onChange={handleInputChange}
                                    />
                                    <label htmlFor="rd2">
                                        {`  Refer to them as "bill-to" and "ship-to" customers `}
                                    </label>
                                </SCol>
                            </SRow>
                        </SCol>
                    </SRow>
                </>
            ) : (
                ""
            )}
            <SRow className="margin-top-md">
                <SCol span="3">
                    <span className="margin-top-md text-md">Customer tax regions</span>
                </SCol>
                <SCol>
                    <input
                        id="showCustomerTaxRegions"
                        name="showCustomerTaxRegions"
                        type="checkbox"
                        aria-label="Automatically add a tax region based on the customer's address"
                        checked={autoCustomerTaxRegion}
                        onChange={handleCheckBoxChange(setAutoCustomerTaxRegion)}
                    />
                    <label htmlFor="showCustomerTaxRegions">
                        Automatically add a tax region based on the customer&apos;s address
                    </label>
                </SCol>
            </SRow>
            <SRow className="margin-top-md">
                <SCol span="4">
                    <span className="margin-top-md text-md">Taxpayer ID numbers</span>
                </SCol>
                <SCol>
                    <input
                        id="taxPayerId"
                        name="taxPayerId"
                        type="checkbox"
                        aria-label="Require Taxpayer ID numbers on certificate"
                        checked={requireTaxPayerId}
                        disabled={settings.collectSst && settings.requireSst}
                        onChange={handleCheckBoxChange(setRequireTaxPayerId)}
                    />
                    <label htmlFor="taxPayerId">Require Taxpayer ID numbers on certificates</label>
                </SCol>
            </SRow>

            {!isVemOnly ? (
                <SRow className="margin-top-md">
                    <SCol span="4">
                        <span className="margin-top-md text-md">Incoming and new certificates</span>
                    </SCol>
                    <SCol>
                        <input
                            id="remove-extra-regions"
                            name="remove-extra-regions"
                            type="checkbox"
                            checked={removeExtraRegions}
                            onChange={handleCheckBoxChange(setRemoveExtraRegions)}
                        />
                        <label htmlFor="remove-extra-regions">
                            Provide user with the option to{" "}
                            <span className="text-sm-strong">Remove extra regions</span> from a
                            certificate being created or reviewed
                        </label>

                        {removeExtraRegions ? (
                            <fieldset role="radiogroup" className="margin-left-md">
                                <legend className="text-sm margin-top-sm">This option:</legend>
                                <input
                                    name="radio-ignore-customer-regions"
                                    type="radio"
                                    id="radio-ignore-customer-regions"
                                    value={applyCustomerExposure}
                                    className="pad-top-xs"
                                    checked={radioValue === applyCustomerExposure}
                                    onChange={e => handleRadioChange(e)}
                                />
                                <label htmlFor="radio-ignore-customer-regions">
                                    Removes certificate regions not among the customer&apos;s tax
                                    regions
                                </label>
                                <br />
                                <input
                                    name="radio-ignore-companies-regions"
                                    type="radio"
                                    id="radio-ignore-companies-regions"
                                    value={removeNonNexusExposure}
                                    className="pad-top-xs"
                                    checked={radioValue === removeNonNexusExposure}
                                    onChange={e => handleRadioChange(e)}
                                />
                                <label htmlFor="radio-ignore-companies-regions">
                                    Removes certificate regions not among the company&apos;s
                                    certificate regions
                                </label>
                                <br />
                                <input
                                    name="radio-ignore-all-regions"
                                    type="radio"
                                    id="radio-ignore-all-regions"
                                    value={ignoreAllRegions}
                                    className="pad-top-xs"
                                    checked={radioValue === ignoreAllRegions}
                                    onChange={e => handleRadioChange(e)}
                                />
                                <label htmlFor="radio-ignore-all-regions">
                                    Removes certificate regions that aren&apos;t among the
                                    customer&apos;s tax regions or the company&apos;s certificate
                                    regions
                                </label>
                            </fieldset>
                        ) : null}
                    </SCol>
                </SRow>
            ) : (
                ""
            )}
            <FeatureToggler category="retail" id="enableRetailSetting">
                <SRow className="margin-top-md">
                    <SCol span="4">
                        <span className="margin-top-md text-md">Retail</span>
                    </SCol>
                    <SCol>
                        <input
                            id="enable_retail"
                            name="enable_retail"
                            type="checkbox"
                            aria-label="Allow authorized users to access retail and corresponding settings"
                            checked={enableRetail}
                            onChange={handleCheckBoxChange(setEnableRetail)}
                        />
                        <label htmlFor="enable_retail">
                            Allow authorized users to access retail and corresponding settings
                        </label>
                    </SCol>
                </SRow>
            </FeatureToggler>

            <SRow>
                <SCol span="12">
                    <hr className="margin-top-xl margin-bottom-lg" />
                </SCol>
            </SRow>
            <SRow>
                <SCol span="12">
                    <button
                        id="save-client"
                        className={submitButtonClassNames}
                        type="button"
                        onClick={handleSubmitRequest}
                        disabled={isEligibleUserFlag}
                        name="stop-apply-btn">
                        Save
                    </button>

                    <button className="tertiary" type="button" onClick={cancelHandler}>
                        Cancel
                    </button>
                </SCol>
            </SRow>
            {isCompanyLogoPresent && isPreviewDialogOpen ? (
                <SDialog
                    open={isPreviewDialogOpen}
                    id="preview-dialog"
                    onS-dismiss={onPreviewDismiss}
                    aria-modal="true">
                    <div slot="header" id="previewCompanyLogo">
                        {companyId} company logo
                    </div>
                    <div slot="body">
                        <img src={`data:image/*;base64,${companyLogo.data}`} alt="logo-preview" />
                    </div>
                </SDialog>
            ) : null}
            {isDeleteDialogOpen ? (
                <SDialog
                    open={isDeleteDialogOpen}
                    id="deleteCompanyLogo-dialog"
                    onS-dismiss={onDeleteDismiss}
                    aria-modal="true">
                    <div slot="header" id="deleteCompanyLogo">
                        Delete this company logo?
                    </div>
                    <div slot="body">
                        This will remove the company logo. Are you sure you want to do this?
                    </div>
                    <div slot="footer">
                        <button className="secondary small" onClick={cancelDelete}>
                            No, cancel
                        </button>
                        <button
                            className="primary small"
                            onClick={deleteLogo}
                            disabled={isEligibleUserFlag}>
                            Yes, delete
                        </button>
                    </div>
                </SDialog>
            ) : null}
            {isUploadDialogOpen ? (
                <FileUploaderDialog
                    certificateID={props.certificateID}
                    isLogo
                    title="Upload a logo"
                    isSupplementalAttachment={false}
                    isReplace={false}
                />
            ) : null}
        </React.Fragment>
    );
});

export default General;
