import React from "react";
import { SIcon } from "@avalara/skylab-react";
import { Link } from "react-router-dom";

const VendorRequestValidationError = React.memo(props => {
    const content = props.emptyMatrix ? (
        <span className="top-xs">
            This reason doesn&apos;t have a default form in this state.
            <Link
                to="/settings/default-exemption-forms/outgoing"
                className="margin-left-xs text-red-dark text-sm-strong text-underline ">
                View forms
            </Link>
        </span>
    ) : (
        <span className="top-xs"> Enter a {props.fieldName} </span>
    );

    return (
        <div className="input-msg">
            <SIcon name="alert-circle-filled" aria-hidden="true" />
            {content}
        </div>
    );
});

export default VendorRequestValidationError;
