import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildApiV3UrlWithQuery } from "../shared/Utils";

export const customerHistorySlice = createSlice({
    name: "customerHistory",
    initialState: {
        isloading: false,
        page: {
            data: [],
            paginate: {
                pageNumber: 0,
                startIndex: -1,
                endIndex: 0,
                rowsPerPage: 0,
                totalRecords: 0,
            },
        },
        sortColumn: "",
    },

    reducers: {
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
    },
});

export const { setLoading, setPage, setPageToNull, setSortColumn, setNoDataType } =
    customerHistorySlice.actions;

export const selectPage = state => {
    return state.customerHistory.page;
};

export const selectSortColumn = state => {
    return state.customerHistory.sortColumn;
};

export const selectIsLoading = state => {
    return state.customerHistory.isloading;
};

export const fetchCustomerHistoryAPI =
    (customerId, include, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
        const { isLoading, sortColumn } = getState().customerHistory;

        if (isLoading) {
            return;
        }

        dispatch(setLoading(true));
        const sort = sortColumn || "created DESC";

        const apiURI = buildApiV3UrlWithQuery(
            `customers/${customerId}/history`,
            "",
            include,
            top,
            skip,
            sort
        );

        const response = await axios.get(`${apiURI}`, { withCredentials: true });

        if (response.data && response.data.count === "0") {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull());
        } else {
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
        }

        dispatch(setLoading(false));
    };

export default customerHistorySlice.reducer;
