import React from "react";
import { SDialog } from "@avalara/skylab-react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { selectIsDeleting, deleteRetailLocation } from "../../../../app/retailLocationSlice";

const DeleteRetailLocationDialog = React.memo(props => {
    const dispatch = useDispatch();
    const isDeleting = useSelector(selectIsDeleting);
    const deleteButton = classnames({
        primary: true,
        small: true,
        loading: isDeleting,
    });
    const handleDelete = async event => {
        event.preventDefault();
        await dispatch(deleteRetailLocation(props.locationId));
        props.setIsDeleteDialogOpen(false);
        props?.setLocation({});
    };

    return (
        <SDialog
            id="deleteRetailLocation-dialog"
            open={props.isDeleteDialogOpen}
            onS-dismiss={() => props.setIsDeleteDialogOpen(false)}
            aria-modal="true"
            className="retail-dialog-width">
            <div slot="header" id="deleteRetailLocation">
                Are you sure you want to delete this location?
            </div>
            <div slot="body">
                You can&apos;t undo this action, but you can recreate this location later.
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => props.setIsDeleteDialogOpen(false)}>
                    Cancel
                </button>
                <button className={deleteButton} onClick={handleDelete}>
                    Yes, I&apos;m sure
                </button>
            </div>
        </SDialog>
    );
});

export default DeleteRetailLocationDialog;
