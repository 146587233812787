import React, { useEffect, useCallback, useState } from "react";
import { SRow, SCol, SLoader } from "@avalara/skylab-react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchCertificateForms,
    selectIsLoading,
    setCertificateForms,
} from "../../../app/contentSlice";
import { getPageTitle } from "../../../shared/sessionUtility";
import SelectState from "../../sharedComponents/SelectState";
import CertificateFormsList from "./CertificateFormsList";
import CertificateFormsContent from "./CertificateFormsContent";

const CertificateForms = React.memo(() => {
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);
    const [state, setState] = useState("Alabama");

    useEffect(() => {
        dispatch(fetchCertificateForms(state));
        return () => {
            dispatch(setCertificateForms([]));
        };
    }, [dispatch, state]);
    const handleStateChange = useCallback(
        value => {
            setState(value?.label);
            dispatch(fetchCertificateForms(value?.label));
        },
        [dispatch]
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Exemption Certificate Forms"))}</title>
            </Helmet>

            <h1 className="line-height-md pad-bottom-sm">Exemption Certificate Forms</h1>
            <SRow>
                <SCol span={3}>
                    <SelectState
                        onAdd={handleStateChange}
                        value={state}
                        AddUncat
                        label="Region"
                        disabled={false}
                        multiple={false}
                        selectionOptional={false}
                    />
                </SCol>
            </SRow>
            {loading ? (
                <SRow>
                    <div className="flex dl-flex-fill-height dl-flex-center">
                        <h3>Loading ...</h3>
                        <SLoader id="page-loader" className="medium" aria-live="polite" loading />
                    </div>
                </SRow>
            ) : (
                <SRow>
                    <SCol span="3">
                        <CertificateFormsList selectedState={state} />
                    </SCol>
                    <SCol span="9">
                        <CertificateFormsContent />
                    </SCol>
                </SRow>
            )}
        </React.Fragment>
    );
});
export default CertificateForms;
