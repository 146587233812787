import React, { useState } from "react";
import { SDialog, SAlert, SContainer, SRow, SCol } from "@avalara/skylab-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import classnames from "classnames";
import toast from "../../../hooks/toast";

import {
    deleteBulkCustomers,
    selectIsBulkDeleteCustomerDialogOpen,
    setIsBulkDeleteCustomerDialogOpen,
    selectIsDeletingCustomer,
    selectCustomerIds,
} from "../../../app/customerSlice";

function BulkDeleteCustomerDialog() {
    const isCustomerDeleteDialogOpen = useSelector(selectIsBulkDeleteCustomerDialogOpen);
    const dispatch = useDispatch();
    const [selectedField, setSelectedField] = useState("deleteCertificates");
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
    const isCustomersDeleteLoading = useSelector(selectIsDeletingCustomer);
    const customerIds = useSelector(selectCustomerIds, shallowEqual);
    const [inputValue, setInputValue] = useState("");

    const [showToast] = toast();
    const record = customerIds.length > 1 ? "records" : "record";
    const title = `Permanently delete ${customerIds.length} customer ${record}?`;

    const deleteButton = classnames({
        primary: true,
        small: true,
        loading: isCustomersDeleteLoading,
    });
    const onDialogClose = () => {
        setInputValue("");
        dispatch(setIsBulkDeleteCustomerDialogOpen(false));
    };

    const handleRadioChange = e => {
        setSelectedField(e.target.value);
    };

    const handleInputChange = e => {
        const value = e.target?.value?.toUpperCase();
        setInputValue(value);
        if (value === "DELETE") {
            setIsDeleteDisabled(false);
        } else {
            setIsDeleteDisabled(true);
        }
    };
    const onBulkCustomersDelete = async () => {
        if (customerIds.length > 100) {
            showToast("warning", "There is a deletion limit of 100 customers.");
            dispatch(setIsBulkDeleteCustomerDialogOpen(false));
            return;
        }
        const isCertificateDelete = selectedField === "deleteCertificates";
        await dispatch(deleteBulkCustomers(showToast, isCertificateDelete));
        dispatch(setIsBulkDeleteCustomerDialogOpen(false));
    };

    return (
        <SDialog
            id="deleteCustomerConfirmation-dialog"
            open={isCustomerDeleteDialogOpen}
            onS-dismiss={onDialogClose}
            aria-modal="true">
            <div slot="header" id="deleteCustomerConfirmation">
                {title}
            </div>

            <div slot="body">
                <label className="text-sm-strong margin-top-sm">
                    What would you like to do with the customers&apos; certificates?
                </label>

                <fieldset role="radiogroup">
                    <input
                        name="deleteCertificates"
                        id="deleteCertificates"
                        type="radio"
                        value="deleteCertificates"
                        onChange={e => handleRadioChange(e)}
                        checked={selectedField === "deleteCertificates"}
                    />
                    <label htmlFor="deleteCertificates">Delete the certificates</label>
                    <div className="text-xs margin-left-md">
                        Certificates shared by more than one customer won&apos;t be deleted.
                    </div>
                    <input
                        name="keepCertificates"
                        id="keepCertificates"
                        type="radio"
                        value="keepCertificates"
                        onChange={e => handleRadioChange(e)}
                        checked={selectedField === "keepCertificates"}
                    />
                    <label htmlFor="keepCertificates">Keep the certificates</label>
                    <div className="text-xs margin-left-md">
                        The customer is removed from the customer field.
                    </div>
                </fieldset>

                <label htmlFor="confirmDelete" className="text-sm margin-bottom-md">
                    Deleting customer records can take a while.
                </label>

                <SAlert id="delete-alert-dialog" status="warning" role="alert" noDismiss>
                    <SContainer className="alert-container">
                        <SRow>
                            <SCol span="12">
                                <span className="font-bold">
                                    When customer record is deleted it&apos;s gone for good.
                                </span>
                                <span> Type </span>
                            </SCol>
                        </SRow>
                        <SRow>
                            <SCol span="12">
                                <span className="font-bold">DELETE </span>
                                <span>to confirm you want to do this?</span>
                            </SCol>
                        </SRow>
                        <SRow>
                            <SCol span="12">
                                <input
                                    id="confirmDelete"
                                    name="confirmDelete"
                                    className="margin-top-sm"
                                    type="text"
                                    value={inputValue}
                                    onChange={e => handleInputChange(e)}
                                />
                            </SCol>
                        </SRow>
                    </SContainer>
                </SAlert>
            </div>
            <div slot="footer">
                <button className="secondary small" onClick={onDialogClose}>
                    Cancel
                </button>
                <button
                    disabled={isDeleteDisabled}
                    type="button"
                    className={deleteButton}
                    onClick={onBulkCustomersDelete}>
                    Yes, delete
                </button>
            </div>
        </SDialog>
    );
}

export default BulkDeleteCustomerDialog;
