import "./base.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import store from "./app/store";
import Application from "./features/application/Application";
import * as serviceWorker from "./registerServiceWorker";
import retainCriticalData from "./app/retainCriticalData";
import isOneWebDomainRuntime from "./shared/avaAppRuntime";

if (isOneWebDomainRuntime()) {
    window.addEventListener("pagehide", retainCriticalData);
}

const container = isOneWebDomainRuntime()
    ? document.querySelector("main")
    : document.getElementById("root");
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HelmetProvider>
                <Application />
            </HelmetProvider>
        </Provider>
    </React.StrictMode>
);
serviceWorker.unregister();
