import { SCol, SRow, SSelect } from "@avalara/skylab-react";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    fetchCompanyLocations,
    selectCompanyLocation,
    selectIsLoadingCompanyLocation,
    setSelectedLocation,
} from "../../../app/retailSlice";
import {
    getUserInfo,
    selectIsLoading,
    selectUserLocationsInfo,
} from "../../../app/roleManagementSlice";
import { selectSession } from "../../../app/sessionSlice";
import {
    fetchCompanyLogo,
    selectCompanyLogo,
    selectIsCompanyLogoPresent,
} from "../../../app/settingsSlice";
import featureFlag from "../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../shared/sessionUtility";
import "./retailNavBar.css";

function RetailNavBar({ isLogoVisible }) {
    const dispatch = useDispatch();
    const [userLocations, setUserLocations] = useState([]);
    const session = useSelector(selectSession, shallowEqual);
    const { isAdminUser } = session;
    const userCompanyLocations = useSelector(selectUserLocationsInfo, shallowEqual);
    const companyLocations = useSelector(selectCompanyLocation, shallowEqual);
    const isLoadingCompanyLocations = useSelector(selectIsLoadingCompanyLocation);
    const companyLogo = useSelector(selectCompanyLogo, shallowEqual);
    const isCompanyLogoPresent = useSelector(selectIsCompanyLogoPresent);
    const isLoadingUserData = useSelector(selectIsLoading);

    const retailLocationsId = "user-location-id";
    const isUserEligibleToViewLocationSelector = dispatch(
        isEligibleUser(featureFlag.retail.selectLocation)
    );

    useEffect(() => {
        if (isAdminUser) {
            dispatch(fetchCompanyLocations());
        }
        // To get the user retail locations
        if (session.userId && session.activeCompany.id) {
            dispatch(getUserInfo(session.userId));
            dispatch(fetchCompanyLogo());
        }
    }, [isAdminUser, session.userId, session.activeCompany.id]);

    const createLocationOption = location => ({
        label: location.name,
        value: `${location.id}`,
        selected: false,
    });

    const getLocationOptions = (locations = []) => [
        {
            label: "Select a location",
            value: "-1",
            selected: true,
        },
        ...(Array.isArray(locations) ? locations.map(createLocationOption) : []),
    ];

    useEffect(() => {
        if (isAdminUser) {
            if (companyLocations?.length > 0) {
                setUserLocations(getLocationOptions(companyLocations));
            }
        } else if (userCompanyLocations?.length > 0) {
            if (userCompanyLocations[0]?.isAllCompany) {
                const locations = userCompanyLocations[0]?.locations || [];
                setUserLocations(getLocationOptions(locations));
            } else {
                const filteredLocations = userCompanyLocations
                    .filter(
                        userCompanyLocation =>
                            userCompanyLocation?.companyId === session.activeCompany?.id
                    )
                    .flatMap(userCompanyLocation => userCompanyLocation?.locations || []);

                if (Array.isArray(filteredLocations)) {
                    setUserLocations(getLocationOptions(filteredLocations));
                } else {
                    setUserLocations(getLocationOptions([]));
                }
            }
        } else {
            setUserLocations(getLocationOptions([]));
        }
    }, [
        isAdminUser,
        session.activeCompany?.id,
        JSON.stringify(companyLocations),
        JSON.stringify(userCompanyLocations),
    ]);

    return (
        <SRow className="flex margin-right-lg margin-bottom-md justify-content-space retail-nav-bar">
            <SCol span="4">
                {isUserEligibleToViewLocationSelector ? (
                    <div className="location-selector">
                        <SSelect
                            optionsList={userLocations}
                            selectionOptional
                            loading={isLoadingCompanyLocations || isLoadingUserData}
                            onS-select={e => dispatch(setSelectedLocation(e.detail.item))}
                            name={retailLocationsId}
                            inputId={retailLocationsId}
                            onS-deselect={() => dispatch(setSelectedLocation(null))}
                        />
                    </div>
                ) : null}
            </SCol>
            <SCol span="3">
                {isLogoVisible && isCompanyLogoPresent ? (
                    <img
                        src={`data:image/*;base64,${companyLogo.data}`}
                        alt="logo-preview"
                        className="company-logo"
                    />
                ) : null}
            </SCol>
        </SRow>
    );
}

export default RetailNavBar;
