import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { checkSelectComponentProps } from "../../shared/Utils";
import { selectExposureZones, selectIsLoadingExposureZones } from "../../app/certificateSlice";

const SplitCertSelectExposureZone = React.forwardRef((props, ref) => {
    const [getCurrentId, getNextId] = useId();
    const isLoading = useSelector(selectIsLoadingExposureZones);
    const exposureZones = useSelector(selectExposureZones, shallowEqual).map(element => ({
        label: element.name,
        value: element.id,
        selected: element.id === props.value,
        isDataEntrySet: false,
    }));

    checkSelectComponentProps("SplitCertSelectExposureZone", props);

    return (
        <React.Fragment>
            {props.label ? (
                <label htmlFor={getNextId()} id={`lbl-${getCurrentId()}`}>
                    {props.label}
                </label>
            ) : (
                <label htmlFor={getNextId()} id="lbl-region">
                    Region
                </label>
            )}
            <SSelect
                name="splitCertExposureZone"
                inputId={getCurrentId()}
                selectionOptional
                optionsList={exposureZones}
                loading={isLoading}
                className={props.error ? props.error : ""}
                onS-select={e => {
                    props.onAdd(e.detail.item);
                }}
                onS-deselect={e => {
                    props.onRemove(e.detail.item);
                }}
                onS-close={e => (props.onClose ? props.onClose(e) : null)}
                disabled={props?.disabled || props?.exposureZoneDisabled}
                multiple={props.multiple}
                ref={ref}
            />
        </React.Fragment>
    );
});

export default React.memo(SplitCertSelectExposureZone);
