import React, { useRef, useState, useCallback } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { SDialog, SRow, SCol, SLoader, SIcon } from "@avalara/skylab-react";
import axios from "../../../axios";
import SelectExposureZone from "../../sharedComponents/SelectExposureZone";
import toast from "../../../hooks/toast";
import {
    setLoadingCustomerShipToZones,
    selectIsLoadingCustomerShipToZones,
    setCustomerShipToZones,
    setCustomer,
    getCustomerMissingCertRegion,
    getVendorMissingCertRegion,
} from "../../../app/customerSlice";
import { selectIsLoadingExposureZones } from "../../../app/certificateSlice";
import {
    setIsAddRegionDialogOpen,
    fetchCustomerExemptionsDetails,
} from "../../../app/customerCertificateSlice";
import "../customer.css";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import { buildApiV3Url } from "../../../shared/Utils";
import { parsePostObjResponse } from "../../../shared/responseUtils";

function CustomerAddRegionDialog(props) {
    const dispatch = useDispatch();
    const vendorCheck = props.isVendor;
    const taxRegionsRef = useRef(null);
    const [customerShipToZones, setCustomersShipToZones] = useState([]);
    const [deselectedClicked, setDeselectedClicked] = useState(false);
    const [formUpdated, setFormUpdated] = useState(false);
    const [regionError, setRegionError] = useState("");
    const isLoadingCustomerShipToZones = useSelector(
        selectIsLoadingCustomerShipToZones,
        shallowEqual
    );
    const isLoadingExposureZone = useSelector(selectIsLoadingExposureZones);
    const docName = "Sales and Use Tax";
    const saveButton = classnames({
        primary: true,
        loading: isLoadingCustomerShipToZones,
    });
    const [showToast] = toast();
    const labelString = vendorCheck
        ? "Select the regions where you purchase from this vendor."
        : "Select the regions where you expect to request exemptions certificates from this customer.";
    const entityType = vendorCheck ? "vendor" : "customer";
    const closeDialog = () => {
        dispatch(setIsAddRegionDialogOpen(false));
    };
    let CustomerDOM = null;
    const handleAddShipToZone = value => {
        setRegionError("");
        let items = value;
        if (!Array.isArray(value)) {
            items = [{ id: value.value, name: value.label }];
        }
        setCustomersShipToZones([...customerShipToZones, ...items]);
        dispatch(setLoadingCustomerShipToZones(false));
        setFormUpdated(true);
        setDeselectedClicked(false);
    };

    const handleRemoveShipToZone = useCallback(
        (eventDetail, isDeselectAll) => {
            const { value } = eventDetail;
            if (isDeselectAll) {
                setCustomersShipToZones([]);
            } else {
                setCustomersShipToZones(customerShipToZones.filter(option => option.id !== value));
            }
            dispatch(setLoadingCustomerShipToZones(false));
            setFormUpdated(true);
        },
        [customerShipToZones]
    );

    const addCustomersExposureZones = async (customerId, exposureZonesId, documentDescription) => {
        const requestBody = {
            exposureZoneIds: exposureZonesId,
            documentTypeName: documentDescription,
            outgoing: vendorCheck,
        };
        return axios
            .post(buildApiV3Url(`customers/${customerId}/ship-to-zones`), requestBody, {
                withCredentials: true,
            })
            .catch(error => {
                return error;
            });
    };

    const updateCustomersExposureZones = async (customerId, shipToStates) => {
        let response;
        const shipStateIds = shipToStates.map(shipState => shipState.id);
        const addRequestBody = shipStateIds;
        if (addRequestBody.length !== 0) {
            response = await addCustomersExposureZones(customerId, addRequestBody, docName);
        }
        return response;
    };
    const addTaxRegion = async () => {
        if (!customerShipToZones || (customerShipToZones && customerShipToZones.length <= 0)) {
            setRegionError("Please select a region");
            return;
        }

        dispatch(setLoadingCustomerShipToZones(true));

        const response = await updateCustomersExposureZones(props.customer.id, customerShipToZones);
        const addResponse = parsePostObjResponse(response);
        if (!addResponse) {
            showToast("warning", `Could not update ${entityType} regions.`);
            return;
        }
        if (addResponse) {
            dispatch(setCustomerShipToZones(customerShipToZones));
            showToast("success", `Tax region added.`);
            dispatch(setLoadingCustomerShipToZones(false));
            dispatch(setIsAddRegionDialogOpen(false));
            dispatch(fetchCustomerExemptionsDetails());
            const customerData = JSON.parse(JSON.stringify(props.customer));
            customerData.isShipTo = addResponse.isShipTo;
            customerData.isBillTo = addResponse.isBillTo;
            dispatch(setCustomer(customerData));
            if (entityType === "vendor") {
                dispatch(getVendorMissingCertRegion(props.customer.id));
            } else {
                dispatch(getCustomerMissingCertRegion(props.customer.id));
            }
        }
    };

    if (isLoadingExposureZone && isLoadingCustomerShipToZones) {
        CustomerDOM = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h1>Loading ...</h1>
                <SLoader loading="true" aria-live="polite" />
            </div>
        );
    } else {
        CustomerDOM = (
            <SRow>
                <SCol>
                    <FeatureToggler category="customers" id="taxRegions">
                        <SelectExposureZone
                            label={labelString}
                            id="shipToZones"
                            name="shipToZones"
                            onAdd={handleAddShipToZone}
                            onRemove={handleRemoveShipToZone}
                            multiple
                            exposureZoneDisabled={false}
                            disabled={false}
                            loading={isLoadingExposureZone && isLoadingCustomerShipToZones}
                            ref={taxRegionsRef}
                            externalDeselectedClicked={deselectedClicked}
                            isVendor={vendorCheck}
                            error={regionError}
                        />
                        <div className="input-msg" hidden={!regionError}>
                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                            <span className="top-xs">{regionError}</span>
                        </div>
                    </FeatureToggler>
                </SCol>
            </SRow>
        );
    }

    return (
        <SDialog
            id="customerAddRegionDialog"
            open={props.isAddRegionDialogOpen}
            onS-dismiss={closeDialog}
            className="region-dialog-width">
            <div slot="header" id="taxregions">
                Region
            </div>
            <div slot="body" className="region-dialog-body">
                {CustomerDOM}
            </div>
            <div slot="footer">
                <button
                    className={saveButton}
                    disabled={!formUpdated}
                    type="button"
                    onClick={addTaxRegion}>
                    Save
                </button>
                <button className="secondary" onClick={closeDialog}>
                    Cancel
                </button>
            </div>
        </SDialog>
    );
}
export default CustomerAddRegionDialog;
