import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { SLoader, SIcon, SRow, SCol } from "@avalara/skylab-react";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import {
    getCustomerShipTos,
    selectCustomerShipTos,
    getCustomerBillTos,
    selectCustomerBillTos,
    selectIsRelatedCustomerDialog,
    setIsRelatedCustomerDialog,
    selectIsLoadingShipTos,
    selectIsLoadingBillTos,
} from "../../../app/customerSlice";
import ShipToCustomers from "./ShipToCustomers";
import BillToCustomers from "./BillToCustomers";
import LinkCustomerDialog from "./LinkCustomerDialog";
import UnlinkCustomerDialog from "./UnlinkCustomerDialog";
import featureFlag from "../../../featureToggler/featureFlag";

const RelatedCustomers = React.memo(props => {
    const dispatch = useDispatch();
    const shipTos = useSelector(selectCustomerShipTos, shallowEqual);
    const billTos = useSelector(selectCustomerBillTos, shallowEqual);
    const isOpenDislog = useSelector(selectIsRelatedCustomerDialog);
    const isLoadingShipTos = useSelector(selectIsLoadingShipTos);
    const isLoadingBillTos = useSelector(selectIsLoadingBillTos);
    const isEligibleECMUserFlag = dispatch(isEligibleUser(featureFlag.customers.manageCustomer));
    const [isUnlinkCustomerDialogOpen, setIsUnlinkCustomerDialogOpen] = useState(false);
    const [childCustomerId, setChildCustomerId] = useState(0);
    const [linkDialogType, setLinkDialogType] = useState(1);

    const openLinkDialog = linkType => {
        setLinkDialogType(linkType);
        dispatch(setIsRelatedCustomerDialog(true));
    };

    const navigateToDefaultTab = () => {
        props?.navigateTo("exemptions");
    };

    useEffect(() => {
        dispatch(getCustomerShipTos(props.customer.id));
        dispatch(getCustomerBillTos(props.customer.id));
    }, [dispatch, props.customer.id]);

    let billToTable = isLoadingBillTos ? (
        <div className="flex dl-flex-fill-height dl-flex-center">
            <h3>Loading ...</h3>
            <SLoader id="page-loader" className="medium" aria-live="polite" loading />
        </div>
    ) : null;
    if (billTos.length > 0) {
        billToTable = (
            <SRow className="margin-bottom-md">
                <SCol>
                    <BillToCustomers
                        billTos={billTos}
                        setIsUnlinkCustomerDialogOpen={setIsUnlinkCustomerDialogOpen}
                        setChildCustomerId={setChildCustomerId}
                        setLinkDialogType={setLinkDialogType}
                        navigateToDefaultTab={navigateToDefaultTab}
                    />
                </SCol>
            </SRow>
        );
    } else {
        billToTable = (
            <button
                type="button"
                disabled={!isEligibleECMUserFlag}
                className="primary left inline"
                onClick={() => openLinkDialog(1)}>
                <SIcon name="link" className="margin-right-xs" aria-hidden="true" />
                Link a bill-to customer
            </button>
        );
    }

    let shipToTable = isLoadingShipTos ? (
        <div className="flex dl-flex-fill-height dl-flex-center">
            <h3>Loading ...</h3>
            <SLoader id="page-loader" className="medium" aria-live="polite" loading />
        </div>
    ) : null;
    if (shipTos.length > 0) {
        shipToTable = (
            <div>
                <SRow>
                    <SCol>
                        <button
                            type="button"
                            className="primary right inline"
                            disabled={!isEligibleECMUserFlag}
                            onClick={() => openLinkDialog(2)}>
                            <SIcon name="link" className="margin-right-xs" aria-hidden="true" />
                            Link a ship-to customer
                        </button>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        <p className="right inline margin-all-none">
                            {shipTos.length > 1 ? (
                                <span>{shipTos.length} ship-to customers </span>
                            ) : (
                                <span>{shipTos.length} ship-to customer</span>
                            )}
                        </p>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        <ShipToCustomers
                            shipTos={shipTos}
                            setIsUnlinkCustomerDialogOpen={setIsUnlinkCustomerDialogOpen}
                            setChildCustomerId={setChildCustomerId}
                            setLinkDialogType={setLinkDialogType}
                            navigateToDefaultTab={navigateToDefaultTab}
                        />
                    </SCol>
                </SRow>
            </div>
        );
    } else {
        shipToTable = (
            <SRow>
                <SCol>
                    <button
                        type="button"
                        className="primary inline"
                        disabled={!isEligibleECMUserFlag}
                        onClick={() => openLinkDialog(2)}>
                        <SIcon name="link" className="margin-right-xs" aria-hidden="true" />
                        Link a ship-to customer
                    </button>
                </SCol>
            </SRow>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle(`${props.customer.name} Related Customers`))}</title>
            </Helmet>
            <SRow>
                <SCol span="6">
                    <h2 className="margin-bottom-none">
                        {props.customer.name}&apos;s bill-to customer
                    </h2>
                </SCol>
            </SRow>
            {billToTable}

            <SRow>
                <SCol span="6">
                    <h2 className="margin-bottom-none">
                        {props.customer.name}&apos;s ship-to customers
                    </h2>
                </SCol>
            </SRow>
            {shipToTable}
            {isOpenDislog ? <LinkCustomerDialog linkDialogType={linkDialogType} /> : null}
            <UnlinkCustomerDialog
                isUnlinkCustomerDialogOpen={isUnlinkCustomerDialogOpen}
                setIsUnlinkCustomerDialogOpen={setIsUnlinkCustomerDialogOpen}
                childCustomerId={childCustomerId}
                setChildCustomerId={setChildCustomerId}
                parentCustomerId={props.customer.id}
                linkDialogType={linkDialogType}
            />
        </React.Fragment>
    );
});

export default RelatedCustomers;
