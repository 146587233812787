import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { SRow, SCol, SBox } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import { selectData, setActiveLaw, selectActiveLaw } from "../../../app/contentSlice";
import "../taxContent.css";

export const countLabel = "tax laws";
const TaxLawsList = React.memo(() => {
    const dispatch = useDispatch();
    const pageData = useSelector(selectData);
    const activeLawId = useSelector(selectActiveLaw);
    const setActiveLawId = e => {
        dispatch(setActiveLaw(e.target.id));
        if (e.pageY >= window.innerHeight) window.scrollTo(0, 0);
    };
    return (
        <React.Fragment>
            <SRow className="margin-top-md">
                <SCol span="9">
                    <span className="laws-font">Laws</span>
                </SCol>
                <SCol span="3">
                    <span className="right">
                        {pageData?.data?.length ? pageData.data.length : "0"} {countLabel}
                    </span>
                </SCol>
            </SRow>
            <hr />
            <SRow>
                <SCol>
                    {pageData.data && pageData.data.length > 0 ? (
                        pageData.data.map(law => (
                            <div
                                key={uuidv4()}
                                className="margin-top-xs font-bold"
                                id={law.id}
                                role="button"
                                tabIndex={0}
                                onClick={e => setActiveLawId(e)}>
                                <SBox
                                    id={law.id}
                                    className={
                                        law.id == activeLawId ? "laws-blue-box" : "laws-box"
                                    } /* eslint eqeqeq: 0 */
                                >
                                    {law.name}
                                </SBox>
                            </div>
                        ))
                    ) : (
                        <span className="margin-top-lg font-semibold line-height-sm">
                            No results found.
                        </span>
                    )}
                </SCol>
            </SRow>
        </React.Fragment>
    );
});

export default TaxLawsList;
