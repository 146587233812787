import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { SPagination, SLoader } from "@avalara/skylab-react";
import {
    selectCustomerSearchPage,
    selectCustomerSearchLoading,
    requestSearch,
    setCreateSortColumn,
    selectCreateSortColumn,
} from "../../app/requestSlice";
import RequestFilter from "./RequestFilter";
import RecordCount from "../search/RecordCount";
import { selectSession, updateRowCountPreference } from "../../app/sessionSlice";
import { getRowsPerPage } from "../../shared/Utils";

function RequestGrid(props) {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const session = useSelector(selectSession, shallowEqual);
    const loading = useSelector(selectCustomerSearchLoading);
    const location = useLocation();
    const { searchType } = location?.state || {};
    const page = useSelector(selectCustomerSearchPage);

    const pageData = page.data;
    const paginateData = page.paginate;

    const sortColumn = useSelector(selectCreateSortColumn);
    async function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setCreateSortColumn([column, order]));
        await dispatch(requestSearch(session.rowsPerPage));
        setSubmitting(false);
    }

    const columns = [
        gridHeader(
            "Customer code",
            "customerNumber",
            (value, row) => (
                <Link className="font-semibold customer-link" to={`/customer/${row.id}`}>
                    {row.customerNumber}
                </Link>
            ),
            null,
            null,
            null,
            null,
            sortFunction,
            "customerNumber"
        ),
        gridHeader("Customer name", "name", null, null, null, null, null, sortFunction, "name"),
        gridHeader(
            "Address",
            ["city", "state"],
            (value, row) => (
                <span>
                    {row.addressLine1} {row.city ? `${row.city}, ` : ""} {row.stateInitials}{" "}
                    {row.zip} {row.countryInitials}
                </span>
            ),
            null,
            null
        ),
        gridHeader("Email", "emailAddress", null, null, null, null, null, sortFunction, ""),
    ];

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            await dispatch(requestSearch(e.detail.rowsPerPage, e.detail.currentPage, e.detail));
            setSubmitting(false);
        }
    };

    const getRowKey = row => {
        return row.id;
    };

    let table = null;
    if (submitting || loading) {
        table = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    } else if (pageData.length === 0) {
        table = (
            <div className="margin-top-xl">
                <div id="no-customers-found" className="margin-top-sm">
                    <div className="font-semibold font-normal text-lg">
                        No matching customers found
                    </div>
                </div>
            </div>
        );
    } else {
        table = (
            <s-table-container class="margin-top-xs th-with-filter">
                <DataGrid
                    columns={columns}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={submitting}
                    sortColumn={sortColumn}
                    inContainer
                />
            </s-table-container>
        );
    }
    let pagination = null;
    if (paginateData.totalRecords > 0 && paginateData.indeterminate) {
        pagination = (
            <SPagination
                className="margin-top-md"
                rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                totalRecords={paginateData.totalRecords}
                indeterminate
                disabled
                startIndex={paginateData.startIndex}
                onS-paginate={e => {
                    handlePaginate(e);
                }}
            />
        );
    } else if (paginateData.totalRecords > 0) {
        pagination = (
            <SPagination
                className="margin-top-md"
                rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                totalRecords={paginateData.totalRecords}
                startIndex={paginateData.startIndex}
                onS-paginate={e => {
                    handlePaginate(e);
                }}
            />
        );
    }
    return (
        <React.Fragment>
            <RequestFilter savedSearchRef={props.savedSearchRef} searchType={searchType} />
            <div>
                <RecordCount
                    selectedCount={0}
                    recordCount={new Intl.NumberFormat().format(paginateData.totalRecords)}
                    recordName="recipients"
                />
            </div>
            {table}
            {pagination}
        </React.Fragment>
    );
}
export default RequestGrid;
