import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SSelect, SRow, SCol, SDialog, SAlert } from "@avalara/skylab-react";
import classnames from "classnames";
import CustomerTabTypeAhead from "../CustomerTabTypeAhead";
import {
    linkDuplicateCustomerAPI,
    selectIsLinkingDuplicateCustomer,
    selectHasCrossCompanySameAs,
    fetchSameAsClients,
    selectClientData,
    selectIsLoadingClients,
    selectPage,
} from "../../../app/customerDuplicateSlice";

function DuplicateCustomerDialog(props) {
    const dispatch = useDispatch();
    const isLinkingDuplicateCustomer = useSelector(selectIsLinkingDuplicateCustomer);
    const [sameAsCustomer, setSameAsCustomer] = useState(null);
    const [sameAsCompany, setSameAsCompany] = useState(0);
    const hasCrossCompanySameAs = useSelector(selectHasCrossCompanySameAs);
    const companyData = useSelector(selectClientData);
    const isLoadingClients = useSelector(selectIsLoadingClients);
    const [companyOptions, setCompanyOptions] = useState([]);
    const selectRef = React.createRef();
    const page = useSelector(selectPage);
    const paginateData = page.paginate;
    const duplicateLimit = paginateData.totalRecords === 10;

    const saveButton = classnames({
        primary: true,
        loading: isLinkingDuplicateCustomer,
        hidden: duplicateLimit,
    });

    const cancelButton = classnames({
        secondary: !duplicateLimit,
        primary: duplicateLimit,
    });

    const dialogLimitBody = classnames({
        hidden: !duplicateLimit,
    });

    const dialogDuplicateBody = classnames({
        hidden: duplicateLimit,
    });

    const addCustomer = value => {
        setSameAsCustomer(value[0]);
    };

    const removeCustomer = () => {
        setSameAsCustomer(null);
    };

    const updateOptions = value => {
        setCompanyOptions(
            companyOptions.map(element => {
                const selectOption = {
                    label: element.label,
                    value: element.value.toString(),
                    selected: element.value === value,
                };
                return selectOption;
            })
        );
        setSameAsCompany(value);
    };

    const linkDuplicateCustomer = async () => {
        await dispatch(
            linkDuplicateCustomerAPI(props.customerId, sameAsCustomer.id, sameAsCompany)
        );
        setSameAsCustomer(null);
        props.setIsDuplicateCustomerDialogOpen(false);
    };

    useEffect(() => {
        if (!props.isDuplicateCustomerDialogOpen) {
            selectRef.current.deselectAll();
        }
    }, [props.isDuplicateCustomerDialogOpen]);

    useEffect(() => {
        if (hasCrossCompanySameAs) {
            dispatch(fetchSameAsClients);
        }
    }, [hasCrossCompanySameAs]);

    useEffect(() => {
        if (companyData.length > 0) {
            setCompanyOptions(
                companyData.map(element => {
                    const selectOption = {
                        label: element.client.name,
                        value: element.client.id.toString(),
                        selected: false,
                    };
                    return selectOption;
                })
            );
        }
    }, [companyData]);

    return (
        <SDialog
            id="duplicate-customer-dialog"
            open={props.isDuplicateCustomerDialogOpen}
            onS-dismiss={() => props.setIsDuplicateCustomerDialogOpen(false)}
            className="overflow-visible"
            aria-modal="true">
            <div slot="header" id="linkDuplicateCustomer">
                Link a duplicate customer
            </div>
            <div slot="body" className={dialogLimitBody}>
                <SAlert status="warning" noDismiss className="margin-bottom-sm">
                    The maximum number of duplicate customers is 10. Remove customers and try saving
                    again.
                </SAlert>
            </div>

            <div slot="body" className={dialogDuplicateBody}>
                <p>
                    Linked customers share exemption certificates. When linked, changes to one
                    customer&apos;s certificates apply to all.
                </p>
                <SRow>
                    <SCol span="12">
                        {hasCrossCompanySameAs ? (
                            <React.Fragment>
                                <label>Company</label>
                                <SSelect
                                    inputId="same-as-company"
                                    selectionOptional
                                    optionsList={companyOptions}
                                    loading={isLoadingClients}
                                    onS-select={e => updateOptions(e.detail.item.value)}
                                    onS-deselect={() => setSameAsCompany(null)}
                                    className="margin-bottom-md"
                                />
                            </React.Fragment>
                        ) : null}
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="12">
                        <CustomerTabTypeAhead
                            handleAdd={addCustomer}
                            handleRemove={removeCustomer}
                            value={sameAsCustomer}
                            sameAsCompany={sameAsCompany}
                            typeAheadlabel="Customer"
                            customerId={props.customerId}
                            sameas
                            selectRef={selectRef}
                        />
                    </SCol>
                </SRow>
                {sameAsCustomer ? (
                    <SRow>
                        <SCol>
                            <s-table-container>
                                <table className="table-style" role="presentation">
                                    <thead className="theadStyle">
                                        <tr>
                                            <th>Customer code</th>
                                            <th>Name</th>
                                            <th>Address</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key={sameAsCustomer.id}>
                                            <td>{sameAsCustomer.customerNumber}</td>
                                            <td>{sameAsCustomer.name}</td>
                                            <td>
                                                {sameAsCustomer.addressLine1}{" "}
                                                {sameAsCustomer.city
                                                    ? `${sameAsCustomer.city}, `
                                                    : ""}{" "}
                                                {sameAsCustomer.state
                                                    ? sameAsCustomer.state.initials
                                                    : ""}{" "}
                                                {sameAsCustomer.zip}{" "}
                                                {sameAsCustomer.country
                                                    ? sameAsCustomer.country.initials
                                                    : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </s-table-container>
                        </SCol>
                    </SRow>
                ) : null}
            </div>
            <div slot="footer">
                <button
                    className={cancelButton}
                    onClick={() => {
                        props.setIsDuplicateCustomerDialogOpen(false);
                    }}>
                    Cancel
                </button>
                <button
                    className={saveButton}
                    type="button"
                    onClick={linkDuplicateCustomer}
                    disabled={!sameAsCustomer}>
                    Link duplicate customer
                </button>
            </div>
        </SDialog>
    );
}

export default DuplicateCustomerDialog;
