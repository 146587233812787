import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { getLabels, selectLabels, selectIsLoadingLabels } from "../../app/certificateSlice";

const SelectLabels = React.memo(props => {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingLabels);

    const labelOptions = useSelector(selectLabels, shallowEqual)?.map(element => {
        if (props.value.includes(element.id)) {
            return { label: element.name, value: element.id, selected: true };
        }
        return { label: element.name, value: element.id, selected: false };
    });

    useEffect(() => {
        if (!props?.hasLabels) dispatch(getLabels());
    }, [props?.hasLabels]);

    return (
        <React.Fragment>
            {props.name ? (
                <label htmlFor={getNextId()} id={`lbl-${getCurrentId()}`}>
                    {props.name}
                </label>
            ) : (
                <label htmlFor={getNextId()} id="lbl-labels">
                    Labels
                </label>
            )}
            <SSelect
                name="labels"
                inputId={getCurrentId()}
                optionsList={labelOptions}
                multiple={props.multiple}
                loading={isLoading}
                className={props.error ? props.error : ""}
                onS-select={e => props.onAdd(e.detail.item)}
                onS-deselect={e => props.onRemove(e.detail.item)}
                value={props.value}
                disabled={props?.disabled ? true : false} // eslint-disable-line
            />
        </React.Fragment>
    );
});

export default SelectLabels;
