import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { SDialog, SRow, SCol } from "@avalara/skylab-react";
import SelectInvalidReasons from "../sharedComponents/SelectInvalidReasons";
import "./bulkeditdialog.css";
import {
    setAddInvalidReasonsList,
    setRemoveInvalidReasonsList,
    setSelectedField,
} from "../../app/bulkUpdateSlice";
import { getInvalidReasons } from "../../app/certificateSlice";

function BulkUpdateInvalidReasonsDialog(props) {
    const dispatch = useDispatch();
    const { certificateCount } = props;
    const [addInvalidReasons, setAddInvalidReasons] = useState([]);
    const [removeInvalidReasons, setRemoveInvalidReasons] = useState([]);
    const onCancel = () => {
        props.setIsBulkUpdateInvalidReasonDialogOpen(false);
        dispatch(setAddInvalidReasonsList([]));
        dispatch(setRemoveInvalidReasonsList([]));
        dispatch(setSelectedField(""));
    };

    const onSave = () => {
        dispatch(setAddInvalidReasonsList(addInvalidReasons));
        dispatch(setRemoveInvalidReasonsList(removeInvalidReasons));
        props.setIsBulkUpdateConfirmDialogOpen(true);
        props.setIsBulkUpdateInvalidReasonDialogOpen(false);
    };

    const addListAddInvalidReasons = useCallback(item => {
        setAddInvalidReasons(prevInvalidReasons => [...prevInvalidReasons, item.value]);
    }, []);

    const addListRemoveInvalidReasons = useCallback(item => {
        setAddInvalidReasons(prevInvalidReasons =>
            prevInvalidReasons.filter(value => value !== item.value)
        );
    }, []);

    const removeListAddInvalidReasons = useCallback(item => {
        setRemoveInvalidReasons(prevInvalidReasons => [...prevInvalidReasons, item.value]);
    }, []);

    const removeListRemoveInvalidReasons = useCallback(item => {
        setRemoveInvalidReasons(prevInvalidReasons =>
            prevInvalidReasons.filter(value => value !== item.value)
        );
    }, []);
    useEffect(() => {
        dispatch(getInvalidReasons());
    }, [dispatch]);

    return (
        <SDialog
            id="bulk-update-invalidReason-dialog"
            open={props.isBulkUpdateInvalidReasonDialogOpen}
            onS-dismiss={onCancel}
            aria-modal="true">
            <div slot="header" id="bulk-update-invalidReason">
                Update {certificateCount} {certificateCount > 1 ? "certificates" : "certificate"}
            </div>
            <div slot="body">
                <div>
                    Use these fields to add or remove invalid reasons for the selected certificates.
                    We&apos;ll skip unnecessary operations like adding reasons that are already
                    present, or removing reasons that aren&apos;t.
                </div>
                <br />
                <div>
                    <SRow>
                        <SCol span="auto">
                            <SelectInvalidReasons
                                name="Add reasons these certificates are invalid"
                                onAdd={e => addListAddInvalidReasons(e)}
                                onRemove={e => addListRemoveInvalidReasons(e)}
                                multiple
                                value={JSON.stringify(addInvalidReasons)}
                                hasInvalidReasons
                            />
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span="auto">
                            <SelectInvalidReasons
                                name="Remove reasons these certificates are invalid"
                                onAdd={removeListAddInvalidReasons}
                                onRemove={removeListRemoveInvalidReasons}
                                multiple
                                value={JSON.stringify(removeInvalidReasons)}
                                hasInvalidReasons
                            />
                        </SCol>
                    </SRow>
                </div>
            </div>
            <div slot="footer">
                <button className="secondary small" onClick={onCancel}>
                    Cancel
                </button>
                <button
                    className="primary small"
                    type="button"
                    onClick={onSave}
                    disabled={
                        !(addInvalidReasons.length !== 0 || removeInvalidReasons.length !== 0)
                    }>
                    Save
                </button>
            </div>
        </SDialog>
    );
}
export default BulkUpdateInvalidReasonsDialog;
