import React, { useState, useRef, useCallback, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { SLoader, SPagination, SIcon, SRowActions } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import toast from "../../../hooks/toast";
import { DataGrid, gridHeader, useId } from "./index";
import {
    selectPage,
    fetchCertificatesSearchAPI,
    selectLoading,
    setSortColumn,
    selectSortColumn,
    getSelectedColumns,
    selectIsLoadingSelectedColumns,
    getCertificateColumnList,
    getCertificateFile,
    selectCertificateFiltersState,
    setIsCertificateGridEllipsesMenu,
    selectCustomFields,
    selectFormState,
    selectCertificateStatuses,
} from "../../../app/certificateSlice";
import "./certificate.css";
import CertificateFilter from "./CertificateFilter";
import CertificateStatusTag from "../../sharedComponents/CertificateStatusTag";
import { selectSession, updateRowCountPreference } from "../../../app/sessionSlice";
import RequestDialog from "../../sharedDialogs/RequestDialog";
import UploadCertificate from "../../sharedDialogs/UploadCertificate";
import DeleteCertificateDialog from "../../sharedDialogs/DeleteCertificateDialog";
import Upgrade from "../../upgradeTier/OnlineBuyDialog";
import { isEligibleUser } from "../../../shared/sessionUtility";
import { sentenceCase, getRowsPerPage } from "../../../shared/Utils";
import { Messages } from "../../../shared/constants";
import { selectResponseType, setResponseType } from "../../../app/savedSearchesSlice";
import SaveSearchDialog from "../../sharedDialogs/SaveSearchDialog";
import ExportSearchDialog from "../../sharedDialogs/ExportSearchDialog";
import BulkDownloadCertificateDialog from "../../sharedDialogs/BulkDownloadCertificateDialog";
import BulkDeleteCertificateDialog from "../../sharedDialogs/BulkDeleteCertificateDialog";
import featureFlag from "../../../featureToggler/featureFlag";
import LabelElementInGrid from "../../sharedComponents/LabelElementInGrid";
import RecordCount from "../RecordCount";

function CertificateGrid() {
    const dispatch = useDispatch();
    const [showToast] = toast();
    const [submitting, setSubmitting] = useState(false);
    const loading = useSelector(selectLoading);
    const isLoadingSelectedColumns = useSelector(selectIsLoadingSelectedColumns);
    let customerTableMap = [];
    const [customerRows, setCustomerRows] = useState({});
    const page = useSelector(selectPage, shallowEqual);
    const pageData = page.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectSortColumn);
    const certificateStatuses = useSelector(selectCertificateStatuses, shallowEqual);
    const selectedColumns = useSelector(getSelectedColumns);
    const [getCurrentId, getNextId] = useId();
    const session = useSelector(selectSession, shallowEqual);
    const [currentRow, setCurrentRow] = useState({});
    const uploadRef = useRef(null);
    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
    const [isBulkDeleteCertificateDialogOpen, setIsBulkDeleteCertificateDialogOpen] =
        useState(false);
    const [ellipseMenuState, setEllipseMenuState] = useState("");
    const saveSearchStatus = useSelector(selectResponseType);
    const certificateFiltersState = useSelector(selectCertificateFiltersState, shallowEqual);
    const customFields = useSelector(selectCustomFields);
    const formState = useSelector(selectFormState, shallowEqual);
    const isEligibleUserCustomFields = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.customFields, true)
    );
    const isEligibleUserValidatedCertificateUpload = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.upload)
    );
    const isEligibleUserValidatedCertificateDelete = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.delete)
    );

    const isEligibleUserRequestCertificatesSingleRequest = dispatch(
        isEligibleUser(featureFlag.requestCertificates.singleRequest)
    );

    const isUserEligibleToViewCertificateLabels = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.certificateLabels)
    );

    const isUserEligibleToViewReviewedBy = dispatch(
        isEligibleUser(featureFlag.certificateFilters.reviewedBy)
    );

    const savedSearchToast = useCallback(() => {
        switch (saveSearchStatus) {
            case "success": {
                dispatch(setResponseType(""));
                return showToast("success", Messages.saved_Success);
            }
            case "failure": {
                dispatch(setResponseType(""));
                return showToast("error", Messages.saved_failure);
            }
            default:
                return null;
        }
    }, [dispatch, saveSearchStatus, showToast]);

    useEffect(() => {
        if (saveSearchStatus) savedSearchToast();
    }, [saveSearchStatus, savedSearchToast]);

    useEffect(() => {
        dispatch(
            getCertificateColumnList(
                isEligibleUserCustomFields,
                isUserEligibleToViewCertificateLabels,
                isUserEligibleToViewReviewedBy
            )
        );
    }, [
        isEligibleUserCustomFields,
        isUserEligibleToViewCertificateLabels,
        isUserEligibleToViewReviewedBy,
        dispatch,
    ]);

    const commonEllipsesMenuItems = [
        {
            label: "Request a certificate",
            classList: ["primary"],
            id: `menu-${uuidv4()}-request`,
            disabled: !isEligibleUserRequestCertificatesSingleRequest,
        },
        {
            label: "Delete the certificate",
            classList: ["primary", "icon-leading"],
            id: `menu-${uuidv4()}-delete`,
            disabled: !isEligibleUserValidatedCertificateDelete,
        },
    ];

    const ellipsesMenuItems = [
        {
            label: "Upload a certificate",
            classList: ["primary", "icon-leading"],
            id: `menu-${uuidv4()}-upload`,
            disabled: !isEligibleUserValidatedCertificateUpload,
        },
        ...commonEllipsesMenuItems,
    ];
    const downloadEllipsesMenuItems = {
        label: "Download the certificate",
        classList: ["primary", "icon-leading"],
        id: `menu-${uuidv4()}-download`,
    };

    const ellipsisItems = row => {
        const itemList = [];
        if (row.s3DocumentExists) {
            itemList.push(downloadEllipsesMenuItems);
        }
        if (
            certificateStatuses?.filter(x => x.id === row.certificateStatusId)[0]?.name ===
            "REVOKED"
        ) {
            itemList.push(...commonEllipsesMenuItems);
        } else {
            itemList.push(...ellipsesMenuItems);
        }
        return itemList;
    };

    const openRequestModal = () => {
        if (session.baseSubscription) {
            setIsUpgradeDialogOpen(true);
        }
    };

    const downloadCertificate = async row => {
        await dispatch(getCertificateFile(row.certificateId, showToast));
    };

    function sortFunction(e) {
        setSubmitting(true);
        const columnId = e.detail.id;
        const order = sortColumn[0] === columnId ? !sortColumn[1] : true;
        dispatch(setSortColumn([columnId, order]));
        dispatch(fetchCertificatesSearchAPI(false, null, paginateData.rowsPerPage));
        setSubmitting(false);
    }

    const toggleCustomerRow = customerRow => {
        const rowId = customerRow.certificateId;
        let collapsed = true;

        if (!customerRows[rowId] || customerRows[rowId].collapsed) {
            collapsed = false;
        }

        setCustomerRows(prevState => ({
            ...prevState,
            [rowId]: { ...prevState[rowId], collapsed },
        }));
    };

    const getTaxCode = row => {
        if (row.certificateStatus.toUpperCase() === "PENDING-FUTURE") {
            return sentenceCase(row.certificateStatus);
        }
        if (row.taxCodeName === null || row.taxCodeName.includes("EXPOSURE:")) {
            return "";
        }
        return sentenceCase(row.taxCodeName);
    };

    const getCustomValue = (customField, fieldName) => {
        const noDupes = [...new Set(customField)];
        if (noDupes[0] !== null && noDupes[0] !== "null") {
            const match = noDupes?.filter(x => x && x?.includes(fieldName));
            if (match.length !== 0) {
                const customValue = match[0].split(":");
                return customValue[1].trim();
            }
            return match;
        }
        return noDupes;
    };

    const headerColumns = [
        gridHeader(
            "Certificate ID",
            "certificateId",
            value => (
                <Link className="cert-link" to={`/certificate/${value}`}>
                    {value}
                </Link>
            ),
            null,
            null,
            null,
            null,
            sortFunction,
            "id"
        ),
        gridHeader(
            "Customer name",
            "[customers]",
            (value, row) =>
                row.customers.length > 1 ? (
                    <div>
                        {customerRows[row.certificateId] &&
                        !customerRows[row.certificateId].collapsed ? (
                            <s-table-container class="no-border name-table">
                                <table className="row-height-sm borderless" role="presentation">
                                    <tbody>
                                        {Object.keys(row.customers).map((v, idx) => {
                                            customerTableMap.push(
                                                <tr key={row.customers[idx]}>
                                                    <td className="pad-left-none">
                                                        {row.customers[idx].name}
                                                    </td>
                                                </tr>
                                            );

                                            const returnMap = customerTableMap;
                                            customerTableMap = [];
                                            return returnMap;
                                        })}
                                    </tbody>
                                </table>
                            </s-table-container>
                        ) : null}
                    </div>
                ) : (
                    row.customers[0] && <span>{row.customers[0].name}</span>
                ),
            null,
            null,
            null,
            null,
            null,
            "name"
        ),
        gridHeader(
            "Customer code",
            "[customers]",
            (value, row) =>
                row.customers.length > 1 ? (
                    <div>
                        <button className="link" onClick={() => toggleCustomerRow(row)}>
                            {`${row.customers.length} customers`}
                            {customerRows[row.certificateId] &&
                            !customerRows[row.certificateId].collapsed ? (
                                <SIcon
                                    name="chevron-up"
                                    className="pad-left-xs top-xs"
                                    aria-hidden="true"
                                />
                            ) : (
                                <SIcon
                                    name="chevron-down"
                                    className="pad-left-xs top-xs"
                                    aria-hidden="true"
                                />
                            )}
                        </button>

                        {customerRows[row.certificateId] &&
                        !customerRows[row.certificateId].collapsed ? (
                            <s-table-container class="no-border margin-top-sm">
                                <table className="row-height-sm borderless" role="presentation">
                                    <tbody>
                                        {Object.keys(row.customers).map((v, idx) => {
                                            customerTableMap.push(
                                                <tr key={row.customers[idx]}>
                                                    <td className="pad-left-none">
                                                        <Link
                                                            to={`${
                                                                row.customers[idx].isVendor
                                                                    ? "/vendor"
                                                                    : "/customer"
                                                            }/${row.customers[idx].id}`}>
                                                            <button className="link">
                                                                {row.customers[idx].customerNumber}
                                                            </button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );

                                            const returnMap = customerTableMap;
                                            customerTableMap = [];
                                            return returnMap;
                                        })}
                                    </tbody>
                                </table>
                            </s-table-container>
                        ) : null}
                    </div>
                ) : (
                    row.customers[0] && (
                        <Link
                            to={`${row.customers[0].isVendor ? "/vendor/" : "/customer/"}${
                                row.customers[0].id
                            }`}>
                            <button className="link">{row.customers[0].customerNumber}</button>
                        </Link>
                    )
                ),
            null,
            null,
            null,
            null,
            null,
            "customerCode"
        ),
        gridHeader(
            "Region",
            "exposureZoneName",
            value => <span name="Region-field"> {value} </span>,
            null,
            "",
            null,
            null,
            sortFunction,
            "exposureZoneName"
        ),
        gridHeader(
            "Exemption reason",
            "taxCodeName",
            (value, row) => <span name="exemptionReasons-field">{getTaxCode(row)}</span>,
            null,
            "",
            null,
            null,
            null,
            "taxCodeName"
        ),
        gridHeader(
            "Added",
            "createdDate",
            value => (value ? new Date(value).toLocaleDateString() : ""),
            null,
            "",
            null,
            null,
            sortFunction,
            "createdDate"
        ),
        gridHeader(
            "Effective",
            "signedDate",
            value => (value ? new Date(value).toLocaleDateString() : ""),
            null,
            "",
            null,
            null,
            sortFunction,
            "signedDate"
        ),
        gridHeader(
            "Expiration",
            "expirationDate",
            value => (value ? new Date(value).toLocaleDateString() : ""),
            null,
            "",
            null,
            null,
            sortFunction,
            "expirationDate"
        ),
        gridHeader(
            "Status",
            "certificateStatus",
            (value, row, col, index) => (
                <React.Fragment>
                    {CertificateStatusTag(
                        row,
                        col.length - index <= 3,
                        false,
                        certificateStatuses.filter(x => x.id === row.certificateStatusId)[0]?.name
                    )}
                </React.Fragment>
            ),
            "col-status",
            "col-status",
            null,
            null,
            null,
            "certificateStatus"
        ),
        gridHeader(
            "Single use",
            "isSingle",
            value => <span name="isSingle"> {value ? "Yes" : "No"} </span>,
            null,
            null,
            null,
            null,
            null,
            "isSingle"
        ),
        gridHeader(
            "PO Number",
            "purchaseOrderNumber",
            value => <span name="poNumber"> {value} </span>,
            null,
            null,
            null,
            null,
            null,
            "purchaseOrderNumber"
        ),
        gridHeader(
            "Tax ID",
            "taxNumber",
            value => <span name="taxNumber"> {value} </span>,
            null,
            null,
            null,
            null,
            null,
            "taxNumber"
        ),
        gridHeader(
            "Labels",
            "[certificatesLabels]",
            (value, row) => (
                <LabelElementInGrid
                    labels={[...row.certificatesLabels.filter(labelName => labelName)]}
                />
            ),
            null,
            null,
            null,
            null,
            null,
            "certificatesLabels"
        ),
        gridHeader(
            "Reviewed by",
            "uploadedBy",
            value => <span name="reviewedBy"> {value} </span>,
            null,
            null,
            null,
            null,
            null,
            "uploadedBy"
        ),
        gridHeader(
            "",
            "",
            (value, row) => (
                <React.Fragment>
                    <span htmlFor={getNextId()} />
                    <SRowActions
                        menuId={`menu-${getCurrentId()}`}
                        key={uuidv4()}
                        actionItems={ellipsisItems(row)}
                        collapsed
                        onS-select={e => {
                            setCurrentRow(row);
                            setEllipseMenuState(e.detail.id);
                            dispatch(setIsCertificateGridEllipsesMenu(true));
                            if (e.detail.id.includes("upload")) {
                                uploadRef.current.click();
                            } else if (e.detail.id.includes("download")) {
                                downloadCertificate(row);
                            } else if (e.detail.id.includes("request")) {
                                openRequestModal(row);
                            }
                        }}
                    />
                </React.Fragment>
            ),
            "text-right",
            "text-right"
        ),
    ];

    let modifiedColumns = [];

    const arrangeColumns = columnlist => {
        const visibleColumns = columnlist?.filter(a => a.hidden === false);
        const tempCol = [modifiedColumns[0]];
        for (let j = 0; j < visibleColumns.length; ) {
            const item = modifiedColumns.find(obj => {
                return obj.id === visibleColumns[j].id;
            });
            tempCol.push(item);
            j += 1;
        }
        tempCol.push(modifiedColumns[modifiedColumns.length - 1]);
        modifiedColumns = tempCol;
    };

    const ManageColumns = () => {
        if (customFields.length !== 0) {
            customFields.forEach(field => {
                if (!headerColumns.find(obj => obj.id === field.fieldName)) {
                    headerColumns.splice(
                        -1,
                        0,
                        gridHeader(
                            field.fieldName,
                            field.fieldName,
                            (value, row) => (
                                <span name={field.fieldName}>
                                    {getCustomValue(row.customFields, field.fieldName)}
                                </span>
                            ),
                            null,
                            null,
                            null,
                            null,
                            null,
                            field.fieldName?.replace(/ /g, "")
                        )
                    );
                }
            });
        }
        modifiedColumns = headerColumns;
        selectedColumns?.forEach(col => {
            if (col.hidden) {
                modifiedColumns = modifiedColumns?.filter(obj => {
                    return obj.id !== col.id;
                });
            }
        });
        arrangeColumns(selectedColumns);
        return modifiedColumns;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            setSubmitting(true);
            await dispatch(
                fetchCertificatesSearchAPI(
                    false,
                    e.detail,
                    e.detail.rowsPerPage,
                    e.detail.currentPage
                )
            );
            setSubmitting(false);
        }
    };

    const getRowKey = row => {
        return row.certificateId;
    };

    let table = null;
    if (submitting || loading || isLoadingSelectedColumns)
        table = (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    else if (pageData.length > 0)
        table = (
            <s-table-container class="th-with-filter">
                <DataGrid
                    columns={ManageColumns(selectedColumns)}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={submitting}
                    inContainer
                    sortColumn={sortColumn}
                />
            </s-table-container>
        );

    let pagination = null;
    if (paginateData.totalRecords > 0 && paginateData.indeterminate) {
        pagination = (
            <SPagination
                className="margin-top-md"
                rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                totalRecords={paginateData.totalRecords}
                indeterminate
                disabled
                startIndex={paginateData.startIndex}
                onS-paginate={e => {
                    handlePaginate(e);
                }}
            />
        );
    } else if (paginateData.totalRecords > 0) {
        pagination = (
            <SPagination
                className="margin-top-md"
                rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                totalRecords={paginateData.totalRecords}
                startIndex={paginateData.startIndex}
                onS-paginate={e => {
                    handlePaginate(e);
                }}
            />
        );
    }

    return (
        <React.Fragment>
            <CertificateFilter
                setIsBulkDeleteCertificateDialogOpen={setIsBulkDeleteCertificateDialogOpen}
            />
            <div className="margin-top-sm">
                <RecordCount
                    selectedCount={0}
                    recordCount={new Intl.NumberFormat().format(paginateData.totalRecords)}
                    recordName="certificates"
                />
            </div>
            {table}
            {pagination}

            {String(ellipseMenuState).includes("delete") ? (
                <DeleteCertificateDialog
                    isDeleteCertificateDialogOpen
                    setIsDeleteCertificateDialogOpen={setEllipseMenuState}
                    certificateIds={currentRow.certificateId}
                />
            ) : null}

            {String(ellipseMenuState).includes("request") &&
            currentRow.certificateId &&
            !session.baseSubscription ? (
                <RequestDialog
                    isRequestDialogOpen
                    setIsRequestDialogOpen={setEllipseMenuState}
                    customer={currentRow.customers[0]}
                    customerCount={currentRow.customers.length}
                    setCurrentRow={setCurrentRow}
                    exposureZone={currentRow && currentRow.exposureZoneName}
                    taxCode={currentRow && currentRow.expectedTaxCodeName}
                />
            ) : null}
            <Upgrade
                isUpgradeDialogOpen={isUpgradeDialogOpen}
                setIsUpgradeDialogOpen={setIsUpgradeDialogOpen}
                type="request"
            />
            <UploadCertificate
                ref={uploadRef}
                isButtonHidden
                isOverride={currentRow.s3DocumentExists}
                certificateID={currentRow.certificateId}
                isUploaderDisabled={
                    !dispatch(isEligibleUser(featureFlag.validatedCertificate.upload))
                }
            />
            <SaveSearchDialog
                searchType="Certificate"
                criteria={{ ...certificateFiltersState, ...formState }}
            />
            <ExportSearchDialog exportType="Certificate" />
            <BulkDownloadCertificateDialog filteredRecordCount={paginateData.totalRecords} />
            <BulkDeleteCertificateDialog
                isBulkDeleteCertificateDialogOpen={isBulkDeleteCertificateDialogOpen}
                setIsBulkDeleteCertificateDialogOpen={setIsBulkDeleteCertificateDialogOpen}
                totalRecords={paginateData.totalRecords}
                useFilterCriteriaForDelete
            />
        </React.Fragment>
    );
}
export default CertificateGrid;
