import React from "react";
import { SDialog } from "@avalara/skylab-react";

const CannotAddCustomFieldDialog = React.memo(props => {
    const dialogName = "CannotAddCertificateCustomFieldDialog";
    return (
        <SDialog
            id={dialogName}
            open={props.isLimitReachedDialogOpen}
            onS-dismiss={() => props.setIsLimitReachedDialogOpen(false)}
            aria-modal="true"
            role="alertdialog">
            <div slot="header" id="customFieldsLimit">
                Can&apos;t save more custom fields
            </div>
            <div slot="body">
                <div>
                    You have reached the maximum number of custom fields for this company (50).
                    Remove some
                </div>
                <div>custom fields before creating another one.</div>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => props.setIsLimitReachedDialogOpen(false)}>
                    Back
                </button>
            </div>
        </SDialog>
    );
});

export default CannotAddCustomFieldDialog;
