import React from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { STabs } from "@avalara/skylab-react";
import CustomerQuickSearch from "../sharedComponents/CustomerQuickSearch";

const RequestDashboard = React.memo(() => {
    const location = useLocation();
    const locationPath = location.pathname.toString();
    const navigate = useNavigate();

    const tabItems = [
        {
            id: "tabpanel-bulkRequests",
            path: "/requests/bulkRequests",
            label: "Bulk requests",
            tabPanelId: "tabpanel-bulkRequests",
            selected: locationPath.includes("bulkRequests"),
        },
        {
            id: "tabpanel-singleRequests",
            path: "/requests/singleRequests",
            label: "Single requests",
            tabPanelId: "tabpanel-singleRequests",
            selected: locationPath.includes("singleRequests"),
        },
    ];

    const navigateTo = to => {
        const currentHistory = locationPath;
        if (to.includes("bulkRequests") && !currentHistory.includes("bulkRequests")) {
            navigate(`/requests/bulkRequests`);
        } else if (to.includes("singleRequests") && !currentHistory.includes("singleRequests")) {
            navigate(`/requests/singleRequests`);
        }
    };

    return (
        <React.Fragment>
            <div className="dl-search">
                <div className="cx-quick-search">
                    <CustomerQuickSearch />
                </div>
                <h1 className="line-height-md pad-top-md margin-bottom-xl">Certificate requests</h1>
            </div>
            <React.Fragment>
                <STabs tabItems={tabItems} onS-select={e => navigateTo(e.detail.id)} />
                <Outlet />
            </React.Fragment>
        </React.Fragment>
    );
});

export default RequestDashboard;
