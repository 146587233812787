import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SRow, SCol, SIcon, SFiltersPanel, SInputExtended } from "@avalara/skylab-react";
import FilterByCustomer from "../search/Filters/FilterByCustomer";
import FilterByCertificate from "../search/Filters/FilterByCertificate";
import {
    requestSearch,
    selectFormState,
    setFormState,
    clearFormState,
    selectRequestFiltersState,
    setRequestFiltersState,
    clearRequestFiltersState,
    selectCustomerSearchLoading,
    selectCustomerSearchPage,
    setSavedSearch,
    setSavedSearchState,
} from "../../app/requestSlice";
import { nextDays, previousDays } from "../../shared/Utils";
import SelectSavedSearch from "../sharedComponents/SelectSavedSearch";

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const RequestFilter = React.memo(props => {
    const dispatch = useDispatch();
    const requestFiltersState = useSelector(selectRequestFiltersState);
    const [openFilters, setOpenFilters] = useState(true);
    const isLoading = useSelector(selectCustomerSearchLoading);
    const formState = useSelector(selectFormState);
    const page = useSelector(selectCustomerSearchPage);
    const paginateData = page.paginate;
    const { nameOrCode } = formState;
    const selectCustomerRegionsRef = useRef(null);
    const selectCertificateStatusRef = useRef(null);
    const selectCertificateRegionsRef = useRef(null);
    const selectexemptReasonsRef = useRef(null);
    const scrollRef = useRef(null);
    const SelectSavedSearchRef = props.savedSearchRef;
    const executeScroll = () => scrollToRef(scrollRef);
    const applyButton = document.querySelector('button[ref="apply-button"]');
    const resetButton = document.querySelector('button[ref="reset-button"]');
    const currentdate = new Date().toISOString().slice(0, 10);

    const { isCustomerDetails, isCertificateDetails, savedSearch } = requestFiltersState;

    const dismissFilters = () => {
        setOpenFilters(false);
    };

    const showFilters = () => {
        setOpenFilters(!openFilters);
    };

    const applyFilters = useCallback(() => {
        if (!requestFiltersState.certificateIdError) {
            dispatch(requestSearch(paginateData.rowsPerPage, 1));
        }
        executeScroll();
    }, [dispatch, paginateData.rowsPerPage, requestFiltersState.certificateIdError]);

    const resetFilters = useCallback(() => {
        if (SelectSavedSearchRef?.current) SelectSavedSearchRef?.current?.deselectAll();
        dispatch(clearRequestFiltersState());
        dispatch(clearFormState());
        applyFilters();
    }, [applyFilters, dispatch, SelectSavedSearchRef]);

    const updateFilters = useCallback(
        (id, value, isfilter = true) => {
            if (requestFiltersState[id] !== value) {
                dispatch(
                    setRequestFiltersState({
                        [id]: value,
                    })
                );
                if (isfilter) {
                    applyFilters();
                }
            }
        },
        [applyFilters, dispatch, requestFiltersState]
    );

    const handleSearchBoxSubmit = useCallback(() => {
        dispatch(requestSearch());
    }, [dispatch]);

    const clearSearchBox = () => {
        dispatch(
            setFormState({
                ...formState,
                nameOrCode: "",
            })
        );
        handleSearchBoxSubmit();
    };

    const handleInput = el => {
        dispatch(
            setFormState({
                ...formState,
                [el.detail.inputId]: el.detail.value.trim(),
            })
        );
    };

    useEffect(() => {
        let timeout = "";
        if (formState.nameOrCode !== "") {
            timeout = setTimeout(() => {
                handleSearchBoxSubmit();
            }, 400);
        }
        return () => clearTimeout(timeout);
    }, [formState.nameOrCode]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formState.nameOrCodeWhileLoading !== "" && !isLoading) {
            handleSearchBoxSubmit();
        }
    }, [formState.nameOrCodeWhileLoading, handleSearchBoxSubmit, isLoading]);

    useEffect(() => {
        if (applyButton !== null) {
            if (isLoading) {
                applyButton.setAttribute("disabled", "disabled");
                resetButton.setAttribute("disabled", "disabled");
            } else {
                applyButton.removeAttribute("disabled");
                resetButton.removeAttribute("disabled");
            }
        }
    }, [applyButton, isLoading, resetButton]);

    const handlebtnClick = (e, value) => {
        updateFilters(e.target.id, !value, false);
    };

    const handleCheckBoxChange = useCallback(
        async e => {
            if (requestFiltersState[e.target.id] !== e.target.checked) {
                await dispatch(
                    setRequestFiltersState({
                        [e.target.id]: e.target.checked,
                    })
                );
                await applyFilters();
            }
        },
        [dispatch, applyFilters, requestFiltersState]
    );

    const handleChangeInput = useCallback(
        (id, value) => {
            return event => {
                dispatch(
                    setRequestFiltersState({
                        [id]:
                            value ||
                            (event && event.target && event.target.value ? event.target.value : ""),
                    })
                );
            };
        },
        [dispatch]
    );

    const handleMultilineInputChange = useCallback(() => {
        return event => {
            if (event.target.id === "customerCodes") {
                if (
                    event.target.value.split("\n").filter(e => {
                        return e != null;
                    }).length <= 100
                ) {
                    handleChangeInput("customerCodeError", "")();
                } else {
                    // eslint-disable-next-line no-param-reassign
                    event.target.className = "error";
                    handleChangeInput(
                        "customerCodeError",
                        "Only 100 customer codes that can be searched."
                    )();
                }
                handleChangeInput("customerCodes", event.target.value)();
            }
            if (event.target.id === "certificateIds") {
                const validInputs = /(^[0-9\n]+$|^$)/;
                if (
                    event.target.value.split("\n").filter(e => {
                        return e != null;
                    }).length <= 100
                ) {
                    if (event.target.value.match(validInputs)) {
                        event.target.className = ""; // eslint-disable-line
                        handleChangeInput("certificateIdError", "")();
                    } else {
                        event.target.className = "error"; // eslint-disable-line
                        handleChangeInput(
                            "certificateIdError",
                            "Certificate Id should be numeric."
                        )();
                    }
                } else {
                    event.target.className = "error"; // eslint-disable-line no-param-reassign
                    handleChangeInput(
                        "certificateIdError",
                        "Only 100 certificicate Ids that can be searched."
                    )();
                }
                handleChangeInput("certificateIds", event.target.value)();
            }
        };
    }, [handleChangeInput]);

    const handleSavedSearchAdd = optObj => {
        const searchCriteriaObj = JSON.parse(
            optObj.value
                .replace("Last30days", previousDays(30))
                .replace("Next30days", nextDays(30))
                .replace("today", currentdate)
        );
        dispatch(clearRequestFiltersState());
        dispatch(setSavedSearchState(searchCriteriaObj));
        dispatch(
            setFormState({
                ...formState,
                nameOrCode: searchCriteriaObj?.nameOrCode,
            })
        );
        dispatch(setSavedSearch(optObj.label));
        dispatch(requestSearch(paginateData.rowsPerPage, 1));
    };

    const handleSavedSearchRemove = () => {
        dispatch(clearRequestFiltersState());
        dispatch(setSavedSearch(""));
        dispatch(clearFormState());
        dispatch(requestSearch(paginateData.rowsPerPage, 1));
    };
    return (
        <React.Fragment>
            <SRow className="pad-top-md align-items-end">
                <SCol span="xl-8 6">
                    <SRow className="margin-left-none align-items-end">
                        <button type="button" className="secondary" onClick={showFilters}>
                            <SIcon name="filter" className="margin-right-xs" aria-hidden="true" />
                            Filters
                        </button>

                        <SCol span="md-6 xl-6 5" className="pad-bottom-none">
                            <label htmlFor="nameOrCode">
                                Search by customer name or customer code
                            </label>
                            <SInputExtended
                                inputId="nameOrCode"
                                aria-label="CustomerName/Code"
                                placeholder="CustomerName/Code"
                                type="search"
                                onS-input={e => handleInput(e)}
                                onS-search={e => handleSearchBoxSubmit(e)}
                                onS-clear={() => clearSearchBox()}
                                value={nameOrCode}
                            />
                        </SCol>
                    </SRow>
                </SCol>
            </SRow>
            <SFiltersPanel
                open={openFilters}
                id="request-filters-panel"
                onS-dismiss={dismissFilters}
                onS-reset={resetFilters}
                onS-apply={applyFilters}
                className="margin-top-xl margin-right-lg">
                <div slot="filters-panel-content" ref={scrollRef}>
                    <SRow>
                        <SCol span="12" className="pad-bottom-sm">
                            <SelectSavedSearch
                                ref={SelectSavedSearchRef}
                                loading={isLoading}
                                onAdd={e => handleSavedSearchAdd(e)}
                                onRemove={e => handleSavedSearchRemove(e)}
                                value={savedSearch}
                                label="Saved searches"
                                disabled={isLoading}
                                searchType="Customer,Certificate"
                            />
                        </SCol>
                    </SRow>
                    <SRow>
                        <SCol span="12" className="pad-right-md pad-left-md">
                            <div>
                                <button
                                    id="isCertificateDetails"
                                    className="ghost-blue icon-trailing pad-all-none"
                                    onClick={e => handlebtnClick(e, isCertificateDetails)}>
                                    Certificate details
                                    <SIcon
                                        id="isCertificateDetails"
                                        aria-hidden="true"
                                        name={isCertificateDetails ? "chevron-up" : "chevron-down"}
                                    />
                                </button>
                            </div>
                            <FilterByCertificate
                                isLoading={isLoading}
                                updateFilters={updateFilters}
                                handleCheckBoxChange={handleCheckBoxChange}
                                handleMultilineInputChange={handleMultilineInputChange}
                                applyFilters={applyFilters}
                                filterState={requestFiltersState}
                                setFilterState={setRequestFiltersState}
                                ref={{
                                    selectexemptReasonsRef,
                                    selectCertificateStatusRef,
                                    selectCertificateRegionsRef,
                                }}
                                usedBy="CustomerSearch"
                            />
                            <div>
                                <button
                                    id="isCustomerDetails"
                                    className="ghost-blue icon-trailing pad-right-none pad-left-none"
                                    onClick={e => handlebtnClick(e, isCustomerDetails)}>
                                    Customers details
                                    <SIcon
                                        id="isCustomerDetails"
                                        aria-hidden="true"
                                        name={isCustomerDetails ? "chevron-up" : "chevron-down"}
                                    />
                                </button>
                            </div>
                            <FilterByCustomer
                                isLoading={isLoading}
                                updateFilters={updateFilters}
                                handleCheckBoxChange={handleCheckBoxChange}
                                handleMultilineInputChange={handleMultilineInputChange}
                                applyFilters={applyFilters}
                                filterState={requestFiltersState}
                                setFilterState={setRequestFiltersState}
                                ref={selectCustomerRegionsRef}
                                usedBy="CustomerSearch"
                            />
                        </SCol>
                    </SRow>
                </div>
            </SFiltersPanel>
        </React.Fragment>
    );
});

export default RequestFilter;
