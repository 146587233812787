import React, { useState } from "react";
import { SDialog, SIcon, SBox } from "@avalara/skylab-react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import {
    selectIsUploadingFile,
    setIsUploadDialogEllipsesMenuOpen,
} from "../../app/certificateSlice";

import Upgrade from "../upgradeTier/OnlineBuyDialog";
import { selectSession } from "../../app/sessionSlice";
import FileUploaderDialog from "./FileUploaderDialog";

const UploadCertificate = React.forwardRef((props, ref) => {
    const [isReplaceDialogOpen, setIsReplaceDialogOpen] = useState(false);
    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
    const dialogName = "uploadDocumentDialog";
    const dispatch = useDispatch();
    const session = useSelector(selectSession);
    const isUploadingFile = useSelector(selectIsUploadingFile);
    const uploadButton = classnames({ primary: true, small: true, loading: isUploadingFile });

    const openFVSDialog = () => {
        setIsReplaceDialogOpen(false);
        dispatch(setIsUploadDialogEllipsesMenuOpen(true));
    };

    const uploadOnClick = event => {
        event.preventDefault();
        if (session.baseSubscription) {
            setIsUpgradeDialogOpen(true);
        } else if (props.isOverride) {
            setIsReplaceDialogOpen(true);
        } else {
            openFVSDialog();
        }
    };

    const closeUploadCertificateDialog = event => {
        if (event.detail.id === dialogName) setIsReplaceDialogOpen(false);
    };

    return (
        <React.Fragment>
            <button
                className={props.class}
                onClick={e => uploadOnClick(e)}
                ref={ref}
                style={{
                    display: props.isButtonHidden ? "none" : "block",
                }}>
                <SIcon name="upload" aria-hidden="true" />
                <span className="margin-left-xs">Upload a certificate</span>
            </button>
            <FileUploaderDialog
                certificateID={props.certificateID}
                title={`${isReplaceDialogOpen ? `Replace` : `Upload`} a certificate`}
                isUploaderDisabled={props.isUploaderDisabled}
                showRevokedAlert={props?.showRevokedAlert}
            />
            <Upgrade
                isUpgradeDialogOpen={isUpgradeDialogOpen}
                setIsUpgradeDialogOpen={setIsUpgradeDialogOpen}
                type="upload"
            />
            <SDialog
                id={dialogName}
                open={isReplaceDialogOpen}
                onS-dismiss={e => {
                    closeUploadCertificateDialog(e);
                }}
                aria-modal="true">
                <div slot="header" id="replaceCertificate">
                    Replace this certificate?
                </div>
                <div slot="body">
                    <SBox className="yellow">
                        <table className="borderless" role="presentation">
                            <tbody>
                                <tr>
                                    <td>
                                        <SIcon name="flag" className="medium" aria-hidden="true" />
                                    </td>
                                    <td>
                                        This replaces the certificate you previously uploaded. Are
                                        you sure you
                                        <br />
                                        <span> want to do this? </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </SBox>
                </div>
                <div slot="footer">
                    <button
                        className="secondary small"
                        disabled={isUploadingFile}
                        onClick={() => setIsReplaceDialogOpen(false)}>
                        No, cancel
                    </button>
                    <button className={uploadButton} type="button" onClick={openFVSDialog}>
                        Yes, replace
                    </button>
                </div>
            </SDialog>
        </React.Fragment>
    );
});

export default UploadCertificate;
