import React, { useState, useEffect } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { SLoader, SPagination, SIcon } from "@avalara/skylab-react";
import parse from "html-react-parser";
import { upperFirst } from "lodash";
import {
    selectPage,
    setSortColumn,
    selectSortColumn,
    fetchCustomerHistoryAPI,
    selectIsLoading,
} from "../../../app/customerHistorySlice";
import { selectSession, updateRowCountPreference } from "../../../app/sessionSlice";
import { getRowsPerPage } from "../../../shared/Utils";

function CustomerHistoryGrid(props) {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const loading = useSelector(selectIsLoading);
    const page = useSelector(selectPage);
    const pageData = page.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectSortColumn);
    const entityType = props.customer.isVendor ? "vendor" : "customer";
    const session = useSelector(selectSession, shallowEqual);

    const parseLogData = (oldValue, newValue, field, logType) => {
        let [finalLogRow, text, link, icon, pText] = "";
        switch (logType) {
            case "History":
                switch (field) {
                    case "Billing Customer":
                    case "Shipping Customer":
                    case "SameAs Customer":
                        link = oldValue ? oldValue.split(":") : newValue.split(":");
                        text = oldValue ? `Removed: ${link[1]}` : `Linked: ${link[1]}`;
                        icon = "user-circle-filled";
                        pText = parse(
                            `${field}: <a href='/${entityType}/${link[0]}'> ${text} </a>`
                        );
                        break;
                    case "Certificate":
                        link = oldValue ? `/certificate/${oldValue}` : `/certificate/${newValue}`;
                        text = oldValue ? `Removed ID: ${oldValue}` : `Added ID: ${newValue}`;
                        icon = "certificate";
                        pText = parse(`${field}: <a href='${link}'>${text}</a>`);
                        break;
                    case "Created":
                        text = `New ${upperFirst(entityType)} Record`;
                        icon = "user-circle-filled";
                        pText = `${field}: ${text}`;
                        break;
                    default:
                        if (oldValue && newValue) {
                            text = `${oldValue} &rarr; ${newValue}`;
                            icon = "edit-pencil";
                        } else if (oldValue) {
                            text = `Removed: ${oldValue}`;
                            icon = "indeterminate-circle";
                        } else if (newValue) {
                            text = `Added: ${newValue}`;
                            icon = "plus-circle";
                        }
                        if (oldValue && newValue) {
                            text = `${oldValue} &rarr; ${newValue}`;
                            icon = "edit-pencil";
                        } else if (!oldValue && !newValue) {
                            icon = "circle-info";
                            text = "Missing or invalid history data";
                        }
                        pText = `${field}: ${parse(text)}`;
                        break;
                }
                break;
            case "Request":
                icon = "email";
                pText = parse(newValue.slice(0, newValue.indexOf("<a")));
                break;
            default:
                icon = "chat";
                pText = newValue;
                break;
        }

        finalLogRow = (
            <span>
                <SIcon name={icon} className="margin-right-xs" aria-hidden="true" />
                {pText}
            </span>
        );
        return finalLogRow;
    };
    useEffect(() => {
        dispatch(fetchCustomerHistoryAPI(props.customer.id));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    async function sortFunction(e) {
        setSubmitting(true);
        const columnId = e.detail.id;
        const order = sortColumn[0] === columnId ? !sortColumn[1] : true;
        dispatch(setSortColumn([columnId, order]));
        await dispatch(
            fetchCustomerHistoryAPI(
                props.customer.id,
                null,
                e.detail.rowsPerPage,
                e.detail.startIndex,
                false,
                e.detail
            )
        );
        setSubmitting(false);
    }

    const columns = [
        gridHeader(
            "Date and time",
            "created",
            value =>
                `${new Date(value).toLocaleDateString()} ${new Date(value).toLocaleTimeString()}`,
            null,
            "",
            null,
            null,
            sortFunction,
            "created"
        ),
        gridHeader("By", "account", (value, row) => row.account, null),
        gridHeader(
            "Activity",
            "activtiy",
            (value, row) => parseLogData(row.oldValue, row.newValue, row.field, row.logType),
            null
        ),
    ];

    const getRowKey = row => {
        return `${row.field}-${row.id}-${row.logType}`;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            await dispatch(
                fetchCustomerHistoryAPI(
                    props.customer.id,
                    null,
                    e.detail.rowsPerPage,
                    e.detail.startIndex,
                    false,
                    e.detail
                )
            );
            setSubmitting(false);
        }
    };

    let data = null;
    if (pageData.pageNumber !== 0) {
        data = loading ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <s-table-container class="margin-top-xl">
                <DataGrid
                    columns={columns}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={submitting}
                    inContainer
                    sortColumn={sortColumn}
                />
            </s-table-container>
        );
    }

    return (
        <React.Fragment>
            {data}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}
        </React.Fragment>
    );
}
export default CustomerHistoryGrid;
