import React, { useState, useRef, useEffect, useCallback } from "react";
import { SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import Customer from "../customers/Customer";
import axios from "../../axios";
import toast from "../../hooks/toast";
import { buildApiV2Point5Url } from "../../shared/Utils";

function EditCustomeDialog(props) {
    const customerSaveRef = useRef(null);
    const [submitting, setSubmitting] = useState(false);
    const [saveClicked, setSaveClicked] = useState(false);
    const [nextClicked, setNextClicked] = useState(false);
    const [backClicked, setBackClicked] = useState(false);
    const [count, setCount] = useState(1);
    const [updatedCustomers, setUpdatedCustomers] = useState([]);
    const [customerDetail, setCustomerDetail] = useState({});
    const [showNext, setshowNext] = useState(false);
    const [showBack, setshowBack] = useState(false);
    const [showSave, setshowSave] = useState(false);
    const [lastIndex, setlastIndex] = useState(false);

    const [showToast] = toast();

    const saveClassName = classnames({ primary: true, small: true, loading: submitting });

    useEffect(() => {
        setNextClicked(false);
    }, [nextClicked]); // eslint-disable-line

    useEffect(() => {
        setSaveClicked(false);
    }, [saveClicked]); // eslint-disable-line

    useEffect(() => {
        setBackClicked(false);
    }, [backClicked]); // eslint-disable-line

    const setInitValue = useCallback(() => {
        if (props?.customers?.length > 0) {
            if (props?.customers?.length === 1) {
                setshowNext(false);
                setshowBack(false);
                setlastIndex(true);
            } else {
                setshowNext(true);
                setshowBack(false);
                setshowSave(false);
                setCount(1);
            }
            const customerInfo = props?.customers?.slice(0, 1)[0];
            setCustomerDetail(customerInfo);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props?.customers?.length > 0) {
            setInitValue();
        }
    }, [props?.customers?.length]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setshowSave(false);
        if (lastIndex) {
            const resultList = updatedCustomers.filter(item => item.customerUpdated);
            if (resultList.length > 0) {
                setshowSave(true);
            }
        }
    }, [updatedCustomers, lastIndex]);

    const updateCustomerData = useCallback(
        info => {
            const customerobj = info;
            const prevCustomerIds = updatedCustomers.map(ele => ele.id);
            if (prevCustomerIds.indexOf(customerobj.id) === -1) {
                setUpdatedCustomers([...updatedCustomers, customerobj]);
            } else {
                const targetIndex = updatedCustomers.findIndex(f => f.id === customerobj.id);
                if (targetIndex >= 0) {
                    customerobj.customerUpdated = updatedCustomers[targetIndex]?.customerUpdated;
                    updatedCustomers[targetIndex] = customerobj;
                }
            }
        },
        [updatedCustomers]
    );

    const nextStep = useCallback(
        info => {
            if (info) {
                updateCustomerData(info);
                setlastIndex(false);
                if (props?.customers?.length > 0 && props?.customers?.length >= count) {
                    setshowNext(true);
                    setshowBack(true);
                    const nextCount = count + 1;
                    let customerinfo = props?.customers?.slice(nextCount - 1, nextCount)?.[0];
                    if (customerinfo) {
                        const updatedinfo = updatedCustomers.filter(
                            x => x.id === customerinfo.id
                        )?.[0];
                        if (updatedinfo) {
                            customerinfo = updatedinfo;
                        }
                        setCustomerDetail(customerinfo);
                    }
                    if (props?.customers?.length > count) {
                        setCount(nextCount);
                    }
                    if (props?.customers?.length === nextCount) {
                        setshowNext(false);
                        setshowBack(true);
                        setshowSave(false);
                        setlastIndex(true);
                    }
                }
            }
        },
        [props?.customers, count, setCustomerDetail, updatedCustomers, updateCustomerData]
    );

    const backStep = useCallback(
        info => {
            setlastIndex(false);
            if (props?.customers?.length > 0 && count > 1) {
                updateCustomerData(info);
                setshowBack(true);
                setshowNext(true);
                setshowSave(false);
                const backCount = count - 1;
                const customerinfo = updatedCustomers?.slice(count - 2, backCount)?.[0];
                setCustomerDetail(customerinfo);
                setCount(backCount);
                if (backCount === 1) {
                    setshowBack(false);
                }
            }
        },
        [
            props?.customers,
            count,
            updatedCustomers,
            setCustomerDetail,
            setshowSave,
            updateCustomerData,
        ]
    );

    const saveStep = useCallback(
        async info => {
            setSubmitting(true);
            let customersaved = false;
            // Find Duplicates
            const prevCustomerIds = updatedCustomers.map(ele => ele.id);
            let customerList = updatedCustomers;
            if (prevCustomerIds.indexOf(info.id) === -1) {
                customerList = [...updatedCustomers, info];
                setUpdatedCustomers([...updatedCustomers, info]);
            }
            const resultList = customerList.filter(item => item.customerUpdated);
            if (customerList?.length > 0 && resultList?.length > 0) {
                const promises = [];

                resultList.forEach(customerData => {
                    promises.push(
                        axios.put(
                            buildApiV2Point5Url(`customers/${customerData.id}`),
                            customerData,
                            {
                                withCredentials: true,
                            }
                        )
                    );
                });
                if (promises.length > 0) {
                    await Promise.allSettled(promises).then(results => {
                        if (results) {
                            customersaved = true;
                        }
                    });
                }
            }
            setSubmitting(false);
            // update customers in the optionlist
            props?.onEditUpdateCustomers(resultList);
            props?.setCustomerDialogOpen(false);
            props?.setIsCustomerDataMissing(false);
            if (customersaved) {
                showToast("success", "Successfully updated customer.", "customer-success");
            }
        },
        [updatedCustomers] // eslint-disable-line
    );

    return (
        <SDialog
            open={props.customerDialogOpen}
            id="customer-dialog"
            onS-dismiss={() => props.setCustomerDialogOpen(false)}
            aria-modal="true">
            <div slot="header" id="addCustomer">
                Add customer information
            </div>
            <div slot="body">
                <Customer
                    ref={customerSaveRef}
                    setshowSave={setshowSave}
                    showSave={showSave}
                    lastIndex={lastIndex}
                    setSubmitting={setSubmitting}
                    isDisplayedAsDialogMulti={props?.isDisplayedAsDialogMulti}
                    customerInfo={customerDetail}
                    nextStep={nextStep}
                    backStep={backStep}
                    saveStep={saveStep}
                    saveClicked={saveClicked}
                    nextClicked={nextClicked}
                    backClicked={backClicked}
                />
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => {
                        setSaveClicked(false);
                        setNextClicked(false);
                        setBackClicked(false);
                        props.setCustomerDialogOpen(false);
                    }}>
                    Cancel
                </button>
                {showBack && (
                    <button
                        className={saveClassName}
                        onClick={() => {
                            setSaveClicked(false);
                            setNextClicked(false);
                            setBackClicked(true);
                        }}>
                        Back
                    </button>
                )}
                {showSave && (
                    <button
                        className={saveClassName}
                        onClick={() => {
                            setSaveClicked(true);
                            setNextClicked(false);
                            setBackClicked(false);
                        }}>
                        Save
                    </button>
                )}
                {showNext && (
                    <button
                        className={saveClassName}
                        onClick={() => {
                            setSaveClicked(false);
                            setNextClicked(true);
                            setBackClicked(false);
                        }}>
                        Next
                    </button>
                )}
            </div>
        </SDialog>
    );
}

export default EditCustomeDialog;
