import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import toast from "../hooks/toast";
import { buildApiV3Url } from "../shared/Utils";

const initialState = {
    isLoading: false,
    expirationDate: null,
    addLabelsList: [],
    removeLabelsList: [],
    addInvalidReasonsList: [],
    removeInvalidReasonsList: [],
    selectedField: "",
    certificateIds: [],
};

export const bulkUpdateSlice = createSlice({
    name: "bulkUpdate",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setExpirationDate: (state, action) => {
            state.expirationDate = action.payload;
        },
        setAddLabelsList: (state, action) => {
            state.addLabelsList = action.payload;
        },
        setRemoveLabelsList: (state, action) => {
            state.removeLabelsList = action.payload;
        },
        setAddInvalidReasonsList: (state, action) => {
            state.addInvalidReasonsList = action.payload;
        },
        setRemoveInvalidReasonsList: (state, action) => {
            state.removeInvalidReasonsList = action.payload;
        },
        setSelectedField: (state, action) => {
            state.selectedField = action.payload;
        },
        setCertificateIds: (state, action) => {
            state.certificateIds = action.payload;
        },
    },
});
export const {
    setIsLoading,
    setExpirationDate,
    setAddLabelsList,
    setRemoveLabelsList,
    setAddInvalidReasonsList,
    setRemoveInvalidReasonsList,
    setSelectedField,
    setCertificateIds,
} = bulkUpdateSlice.actions;

export const selectIsLoading = state => {
    return state.bulkUpdate.isLoading;
};
export const selectExpirationDate = state => {
    return state.bulkUpdate.expirationDate;
};
export const selectAddLabelsList = state => {
    return state.bulkUpdate.addLabelsList;
};
export const selectRemoveLabelsList = state => {
    return state.bulkUpdate.removeLabelsList;
};
export const selectAddInvalidReasonsList = state => {
    return state.bulkUpdate.addInvalidReasonsList;
};
export const selectRemoveInvalidReasonsList = state => {
    return state.bulkUpdate.removeInvalidReasonsList;
};
export const selectSelectedField = state => {
    return state.bulkUpdate.selectedField;
};
export const selectCertificateIds = state => {
    return state.bulkUpdate.certificateIds;
};

export const getSelectedCertificateIds = () => (dispatch, getState) => {
    const { certificateIds } = getState().bulkUpdate;
    return certificateIds;
};

const [showToast] = toast();
export const putBulkUpdateCertificatesExpirationDate =
    (certificateIds, expirationDate) => async (dispatch, getState) => {
        const { isLoading } = getState().bulkUpdate;
        if (isLoading) return;
        dispatch(setIsLoading(true));
        const bulkUpdateJson = {
            certificateIds,
            expirationDate,
        };
        const response = await axios.put(
            buildApiV3Url(`certificate-bulk/expiration-date`),
            bulkUpdateJson,
            { withCredentials: true }
        );
        if (response?.status === 200 && response?.data) {
            const passedIdsLength = response?.data?.passedCertificates?.length;
            const failedIdsLength = response?.data?.failedCertificates?.length;
            if (failedIdsLength) {
                showToast(
                    "warning",
                    `We updated ${passedIdsLength} out of ${certificateIds.length} certificates. ${failedIdsLength} couldn't be changed.`
                );
            } else {
                showToast("success", `You updated ${passedIdsLength} certificates.`);
            }
        }
        dispatch(setIsLoading(false));
    };

export const putBulkUpdateCertificatesLabels =
    (certificateIds, addCertificateCodeIds, removeCertificateCodeIds) =>
    async (dispatch, getState) => {
        const { isLoading } = getState().bulkUpdate;
        if (isLoading) return;
        dispatch(setIsLoading(true));
        const bulkUpdateJson = {
            certificateIds,
            addCertificateCodeIds,
            removeCertificateCodeIds,
        };
        const response = await axios.put(buildApiV3Url(`certificate-bulk/labels`), bulkUpdateJson, {
            withCredentials: true,
        });
        if (response?.status === 200 && response?.data) {
            const passedIdsLength = response?.data?.passedCertificates?.length;
            const failedIdsLength = response?.data?.failedCertificates?.length;
            if (failedIdsLength) {
                showToast(
                    "warning",
                    `We updated ${passedIdsLength} out of ${certificateIds.length} certificates. ${failedIdsLength} couldn't be changed.`
                );
            } else {
                showToast("success", `You updated ${passedIdsLength} certificates.`);
            }
        }
        dispatch(setIsLoading(false));
    };

export const putBulkUpdateCertificatesInvalidReasons =
    (certificateIds, addInvalidReasonIds, removeInvalidReasonIds) => async (dispatch, getState) => {
        const { isLoading } = getState().bulkUpdate;
        if (isLoading) return;
        dispatch(setIsLoading(true));
        const bulkUpdateJson = {
            certificateIds,
            addInvalidReasonIds,
            removeInvalidReasonIds,
        };
        const response = await axios.put(
            buildApiV3Url(`certificate-bulk/invalid-reasons`),
            bulkUpdateJson,
            { withCredentials: true }
        );
        if (response?.status === 200 && response?.data) {
            const passedIdsLength = response?.data?.passedCertificates?.length;
            const failedIdsLength = response?.data?.failedCertificates?.length;
            if (failedIdsLength) {
                showToast(
                    "warning",
                    `We updated ${passedIdsLength} out of ${certificateIds.length} certificates. ${failedIdsLength} couldn't be changed.`
                );
            } else {
                showToast("success", `You updated ${passedIdsLength} certificates.`);
            }
        }

        dispatch(setIsLoading(false));
    };

export default bulkUpdateSlice.reducer;
