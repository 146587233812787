import { createSlice } from "@reduxjs/toolkit";
import axiosInstanceWithoutInterceptor from "../axiosWithoutInterceptor";
import getConfig from "../config";

const { hostnames } = getConfig();
const { ecmApiHost } = hostnames;

const initialState = {
    data: "awaiting",
    isLoading: false,
};

export const healthCheckSlice = createSlice({
    name: "healthCheck",
    initialState,

    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setHealthData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setIsLoading, setHealthData } = healthCheckSlice.actions;

export const selectIsLoading = state => {
    return state.healthCheck.isLoading;
};

export const selectData = state => {
    return state.healthCheck.data;
};

export const getHealthStatus = () => async (dispatch, getState) => {
    if (getState().healthCheck.isLoading) return;
    dispatch(setIsLoading(true));
    const response = await axiosInstanceWithoutInterceptor.get(`//${ecmApiHost}/orangez/health`);
    dispatch(setHealthData(response.data));
    dispatch(setIsLoading(false));
};

export default healthCheckSlice.reducer;
