import { SCol, SRow } from "@avalara/skylab-react";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchRetailCustomers, resetCustomerFiltersState } from "../../../app/retailSlice";
import { urlToPageMap } from "../../../shared/Utils";
import featureFlag from "../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../shared/sessionUtility";

function SearchFooter() {
    const searchClassName = "primary large max-width-buttton icon-leading";
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isUserEligibleToCreateCustomer = dispatch(
        isEligibleUser(featureFlag.retail.editCustomer)
    );
    return (
        <>
            <SRow class="margin-top-sm">
                <SCol span="lg-5 xl-5 12">
                    <button
                        type="submit"
                        className={searchClassName}
                        onClick={() => navigate(urlToPageMap.retail)}>
                        New Search &nbsp;
                        <s-icon
                            name="chevron-right"
                            className=""
                            aria-hidden="true"
                            aria-label="chevron-right"
                        />
                    </button>
                </SCol>
            </SRow>
            <SRow class="margin-top-sm">
                <SCol span="md-6 lg-3 xl-3 12">
                    <button
                        type="button"
                        className="secondary large max-width-buttton icon-leading pad-all-none"
                        onClick={() => {
                            navigate(
                                `${urlToPageMap.retail}/${urlToPageMap.retailCustomer.replace(
                                    "/:id?",
                                    ""
                                )}`
                            );
                        }}
                        disabled={!isUserEligibleToCreateCustomer}>
                        <s-icon name="plus" aria-label="plus" aria-hidden="true" />
                        New Exemption
                    </button>
                </SCol>
                <SCol span="md-6 lg-2 xl-2 12">
                    <button
                        type="button"
                        className="tertiary large max-width-buttton flex align-items-center justify-content-center"
                        onClick={() => {
                            dispatch(resetCustomerFiltersState());
                            dispatch(fetchRetailCustomers());
                        }}>
                        Clear/reset
                    </button>
                </SCol>
            </SRow>
        </>
    );
}

export default SearchFooter;
