import { SBox, SLoader } from "@avalara/skylab-react";
import React, { useCallback, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector, useDispatch } from "react-redux";
import {
    selectValidationData,
    getValidationFile,
    selectIsUploadingFile,
    selectIsLoadingValidationData,
    selectHasFileUnderValidation,
    selectHasCertificateFile,
} from "../../app/certificateSlice";
import CertificateImage from "../sharedComponents/CertificateImage";
import CertificateFileNotFound from "./CertificateFileNotFound";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

function ValidationFileRender(props) {
    const dispatch = useDispatch();
    const [numberPages, setNumberPages] = useState(null);
    const validationData = useSelector(selectValidationData);
    const [fileType, setFileType] = useState(0);
    const isUploadingFile = useSelector(selectIsUploadingFile);
    const isLoadingValidationData = useSelector(selectIsLoadingValidationData);
    const hasFileUnderValidation = useSelector(selectHasFileUnderValidation);
    const hasCertificateFile = useSelector(selectHasCertificateFile);

    useEffect(() => {
        if (!validationData.data && props.certificateID && !isUploadingFile) {
            dispatch(getValidationFile(props.certificateID));
        }
    }, [props.certificateID, validationData, dispatch, isUploadingFile]);

    useEffect(() => {
        const pdfTypeString = "data:application/pdf;base64";
        const imageStrings = [
            "data:image/jpeg;base64",
            "data:image/png;base64",
            "data:image/gif;base64",
            "data:image/tiff;base64",
        ];

        if (validationData.data) {
            if (validationData.fileType === pdfTypeString) {
                setFileType(1);
            } else if (imageStrings.includes(validationData.fileType)) {
                setFileType(2);
            } else {
                setFileType(0);
            }
        }
    }, [validationData]);

    const onDocumentLoadSuccess = useCallback(({ numPages }) => {
        setNumberPages(numPages);
        props.PdfPageCount(numPages);
    }, []);

    const fileLoader = (
        <SBox className="no-border">
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        </SBox>
    );

    let viewerDOM = null;
    if (isLoadingValidationData || isUploadingFile) {
        viewerDOM = fileLoader;
    } else if (hasCertificateFile && fileType === 1) {
        viewerDOM = (
            <Document
                file={validationData.data}
                loading={fileLoader}
                noData={fileLoader}
                onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numberPages), (el, index) => (
                    <Page
                        className="pdf-border"
                        key={`page_${index + 1}`}
                        scale={props.scale ? props.scale : 1.6}
                        pageNumber={index + 1}
                        renderAnnotationLayer={false}
                    />
                ))}
            </Document>
        );
    } else if (hasCertificateFile && fileType === 2) {
        viewerDOM = (
            <CertificateImage
                className={`${props?.rotation || ""} image-border pad-all-md`}
                certificateID={props.certificateID}
                poll={props.poll}
                src={validationData.data}
                enableMagnifier={props.enableMagnifier}
                width="100%"
                previewEnabled={props.previewEnabled}
            />
        );
    } else if (hasFileUnderValidation) {
        viewerDOM = (
            <SBox className="gray-box font-bold flex dl-flex-fill-height dl-flex-center">
                Formatting your file...this may take few moments.
                <br />
                <span className="pad-top-none margin-top-none padding-left-sm">
                    Refresh the page to view your certificate.
                </span>
            </SBox>
        );
    } else {
        viewerDOM = (
            <CertificateFileNotFound
                certificateID={props.certificateID}
                isOutgoing={props.isOutgoing}
                isUploaderDisabled={props.isUploaderDisabled}
                showRevokedAlert={props.showRevokedAlert}
            />
        );
    }

    return viewerDOM;
}

export default ValidationFileRender;
