import React, { useEffect, useState } from "react";
import { SRow, SCol, SSelect } from "@avalara/skylab-react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    fetchClientProductCategories,
    selectClientProductCategories,
    fetchCertificateProductCategories,
    selectCertificateProductCategories,
} from "../../app/productCategoriesSlice";

const CertificateProductCategories = React.memo(props => {
    const [enabledInput, enableInput] = useState(false);
    const dispatch = useDispatch();
    const enabledCategories = useSelector(selectCertificateProductCategories, shallowEqual);
    const pulledCategories = useSelector(selectClientProductCategories, shallowEqual);
    const [clearAll, setClearAll] = useState(false);
    const [availableCategories, setAvailableCategories] = useState([]);

    useEffect(() => {
        setAvailableCategories(
            pulledCategories
                ? pulledCategories.map(elm => {
                      const selectOption = {
                          label: elm.category,
                          value: elm.category,
                          selected: false,
                      };

                      if (
                          props.newCategories?.includes(elm.category) ||
                          (enabledCategories?.includes(elm.category) &&
                              !props.deletedCategory?.includes(elm.category) &&
                              !clearAll)
                      ) {
                          selectOption.selected = true;
                      }
                      return selectOption;
                  })
                : null
        );
    }, [pulledCategories, enabledCategories, props.newCategories, clearAll, props.deletedCategory]);

    useEffect(() => {
        dispatch(fetchClientProductCategories());
        if (props.certificateID) {
            dispatch(fetchCertificateProductCategories(props.certificateID));
        }
    }, [dispatch]);

    const handleCheckBoxChange = () => {
        if (enabledInput) {
            enableInput(false);
        } else {
            enableInput(true);
        }
    };

    useEffect(() => {
        if (enabledCategories.length > 0) {
            enableInput(true);
        } else {
            enableInput(false);
        }
    }, [enabledCategories]);

    return (
        <React.Fragment>
            <SRow hidden={!pulledCategories}>
                <SCol>
                    <fieldset role="group" aria-labelledby="categories">
                        <input
                            id="categories"
                            name="categories"
                            type="checkbox"
                            aria-label="Limit this exemption to certain product categories"
                            onChange={handleCheckBoxChange}
                            checked={enabledInput}
                        />
                        <label htmlFor="categories">
                            Limit this exemption to certain product categories
                        </label>
                    </fieldset>
                </SCol>
            </SRow>
            {enabledInput ? (
                <SRow>
                    <SCol className="margin-left-md no-wrap">
                        <label> Product categories</label>
                        <SSelect
                            optionsList={availableCategories}
                            selectionOptional
                            multiple
                            onS-select={e => {
                                props.addCategory(e.detail.item.value);
                            }}
                            onS-deselect={e => {
                                if (e.detail.isDeselectAll) {
                                    setClearAll(true);
                                    const delCategory = [];
                                    availableCategories.forEach(xmp => {
                                        if (xmp.selected === true) {
                                            delCategory.push(xmp.label);
                                        }
                                    });
                                    props.allCategory(delCategory);
                                } else {
                                    props.deleteCategory(e.detail.item.value);
                                }
                            }}
                        />
                    </SCol>
                </SRow>
            ) : null}
        </React.Fragment>
    );
});

export default CertificateProductCategories;
