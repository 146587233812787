import React from "react";
import { SSelect } from "@avalara/skylab-react";

const SelectYesNo = React.memo(props => {
    const yesNoOptions = [
        {
            label: "Yes",
            value: "1",
        },
        {
            label: "No",
            value: "2",
        },
    ];

    const yesNoList = yesNoOptions.map(element => {
        const selectOption = {
            label: element.label,
            value: element.value,
            selected: false,
        };
        if (props?.value === element.value) {
            selectOption.selected = true;
        }
        return selectOption;
    });

    return (
        <React.Fragment>
            {props.required ? (
                <label htmlFor={props?.id} className="required" id={`lbl-${props?.id}`}>
                    {props?.label}
                </label>
            ) : (
                <label htmlFor={props?.id} id={`lbl-${props?.id}`}>
                    {props?.label}
                </label>
            )}
            <SSelect
                inputId={props?.id}
                name={props?.name}
                optionsList={yesNoList}
                onS-select={e => props.onAdd(e.detail.item.value)}
                onS-deselect={e => props.onRemove(e.detail.item)}
                nosearch
                selectionOptional
                value={props?.value}
                disabled={props?.disabled ? "disabled" : null}
            />
        </React.Fragment>
    );
});

export default SelectYesNo;
