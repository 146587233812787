import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SLoader, SPagination, SIcon } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import {
    fetchCustomerLabels,
    selectPage,
    selectIsLoading,
    setPageToNull,
    selectSortColumn,
    setSortColumn,
} from "../../../../app/customerLabelSlice";
import AddEditCustomerLabelsDialog from "./AddEditCustomerLabelsDialog";
import DeleteCustomerLabelDialog from "./DeleteCustomerLabel";
import FeatureToggler from "../../../../featureToggler/FeatureToggler";
import featureFlag from "../../../../featureToggler/featureFlag";
import { isEligibleUser } from "../../../../shared/sessionUtility";
import { selectSession, updateRowCountPreference } from "../../../../app/sessionSlice";
import { getRowsPerPage } from "../../../../shared/Utils";

function CustomerLabelsGrid(props) {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const session = useSelector(selectSession, shallowEqual);
    const page = useSelector(selectPage, shallowEqual);
    const gridData = page.data;
    const pageData = page.paginate;
    const isLoading = useSelector(selectIsLoading);
    const sortColumn = useSelector(selectSortColumn);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [customerLabel, setCustomerLabel] = useState({});
    const isEligibleUserEditCustomerLabels = !dispatch(
        isEligibleUser(featureFlag.settings.editLabel)
    );
    const isEligibleUserDeleteCustomerLabels = !dispatch(
        isEligibleUser(featureFlag.settings.deleteLabel)
    );

    useEffect(() => {
        dispatch(fetchCustomerLabels());
        return () => {
            dispatch(setPageToNull([]));
        };
    }, [dispatch]);

    async function sortFunction(e) {
        setSubmitting(true);
        const column = e.detail.id;
        const order = sortColumn[0] === column ? !sortColumn[1] : true;
        dispatch(setSortColumn([column, order]));
        await dispatch(fetchCustomerLabels(session.rowsPerPage, null));
        setSubmitting(false);
    }

    const columns = React.useMemo(
        () => [
            gridHeader("Name", "name", value => value, null, "", null, null, sortFunction, "name"),
            gridHeader("Description", "description", value => value),
            gridHeader(
                "Exclude from requests",
                "nonDeliver",
                value => value && <SIcon name="check" aria-hidden="true" />
            ),
            gridHeader(
                "",
                "",
                (value, row) => (
                    <FeatureToggler category="settings" id="editLabel">
                        <button
                            className="link font-bold"
                            onClick={() => {
                                props.setAddOrEdit("Edit");
                                setCustomerLabel(row);
                                props.setOpenAddUpdateDialog(true);
                            }}
                            disabled={row.systemCode || isEligibleUserEditCustomerLabels}>
                            Edit
                        </button>
                    </FeatureToggler>
                ),
                "text-center",
                "text-center"
            ),
            gridHeader(
                "",
                "inUse",
                (value, row) => (
                    <FeatureToggler category="settings" id="deleteLabel">
                        <button
                            className="link font-bold"
                            onClick={() => {
                                setCustomerLabel(row);
                                setIsDeleteDialogOpen(true);
                            }}
                            disabled={row.systemCode || isEligibleUserDeleteCustomerLabels}>
                            Delete
                        </button>
                    </FeatureToggler>
                ),
                "text-center",
                "text-center"
            ),
        ],
        [props, sortColumn]
    );

    const getRowKey = React.useCallback(row => row.id, []);

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && pageData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            dispatch(fetchCustomerLabels(e.detail.rowsPerPage, e.detail.startIndex, e.detail));
            setSubmitting(false);
        }
    };

    const table =
        isLoading || submitting ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <>
                <div className="margin-bottom-xs text-right">
                    {pageData.totalRecords} labels for customers
                </div>
                <span className="clear" />
                <s-table-container>
                    <DataGrid
                        columns={columns}
                        rows={gridData}
                        loading={isLoading}
                        getKey={getRowKey}
                        inContainer
                        sortColumn={sortColumn}
                    />
                </s-table-container>
            </>
        );

    return !isLoading && pageData.totalRecords === 0 ? (
        <div className="text-center flex dl-flex-fill-height dl-flex-center">
            <h1 id="no-labels-found" className="text-color-no-data">
                No labels found
            </h1>
        </div>
    ) : (
        <>
            {table}
            {pageData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={getRowsPerPage(pageData, session.rowsPerPage)}
                    totalRecords={pageData.totalRecords}
                    startIndex={pageData.startIndex}
                    onS-paginate={handlePaginate}
                />
            ) : null}

            {isDeleteDialogOpen ? (
                <DeleteCustomerLabelDialog
                    isDeleteDialogOpen
                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                    customerLabel={customerLabel}
                />
            ) : null}
            {props.openAddUpdateDialog ? (
                <AddEditCustomerLabelsDialog
                    addOrEdit={props.addOrEdit}
                    customerLabel={customerLabel}
                    openAddUpdateDialog={props.openAddUpdateDialog}
                    setOpenAddUpdateDialog={props.setOpenAddUpdateDialog}
                />
            ) : null}
        </>
    );
}

export default CustomerLabelsGrid;
