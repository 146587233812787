import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { checkSelectComponentProps } from "../../shared/Utils";
import {
    getCertificateStatusesAsync,
    selectIsLoadingCertificateStatuses,
} from "../../app/certificateSlice";
import featureFlag from "../../featureToggler/featureFlag";
import { isEligibleUser } from "../../shared/sessionUtility";

function SelectCertificateStatus(props, ref) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingCertificateStatuses);
    checkSelectComponentProps("SelectCertificateStatus", props);

    const isEligibleUserValidatedCertificateShowInvalidAsOption = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.showInvalidAsOption, true)
    );
    let optionsList = [];
    if (typeof props.usedBy !== "undefined") {
        optionsList = [
            {
                label: "Valid",
                value: "Valid",
                selected: false,
            },
            {
                label: "Invalid",
                value: "Invalid",
                selected: false,
            },
            {
                label: "Expired",
                value: "Expired",
                selected: false,
            },
            {
                label: "Paused",
                value: "Paused",
                selected: false,
            },
            {
                label: "Pending-future",
                value: "Pending-future",
                selected: false,
            },
        ];
    } else if (props.isVendor) {
        optionsList = [
            {
                label: "Valid",
                value: "Valid",
                selected: false,
            },
            {
                label: "Expired",
                value: "Expired",
                selected: false,
            },
            {
                label: "Invalid",
                value: "Invalid",
                selected: false,
            },
            {
                label: "Missing",
                value: "Missing",
                selected: false,
            },
        ];
    } else {
        optionsList = [
            {
                label: "Expired",
                value: "Expired",
                selected: false,
            },
            {
                label: "Invalid",
                value: "Invalid",
                selected: false,
            },
            {
                label: "Missing",
                value: "Missing",
                selected: false,
            },
        ];
    }
    useEffect(() => {
        dispatch(getCertificateStatusesAsync());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!isEligibleUserValidatedCertificateShowInvalidAsOption) {
        optionsList = optionsList.filter(status => status.label !== "Invalid");
    }
    const CertificateStatus = optionsList.map(element => {
        const selectOption = {
            label: element.label,
            value: element.value,
            selected: false,
        };
        if (props.multiple && props.value?.includes(element.value)) {
            selectOption.selected = true;
        }
        if (props.value === element.value) {
            selectOption.selected = true;
        }
        return selectOption;
    });

    return (
        <React.Fragment>
            <label htmlFor="Select-Certificate-status" id="lbl-certificateStatus">
                Certificate status
            </label>
            <SSelect
                name="Select-Certificate-status"
                inputId="Select-Certificate-status"
                optionsList={CertificateStatus}
                ref={ref}
                multiple={props.multiple}
                noSearch
                value={props.value}
                onS-select={e => props.onAdd(e.detail.item)}
                onS-deselect={e => props.onRemove(e.detail.item)}
                onS-close={e => (props.onClose ? props.onClose(e) : null)}
                disabled={props.disabled ? "disabled" : null}
                loading={isLoading}
                selectionoptional
            />
        </React.Fragment>
    );
}

export default React.forwardRef(SelectCertificateStatus);
