import React, { useCallback } from "react";
import { SBox } from "@avalara/skylab-react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { nextDays, previousDays } from "../../shared/Utils";

import "./certificateGraph.css";
import {
    setCertificateFiltersState,
    clearCertificateFiltersState,
} from "../../app/certificateSlice";

const CertificateOverviewGraph = React.memo(props => {
    const dispatch = useDispatch();

    const searchFilterHandler = filter => {
        dispatch(clearCertificateFiltersState());
        if (filter === "Invalid") {
            dispatch(setCertificateFiltersState({ certificateStatus: ["Invalid"] }));
            dispatch(setCertificateFiltersState({ isCertActive: "1" }));
        } else if (filter === "Expiring") {
            dispatch(setCertificateFiltersState({ certificateExpirationDateBefore: nextDays(30) }));
            dispatch(
                setCertificateFiltersState({ certificateExpirationDateAfter: previousDays(0) })
            );
            dispatch(setCertificateFiltersState({ isCertificateActive: true }));
        } else if (filter === "Expired") {
            dispatch(setCertificateFiltersState({ certificateStatus: ["Expired"] }));
            dispatch(setCertificateFiltersState({ isCertActive: "1" }));
            dispatch(setCertificateFiltersState({ isCertificateActive: true }));
        } else if (filter === "Valid") {
            dispatch(setCertificateFiltersState({ certificateStatus: ["Valid"] }));
            dispatch(setCertificateFiltersState({ isCertActive: "1" }));
        }
    };
    const dataSet = [
        {
            name: "Valid certificates",
            value: props.data.validCount,
            tip: "Valid",
            link: "/search/certificates",
            "aria-label": "Valid certificates",
        },
        {
            name: "Invalid certificates",
            value: props.data.invalidCertificateCount,
            tip: "Invalid",
            link: "/search/certificates",
            "aria-label": "Invalid certificates",
        },
        {
            name: "Certificates expired",
            value: props.data.expiredCertificateCount,
            tip: "Expired",
            link: "/search/certificates",
            "aria-label": "Certificates expired",
        },
        {
            name: "Missing certificates",
            value: props.data.missingCertificateCount,
            tip: "Missing",
            link: "#",
            "aria-label": "Missing certificates",
        },
    ];
    const colors = ["#38A632", "#FFDB4D", "#FFEEA8", "#FFFFFF"];
    const label = props.data.expectedCount ? props.data.expectedCount.toLocaleString() : "";
    const legendStyle = {
        left: 18,
        paddingTop: 10,
    };

    const CustomTooltip = useCallback(({ active, payload }) => {
        if (active && payload && payload.length) {
            const pointValue = (payload[0].value / props.data.expectedCount) * 100;
            const display = pointValue > 1 ? Math.floor(pointValue) : pointValue.toFixed(1);
            return (
                <SBox className="pad-right-xs pad-left-xs pad-top-none pad-bottom-none margin-all-none">
                    <p className="label">{`${display} % ${payload[0].payload.tip}`}</p>
                </SBox>
            );
        }
        return null;
    }, []);

    return (
        <PieChart width={400} height={350}>
            <Legend
                verticalAlign="bottom"
                height={20}
                iconSize={15}
                iconType="circle"
                layout="vertical"
                align="center"
                wrapperStyle={legendStyle}
                formatter={(value, entry, index) => {
                    return dataSet[index].link === "#" ? (
                        <span className="keyStyle">
                            {dataSet[index].name} ({dataSet[index].value})
                        </span>
                    ) : (
                        <Link
                            onClick={() => {
                                searchFilterHandler(dataSet[index].tip);
                            }}
                            to={dataSet[index].link}
                            className="keyStyle">
                            {dataSet[index].name} ({dataSet[index].value})
                        </Link>
                    );
                }}
            />
            <Tooltip content={CustomTooltip} offset={0} />
            <text
                x={180}
                y={130}
                fill="black"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize={25}>
                {label}
            </text>
            <text
                x={180}
                y={155}
                fill="black"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize={14}>
                Certificates
            </text>

            <text
                x={180}
                y={170}
                fill="black"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize={14}>
                expected
            </text>
            <Pie
                isAnimationActive
                data={dataSet}
                dataKey="value"
                outerRadius={150}
                innerRadius={100}
                minAngle={3}
                cx={175}
                fill="#ff5733">
                {dataSet.map((entry, index) => (
                    <Cell
                        key={`cell-${index}`} // eslint-disable-line react/no-array-index-key
                        fill={colors[index % colors.length]}
                        stroke="#c4c4c4"
                        strokeWidth="1"
                    />
                ))}
            </Pie>
        </PieChart>
    );
});

export default CertificateOverviewGraph;
