import React, { useState } from "react";
import { SBox, SIcon, SRow, SCol } from "@avalara/skylab-react";
import { Link } from "react-router-dom";
import ecmProduct from "./assets/images/ecm-product.svg";
import "./assets/provision.css";
import Upgrade from "../upgradeTier/OnlineBuyDialog";
import PromoDialog from "./PromoDialog";

const Promo = React.memo(() => {
    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
    const [isPromoDialogOpen, setIsPromoDialogOpen] = useState(false);

    const upgradePlan = () => {
        setIsUpgradeDialogOpen(!isUpgradeDialogOpen);
    };

    const openPromo = () => {
        setIsPromoDialogOpen(!isPromoDialogOpen);
    };

    return (
        <React.Fragment>
            <h1 className="heading margin-bottom-sm">
                How do you want to manage your exemption certificates?
            </h1>
            <p>
                Continue with the basic exemption tools that are included with your subscription, or
                choose an Exemption Certificate Management (ECM) plan that meets your business
                needs.
            </p>
            <SBox className="pad-all-lg">
                <SRow>
                    <SCol span="9">
                        <h2 className="margin-top-none">Included in your subscription</h2>
                    </SCol>
                    <SCol span="3" className="margin-top-lg">
                        <div className="text-center">
                            <Link to="/">
                                <button className="primary">Continue with AvaTax Exemptions</button>
                            </Link>
                        </div>
                    </SCol>
                </SRow>
                <b>AvaTax Exemptions</b> is a service that&apos;s included with your subscription.
                Use it to:
                <SRow className="margin-top-sm margin-bottom-sm">
                    <SCol span="4">
                        <SIcon name="check" aria-hidden="true" /> Import or add exempt customers and
                        certificate information
                    </SCol>
                    <SCol span="6">
                        <SIcon name="check" aria-hidden="true" /> Exempt customers from tax based on
                        their certificate details
                    </SCol>
                </SRow>
            </SBox>
            <div className="text-center margin-bottom-xl">
                <h2 className="margin-bottom-sm">
                    Save Time. Simplify your document management process
                </h2>
                <span className="font-bold">AvaTax Exemption Certificate Management (ECM) </span>
                plans have the tools you need to manage <br /> all of your compliance documents,
                whether you&apos;re a small business or large organization.
            </div>
            <SRow>
                <SCol span="4">
                    <SBox className="pad-all-lg">
                        <h3 className="margin-top-none">
                            Use ECM Essentials to request and store files
                        </h3>
                        <hr />
                        <div className="hanging-indent">
                            <SIcon name="check" aria-hidden="true" /> Upload and store certificate
                            images
                        </div>
                        <div className="hanging-indent">
                            <SIcon name="check" aria-hidden="true" /> Request certificates from
                            customers through email or our online portal
                        </div>
                        <button className="primary margin-top-md" onClick={() => upgradePlan()}>
                            Upgrade to Essentials
                        </button>
                    </SBox>
                </SCol>
                <SCol span="8">
                    <SBox className="pad-all-none">
                        <div className="pad-all-lg upgrade-box">
                            <h3 className="margin-top-none">
                                Get ECM Pro to automate requests, explore tax resources, and monitor
                                tasks
                            </h3>
                            <hr />
                            <img id="ecm-product" src={ecmProduct} alt="ECM Product" />
                            <SRow>
                                <SCol span="6">
                                    <div className="hanging-indent">
                                        <SIcon name="check" aria-hidden="true" />
                                        Upload and store certificate images
                                    </div>
                                    <div className="hanging-indent">
                                        <SIcon name="check" aria-hidden="true" />
                                        Request certificates from customers through email or online
                                        portal
                                    </div>
                                    <div className="hanging-indent">
                                        <SIcon name="check" aria-hidden="true" />
                                        Automatically request, review, and validate customer
                                        certificates
                                    </div>
                                    <div className="hanging-indent">
                                        <SIcon name="check" aria-hidden="true" />
                                        Monitor requests and complete tasks from a compliance
                                        dashboard
                                    </div>
                                    <div className="hanging-indent">
                                        <SIcon name="check" aria-hidden="true" />
                                        Explore AvaTax tax answers, research tax laws, and access a
                                        library of certificate forms
                                    </div>
                                    <button
                                        className="primary margin-top-md"
                                        onClick={() => openPromo()}>
                                        How do I go Pro?
                                    </button>
                                </SCol>
                            </SRow>
                        </div>
                    </SBox>
                </SCol>
            </SRow>
            <Upgrade
                isUpgradeDialogOpen={isUpgradeDialogOpen}
                setIsUpgradeDialogOpen={setIsUpgradeDialogOpen}
                type="provision"
                redirect="true"
            />
            <PromoDialog
                isPromoDialogOpen={isPromoDialogOpen}
                setIsPromoDialogOpen={setIsPromoDialogOpen}
            />
        </React.Fragment>
    );
});

export default Promo;
