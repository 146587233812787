import React, { useState } from "react";
import { DataGrid, gridHeader } from "@certcapture/react-components";
import { SLoader, SIcon, SPagination } from "@avalara/skylab-react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RecordCount from "../../search/RecordCount";
import {
    selectIsLoading,
    selectPage,
    fetchDuplicateCustomers,
    selectHasCrossCompanySameAs,
    selectSortColumn,
    setSortColumn,
} from "../../../app/customerDuplicateSlice";

const DuplicateCustomerGrid = React.memo(props => {
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);
    const hasCrossCompanySameAs = useSelector(selectHasCrossCompanySameAs);
    const page = useSelector(selectPage);
    const pageData = page.data;
    const paginateData = page.paginate;
    const sortColumn = useSelector(selectSortColumn);
    const [submitting, setSubmitting] = useState(false);

    const unlinkDuplicateCustomer = row => {
        props.setIsUnlinkDuplicateCustomerDialogOpen(true);
        props.setSameAsId(row.id);
    };

    async function sortFunction() {
        setSubmitting(true);
        const order = sortColumn.includes("ASC") ? "DESC" : "ASC";
        dispatch(setSortColumn(`name ${order}`));
        await dispatch(fetchDuplicateCustomers(props.customerId, "", 10, null, false));
        setSubmitting(false);
    }

    const columns = [
        gridHeader(
            "Customer name",
            "name",
            (value, row) => (
                <Link className="font-semibold customer-link" to={`/customer/${row.id}`}>
                    {row.name}
                </Link>
            ),
            null,
            null,
            null,
            null,
            sortFunction,
            "name"
        ),
        gridHeader("Customer code", "customerNumber", null, null, null),
        gridHeader(
            "Address",
            ["city", "state"],
            (value, row) => (
                <span>
                    {row.addressLine1} {row.city ? `${row.city}, ` : ""}{" "}
                    {row.state ? row.state.initials : ""} {row.zip}{" "}
                    {row.country ? row.country.initials : ""}
                </span>
            ),
            null,
            null,
            null,
            null,
            null,
            "address"
        ),
    ];

    if (hasCrossCompanySameAs) {
        columns.push(
            gridHeader(
                "Associated company",
                ["client"],
                (value, row) => <span>{row.client.name}</span>,
                null,
                null
            )
        );
    }

    columns.push(
        gridHeader(
            "",
            "",
            (value, row) => (
                <button
                    type="button"
                    className="secondary small"
                    onClick={() => unlinkDuplicateCustomer(row)}>
                    <SIcon name="close" className="pad-right-xs pad-top-xxs" aria-hidden="true" />
                    Unlink
                </button>
            ),
            "text-right",
            "text-right",
            null
        )
    );

    const getRowKey = row => {
        return row.id;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            await dispatch(
                fetchDuplicateCustomers(
                    props.customerId,
                    "",
                    10,
                    e.detail.currentPage,
                    false,
                    e.detail
                )
            );
            setSubmitting(false);
        }
    };

    const table = loading ? (
        <div className="flex dl-flex-fill-height dl-flex-center">
            <h3>Loading ...</h3>
            <SLoader id="page-loader" className="medium" aria-live="polite" loading />
        </div>
    ) : (
        <div>
            {paginateData.totalRecords === 0 ? (
                <React.Fragment>
                    <p>
                        If multiple customers should share certificates, link them as
                        <br />
                        duplicate customers. When linked, changes to one customer&apos;s <br />{" "}
                        certificates apply to all.
                    </p>
                    <button
                        id="link-duplicate-customer"
                        className="secondary inline margin-top-md"
                        onClick={() => props.setIsDuplicateCustomerDialogOpen(true)}>
                        <SIcon
                            name="link"
                            className="margin-right-xs"
                            aria-label="Link duplicate customer"
                        />
                        Link duplicate customer
                    </button>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <RecordCount
                        selectedCount={0}
                        recordCount={paginateData.totalRecords}
                        recordName="duplicate customers"
                    />

                    <s-table-container>
                        <DataGrid
                            columns={columns}
                            rows={pageData}
                            getKey={getRowKey}
                            loading={submitting}
                            inContainer
                            sortColumn={sortColumn}
                        />
                    </s-table-container>
                </React.Fragment>
            )}
        </div>
    );

    return (
        <React.Fragment>
            {table}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={paginateData.rowsPerPage}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}
        </React.Fragment>
    );
});

export default DuplicateCustomerGrid;
