import React from "react";
import CertificateStatusTag from "../../sharedComponents/CertificateStatusTag";

const SearchCustomerCertificates = props => {
    const { certificates } = props;
    return certificates.map(certificate => (
        <p className="flex" key={certificate.id}>
            {certificate.exposureZoneInitials}
            {CertificateStatusTag(certificate)}
            <br />
        </p>
    ));
};

export default SearchCustomerCertificates;
