import React from "react";
import { SDialog } from "@avalara/skylab-react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { selectIsDeleting, deleteExemptReasonAsync } from "../../../../app/exemptionReasonSlice";

const DeleteExemptReasonDialog = React.memo(props => {
    const dialogName = "DeleteExemptReasonWarningDialog";
    const dispatch = useDispatch();
    const isDeleting = useSelector(selectIsDeleting);
    const deleteButton = classnames({
        primary: true,
        small: true,
        loading: isDeleting,
    });

    const handleDelete = async event => {
        event.preventDefault();
        await dispatch(deleteExemptReasonAsync(props.exemptReason.id, props.exemptReason.name));
        props.setIsDeleteDialogOpen(false);
    };

    return (
        <SDialog
            id={dialogName}
            open={props.isDeleteDialogOpen}
            onS-dismiss={() => props.setIsDeleteDialogOpen(false)}
            aria-modal="true">
            <div slot="header" id="deleteExemptReason">
                Are you sure you want to delete this exempt reason?
            </div>
            <div slot="body">
                You can&apos;t undo this action, but you can recreate this exempt reason later.
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => props.setIsDeleteDialogOpen(false)}>
                    Cancel
                </button>
                <button className={deleteButton} onClick={handleDelete}>
                    Yes, I&apos;m sure
                </button>
            </div>
        </SDialog>
    );
});

export default DeleteExemptReasonDialog;
