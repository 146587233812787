import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SRow, SCol, SSelect, SLoader } from "@avalara/skylab-react";
import {
    getCustomFields,
    getCustomerCustomFields,
    selectCustomFields,
    selectCustomerCustomFields,
    selectIsLoadingCustomerCustomFields,
    setCustomerCustomFields,
    selectCustomerExistingCustomFieldValues,
    setCustomerExistingCustomFieldValues,
    selectCustomerAddedCustomFieldValues,
    setCustomerAddedCustomFieldValues,
    setFormUpdated,
    getCustomFieldsValidationResults,
} from "../../app/customerSlice";
import { errorTag, noErrorTag, getLabelForCustomField } from "../../shared/Utils";
import ValidationError from "./ValidationError";

const CustomerCustomField = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const [validationResults, setValidationResults] = useState({});
    const customerCustomFields = useSelector(
        props.customerID ? selectCustomerCustomFields : selectCustomFields,
        shallowEqual
    );
    const customerExistingCustomFieldValues = useSelector(
        selectCustomerExistingCustomFieldValues,
        shallowEqual
    );
    const customerAddedCustomFieldValues = useSelector(
        selectCustomerAddedCustomFieldValues,
        shallowEqual
    );
    const isLoading = useSelector(selectIsLoadingCustomerCustomFields);

    useEffect(() => {
        if (props.customerID) {
            dispatch(getCustomerCustomFields(props.customerID));
        } else {
            dispatch(getCustomFields());
        }
    }, [dispatch, props.customerID]);

    // clean up when component dismounts
    useEffect(() => {
        return () => {
            dispatch(setCustomerCustomFields([]));
        };
    }, [dispatch]);

    // used for doing CSV injection validation which is currently not in use. Can be leveraged for any validation.
    useImperativeHandle(ref, () => ({
        validateCustomFields() {
            const customFieldsValidationResult = dispatch(getCustomFieldsValidationResults());
            setValidationResults(customFieldsValidationResult);
            return customFieldsValidationResult;
        },
    }));

    const updateCustomerCustomFields = (e, type, valueExists, add = true, isDeselectAll) => {
        let value = null;
        if (isDeselectAll) {
            value = null;
        } else if (type === "multi-select") {
            if (add) {
                if (valueExists) {
                    value = customerExistingCustomFieldValues[e.target.id]
                        ? [...customerExistingCustomFieldValues[e.target.id], e.detail.item.value]
                        : [e.detail.item.value];
                } else
                    value = customerAddedCustomFieldValues[e.target.id]
                        ? [...customerAddedCustomFieldValues[e.target.id], e.detail.item.value]
                        : [e.detail.item.value];
            } else if (valueExists) {
                const filteredValue = customerExistingCustomFieldValues[e.target.id].filter(
                    item => {
                        return item !== e.detail.item.value;
                    }
                );
                value = filteredValue;
            } else {
                const filteredValue = customerAddedCustomFieldValues[e.target.id].filter(item => {
                    return item !== e.detail.item.value;
                });
                value = filteredValue;
            }
        } else if (type === "select" || type === "boolean") {
            value = e.detail.item.value;
        } else {
            value = e.target.value;
            if (e.target.className === "error") {
                setValidationResults(prevValidationResults => ({
                    ...prevValidationResults,
                    [e.target.id]: { status: "passed" },
                }));
            }
        }

        if (valueExists) dispatch(setCustomerExistingCustomFieldValues({ [e.target.id]: value }));
        else dispatch(setCustomerAddedCustomFieldValues({ [e.target.id]: value }));
        dispatch(setFormUpdated(true));
    };

    const getOptions = (possibleValues, value, id, type) => {
        const splitPossibleValues = possibleValues.split("|");
        const options = [];

        if (id && customerExistingCustomFieldValues[id]) {
            splitPossibleValues.forEach(element => {
                options.push({
                    label: getLabelForCustomField(type, element),
                    value: element,
                    selected:
                        props.customerID && Array.isArray(customerExistingCustomFieldValues[id])
                            ? customerExistingCustomFieldValues[id].includes(element)
                            : customerExistingCustomFieldValues[id] === element,
                });
            });
            return options;
        }

        if (id && customerAddedCustomFieldValues[id]) {
            splitPossibleValues.forEach(element => {
                options.push({
                    label: getLabelForCustomField(type, element),
                    value: element,
                    selected: customerAddedCustomFieldValues[id].includes(element),
                });
            });
            return options;
        }

        splitPossibleValues.forEach(element => {
            options.push({
                label: getLabelForCustomField(type, element),
                value: element,
                selected: false,
            });
        });

        return options;
    };

    const hasValidValues = elementId => {
        return (
            !Object.keys(validationResults).includes(elementId) ||
            validationResults[elementId].status === "passed"
        );
    };

    const getErrorMessage = elementId => {
        return Object.keys(validationResults).includes(elementId) &&
            validationResults[elementId].status === "failed"
            ? validationResults[elementId].issues[0]
            : "";
    };

    return (
        <React.Fragment>
            {isLoading && (
                <SRow className="margin-top-sm">
                    <SCol>
                        <SLoader loading="true" aria-live="polite" />
                    </SCol>
                </SRow>
            )}

            {customerCustomFields &&
                customerCustomFields.map(customerCustomField => (
                    <React.Fragment key={customerCustomField.id}>
                        {customerCustomField.type === "text" ? (
                            <React.Fragment>
                                <label
                                    id={`lbl-customer-custom-field-${customerCustomField.id}`}
                                    className="margin-top-sm">
                                    {customerCustomField.fieldName}
                                </label>
                                <input
                                    id={customerCustomField.id}
                                    name={customerCustomField.id}
                                    aria-labelledby={`lbl-customer-custom-field-${customerCustomField.id}`}
                                    type="text"
                                    onChange={e =>
                                        updateCustomerCustomFields(
                                            e,
                                            "input",
                                            customerCustomField.exists
                                        )
                                    }
                                    defaultValue={customerCustomField.value}
                                    className={
                                        hasValidValues(customerCustomField.id.toString())
                                            ? noErrorTag
                                            : errorTag
                                    }
                                />
                                <ValidationError
                                    errorMsg={getErrorMessage(customerCustomField.id.toString())}
                                />
                            </React.Fragment>
                        ) : null}
                        {customerCustomField.type === "select" ||
                        customerCustomField.type === "boolean" ? (
                            <React.Fragment>
                                <label
                                    htmlFor={customerCustomField.id}
                                    className="margin-top-sm"
                                    id={`lbl-customer-custom-field-${customerCustomField.id}`}>
                                    {customerCustomField.fieldName}
                                </label>
                                <SSelect
                                    id={customerCustomField.id}
                                    inputId={customerCustomField.id}
                                    aria-labelledby={`lbl-customer-custom-field-${customerCustomField.id}`}
                                    selectionOptional
                                    optionsList={getOptions(
                                        customerCustomField.possibleValues,
                                        customerCustomField.value,
                                        customerCustomField.id,
                                        customerCustomField.type
                                    )}
                                    onS-select={e => {
                                        updateCustomerCustomFields(
                                            e,
                                            customerCustomField.type,
                                            customerCustomField.exists
                                        );
                                    }}
                                    onS-deselect={e => {
                                        updateCustomerCustomFields(
                                            e,
                                            customerCustomField.type,
                                            customerCustomField.exists,
                                            true,
                                            e.detail.isDeselectAll
                                        );
                                    }}
                                />
                            </React.Fragment>
                        ) : null}
                        {customerCustomField.type === "multi-select" ? (
                            <React.Fragment>
                                <label
                                    id={`lbl-customer-custom-field-${customerCustomField.id}`}
                                    className="margin-top-sm"
                                    htmlFor={customerCustomField.id}>
                                    {customerCustomField.fieldName}
                                </label>
                                <SSelect
                                    inputId={customerCustomField.id}
                                    id={customerCustomField.id}
                                    aria-labelledby={`lbl-customer-custom-field-${customerCustomField.id}`}
                                    multiple
                                    selectionOptional
                                    optionsList={getOptions(
                                        customerCustomField.possibleValues,
                                        customerCustomField.value,
                                        customerCustomField.id,
                                        customerCustomField.type
                                    )}
                                    onS-select={e => {
                                        updateCustomerCustomFields(
                                            e,
                                            "multi-select",
                                            customerCustomField.exists
                                        );
                                    }}
                                    onS-deselect={e => {
                                        updateCustomerCustomFields(
                                            e,
                                            "multi-select",
                                            customerCustomField.exists,
                                            false,
                                            e.detail.isDeselectAll
                                        );
                                    }}
                                />
                            </React.Fragment>
                        ) : null}
                    </React.Fragment>
                ))}
        </React.Fragment>
    );
});

export default React.memo(CustomerCustomField);
