import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";

import {
    fetchExpirationMatrixExemptReasons,
    selectIsLoadingMatrixExemptReasons,
    selectMatrixExemptReasonData,
} from "../../../app/exemptionMatrixSlice";

const SelectVendorReasons = React.memo(props => {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingMatrixExemptReasons);
    const exemptionReasons = useSelector(selectMatrixExemptReasonData).map(element => {
        const selectOption = {
            label: element.exemptReason.name,
            value: element.exemptReason.id,
            staticFile: element.staticFile !== null,
            tag: element.templateTag,
            selected: false,
        };
        if (props.value === element.exemptReason.id && !props.cleared) {
            selectOption.selected = true;
        }
        return selectOption;
    });

    useEffect(() => {
        if (props.exposureZoneId) {
            dispatch(fetchExpirationMatrixExemptReasons(props.exposureZoneId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.exposureZoneId]);

    return (
        <React.Fragment>
            <label htmlFor={getNextId()} className={props.labelClass} id="lbl-exemptionReason">
                Reason for exemption
            </label>
            <SSelect
                name="exemptionReason"
                inputId={getCurrentId()}
                selectionOptional
                optionsList={exemptionReasons}
                loading={isLoading}
                className={props.taxCodeError}
                onS-select={e => props.onAdd(e.detail.item)}
                onS-deselect={e => props.onRemove(e.detail.item)}
                disabled={props.disabled ? "disabled" : null}
                value={props.value}
            />
        </React.Fragment>
    );
});

export default SelectVendorReasons;
