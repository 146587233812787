import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Helmet } from "react-helmet-async";
import { SIcon, SRow, SCol } from "@avalara/skylab-react";
import {
    selectIsAddRegionDialogOpen,
    fetchCustomerExemptionsDetails,
    selectPage,
    setShowOnlyActiveCertificate,
    selectShowOnlyActiveCertificate,
    setIsAddRegionDialogOpen,
} from "../../../app/customerCertificateSlice";
import { getCustomerShipToZonesAsync } from "../../../app/customerSlice";
import CustomerExemptionsGrid from "./CustomerExemptionsGrid";
import { selectSession } from "../../../app/sessionSlice";
import { setRedirectURL } from "../../../app/certificateSlice";
import { urlToPageMap } from "../../../shared/Utils";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import featureFlag from "../../../featureToggler/featureFlag";
import CustomerAddRegionDialog from "./CustomerAddRegionDialog";

const CustomerExemptions = React.memo(props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const session = useSelector(selectSession);
    const showActive = useSelector(selectShowOnlyActiveCertificate);
    const pageData = useSelector(selectPage, shallowEqual);
    const buttonString = props.customer.isVendor ? "Send a certificate " : "Request a certificate";
    const isEligibleUserCustRequestCertificateFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.singleRequest)
    );
    const isAddRegionDialogOpen = useSelector(selectIsAddRegionDialogOpen);
    const isEligibleUserVendorCertificateFlag = dispatch(
        isEligibleUser(featureFlag.vendors.createAndSendCertificates)
    );

    const clickRequest = () => {
        if (session.baseSubscription) {
            props.setIsUpgradeDialogOpen(true);
        } else if (props.customer.isVendor) {
            navigate(`/vendor-requests/${props.customer.id}`);
        } else if (!props.isDialogOpen) {
            props.setIsDialogOpen(true);
        }
    };

    const handleCheckboxChange = async () => {
        dispatch(setShowOnlyActiveCertificate(!showActive));
        await dispatch(
            fetchCustomerExemptionsDetails(pageData.paginate.rowsPerPage, 0, pageData.paginate)
        );
    };

    useEffect(() => {
        dispatch(fetchCustomerExemptionsDetails());
        return () => {
            dispatch(setShowOnlyActiveCertificate(true));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openCustomerAddRegionDialog = async () => {
        if (props?.customer?.id) {
            await dispatch(getCustomerShipToZonesAsync(props.customer.id));
        }
        dispatch(setIsAddRegionDialogOpen(true));
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle(`${props.customer.name} Exemptions`))}</title>
            </Helmet>
            <SRow>
                <SCol span="4">
                    <fieldset>
                        <input
                            id="showActive"
                            name="showActive"
                            aria-label="Hide inactive certificates"
                            type="checkbox"
                            onChange={handleCheckboxChange}
                            checked={showActive}
                        />
                        <label htmlFor="showActive" className="margin-top-xl margin-right-xl">
                            Hide inactive certificates (Certificates not in use)
                        </label>
                    </fieldset>
                </SCol>
                <SCol span="8" className="flex justify-content-end margin-top-md">
                    <button
                        className="secondary right margin-left-sm"
                        onClick={() => {
                            openCustomerAddRegionDialog();
                        }}>
                        Add a region
                    </button>
                    {!props?.customer.isVendor && (
                        <button
                            className="secondary right margin-left-sm"
                            hidden={props?.customer.isVendor}
                            onClick={() => {
                                dispatch(
                                    setRedirectURL(
                                        urlToPageMap.customerDetail.replace(
                                            ":id?",
                                            props?.customer.id
                                        )
                                    )
                                );
                                navigate(urlToPageMap.review, {
                                    state: { customer: props.customer },
                                });
                            }}>
                            Add a certificate
                        </button>
                    )}

                    <button
                        className="primary right margin-left-sm"
                        disabled={
                            props?.customer.isVendor
                                ? !props?.matrixSet || !isEligibleUserVendorCertificateFlag
                                : !isEligibleUserCustRequestCertificateFlag
                        }
                        onClick={() => clickRequest()}>
                        <SIcon name="send" aria-hidden="true" />
                        <span className="margin-left-xs">{buttonString}</span>
                    </button>
                </SCol>
            </SRow>
            <SRow>
                <SCol>
                    <CustomerExemptionsGrid
                        isGeneralRequestDialogOpen={props.isDialogOpen}
                        setIsGeneralRequestDialogOpen={props.setIsDialogOpen}
                        setIsUpgradeDialogOpen={props.setIsUpgradeDialogOpen}
                        showActive={showActive}
                        customer={props.customer}
                    />
                </SCol>
            </SRow>
            {isAddRegionDialogOpen ? (
                <CustomerAddRegionDialog
                    isAddRegionDialogOpen={isAddRegionDialogOpen}
                    isVendor={props?.customer.isVendor}
                    customer={props.customer}
                />
            ) : null}
        </React.Fragment>
    );
});

export default CustomerExemptions;
