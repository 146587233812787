import React from "react";
import { SRow, SCol, SIcon } from "@avalara/skylab-react";
import SelectState from "../../../sharedComponents/SelectState";
import "./retailLocation.css";
import { errorTag, noErrorTag } from "../../../../shared/Utils";

function EditRetailSiteSetting(props) {
    const handleCheckBoxChange = setter => {
        return event => {
            if (event?.target?.id === "useStateAsJurisdiction") {
                props?.setAcceptedRegionsError("");
            }
            setter(event.target.checked);
        };
    };
    const handleAdd = value => {
        if (props?.acceptedRegions?.length > 0)
            props.setAcceptedRegions([...props.acceptedRegions, parseInt(value.value, 10)]);
        else props.setAcceptedRegions([parseInt(value.value, 10)]);
        props?.setAcceptedRegionsError("");
    };

    const handleRemove = (value, isDeselectAll) => {
        if (isDeselectAll) {
            props.setAcceptedRegions([]);
        } else {
            props.setAcceptedRegions(
                props.acceptedRegions?.filter(val => val !== parseInt(value.value, 10))
            );
        }
        props?.setAcceptedRegionsError("");
    };

    return (
        <>
            <SRow>
                <SCol>
                    <input
                        type="checkbox"
                        id="useStateAsJurisdiction"
                        name="useStateAsJurisdiction"
                        checked={props.useStateAsJurisdiction}
                        onChange={handleCheckBoxChange(props.setUseStateAsJurisdiction)}
                    />
                    <label htmlFor="useStateAsJurisdiction">
                        Limit certificates accepted at this retail site to the site region and other
                        specified regions
                    </label>
                </SCol>
            </SRow>
            <SRow>
                <SCol className="pad-left-xl pad-right-xl">
                    <SelectState
                        id="acceptedRegions"
                        name="acceptedRegions"
                        onAdd={e => handleAdd(e)}
                        onRemove={(e, isDeselectAll) => handleRemove(e, isDeselectAll)}
                        value={[...props.acceptedRegions.map(regionId => `${regionId}`)]}
                        multiple
                        disabled={!props.useStateAsJurisdiction}
                        stateError={props?.acceptedRegionsError ? errorTag : noErrorTag}
                    />
                    <div className="input-msg">
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        <span className="top-xs">{props?.acceptedRegionsError}</span>
                    </div>
                </SCol>
            </SRow>
            <SRow>
                <SCol>
                    <input
                        type="checkbox"
                        id="useLocationAsJurisdiction"
                        name="useLocationAsJurisdiction"
                        checked={props.useLocationAsJurisdiction}
                        onChange={handleCheckBoxChange(props.setUseLocationAsJurisdiction)}
                    />
                    <label htmlFor="useLocationAsJurisdiction">
                        Only show exemptions created at this retail site
                    </label>
                </SCol>
            </SRow>
            <SRow>
                <SCol>
                    <input
                        type="checkbox"
                        id="enableCustomerRefId"
                        name="enableCustomerRefId"
                        checked={props.enableCustomerRefId}
                        onChange={handleCheckBoxChange(props.setEnableCustomerRefId)}
                    />
                    <label htmlFor="enableCustomerRefId">
                        Include customer codes in search filter
                    </label>
                </SCol>
            </SRow>
        </>
    );
}

export default EditRetailSiteSetting;
