import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import toast from "../../../hooks/toast";
import { selectSession } from "../../../app/sessionSlice";
import { buildApiV3Url } from "../../../shared/Utils";

function PackageCertDeleteDialog(props) {
    const [isLoading, setIsLoading] = useState(false);
    const deleteButton = classnames({ primary: true, small: true, loading: isLoading });
    const [showToast] = toast();
    const session = useSelector(selectSession);
    const companyId = session.activeCompany.id;
    const deletePackageCert = async () => {
        setIsLoading(true);

        await axios
            .delete(
                buildApiV3Url(
                    `companies/${companyId}/vendor-packages-content/${props.packageCertId}`
                ),
                { withCredentials: true }
            )
            .then(() => {
                showToast("success", "Certificate removed");
                props.reloadHdlr();
            });

        setIsLoading(false);
        props.closeCertDel(false);
    };

    return (
        <SDialog id="del-cert-package-dialog" open={props.openCertDel} aria-modal="true">
            <div slot="header" id="delCertPackage">
                Remove this certificate?
            </div>
            <div slot="body">
                <span>
                    You can&apos;t undo this action, but you can add this certificate again later.
                </span>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    disabled={isLoading}
                    onClick={() => props.closeCertDel(false)}>
                    Cancel
                </button>
                <button className={deleteButton} type="button" onClick={() => deletePackageCert()}>
                    Remove certificate
                </button>
            </div>
        </SDialog>
    );
}

export default PackageCertDeleteDialog;
