import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SRow, SCol, SDialog, SIcon, STooltip } from "@avalara/skylab-react";
import {
    addOrUpdateRetailLocations,
    selectIsAddOrEditLoading,
} from "../../../../app/retailLocationSlice";
import SelectState from "../../../sharedComponents/SelectState";
import SelectCountry from "../../../sharedComponents/SelectCountry";
import EditRetailSiteSetting from "./EditRetailSiteSetting";
import { errorTag, noErrorTag } from "../../../../shared/Utils";
import ValidationError from "../../../sharedComponents/ValidationError";
import "./retailLocation.css";

function AddEditRetailLocation(props) {
    const dispatch = useDispatch();
    const { location } = props;
    const isLoading = useSelector(selectIsAddOrEditLoading);
    const [name, setName] = useState(location?.name ? location.name : "");
    const [code, setCode] = useState(location?.code ? location.code : "");
    const [zip, setZip] = useState(location?.zip ? location.zip : "");
    const [addressLine1, setAddressLine1] = useState(
        location?.addressLine1 ? location.addressLine1 : ""
    );
    const [addressLine2, setAddressLine2] = useState(
        location?.addressLine2 ? location.addressLine2 : ""
    );
    const [city, setCity] = useState(location?.city ? location.city : "");
    const [state, setState] = useState(location?.stateId ? location.stateId : null);
    const [country, setCountry] = useState(location?.countryId ? location.countryId : "");
    const [acceptedRegions, setAcceptedRegions] = useState(
        location?.acceptedRegions ? location.acceptedRegions.map(item => item.stateId) : []
    );
    const [useStateAsJurisdiction, setUseStateAsJurisdiction] = useState(
        location?.useStateAsJurisdiction ? location.useStateAsJurisdiction : false
    );
    const [useLocationAsJurisdiction, setUseLocationAsJurisdiction] = useState(
        location?.useLocationAsJurisdiction ? location.useLocationAsJurisdiction : false
    );
    const [enableCustomerRefId, setEnableCustomerRefId] = useState(
        location?.enableCustomerRefId ? location.enableCustomerRefId : false
    );
    const [showAddressLine2, setShowAddressLine2] = useState(false);
    const [nameError, setNameError] = useState("");
    const [codeError, setCodeError] = useState("");
    const [zipError, setZipError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [cityError, setCityError] = useState("");
    const [stateError, setStateError] = useState("");
    const [acceptedRegionsError, setAcceptedRegionsError] = useState("");
    const [countryError, setCountryError] = useState("");

    const checkValidation = () => {
        let valid = true;

        if (!name || name.trim()?.length <= 0) {
            setNameError("Enter a name");
            valid = false;
        }
        if (!code || code.trim()?.length <= 0) {
            setCodeError("Enter a code");
            valid = false;
        }
        if (!addressLine1 || addressLine1.trim()?.length <= 0) {
            setAddressError("Enter an address");
            valid = false;
        }
        if (!city || city.trim()?.length <= 0) {
            setCityError("Enter a city");
            valid = false;
        }
        if (!state) {
            setStateError("Enter a state");
            valid = false;
        }
        if (!zip || zip.trim()?.length <= 0) {
            setZipError("Enter a zip code");
            valid = false;
        }
        if (useStateAsJurisdiction && (!acceptedRegions || acceptedRegions?.length === 0)) {
            setAcceptedRegionsError("Enter a state");
            valid = false;
        }
        if (!country) {
            setCountryError("Enter a country");
            valid = false;
        }
        return valid;
    };
    useEffect(() => {
        if (location?.addressLine2) {
            setShowAddressLine2(true);
        }
    }, [location?.addressLine2, dispatch]);
    const onSave = async () => {
        if (checkValidation()) {
            const payload = {
                name,
                code,
                countryId: country,
                addressLine1,
                addressLine2,
                city,
                zip,
                stateId: state,
                useStateAsJurisdiction,
                useLocationAsJurisdiction,
                acceptedRegions,
                enableCustomerRefId,
            };

            if (props?.addOrEdit === "Add") {
                await dispatch(addOrUpdateRetailLocations("Add", payload));
            } else {
                await dispatch(addOrUpdateRetailLocations("Edit", payload, location.id));
            }
            props?.setOpenAddUpdateDialog(false);
            props?.setLocation({});
        }
    };

    const handleChangeInput = setter => {
        return event => {
            if (event.target.value) {
                if (event.target.name === "name") {
                    setNameError("");
                }
                if (event.target.name === "code") {
                    setCodeError("");
                }
                if (event.target.name === "addressLine1") {
                    setAddressError("");
                }
                if (event.target.name === "city") {
                    setCityError("");
                }
                if (event.target.name === "zip") {
                    setZipError("");
                }
                if (event.target.name === "state") {
                    setStateError("");
                }
                if (event.target.name === "country") {
                    setCountryError("");
                }
            }
            setter(event.target.value);
        };
    };
    const onCancel = () => {
        if (location) props?.setLocation({});
        props?.setOpenAddUpdateDialog(false);
    };
    const handleStateChange = value => {
        setState(value.value);
        setStateError("");
    };
    const handleStateRemove = () => {
        setState("");
        setStateError("");
    };

    const handleCountryChange = value => {
        setCountry(value);
        setCountryError("");
    };
    const handleCountryRemove = () => {
        setCountry("");
        setCountryError("");
    };

    return (
        <SDialog id="addLocationDialog" open={props?.openAddUpdateDialog} onS-dismiss={onCancel}>
            <div slot="header">
                {props?.addOrEdit === "Edit" ? location?.name : "Add a retail site location"}
            </div>
            <div slot="body">
                <h3 className="margin-top-none">Retail site details</h3>
                <SRow>
                    <SCol span="6">
                        <label htmlFor="name" className="required">
                            Site name
                        </label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            value={name}
                            onChange={handleChangeInput(setName)}
                            className={nameError ? errorTag : noErrorTag}
                        />
                        {nameError ? (
                            <ValidationError
                                errorMsg={nameError !== "error" ? nameError : null}
                                fieldName="site name"
                            />
                        ) : null}
                    </SCol>
                    <SCol span="6">
                        <div className="flex">
                            <SCol span={10} className="flex justify-content-start pad-all-none">
                                <label htmlFor="code" className="required pad-bottom-none">
                                    Site code
                                </label>
                            </SCol>
                            <SCol span={2} className="flex justify-content-end pad-all-none">
                                <button
                                    className="ghost-blue icon pad-bottom-none align-end sitecode-info"
                                    id="siteCode"
                                    aria-label="Site Code info">
                                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                                </button>
                                <STooltip triggerId="siteCode">
                                    <span slot="content">
                                        A unique identifier used to distinguish this location from
                                        others. It can be any combination of letters, spaces, and
                                        numbers.
                                    </span>
                                </STooltip>
                            </SCol>
                        </div>
                        <input
                            id="code"
                            name="code"
                            type="text"
                            value={code}
                            onChange={handleChangeInput(setCode)}
                            className={codeError ? errorTag : noErrorTag}
                        />
                        {codeError ? (
                            <ValidationError
                                errorMsg={codeError !== "error" ? codeError : null}
                                fieldName="site code"
                            />
                        ) : null}
                    </SCol>
                </SRow>

                <SRow>
                    <SCol>
                        <SelectCountry
                            onChange={handleCountryChange}
                            onRemove={handleCountryRemove}
                            value={country?.toString()}
                            required
                            countryError={countryError ? errorTag : noErrorTag}
                        />
                        {countryError ? (
                            <ValidationError
                                errorMsg={countryError !== "error" ? countryError : null}
                                fieldName="country"
                            />
                        ) : null}
                    </SCol>
                </SRow>

                <SRow>
                    <SCol>
                        <label htmlFor="addressLine1" className="required  margin-top-xs">
                            Address
                        </label>
                        <input
                            id="addressLine1"
                            name="addressLine1"
                            type="text"
                            value={addressLine1}
                            onChange={handleChangeInput(setAddressLine1)}
                            className={addressError ? errorTag : noErrorTag}
                        />
                        {addressError ? (
                            <ValidationError
                                errorMsg={addressError !== "error" ? addressError : null}
                                fieldName="address"
                            />
                        ) : null}
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        {!showAddressLine2 ? (
                            <div className="margin-top-xs">
                                <button className="link" onClick={() => setShowAddressLine2(true)}>
                                    <SIcon name="plus" className="custom-top-icon" />
                                    <span className="margin-left-xs">Address line</span>
                                </button>
                            </div>
                        ) : null}
                        {showAddressLine2 ? (
                            <div className="margin-top-xs">
                                <label htmlFor="addressLine2">Address Line 2</label>
                                <input
                                    name="addressLine2"
                                    id="addressLine2"
                                    type="text"
                                    onChange={handleChangeInput(setAddressLine2)}
                                    value={addressLine2}
                                />
                            </div>
                        ) : null}
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="4">
                        <label htmlFor="city" className="required  margin-top-xs">
                            City
                        </label>
                        <input
                            id="city"
                            name="city"
                            type="text"
                            value={city}
                            onChange={handleChangeInput(setCity)}
                            className={cityError ? errorTag : noErrorTag}
                        />
                        {cityError ? (
                            <ValidationError
                                errorMsg={cityError !== "error" ? cityError : null}
                                fieldName="city"
                            />
                        ) : null}
                    </SCol>
                    <SCol span="4" className=" margin-top-xs">
                        <SelectState
                            id="state"
                            name="state"
                            onAdd={e => handleStateChange(e)}
                            onRemove={handleStateRemove}
                            value={[state]}
                            label="Region"
                            selectionOptional
                            required
                            stateError={stateError ? errorTag : noErrorTag}
                        />
                        {stateError ? (
                            <ValidationError
                                errorMsg={stateError !== "error" ? stateError : null}
                                fieldName="region"
                            />
                        ) : null}
                    </SCol>
                    <SCol span="4">
                        <label htmlFor="zip" className="required  margin-top-xs">
                            Zip Code
                        </label>
                        <input
                            id="zip"
                            name="zip"
                            type="text"
                            value={zip}
                            onChange={handleChangeInput(setZip)}
                            className={zipError ? errorTag : noErrorTag}
                        />
                        {zipError ? (
                            <ValidationError
                                errorMsg={zipError !== "error" ? zipError : null}
                                fieldName="zip Code"
                            />
                        ) : null}
                    </SCol>
                </SRow>
                {props.addOrEdit === "Add" && (
                    <>
                        <h3 className="margin-bottom-none">Retail site settings</h3>
                        <EditRetailSiteSetting
                            useStateAsJurisdiction={useStateAsJurisdiction}
                            setUseStateAsJurisdiction={setUseStateAsJurisdiction}
                            setUseLocationAsJurisdiction={setUseLocationAsJurisdiction}
                            setEnableCustomerRefId={setEnableCustomerRefId}
                            setAcceptedRegions={setAcceptedRegions}
                            acceptedRegions={acceptedRegions}
                            acceptedRegionsError={acceptedRegionsError}
                            setAcceptedRegionsError={setAcceptedRegionsError}
                        />
                    </>
                )}
            </div>
            <div slot="footer">
                <button
                    className={isLoading ? "primary right loading" : "primary right"}
                    id="save-label"
                    type="button"
                    onClick={onSave}>
                    Save
                </button>
                <button className="secondary" onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </SDialog>
    );
}

export default AddEditRetailLocation;
