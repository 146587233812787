import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { SDialog, SRow, SCol, SAlert } from "@avalara/skylab-react";
import SelectLabels from "../sharedComponents/SelectLabels";
import "./bulkeditdialog.css";
import { setAddLabelsList, setRemoveLabelsList, setSelectedField } from "../../app/bulkUpdateSlice";
import { getLabels } from "../../app/certificateSlice";

function BulkUpdateLabelsDialog(props) {
    const dispatch = useDispatch();
    const { certificateCount } = props;
    const [addLabels, setAddLabels] = useState([]);
    const [removeLabels, setRemoveLabels] = useState([]);
    const [warningLabel, setWarningLabel] = useState(false);
    const onCancel = () => {
        props.setIsBulkUpdateLabelsDialogOpen(false);
        dispatch(setAddLabelsList([]));
        dispatch(setRemoveLabelsList([]));
        dispatch(setSelectedField(""));
    };

    const onSave = () => {
        if (addLabels.length !== 0 || removeLabels.length !== 0) {
            setWarningLabel(false);
            dispatch(setAddLabelsList(addLabels));
            dispatch(setRemoveLabelsList(removeLabels));
            props.setIsBulkUpdateConfirmDialogOpen(true);
            props.setIsBulkUpdateLabelsDialogOpen(false);
        } else {
            setWarningLabel(true);
        }
    };

    const onBack = () => {
        props.setIsBulkUpdateLabelsDialogOpen(false);
        props.setChooseField(true);
    };

    const addListAddLabels = useCallback(item => {
        setWarningLabel(false);
        setAddLabels(prevLabels => [...prevLabels, item.value]);
    }, []);

    const addListRemoveLabels = useCallback(item => {
        setAddLabels(prevLabels => prevLabels.filter(value => value !== item.value)); // eslint-disable-next-line
    }, []);

    const removeListAddLabels = useCallback(item => {
        setWarningLabel(false);
        setRemoveLabels(prevLabels => [...prevLabels, item.value]);
    }, []);

    const removeListRemoveLabels = useCallback(item => {
        setRemoveLabels(prevLabels => prevLabels.filter(value => value !== item.value)); // eslint-disable-next-line
    }, []);
    useEffect(() => {
        dispatch(getLabels());
    }, [dispatch]);

    return (
        <SDialog
            className="label_modal overflow-visible"
            id="bulk-updateLabels-dialog"
            open={props.isBulkUpdateLabelsDialogOpen}
            onS-dismiss={onCancel}
            aria-modal="true">
            <div slot="header" id="bulk-updateLabels">
                Update {certificateCount} {certificateCount > 1 ? "certificates" : "certificate"}
            </div>
            <div slot="body">
                <span hidden={!warningLabel}>
                    <SAlert
                        id="error-alert"
                        status="warning"
                        noDismiss
                        className="margin-bottom-sm">
                        To save, there must be one selection
                    </SAlert>
                </span>
                <SRow>
                    <SCol span="auto">
                        <SelectLabels
                            name="Add Labels"
                            onAdd={e => addListAddLabels(e)}
                            onRemove={e => addListRemoveLabels(e)}
                            multiple
                            value={addLabels}
                            hasLabels
                        />
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="auto">
                        <SelectLabels
                            name="Remove Labels"
                            onAdd={removeListAddLabels}
                            onRemove={removeListRemoveLabels}
                            multiple
                            value={removeLabels}
                            hasLabels
                        />
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button className="primary" type="button" onClick={onSave}>
                    Save
                </button>
                <button className="secondary" onClick={onBack}>
                    Back
                </button>
                <button className="secondary" onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </SDialog>
    );
}
export default BulkUpdateLabelsDialog;
