import React from "react";
import { STag } from "@avalara/skylab-react";
import Tooltip from "./Tooltip";
import "./tooltip.css";

function getTooltipContent(invalidReasons) {
    const invalid = invalidReasons.length > 0 ? invalidReasons : [];
    const invalidList = [];
    invalid.forEach(item => invalidList.push(<li key={item.id}>{item.name}</li>));
    return invalidList;
}

export default function CertificateStatusTag(
    certificate,
    columnPositionRight,
    reviewPage,
    certificateStatuses
) {
    const hasInvalidReasons =
        certificate.invalidReasons != null && certificate.invalidReasons.length > 0;
    let tagValue = certificate.certificateStatus;
    let tagColor = "blue-light";
    if (reviewPage) {
        if (certificate.isExpired) tagValue = "Expired";
        else if (certificate.isValid && certificate.certificateStatus !== "PENDING-FUTURE")
            tagValue = "Valid";
        else if (certificate.certificateStatus === "PENDING-FUTURE") tagValue = "Pending-future";
        else tagValue = "Invalid";
    }

    if (tagValue === "Valid") tagColor = "green-dark";
    if (tagValue === "Invalid" || tagValue === "Missing") tagColor = "yellow-medium";

    if (
        certificateStatuses !== undefined &&
        certificateStatuses !== null &&
        certificateStatuses === "REVOKED"
    ) {
        tagValue = "Revoked";
        tagColor = "yellow-medium";
    } else if (
        tagValue === "Invalid" &&
        hasInvalidReasons &&
        certificate.invalidReasons.some(e => e.name === "PAUSED" || e.name === "REVOKED")
    ) {
        tagValue = "Paused";
        tagColor = "yellow-medium";
    }
    if (hasInvalidReasons && !reviewPage && tagValue === "Invalid") {
        tagValue += " ...";

        return (
            <div className="tooltip">
                <STag
                    color={tagColor}
                    className={`statusTag ${reviewPage ? "" : "margin-left-xs"}`}>
                    <span slot="content">{tagValue}</span>
                </STag>
                <Tooltip
                    tooltipData={getTooltipContent(certificate.invalidReasons)}
                    columnPositionRight={columnPositionRight}
                />
            </div>
        );
    }
    return (
        <STag color={tagColor} className={`statusTag ${reviewPage ? "" : "margin-left-xs"}`}>
            <span slot="content">{tagValue}</span>
        </STag>
    );
}
