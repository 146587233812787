import React, { useCallback, useEffect, useState } from "react";
import {
    SDialog,
    SRow,
    SCol,
    SSelect,
    SIcon,
    AuiUploader,
    SLoader,
    SAlert,
} from "@avalara/skylab-react";
import classnames from "classnames";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import expirationTimeFrames from "./ExpirationTimeFrames";
import {
    fetchCertificateForms,
    selectIsLoading,
    selecCertificateForms,
    getCertificateFormFile,
    selectFormData,
    selectIsFormLoading,
} from "../../../../app/contentSlice";
import { isEligibleUser, getSessionAccessToken } from "../../../../shared/sessionUtility";
import SaveResetConfirmationDialog from "./SaveResetConfirmationDialog";
import CancelConfirmationDialog from "./CancelConfirmationDialog";

import {
    setMatrixSetting,
    setCertificateFormsDialogOpen,
    setResetMatrixSetting,
    getMatrixFile,
    postMatrixFileAsync,
    selectMatrixFile,
    selectIsSavingMatrixFile,
    setMatrixFile,
    selectIsLoadingMatrixFile,
    setLoadingMatrixFile,
    checkIfSystemCollection,
    selectDefaultExist,
    setSavingMatrixFile,
    putDefaultMatrixSetting,
    selectCertificateFormsDialogOpen,
} from "../../../../app/settingsSlice";
import toast from "../../../../hooks/toast";
import FileRender from "../../../sharedComponents/FileRenderer";
import "../../settingsStyle.css";
import featureFlag from "../../../../featureToggler/featureFlag";
import { errorTag, getTimeFrame, noErrorTag } from "../../../../shared/Utils";
import SignatureValidationError from "../../../sharedComponents/SignaturePad/SignaturePadValidationError";
import getConfig from "../../../../config";

const { fvsConfigurationId } = getConfig();

function CertificateFormsDialog(props) {
    const [acceptReason, setAcceptReason] = useState(false);
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);
    const isDefault = useSelector(selectDefaultExist);
    const certificateFormsData = useSelector(selecCertificateForms, shallowEqual);
    const isEligibleUserEditMatrix = dispatch(
        isEligibleUser(featureFlag.settings.sendingCertExemptionMatrixUpdate)
    );
    const formData = useSelector(selectFormData, shallowEqual);
    const [isSaveConfirmationDialogOpen, setIsSaveConfirmationDialogOpen] = useState(false);
    const [isCancelConfirmationDialogOpen, setIsCancelConfirmationDialogOpen] = useState(false);
    const [certFormName, setCertFormName] = useState("");
    const [timeLength, setTimeLength] = useState("");
    const [expTimeFrame, setExpTimeFrame] = useState("");
    const [duration, setDuration] = useState("");
    const [durationError, setDurationError] = useState("");
    const [isDisplayMonth, setIsDisplayMonth] = useState(false);
    const [showMonthDayText, setMonthDayText] = useState(false);
    const [question, setQuestion] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const matrixFile = useSelector(selectMatrixFile);
    const [matrixTaxID, setMatrixTaxID] = useState("");
    const [defaultSource, setDefaultSource] = useState(true);
    const dialogClassName = classnames({ dialogExpanded: !defaultSource });
    const auiUploaderRef = React.createRef();
    const [uploadEvent, setUploadEvent] = useState("");
    const isSavingMatrixFile = useSelector(selectIsSavingMatrixFile);
    const isLoadingMatrixFile = useSelector(selectIsLoadingMatrixFile);
    const [showToast] = toast();
    const formLoading = useSelector(selectIsFormLoading);
    const previewClassName = classnames({ small: true, loading: formLoading });
    const [certFormObj, setCertFormObj] = useState(null);
    const certFormDiagOpen = useSelector(selectCertificateFormsDialogOpen);
    const [byPassConfirm, setByPassConfirm] = useState(false);
    const [taxIdError, setTaxIdError] = useState("");
    const [imageToggle, setImageToggle] = useState(false);
    const [anyError, setAnyError] = useState(false);
    const [formError, setFormError] = useState("");
    const [fileUploadError, setFileUploadError] = useState("");

    const resetAuiRef = () => {
        if (auiUploaderRef.current) {
            auiUploaderRef.current.reset();
        }
        const uploader = document.getElementById("aui-uploader");
        if (uploader) {
            uploader.reset();
        }
    };
    useEffect(() => {
        if (certFormName !== undefined || certFormName !== null) {
            const formObj = certificateFormsData?.data?.find(x => x.tag === certFormName);
            setCertFormObj(formObj);
        }
    }, [JSON.stringify(certificateFormsData)]);

    useEffect(() => {
        dispatch(fetchCertificateForms(props?.certificateForm?.regionName, true));
    }, [dispatch, props.certificateForm.regionName]);

    useEffect(() => {
        if (props.certificateForm.staticFile) {
            dispatch(getMatrixFile(props.certificateForm.id));
            setDefaultSource(false);
            setImageToggle(true);
        }
    }, [dispatch, props.certificateForm.id, props.certificateForm.staticFile]);

    useEffect(() => {
        dispatch(
            checkIfSystemCollection(
                props.certificateForm.exemptReasonTag,
                props.certificateForm.regionTag
            )
        );
    }, [props.certificateForm.regionTag, props.certificateForm.exemptReasonTag]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formData?.data) {
            localStorage.setItem("certificateForm", formData.data);
        }
        return () => {
            localStorage.setItem("certificateForm", "");
        };
    }, [formData]);
    useEffect(() => {
        if (
            props?.certificateForm?.templateTag !== null &&
            props?.certificateForm?.templateTag !== "NOTEMPLATE"
        )
            setCertFormName(props?.certificateForm?.templateTag);
        else setCertFormName(null);
        if (props?.certificateForm?.timeLength) {
            const propsTimeLength = props?.certificateForm?.timeLength;
            const { timeFrame, timeDuration, isMonth } = getTimeFrame(propsTimeLength);
            setTimeLength(timeFrame);
            setExpTimeFrame(timeFrame);
            setDuration(timeDuration);
            setIsDisplayMonth(isMonth);
            setMonthDayText(propsTimeLength?.match(/months|days/) != null);
        }
        setQuestion(props?.certificateForm?.question);
        setMatrixTaxID(props?.certificateForm?.matrixTaxId);
        dispatch(setMatrixFile(""));
        dispatch(setLoadingMatrixFile(false));
    }, [
        dispatch,
        props?.certificateForm?.matrixTaxId,
        props?.certificateForm?.question,
        props?.certificateForm?.templateTag,
        props?.certificateForm?.timeLength,
    ]);

    const handleCheckBoxChange = e => {
        setAcceptReason(e.target.checked);
    };

    const stringQualifer = imageToggle ? "Expiration Manually Provided" : "";
    const timeframes = expirationTimeFrames
        .filter(element => element.label !== stringQualifer)
        .map(element => {
            if (timeLength && element.id === timeLength) {
                return { label: element.label, value: element.id, selected: true };
            }
            return { label: element.label, value: element.id };
        });

    const getCertFormsOptionList = () => {
        let optionList = certificateFormsData?.data?.map(element => {
            const selectOption = {
                label: element.name,
                value: element.tag,
                selected: element.tag === certFormName,
            };
            return selectOption;
        });
        if (!props.outgoing) {
            optionList?.push({
                label: "Manual Submission Required",
                value: null,
                selected: certFormName === null,
            });
            if (typeof optionList === "undefined") {
                optionList = [
                    {
                        label: "Manual Submission Required",
                        value: null,
                        selected: true,
                    },
                ];
            }
        } else {
            optionList?.push({
                label: "Please select a form",
                value: null,
                selected: certFormName === null || certFormName === "NONE",
            });
        }
        return optionList;
    };

    const renderForm = async () => {
        await dispatch(getCertificateFormFile(certFormObj?.s3_UUID, certFormObj?.fileName));

        window.open(`${window.location.origin}/certificate-forms`, "_blank", "noopener,noreferrer");
    };

    const saveMatrixSetting = async byPassConfirmation => {
        if (showMonthDayText && (!duration || duration <= 0)) {
            setDurationError("Enter a number.");
            return false;
        }
        setIsUpdate(true);
        let origTemplateFile = null;
        if (props.outgoing && !defaultSource) {
            if (matrixFile && uploadEvent?.detail?.files[0]) {
                origTemplateFile = uploadEvent.detail.files[0].name;
            } else if (uploadEvent?.detail?.files[0]) {
                origTemplateFile = uploadEvent?.detail?.files[0].name;
            } else if (props?.certificateForm?.staticFile) {
                origTemplateFile = props.certificateForm.origTemplateFile;
            }
        }

        const matrixSetting = {
            id: props?.certificateForm?.id,
            templateTag: certFormName,
            timeLength: showMonthDayText ? `${duration} ${timeLength}` : timeLength,
            question,
            matrixTaxID,
            outgoing: true,
            origTemplateFile,
        };
        await dispatch(setMatrixSetting(matrixSetting));
        if (byPassConfirmation) {
            dispatch(putDefaultMatrixSetting(matrixSetting.id));
        } else {
            setIsSaveConfirmationDialogOpen(true);
        }
        return true;
    };

    const resetMatrixSetting = () => {
        setIsUpdate(false);

        const resetMatrixData = {
            id: props?.certificateForm?.id,
            regionName: props?.certificateForm?.regionName,
            reasonName: props?.certificateForm?.exemptReasonName,
        };
        dispatch(setResetMatrixSetting(resetMatrixData));
        setIsSaveConfirmationDialogOpen(true);
    };

    const cancelDialog = () => {
        if (
            props.certificateForm.question !== question ||
            (props.certificateForm.templateTag &&
                props.certificateForm.templateTag !== certFormName) ||
            expTimeFrame !== timeLength
        ) {
            setIsCancelConfirmationDialogOpen(true);
        } else {
            setIsCancelConfirmationDialogOpen(false);
            dispatch(setCertificateFormsDialogOpen(false));
        }
    };

    const onSelectChange = (type, e) => {
        setFormError("");
        setByPassConfirm(true);
        setAnyError(false);
        const value = e?.detail?.item?.value;
        if (type === "certForms") {
            setCertFormName(value);
            const formObj = certificateFormsData?.data?.find(x => x.tag === value);
            setCertFormObj(formObj);
        } else {
            setTimeLength(value);
        }
        setMonthDayText(value?.match(/months|days/) != null);
        const isMonthPresent = value?.match(/months/) != null;
        setIsDisplayMonth(isMonthPresent);
        setDuration(isMonthPresent ? 6 : 14);
        setDurationError("");
    };

    const handleChangeInput = setter => {
        return event => {
            if (event.target.value && event.target.className.match(/error/)) {
                if (event.target.name === "duration") {
                    setDurationError("");
                }
            }
            if (event.target.name === "duration" && event.target.value.match(/^\d+$/) == null) {
                setByPassConfirm(true);
                setter("");
            } else {
                setByPassConfirm(true);
                setter(event.target.value);
            }
            setAnyError(false);
            setTaxIdError("");
        };
    };

    const handleRadioChange = e => {
        if (defaultSource) {
            dispatch(getMatrixFile(props.certificateForm.id));
        }
        if (e.target.id === "fileImage") {
            setImageToggle(true);
        } else {
            setImageToggle(false);
        }
        setByPassConfirm(true);
        setDefaultSource(!defaultSource);
    };

    const handleValidateEvent = useCallback(
        e => {
            const { filesPassedValidation, filesFailedValidation } = e.detail;
            if (filesPassedValidation.length > 0) {
                if (uploadEvent.detail.files[0]) {
                    const fileObj = uploadEvent.detail.files[0];
                    const FileName = uploadEvent.detail.files[0].name;
                    const uploadData = new FormData();
                    uploadData.append("File", fileObj, FileName);
                    dispatch(postMatrixFileAsync(props?.certificateForm?.id, uploadData));
                }
                resetAuiRef();
            }
            if (filesFailedValidation.length > 0) {
                showToast("error", `${filesFailedValidation[0].message}`);
                resetAuiRef();
            }
        },
        [uploadEvent]
    );

    const handleErrorEvent = useCallback(() => {
        resetAuiRef();
    }, []);

    const checkValidation = () => {
        let valid = false;
        let matrixFail = false;
        let imageFail = false;

        if (matrixTaxID === "" || !matrixTaxID) {
            setTaxIdError("error");
            matrixFail = true;
        }
        if ((certFormName === "" || !certFormName || certFormName === "NONE") && defaultSource) {
            setFormError("error");
            imageFail = true;
        } else if (!matrixFile && !defaultSource) {
            setFileUploadError("error");
            imageFail = true;
        }

        if (matrixFail === true || imageFail === true) {
            setAnyError(true);
        } else {
            valid = true;
        }

        return valid;
    };

    const handleSaveEvent = () => {
        if (checkValidation()) {
            dispatch(setCertificateFormsDialogOpen(false));
            saveMatrixSetting(byPassConfirm);
            resetAuiRef();
        }
    };

    function handleAttachEvent(event) {
        dispatch(setSavingMatrixFile(true));
        setFileUploadError("");
        setAnyError(false);
        if (event.detail.files[0]) {
            setByPassConfirm(true);
            const FileName = event.detail.files[0].name;
            const fileNameExtension = FileName.slice(FileName.lastIndexOf(".") + 1);

            if (
                !fileNameExtension.toLowerCase().includes("pdf") &&
                !fileNameExtension.toLowerCase().includes("jpg") &&
                !fileNameExtension.toLowerCase().includes("jpeg") &&
                !fileNameExtension.toLowerCase().includes("png")
            ) {
                showToast("error", "File type not allowed.");
                resetAuiRef();
            } else {
                setUploadEvent(event);
            }
        }
    }

    const expirationTimeFrame = (
        <SRow>
            <SCol span="7">
                <label
                    htmlFor="expirationTimeframe"
                    id="lbl-expiration-time-frame"
                    className={
                        props.outgoing && !defaultSource ? "margin-top-md required" : "required"
                    }>
                    Expiration timeframe
                </label>
                <SSelect
                    id="expirationTimeframe"
                    inputId="expirationTimeframe"
                    aria-labelledby="lbl-expiration-time-frame"
                    noSearch
                    disabled={!isEligibleUserEditMatrix ? "disabled" : null}
                    name="expirationTimeframe"
                    value={timeLength}
                    optionsList={timeframes}
                    onS-select={e => onSelectChange("expirationTimeframes", e)}
                />
            </SCol>
            {showMonthDayText ? (
                <SCol span="auto">
                    <label
                        htmlFor="duration"
                        className={
                            props.outgoing && !defaultSource ? "required margin-top-md" : "required"
                        }>
                        {isDisplayMonth ? "Months" : "Days"}
                    </label>
                    <input
                        id="duration"
                        name="duration"
                        type="text"
                        className={`small-textbox ${durationError !== "" ? errorTag : noErrorTag}`}
                        onChange={handleChangeInput(setDuration)}
                        value={duration}
                    />
                    {durationError ? (
                        <div className="input-msg">
                            <SIcon name="alert-circle-filled" aria-hidden="true" />
                            <span name="invalid-duration">{durationError}</span>
                        </div>
                    ) : null}
                </SCol>
            ) : null}
        </SRow>
    );

    return (
        <>
            <SDialog
                open={certFormDiagOpen}
                id="certificateForms-dialog"
                className={dialogClassName}
                onS-dismiss={cancelDialog}
                aria-modal="true">
                <div slot="header" id="certificateForms">
                    {props?.certificateForm?.regionName} exemption for{" "}
                    {props?.certificateForm?.exemptReasonName?.toLowerCase()}
                </div>

                <div slot="body">
                    <SRow>
                        <SCol span="18" className="pad-right-none col-expanded">
                            <div>
                                <div hidden>
                                    <input
                                        name="isSingle"
                                        id="acceptReason"
                                        type="checkbox"
                                        aria-label="This company accepts certificates for this reason."
                                        checked={acceptReason}
                                        onChange={e => handleCheckBoxChange(e)}
                                    />
                                    <label htmlFor="acceptReason" className="margin-top-none">
                                        This company accepts certificates for this reason.
                                    </label>
                                </div>

                                {props.outgoing === true ? (
                                    <React.Fragment>
                                        <SRow>
                                            <SCol>
                                                {anyError === true ? (
                                                    <SAlert
                                                        status="error"
                                                        id="error-alert"
                                                        noDismiss
                                                        className="margin-left-">
                                                        Check your entries before trying to save
                                                        again.
                                                    </SAlert>
                                                ) : (
                                                    " "
                                                )}
                                                <label
                                                    htmlFor="matrixTaxID"
                                                    className="margin-top-xs required">
                                                    Taxpayer ID
                                                </label>
                                                <input
                                                    id="matrixTaxID"
                                                    name="matrixTaxID"
                                                    type="input"
                                                    className={taxIdError}
                                                    value={matrixTaxID}
                                                    onChange={handleChangeInput(setMatrixTaxID)}
                                                />
                                                {taxIdError ? (
                                                    <SignatureValidationError fieldName="TaxpayerID" />
                                                ) : null}
                                            </SCol>
                                        </SRow>

                                        <h3 className="margin-bottom-none">
                                            Select a source certificate to use
                                        </h3>
                                        <SRow>
                                            <SCol span="auto">
                                                <input
                                                    id="formLibrary"
                                                    name="formLibrary"
                                                    type="radio"
                                                    value="formLibrary"
                                                    checked={defaultSource}
                                                    onChange={e => handleRadioChange(e)}
                                                />
                                                <label
                                                    htmlFor="formLibrary"
                                                    className="radio-button-fix">
                                                    Exemption certificate form library
                                                </label>
                                            </SCol>
                                            <SCol span="auto">
                                                <input
                                                    id="fileImage"
                                                    name="fileImage"
                                                    type="radio"
                                                    value="fileImage"
                                                    checked={!defaultSource}
                                                    onChange={e => handleRadioChange(e)}
                                                />
                                                <label
                                                    htmlFor="fileImage"
                                                    className="radio-button-fix">
                                                    Uploaded file image
                                                </label>
                                            </SCol>
                                        </SRow>
                                    </React.Fragment>
                                ) : null}
                            </div>

                            {defaultSource ? (
                                <SRow className="pad-top-sm">
                                    <SCol span={props.outgoing === true ? "10" : "5"}>
                                        <SRow>
                                            <SCol>
                                                <SCol>
                                                    <label
                                                        htmlFor="certForms"
                                                        id="lbl-cert-forms"
                                                        className="required">
                                                        Certificate form
                                                    </label>
                                                    <SSelect
                                                        name="certForms"
                                                        id="certForms"
                                                        inputId="certForms"
                                                        aria-labelledby="lbl-cert-forms"
                                                        className={
                                                            formError ? "error" : "pad-right-none"
                                                        }
                                                        noSearch
                                                        disabled={
                                                            !isEligibleUserEditMatrix
                                                                ? "disabled"
                                                                : null
                                                        }
                                                        loading={loading}
                                                        value={certFormName}
                                                        optionsList={getCertFormsOptionList()}
                                                        onS-select={e =>
                                                            onSelectChange("certForms", e)
                                                        }
                                                    />
                                                    {formError ? (
                                                        <SignatureValidationError fieldName="Certificate Form" />
                                                    ) : null}
                                                </SCol>
                                            </SCol>
                                        </SRow>
                                        <SRow className="pad-top-xl">
                                            <SCol>{expirationTimeFrame}</SCol>
                                        </SRow>
                                    </SCol>
                                    <SCol
                                        span="1"
                                        className="margin-top-md pad-top-sm pad-left-none">
                                        <button
                                            className={`link font-bold ${previewClassName}`}
                                            aria-label="View certificate form"
                                            disabled={
                                                certFormName == null ||
                                                loading ||
                                                !isEligibleUserEditMatrix
                                            }
                                            onClick={renderForm}>
                                            <SIcon
                                                name="zoom-in"
                                                disabled={loading || !isEligibleUserEditMatrix}
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </SCol>
                                </SRow>
                            ) : (
                                <React.Fragment>
                                    <AuiUploader
                                        id="aui-uploader"
                                        basic
                                        ref={auiUploaderRef}
                                        callbackUrl="/"
                                        multiple={false}
                                        stagingInlineCta={false}
                                        className="margin-left-lg align-left"
                                        ctaText="Upload a file"
                                        accept="application/pdf, image/jpeg"
                                        stagingExternalCta={false}
                                        configurationId={fvsConfigurationId}
                                        requestCurrentAccessToken={getSessionAccessToken}
                                        disabled={!isEligibleUserEditMatrix}
                                        onS-attach={e => {
                                            handleAttachEvent(e);
                                        }}
                                        retries={3}
                                        onS-validate={handleValidateEvent}
                                        onS-error={handleErrorEvent}>
                                        <div slot="guidelines">
                                            Your PDF or JPG file can be up to 15 MB in size.
                                        </div>
                                        <div
                                            slot="loading"
                                            className="flex flex-dir-col align-items-center pad-all-xl">
                                            <SLoader
                                                loading
                                                className="margin-bottom-sm"
                                                aria-live="polite"
                                            />
                                            <h3 className="margin-all-none">
                                                We&apos;re importing the file
                                            </h3>
                                            <p className="margin-all-none">
                                                This might take a while.
                                            </p>
                                        </div>
                                    </AuiUploader>
                                    {fileUploadError ? (
                                        <SCol>
                                            <div className="pad-top-xs margin-left-sm margin-top-xs">
                                                <SignatureValidationError
                                                    fieldName="FileUpload"
                                                    type
                                                />
                                            </div>
                                        </SCol>
                                    ) : null}
                                    <SRow>
                                        <SCol span="auto">{expirationTimeFrame}</SCol>
                                    </SRow>
                                </React.Fragment>
                            )}
                        </SCol>
                        {(isSavingMatrixFile || isLoadingMatrixFile) && !defaultSource ? (
                            <SLoader
                                loading="loading"
                                className="margin-bottom-sm"
                                aria-live="polite"
                            />
                        ) : null}
                        {matrixFile &&
                        !defaultSource &&
                        !(isSavingMatrixFile || isLoadingMatrixFile) ? (
                            <SCol span="2" className="pad-left-none">
                                <FileRender
                                    fileData={matrixFile}
                                    scale={0.3}
                                    imageClass="matrix-file"
                                    documentClass="matrix-pdf-file"
                                />
                            </SCol>
                        ) : null}
                    </SRow>
                </div>
                <div slot="footer">
                    <button
                        className="primary pad-left-xl pad-right-xl"
                        id="save-form-button"
                        disabled={loading || !isEligibleUserEditMatrix || isSavingMatrixFile}
                        onClick={() => handleSaveEvent()}>
                        Save
                    </button>
                    <button
                        className="secondary pad-left-xl pad-right-xl"
                        disabled={isSavingMatrixFile}
                        onClick={cancelDialog}>
                        Cancel
                    </button>
                    <button
                        className="link font-bold margin-top-xs"
                        disabled={
                            loading || !isEligibleUserEditMatrix || isSavingMatrixFile || !isDefault
                        }
                        onClick={resetMatrixSetting}>
                        <span className="margin-left-xs">Reset to defaults</span>
                    </button>
                </div>
            </SDialog>

            {isSaveConfirmationDialogOpen ? (
                <SaveResetConfirmationDialog
                    isDeleteCertificateFileDialogOpen={isSaveConfirmationDialogOpen}
                    setIsSaveConfirmationDialogOpen={setIsSaveConfirmationDialogOpen}
                    title={isUpdate ? "Update" : "Reset"}
                    message={
                        isUpdate
                            ? "Are you sure you want to edit the default settings for this region and exempt reason? Doing so will disable any future updates to this information by Avalara."
                            : "Are you sure you want to reset the default settings for this region and exempt reason? Doing so will disable any future updates to this information by Avalara."
                    }
                />
            ) : null}

            {isCancelConfirmationDialogOpen ? (
                <CancelConfirmationDialog
                    isCancelConfirmationDialogOpen={isCancelConfirmationDialogOpen}
                    setIsCancelConfirmationDialogOpen={setIsCancelConfirmationDialogOpen}
                />
            ) : null}
        </>
    );
}

export default CertificateFormsDialog;
