import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { SRow, SCol, SIcon } from "@avalara/skylab-react";
import { useDispatch } from "react-redux";
import { getPageTitle, isEligibleUser } from "../../../../shared/sessionUtility";
import FeatureToggler from "../../../../featureToggler/FeatureToggler";
import CertificateLabelsGrid from "./CertificateLabelsGrid";
import CertificateLabelsFilter from "./CertificateLabelsFilter";
import featureFlag from "../../../../featureToggler/featureFlag";

const CertificateLabels = React.memo(() => {
    const dispatch = useDispatch();
    const [openAddUpdateDialog, setOpenAddUpdateDialog] = useState(false);
    const [addOrEdit, setAddOrEdit] = useState("");
    const isEligibleUserAddCertificateLabels = !dispatch(
        isEligibleUser(featureFlag.settings.addLabel)
    );

    const handleAddLabel = () => {
        setOpenAddUpdateDialog(true);
        setAddOrEdit("Add");
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Certificates labels"))}</title>
            </Helmet>

            <div>
                <p>View and manage additional labels for editing or reviewing certificates.</p>
                <SRow className="align-items-end pad-bottom-sm">
                    <SCol span="8">
                        <SRow>
                            <SCol span="md-6 xl-6 5" className="pad-bottom-none">
                                <CertificateLabelsFilter />
                            </SCol>
                        </SRow>
                    </SCol>
                    <FeatureToggler category="settings" id="addLabel">
                        <SCol span="4">
                            <span className="flex justify-content-end">
                                <button
                                    id="add-customerLabel-button"
                                    className="primary inline margin-left-sm"
                                    disabled={isEligibleUserAddCertificateLabels}
                                    onClick={() => handleAddLabel()}>
                                    <SIcon
                                        name="plus"
                                        className="margin-right-xs"
                                        aria-label="Add label"
                                    />
                                    Add label
                                </button>
                            </span>
                        </SCol>
                    </FeatureToggler>
                </SRow>
            </div>
            <FeatureToggler category="settings" id="viewLabels">
                <CertificateLabelsGrid
                    openAddUpdateDialog={openAddUpdateDialog}
                    setOpenAddUpdateDialog={setOpenAddUpdateDialog}
                    addOrEdit={addOrEdit}
                    setAddOrEdit={setAddOrEdit}
                />
            </FeatureToggler>
        </React.Fragment>
    );
});

export default CertificateLabels;
