import React, { useState } from "react";
import { SIcon, SLoader } from "@avalara/skylab-react";
import { useSelector } from "react-redux";
import SelectCompanyDialog from "./SelectCompanyDialog";
import RolePermissionAll from "./RolePermissionAll";
import RolePermissionCompanyWise from "./RolePermissionCompanyWise";
import {
    selectCompaniesSelectedCount,
    selectIsCompanyLoading,
    selectIsAllCompany,
} from "../../app/roleManagementSlice";
import "./roleManagement.css";

function UserCompanyAccess() {
    const companiesSelectedCount = useSelector(selectCompaniesSelectedCount);
    const isCompanyLoading = useSelector(selectIsCompanyLoading);
    const isAllCompany = useSelector(selectIsAllCompany);
    const [radioValue, setRadioValue] = useState(isAllCompany ? "all" : "select companies");
    const [selectCompanyDialog, setSelectCompnayDialog] = useState(false);

    const handleRadioChange = e => {
        setRadioValue(e.target.value);
    };

    return (
        <>
            <label className="text-md">Access</label>
            <div>
                <span>
                    Allow this user to access all companies in the account, or limit their access to
                    specific companies
                </span>
            </div>
            <fieldset role="radiogroup">
                <input
                    name="radio-all"
                    id="radio-all"
                    type="radio"
                    value="all"
                    className="pad-top-xs"
                    checked={radioValue === "all"}
                    onChange={e => handleRadioChange(e)}
                />
                <label htmlFor="radio-all">
                    <span className="text-sm-strong">All companies</span>
                    <br /> Access to all companies in the account
                </label>
                <br />
                <input
                    name="radio-select-companies"
                    id="radio-select-companies"
                    type="radio"
                    value="select companies"
                    className="pad-top-xs"
                    checked={radioValue === "select companies"}
                    onChange={e => handleRadioChange(e)}
                />
                <label htmlFor="radio-select-companies">
                    <span className="text-sm-strong">Select companies</span>
                    <br /> Access to specific companies in the account
                    <br />
                    {radioValue === "select companies" ? (
                        <>
                            <span className="text-underline	pad-right-sm">
                                {isCompanyLoading ? (
                                    <SLoader
                                        id="page-loader"
                                        className="small"
                                        aria-live="polite"
                                        loading
                                    />
                                ) : (
                                    ` ${companiesSelectedCount} companies selected`
                                )}
                            </span>
                            <button
                                className="ghost-blue icon-leading link"
                                id="edit-button"
                                onClick={() => setSelectCompnayDialog(true)}>
                                <SIcon name="edit-pencil" aria-hidden="true" />
                                Edit
                            </button>
                        </>
                    ) : null}
                </label>
            </fieldset>
            {radioValue === "all" ? <RolePermissionAll /> : <RolePermissionCompanyWise />}
            <SelectCompanyDialog
                isOpen={selectCompanyDialog}
                closeDialog={setSelectCompnayDialog}
            />
        </>
    );
}

export default React.memo(UserCompanyAccess);
