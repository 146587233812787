import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SIcon } from "@avalara/skylab-react";
import CertificateOutgoingRequestFormsGrid from "./CertificateOutgoingRequestFormsGrid";
import CertificateOutgoingRequestFormsFilter from "./CertificateOutgoingRequestFormsFilter";
import { fetchCertificateRequestFormsAPI } from "../../../../app/settingsSlice";
import { setPageToNull } from "../../../../app/savedSearchesSlice";
import CollectionsDialog from "./CollectionDialog";

const VendorRequestForms = React.memo(() => {
    const noData = false;
    const dispatch = useDispatch();
    const [colDiag, setColDiag] = useState(false);

    useEffect(() => {
        dispatch(fetchCertificateRequestFormsAPI(true, true));
        return () => {
            dispatch(setPageToNull([]));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const DOMelement = (
        <div>
            <div>
                <span hidden={!noData}>
                    <h2> Tell us which certificates to send to your vendors </h2>
                    <button className="ghost-blue" onClick={() => setColDiag(true)}>
                        <SIcon name="plus" aria-hidden="true" /> Define why and where you&#39;re
                        exempt
                    </button>
                </span>
                <span hidden={noData}>
                    <CertificateOutgoingRequestFormsFilter />
                    <CertificateOutgoingRequestFormsGrid />
                </span>
                <div hidden={!colDiag}>
                    <CollectionsDialog diagStatus={setColDiag} />
                </div>
            </div>
        </div>
    );
    return DOMelement;
});

export default VendorRequestForms;
