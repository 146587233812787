import React, { useCallback, useState } from "react";
import { SRow, SCol, SIcon, SBox, SLoader } from "@avalara/skylab-react";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument } from "pdf-lib";
import { useSelector, shallowEqual } from "react-redux";
import { selectFormData, selectIsFormLoading, selectActiveForm } from "../../../app/contentSlice";
import axiosInstanceWithoutInterceptor from "../../../axiosWithoutInterceptor";
import { buildApiV3Url } from "../../../shared/Utils";
import "../taxContent.css";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const CertificateFormsContent = React.memo(() => {
    const formData = useSelector(selectFormData, shallowEqual);

    const isFormLoading = useSelector(selectIsFormLoading);
    const [numberPages, setNumberPages] = useState(null);
    const activeForm = useSelector(selectActiveForm, shallowEqual);

    const onDocumentLoadSuccess = useCallback(({ numPages }) => {
        setNumberPages(numPages);
    }, []);

    const downloadFile = () => {
        const downloadLink = document.createElement("a");
        downloadLink.href = formData.data;
        downloadLink.download = formData.fileName;
        downloadLink.click();
    };

    const setPrefillData = (prefillData, prefillPostData) => {
        let split;
        let object;
        let table;
        let column;
        let columnSplit;
        let prefillVal;
        let upperCaseColumns;
        let shiftedElement;
        const prefillMap = [];

        prefillData.forEach(fieldMap => {
            split = fieldMap.gencertCertcaptureField.attribute.split(".");
            object = fieldMap.gencertCertcaptureField.type;
            [table, column] = split;

            columnSplit = column.split("_");
            if (columnSplit.length > 1) {
                shiftedElement = columnSplit.shift();
                upperCaseColumns = columnSplit.map(a => a.charAt(0).toUpperCase() + a.substr(1));
                upperCaseColumns.unshift(shiftedElement);
                column = upperCaseColumns.join("");
            }

            prefillVal = prefillPostData?.[object]?.[table]?.[column];

            if (prefillVal) {
                prefillMap[fieldMap.gencertField.internalName] = prefillVal;
            }
        });
        return prefillMap;
    };

    const downloadPrefilled = async () => {
        const prefillMap = await axiosInstanceWithoutInterceptor.get(
            buildApiV3Url(`form-masters/get-prefill-map/${activeForm}`),
            {
                withCredentials: true,
            }
        );

        const client = JSON.parse(localStorage.getItem("generalSettings"));

        const builtData = {
            Client: {
                State: {
                    name: client?.state?.name,
                    initials: client?.state?.initials,
                },
                Country: {
                    name: client?.country?.name,
                    initials: client?.country?.initials,
                },
                Client: {
                    fullAddress: [
                        client.addressLine1,
                        client.addressLine2,
                        `${client.city},`,
                        client?.state?.initials,
                        client.zip,
                    ].join(" "),
                    longAddress: [client.addressLine1, client.addressLine2].join(" "),
                    cityStateZip: [client.city, client?.state?.initials, client.zip].join(" "),
                },
            },
        };

        [builtData.ClientFaxEmail] = client.clientsFaxEmails;

        for (const key in client) {
            if (client[key]) {
                builtData.Client.Client[key] = client[key];
            }
        }

        const builtPrefillMap = setPrefillData(prefillMap.data, builtData);
        const pdfDoc = await PDFDocument.load(formData.data);
        const pdfForm = pdfDoc.getForm();
        const pdfFields = pdfDoc.getForm().getFields();
        const pdfFieldNames = pdfFields.map(f => f.getName());

        for (const [key, value] of Object.entries(builtPrefillMap)) {
            if (pdfFieldNames.includes(key)) {
                pdfForm.getTextField(key).setText(value);
            }
        }

        const pdfBytes = await pdfDoc.save();
        const pdfFile = new Blob([pdfBytes], {
            type: "application/pdf",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(pdfFile);
        downloadLink.download = formData.fileName;
        downloadLink.click();
    };

    const fileLoader = (
        <SBox className="no-border">
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        </SBox>
    );

    return (
        <SRow>
            <SCol
                className="flex justify-content-end button-box margin-top-right-md pad-top-bottom-lg"
                span="12">
                <button
                    id="download-blank-btn"
                    onClick={downloadFile}
                    className="download-blank margin-all-sm secondary"
                    disabled={isFormLoading || formData?.data?.length === 0}>
                    <SIcon name="download" aria-hidden="true" /> Download blank
                </button>
                <button
                    id="download-blank-btn"
                    onClick={downloadPrefilled}
                    className="download-blank margin-all-sm primary"
                    disabled={isFormLoading || formData?.data?.length === 0}>
                    <SIcon name="download" aria-hidden="true" /> Download prefilled
                </button>
            </SCol>
            <SCol span="12" className="content-border">
                {!isFormLoading ? (
                    <Document
                        file={formData.data}
                        loading={fileLoader}
                        noData={
                            isFormLoading && formData?.data?.length === 0
                                ? fileLoader
                                : "no file found"
                        }
                        onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numberPages), (el, index) => (
                            <Page
                                className="pdf-border"
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={1.75}
                            />
                        ))}
                    </Document>
                ) : (
                    fileLoader
                )}
            </SCol>
        </SRow>
    );
});

export default CertificateFormsContent;
