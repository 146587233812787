import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import {
    getCustomerLabels,
    selectCustomerLabels,
    selectIsLoadingCustomerLabels,
    setCustomerLabels,
} from "../../app/customerSlice";

function SelectCustomerLabel(props) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingCustomerLabels);

    const optionsList = useSelector(selectCustomerLabels, shallowEqual).map(element => {
        const selectOption = {
            label: element.name,
            value: element.id,
            selected: false,
        };
        return selectOption;
    });

    useEffect(() => {
        dispatch(getCustomerLabels());

        return () => {
            dispatch(setCustomerLabels([]));
        };
    }, [dispatch]);

    const customerLabelOptions = optionsList.map(element => {
        if (props.value?.includes(parseInt(element.value, 10))) {
            return { ...element, selected: true };
        }
        return element;
    });

    return (
        <React.Fragment>
            <label
                htmlFor="SelectCustomerLabel"
                className="margin-top-sm"
                id="lbl-SelectCustomerLabel">
                Customer labels
            </label>
            <SSelect
                name="SelectCustomerLabel"
                inputId="SelectCustomerLabel"
                multiple
                selectionOptional
                loading={isLoading}
                disabled={props?.loading}
                optionsList={customerLabelOptions}
                value={[...props.value.map(customerLabelId => `${customerLabelId}`)]}
                onS-select={e => props?.onAdd({ value: parseInt(e.detail.item.value, 10) })}
                onS-deselect={e =>
                    props?.onRemove(
                        { value: parseInt(e.detail.item.value, 10) },
                        e.detail.isDeselectAll
                    )
                }
                onS-close={e => (props.onClose ? props.onClose(e) : null)}
            />
        </React.Fragment>
    );
}

export default React.memo(SelectCustomerLabel);
