import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import { isEligibleUser } from "../../../shared/sessionUtility";
import "../../review/validation.css";
import featureFlag from "../../../featureToggler/featureFlag";
import { deleteRegionAPI, fetchRegionsWithNexusDetailsAPI } from "../../../app/regionSlice";

function DeleteRegionDialog(props) {
    const [submitting, setSubmitting] = useState(false);
    const deleteButton = classnames({
        primary: true,
        small: true,
        loading: submitting,
    });
    const dispatch = useDispatch();
    const isEligibleUserSettingsCustomRegionDelete = dispatch(
        isEligibleUser(featureFlag.settings.deleteExemptionRegion)
    );
    async function backToRegions() {
        dispatch(fetchRegionsWithNexusDetailsAPI());
    }

    const deleteRegion = async () => {
        setSubmitting(true);
        const deleteRegionObj = {
            id: props?.region?.id,
            name: props?.region?.name,
        };
        await dispatch(deleteRegionAPI(deleteRegionObj)).then(response => {
            if (response.status === 200) {
                backToRegions();
            }
            setSubmitting(false);
            props.setIsDeleteRegionDialogOpen(false);
        });
    };

    return (
        <SDialog
            id="deleteRegion-dialog"
            open={props.isDeleteRegionDialogOpen}
            onS-dismiss={() => {
                props.setIsDeleteRegionDialogOpen(false);
            }}
            aria-modal="true">
            <div slot="header" id="deleteRegion">
                Are you sure you want to delete this region?
            </div>
            <div slot="body">
                <span>
                    You can&apos;t undo this action, but you can recreate this region later.
                </span>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => props.setIsDeleteRegionDialogOpen(false)}>
                    Cancel
                </button>
                <button
                    disabled={!isEligibleUserSettingsCustomRegionDelete}
                    className={deleteButton}
                    type="button"
                    onClick={() => deleteRegion()}>
                    Yes, I&apos;m sure
                </button>
            </div>
        </SDialog>
    );
}

export default DeleteRegionDialog;
