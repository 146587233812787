import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { CRUDOperation } from "../shared/constants";
import { buildApiV3UrlWithQuery, buildApiV3Url } from "../shared/Utils";
import {
    parseSuccessResponse,
    parseGetAllObjResponse,
    cannotDeleteRecordResponse,
    parseResponseMessage,
} from "../shared/responseUtils";
import toast from "../hooks/toast";

const initialState = {
    isLoading: false,
    isDeleting: false,
    isAddOrEditLoading: false,
    enableLoading: "",
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    sortColumn: ["fieldName", true],
    formState: {
        fieldName: "",
    },
    cannotDeleteRecordMessage: "",
};

export const customerCustomFieldSlice = createSlice({
    name: "customerCustomField",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setIsDeleting: (state, action) => {
            state.isDeleting = action.payload;
        },
        setIsAddOrEditLoading: (state, action) => {
            state.isAddOrEditLoading = action.payload;
        },
        setEnableLoading: (state, action) => {
            state.enableLoading = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setIsLimitReached: (state, action) => {
            state.setIsLimitReached = action.payload;
        },
        setCannotDeleteRecordMessage: (state, action) => {
            state.cannotDeleteRecordMessage = action.payload;
        },
    },
});

const [showToast] = toast();
export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setFormState,
    clearFormState,
    setIsDeleting,
    setIsAddOrEditLoading,
    setEnableLoading,
    setSortColumn,
    setIsLimitReached,
    setCannotDeleteRecordMessage,
} = customerCustomFieldSlice.actions;

export const selectIsLoading = state => {
    return state.customerCustomField.isLoading;
};
export const selectPage = state => {
    return state.customerCustomField.page;
};
export const selectFormState = state => {
    return state.customerCustomField.formState;
};
export const selectIsDeleting = state => {
    return state.customerCustomField.isDeleting;
};
export const selectIsAddOrEditLoading = state => {
    return state.customerCustomField.isAddOrEditLoading;
};
export const selectEnableLoading = state => {
    return state.customerCustomField.enableLoading;
};
export const selectSortColumn = state => {
    return state.customerCustomField.sortColumn;
};
export const selectIsLimitReached = state => {
    return state.customerCustomField.setIsLimitReached;
};

export const selectCannotDeleteRecordMessage = state => {
    return state.customerCustomField.cannotDeleteRecordMessage;
};

export const fetchCustomerCustomFields =
    (top, skip, paginateDetails) => async (dispatch, getState) => {
        const { isLoading, sortColumn, formState } = getState().customerCustomField;
        const { rowsPerPage } = getState().session;
        top = !top ? rowsPerPage : top;
        const { fieldName } = formState;

        if (isLoading) {
            return;
        }
        let filterString = "";
        dispatch(setIsLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
        if (fieldName) {
            filterString = `fieldName contains '${formState.fieldName}'`;
        }

        const apiURI = buildApiV3UrlWithQuery(
            `customer-custom-fields`,
            filterString,
            null,
            top,
            skip,
            sort
        );
        const response = await axios.get(`${apiURI}`, { withCredentials: true });
        if (!parseSuccessResponse(CRUDOperation.ReadAll, response)?.recordFound) {
            dispatch(setPageToNull());
            dispatch(setIsLimitReached(false));
        } else {
            const readAllResponse = parseGetAllObjResponse(response);
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails?.pageNumber,
                      startIndex: paginateDetails?.startIndex,
                      endIndex: paginateDetails?.endIndex,
                      rowsPerPage: paginateDetails?.rowsPerPage,
                      totalRecords: readAllResponse?.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: readAllResponse?.count,
                  };
            dispatch(
                setPage({
                    data: readAllResponse?.value,
                    paginate: paginateData,
                })
            );
            if (paginateData?.totalRecords >= 50) dispatch(setIsLimitReached(true));
            else dispatch(setIsLimitReached(false));
        }
        dispatch(setIsLoading(false));
    };

export const deleteCustomerCustomField = id => async (dispatch, getState) => {
    const { isDeleting } = getState().customerCustomField;
    let successResponse = true;
    if (isDeleting) {
        return false;
    }
    dispatch(setIsDeleting(true));
    const response = await axios.delete(buildApiV3Url(`customer-custom-fields/${id}`), {
        withCredentials: true,
    });

    if (cannotDeleteRecordResponse(response)) {
        dispatch(setCannotDeleteRecordMessage(parseResponseMessage(response)));
        successResponse = false;
    } else if (parseSuccessResponse(CRUDOperation.Delete, response)?.isSuccess) {
        showToast("success", "Custom field deleted.", "deleteCustomerCustomFieldToast", false);
        dispatch(fetchCustomerCustomFields());
        dispatch(setCannotDeleteRecordMessage(""));
    }
    dispatch(setIsDeleting(false));
    return successResponse;
};

export const AddOrUpdateCustomerCustomFields =
    (addOrEdit, payload, customerCustomFieldId) => async dispatch => {
        dispatch(setIsAddOrEditLoading(true));
        let success = false;
        if (addOrEdit === "Add") {
            await axios
                .post(buildApiV3Url(`customer-custom-fields`), payload, {
                    withCredentials: true,
                })
                .then(x => {
                    if (parseSuccessResponse(CRUDOperation.Create, x)?.isSuccess) {
                        showToast(
                            "success",
                            `Custom field added.`,
                            "custom-field-success-toast",
                            false
                        );
                        success = true;
                        dispatch(fetchCustomerCustomFields());
                    } else {
                        success = false;
                    }
                });
        } else {
            await axios
                .put(buildApiV3Url(`customer-custom-fields/${customerCustomFieldId}`), payload, {
                    withCredentials: true,
                })
                .then(x => {
                    if (parseSuccessResponse(CRUDOperation.Update, x)?.isSuccess) {
                        showToast(
                            "success",
                            `Successfully updated customer custom field`,
                            "custom-field-success-toast",
                            false
                        );

                        success = true;
                        dispatch(fetchCustomerCustomFields());
                    } else {
                        success = false;
                    }
                });
        }
        dispatch(setIsAddOrEditLoading(false));
        return success;
    };

export default customerCustomFieldSlice.reducer;
