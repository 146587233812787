import React from "react";
import { SDialog, SBox } from "@avalara/skylab-react";

const ImportErrorDialog = React.memo(props => {
    const dialogName = "importErrorDialog";
    const closeImportErrorDialog = event => {
        if (event.detail.id === dialogName) props.setIsImportErrorDialogOpen(false);
    };

    return (
        <SDialog
            id={dialogName}
            open={props.isImportErrorDialogOpen}
            onS-dismiss={e => {
                closeImportErrorDialog(e);
            }}
            aria-modal="true"
            role="alertdialog">
            <div slot="header" id="importError">
                We couldn&apos;t import all or some items in this file
            </div>
            <div slot="body">
                <SBox>
                    {props.errorMessage ? (
                        <div>
                            <h4>We found error</h4>
                            {props.errorMessage}
                        </div>
                    ) : null}
                    {props.errorFile ? (
                        <div>
                            <h4>How do I fix the problem?</h4>
                            <h4>
                                Download the error file and scroll to the last column, where you
                                will find error information that can help you make changes that will
                                lead to a successful import
                            </h4>
                            <a href={props.errorFile}>Download error file (.xlsx)</a>
                        </div>
                    ) : null}
                    {!props.errorFile && !props.errorMessage ? (
                        <div>
                            <h4>This might be due to:</h4>
                            <ul className="margin-left-xl">
                                <li>An unrecognized file format</li>
                                <li>Extra commas in your file</li>
                                <li>
                                    Missing column headers, or headers that don&apos;t match our
                                    template exactly
                                </li>
                            </ul>
                        </div>
                    ) : null}
                </SBox>
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => props.setIsImportErrorDialogOpen(false)}>
                    OK, got it
                </button>
            </div>
        </SDialog>
    );
});

export default ImportErrorDialog;
