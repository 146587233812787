import React from "react";
import { SRow, SCol } from "@avalara/skylab-react";
import { useSelector } from "react-redux";
import Parser from "html-react-parser";
import { selectData, selectActiveEntities } from "../../../app/contentSlice";

const ExemptEntitiesContent = React.memo(() => {
    const pageData = useSelector(selectData);
    const activeEntities = useSelector(selectActiveEntities);

    const entities = pageData?.data?.find(element => {
        return element.id == activeEntities; /* eslint eqeqeq: 0 */
    });

    return (
        <React.Fragment>
            <SRow>
                <SCol>{entities?.itemname ? <h2>{entities?.itemname}</h2> : null}</SCol>
            </SRow>
            <SRow>
                {entities?.explanationname ? (
                    <div>
                        <SCol className="font-bold">Question</SCol>
                        <SCol span="12">{Parser(entities.explanationname)}</SCol>
                    </div>
                ) : null}
            </SRow>
            <SRow className="margin-top-md">
                {entities?.answer ? (
                    <div>
                        <SCol className="font-bold">Answer</SCol>
                        <SCol span="12">{entities.answer}</SCol>
                    </div>
                ) : null}
            </SRow>
            <SRow className="margin-top-md">
                {entities?.description ? (
                    <div>
                        <SCol className="font-bold">Explanation</SCol>
                        <SCol span="12">{Parser(entities.description)}</SCol>
                    </div>
                ) : null}
            </SRow>
            <SRow className="margin-top-md">
                {entities?.itemexamples ? (
                    <div>
                        <SCol className="font-bold">Background Information</SCol>
                        <SCol span="12">{Parser(entities.itemexamples)}</SCol>
                    </div>
                ) : null}
            </SRow>
            <SRow className="margin-top-md">
                {entities?.examples ? (
                    <div>
                        <SCol className="font-bold">Accepted Forms</SCol>
                        <SCol span="12">{Parser(entities.examples)}</SCol>
                    </div>
                ) : null}
            </SRow>
        </React.Fragment>
    );
});

export default ExemptEntitiesContent;
