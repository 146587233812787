import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SDialog } from "@avalara/skylab-react";
import bgImg from "./assets/Frame.jpg";
import "./assets/upgrade.css";
import { getSessionAsync } from "../../app/sessionSlice";
import toast from "../../hooks/toast";
import getConfig from "../../config";

const { appEnv } = getConfig();

function OnlineBuyDialog(props) {
    const dispatch = useDispatch();
    let buydotUrl = "";
    if (appEnv === "qa") {
        buydotUrl = "https://qa.buy.web.avalara.io";
    }
    if (appEnv === "prd") {
        buydotUrl = "https://buy.avalara.com";
    }
    const buyFlow = "exemptions/plans";
    const returnUrl =
        appEnv === "prd"
            ? encodeURI(`${buydotUrl}/${buyFlow}?embedded=true&CampaignID=7015a000001ixUVAAY`)
            : encodeURI(`${buydotUrl}/${buyFlow}?embedded=true?`);
    const [hideFrame, setHideFrame] = useState(true);
    const [hideDescription, setHideDescription] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("Want even more powerful features?");
    const [bodyDesc, setBodyDesc] = useState(
        "Your current subscription tier does not include this action. Avalara exemptions has many more powerful tools and features. Upgrade now to have even more control over your exemptions!"
    );
    const enableOnlineBuy = appEnv === "qa" || appEnv === "prd";
    const dialogName = "onlineBuyDialog";
    const modalTitle = hideFrame ? headerTitle : "Upgrade your subscription";
    const [iframeURL, setIframeURL] = useState("");
    const [showToast] = toast();
    const navigate = useNavigate();
    const redirect = props.redirect ? props.redirect : false;

    useEffect(() => {
        if (props.type === "upload") {
            setHeaderTitle("Upload a certificate image");
            setBodyDesc(
                "Upgrade to add certificate images. Your current plan only allows you to exempt customers but not add certificate images."
            );
        } else if (props.type === "request") {
            setHeaderTitle("Request a certificate");
            setBodyDesc(
                // "Upgrade to request a certificate. Your current plan allows you to exempt customers but not request and receive certificate images."
                "Your current plan allows you to exempt customers but not request and receive certificate images."
            );
        } else if (props.type === "provision") {
            setBodyDesc(
                "Avalara exemptions has many more powerful tools and features. Upgrade now to have even more control over your exemptions!"
            );
        }
    }, [setHeaderTitle, setBodyDesc, props.type]); // eslint-disable-line react-hooks/exhaustive-deps

    const renderOnlineBuy = () => {
        setIframeURL(`${buydotUrl}/auth/login?returnUrl=${returnUrl}`);
        setHideFrame(false);
        setHideDescription(true);
    };

    const hideOnlineBuy = () => {
        setIframeURL("");
        setHideFrame(true);
        setHideDescription(false);
    };

    useEffect(() => {
        const handler = message => {
            if (!message.data || hideFrame === true) return;
            const { event, success } = message.data;
            if (event === "add-offer") {
                const toastMessage = success
                    ? "You have successfully upgraded!"
                    : "Upgrade was unsuccessful. Please try again later or reach out to support.";
                props.setIsUpgradeDialogOpen(false);
                hideOnlineBuy();
                showToast(success ? "success" : "error", toastMessage);
                if (redirect) {
                    setTimeout(() => {
                        dispatch(getSessionAsync(true));
                        navigate("/search/customers");
                    }, 5000);
                } else {
                    setTimeout(() => {
                        dispatch(getSessionAsync(true));
                    }, 5000);
                }
            }
        };
        window.addEventListener("message", handler);
        return () => window.removeEventListener("message", handler); // clean up
    }, [props, hideFrame, showToast]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <SDialog
            id={dialogName}
            open={props.isUpgradeDialogOpen}
            onS-dismiss={() => {
                props.setIsUpgradeDialogOpen(false);
                hideOnlineBuy();
            }}
            className="dialog-style"
            aria-modal="true">
            <div slot="header" id="upgradeSubscription">
                {modalTitle}
            </div>
            <div slot="body">
                <span hidden={hideDescription}>
                    <img className="image-style" src={bgImg} alt="cookies" />
                    <span className="description-style">
                        {bodyDesc}
                        {enableOnlineBuy ? (
                            <p> To upgrade your subscription, click the Upgrade button below. </p>
                        ) : (
                            <p>
                                To upgrade your subscription, contact your customer account manager.
                            </p>
                        )}
                    </span>
                </span>
                <iframe
                    hidden={hideFrame}
                    height="600px"
                    width="100%"
                    src={iframeURL}
                    title="buydot"
                />
            </div>
            <div slot="footer">
                <button
                    className="secondary small"
                    onClick={() => {
                        props.setIsUpgradeDialogOpen(false);
                        hideOnlineBuy();
                    }}>
                    {enableOnlineBuy ? "Cancel" : "OK"}
                </button>
                <button
                    className="primary small"
                    type="button"
                    hidden={!enableOnlineBuy || !hideFrame}
                    onClick={() => renderOnlineBuy()}>
                    Upgrade
                </button>
            </div>
        </SDialog>
    );
}

export default OnlineBuyDialog;
