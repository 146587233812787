import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import { selectSetting, selectSignatureData, fetchSignatureData } from "../../app/settingsSlice";
import toast from "../../hooks/toast";
import { selectDeliveryType } from "../../app/coverLetterSlice";
import { urlToPageMap, buildApiV2Point5Url } from "../../shared/Utils";
import { selectSelectedLocation } from "../../app/retailSlice";
import getConfig from "../../config";

const { hostnames } = getConfig();
const { docLockerClient, certCapture, genCert } = hostnames;

const GenCert = React.memo(props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showToast, hideToast] = toast();
    const selectedUserLocation = useSelector(selectSelectedLocation, shallowEqual);

    const isAdmin = useState(true); // TODO pass in some form of check once product defines one

    const deliveryType = useSelector(selectDeliveryType);
    const settings = useSelector(selectSetting, shallowEqual);
    const loadSigData = useSelector(selectSignatureData, shallowEqual);

    let { customer } = props;
    const {
        isRetail,
        defaultSigData,
        emailAddress,
        enableGencert,
        fillOnly,
        skipSignature,
        docType,
        exposureZone,
        taxCode,
        coverLetterID,
        ecmUser,
        skipCl,
    } = props;

    if (typeof customer === "string") {
        customer = JSON.parse(customer);
    }

    const toastPersistenceAndMessage = [
        false,
        `Go to ${isRetail ? "retail" : "vendor"} search`,
        () => {
            hideToast("signature-error");
            if (isRetail) {
                navigate(urlToPageMap.retail);
            } else {
                navigate(`/search/vendors`);
            }
        },
    ];

    useEffect(() => {
        if (!defaultSigData) {
            dispatch(fetchSignatureData(settings.id));
        }
    }, [dispatch, settings.id]);

    const sigData = !defaultSigData ? loadSigData : defaultSigData;

    function getGenCertInstance(frame, options, clientId) {
        const { GenCertClass } = window;
        return new GenCertClass(frame, options, clientId);
    }

    const onRetailCertificateCreationSuccess = () => {
        showToast("success", "Certificate created successfully.");
        navigate(
            `${urlToPageMap.retail}/${urlToPageMap.retailCustomerAndCertificates.replace(
                ":id",
                `${customer.id}`
            )}`
        );
    };

    const onCertSuccess = () => {
        if (isRetail) {
            onRetailCertificateCreationSuccess();
        } else {
            const message = isAdmin
                ? `We're creating your vendor certificate. It may take a few minutes. When it's ready we'll send it to ${emailAddress}`
                : `The document has been submitted to your company Admin users for a signature`;
            const type = isAdmin ? "success" : "warning";
            if (deliveryType === "D" && isAdmin) {
                showToast(
                    "success",
                    "We're creating your vendor certificate. It may take a few minutes.",
                    "download-success",
                    true,
                    "View the vendor certificate in My Downloads",
                    () => {
                        hideToast("download-success");
                        window.open(
                            `https://${docLockerClient}/mylockers/downloads`,
                            "_blank",
                            "noopener,noreferrer"
                        );
                    }
                );
            } else {
                showToast(type, message);
            }
            navigate(`/vendor/${customer.id}`);
        }
    };

    function onCancel() {
        if (isRetail) {
            navigate(
                `${urlToPageMap.retail}/${urlToPageMap.retailCustomerAndCertificates.replace(
                    ":id",
                    `${customer.id}`
                )}`
            );
        } else {
            navigate(`/vendor/${customer.id}`);
        }
    }

    const onCertFailure = showError => {
        if (typeof showError === "undefined" || showError !== false) {
            showToast(
                "error",
                "Could not generate a certificate. Please try again or contact support."
            );
            if (isRetail) {
                navigate(
                    `${urlToPageMap.retail}/${urlToPageMap.retailCustomerAndCertificates.replace(
                        ":id",
                        `${customer.id}`
                    )}`
                );
            } else {
                navigate(`/vendor-requests/${customer.id}`);
            }
        }
    };

    const onManualSubmit = () => {
        if (!isRetail) {
            showToast(
                "error",
                "Your exemption matrix is set to manually collect for the region and reason for exemption selected. Please check your exemption matrix and try again."
            );
            navigate(`/vendor-requests/${customer.id}`);
        }
    };

    const initializeGenCert = () => {
        const clientId = settings.id;
        const cxNumber = customer.customerNumber;

        axios
            .post(
                buildApiV2Point5Url(`get-ecomm-token`),
                {
                    ClientId: clientId.toString(),
                    CustomerNumber: cxNumber,
                },
                {
                    withCredentials: true,
                }
            )
            .then(response => {
                const gencertFrame = document.getElementById("gencert-frame");
                const gencertObj = {};
                const defaultOptions = {
                    certCaptureURL: certCapture,
                    token: response.data.token,
                    submit_to_stack: false,
                    fill_only: fillOnly,
                    css: "ecm",
                    gencertAPI: genCert,
                    skip_signature: skipSignature,
                    customer_number: cxNumber,
                    doc_type: docType,
                    ship_zone: exposureZone,
                    tax_code: taxCode,
                    delivery_type: deliveryType,
                    email_address: emailAddress,
                    cover_letter_only: false,
                    cover_letter: coverLetterID,
                    preview: false,
                    show_files: true,
                    ecm_flag: "approved",
                    ecm_user: ecmUser,
                    skip_cl: skipCl,
                    source: isRetail ? "Retail" : "Vendor",
                    onCertSuccess,
                    onCertFailure,
                    onCancel,
                    onManualSubmit,
                    onUpload: onRetailCertificateCreationSuccess,
                };
                if (selectedUserLocation?.value) {
                    defaultOptions.location_id = selectedUserLocation.value;
                }
                gencertObj.myGenCert = getGenCertInstance(gencertFrame, defaultOptions, clientId);
                window.GenCert = gencertObj.myGenCert;
                if (gencertObj.myGenCert) {
                    gencertObj.myGenCert.show();
                }

                if (sigData) {
                    gencertObj.myGenCert.setSignatureData({
                        name: sigData.name,
                        title: sigData.title,
                        signature: sigData.signature,
                    });
                } else {
                    showToast(
                        "error",
                        `Could not load signature.`,
                        "signature-error",
                        ...toastPersistenceAndMessage
                    );
                }
            });
    };

    const getGenCertScript = () => {
        const script = document.createElement("script");
        script.src = `${genCert}/js/gencert.js`;
        script.async = true;
        script.onload = () => initializeGenCert();
        script.onerror = () => {
            if (document.querySelector("#gencert-load-error") === null) {
                showToast(
                    "error",
                    "Failed to load Gencert. Please try again later.",
                    "gencert-load-error"
                );
            }
        };
        document.body.appendChild(script);
    };

    useEffect(() => {
        if (enableGencert) {
            getGenCertScript();
        }
    }, [enableGencert]);

    return <div id="gencert-frame" />;
});

export default GenCert;
