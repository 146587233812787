import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SCol, SLoader, SPagination, SRow } from "@avalara/skylab-react";
import { Link } from "react-router-dom";
import {
    fetchRetailCustomers,
    selectIsLoading,
    selectNoData,
    selectOpenCustomerAddOrEdit,
    selectOpenCustomerDetails,
    selectPage,
    selectSortColumn,
    setSortColumn,
} from "../../../app/retailSlice";
import { urlToPageMap } from "../../../shared/Utils";
import RecordCount from "../../search/RecordCount";
import { DataGrid, gridHeader } from "../../search/certificates";
import Customer from "../customer/Customer";
import CustomerDetails from "../customer/CustomerDetails";
import SearchFooter from "./SearchFooter";
import SearchNoData from "./SearchNoData";
import SearchCustomerCertificates from "./SearchCustomerCertificates";
import SearchCustomerCertificatesWithDialog from "./SearchCustomerCertificatesWithDialog";

function SearchGrid() {
    const page = useSelector(selectPage, shallowEqual);
    const openCustomerAddOrEdit = useSelector(selectOpenCustomerAddOrEdit);
    const openCustomerDetails = useSelector(selectOpenCustomerDetails);
    const isLoading = useSelector(selectIsLoading);
    const sortColumn = useSelector(selectSortColumn, shallowEqual);
    const noData = useSelector(selectNoData);
    const [currentRow] = useState({});
    const pageData = page.data;
    const paginateData = page.paginate;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isLoading && pageData.length === 0 && noData === "") {
            dispatch(fetchRetailCustomers(null, true));
        }
    }, []);

    function sortFunction(e) {
        const columnId = e.detail.id;
        const order = sortColumn[0] === columnId ? !sortColumn[1] : true;
        dispatch(setSortColumn([columnId, order]));
        dispatch(fetchRetailCustomers(paginateData, false, paginateData.rowsPerPage));
    }

    const headerColumns = [
        gridHeader(
            "Customer code",
            "customerNumber",
            value => value,
            null,
            null,
            null,
            null,
            sortFunction,
            "customerNumber"
        ),
        gridHeader(
            "Customer name",
            "name",
            (value, row) => (
                <Link
                    className="link font-bold"
                    to={`${
                        urlToPageMap.retail
                    }/${urlToPageMap.retailCustomerAndCertificates.replace(":id", `${row.id}`)}`}>
                    {row.name}
                </Link>
            ),
            null,
            null,
            null,
            null,
            sortFunction,
            "name"
        ),
        gridHeader("Address", "address", value => value),
        gridHeader("Phone", "phoneNumber", value => value, "divider-right", "divider-right"),
        gridHeader("Certificate", "retailCertificates", (value, row) =>
            value.length <= 3 ? (
                <SearchCustomerCertificates certificates={value} />
            ) : (
                <SearchCustomerCertificatesWithDialog
                    certificates={value}
                    customerName={row.name}
                    customerNumber={row.customerNumber}
                    customerAddress={row.address}
                />
            )
        ),
    ];

    const handlePaginate = e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            dispatch(
                fetchRetailCustomers(e.detail, false, e.detail.rowsPerPage, e.detail.currentPage)
            );
        }
    };

    const getRowKey = row => {
        return row.id;
    };

    let dataDOM = null;
    if (isLoading) {
        dataDOM = (
            <div className="flex flex-dir-col align-items-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        );
    } else if (paginateData.totalRecords === 0) {
        dataDOM = <SearchNoData />;
    } else {
        dataDOM = (
            <s-table-container class="margin-top-xl">
                <DataGrid
                    columns={headerColumns}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={isLoading}
                    inContainer
                    sortColumn={sortColumn}
                />
            </s-table-container>
        );
    }

    let pagination = null;
    if (paginateData.totalRecords > 0 && paginateData.indeterminate) {
        pagination = (
            <SPagination
                className="margin-top-md"
                rowsPerPage={paginateData.rowsPerPage}
                totalRecords={paginateData.totalRecords}
                indeterminate
                disabled
                startIndex={paginateData.startIndex}
                onS-paginate={e => {
                    handlePaginate(e);
                }}
            />
        );
    } else if (paginateData.totalRecords > 0 && !isLoading) {
        pagination = (
            <SPagination
                className="margin-top-md"
                rowsPerPage={paginateData.rowsPerPage}
                totalRecords={paginateData.totalRecords}
                startIndex={paginateData.startIndex}
                onS-paginate={e => {
                    handlePaginate(e);
                }}
            />
        );
    }

    return (
        <>
            <SRow>
                <SCol>
                    <SRow>
                        <SCol span={12}>
                            <span className="font-semibold text-xl">Customer exemptions</span>
                            {paginateData.totalRecords > 0 && !isLoading ? (
                                <RecordCount
                                    selectedCount={0}
                                    recordCount={paginateData.totalRecords}
                                    recordName={
                                        paginateData.totalRecords > 1 ? "customers" : "customer"
                                    }
                                />
                            ) : null}
                            {dataDOM}
                            {pagination}
                        </SCol>
                    </SRow>
                    <SearchFooter />
                </SCol>
            </SRow>
            {openCustomerAddOrEdit && <Customer />}
            {openCustomerDetails && <CustomerDetails customer={currentRow} />}
        </>
    );
}

export default SearchGrid;
