import React from "react";
import { useNavigate } from "react-router-dom";
import { SDialog, SRow, SCol } from "@avalara/skylab-react";

function SignatureError(props) {
    const navigate = useNavigate();
    const errorMessage =
        props.userRole === "AccountAdmin" || props.userRole === "CompanyAdmin"
            ? `To send certificates, you need to have a company signature on file. If you’re not an authorized signer for ${props.companyName} ask an authorized signer to add a company signature to the settings.`
            : `To send certificates, an authorized signer for ${props.companyName} needs to add a company signature to the settings.`;

    const onSDismiss = () => {
        props.setIsSignatureError(false);
        navigate("/settings");
    };

    const clickCancel = () => {
        props.setIsSignatureError(false);
        navigate(-1);
    };

    return (
        <SDialog
            id="signatureError-dialog"
            open={props.isSignatureError}
            noDismiss
            aria-modal="true"
            className="signatureError">
            <div slot="header" id="signatureError">
                Sending certificates
            </div>
            <div slot="body" className="preview-modal-body">
                <div className="text-gray">{errorMessage}</div>
            </div>
            <div slot="footer" className="block">
                <SRow>
                    <SCol span="12" className="flex justify-content-end">
                        <button className="secondary inline" onClick={() => clickCancel()}>
                            Cancel
                        </button>
                        <button className="primary inline" onClick={() => onSDismiss()}>
                            Create a signature
                        </button>
                    </SCol>
                </SRow>
            </div>
        </SDialog>
    );
}

export default SignatureError;
