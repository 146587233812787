import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SDialog } from "@avalara/skylab-react";
import classnames from "classnames";
import BulkUpdateExpirationDateDialog from "./BulkUpdateExpirationDateDialog";
import BulkUpdateLabelsDialog from "./BulkUpdateLabelsDialog";
import BulkUpdateInvalidReasonsDialog from "./BulkUpdateInvalidReasons";
import BulkUpdateConfirmDialog from "./BulkUpdateConfirmDialog";
import "./bulkeditdialog.css";
import { selectSelectedField, setSelectedField } from "../../app/bulkUpdateSlice";
import { fetchCertificatesSearchAPI } from "../../app/certificateSlice";

function BulkUpdateChooseFieldDialog(props) {
    const dispatch = useDispatch();
    const selectedField = useSelector(selectSelectedField);
    const { certificateCount } = props;
    const nextButton = classnames({ primary: true, small: true });
    const [isBulkUpdateLabelsDialogOpen, setIsBulkUpdateLabelsDialogOpen] = useState(false);
    const [isBulkUpdateExpirationDateDialogOpen, setIsBulkUpdateExpirationDateDialogOpen] =
        useState(false);
    const [isBulkUpdateInvalidReasonDialogOpen, setIsBulkUpdateInvalidReasonDialogOpen] =
        useState(false);
    const [isBulkUpdateConfirmDialogOpen, setIsBulkUpdateConfirmDialogOpen] = useState(false);
    const handleNext = () => {
        props.setIsBulkUpdateChooseFieldDialogOpen(false);
        dispatch(fetchCertificatesSearchAPI(false, null, certificateCount));
        switch (selectedField) {
            case "labels":
                setIsBulkUpdateLabelsDialogOpen(true);
                break;
            case "expiration":
                setIsBulkUpdateExpirationDateDialogOpen(true);
                break;
            case "invalidReason":
                setIsBulkUpdateInvalidReasonDialogOpen(true);
                break;
            default:
                break;
        }
    };

    const handleRadioChange = e => {
        dispatch(setSelectedField(e.target.value));
    };

    const handleCancel = () => {
        props.setIsBulkUpdateChooseFieldDialogOpen(false);
        dispatch(setSelectedField(""));
    };
    return (
        <>
            <SDialog
                id="bulk-edit-dialog"
                open={props.isBulkUpdateChooseFieldDialogOpen}
                onS-dismiss={handleCancel}
                aria-modal="true">
                <div slot="header" id="bulkEdit">
                    Update {certificateCount}{" "}
                    {certificateCount > 1 ? "certificates" : "certificate"}
                </div>
                <div slot="body">
                    Choose which field you&apos;d like to update to a new value.
                    <fieldset role="radiogroup">
                        <input
                            type="radio"
                            id="labels"
                            value="labels"
                            name="fields"
                            onChange={e => handleRadioChange(e)}
                            checked={selectedField === "labels"}
                        />
                        <label htmlFor="labels" id="lbl-labels">
                            Labels
                        </label>
                        <br />
                        <input
                            type="radio"
                            id="expiration"
                            value="expiration"
                            name="fields"
                            onChange={e => handleRadioChange(e)}
                            checked={selectedField === "expiration"}
                        />
                        <label htmlFor="expiration" id="lbl-expiration">
                            Expiration Date
                        </label>
                        <br />
                        <input
                            type="radio"
                            id="invalidReason"
                            value="invalidReason"
                            name="fields"
                            onChange={e => handleRadioChange(e)}
                            checked={selectedField === "invalidReason"}
                        />
                        <label htmlFor="invalidReason">Invalid Reason</label>
                    </fieldset>
                </div>
                <div slot="footer">
                    <button className="secondary small" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button
                        className={nextButton}
                        disabled={!selectedField}
                        type="button"
                        onClick={handleNext}>
                        Next
                    </button>
                </div>
            </SDialog>
            {isBulkUpdateExpirationDateDialogOpen ? (
                <BulkUpdateExpirationDateDialog
                    isBulkUpdateExpirationDateDialogOpen={isBulkUpdateExpirationDateDialogOpen}
                    setIsBulkUpdateExpirationDateDialogOpen={
                        setIsBulkUpdateExpirationDateDialogOpen
                    }
                    setIsBulkUpdateConfirmDialogOpen={setIsBulkUpdateConfirmDialogOpen}
                />
            ) : null}

            {isBulkUpdateLabelsDialogOpen ? (
                <BulkUpdateLabelsDialog
                    isBulkUpdateLabelsDialogOpen={isBulkUpdateLabelsDialogOpen}
                    setIsBulkUpdateLabelsDialogOpen={setIsBulkUpdateLabelsDialogOpen}
                    setIsBulkUpdateConfirmDialogOpen={setIsBulkUpdateConfirmDialogOpen}
                    certificateCount={certificateCount}
                    setChooseField={props.setIsBulkUpdateChooseFieldDialogOpen}
                />
            ) : null}
            {isBulkUpdateInvalidReasonDialogOpen ? (
                <BulkUpdateInvalidReasonsDialog
                    isBulkUpdateInvalidReasonDialogOpen={isBulkUpdateInvalidReasonDialogOpen}
                    setIsBulkUpdateInvalidReasonDialogOpen={setIsBulkUpdateInvalidReasonDialogOpen}
                    setIsBulkUpdateConfirmDialogOpen={setIsBulkUpdateConfirmDialogOpen}
                    certificateCount={certificateCount}
                />
            ) : null}
            {isBulkUpdateConfirmDialogOpen ? (
                <BulkUpdateConfirmDialog
                    isBulkUpdateConfirmDialogOpen={isBulkUpdateConfirmDialogOpen}
                    setIsBulkUpdateConfirmDialogOpen={setIsBulkUpdateConfirmDialogOpen}
                />
            ) : null}
        </>
    );
}

export default BulkUpdateChooseFieldDialog;
