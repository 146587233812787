import React, { useCallback } from "react";
import { SIcon } from "@avalara/skylab-react";

function ZoomTip(props) {
    const idString = `${props.tipTitle}-popover`;
    const handleToolTip = useCallback(() => {
        // eslint-disable-next-line no-undef
        iph.Popover(idString, props.url).open();
    }, [props.url, props.tipTitle]);

    const zoomTipDom = (
        <button
            id={idString}
            onClick={() => handleToolTip()}
            className="text-blue-medium icon ghost no-link"
            aria-label={props.tipTitle}>
            <SIcon name="help-circle-filled" aria-hidden="true" />
        </button>
    );
    return zoomTipDom;
}

export default ZoomTip;
