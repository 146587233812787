import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { DataGrid, gridHeader, useId } from "@certcapture/react-components";
import { SLoader, SIcon, SPagination, SRowActions } from "@avalara/skylab-react";
import { v4 as uuidv4 } from "uuid";
import toast from "../../../hooks/toast";
import { selectCustomer } from "../../../app/customerSlice";
import {
    fetchCustomerExemptionsDetails,
    selectPage,
    setSortColumn,
    selectSortColumn,
    selectLoading,
    setIsRequestFromCustomersCertificateGrid,
} from "../../../app/customerCertificateSlice";
import RequestDialog from "../../sharedDialogs/RequestDialog";
import UploadCertificate from "../../sharedDialogs/UploadCertificate";
import { selectSession, updateRowCountPreference } from "../../../app/sessionSlice";
import PreviewDialog from "../../sharedDialogs/PreviewDialog";
import RemoveRegionDialog from "./RemoveRegionDialog";
import CertificateStatusTag from "../../sharedComponents/CertificateStatusTag";
import { isEligibleUser } from "../../../shared/sessionUtility";
import { sentenceCase, getRowsPerPage } from "../../../shared/Utils";
import DeleteCertificateFileDialog from "../../sharedDialogs/DeleteCertificateFileDialog";
import { getCertificateFile, selectCertificateStatuses } from "../../../app/certificateSlice";
import featureFlag from "../../../featureToggler/featureFlag";

function CustomerExemptionsGrid(props) {
    const dispatch = useDispatch();
    const [showToast] = toast();
    const customer = useSelector(selectCustomer);
    const [submitting, setSubmitting] = useState(false);
    const page = useSelector(selectPage);
    const pageData = page.data;
    const paginateData = page.paginate;
    const session = useSelector(selectSession);
    const loading = useSelector(selectLoading);
    const [currentRow, setCurrentRow] = useState({});
    const [certificateID, setCertificateID] = useState(null);
    const [certificateFileName, setcertificateFileName] = useState(null);
    const certificateStatuses = useSelector(selectCertificateStatuses, shallowEqual);
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
    const [isSpecificRequestDialogOpen, setIsSpecificRequestDialogOpen] = useState(false);
    const [isRemoveRegionDialogOpen, setIsRemoveRegionDialogOpen] = useState(false);
    const sortColumn = useSelector(selectSortColumn);
    const [getCurrentId, getNextId] = useId();
    const uploadRef = useRef(null);
    const [ellipseMenuState, setEllipseMenuState] = useState("");
    const isEligibleUserValidatedCertificateUpload = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.upload)
    );
    const isEligibleUserRequestCertificatesSingleRequest = dispatch(
        isEligibleUser(featureFlag.requestCertificates.singleRequest)
    );
    const isEligibleUserValidatedCertificateDelete = dispatch(
        isEligibleUser(featureFlag.validatedCertificate.delete)
    );
    const ellipsesMenuItems = !props.customer.isVendor
        ? [
              {
                  label: "Request a certificate",
                  iconName: "send",
                  classList: ["primary", "icon-leading"],
                  id: `menu-${uuidv4()}-request`,
                  disabled: !isEligibleUserRequestCertificatesSingleRequest,
              },
          ]
        : [];

    const downloadCertificate = async row => {
        await dispatch(getCertificateFile(row.certificateId, showToast));
    };

    const actionItems = row => {
        const menu = [];
        if (
            row.s3DocumentExists &&
            certificateStatuses?.filter(x => x.id === row.certificateStatusId)[0]?.name !==
                "REVOKED"
        ) {
            menu.push({
                label: "Download",
                iconName: "download",
                classList: ["primary", "icon-leading"],
                id: `menu-${uuidv4()}-download`,
            });
            menu.push({
                label: "Delete image",
                iconName: "trash",
                classList: ["primary", "icon-leading"],
                id: `menu-${uuidv4()}-delete`,
                disabled: !isEligibleUserValidatedCertificateDelete,
            });
        }
        if (row.certificateId) {
            menu.push({
                label: "Upload a certificate",
                iconName: "upload",
                classList: ["primary", "icon-leading"],
                id: `menu-${uuidv4()}-upload`,
                disabled: !isEligibleUserValidatedCertificateUpload,
            });
        }

        if (!row.certificateId) {
            menu.push({
                label: "Remove a region",
                iconName: "trash",
                classList: ["primary", "icon-leading"],
                id: `menu-${uuidv4()}-region`,
            });
        }
        return [...ellipsesMenuItems, ...menu];
    };

    async function sortFunction(e) {
        setSubmitting(true);
        const columnId = e.detail.id;
        const order = sortColumn[0] === columnId ? !sortColumn[1] : true;
        dispatch(setSortColumn([columnId, order]));
        await dispatch(fetchCustomerExemptionsDetails(session.rowsPerPage, 0));
        setSubmitting(false);
    }

    const openPreviewButton = row => {
        if (row.certificateId) {
            setCertificateID(row.certificateId);
            setcertificateFileName(
                row.s3DocumentExists && row.certificateFileName ? row.certificateFileName : ""
            );
            setIsPreviewDialogOpen(true);
            setCurrentRow(row);
        }
    };

    const openRequestModal = () => {
        if (session.baseSubscription) {
            props.setIsUpgradeDialogOpen(true);
        } else {
            setIsSpecificRequestDialogOpen(true);
        }
    };

    const openRemoveRegionModal = () => {
        setIsRemoveRegionDialogOpen(true);
    };

    const getTaxCode = row => {
        if (row.certificateStatus.toUpperCase() === "PENDING-FUTURE") {
            return sentenceCase(row.certificateStatus);
        }

        if (
            row.actualTaxCodeName === null ||
            row.actualTaxCodeName?.includes("EXPOSURE:") ||
            row.actualTaxCodeName?.includes("NON-NEXUS")
        ) {
            if (row.actualTaxCodeName?.includes("INVALID CERT")) {
                return sentenceCase(row.expectedTaxCodeName);
            }
            return "";
        }
        return sentenceCase(row.actualTaxCodeName);
    };

    const getRevokedCertificateStatuse = row => {
        return (
            certificateStatuses?.filter(x => x.id === parseInt(row.certificateStatusId, 10))[0]
                ?.name === "REVOKED"
        );
    };

    const columns = [
        gridHeader(
            "Region",
            "exposureZoneName",
            (value, row) => row.exposureZoneName,
            null,
            "",
            null,
            null,
            sortFunction,
            "exposureZoneName"
        ),
        gridHeader(
            "Certificate ID",
            "certificateId",
            value => <Link to={`/certificate/${value}`}>{value}</Link>,
            null,
            "pad-left-md",
            null,
            null,
            sortFunction,
            "certificateId"
        ),
        gridHeader(
            "Exemption reason",
            "actualTaxCodeName",
            (value, row) => getTaxCode(row),
            null,
            "",
            null
        ),
        gridHeader(
            "Effective",
            "signedDate",
            value => (value ? new Date(value).toLocaleDateString() : ""),
            null,
            "",
            null,
            null,
            sortFunction,
            "signedDate"
        ),
        gridHeader(
            "Expiration",
            "expirationDate",
            value => (value ? new Date(value).toLocaleDateString() : ""),
            null,
            "",
            null,
            null,
            sortFunction,
            "expirationDate"
        ),
        gridHeader(
            "Status",
            "certificateStatus",
            (value, row) => (
                <React.Fragment>
                    {CertificateStatusTag(
                        row,
                        null,
                        false,
                        certificateStatuses?.filter(
                            x => x.id === parseInt(row.certificateStatusId, 10)
                        )[0]?.name
                    )}
                </React.Fragment>
            ),
            null,
            null
        ),

        gridHeader(
            "Single use",
            "isSingle",
            (value, row) => (row.isSingle ? <SIcon name="check" aria-hidden="true" /> : ""),
            null,
            "",
            null,
            null
        ),

        gridHeader(
            "Preview",
            "id",
            (value, row) => (
                <button
                    className="link font-bold"
                    disabled={!row.certificateId}
                    onClick={() => openPreviewButton(row)}>
                    <SIcon
                        name="zoom-in"
                        aria-label={row.certificateId}
                        className={
                            row.certificateId && row.certificateFileName ? "blue-icon" : "disabled"
                        }
                    />
                </button>
            ),
            "text-center",
            "text-center"
        ),

        gridHeader(
            "",
            "",
            (value, row) => (
                <>
                    <span htmlFor={getNextId()} />
                    {!props.customer.isVendor ? (
                        <SRowActions
                            menuId={`menu-${getCurrentId()}`}
                            key={uuidv4()}
                            disabled
                            actionItems={actionItems(row)}
                            collapsed
                            onS-select={e => {
                                setCurrentRow(row);
                                setEllipseMenuState(e.detail.id);
                                dispatch(setIsRequestFromCustomersCertificateGrid(true));
                                if (e.detail.id.includes("upload")) {
                                    uploadRef.current.click();
                                } else if (e.detail.id.includes("download")) {
                                    downloadCertificate(row);
                                } else if (e.detail.id.includes("request")) {
                                    openRequestModal(row);
                                } else if (e.detail.id.includes("region")) {
                                    openRemoveRegionModal();
                                }
                            }}
                        />
                    ) : null}
                </>
            ),
            "text-right",
            "text-right"
        ),
    ];

    const getRowKey = () => {
        return uuidv4();
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && paginateData.totalRecords > 0 && e.detail.flag !== "") {
            setSubmitting(true);
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            await dispatch(
                fetchCustomerExemptionsDetails(e.detail.rowsPerPage, e.detail.startIndex, e.detail)
            );

            setSubmitting(false);
        }
    };

    const data =
        loading || submitting ? (
            <div className="flex dl-flex-fill-height dl-flex-center">
                <h3>Loading ...</h3>
                <SLoader id="page-loader" className="medium" aria-live="polite" loading />
            </div>
        ) : (
            <s-table-container class="margin-top-xl initial-overflow">
                <DataGrid
                    columns={columns}
                    rows={pageData}
                    getKey={getRowKey}
                    loading={submitting}
                    inContainer
                    sortColumn={sortColumn}
                />
            </s-table-container>
        );

    return (
        <React.Fragment>
            {data}
            {paginateData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={getRowsPerPage(paginateData, session.rowsPerPage)}
                    totalRecords={paginateData.totalRecords}
                    startIndex={paginateData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            {certificateID && isPreviewDialogOpen ? (
                <PreviewDialog
                    isPreviewDialogOpen
                    setIsPreviewDialogOpen={setIsPreviewDialogOpen}
                    certificateID={certificateID}
                    certificateName={certificateFileName}
                    isUploaderDisabled={!isEligibleUserValidatedCertificateUpload}
                    showRevokedAlert={getRevokedCertificateStatuse(currentRow)}
                />
            ) : null}
            {String(ellipseMenuState).includes("request") ? (
                <RequestDialog
                    isRequestDialogOpen={
                        props.isGeneralRequestDialogOpen || isSpecificRequestDialogOpen
                    }
                    setIsGeneralRequestDialogOpen={props.setIsGeneralRequestDialogOpen}
                    setIsRequestDialogOpen={setIsSpecificRequestDialogOpen}
                    customer={customer}
                    customerCount={1}
                    setCurrentRow={setCurrentRow}
                    exposureZone={currentRow?.exposureZoneName}
                    taxCode={currentRow?.actualTaxCodeName}
                />
            ) : null}
            {String(ellipseMenuState).includes("region") ? (
                <RemoveRegionDialog
                    isRemoveRegionDialogOpen={isRemoveRegionDialogOpen}
                    setIsRemoveRegionDialogOpen={setIsRemoveRegionDialogOpen}
                    customer={customer}
                    exposureZoneId={currentRow.exposureZoneId}
                    isVendor={props.customer.isVendor}
                />
            ) : null}
            {String(ellipseMenuState).includes("delete") ? (
                <DeleteCertificateFileDialog
                    isDeleteCertificateFileDialogOpen
                    setIsDeleteCertificateFileDialogOpen={setEllipseMenuState}
                    certificateID={currentRow.certificateId}
                />
            ) : null}
            <UploadCertificate
                ref={uploadRef}
                isOverride={false}
                isButtonHidden
                certificateID={currentRow.certificateId}
                isUploaderDisabled={!isEligibleUserValidatedCertificateUpload}
                showRevokedAlert={getRevokedCertificateStatuse(currentRow)}
            />
        </React.Fragment>
    );
}
export default CustomerExemptionsGrid;
