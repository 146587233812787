import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { getStatesAsync, selectStates, selectIsLoadingStates } from "../../app/commonSlice";

function SelectState(props, ref) {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingStates);
    const states = useSelector(selectStates).map(element => {
        const selectOption = {
            label: element.name,
            value: element.id,
            initials: element.initials,
            selected: false,
            page: "",
        };
        if (
            (props.value && props.value?.indexOf(element.id) !== -1) ||
            props.value === element.initials ||
            props.value === element.name
        ) {
            selectOption.selected = true;
        }
        return selectOption;
    });
    useEffect(() => {
        if (props.USStates) dispatch(getStatesAsync(true));
        else if (props?.allStates) dispatch(getStatesAsync(false, true));
        else dispatch(getStatesAsync(false));
    }, [props.USStates, dispatch]);
    if (props.AddUncat && states.length > 0) {
        const uncategorized = {
            label: "Uncategorized",
            value: states.length + 1,
            initials: "UNC",
            selected: false,
            page: "",
        };
        states.push(uncategorized);
    }

    return (
        <React.Fragment>
            <label
                htmlFor={getNextId()}
                id={`lbl-${getCurrentId()}`}
                className={props.required ? "required" : ""}>
                {props.label}
            </label>
            <SSelect
                inputId={getCurrentId()}
                ref={ref}
                name="state"
                loading={isLoading}
                value={props?.value}
                selectionOptional={props?.selectionOptional}
                optionsList={states}
                onS-select={e => props?.onAdd(e.detail.item)}
                onS-deselect={e => props?.onRemove(e.detail.item, e.detail.isDeselectAll)}
                onS-close={e => (props.onClose ? props.onClose(e) : null)}
                className={props.stateError ? props.stateError : ""}
                multiple={props.multiple}
                disabled={props.disabled ? "disabled" : null}
            />
        </React.Fragment>
    );
}
export default React.forwardRef(SelectState);
